<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Balances-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Parameters -->
              <b-row class="mb-3">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div class="d-flex">
                    <label
                      style="flex: none; align-self: center; font-weight: 500"
                    >
                      {{ $t("BALANCES.PARAMETER.DATE") }}
                    </label>
                    <b-form-input
                      type="date"
                      v-model="parameterDate"
                      class="mb-3 mb-sm-0 ml-0 ml-sm-1 mr-0 mr-sm-3"
                    ></b-form-input>
                    <b-button
                      variant="primary"
                      id="parameter_button"
                      @click="fetchData()"
                    >
                      {{ $i18n.t("BALANCES.PARAMETER.BUTTON") }}
                    </b-button>
                  </div>
                  <b-col
                    class="d-flex flex-row align-items-center mr-md-3 mr-0 col-lg-4 col-md-6 col-sm-12"
                  >
                    <label for="search" class="mr-1" style="font-weight: 500">
                      {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                    </label>
                    <b-form-input
                      v-model="filter"
                      @change="Search()"
                    ></b-form-input>
                  </b-col>
                </b-col>
              </b-row>
              <!--End::Parameters -->

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :filter="filter"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          {{ data.value.VS }}
                          <b>{{ data.value.Name }}</b>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span
                          v-bind:class="[
                            data.value.Balance < 0 ? 'color-danger' : '',
                          ]"
                        >
                          {{
                            getFormatService().formatNumberToLocal(
                              data.value.Balance,
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        {{
                          data.value.ApartmentType +
                            ", " +
                            data.value.ApartmentNumber
                        }}
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        {{
                          getFormatService().formatDateToLocal(
                            data.value.LastPaymentDate,
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Balances-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";
import GridService from "@/common/grid.service";

export default {
  name: "balances",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      filter: null,
      fields: [
        {
          key: "VS",
          label: this.$i18n.t("BALANCES.TABLE.VS"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "ApartmentNumber",
          label: this.$i18n.t("BALANCES.TABLE.APARTMENT_NUMBER"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
        },
        {
          key: "ApartmentType",
          label: this.$i18n.t("BALANCES.TABLE.APARTMENT_TYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Name",
          label: this.$i18n.t("BALANCES.TABLE.NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Balance",
          label: this.addCurrencyToColumn(
            this.$i18n.t("BALANCES.TABLE.BALANCE"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (item.Balance < 0) {
              item._cellVariants = { Balance: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "LastPaymentDate",
          label: this.$i18n.t("BALANCES.TABLE.LAST_PAYMENT_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
      parameterDate: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    let bContent = GridService.getGridFilter("balances");
    if (bContent) {
      this.filter = bContent.filter;
      this.sortBy = bContent.sortBy;
      this.sortDirection = bContent.sortDirection;
    }
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showAccountDetail(record.UzivatelId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    showAccountDetail(accountID) {
      let postContent = {
        name: "balances",
        filter: this.filter,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
      };
      GridService.setGridFilter(postContent);

      this.$router.push({
        name: "domus-account",
        query: { menuId: 0, accUnitId: accountID, lastMenuId: this.menuId }, //0 sa pouziva preto lebo mi je jedno kam idem, kedze si to zistujem v procke poschodoch_Account
      });
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.parameterDate != null)
        parameters += "&dueDate=" + this.parameterDate.toString();
      window.open("/BalancesPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("BALANCES.TABLE.VS"),
        this.$i18n.t("BALANCES.TABLE.APARTMENT_NUMBER"),
        this.$i18n.t("BALANCES.TABLE.APARTMENT_TYPE"),
        this.$i18n.t("BALANCES.TABLE.NAME"),
        this.addCurrencyToColumn(this.$i18n.t("BALANCES.TABLE.BALANCE")),
        this.$i18n.t("BALANCES.TABLE.LAST_PAYMENT_DATE"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.VS,
          item.ApartmentNumber,
          item.ApartmentType,
          item.Name,
          FormatService.formatNumberToLocalForCSV(item.Balance),
          FormatService.formatDateToLocal(item.LastPaymentDate),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      if (this.parameterDate != null)
        parameters += "&dueDate=" + this.parameterDate.toString();

      ApiService.setHeader();
      ApiService.get("Object", "Balances" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Balances;
        this.parameterDate = data.DueDate;

        this.transformData(this.items);
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].BalanceLocale = FormatService.formatNumberToLocal(
          items[i].Balance,
        );
        items[i].LastPaymentDateLocale = FormatService.formatDateToLocal(
          items[i].LastPaymentDate,
        );
        items[i].Mobile = {
          VS: items[i].VS,
          ApartmentNumber: items[i].ApartmentNumber,
          ApartmentType: items[i].ApartmentType,
          Name: items[i].Name,
          Balance: items[i].Balance,
          LastPaymentDate: items[i].LastPaymentDate,
        };
      }
    },
  },
};
</script>
