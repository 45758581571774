const i18nService = {
  defaultLanguage: "sk",

  languages: [
    {
      lang: "sk",
      name: "Slovensky",
      flag: process.env.BASE_URL + "assets/media/flags/091-slovakia.svg",
    },
    {
      lang: "cz",
      name: "Česky",
      flag: process.env.BASE_URL + "assets/media/flags/149-czech-republic.svg",
    },
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "assets/media/flags/226-united-states.svg",
    },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    if (
      !localStorage.getItem("language") &&
      (window.location.host.includes("poschodech") ||
        window.location.host.includes("mujbyt"))
    )
      this.defaultLanguage = "cz";

    return localStorage.getItem("language") || this.defaultLanguage;
  },
};

export default i18nService;
