<template>
  <b-col xs="12" sm="10" lg="5" class="mx-auto">
    <b-card
      text-variant="black"
      style="background-color: rgba(255, 255, 255, 0.85)"
    >
      <b-card-body>
        <!--begin::Body-->
        <div class="kt-login__body">
          <h3>{{ $t("FACEBOOK.TITLE") }}</h3>
          <!-- <div class="mt-4">
            <Loader />
          </div> -->
          <div v-if="!fbError.includes(' Facebook ')">
            <div
              role="alert"
              v-bind:class="{ show: errors && errors.length }"
              class="alert fade alert-danger mt-4 mb-2"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>
            <div class="mt-4">
              <b-button block variant="secondary" @click="backToLogin">
                {{ $i18n.t("AUTH.GENERAL.BACK_TOLOGIN") }}
              </b-button>
            </div>
          </div>
          <div v-else>
            <div
              role="alert"
              v-bind:class="{ show: errors && errors.length }"
              class="alert fade alert-info mt-4 mb-2"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error.replace(" (LoginWithFacebookToken)", "") }}
              </div>
            </div>
            <div class="mt-4">
              <b-button block variant="secondary" @click="goToRegistration">
                {{ $i18n.t("AUTH.GENERAL.SIGNUP_BUTTON") }}
              </b-button>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </b-card-body>
    </b-card>
  </b-col>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import UIService from "@/common/ui.service";
import { CHANGE_UNIT, LOGIN_WITH_FACEBOOK } from "@/store/auth.module";
// import Loader from "@/views/components/Loader.vue";

export default {
  name: "facebook",
  components: {
    // Loader
  },
  data() {
    return {
      token: null,
      fbError: "",
    };
  },
  mounted: function() {
    //set default brand color
    UIService.changeSiteColorCustomer();
    this.token = this.$route.query.token;
    this.loginWithFacebook();

    //redirect to dashboard if already logged in
    if (this.isAuthenticated) {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    loginWithFacebook() {
      // send login with facebook request
      this.$store
        .dispatch(LOGIN_WITH_FACEBOOK, { FacebookToken: this.token })
        // go to which page after successfully login
        .then((data) => {
          if (data.length == 1) {
            // user has only one unit
            let obj = {
              unitID: data[0].UnitId,
              portalID: data[0].PortalId,
              unitType: data[0].UnitType,
            };

            this.$store.dispatch(CHANGE_UNIT, obj).then(() => {
              this.$router.push({ name: "dashboard" });
            });
          } else {
            // route to unit selection if user has more unit available
            this.$router.push({ name: "units" });
          }
        })
        .catch((error) => {
          // uklada nove errory do 'fbError' dokym nenajde tento (ak by bolo viac errorov naraz)
          if (!this.fbError.includes(" Facebook ")) {
            this.fbError = error.data.error;
          }
          console.log(error);
        });
    },
    backToLogin() {
      this.$router.push({
        name: "login",
      });
    },
    goToRegistration() {
      this.$router.push({
        name: "registrationCode",
      });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["layoutConfig", "isAuthenticated"]),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    },
  },
};
</script>
