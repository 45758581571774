<template>
  <b-col xs="12" sm="10" md="10" lg="8" class="mx-auto">
    <b-card
      text-variant="black"
      style="background-color: rgba(255, 255, 255, 0.85)"
    >
      <template>
        <!-- errors -->
        <div
          role="alert"
          v-if="errors && errors.length"
          v-bind:class="{ show: errors && errors.length }"
          class="alert fade alert-danger mt-2 mb-5"
          style="flex-direction: column"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
        <!-- errors -->
      </template>
      <b-card-body>
        <!--begin::Body-->
        <div class="kt-login__body" v-if="!Countdown">
          <h3>{{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.HEAD") }}</h3>
          <div
            class="poshodoch-multiline-header d-flex flex-column align-items-start pt-3 pb-3"
          >
            <div v-if="!haveAcc">
              <span class="font-weight-bold">
                {{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.ANASOFT_TEXT") }}
              </span>
              <b-form-checkbox
                v-model="$v.form.checkedLegalAgreementAna.$model"
                :state="validateState('checkedLegalAgreementAna')"
              >
                <router-link
                  :to="{ name: 'registrationTerms', query: { type: 0 } }"
                  class="kt-link"
                  target="_blank"
                >
                  {{
                    $i18n.t(
                      "AUTH.REGISTRATION_AGREEMENT.LEGAL_AGREEMENT_CHECKBOX_ANA",
                    )
                  }}
                </router-link>
              </b-form-checkbox>
            </div>
            <span class="font-weight-bold">
              {{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.TEXT_ACCESS") }}
            </span>
            <span class="poschodoch-header-label">
              <span class="font-weight-bold">
                {{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.NAME") + ": " }}
              </span>
              {{ stateAgreementInfo.Name }}
            </span>
            <span class="poschodoch-header-label">
              <span class="font-weight-bold">
                {{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.ADDRESS") + ": " }}
              </span>
              {{ stateAgreementInfo.Address }}
            </span>
            <span class="poschodoch-header-label">
              <span class="font-weight-bold">
                {{
                  $i18n.t("AUTH.REGISTRATION_AGREEMENT.VARIABLE_SYMBOL") + ": "
                }}
              </span>
              {{ stateAgreementInfo.VS }}
            </span>
            <span class="font-weight-bold">
              {{
                $i18n.t("AUTH.REGISTRATION_AGREEMENT.CUSTOMER_TEXT") +
                  stateAgreementInfo.CustomerName
              }}
            </span>
          </div>
          <div class="kt-login__form">
            <b-form class="kt-form" @submit.stop.prevent="onSubmit">
              <!--Begin::Checkbox -->

              <b-form-checkbox
                v-model="$v.form.checkedPrice.$model"
                :state="validateState('checkedPrice')"
              >
                <router-link
                  :to="{ name: 'registrationTerms', query: { type: 1 } }"
                  class="kt-link"
                  target="_blank"
                >
                  {{
                    $i18n.t(
                      "AUTH.REGISTRATION_AGREEMENT.LEGAL_AGREEMENT_CHECKBOX",
                    )
                  }}
                </router-link>
              </b-form-checkbox>
              <b-form-checkbox
                v-if="!haveAcc"
                v-model="$v.form.gdprAgreement.$model"
                :state="validateState('gdprAgreement')"
              >
                <router-link
                  :to="{ name: 'gdprTerms' }"
                  class="kt-link"
                  target="_blank"
                >
                  {{
                    $i18n.t(
                      "AUTH.REGISTRATION_AGREEMENT.GDPR_AGREEMENT_CHECKBOX",
                    )
                  }}
                </router-link>
              </b-form-checkbox>
              <div
                class="mt-3"
                v-if="stateAgreementInfo.Price"
                v-show="stateAgreementInfo.PriceVisibility == 1"
              >
                <span v-show="stateAgreementInfo.Text">
                  {{ stateAgreementInfo.Text }}
                </span>
                <span class="font-weight-bold">
                  {{
                    $i18n.t("AUTH.REGISTRATION_AGREEMENT.PRICE_TEXT") +
                      stateAgreementInfo.Price
                  }}
                </span>
                <br />
                <!-- Added a line break to separate Price and Price2 -->
                <span class="font-weight-bold">
                  {{ stateAgreementInfo.Price2 }}
                </span>
                <p class="font-weight-normal">
                  <span>
                    {{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.PRICE_TEXT2") }}
                  </span>
                </p>
                <b-form-checkbox
                  :state="validateState('checkedLegalAgreement')"
                  v-model="$v.form.checkedLegalAgreement.$model"
                >
                  {{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.PRICE_CHECKBOX") }}
                </b-form-checkbox>
              </div>
              <!--End::Checkbox -->
              <hr />
              <div class="mx-auto col-sm-12 col-lg-7">
                <div class="kt-login__actions mt-4">
                  <b-button
                    block
                    type="submit"
                    id="kt_submit"
                    variant="primary"
                  >
                    {{ DeactiveateText }}
                  </b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </b-card-body>
      <b-card-body>
        <div class="mx-auto col-sm-12 col-lg-12" v-if="Countdown">
          <div class="kt-login__actions mt-4">
            <b-button block type="submit" id="kt_submit" variant="primary">
              {{ DeactiveateText }}
            </b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { ADD_REGISTRATION_CODE, ADD_UNIT } from "@/store/auth.module";
import JwtService from "@/common/jwt.service";
import UIService from "@/common/ui.service";

export default {
  mixins: [validationMixin],
  name: "registrationAgreement",
  data() {
    return {
      errors: [],
      intervalid: null,
      Countdown: false,
      DeactiveateText: this.$i18n.t("AUTH.REGISTRATION_AGREEMENT.AGREE_BUTTON"),
      form: {
        checkedPrice: false,
        checkedLegalAgreement: false,
        gdprAgreement: false,
        checkedLegalAgreementAna: false,
      },
      haveAcc: false,
    };
  },
  validations: {
    form: {
      checkedPrice: {
        checked(val) {
          return this.stateAgreementInfo.PriceVisibility == 0 || val === true;
        },
      },
      checkedLegalAgreement: {
        sameAs: (val) => val === true,
      },
      checkedLegalAgreementAna: {
        sameAs: (val) => val === true,
      },
      gdprAgreement: {
        sameAs: (val) => val === true,
      },
    },
  },
  mounted() {
    UIService.changeSiteColorCustomer();
    if (!this.stateAgreementInfo) {
      this.$router.replace({ name: "login" });
    }
    //save to local storage because of new tab
    localStorage.setItem("registrationTerms", this.stateAgreementInfo.Content);

    //save reg. code to local storage because social network registration
    localStorage.setItem(
      "registrationCode",
      this.stateAgreementInfo.RegistrationCode,
    );

    //this helps with form validation in a case where the 'checkedLegalAgreement' part of the form doesnt render (when the Price property is empty)
    if (!this.stateAgreementInfo.Price) this.form.checkedLegalAgreement = true;
    if (this.stateAgreementInfo.PriceVisibility === 0)
      this.form.checkedLegalAgreement = true;

    if (this.$route.params.haveAccount) {
      this.haveAcc = true;
      this.form.checkedLegalAgreementAna = true;
      this.form.gdprAgreement = true;
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    startTimer() {
      let i = 1;
      this.intervalid = setInterval(() => {
        if (i == 10) {
          clearInterval(this.intervalid);
          this.$router.push({ name: "login" });
        }
        this.DeactiveateText =
          this.$i18n.t("ACCOUNTPROFILE.REDIRECT2") + (10 - i);
        i++;
      }, 1000);
      //this.timerId = this.intervalid._id
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.errors = [];
        this.errors.push(this.$i18n.t("AUTH.REGISTRATION_AGREEMENT.REQUIRED"));
        return;
      }
      //remove terms from local storage
      localStorage.removeItem("registrationTerms");

      UIService.addButtonSpinner("kt_submit");

      if (this.isAuthenticated || JwtService.getToken()) {
        //if is logged in add reg code
        const registrationCode = localStorage.getItem("registrationCode");
        localStorage.removeItem("registrationCode");
        if (this.haveAcc) {
          // pridanie priestoru vetva
          this.$store
            .dispatch(ADD_UNIT, {
              RegistrationCode: registrationCode,
            })
            .then(() => {
              if (!JwtService.getToken() || !JwtService.getUnit()) {
                UIService.removeButtonSpinner("kt_submit");
                this.DeactiveateText = this.$i18n.t("ACCOUNTPROFILE.REDIRECT2");
                this.Countdown = true;
                this.startTimer();
              } else {
                UIService.removeButtonSpinner("kt_submit");
                this.$router.push({ name: "dashboard" });
              }
            })
            .catch((error) => {
              UIService.removeButtonSpinner("kt_submit");
              console.log(error);
            });
        } else {
          // bezna registracia vetva
          this.$store
            .dispatch(ADD_REGISTRATION_CODE, {
              RegistrationCode: registrationCode,
              Email: JwtService.getUsername(),
            })
            .then(() => {
              UIService.removeButtonSpinner("kt_submit");
              this.$router.push({ name: "dashboard" });
            })
            .catch((error) => {
              UIService.removeButtonSpinner("kt_submit");
              console.log(error);
            });
        }
      } else {
        this.$router.push({ name: "register" });
      }
    },
  },
  computed: {
    ...mapState({
      stateAgreementInfo: (state) => state.auth.stateAgreementInfo,
    }),
    ...mapGetters(["layoutConfig", "isAuthenticated"]),
  },
};
</script>
