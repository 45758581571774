/**
 * Service to work ui elements
 */
import ColorService from "@/common/color.service";
import objectPath from "object-path";
import store from "@/store";

const UIService = {
  config: store.getters.layoutConfig(),

  addButtonSpinner(elementId) {
    let buttonElement = document.getElementById(elementId);
    buttonElement.classList.add(
      "kt-spinner",
      "kt-spinner--light",
      "kt-spinner--right",
    );
  },

  removeButtonSpinner(elementId) {
    let buttonElement = document.getElementById(elementId);
    buttonElement.classList.remove(
      "kt-spinner",
      "kt-spinner--light",
      "kt-spinner--right",
    );
  },

  changeSiteColor(hexColor) {
    let rgbBackgroundColor = ColorService.hexToRgb(hexColor);
    let hslBackgroundColor = ColorService.rgbToHsl(
      rgbBackgroundColor.r,
      rgbBackgroundColor.g,
      rgbBackgroundColor.b,
    );

    document.documentElement.style.setProperty(
      "--company-color",
      "rgb(" +
        +rgbBackgroundColor.r +
        "," +
        +rgbBackgroundColor.g +
        "," +
        +rgbBackgroundColor.b +
        ")",
    );

    document.documentElement.style.setProperty(
      "--company-color-val",
      +rgbBackgroundColor.r +
        "," +
        +rgbBackgroundColor.g +
        "," +
        +rgbBackgroundColor.b,
    );

    document.documentElement.style.setProperty(
      "--company-color-hsl",
      hslBackgroundColor.h + "," + hslBackgroundColor.s * 100 + "%",
    );

    document.documentElement.style.setProperty(
      "--company-color-hsl-l",
      hslBackgroundColor.l * 100 + "%",
    );
  },

  createChartLegend(chart) {
    let text = [];
    text.push('<ul class="' + chart.id + '-legend">');
    for (let i = 0; i < chart.data.datasets.length; i++) {
      text.push(
        '<li><span class="poschodoch-color-square" style="background-color:' +
          chart.data.datasets[i].backgroundColor +
          '"></span>',
      );
      if (chart.data.datasets[i].label) {
        text.push(chart.data.datasets[i].label);
      }
      text.push("</li>");
    }
    text.push("</ul>");
    return text.join("");
  },

  createDoughnutChartLegend(chart) {
    let text = [];
    text.push('<ul class="' + chart.id + '-legend">');
    for (let i = 0; i < chart.data.labels.length; i++) {
      text.push(
        '<li><span class="poschodoch-color-square" style="background-color:' +
          chart.data.datasets[0].backgroundColor[i] +
          '"></span>',
      );
      if (chart.data.labels[i]) {
        text.push(chart.data.labels[i]);
      }
      text.push("</li>");
    }
    text.push("</ul>");
    return text.join("");
  },
  changeSiteColorCustomer() {
    if (this.isCustomerPenta() == 1) {
      this.changeSiteColor(
        objectPath.get(this.config, "colors.state.brand-penta"),
      );
    } else {
      this.changeSiteColor(objectPath.get(this.config, "colors.state.brand"));
    }
  },
  isCustomerPenta() {
    let ret = 0;
    if (window.location.host.includes("penta")) ret = 1;
    return ret;
  },
  isCustomerEnbra() {
    let ret = 0;
    if (window.location.host.includes("enbra")) ret = 1;
    return ret;
  },
  CustomerName() {
    let ret = "poschodoch";
    if (window.location.host.includes("penta")) ret = "penta";
    if (window.location.host.includes("penta")) ret = "enbra";
    return ret;
  },
};

export default UIService;
