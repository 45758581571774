<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-bind:title="null" class="poschodoch-print">
          <template v-slot:title>
            <span class="kt-portlet__head-title">{{ title }}</span>
            <span class="poschodoch-inline-devided-text">
              {{ meterNumber }}
            </span>
            <span class="poschodoch-inline-devided-text">{{ meterType }}</span>
          </template>

          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-table
                v-if="items"
                show-empty
                hover
                :items="items"
                :fields="fields"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :refreshed="print()"
              ></b-table>

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "meterReadingDetail-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      meterId: null,
      fields: [
        {
          key: "MeterNumber",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.METERNUMBER"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "MeterType",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.METERTYPE"),
          sortable: false,
          class: "text-right",
        },
        {
          key: "ClimbingIron",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.CLIMBINGIRON"),
          sortable: false,
          class: "text-right",
        },
        {
          key: "State",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.STATE"),
          sortable: false,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "ReadingDate",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.READINGDATE"),
          sortable: false,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "ReadingCode",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.READINGCODE"),
          sortable: false,
          class: "text-right",
        },
        {
          key: "Description",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.DESCRIPTION"),
          sortable: false,
          class: "text-right",
        },
      ],
      meterNumber: null,
      meterType: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.meterId = this.$route.query.meterId;
    this.fetchData();
  },
  methods: {
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?menuId=" +
        this.menuId.toString() +
        "&meterId=" +
        this.meterId.toString();
      let tit = "";
      let docTitle = "";

      ApiService.setHeader();
      ApiService.get("Flat", "MeterReadingDetail" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.MeterReadingDetail;
          this.currentPage = 1;
          this.totalRows = this.items.length;

          this.meterNumber = data.MeterNumber;
          this.meterType = data.MeterType;
          tit = this.title;
        },
      );

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
