<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12 poschodoch-repair-funds-content">
        <!--Begin::Repair Fund-->
        <KTPortlet v-bind:title="null" class="poschodoch-print">
          <template v-slot:title>
            <span class="kt-portlet__head-title">{{ title }}</span>
            <span class="poschodoch-inline-devided-text mb-2">
              {{ titleAddition }}
            </span>
          </template>
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Parameters -->
              <b-row>
                <b-col sm="6" md="6" lg="3" xl="3">
                  <label>
                    {{ $t("REPAIRFUND.PARAMETER_YEAR") }} {{ year }}
                  </label>
                </b-col>
              </b-row>
              <b-row v-if="gate">
                <b-col sm="6" md="6" lg="3" xl="3">
                  <label>{{ $t("REPAIRFUND.FILTER.GATE") }}: {{ gate }}</label>
                </b-col>
              </b-row>
              <b-row v-if="vs">
                <b-col sm="6" md="6" lg="3" xl="3">
                  <label>{{ $t("REPAIRFUND.FILTER.VS") }}: {{ vs }}</label>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="6" md="6" lg="3" xl="3">
                  <label>
                    {{ $t("REPAIRFUND.FILTER.VIEW") }}: {{ viewOptions[fv] }}
                  </label>
                </b-col>
              </b-row>
              <!--End::Parameters -->

              <b-row class="mb-4" style="white-space: pre-wrap">
                <b-col sm="12" md="12" lg="2">
                  <span>
                    {{ $t("REPAIRFUND.OPENBALANCE") }}: {{ openingBalance }}
                  </span>
                </b-col>
                <b-col sm="12" md="12" lg="2">
                  <span>
                    {{ $t("REPAIRFUND.PRODUCTION") }}: {{ production }}
                  </span>
                </b-col>
                <b-col sm="12" md="12" lg="2">
                  <span>{{ $t("REPAIRFUND.DRAWING") }}: {{ drawing }}</span>
                </b-col>
                <b-col sm="12" md="12" lg="2">
                  <span>
                    {{ $t("REPAIRFUND.FINBALANCE") }}: {{ finalBalance }}
                  </span>
                </b-col>
              </b-row>

              <b-row class="mb-2" style="font-size: 11px">
                <b-col sm="4">
                  <table
                    size="sm"
                    role="table"
                    aria-busy="false"
                    aria-colcount="3"
                    class="table b-table table-hover"
                  >
                    <thead role="rowgroup">
                      <tr role="row" class="poschodoch-repFund-monthTable-row">
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class="text-left"
                        >
                          {{ $t("REPAIRFUND.PERIOD") }}
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="2"
                          class="text-right"
                        >
                          {{ $t("REPAIRFUND.PRODUCTION") }}
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="3"
                          class="text-right"
                        >
                          {{ $t("REPAIRFUND.DRAWING") }}
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class="text-right"
                        >
                          {{ $t("REPAIRFUND.BALANCE") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr
                        role="row"
                        aria-rowindex="1"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_1") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[0],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[0],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[0],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="2"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_2") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[1],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[1],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[1],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="3"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_3") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[2],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[2],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[2],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="4"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_4") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[3],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[3],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[3],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="5"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_5") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[4],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[4],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[4],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="6"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_6") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[5],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[5],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[5],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="7"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_7") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[6],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[6],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[6],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="8"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_8") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[7],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[7],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[7],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="9"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_9") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[8],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[8],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[8],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="10"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_10") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[9],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[9],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[9],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="11"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_11") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[10],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[10],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[10],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="12"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_12") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[11],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[11],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[11],
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col
                  sm="8"
                  v-if="chartOptions"
                  class="poschodoch-repFund-chart"
                >
                  <div class="mt-2 mt-lg-5">
                    <Chart
                      ref="repFund-chart"
                      v-for="c in charts"
                      :key="c"
                      v-bind:options="chartOptions"
                      height="200"
                      width="0"
                    ></Chart>
                  </div>

                  <!--Begin::Legend -->
                  <div
                    class="poschodoch-legend"
                    style="-webkit-print-color-adjust: exact"
                    id="repFund-chart-legend"
                  />
                  <!--End::Legend -->
                </b-col>
              </b-row>

              <!--Begin::Main table element original (+ and -) ; Count of rows per page ; Pagination -->
              <div v-if="fv == 0">
                <b-table
                  show-empty
                  hover
                  size="sm"
                  :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                  :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-direction="sortDirection"
                  :refreshed="print()"
                >
                  <template v-slot:cell(DocCount)="data">
                    <a
                      href="javascript:void(0)"
                      v-if="data.value > 1"
                      class="text-center poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      v-if="data.value == 1"
                      class="text-center poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-download poschodoch-icon-medium"></i>
                    </a>
                  </template>
                  <template v-slot:custom-foot>
                    <tr class="poschodoch-summary-row">
                      <td>
                        {{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}
                      </td>
                      <td></td>
                      <td></td>
                      <td class="text-right">
                        <span v-bind:class="{ 'color-danger': sum < 0 }">
                          {{ getFormatService().formatNumberToLocal(sum) }}
                        </span>
                      </td>
                      <td class="text-center"></td>
                    </tr>
                  </template>
                </b-table>
              </div>
              <!--End::Main table element original (+ and -) ; Count of rows per page ; Pagination -->

              <!--Begin::Main table element plus (+) ; Count of rows per page ; Pagination -->
              <div v-if="fv == 1 || fv == 3">
                <b-table
                  show-empty
                  hover
                  size="sm"
                  :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                  :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                  :items="itemsPlus"
                  :fields="fieldsPlus"
                  :sort-by.sync="sortBy"
                  :sort-direction="sortDirection"
                  :refreshed="print()"
                >
                  <template v-slot:cell(DocCount)="data">
                    <a
                      href="javascript:void(0)"
                      v-if="data.value > 1"
                      class="text-center poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      v-if="data.value == 1"
                      class="text-center poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-download poschodoch-icon-medium"></i>
                    </a>
                  </template>
                  <template v-slot:custom-foot>
                    <tr class="poschodoch-summary-row">
                      <td>
                        {{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}
                      </td>
                      <td></td>
                      <td></td>
                      <td class="text-right">
                        <span v-bind:class="{ 'color-danger': sumPlus < 0 }">
                          {{ getFormatService().formatNumberToLocal(sumPlus) }}
                        </span>
                      </td>
                      <td class="text-center"></td>
                    </tr>
                  </template>
                </b-table>
              </div>
              <!--End::Main table element plus (+) ; Count of rows per page ; Pagination -->

              <!--Begin::Main table element minus (-) ; Count of rows per page ; Pagination -->
              <div v-if="fv == 2 || fv == 3">
                <b-table
                  show-empty
                  hover
                  size="sm"
                  :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                  :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                  :items="itemsMinus"
                  :fields="fieldsMinus"
                  :sort-by.sync="sortBy"
                  :sort-direction="sortDirection"
                  :refreshed="print()"
                >
                  <template v-slot:cell(DocCount)="data">
                    <a
                      href="javascript:void(0)"
                      v-if="data.value > 1"
                      class="text-center poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      v-if="data.value == 1"
                      class="text-center poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-download poschodoch-icon-medium"></i>
                    </a>
                  </template>
                  <template v-slot:custom-foot>
                    <tr class="poschodoch-summary-row">
                      <td>
                        {{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}
                      </td>
                      <td></td>
                      <td></td>
                      <td class="text-right">
                        <span v-bind:class="{ 'color-danger': sumMinus < 0 }">
                          {{ getFormatService().formatNumberToLocal(sumMinus) }}
                        </span>
                      </td>
                      <td class="text-center"></td>
                    </tr>
                  </template>
                </b-table>
              </div>
              <!--End::Main table element minus (-) ; Count of rows per page ; Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col sm="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Repair Fund-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import Chart from "@/views/components/Chart.vue";
import { mapGetters } from "vuex";

export default {
  name: "repairFund-print",
  components: {
    KTPortlet,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      titleAddition: null,
      description: null,
      menuId: null,
      items: [],
      itemsPlus: [],
      itemsMinus: [],
      sum: 0,
      sumPlus: 0,
      sumMinus: 0,
      lastSelectedYear: null,
      finalBalance: null,
      openingBalance: null,
      production: null,
      drawing: null,
      productionCells: [],
      drawingCells: [],
      balanceCells: [],
      fields: [
        {
          key: "Date",
          label: this.$i18n.t("REPAIRFUND.TABLE.DATE"),
          sortable: false,
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Evidence",
          label: this.$i18n.t("REPAIRFUND.TABLE.EVIDENCE"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Description",
          label: this.$i18n.t("REPAIRFUND.TABLE.DESCRIPTION"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("REPAIRFUND.TABLE.AMOUNT"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center poschodoch-repFund-docCount",
        },
      ],
      fieldsPlus: [
        {
          key: "Date",
          label: this.$i18n.t("REPAIRFUND.TABLE.DATE"),
          sortable: false,
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Evidence",
          label: this.$i18n.t("REPAIRFUND.TABLE.EVIDENCE"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Description",
          label: this.$i18n.t("REPAIRFUND.TABLE.DESCRIPTION"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("REPAIRFUND.TABLE.AMOUNT"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._rowVariant = "row-hide";
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center poschodoch-repFund-docCount",
        },
      ],
      fieldsMinus: [
        {
          key: "Date",
          label: this.$i18n.t("REPAIRFUND.TABLE.DATE"),
          sortable: false,
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Evidence",
          label: this.$i18n.t("REPAIRFUND.TABLE.EVIDENCE"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Description",
          label: this.$i18n.t("REPAIRFUND.TABLE.DESCRIPTION"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("REPAIRFUND.TABLE.AMOUNT"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            } else {
              item._rowVariant = "row-hide";
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center poschodoch-repFund-docCount",
        },
      ],
      year: null,
      years: null,
      sortBy: "",
      sortDirection: "desc",
      viewOptions: [
        this.$i18n.t("REPAIRFUND.VIEWOPTIONS.1"),
        this.$i18n.t("REPAIRFUND.VIEWOPTIONS.2"),
        this.$i18n.t("REPAIRFUND.VIEWOPTIONS.3"),
        this.$i18n.t("REPAIRFUND.VIEWOPTIONS.4"),
      ],
      chartOptions: null,
      charts: [1],
      fv: null,
      gate: null,
      vs: null,
      selYear: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fv = this.$route.query.fv;
    this.gate = this.$route.query.Gate;
    this.vs = this.$route.query.VS;
    this.selYear = this.$route.query.year;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    addCurrencyWSpace(data) {
      return (
        " " +
        data +
        " " +
        FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    createChart() {
      this.charts = [this.charts[0] + 1];
      this.chartOptions = {
        type: "line",
        data: {
          datasets: [
            {
              data: this.productionCells,
              label: this.$i18n.t("REPAIRFUND.PRODUCTION"),
              fill: false,
              borderColor: "#0abb87",
              borderWidth: 3,
              backgroundColor: "#0abb87",
            },
            {
              data: this.drawingCells,
              label: this.$i18n.t("REPAIRFUND.DRAWING"),
              fill: false,
              borderColor: this.layoutConfig("colors.state.danger"),
              borderWidth: 3,
              backgroundColor: this.layoutConfig("colors.state.danger"),
            },
            {
              data: this.balanceCells,
              label: this.$i18n.t("REPAIRFUND.BALANCE"),
              fill: false,
              borderColor: this.layoutConfig("colors.state.brand"),
              borderWidth: 3,
              backgroundColor: this.layoutConfig("colors.state.brand"),
            },
          ],
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "repFund-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  fontSize: 11,
                  display: true,
                  labelString: this.$i18n.t("REPAIRFUND.MONTHS"),
                },
                ticks: {
                  fontSize: 11,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  fontSize: 11,
                  display: false,
                  labelString: FormatService.formatCurrency(
                    JwtService.getCurrency(),
                  ),
                },
                ticks: {
                  fontSize: 11,
                  min: 0,
                  callback: function(value) {
                    return (
                      FormatService.formatNumberToLocalNoFractionDigits(value) +
                      " " +
                      FormatService.formatCurrency(JwtService.getCurrency())
                    );
                  },
                },
              },
            ],
          },
        },
      };
    },
    fetchData() {
      this.items = null;
      let tit = "";
      let docTitle = "";
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.selYear != null)
        parameters += "&year=" + this.selYear.toString();

      if (this.gate != null) parameters += "&Gate=" + this.gate.toString();
      if (this.vs != null) parameters += "&VS=" + this.vs.toString();

      ApiService.setHeader();
      ApiService.get("Object", "RepairFund" + parameters).then(({ data }) => {
        this.transformData(data.RepairFund);

        this.year = data.Year;

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.RepairFund;
        this.finalBalance = this.addCurrencyWSpace(
          this.getFormatService().formatNumberToLocal(data.FinalBalance),
        );
        this.openingBalance = this.addCurrencyWSpace(
          this.getFormatService().formatNumberToLocal(data.OpeningBalance),
        );
        this.production = this.addCurrencyWSpace(
          this.getFormatService().formatNumberToLocal(data.Production),
        );
        this.drawing = this.addCurrencyWSpace(
          this.getFormatService().formatNumberToLocal(data.Drawing),
        );

        this.productionCells = [
          data.Production01,
          data.Production02,
          data.Production03,
          data.Production04,
          data.Production05,
          data.Production06,
          data.Production07,
          data.Production08,
          data.Production09,
          data.Production10,
          data.Production11,
          data.Production12,
        ];
        this.drawingCells = [
          data.Drawing01,
          data.Drawing02,
          data.Drawing03,
          data.Drawing04,
          data.Drawing05,
          data.Drawing06,
          data.Drawing07,
          data.Drawing08,
          data.Drawing09,
          data.Drawing10,
          data.Drawing11,
          data.Drawing12,
        ];
        this.balanceCells = [
          data.Balance01,
          data.Balance02,
          data.Balance03,
          data.Balance04,
          data.Balance05,
          data.Balance06,
          data.Balance07,
          data.Balance08,
          data.Balance09,
          data.Balance10,
          data.Balance11,
          data.Balance12,
        ];
        this.createChart();

        this.titleAddition =
          this.$i18n.t("REPAIRFUND.TITLEADD") +
          FormatService.formatDateToLocal(data.IssueDate) +
          ": " +
          this.addCurrency(
            this.getFormatService().formatNumberToLocal(data.FinalBalance),
          );

        tit = this.title;
      });

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
    transformData(items) {
      let z = 0,
        x = 0;
      (this.sum = 0), (this.sumPlus = 0), (this.sumMinus = 0);
      (this.itemsPlus = []), (this.itemsMinus = []);

      for (let i = 0; i < items.length; i++) {
        if (items[i].Amount > 0) {
          this.itemsPlus[z] = items[i];
          z++;
          this.sumPlus += parseFloat(
            this.getFormatService().formatNumberToLocal(items[i].Amount),
          );
        } else if (items[i].Amount < 0) {
          this.itemsMinus[x] = items[i];
          x++;
          this.sumMinus += parseFloat(
            this.getFormatService().formatNumberToLocal(items[i].Amount),
          );
        }
        this.sum += parseFloat(
          this.getFormatService().formatNumberToLocal(items[i].Amount),
        );
      }
    },
  },
};
</script>
