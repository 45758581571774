<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <!--Begin::reset password-->
      <KTPortlet
        v-show="showMain"
        style="background-color: rgba(255, 255, 255, 0.85)"
      >
        <template v-slot:body>
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="kt-login__title mb-4">
                <h3 class="text-center">
                  {{ $i18n.t("RESETPASSWORD.TITLE") }}
                </h3>
              </div>
              <div
                role="alert"
                v-bind:class="{ show: errors && errors.length }"
                class="alert fade alert-danger mt-2 mb-2"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>
              <div class="d-flex mb-4 justify-content-center">
                <span style="width: 14rem; margin-top: 0.65rem !important">
                  {{ $i18n.t("ACCOUNTPROFILE.PWD_NEW") }}
                </span>
                <div class="mr-5" style="width: 50%">
                  <b-form-input
                    id="input-new-Password"
                    :state="validateNewPWD"
                    v-model="newPassword"
                    :type="visibilityPWD"
                    class="ml-3 mr-3"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validateNewPWD" class="ml-3">
                    {{ $i18n.t("ACCOUNTPROFILE.VALIDATION.PWD") }}
                  </b-form-invalid-feedback>
                </div>
                <div class="mt-2" style="width: 2%">
                  <a
                    @click="showPassword()"
                    style="opacity: 0.9"
                    class="notunderlined ml-0"
                    v-if="visibilityPWD == 'password'"
                  >
                    <i class="fas fa-eye-slash poschodoch-icon-medium"></i>
                  </a>
                  <a
                    @click="hidePassword()"
                    style="opacity: 0.9"
                    class="notunderlined ml-0"
                    v-if="visibilityPWD == 'text'"
                  >
                    <i class="fas fa-eye poschodoch-icon-medium"></i>
                  </a>
                </div>
              </div>
              <div class="d-flex mb-4 justify-content-center">
                <span style="width: 14rem; margin-top: 0.65rem !important">
                  {{ $i18n.t("ACCOUNTPROFILE.PWD_NEWCONFIRM") }}
                </span>
                <div class="mr-5" style="width: 50%">
                  <b-form-input
                    id="input-newAgain-Password"
                    :state="validateNewConfirmPWD"
                    v-model="newPWDConfirm"
                    :type="visibilityPWD"
                    class="ml-3 mr-3"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="validateNewConfirmPWD"
                    class="ml-3"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.VALIDATION.PWD_CONFIRM") }}
                  </b-form-invalid-feedback>
                </div>
                <div class="mt-2" style="width: 2%">
                  <a
                    @click="showPassword()"
                    style="opacity: 0.9"
                    class="notunderlined ml-0"
                    v-if="visibilityPWD == 'password'"
                  >
                    <i class="fas fa-eye-slash poschodoch-icon-medium"></i>
                  </a>
                  <a
                    @click="hidePassword()"
                    style="opacity: 0.9"
                    class="notunderlined ml-0"
                    v-if="visibilityPWD == 'text'"
                  >
                    <i class="fas fa-eye poschodoch-icon-medium"></i>
                  </a>
                </div>
              </div>
              <div class="row justify-content-center">
                <b-button
                  @click="resetPWD(newPassword)"
                  id="reset_pwd"
                  variant="primary"
                  :disabled="!(validateNewPWD && validateNewConfirmPWD)"
                  style="width: 22rem; height: fit-content"
                >
                  {{ $i18n.t("RESETPASSWORD.BUTTON") }}
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </KTPortlet>
      <KTPortlet
        v-show="showCFM"
        style="background-color: rgba(255, 255, 255, 0.85)"
      >
        <template v-slot:body>
          <div class="row justify-content-center">
            <div class="col-lg-9 col-sm-12">
              <div class="d-flex mb-4 justify-content-center">
                <span>
                  {{ $i18n.t("RESETPASSWORD.TEXT") }}
                </span>
              </div>
              <div class="row justify-content-center">
                <b-button
                  @click="okButtonPWD()"
                  variant="primary"
                  style="
                    width: 22rem;
                    height: fit-content;
                    color: #ffffff !important;
                  "
                >
                  {{ $i18n.t("ACCOUNTPROFILE.BUTTON.OK") }}
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </KTPortlet>
      <!--End::reset password-->
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
//import { mapState } from "vuex";
import UIService from "@/common/ui.service";

export default {
  name: "resetPassword",
  components: {
    KTPortlet,
  },
  data() {
    return {
      userID: null,
      newPassword: null,
      newPWDConfirm: null,
      visibilityPWD: "password",
      showCFM: false,
      showMain: true,
      errors: [],
    };
  },
  computed: {
    validateNewPWD() {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(
        this.newPassword,
      );
    },
    validateNewConfirmPWD() {
      if (this.newPassword != this.newPWDConfirm) return false;
      else
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(
          this.newPWDConfirm,
        );
    },
    /* ...mapState({
      errors: (state) => state.auth.errors,
    }),*/
  },
  mounted() {
    UIService.changeSiteColorCustomer();
    this.userID = this.$route.query.id;
  },
  methods: {
    showPassword() {
      this.visibilityPWD = "text";
    },
    hidePassword() {
      this.visibilityPWD = "password";
    },
    resetPWD(newPWD) {
      let postContent = {
        UserId: this.userID,
        NewPassword: newPWD,
      };
      this.errors = [];
      UIService.addButtonSpinner("reset_pwd");
      ApiService.setHeader();
      ApiService.post("Auth/ResetPassword", postContent)
        .then(() => {
          this.showCFM = true;
          this.showMain = false;
          UIService.removeButtonSpinner("reset_pwd");
        })
        .catch((response) => {
          if (response.response.data.error)
            this.errors.push(response.response.data.error);
          else this.errors.push("Niečo sa pokazilo. Pracujeme na tom...");
          UIService.removeButtonSpinner("reset_pwd");
        });
    },
    okButtonPWD() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
