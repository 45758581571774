<template>
  <b-col xs="12" sm="10" lg="5" class="mx-auto">
    <b-card
      text-variant="black"
      style="background-color: rgba(255, 255, 255, 0.85)"
    >
      <b-card-body>
        <!--begin::Body-->
        <div class="kt-login__body">
          <h3>{{ $t("GOOGLE.TITLE") }}</h3>
          <!-- <div class="mt-4">
            <Loader />
          </div> -->
          <div
            role="alert"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-4 mb-2"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <div class="mt-4">
            <b-button block variant="secondary" @click="backToLogin">
              {{ $i18n.t("AUTH.GENERAL.BACK_TOLOGIN") }}
            </b-button>
          </div>
        </div>
        <!--end::Body-->
      </b-card-body>
    </b-card>
  </b-col>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import UIService from "@/common/ui.service";
import { CHANGE_UNIT, LOGIN_WITH_GOOGLE } from "@/store/auth.module";
// import Loader from "@/views/components/Loader.vue";

export default {
  name: "google",
  components: {
    // Loader
  },
  data() {
    return {
      code: null,
      redirectUrl: null,
    };
  },
  mounted: function() {
    //set default brand color
    UIService.changeSiteColorCustomer();
    this.code = this.$route.query.code;
    this.redirectUrl = window.location.protocol + "//" + window.location.host;
    this.loginWithGoogle();

    //redirect to dashboard if already logged in
    if (this.isAuthenticated) {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    loginWithGoogle() {
      // send login with google request
      this.$store
        .dispatch(LOGIN_WITH_GOOGLE, {
          code: this.code,
          redirectUrl: this.redirectUrl,
        })
        // go to which page after successfully login
        .then((data) => {
          if (data.length == 1) {
            // user has only one unit
            let obj = {
              unitID: data[0].UnitId,
              portalID: data[0].PortalId,
              unitType: data[0].UnitType,
            };

            this.$store.dispatch(CHANGE_UNIT, obj).then(() => {
              window.location = this.redirectUrl + "/dashboard";
            });
          } else {
            // unit selection if user has more than 1 unit available
            window.location = this.redirectUrl + "/Units";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    backToLogin() {
      window.location = this.redirectUrl + "/login";
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["layoutConfig", "isAuthenticated"]),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    },
  },
};
</script>
