<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Filter-->
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <b-container fluid>
              <b-row v-if="!hidden">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div
                    class="d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center"
                  >
                    <ul
                      class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills m-0 mr-0 mr-md-3"
                      role="tablist"
                    >
                      <li class="nav-item" v-for="index in years" :key="index">
                        <a
                          v-on:click="fetchData(index)"
                          data-tab="0"
                          class="nav-link"
                          href="javascript:void(0)"
                          v-bind:class="{ active: index == year }"
                        >
                          {{ index }}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <b-button
                    variant="secondary"
                    class="ml-md-5 mt-3 mt-md-0"
                    v-on:click="
                      hidden2 = !hidden2;
                      hidden = !hidden;
                      fetchData(year, inputDateFrom, inputDateTo);
                    "
                  >
                    {{ $i18n.t("MONTHLY_CONSUMPTION.BUTTON1") }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="hidden2">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div class="d-flex flex-row">
                    <div
                      class="ml-md-2 mt-3 mt-md-0 d-flex flex-sm-row flex-column"
                    >
                      <span
                        class="d-flex flex-row align-items-center mr-sm-3 mr-0"
                      >
                        <label for="dF" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("MONTHLY_CONSUMPTION.FROM") }}
                        </label>
                        <b-form-input
                          id="dF"
                          v-model="inputDateFrom"
                          type="date"
                        ></b-form-input>
                      </span>
                      <span
                        class="d-flex flex-row align-items-center mt-sm-0 mt-3"
                      >
                        <label for="dT" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("MONTHLY_CONSUMPTION.TO") }}
                        </label>
                        <b-form-input
                          id="dT"
                          v-model="inputDateTo"
                          type="date"
                        ></b-form-input>
                      </span>

                      <div
                        class="d-flex align-items-sm-center align-self-center"
                      >
                        <b-button
                          variant="primary"
                          class="ml-sm-3 ml-0 mt-3 mt-sm-0"
                          v-on:click="
                            fetchData(null, inputDateFrom, inputDateTo)
                          "
                        >
                          {{ $i18n.t("MONTHLY_CONSUMPTION.BUTTONOK") }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-sm-center">
                    <b-button
                      variant="secondary"
                      class="ml-md-2 ml-sm-3 mt-3 mt-md-0"
                      v-on:click="
                        hidden2 = !hidden2;
                        hidden = !hidden;
                        fetchData(inputDateTo.substring(0, 4));
                      "
                    >
                      {{ $i18n.t("MONTHLY_CONSUMPTION.BUTTON2") }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTPortlet>
        <!--End::Filter-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Monthly Consumption Cold water-->
        <KTPortlet v-bind:title="subTitle">
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="isEmptyWatter === 0">
            <TableContextMenu
              :functionCSV="downloadWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container
              fluid
              v-if="isEmptyWatter === 1"
              style="color:#616365;"
            >
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="isEmptyWatter === 0">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptions"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '800px', height: '300px' }">
                  <Chart
                    ref="monthly-cold-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptions"
                    height="300"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkKorigST"
                  v-on:change="
                    korigovaneST = !korigovaneST;
                    fetchData(year, inputDateFrom, inputDateTo);
                  "
                  size="md"
                  style
                  class="ml-3"
                  v-bind:checked="korigovaneST"
                  switch
                >
                  {{ $i18n.t("MONTHLY_CONSUMPTION.SHOW_CORRECTED") }}
                </b-form-checkbox>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkColdW0"
                    v-on:change="showHideDataset(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legH">
                    {{ $i18n.t("MONTHLY_CONSUMPTION.CHART_WATER.HOT_WATER") }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkColdW1"
                    v-on:change="showHideDataset(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legC">
                    {{ $i18n.t("MONTHLY_CONSUMPTION.CHART_WATER.COLD_WATER") }}
                  </span>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptions && isEmptyWatter === 0">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Monthly Consumption Cold water-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Monthly Consumption Heat-->
        <KTPortlet v-bind:title="subTitleRooms">
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="isEmptyHeat === 0">
            <TableContextMenu
              :functionCSV="downloadRoomsCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="isEmptyHeat === 1" style="color:#616365;">
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="isEmptyHeat === 0">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsRooms"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '800px', height: '300px' }">
                  <Chart
                    ref="monthly-consumption-rooms-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsRooms"
                    height="300"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkKorigU"
                  v-on:change="
                    korigovaneU = !korigovaneU;
                    fetchData(year, inputDateFrom, inputDateTo);
                  "
                  size="md"
                  style
                  class="ml-3"
                  v-bind:checked="korigovaneU"
                  switch
                >
                  {{ $i18n.t("MONTHLY_CONSUMPTION.SHOW_CORRECTED") }}
                </b-form-checkbox>
              </div>
              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div
                  v-for="(label, i) in chartCustomLegendLabelsHeat"
                  :key="label"
                  :index="i"
                  style="display: flex"
                  class="mr-5"
                >
                  <b-form-checkbox
                    v-on:change="showHideDatasetRooms(i)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span :class="['legHe' + i]">{{ label }}</span>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsRooms && isEmptyHeat === 0">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Monthly Consumption Heat-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet>
          <template v-slot:body>
            <b-container fluid v-if="description">
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import ColorService from "@/common/color.service";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";

export default {
  name: "monthly-consumption",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      description: null,
      subTitle: null,
      subTitleRooms: null,
      menuId: null,
      hidden: false,
      hidden2: false,
      items: null,
      itemsRooms: null,
      year: null,
      years: null,
      inputDateFrom: null,
      inputDateTo: null,
      chartOptions: null,
      chartOptionsRooms: null,
      chartLabels: [],
      korigovaneST: false,
      korigovaneU: false,
      chartDatasetLabelHotWater: this.$i18n.t(
        "MONTHLY_CONSUMPTION.CHART_WATER.HOT_WATER",
      ),
      chartDataHotWater: [],
      charts: [1],
      chartDatasetLabelColdWater: this.$i18n.t(
        "MONTHLY_CONSUMPTION.CHART_WATER.COLD_WATER",
      ),
      chartDataColdWater: [],
      chartTooltipsColdWater: [],
      chartTooltipsHotWater: [],
      chartTooltipsHeat: [],
      chartCustomLegendLabelsHeat: [],
      chartRooms: [],
      datasetsRooms: [],
      isEmptyHeat: null,
      isEmptyWatter: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    showHideDataset(index) {
      this.chartOptions.data.datasets[index].hidden = !this.chartOptions.data
        .datasets[index].hidden;
      this.chartOptions.chart.update();
    },
    showHideDatasetRooms(index) {
      this.chartOptionsRooms.data.datasets[index].hidden = !this
        .chartOptionsRooms.data.datasets[index].hidden;
      this.chartOptionsRooms.chart.update();
    },
    createChart() {
      this.charts = [this.charts[0] + 1];
      this.chartOptions = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.chartDataHotWater,
              label: this.chartDatasetLabelHotWater,
              fill: false,
              borderColor: this.layoutConfig("colors.state.danger"),
              backgroundColor: this.layoutConfig("colors.state.danger"),
            },
            {
              data: this.chartDataColdWater,
              label: this.chartDatasetLabelColdWater,
              fill: false,
              borderColor: this.layoutConfig("colors.state.info"),
              backgroundColor: this.layoutConfig("colors.state.info"),
            },
          ],
          labels: this.chartLabels,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem) => {
                let title;
                if (tooltipItem[0].datasetIndex == 0)
                  title = this.chartTooltipsHotWater[tooltipItem[0].index];
                if (tooltipItem[0].datasetIndex == 1)
                  title = this.chartTooltipsColdWater[tooltipItem[0].index];

                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "MONTHLY_CONSUMPTION.CHART_WATER.AX_X",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "MONTHLY_CONSUMPTION.CHART_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartRooms() {
      //this.charts = [this.charts[0] + 1];
      this.chartOptionsRooms = {
        type: "bar",
        data: {
          datasets: this.datasetsRooms,
          labels: this.chartLabelsRooms,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem) => {
                let title = this.chartTooltipsHeat[tooltipItem[0].index];

                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "MONTHLY_CONSUMPTION.CHART_ROOMS.AX_X",
                  ),
                },
                stacked: true,
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "MONTHLY_CONSUMPTION.CHART_ROOMS.AX_Y",
                  ),
                },
                stacked: true,
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    setYearRange(yearFrom, yearTo) {
      //if (this.years == null) {
      if (yearFrom == null) {
        yearFrom = yearTo;
      }
      var years_p = [];
      for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
        years_p.push(i);
      }

      if (this.years == null) {
        this.years = years_p;
      } else {
        var c = this.years.concat(years_p);
        var d = c.filter((item, pos) => c.indexOf(item) === pos);
        d = d.sort();
        this.years = d;
      }
    },
    transformData(data) {
      let consumptionHotWater = [];
      let consumptionColdWater = [];
      let labels = [];
      let tooltipsCold = [];
      let tooltipsHot = [];
      this.isEmptyWatter = 1;
      for (let i = 0; i < data.Consumption.length; i++) {
        if (data.Consumption[i].Type == "T") {
          consumptionHotWater.push({
            x: data.Consumption[i].Label.substring(
              0,
              data.Consumption[i].Label.indexOf("/"),
            ),
            y: data.Consumption[i].Consumption,
          });

          if (data.Consumption[i].Consumption != null) {
            this.isEmptyWatter = 0;
          }

          tooltipsHot.push(
            this.getFormatService().getMonthName(
              data.Consumption[i].Label.substring(
                0,
                data.Consumption[i].Label.indexOf("/"),
              ),
            ) +
              data.Consumption[i].Label.substring(
                data.Consumption[i].Label.indexOf("/"),
                data.Consumption[i].Label.length,
              ),
          );
        }
        if (data.Consumption[i].Type == "S") {
          consumptionColdWater.push({
            x: data.Consumption[i].Label.substring(
              0,
              data.Consumption[i].Label.indexOf("/"),
            ),
            y: data.Consumption[i].Consumption,
          });
          if (data.Consumption[i].Consumption != null) {
            this.isEmptyWatter = 0;
          }
          tooltipsCold.push(
            this.getFormatService().getMonthName(
              data.Consumption[i].Label.substring(
                0,
                data.Consumption[i].Label.indexOf("/"),
              ),
            ) +
              data.Consumption[i].Label.substring(
                data.Consumption[i].Label.indexOf("/"),
                data.Consumption[i].Label.length,
              ),
          );
        }
      }

      var currentDate = new Date(data.DateFrom);
      while (currentDate <= new Date(data.DateTo)) {
        labels.push(currentDate.getMonth() + 1);
        currentDate = new Date(
          currentDate.setMonth(currentDate.getMonth() + 1),
        );
      }

      this.chartLabels = labels;
      this.chartDataHotWater = consumptionHotWater;
      this.chartDataColdWater = consumptionColdWater;
      this.chartTooltipsColdWater = tooltipsCold;
      this.chartTooltipsHotWater = tooltipsHot;
    },
    transformDataRooms(data) {
      let roomLabels = [];
      let roomData = {};
      let labels = [];
      let roomDatasets = [];
      let tooltipsHeat = [];
      this.isEmptyHeat = 1;

      let baseColor = ColorService.hexToRgb(
        this.layoutConfig("colors.state.brand"),
      );

      for (let i = 0; i < data.Consumption.length; i++) {
        if (!roomLabels.includes(data.Consumption[i].Type)) {
          roomLabels.push(data.Consumption[i].Type);
          roomData[data.Consumption[i].Type] = [];
        }
        if (data.Consumption[i].Consumption != null) {
          this.isEmptyHeat = 0;
        }
        roomData[data.Consumption[i].Type].push({
          x: data.Consumption[i].Label.substring(
            0,
            data.Consumption[i].Label.indexOf("/"),
          ),
          y: data.Consumption[i].Consumption,
        });

        tooltipsHeat.push(
          this.getFormatService().getMonthName(
            data.Consumption[i].Label.substring(
              0,
              data.Consumption[i].Label.indexOf("/"),
            ),
          ) +
            data.Consumption[i].Label.substring(
              data.Consumption[i].Label.indexOf("/"),
              data.Consumption[i].Label.length,
            ),
        );
      }

      var currentDate = new Date(data.DateFrom);
      while (currentDate <= new Date(data.DateTo)) {
        labels.push(currentDate.getMonth() + 1);
        currentDate = new Date(
          currentDate.setMonth(currentDate.getMonth() + 1),
        );
      }

      for (let i = 0; i < roomLabels.length; i++) {
        let room = roomLabels[i];
        let ratio = 1 - 0.5 * (i / roomLabels.length);
        let color =
          "rgb(" +
          parseInt(baseColor.r * ratio) +
          "," +
          parseInt(baseColor.g * ratio) +
          "," +
          parseInt(baseColor.b * ratio) +
          ")";

        roomDatasets.push({
          data: roomData[room],
          label: room,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        });

        this.datasetsRooms = roomDatasets;
        this.chartRooms = roomLabels;
      }

      this.chartTooltipsHeat = tooltipsHeat;
      this.chartLabelsRooms = labels;
      this.chartCustomLegendLabelsHeat = roomLabels;
    },
    downloadWaterCSV() {
      this.downloadCSV(
        this.subTitle,
        ["", this.chartDatasetLabelHotWater, this.chartDatasetLabelColdWater],
        this.chartLabels,
        this.items,
      );
    },
    downloadRoomsCSV() {
      this.downloadCSV(
        this.subTitleRooms,
        ["", ...this.chartRooms],
        this.chartLabelsRooms,
        this.itemsRooms,
      );
    },
    downloadCSV(title, header, months, items) {
      let values = items.map(function(v) {
        return [
          v.Type,
          new Date(v.Date).getMonth() + 1,
          FormatService.formatNumberToLocalForCSV(v.Consumption),
        ];
      });

      let result = FileService.generateChartCSV(header, months, values);
      let fileName = title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(selectedYear = null, sDateFrom, sDateTo) {
      // water
      this.items = null;
      this.chartOptions = null;
      this.isEmptyWatter = 1;
      let parameters = "?type=ST&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();

      if (sDateFrom != null) parameters += "&dateFrom=" + sDateFrom;
      if (sDateTo != null) parameters += "&dateTo=" + sDateTo;
      if (this.korigovaneST) parameters += "&Mode=1";
      //parameters += "&Mode=1";

      ApiService.setHeader();
      ApiService.get("Flat", "MonthlyConsumption" + parameters)
        .then(({ data }) => {
          if (data) {
            this.transformData(data);
            this.createChart();
          }

          this.title = data.Title;
          this.description = data.Description;
          this.subTitle = data.Subtitle;
          this.items = data.Consumption;

          this.year = data.Year;
          this.inputDateFrom = data.DateFrom;
          this.inputDateTo = data.DateTo;
          this.setYearRange(data.YearFrom, data.YearTo);
        })
        .catch(() => {
          this.items = undefined;
        });

      // heat
      this.itemsRooms = null;
      this.chartOptionsRooms = null;
      this.isEmptyHeat = 1;
      parameters = "?type=U&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();

      if (sDateFrom != null) parameters += "&dateFrom=" + sDateFrom;
      if (sDateTo != null) parameters += "&dateTo=" + sDateTo;
      if (this.korigovaneU) parameters += "&Mode=1";

      ApiService.get("Flat", "MonthlyConsumption" + parameters)
        .then(({ data }) => {
          if (data) {
            this.transformDataRooms(data);
            this.createChartRooms();
          }

          this.subTitleRooms = data.Subtitle;
          this.itemsRooms = data.Consumption;

          this.title = data.Title;
          this.description = data.Description;

          this.year = data.Year;
          this.inputDateFrom = data.DateFrom;
          this.inputDateTo = data.DateTo;
          this.setYearRange(data.YearFrom, data.YearTo);
        })
        .catch(() => {
          this.itemsRooms = undefined;
        });
    },
  },
};
</script>
