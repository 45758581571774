<template>
  <div>
    <div class="col-lg-12 col-xl-12">
      <KTPortlet v-bind:title="title">
        <template v-slot:body>
          <b-container fluid>
            <div>
              <b-row class="justify-content-md-center mb-6">
                <div class="col-lg-12 col-sm-12 d-flex">
                  <div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("SHOWPICTURE.STATE") }}
                      </span>
                      {{ getFormatService().formatNumberToLocal(State) }}
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("SHOWPICTURE.READINGDATE") }}
                      </span>
                      {{ getFormatService().formatDateToLocal(DateRead) }}
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("SHOWPICTURE.METERNUMBER") }}
                      </span>
                      {{ MeterNumber }}
                    </div>
                  </div>
                </div>
              </b-row>

              <hr class="poschodoch-separator-line mt-4 mb-4" />
            </div>
            <b-img :src="photo" fluid></b-img>

            <b-row class="justify-content-end" v-if="description">
              <b-col lg="12">
                <hr class="poschodoch-separator-line mt-4 mb-4" />
                <ul class="poschodoch-note-list">
                  <li class="poschodoch-note">{{ description }}</li>
                </ul>
              </b-col>
            </b-row>
          </b-container>
          <b-container fluid v-if="!pictureData">
            <Loader />
          </b-container>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
//import JwtService from "@/common/jwt.service";

export default {
  name: "show-meter-picture",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      pictureData: null,
      menuId: null,
      title: null,
      items: null,
      description: null,
      pictureId: null,

      photo: null,
      DateRead: null,
      State: null,
      MeterNumber: null,
    };
  },
  mounted() {
    this.pictureId = this.$route.query.PictureId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },

    fetchData() {
      //this.items = null;
      let parameters = "?unId=" + this.pictureId;

      ApiService.setHeader();
      ApiService.get("Public", "GetPict" + parameters).then(({ data }) => {
        console.log("GetPict");
        console.log(data);
        this.pictureData = data;

        this.title = this.$i18n.t("SHOWPICTURE.INFO");
        this.description = data.Description;
        //Číslo merača: 13996756 Dátum odpočtu: 1. 3. 2021
        this.photo = "data:image/png;base64," + data.Photo;
        this.DateRead = data.Date;
        this.State = data.State;
        this.MeterNumber = data.MeterNumber;
      });
    },
  },
};
</script>
