<template>
  <div>
    <b-modal
      id="modal-annualBilling-detail"
      hide-footer
      size="xl"
      v-show="annualBillingDetail"
      class="poschodoch-modal-annualBilling-detail"
    >
      <template v-slot:modal-title v-if="annualBillingDetail">
        <span v-if="annualBillingDetail.Title" class="poschodoch-modal-title">
          {{ annualBillingDetail.Title }}
        </span>
      </template>

      <div class="d-block text-center">
        <b-table
          v-if="annualBillingDetail && annualBillingDetail.AnnualBillingDetail"
          show-empty
          hover
          :items="annualBillingDetail.AnnualBillingDetail"
          :fields="fields"
          :empty-text="$t('COMMON.LIST.NO_RECORDS')"
        >
          <template v-slot:custom-foot>
            <tr class="poschodoch-summary-row">
              <td class="text-left">{{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}</td>
              <td class="text-right">
                <span
                  v-bind:class="{
                    'color-danger':
                      annualBillingDetail.AmountOfPaymentOrder < 0,
                  }"
                >
                  {{
                    getFormatService().formatNumberToLocal(
                      annualBillingDetail.AmountOfPaymentOrder,
                    )
                  }}
                </span>
              </td>
              <td class="text-right">
                <span
                  v-bind:class="{
                    'color-danger': annualBillingDetail.AmountOfCost < 0,
                  }"
                >
                  {{
                    getFormatService().formatNumberToLocal(
                      annualBillingDetail.AmountOfCost,
                    )
                  }}
                </span>
              </td>
              <td class="text-right">
                <span
                  v-bind:class="{
                    'color-danger': annualBillingDetail.AmountOfDifference < 0,
                  }"
                >
                  {{
                    getFormatService().formatNumberToLocal(
                      annualBillingDetail.AmountOfDifference,
                    )
                  }}
                </span>
              </td>
            </tr>
            <tr class="poschodoch-summary-row">
              <td class="text-left">
                {{ $t("ANNUALBILLING_DETAIL.CUSTOMIZEDAMOUNT") }}
              </td>
              <td class="text-right">
                <span
                  v-bind:class="{
                    'color-danger':
                      annualBillingDetail.AmountOfPaymentOrder < 0,
                  }"
                >
                  {{
                    getFormatService().formatNumberToLocal(
                      annualBillingDetail.AmountOfPaymentOrder,
                    )
                  }}
                </span>
              </td>
              <td class="text-right">
                <span
                  v-bind:class="{
                    'color-danger': annualBillingDetail.CustomizedCost < 0,
                  }"
                >
                  {{
                    getFormatService().formatNumberToLocal(
                      annualBillingDetail.CustomizedCost,
                    )
                  }}
                </span>
              </td>
              <td class="text-right">
                <span
                  v-bind:class="{
                    'color-danger':
                      annualBillingDetail.CustomizedDifference < 0,
                  }"
                >
                  {{
                    getFormatService().formatNumberToLocal(
                      annualBillingDetail.CustomizedDifference,
                    )
                  }}
                </span>
              </td>
            </tr>
            <tr class="poschodoch-summary-row">
              <td class="text-left">
                {{ $t("ANNUALBILLING_DETAIL.ACCOUNTBALANCETO") }}
                {{
                  getFormatService().formatDateToLocal(
                    annualBillingDetail.BalanceDate,
                  )
                }}
              </td>
              <td></td>
              <td></td>
              <td class="text-right">
                <span
                  v-bind:class="{
                    'color-danger':
                      annualBillingDetail.AccountBalanceBeforeBilling < 0,
                  }"
                >
                  {{
                    getFormatService().formatNumberToLocal(
                      annualBillingDetail.AccountBalanceBeforeBilling,
                    )
                  }}
                </span>
              </td>
            </tr>
            <tr class="poschodoch-summary-row">
              <td class="text-left">{{ annualBillingDetail.Result }}</td>
              <td></td>
              <td></td>
              <td class="text-right">
                <span
                  v-bind:class="{
                    'color-danger': annualBillingDetail.FinalBalance < 0,
                  }"
                >
                  {{
                    getFormatService().formatNumberToLocal(
                      annualBillingDetail.FinalBalance,
                    )
                  }}
                </span>
              </td>
            </tr>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FormatService from "@/common/format.service";
import JwtService from "@/common/jwt.service";

export default {
  name: "annualBilling-detail",
  props: {
    annualBillingDetail: {},
  },
  data() {
    return {
      fields: [
        {
          key: "Item",
          label: this.$i18n.t("ANNUALBILLING_DETAIL.TABLE.ITEM"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "PaymentOrder",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING_DETAIL.TABLE.PAYMENTORDER"),
          ),
          sortable: false,
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.PaymentOrder < 0) {
              item._cellVariants = { PaymentOrder: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Cost",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING_DETAIL.TABLE.COST"),
          ),
          sortable: false,
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Cost < 0) {
              item._cellVariants = { Cost: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Difference",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING_DETAIL.TABLE.DIFFERENCE"),
          ),
          sortable: false,
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Difference < 0) {
              item._cellVariants = { Difference: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        /* {
          key: "Order",
          label: this.$i18n.t("ANNUALBILLING_DETAIL.TABLE.ORDER"),
          sortable: false,
          class: "text-right"
        }, */
      ],
    };
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
  },
};
</script>
