<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::ustomerPages-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Name)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="showCustomerPageDetail(data.item.CompanyMenuId)"
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                </template>
                <template v-slot:cell(IsNew)="data">
                  <b-form-checkbox
                    v-model="data.item.IsNew"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(Visible)="data">
                  <b-form-checkbox
                    v-model="data.item.Visible"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ data.value.Order }}
                          </span>
                        </span>
                        <span>
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.CreationDate,
                            )
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left mr-1"
                      >
                        <a
                          href="javascript:void(0)"
                          v-if="data.item != null"
                          v-on:click="
                            showCustomerPageDetail(data.item.CompanyMenuId)
                          "
                          class="kt-link"
                        >
                          {{ data.value.Name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::customerPages-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";

export default {
  name: "customerPages",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "Order",
          label: this.$i18n.t("CUSTOMERPAGE.TABLE.ORDER"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Name",
          label: this.$i18n.t("CUSTOMERPAGE.TABLE.NAME"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DateChange",
          label: this.$i18n.t("CUSTOMERPAGE.TABLE.DATE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "IsNew",
          label: this.$i18n.t("CUSTOMERPAGE.TABLE.ISNEW"),
          sortable: false,
          class: "text-center table-no-mobile",
          formatter: (value) => {
            return value || value == 1 ? true : false;
          },
        },
        {
          key: "Visible",
          label: this.$i18n.t("CUSTOMERPAGE.TABLE.VISIBLE"),
          sortable: false,
          class: "text-center table-no-mobile",
          formatter: (value) => {
            return value || value == 1 ? true : false;
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showCustomerPageDetail(record.CompanyMenuId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },

    showCustomerPageDetail(customerPageId) {
      this.$router.push({
        name: "domus-customerPage-detail",
        query: { menuId: this.menuId, customerPageId: customerPageId },
      });
    },
    fetchData() {
      this.items = null;
      //let parameters = "?menuId=" + this.menuId.toString();
      let parameters = "?dashBoard=1";

      ApiService.setHeader();
      ApiService.get("Dashboard", "CompanyMenu" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data;

          this.transformData(this.items);
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        (items[i].Visible =
          items[i].Visible || items[i].Visible == 1 ? true : false),
          (items[i].IsNew =
            items[i].IsNew || items[i].IsNew == 1 ? true : false),
          (items[i].Mobile = {
            Order: items[i].Order,
            Name: items[i].Name,
            DateChange: items[i].DateChange,
            Visible: items[i].Visible || items[i].Visible == 1 ? true : false,
            IsNew: items[i].IsNew || items[i].IsNew == 1 ? true : false,

            /*   Object: items[i].Object,
          Name: items[i].Name,
          ValidTo: items[i].ValidTo,
          LastInspection: items[i].LastInspection,
          Defects: items[i].Defects,
          Todo: items[i].Todo,
          State: items[i].State,
          DocCount: items[i].DocCount,*/
          });
      }
    },
  },
};
</script>
