<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12 poschodoch-object-account-detail-content">
        <KTPortlet v-bind:title="null" class="poschodoch-print">
          <template v-slot:title>
            <div
              class="poshodoch-multiline-header d-flex flex-column align-items-start pt-3 pb-3 pl-2"
            >
              <h3 class="kt-portlet__head-title mb-2">
                {{ accountDescription }}
              </h3>
              <span
                v-if="issueDate && finalBalance"
                class="poschodoch-header-label"
              >
                <span>{{ issueDate + ": " }}</span>
                <strong>{{ finalBalance }}</strong>
              </span>
              <span v-if="bank">
                <span class="poschodoch-header-label">
                  {{ $i18n.t("OBJECTACCOUNT.TABLE.BANK") + ": " }}
                </span>
                {{ bank }}
              </span>
              <span v-if="bankAccount">
                <span class="poschodoch-header-label">
                  {{ $i18n.t("OBJECTACCOUNT.TABLE.BANKACCOUNT") + ": " }}
                </span>
                {{ bankAccount }}
              </span>
            </div>
          </template>

          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Parameters -->
              <b-row class="mb-3">
                <b-col sm="6" md="6" lg="3" xl="3">
                  <label>{{ $t("OBJECTACCOUNT_DETAIL.PARAMETER_YEAR") }}</label>
                  &nbsp;
                  <label>{{ year }}</label>
                  <span class="ml-3">
                    {{
                      $t("OBJECTACCOUNT_DETAIL.PARAMETER_MONTH") +
                        " " +
                        $t("COMMON.MONTHS.MONTH_" + month)
                    }}
                  </span>
                </b-col>
              </b-row>
              <!--End::Parameters -->

              <b-table
                v-if="items"
                show-empty
                hover
                :items="items"
                :fields="fields"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :refreshed="print()"
              ></b-table>

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "objectAccountDetail-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      accountId: null,
      fields: [
        {
          key: "Date",
          label: this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.DATE"),
          sortable: false,
          class: "text-left",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "VS",
          label: this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.VS"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "Description",
          label: this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.DESCRIPTION"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "Amount",
          label: this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.AMOUNT"),
          sortable: false,
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
      ],
      accountDescription: null,
      bank: null,
      issueDate: null,
      finalBalance: null,
      bankAccount: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.accountId = this.$route.query.accountId;
    this.year = this.$route.query.year;
    this.month = this.$route.query.month;

    this.fetchData();
  },
  methods: {
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?menuId=" +
        this.menuId.toString() +
        "&accountId=" +
        this.accountId.toString();
      let tit = "";
      let docTitle = "";
      if (this.year != null) parameters += "&year=" + this.year.toString();
      if (this.month != null) parameters += "&month=" + this.month.toString();

      ApiService.setHeader();
      ApiService.get("Object", "ObjectAccountDetail" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.ObjectAccount;
          this.year = data.Year;

          this.accountDescription = data.AccountDescription;
          this.bank = data.Bank;
          this.issueDate =
            this.$i18n.t("REPAIRFUND.TITLEADD") +
            FormatService.formatDateToLocal(data.IssueDate) +
            ": ";
          this.finalBalance = data.FinalBalance + " " + data.Currency;
          this.bankAccount = data.BankAccount.substring(0, 38);

          this.month = data.Month;
          tit = this.title;
        },
      );

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
