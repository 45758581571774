<template>
  <div>
    <span role="status" class="mr-3 spinner-border"></span>
    {{ $t("COMMON.LOADER.LOADING_DATA_FROM_SERVER") }}
  </div>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
