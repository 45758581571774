<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <div
          class="kt-portlet pl-5 pr-5 pt-5 pb-5"
          v-if="htmlContent"
          v-html="htmlContent"
        ></div>
        <div class="kt-portlet" v-if="!htmlContent">
          <div class="kt-portlet__body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Begin::DocumentListUser-->
        <KTPortlet v-bind:title="title2" v-if="LoaderDoc || items">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Filter and paging -->
              <b-row class="mb-4 align-items-end">
                <b-col>
                  <label>{{ $t("DOCUMENTS.FILTER.DOC_NAME") }}</label>
                  <b-form-input v-model="filterDocName"></b-form-input>
                </b-col>
                <b-col>
                  <label>{{ $t("DOCUMENTS.FILTER.CATEGORY") }}</label>
                  <b-form-select
                    v-model="filterCategory"
                    :options="categoryOptions"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <label>{{ $t("COMMON.LIST.ROW_COUNT_PER_PAGE") }}</label>
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    :options="pageOptions"
                    @change="getFormatService().formatSetPerPage(perPage)"
                  ></b-form-select>
                </b-col>
              </b-row>
              <!--End::Filter and paging -->

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-function="filterTable"
                class="poschodoch-documents-table notranslate"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(DocName)="data">
                  <i
                    v-if="data.item.CreationDate != null"
                    class="flaticon2-file-1 poschodoch-icon-large poschodoch-document-icon ml-md-3"
                  ></i>
                  <span class="ml-md-3 ml-sm-0">{{ data.item.DocName }}</span>
                </template>
                <template v-slot:cell(DocId)="data">
                  <a
                    href="javascript:void(0)"
                    v-on:click="
                      downloadFile(data.item.DocId, data.item.FileName)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i
                      class="flaticon-download poschodoch-icon-medium"
                      v-if="data.item.CreationDate != null"
                    ></i>
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ data.value.DocName }}
                          </span>
                        </span>
                        <span>
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.CreationDate,
                            )
                          }}
                        </span>
                        <!--<span v-if="data.item.CreationDate != null">
                          <span class="poschodoch-table-title-mobile">
                            {{ $i18n.t("DOCUMENTS.TABLE.CATEGORY") + ": " }}
                          </span>
                          {{ data.value.Category }}
                        </span>-->
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                        v-if="data.item.CreationDate != null"
                      >
                        <a
                          href="javascript:void(0)"
                          v-on:click="
                            downloadFile(data.value.DocId, data.value.FileName)
                          "
                          class="poschodoch-icon-link p-2"
                        >
                          <i
                            class="flaticon-download poschodoch-icon-medium"
                            v-if="data.item.CreationDate != null"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="LoaderDoc">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::DocumentListUser-->
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import Loader from "@/views/components/Loader.vue";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import FileService from "@/common/file.service";
import FormatService from "@/common/format.service";

export default {
  name: "Page",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      menuMappings: {},
      submenupage: 0,
      title: "",
      htmlContent: null,
      id: 0,
      title2: "",
      description: null,
      menuId: null,
      items: null,
      lastPeriod: "",
      periodClass: "row",
      LoaderDoc: true,
      fields: [
        {
          key: "DocName",
          label: this.$i18n.t("DOCUMENTS.TABLE.DOC_NAME"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "CreationDate",
          label: this.$i18n.t("DOCUMENTS.TABLE.CREATION_DATE"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Category",
          label: this.$i18n.t("DOCUMENTS.TABLE.CATEGORY"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
          visible: false,
          formatter: (value, key, item) => {
            if (item.CreationDate == null) {
              value = null;
            }
            return value;
          },
        },
        {
          key: "DocId",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      categoryOptions: [
        { value: null, text: this.$i18n.t("DOCUMENTS.FILTER.PLACEHOLDER") },
      ],
      filterDocName: null,
      filterCategory: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.id != null) {
      this.id = to.query.id;
      this.loadMenu();
      this.fetchData();
    }
    next();
  },
  computed: {
    filter() {
      return { DocName: this.filterDocName, Category: this.filterCategory };
    },
  },
  methods: {
    loadMenu() {
      let parameters = "?companyMenuId=" + this.id;
      this.htmlContent = null;

      ApiService.setHeader();
      ApiService.get("Dashboard/CompanyMenuDetail", parameters).then(
        ({ data }) => {
          this.title = data.Name;
          this.htmlContent = data.HtmlContent;
        },
      );
    },
    getFormatService() {
      return FormatService;
    },
    pointerRowClass(item) {
      // use item parameter if condition is needed
      if (item.CreationDate == null) {
        return "table-secondary";
      } else {
        return "";
      }
      //return "table-warning";
    },
    filterTable(row, filter) {
      let result = true;

      if (filter.DocName != null) {
        if (
          !FormatService.normalizeString(row.DocName).includes(
            FormatService.normalizeString(filter.DocName),
          )
        ) {
          result = false;
        }
      }

      if (filter.Category != null) {
        if (
          !FormatService.normalizeString(row.Category).includes(
            FormatService.normalizeString(filter.Category),
          )
        ) {
          result = false;
        }
      }

      return result;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    downloadFile(docId, fileName) {
      let parameters = "?docId=" + docId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "Get" + parameters).then(({ data }) => {
        let binaryContent = FileService.convertBase64ToByteArray(
          data.FileContent,
        );

        FileService.downloadFile(
          fileName,
          binaryContent,
          null,
          "document-download-link",
        );
      });
    },
    setCategoryOptions(items) {
      let options = {};

      for (let i = 0; i < items.length; i++) {
        let category = items[i].Category;
        if (options[category] == null) {
          options[category] = category;
          this.categoryOptions.push({ value: category, text: category });
        }
      }
    },
    fetchData() {
      this.items = null;
      this.menuMappings = {};
      this.submenupage = 0;
      let parameters = "";
      ApiService.setHeader();
      ApiService.get("Dashboard", "Menu" + parameters).then(({ data }) => {
        // preview menu
        for (let i = 0; i < data.length; i++) {
          this.menuMappings[data[i].MenuCode] = data[i].MenuId;
        }

        ApiService.setHeader();
        ApiService.get("Dashboard/CompanyMenu").then(({ data }) => {
          for (let i = 0; i < data.length; i++) {
            if (this.id == data[i].CompanyMenuId) {
              this.submenupage = i + 1;
            }
          }

          let parameters =
            "?tableName=userset&menuId=" +
            this.menuMappings["DocumentListAdmin"] +
            "&page=" +
            this.submenupage; // + this.menuId.toString(); //+"&RecKey=" + JwtService.getUnit();

          ApiService.setHeader();
          ApiService.get("Document", "ListAdmin" + parameters).then(
            ({ data }) => {
              console.log(data);
              if (data.Documents.length > 0) {
                this.transformData(data.Documents);
                this.items = data.Documents;
                this.totalRows = this.items.length;
                this.setCategoryOptions(data.Documents);
              }
              this.title2 = data.Title;
              this.description = data.Description;
              this.currentPage = 1;
              this.LoaderDoc = false;
            },
          );
        });
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          DocName: items[i].DocName,
          CreationDate: items[i].CreationDate,
          Category: items[i].Category,
          DocId: items[i].DocId,
        };
      }
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.loadMenu();
    this.fetchData();
  },
};
</script>
