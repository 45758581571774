<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-bind:title="title" v-show="itemsServices || itemsHeat">
          <template v-slot:body>
            <b-container fluid v-if="itemsServices || itemsHeat">
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Consumption-->
        <KTPortlet
          v-bind:title="subTitle"
          v-show="itemsServices || itemsServices === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadServicesCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="itemsServices">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsServices"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '300px', height: '400px' }">
                  <Chart
                    ref="annual-costs-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsServices"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div
                  v-for="(legendCheckboxData,
                  index) in legendCheckboxDataServices"
                  v-bind:key="legendCheckboxData.labelSeries"
                >
                  <div style="display: flex" class="mr-5">
                    <b-form-checkbox
                      v-on:change="showHideDatasetServices(index)"
                      size="lg"
                      style
                      checked="true"
                      switch
                    ></b-form-checkbox>
                    <span
                      class="legSquare"
                      v-bind:style="{
                        backgroundColor: legendCheckboxData.colorSeries,
                      }"
                    ></span>
                    <span class="legText">
                      {{ legendCheckboxData.labelSeries }}
                    </span>
                  </div>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsServices">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Consumption-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Consumption-->
        <KTPortlet
          v-bind:title="subTitleHeat"
          v-show="itemsHeat || itemsHeat === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu :functionCSV="downloadHeatCSV"></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="itemsHeat">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHeat"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '300px', height: '400px' }">
                  <Chart
                    ref="annual-costs-heat-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHeat"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div
                  v-for="(legendCheckboxData, index) in legendCheckboxDataHeat"
                  v-bind:key="legendCheckboxData.labelSeries"
                >
                  <div style="display: flex" class="mr-5">
                    <b-form-checkbox
                      v-on:change="showHideDatasetHeat(index)"
                      size="lg"
                      style
                      checked="true"
                      switch
                    ></b-form-checkbox>
                    <span
                      class="legSquare"
                      v-bind:style="{
                        backgroundColor: legendCheckboxData.colorSeries,
                      }"
                    ></span>
                    <span class="legText">
                      {{ legendCheckboxData.labelSeries }}
                    </span>
                  </div>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHeat">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Consumption-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import ColorService from "@/common/color.service";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";

export default {
  name: "annual-costs",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      description: null,
      subTitle: null,
      subTitleHeat: null,
      menuId: null,
      itemsServices: null,
      itemsHeat: null,
      chartOptionsServices: null,
      chartOptionsHeat: null,
      chartLabels: [],
      chartLabelsHeat: [],
      charts: [1],
      chartDataServices: [],
      datasetsHeat: [],
      legendCheckboxDataHeat: [],
      legendCheckboxDataServices: [],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    showHideDatasetServices(index) {
      this.chartOptionsServices.data.datasets[index].hidden = !this
        .chartOptionsServices.data.datasets[index].hidden;
      this.chartOptionsServices.chart.update();
    },
    showHideDatasetHeat(index) {
      this.chartOptionsHeat.data.datasets[index].hidden = !this.chartOptionsHeat
        .data.datasets[index].hidden;
      this.chartOptionsHeat.chart.update();
    },
    createChart() {
      this.charts = [this.charts[0] + 1];
      this.chartOptionsServices = {
        type: "bar",
        data: {
          datasets: this.chartDataServices,
          labels: this.chartLabels,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHeat() {
      //this.charts = [this.charts[0] + 1];
      this.chartOptionsHeat = {
        type: "bar",
        data: {
          datasets: this.datasetsHeat,
          labels: this.chartLabelsHeat,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                },
                stacked: false,
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                },
                stacked: false,
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    transformData(data) {
      let seriesLabels = [];
      let seriesData = {};
      let chartData = [];
      let labels = [];

      for (let i = 0; i < data.Series.length; i++) {
        seriesLabels.push(data.Series[i].Label);
        seriesData[data.Series[i].Label] = [];

        for (let j = 0; j < data.Series[i].Data.length; j++) {
          seriesData[data.Series[i].Label].push({
            x: data.Series[i].Data[j].Period,
            y: data.Series[i].Data[j].Value,
          });
          if (!labels.includes(data.Series[i].Data[j].Period))
            labels.push(data.Series[i].Data[j].Period);
        }
      }

      for (let i = 0; i < seriesLabels.length; i++) {
        let room = seriesLabels[i];
        let color = this.layoutConfig("colors.state.info");
        switch (i % 16) {
          case 0:
            color = this.layoutConfig("colors.graph.color0");
            break;
          case 1:
            color = this.layoutConfig("colors.graph.color1");
            break;
          case 2:
            color = this.layoutConfig("colors.graph.color2");
            break;
          case 3:
            color = this.layoutConfig("colors.graph.color3");
            break;
          case 4:
            color = this.layoutConfig("colors.graph.color4");
            break;
          case 5:
            color = this.layoutConfig("colors.graph.color5");
            break;
          case 6:
            color = this.layoutConfig("colors.graph.color6");
            break;
          case 7:
            color = this.layoutConfig("colors.graph.color7");
            break;
          case 8:
            color = this.layoutConfig("colors.graph.color8");
            break;
          case 9:
            color = this.layoutConfig("colors.graph.color9");
            break;
          case 10:
            color = this.layoutConfig("colors.graph.color10");
            break;
          case 11:
            color = this.layoutConfig("colors.graph.color11");
            break;
          case 12:
            color = this.layoutConfig("colors.graph.color12");
            break;
          case 13:
            color = this.layoutConfig("colors.graph.color13");
            break;
          case 14:
            color = this.layoutConfig("colors.graph.color14");
            break;
          case 15:
            color = this.layoutConfig("colors.graph.color15");
            break;
        }
        this.legendCheckboxDataServices.push({
          labelSeries: room,
          colorSeries: color,
        });

        chartData.push({
          data: seriesData[room],
          label: room,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        });
      }

      this.chartLabels = labels;
      this.chartDataServices = chartData;
    },
    transformDataHeat(data) {
      let seriesLabels = [];
      let seriesData = {};
      let labels = [];
      let seriesDatasets = [];
      let baseColor = ColorService.hexToRgb(
        this.layoutConfig("colors.state.brand"),
      );

      for (let i = 0; i < data.Series.length; i++) {
        seriesLabels.push(data.Series[i].Label);
        seriesData[data.Series[i].Label] = [];

        for (let j = 0; j < data.Series[i].Data.length; j++) {
          seriesData[data.Series[i].Label].push({
            x: data.Series[i].Data[j].Period,
            y: data.Series[i].Data[j].Value,
          });
          if (!labels.includes(data.Series[i].Data[j].Period)) {
            labels.push(data.Series[i].Data[j].Period);
          }
        }
      }

      for (let i = 0; i < seriesLabels.length; i++) {
        let room = seriesLabels[i];
        let ratio = 1 - 0.5 * (i / seriesLabels.length);
        let color =
          "rgb(" +
          parseInt(baseColor.r * ratio) +
          "," +
          parseInt(baseColor.g * ratio) +
          "," +
          parseInt(baseColor.b * ratio) +
          ")";
        this.legendCheckboxDataHeat.push({
          labelSeries: room,
          colorSeries: color,
        });

        seriesDatasets.push({
          data: seriesData[room],
          label: room,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        });
      }

      this.datasetsHeat = seriesDatasets;
      this.chartLabelsHeat = labels;
    },
    downloadServicesCSV() {
      this.downloadCSV(this.subTitle, this.itemsServices);
    },
    downloadHeatCSV() {
      this.downloadCSV(this.subTitleHeat, this.itemsHeat);
    },
    downloadCSV(title, items) {
      let header = [];
      let years = [];
      let values = [];

      if (items) {
        header.push("");
        items.forEach(function(item) {
          header.push(item.Label);
          item.Data.forEach(function(data) {
            if (!years.includes(data.Period)) {
              years.push(data.Period);
            }
            values.push([
              item.Label,
              data.Period,
              FormatService.formatNumberToLocalForCSV(data.Value),
            ]);
          });
        });
      }

      let result = FileService.generateChartCSV(header, years, values);
      let fileName = title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      // Annual costs services
      this.itemsServices = null;
      this.chartOptionsServices = null;
      let parameters =
        "?type=A&TypeOfData=N&RangeOfData=U&menuId=" + this.menuId.toString();

      ApiService.get("Flat", "AnnualCosts" + parameters)
        .then(({ data }) => {
          this.transformData(data);
          this.createChart();

          this.title = data.Title;
          this.description = data.Description;
          this.subTitle = data.Subtitle;
          this.itemsServices = data.Series;
        })
        .catch(() => {
          this.itemsServices = undefined;
        });

      // Annual costs heat
      this.itemsHeat = null;
      this.chartOptionsHeat = null;
      parameters =
        "?type=U&TypeOfData=N&RangeOfData=U&menuId=" + this.menuId.toString();

      ApiService.get("Flat", "AnnualCosts" + parameters)
        .then(({ data }) => {
          this.transformDataHeat(data);
          this.createChartHeat();

          this.subTitleHeat = data.Subtitle;
          this.itemsHeat = data.Series;

          if (!this.itemsServices) {
            this.title = data.Title;
            this.description = data.Description;
          }
        })
        .catch(() => {
          this.itemsHeat = undefined;
        });
    },
  },
};
</script>
