<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Filter -->
        <KTPortlet v-bind:title="title" v-show="items || itemsRooms">
          <template v-slot:body>
            <b-container fluid v-if="items || itemsRooms">
              <b-row v-if="!hidden">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div
                    class="d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center"
                  >
                    <ul
                      class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills m-0 mr-0 mr-md-3"
                      role="tablist"
                    >
                      <li class="nav-item" v-for="index in years" :key="index">
                        <a
                          v-on:click="fetchData(index, month)"
                          data-tab="0"
                          class="nav-link"
                          href="javascript:void(0)"
                          v-bind:class="{ active: index == year }"
                        >
                          {{ index }}
                        </a>
                      </li>
                    </ul>

                    <div
                      class="poschodoch-month-dropdown d-flex align-items-center mt-3 mt-md-0"
                    >
                      <label
                        for="daily-consumption-dropdown-month"
                        class="m-0 mr-3"
                      >
                        {{ $i18n.t("DAILY_CONSUMPTION.FILTERMONTH") }}
                      </label>
                      <button
                        id="previous-month"
                        type="button"
                        class="poschodoch-previous-btn page-link mr-3 mr-md-2"
                        v-on:click="previousMonth(month)"
                        :disabled="isPreviousBtnDisabled"
                      >
                        ‹
                      </button>
                      <b-dropdown
                        id="daily-consumption-dropdown-month"
                        :text="getFormatService().getMonthName(month)"
                        variant="light"
                      >
                        <b-dropdown-item
                          v-for="month in months"
                          :key="month"
                          v-on:click="fetchData(year, month)"
                        >
                          {{ getFormatService().getMonthName(month) }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <button
                        id="next-month"
                        type="button"
                        class="poschodoch-next-btn page-link ml-3 ml-md-2"
                        v-on:click="nextMonth(month)"
                        :disabled="isNextBtnDisabled"
                      >
                        ›
                      </button>
                    </div>
                  </div>

                  <b-button
                    variant="secondary"
                    class="ml-md-5 mt-3 mt-md-0"
                    v-on:click="
                      hidden2 = !hidden2;
                      hidden = !hidden;
                      fetchData(year, month);
                    "
                  >
                    {{ $i18n.t("DAILY_CONSUMPTION.BUTTON1") }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="hidden2">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div class="d-flex flex-row">
                    <div
                      class="ml-md-2 mt-3 mt-md-0 d-flex flex-sm-row flex-column"
                    >
                      <span
                        class="d-flex flex-row align-items-center mr-sm-3 mr-0"
                      >
                        <label for="dF" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("DAILY_CONSUMPTION.FROM") }}
                        </label>
                        <b-form-input
                          id="dF"
                          v-model="inputDateFrom"
                          type="date"
                        ></b-form-input>
                      </span>
                      <span
                        class="d-flex flex-row align-items-center mt-sm-0 mt-3"
                      >
                        <label for="dT" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("DAILY_CONSUMPTION.TO") }}
                        </label>
                        <b-form-input
                          id="dT"
                          v-model="inputDateTo"
                          type="date"
                        ></b-form-input>
                      </span>
                      <div
                        class="d-flex align-items-sm-center align-self-center"
                      >
                        <b-button
                          variant="primary"
                          class="ml-sm-3 ml-0 mt-3 mt-sm-0"
                          v-on:click="
                            fetchData(null, null, inputDateFrom, inputDateTo)
                          "
                        >
                          {{ $i18n.t("DAILY_CONSUMPTION.BUTTONOK") }}
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex align-items-sm-center">
                    <b-button
                      variant="secondary"
                      class="ml-md-2 ml-sm-3 mt-3 mt-md-0"
                      v-on:click="
                        hidden2 = !hidden2;
                        hidden = !hidden;
                        fetchData();
                      "
                    >
                      {{ $i18n.t("DAILY_CONSUMPTION.BUTTON2") }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTPortlet>
        <!--End::Filter -->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption Water-->
        <KTPortlet v-bind:title="subTitle" v-show="items || items === null">
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="items && EmptyWater === 0">
            <TableContextMenu
              :functionCSV="downloadWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container
              fluid
              v-if="items && EmptyWater === 1"
              style="color:#616365;"
            >
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="items && EmptyWater === 0">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptions"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '800px', height: '300px' }">
                  <Chart
                    ref="daily-consumption-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptions"
                    height="300"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkKorigST"
                  v-on:change="
                    korigovaneST = !korigovaneST;
                    fetchData(year, month, inputDateFrom, inputDateTo);
                  "
                  size="md"
                  style
                  class="ml-3"
                  v-bind:checked="korigovaneST"
                  switch
                >
                  {{ $i18n.t("MONTHLY_CONSUMPTION.SHOW_CORRECTED") }}
                </b-form-checkbox>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div
                class="poschodoch-legend"
                id="daily-consumption-chart-legend"
              />
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptions">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption Water-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption Heat-->
        <KTPortlet
          v-bind:title="subTitleRooms"
          v-show="itemsRooms || itemsRooms === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="itemsRooms && EmptyHeat === 0">
            <TableContextMenu
              :functionCSV="downloadRoomsCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container
              fluid
              v-if="itemsRooms && EmptyHeat === 1"
              style="color:#616365;"
            >
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="itemsRooms && EmptyHeat === 0">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsRooms"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '800px', height: '300px' }">
                  <Chart
                    ref="daily-consumption-rooms-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsRooms"
                    height="300"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkKorigU"
                  v-on:change="
                    korigovaneU = !korigovaneU;
                    fetchData(year, month, inputDateFrom, inputDateTo);
                  "
                  size="md"
                  style
                  class="ml-3"
                  v-bind:checked="korigovaneU"
                  switch
                >
                  {{ $i18n.t("MONTHLY_CONSUMPTION.SHOW_CORRECTED") }}
                </b-form-checkbox>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div
                class="poschodoch-legend"
                id="daily-consumption-rooms-chart-legend"
              />
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsRooms">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption Heat-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet>
          <template v-slot:body>
            <b-container fluid v-if="description">
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import ColorService from "@/common/color.service";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";

export default {
  name: "daily-consumption",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      description: null,
      subTitle: null,
      subTitleRooms: null,
      menuId: null,
      hidden: false,
      hidden2: false,
      items: null,
      itemsRooms: null,
      year: null,
      years: null,
      yearFrom: null,
      yearTo: null,
      month: null,
      months: null,
      monthFrom: null,
      monthTo: null,
      inputDateFrom: null,
      inputDateTo: null,
      chartOptions: null,
      chartOptionsRooms: null,
      chartLabels: [],
      chartLabelsCVS1: [],
      chartLabelsCVS2: [],
      chartLabelsRooms: [],
      chartDatasetLabelHotWater: this.$i18n.t(
        "DAILY_CONSUMPTION.CHART_WATER.HOT_WATER",
      ),
      chartDataHotWater: [],
      charts: [1],
      chartDatasetLabelColdWater: this.$i18n.t(
        "DAILY_CONSUMPTION.CHART_WATER.COLD_WATER",
      ),
      chartDataColdWater: [],
      chartTooltipsColdWater: [],
      chartTooltipsHotWater: [],
      chartTooltipsHeat: [],
      chartRooms: [],
      datasetsRooms: [],
      //colors
      colorhot: null,
      colorcold: null,
      colorheat: null,
      colortooltip: null,
      korigovaneST: false,
      korigovaneU: false,
      EmptyWater: 0,
      EmptyHeat: 0,
    };
  },
  mounted() {
    this.colorhot = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.danger");
    this.colorcold = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-cold")
      : this.layoutConfig("colors.state.info");
    this.colorheat = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-heat")
      : this.layoutConfig("colors.state.brand");
    this.colortooltip = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.brand");
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    isPreviousBtnDisabled() {
      return !this.isMonthInRange(this.month - 1);
    },
    isNextBtnDisabled() {
      return !this.isMonthInRange(this.month + 1);
    },
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    isMonthInRange(monthToCheck) {
      if (monthToCheck != null && monthToCheck != -1 && this.months != null) {
        return this.months.includes(monthToCheck);
      } else {
        return false;
      }
    },
    previousMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth - 1))
        this.fetchData(this.year, selectedMonth - 1);
    },
    nextMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth + 1))
        this.fetchData(this.year, selectedMonth + 1);
    },
    createChart() {
      this.charts = [this.charts[0] + 1];
      this.chartOptions = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.chartDataHotWater,
              label: this.chartDatasetLabelHotWater,
              fill: false,
              borderColor: this.colorhot,
              backgroundColor: this.colorhot,
            },
            {
              data: this.chartDataColdWater,
              label: this.chartDatasetLabelColdWater,
              fill: false,
              borderColor: this.colorcold,
              backgroundColor: this.colorcold,
            },
          ],
          labels: this.chartLabels,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "daily-consumption-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colortooltip,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem) => {
                let title;
                if (tooltipItem[0].datasetIndex == 0)
                  title = this.getFormatService().formatDateToLocal(
                    this.chartTooltipsHotWater[tooltipItem[0].index],
                  );
                if (tooltipItem[0].datasetIndex == 1)
                  title = this.getFormatService().formatDateToLocal(
                    this.chartTooltipsColdWater[tooltipItem[0].index],
                  );

                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION.CHART_WATER.AX_X",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION.CHART_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartRooms() {
      //this.charts = [this.charts[0] + 1];
      this.chartOptionsRooms = {
        type: "bar",
        data: {
          datasets: this.datasetsRooms,
          labels: this.chartLabelsRooms,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "daily-consumption-rooms-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem) => {
                let title = this.getFormatService().formatDateToLocal(
                  this.chartTooltipsHeat[tooltipItem[0].index],
                );

                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION.CHART_WATER.AX_X_HEAT",
                  ),
                },
                stacked: true,
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION.CHART_WATER.AX_Y_HEAT",
                  ),
                },
                stacked: true,
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    setYearRange(yearFrom, yearTo) {
      if (this.years == null) {
        this.years = [];
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    setMonthRange(pRok, pMesiac = "") {
      this.months = [];
      this.year = pRok;
      if (pRok == this.yearFrom) {
        for (let i = this.monthFrom; i <= 12; i++) {
          this.months.push(i);
        }
      } else if (pRok == this.yearTo) {
        for (let i = 1; i <= this.monthTo; i++) {
          this.months.push(i);
        }
      } else {
        this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }

      if (pMesiac == null) {
        this.month = "Zvoľte mesiac";
      }
    },
    transformData(data) {
      let consumptionHotWater = [];
      let consumptionColdWater = [];
      let labels = [];
      let tooltipsCold = [];
      let tooltipsHot = [];
      let labels2 = [];

      // Adjust DateTo if it falls on October 31st for any year
      const dateTo = new Date(data.DateTo);
      if (dateTo.getMonth() === 9 && dateTo.getDate() === 31) {
        // October is month 9 (0-based index)
        dateTo.setDate(1);
        dateTo.setMonth(10); // November is month 10
        data.DateTo = dateTo.toISOString().slice(0, 10); // Format as YYYY-MM-DD
      }

      for (let i = 0; i < data.Consumption.length; i++) {
        if (data.Consumption[i].Code == "T") {
          consumptionHotWater.push({
            x: data.Consumption[i].Date,
            y: data.Consumption[i].Consumption,
          });

          if (i == data.Consumption.length / 2)
            tooltipsHot.push(data.Consumption[i].Date);
          this.chartDatasetLabelHotWater = data.Consumption[i].Type;
        }
        if (data.Consumption[i].Code == "S") {
          consumptionColdWater.push({
            x: data.Consumption[i].Date,
            y: data.Consumption[i].Consumption,
          });
          tooltipsCold.push(data.Consumption[i].Date);
          this.chartDatasetLabelColdWater = data.Consumption[i].Type;
        }
      }

      var currentDate = new Date(data.DateFrom);
      while (currentDate <= new Date(data.DateTo)) {
        labels.push(currentDate.getDate());
        labels2.push(
          currentDate
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"),
        );
        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.chartLabels = labels;
      this.chartLabelsCVS1 = labels2;
      this.chartDataHotWater = consumptionHotWater;
      this.chartDataColdWater = consumptionColdWater;
      this.chartTooltipsColdWater = tooltipsCold;
      this.chartTooltipsHotWater = tooltipsHot;
    },
    transformDataRooms(data) {
      let roomLabels = [];
      let roomData = {};
      let labels1 = [];
      let labels2 = [];
      let roomDatasets = [];
      let tooltipsHeat = [];

      let baseColor = ColorService.hexToRgb(
        this.layoutConfig("colors.state.brand"),
      );

      // Adjust DateTo if it falls on October 31st for any year
      const dateTo = new Date(data.DateTo);
      if (dateTo.getMonth() === 9 && dateTo.getDate() === 31) {
        // October is month 9 (0-based index)
        dateTo.setDate(1);
        dateTo.setMonth(10); // November is month 10
        data.DateTo = dateTo.toISOString().slice(0, 10); // Format as YYYY-MM-DD
      }

      for (let i = 0; i < data.Consumption.length; i++) {
        if (!roomLabels.includes(data.Consumption[i].Type)) {
          roomLabels.push(data.Consumption[i].Type);
          roomData[data.Consumption[i].Type] = [];
        }

        roomData[data.Consumption[i].Type].push({
          x: data.Consumption[i].Date,
          y: data.Consumption[i].Consumption,
        });
        if (!tooltipsHeat.includes(data.Consumption[i].Date)) {
          tooltipsHeat.push(data.Consumption[i].Date);
        }

        //
      }

      var currentDate = new Date(data.DateFrom);
      while (currentDate <= new Date(data.DateTo)) {
        labels1.push(currentDate.getDate());
        labels2.push(
          currentDate
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"),
        );
        currentDate.setDate(currentDate.getDate() + 1);
      }

      for (let i = 0; i < roomLabels.length; i++) {
        let room = roomLabels[i];
        let ratio = 1 - 0.5 * (i / roomLabels.length);
        let color =
          "rgb(" +
          parseInt(baseColor.r * ratio) +
          "," +
          parseInt(baseColor.g * ratio) +
          "," +
          parseInt(baseColor.b * ratio) +
          ")";

        roomDatasets.push({
          data: roomData[room],
          label: room,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        });

        this.datasetsRooms = roomDatasets;
      }

      this.chartRooms = roomLabels;
      this.chartTooltipsHeat = tooltipsHeat;
      this.chartLabelsRooms = labels1;
      this.chartLabelsCVS2 = labels2;
    },
    downloadWaterCSV() {
      /*let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.Type, 
          item.Day, 
          FormatService.formatNumberToLocalForCSV(item.Consumption),
        ];
        rows.push(row);
      });*/
      this.downloadCSV(
        this.subTitle,
        ["", this.chartDatasetLabelHotWater, this.chartDatasetLabelColdWater],
        this.chartLabelsCVS1,
        this.items,
      );
    },
    downloadRoomsCSV() {
      this.downloadCSV(
        this.subTitleRooms,
        ["", ...this.chartRooms],
        this.chartLabelsCVS2,
        this.itemsRooms,
      );
    },
    downloadCSV(title, header, days, items) {
      let values = items.map(function(v) {
        return [
          v.Type,
          v.Date,
          FormatService.formatNumberToLocalForCSV(v.Consumption),
        ];
      });

      let result = FileService.generateChartCSV(header, days, values);
      let fileName = title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(selectedYear = null, selectedMonth = null, sDateFrom, sDateTo) {
      // water
      this.items = null;
      this.chartOptions = null;

      let parameters = "?type=ST&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();

      if (sDateFrom != null) parameters += "&dateFrom=" + sDateFrom;
      if (sDateTo != null) parameters += "&dateTo=" + sDateTo;

      if (this.korigovaneST) parameters += "&mode=1";

      ApiService.setHeader();
      ApiService.get("Flat", "DailyConsumption" + parameters)
        .then(({ data }) => {
          this.transformData(data);
          this.createChart();
          this.EmptyWater = data.Empty ? 1 : 0;
          this.items = data.Consumption;
          if (this.items.length != 0) {
            this.title = data.Title;
            this.description = data.Description;
            this.subTitle = data.Subtitle;
            this.year = data.Year;
            this.yearFrom = data.YearFrom;
            this.yearTo = data.YearTo;
            this.monthFrom = data.MonthFrom;
            this.monthTo = data.MonthTo;
            this.inputDateFrom = data.DateFrom;
            this.inputDateTo = data.DateTo;
            this.setYearRange(data.YearFrom, data.YearTo);
            this.setMonthRange(this.year, selectedMonth);
            this.month = data.Month;
          }
        })
        .catch(() => {
          this.items = undefined;
        });

      // heat test
      this.itemsRooms = null;
      this.chartOptionsRooms = null;

      parameters = "?type=U&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();

      if (sDateFrom != null) parameters += "&dateFrom=" + sDateFrom;
      if (sDateTo != null) parameters += "&dateTo=" + sDateTo;

      if (this.korigovaneU) parameters += "&mode=1";

      ApiService.get("Flat", "DailyConsumption" + parameters)
        .then(({ data }) => {
          this.transformDataRooms(data);
          this.createChartRooms();

          this.subTitleRooms = data.Subtitle;
          this.itemsRooms = data.Consumption;
          this.title = data.Title;
          this.description = data.Description;
          this.EmptyHeat = data.Empty ? 1 : 0;

          if (this.itemsRooms != 0) {
            this.year = data.Year;
            this.yearFrom = data.YearFrom;
            this.yearTo = data.YearTo;
            this.monthFrom = data.MonthFrom;
            this.monthTo = data.MonthTo;
            this.inputDateFrom = data.DateFrom;
            this.inputDateTo = data.DateTo;
            this.setYearRange(data.YearFrom, data.YearTo);
            this.setMonthRange(this.year, selectedMonth);
            this.month = data.Month;
          }
        })
        .catch(() => {
          this.itemsRooms = undefined;
        });
    },
  },
};
</script>
