<template>
  <b-col xs="12" sm="10" lg="5" class="mx-auto">
    <b-card
      v-show="serviceMessagesVisible"
      class="mb-3 text-center"
      text-variant="black"
      style="border-style: hidden; background-color: var(--company-color) !important"
    >
      <b-card-body>
        <!-- Iterate over each message in the serviceMessages array -->
        <div
          class="row justify-content-center"
          v-for="(message, index) in serviceMessages"
          :key="index"
        >
          <div class="col text-center">
            <!-- Display each message in a centered span -->
            <span class="mt-3 poschodoch-homepage-text">
              {{ message.message }}
            </span>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-card
      text-variant="black"
      style="background-color: rgba(255, 255, 255, 0.85)"
    >
      <b-card-body>
        <!--begin::Body-->
        <div class="kt-login__body">
          <!--begin::Signin-->
          <div class="kt-login__form text-center">
            <div class="kt-login__title mb-3">
              <h3>{{ $t("AUTH.REGISTER.TITLE") }}</h3>
            </div>

            <!--begin::Form-->
            <b-form class="kt-form" @submit.stop.prevent="onSubmit">
              <div v-show="!showVerificationCodeInput">
                <!--begin::Options-->
                <div class="kt-login__options">
                  <b-button
                    block
                    variant="facebook"
                    v-on:click="registerWithFacebook"
                  >
                    <i class="fab fa-facebook-f"></i>
                    Facebook
                  </b-button>
                  <b-button
                    block
                    variant="light mt-3"
                    v-on:click="registerWithGoogle"
                  >
                    <i class="fab fa-google"></i>
                    Google
                  </b-button>
                </div>
                <!--end::Options-->

                <!--begin::Divider-->
                <div class="kt-login__divider mt-3 mb-3">
                  <div class="kt-divider">
                    <span></span>
                    <span>{{ $t("AUTH.REGISTER.DIVIDER") }}</span>
                    <span></span>
                  </div>
                </div>
                <!--end::Divider-->
                <div
                  role="alert"
                  v-bind:class="{ show: errors && errors.length }"
                  class="alert fade alert-danger mt-2 mb-2"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
                <b-form-group id="email-group" label="" label-for="email-input">
                  <b-form-input
                    id="email-input"
                    name="email-input"
                    autocomplete="off"
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="email-live-feedback"
                    :placeholder="placeholderEmail"
                  ></b-form-input>

                  <b-form-invalid-feedback id="email-live-feedback">
                    {{ $t("AUTH.REGISTER.VALIDATION.EMAIL") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="password-group"
                  label=""
                  label-for="password-input"
                >
                  <b-form-input
                    :type="visibilityPWD"
                    id="password-input"
                    name="password-input"
                    autocomplete="off"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="password-live-feedback"
                    :placeholder="placeholderPassword"
                  ></b-form-input>
                  <b-input-group-append style="float: right">
                    <!-- shows the password -->
                    <a
                      @click="showPassword()"
                      style="                                         
                      float: right;
                      bottom: 2rem;
                      left: 3rem;
                      "
                      class="notunderlined col-lg-1 center"
                      v-if="visibilityPWD == 'password'"
                    >
                      <i class="fas fa-eye-slash poschodoch-icon-medium"></i>
                    </a>
                    <!-- hides the password -->
                    <a
                      @click="hidePassword()"
                      style="                                         
                      float: right;
                      bottom: 2rem;
                      left: 3rem;
                      "
                      class="notunderlined col-lg-1 center"
                      v-if="visibilityPWD == 'text'"
                    >
                      <i class="fas fa-eye poschodoch-icon-medium"></i>
                    </a>
                  </b-input-group-append>

                  <b-form-invalid-feedback
                    class="col-lg-12 "
                    id="password-live-feedback"
                  >
                    {{ $t("AUTH.REGISTER.VALIDATION.PWD") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--begin::Action-->
                <div class="kt-login__actions d-flex flex-column text-center">
                  <div class="mb-3">
                    <span class="kt-login__signup-label">
                      {{ $t("AUTH.REGISTER.ACCOUNT_EXISTS") }}
                    </span>
                    &nbsp;&nbsp;
                    <router-link
                      class="kt-link kt-login__signup-link"
                      :to="{ name: 'login', params: { haveAccount: true } }"
                    >
                      {{ $t("AUTH.REGISTER.LOGIN") }}
                    </router-link>
                  </div>
                  <b-button
                    v-on:click="validateAccount()"
                    id="kt_validateAcc"
                    class="btn btn-primary btn-elevate kt-login__btn-primary"
                  >
                    {{ $t("AUTH.REGISTER.TITLE") }}
                  </b-button>
                </div>
                <!--end::Action-->
              </div>
              <div v-show="showVerificationCodeInput">
                <div
                  role="alert"
                  v-if="errors && errors.length"
                  v-bind:class="{ show: errors && errors.length }"
                  class="alert fade alert-danger mt-2 mb-2"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
                <div>
                  <span class="poschodoch-header-label mr-3">
                    <span class="font-weight-bold">Email:</span>
                    {{ form.email }}
                  </span>
                </div>
                <b-form-group
                  class="mt-3"
                  id="phone-group"
                  label=""
                  label-for="phone-input"
                  v-show="!verificationCodeSend"
                >
                  <span class="poschodoch-header-label mr-3">
                    {{ $t("AUTH.REGISTER.TITLE_PHONE") }}
                  </span>
                  <b-form-input
                    id="phone-input"
                    name="phone-input"
                    autocomplete="off"
                    v-model="$v.form.phone.$model"
                    :state="validateState('phone')"
                    aria-describedby="phone-live-feedback"
                    :placeholder="placeholderPhone"
                  ></b-form-input>

                  <b-form-invalid-feedback id="phone-live-feedback">
                    {{ $t("AUTH.REGISTER.VALIDATION.PHONE") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="email-verification-code-group"
                  label=""
                  label-for="email-verification-code-input"
                  v-show="verificationCodeSend && isEmailRegistration"
                >
                  <span class="poschodoch-header-label mr-3">
                    {{ $t("AUTH.REGISTER.TEXT_EMAIL") }}
                  </span>
                  <b-form-input
                    id="email-verification-code-input"
                    name="email-verification-code-input"
                    autocomplete="off"
                    v-model="$v.form.emailVerificationCode.$model"
                    :state="validateState('emailVerificationCode')"
                    aria-describedby="email-verification-code-live-feedback"
                    :placeholder="placeholderCodeEmail"
                  ></b-form-input>

                  <b-form-invalid-feedback
                    id="email-verification-code-live-feedback"
                  >
                    {{ $t("AUTH.REGISTER.VALIDATION.CODE_EMAIL") }}
                  </b-form-invalid-feedback>

                  <div class="d-flex justify-content-start">
                    <a
                      href="javascript:void(0)"
                      @click="reSendVerificationCode(form.email, 'email')"
                      class="kt-link"
                      v-if="!timerGoE"
                    >
                      <span class="d-flex">
                        {{ $t("AUTH.REGISTER.CODE_RESEND") }}
                      </span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="poschodoch-link-reSendCode"
                      :disabled="timerGoE"
                      v-if="timerGoE"
                    >
                      <span class="d-flex">
                        {{ $t("AUTH.REGISTER.CODE_RESEND") }}
                        <div v-if="timerGoE" class="ml-2 d-flex">
                          {{ timerEMin }}:
                          <div v-if="timerESec < 10">0</div>
                          {{ timerESec }}
                        </div>
                      </span>
                    </a>
                  </div>
                </b-form-group>
                <div>
                  <span
                    class="poschodoch-header-label mr-3"
                    v-show="verificationCodeSend"
                  >
                    <span class="font-weight-bold">Tel:</span>
                    {{ form.phone }}
                  </span>
                </div>
                <b-form-group
                  id="sms-verification-code-group"
                  label=""
                  label-for="sms-verification-code-input"
                  v-show="verificationCodeSend"
                >
                  <span class="poschodoch-header-label mr-3">
                    {{ $t("AUTH.REGISTER.TEXT_MOBIL") }}
                  </span>
                  <b-form-input
                    id="sms-verification-code-input"
                    name="sms-verification-code-input"
                    autocomplete="off"
                    v-model="$v.form.smsVerificationCode.$model"
                    :state="validateState('smsVerificationCode')"
                    aria-describedby="sms-verification-code-live-feedback"
                    :placeholder="placeholderCodeSMS"
                  ></b-form-input>

                  <b-form-invalid-feedback
                    id="sms-verification-code-live-feedback"
                  >
                    {{ $t("AUTH.REGISTER.VALIDATION.CODE_SMS") }}
                  </b-form-invalid-feedback>

                  <div class="d-flex justify-content-start">
                    <a
                      href="javascript:void(0)"
                      @click="reSendVerificationCode(form.phone, 'sms')"
                      class="kt-link"
                      v-if="!timerGoS"
                    >
                      <span class="d-flex">
                        {{ $t("AUTH.REGISTER.CODE_RESEND") }}
                      </span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="poschodoch-link-reSendCode"
                      :disabled="timerGoS"
                      v-if="timerGoS"
                    >
                      <span class="d-flex">
                        {{ $t("AUTH.REGISTER.CODE_RESEND") }}
                        <div v-if="timerGoS" class="ml-2 d-flex">
                          {{ timerSMin }}:
                          <div v-if="timerSSec < 10">0</div>
                          {{ timerSSec }}
                        </div>
                      </span>
                    </a>
                  </div>
                </b-form-group>

                <b-button
                  v-show="!verificationCodeSend"
                  v-on:click="sendVerificationCode()"
                  id="kt_sendCode"
                  class="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  {{ $t("AUTH.REGISTER.SEND_CODE") }}
                </b-button>
                <b-button
                  v-show="verificationCodeSend"
                  type="submit"
                  id="kt_submit"
                  class="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  {{ $t("AUTH.REGISTER.TITLE_REG") }}
                </b-button>
                <span
                  class="kt-link kt-login__signup-link mt-3"
                  v-on:click="startOver()"
                >
                  {{ $t("AUTH.REGISTER.STARTOVER") }}
                </span>
              </div>
            </b-form>
            <!--end::Form-->
          </div>
          <!--end::Signin-->
        </div>
        <!--end::Body-->
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import {
  LOGOUT,
  REGISTER,
  LOGIN,
  CHANGE_UNIT,
  VALID_ACCOUNT,
} from "@/store/auth.module";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import AppConfig from "@/common/config";

Vue.use(AppConfig);

export default {
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      form: {
        email: "",
        facebookID: "",
        googleID: "",
        appleID: "",
        password: "",
        phone: "",
        emailVerificationCode: "",
        smsVerificationCode: "",
        registrationCode: "",
      },
      sendEmail: 1,
      showVerificationCodeInput: false,
      verificationCodeSend: false,
      isEmailRegistration: false,
      visibilityPWD: "password",
      timerEMin: 5,
      timerESec: 0,
      timerGoE: false,
      timerSMin: 5,
      timerSSec: 0,
      serviceMessages: [], // Array to store multiple service messages
      serviceMessagesVisible: false,
      timerGoS: false,
      placeholderEmail: this.$i18n.t("AUTH.GENERAL.PLACEHOLDER_EMAIL"),
      placeholderPassword: this.$i18n.t("AUTH.LOGIN.PLACEHOLDER_PWD"),
      placeholderPhone: this.$i18n.t("AUTH.REGISTER.PLACEHOLDER_PHONE"),
      placeholderCodeEmail: this.$i18n.t(
        "AUTH.REGISTER.PLACEHOLDER_CODE_EMAIL",
      ),
      placeholderCodeSMS: this.$i18n.t("AUTH.REGISTER.PLACEHOLDER_CODE_SMS"),
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        isEmailValid(email) {
          return email.length < 200;
        },
      },
      facebookID: {},
      googleID: {},
      appleID: {},
      password: {
        required,
        isPasswordValid(pwd) {
          const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,50}$/;
          return regex.test(pwd);
        },
      },
      phone: {
        required,
        isPhoneValid(phone) {
          /* povodne s pluskom
          let regex = /^\+[0-9]{10,}$/;
          if (/^\+421[0-9]{9}$/.test(phone)) regex = /^\+4219[0-9]{8}$/;
          if (/^\+420[0-9]{9}$/.test(phone)) regex = /^\+420[67][0-9]{8}$/;
          */
          let regex = /^[+][1-9]{1}[0-9]{10,}$/;
          if (/^\+421[0-9]{9}$/.test(phone)) regex = /^\+4219[0-9]{8}$/;
          if (/^\+420[0-9]{9}$/.test(phone)) regex = /^\+420[67][0-9]{8}$/;

          return regex.test(phone);
        },
      },
      smsVerificationCode: {
        required,
      },
      registrationCode: {},
      emailVerificationCode: {
        required,
      },
    },
  },
  mounted() {
    UIService.changeSiteColorCustomer();
    this.loadServiceMessages();
    //get reg. code from local storage
    this.$v.form.registrationCode.$model = localStorage.getItem(
      "registrationCode",
    );

    //if came from fb, google, apple with id param
    let parameters = this.$route.query;

    // call api to obtain token for google
    if (parameters.code && parameters.type === "google") {
      let redirectUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        "/?register-google";

      let url =
        "https://accounts.google.com/o/oauth2/token?grant_type=authorization_code&code=" +
        parameters.code +
        "&client_id=" +
        process.env.VUE_APP_GOOGLE_CLIENT_ID +
        "&client_secret=" +
        process.env.VUE_APP_GOOGLE_CLIENT_SECRET +
        "&redirect_uri=" +
        redirectUrl;

      ApiService.post(url).then((googleData) => {
        ApiService.getNoSlash(
          "https://www.googleapis.com/oauth2/v2/userinfo?alt=json&access_token=" +
            googleData.data.access_token,
        ).then((googleInfo) => {
          this.showVerificationCodeInput = true;
          this.$v.form.googleID.$model = googleInfo.data.id;
          this.$v.form.email.$model = googleInfo.data.email;
        });
      });
    }

    if (parameters.token) {
      if (parameters.type === "facebook") {
        ApiService.get(
          "https://graph.facebook.com/me?fields=name,email&access_token=" +
            parameters.token,
        ).then((facebookInfo) => {
          this.showVerificationCodeInput = true;
          this.$v.form.facebookID.$model = facebookInfo.data.id;
          this.$v.form.email.$model = facebookInfo.data.email;
        });
      }
    } else if (!localStorage.getItem("registrationCode")) {
      this.$router.replace({ name: "registrationCode" });
    }
  },
  methods: {
    async loadServiceMessages() {
      try {
        this.serviceMessages = [];
        const response = await fetch(
          "/assets/media/files/service_messages.json",
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        this.serviceMessages = data.serviceMessages;

        if (this.serviceMessages) {
          this.serviceMessagesVisible = true;
        }
      } catch (error) {
        console.error(
          "There was a problem with the fetch operation (service_message):",
          error,
        );
      }
    },
    showPassword() {
      this.visibilityPWD = "text";
    },
    hidePassword() {
      this.visibilityPWD = "password";
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        facebookID: null,
        googleID: null,
        // appleID: null,
        password: null,
        phone: null,
        emailVerificationCode: null,
        smsVerificationCode: null,
        registrationCode: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    startOver() {
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      this.showVerificationCodeInput = false;
      this.verificationCodeSend = false;
    },
    registerWithFacebook() {
      this.sendEmail = 0;
      let redirectUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        "/?register-facebook";
      window.location =
        process.env.VUE_APP_FACEBOOK_URL + redirectUrl + "&scope=email";
    },
    registerWithGoogle() {
      this.sendEmail = 0;
      let redirectUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        "/?register-google";
      window.location =
        process.env.VUE_APP_GOOGLE_URL + redirectUrl + "&scope=email";
    },
    reSendVerificationCode(item, origin) {
      let parameters = "";
      if (origin == "email") parameters = "?email=" + item.toString();
      if (origin == "sms")
        parameters = "?phone=" + encodeURIComponent(item.toString());

      this.callSendVerificationCodeApi(parameters);

      if (origin == "email") {
        this.timerGoE = true;
        this.timerECountdown();
      } else if (origin == "sms") {
        this.timerGoS = true;
        this.timerSCountdown();
      }
    },
    timerECountdown() {
      if (this.timerEMin > 0) {
        if (this.timerESec > 0) {
          setTimeout(() => {
            this.timerESec -= 1;
            this.timerECountdown();
          }, 1000);
        } else {
          setTimeout(() => {
            this.timerEMin -= 1;
            this.timerESec = 59;
            this.timerECountdown();
          }, 1000);
        }
      } else {
        if (this.timerESec > 0) {
          setTimeout(() => {
            this.timerESec -= 1;
            this.timerECountdown();
          }, 1000);
        } else {
          this.timerGoE = false;
          this.timerEMin = 5;
          this.timerESec = 0;
        }
      }
    },
    timerSCountdown() {
      if (this.timerSMin > 0) {
        if (this.timerSSec > 0) {
          setTimeout(() => {
            this.timerSSec -= 1;
            this.timerSCountdown();
          }, 1000);
        } else {
          setTimeout(() => {
            this.timerSMin -= 1;
            this.timerSSec = 59;
            this.timerSCountdown();
          }, 1000);
        }
      } else {
        if (this.timerSSec > 0) {
          setTimeout(() => {
            this.timerSSec -= 1;
            this.timerSCountdown();
          }, 1000);
        } else {
          this.timerGoS = false;
          this.timerSMin = 5;
          this.timerSSec = 0;
        }
      }
    },
    sendVerificationCode() {
      this.$v.form.phone.$touch();
      if (this.$v.form.phone.$anyError) {
        return;
      }

      UIService.addButtonSpinner("kt_sendCode");

      const phone = this.$v.form.phone.$model;
      let parameters = "";
      parameters = "?phone=" + encodeURIComponent(phone.toString());

      const facebookID = this.$v.form.facebookID.$model;
      const googleID = this.$v.form.googleID.$model;
      const appleID = this.$v.form.appleID.$model;

      //registrated with ID
      if (
        facebookID.toString() == "" &&
        appleID.toString() == "" &&
        googleID.toString() == ""
      ) {
        this.isEmailRegistration = true;
      }

      //do not send email when registrated with iD
      const email = this.$v.form.email.$model;
      //28.06.2021 emailova adr sa posiela vzdy, lebo ju treba koli updejtu UA
      parameters += "&email=" + email.toString();
      if (this.isEmailRegistration) {
        this.callSendVerificationCodeApi(parameters);
      } else {
        // clear existing errors
        this.$store.dispatch(LOGOUT);
        this.$store
          .dispatch(VALID_ACCOUNT, {
            Email: email,
            FacebookID: facebookID,
            GoogleID: googleID,
          })
          .then(() => {
            this.showVerificationCodeInput = true;
            UIService.removeButtonSpinner("kt_validateAcc");
            this.callSendVerificationCodeApi(parameters);
          })
          .catch((error) => {
            console.log(error);
            UIService.removeButtonSpinner("kt_validateAcc");
          });
      }
    },
    callSendVerificationCodeApi(parameters) {
      ApiService.setHeader();
      var that = this;
      parameters += "&sendEmail=" + this.sendEmail.toString();
      //parameters = encodeURIComponent(parameters);
      ApiService.get("Registration", "SendVerificationCode" + parameters)
        .then(({ data }) => {
          console.log(data);
          that.verificationCodeSend = true;
          UIService.removeButtonSpinner("kt_sendCode");
        })
        .catch((error) => {
          console.log(error);
          UIService.removeButtonSpinner("kt_sendCode");
        });
    },
    validateAccount() {
      this.$v.form.email.$touch();
      this.$v.form.password.$touch();
      if (this.$v.form.email.$anyError || this.$v.form.password.$anyError) {
        return;
      }

      UIService.addButtonSpinner("kt_validateAcc");

      const email = this.$v.form.email.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      this.$store
        .dispatch(VALID_ACCOUNT, { Email: email })
        .then(() => {
          this.showVerificationCodeInput = true;
          UIService.removeButtonSpinner("kt_validateAcc");
        })
        .catch((error) => {
          console.log(error);
          UIService.removeButtonSpinner("kt_validateAcc");
        });
    },
    onSubmit() {
      this.$v.form.emailVerificationCode.$touch();
      this.$v.form.smsVerificationCode.$touch();
      if (
        (this.isEmailRegistration &&
          this.$v.form.emailVerificationCode.$anyError) ||
        this.$v.form.smsVerificationCode.$anyError
      ) {
        return;
      }

      UIService.addButtonSpinner("kt_submit");

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // clear reg. code from local storage because user is about to register and reg. code is stored in form
      localStorage.removeItem("registrationCode");
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const registrationCode = this.$v.form.registrationCode.$model;
      const phone = this.$v.form.phone.$model;
      const emailVerificationCode = this.$v.form.emailVerificationCode.$model;
      const smsVerificationCode = this.$v.form.smsVerificationCode.$model;
      const facebookID = this.$v.form.facebookID.$model;
      const googleID = this.$v.form.googleID.$model;
      const appleID = this.$v.form.appleID.$model;
      this.$store
        .dispatch(REGISTER, {
          Email: email,
          PWD: password,
          RegistrationCode: registrationCode,
          Tel: phone,
          SMSCode: smsVerificationCode,
          EmailCode: emailVerificationCode,
          FacebookID: facebookID,
          GoogleID: googleID,
          AppleID: appleID,
        })
        .then(() => {
          console.log("Login");

          //login with FB
          if (facebookID != "") {
            let redirectUrl =
              window.location.protocol + "//" + window.location.host;
            window.location =
              process.env.VUE_APP_FACEBOOK_URL + redirectUrl + "&scope=email";
          }

          //login with Google
          if (googleID != "") {
            let redirectUrl =
              window.location.protocol + "//" + window.location.host;
            window.location =
              process.env.VUE_APP_GOOGLE_URL + redirectUrl + "&scope=email";
          }

          // appleID when apple API implemented

          //default login
          this.$store
            .dispatch(LOGIN, { UserName: email, Password: password })
            // go to which page after successfully login
            .then((data) => {
              if (data.length == 1) {
                // user has only one unit
                let obj = {
                  unitID: data[0].UnitId,
                  portalID: data[0].PortalId,
                  unitType: data[0].UnitType,
                };

                this.$store.dispatch(CHANGE_UNIT, obj).then(() => {
                  this.$router.push({ name: "dashboard" });
                });
              } else {
                // route to unit selection if user has more unit available
                this.$router.push({ name: "units" });
              }

              UIService.removeButtonSpinner("kt_submit");
            })
            .catch((error) => {
              console.log(error);
              UIService.removeButtonSpinner("kt_submit");
            });
        })
        .catch((error) => {
          console.log(error);
          UIService.removeButtonSpinner("kt_submit");
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    },
  },
};
</script>
