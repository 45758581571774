<template>
  <div>
    <a id="document-download-link" style="display: none"></a>

    <div class="row h-100">
      <div class="col-lg-12 col-xl-12 h-100">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <KTPortlet v-bind:title="title">
          <template v-slot:toolbar>
            <b-button variant="secondary" v-on:click="back()">
              {{ $i18n.t("CUSTOMERPAGE.TABLE.BACK") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="col-lg-9 col-sm-12">
              <div style="display: flex" class="mb-5 ml-1 mt-3">
                <b-form-checkbox
                  id="checkVisible"
                  v-model="Visible"
                  class="ml-2"
                  size="lg"
                  style
                  checked="false"
                  switch
                ></b-form-checkbox>
                <span class="mr-5 mt-1">
                  {{ $i18n.t("CUSTOMERPAGE.TABLE.VISIBLE") }}
                </span>
              </div>
            </div>
            <div class="row ml-1">
              <!-- <div class="col-lg-6 col-sm-12">                -->

              <b-form-group
                class="col-lg-4 col-sm-12"
                :label="labelNameSK"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="NameSK"
                  type="text"
                  :disabled="!EnabledFields"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col-lg-4 col-sm-12"
                :label="labelNameCZ"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="NameCZ"
                  type="text"
                  :disabled="!EnabledFields"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col-lg-4 col-sm-12"
                :label="labelNameEN"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="NameEN"
                  type="text"
                  :disabled="!EnabledFields"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <b-container fluid h-100>
              <div>
                <!--<div class="col-lg-12 col-sm-12 h-100"> -->
                <div id="app" v-if="EnabledFields">
                  <editor
                    v-model="HtmlContent"
                    :disabled="!EnabledFields"
                    api-key="ssm8a9c38382if53p3ovm1100t6jnzi43ig0huhllvfofint"
                    :init="{
                      height: 500,
                      menubar: false,
                      language: Lang,
                      invalid_elements: 'script',
                      //language_url : '/tinymce-vue/langs/cs.js',
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen table image',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | table | image | help',
                    }"
                  />
                </div>
                <!--</div>-->

                <div class="row">
                  <div class="col-lg-9 col-sm-12">
                    <div style="display: flex" class="mb-5 ml-1 mt-3">
                      <b-form-checkbox
                        id="checkPublish"
                        v-model="Publish"
                        class="ml-2"
                        size="lg"
                        style
                        checked="false"
                        switch
                      ></b-form-checkbox>
                      <span class="mr-5 mt-1">
                        {{ $i18n.t("CUSTOMERPAGE.TABLE.PUBLISH") }}
                      </span>
                      <b-form-checkbox
                        id="checkSendEmail"
                        v-model="SendEmail"
                        v-if="VisibleMail"
                        size="lg"
                        style
                        checked="false"
                        switch
                      ></b-form-checkbox>
                      <span class="mr-5 mt-1" v-if="VisibleMail">
                        {{ $i18n.t("CUSTOMERPAGE.TABLE.SEND") }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-12">
                    <div class="kt-align-right mt-3">
                      <b-button
                        variant="primary"
                        class="mr-2"
                        @click="onUlozTo()"
                        id="send_button"
                      >
                        {{ $i18n.t("CUSTOMERPAGE.TABLE.SENDBUTTON") }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>

              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!kolecko">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script src="/path/to/tinymce-vue.min.js"></script>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import Loader from "@/views/components/Loader.vue";
import Editor from "@tinymce/tinymce-vue";
import UIService from "@/common/ui.service";
import i18nService from "@/common/i18n.service.js";

export default {
  name: "domus-customerPage-detail",
  components: {
    KTPortlet,
    Loader,
    editor: Editor,
  },
  data() {
    return {
      kolecko: 0,
      customerPageId: null,
      errors: [],
      menuId: null,
      title: null,
      description: null,
      Name: null,
      NameSK: null,
      NameCZ: null,
      NameEN: null,
      Publish: 0,
      SendEmail: 0,
      CompanyMenuId: null,
      MenuCode: null,
      Order: null,
      HtmlContent: "",
      name: null,
      Visible: 0,
      VisibleMail: 0,
      Lang:
        i18nService.getActiveLanguage() == "cz"
          ? "cs"
          : i18nService.getActiveLanguage(),
      labelNameSK: this.$i18n.t("CUSTOMERPAGE.TABLE.NAMESK"),
      labelNameCZ: this.$i18n.t("CUSTOMERPAGE.TABLE.NAMECZ"),
      labelNameEN: this.$i18n.t("CUSTOMERPAGE.TABLE.NAMEEN"),
      EnabledFields: false,
      fields: [
        {
          key: "Name",
          label: this.$i18n.t("CUSTOMERPAGE.TABLE.NAME"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "NameSK",
          label: this.$i18n.t("CUSTOMERPAGE.TABLE.NAME"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "NameCZ",
          label: this.$i18n.t("CUSTOMERPAGE.TABLE.NAME"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "NameEN",
          label: this.$i18n.t("CUSTOMERPAGE.TABLE.NAME"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.customerPageId = this.$route.query.customerPageId;
    this.fetchData();
  },
  methods: {
    onUlozTo() {
      this.EnabledFields = false;
      UIService.addButtonSpinner("send_button");

      let postContent = {
        CompanyMenuId: this.CompanyMenuId,
        Name: this.Name,
        NameSK: this.NameSK,
        NameCZ: this.NameCZ,
        NameEN: this.NameEN,
        HtmlContent: this.HtmlContent,
        PageOrder: this.Order,
        Publish: this.Publish || this.Publish == 1 ? 1 : 0,
        sendEmail: this.SendEmail || this.SendEmail == 1 ? 1 : 0,
        Visible: this.Visible || this.Visible == 1 ? 1 : 0,
      };

      ApiService.post("Dashboard/CompanyMenuDetailPost", postContent)
        .then(() => {
          UIService.removeButtonSpinner("send_button");
          this.$router.push({
            name: "domus-customerPages",
            query: { menuId: this.menuId },
          });
        })
        .catch((response) => {
          this.errors.push(response.response.data.error);
          UIService.removeButtonSpinner("send_button");
          this.EnabledFields = true;
        });
    },
    back() {
      this.$router.push({
        name: "domus-customerPages",
        query: { menuId: this.menuId },
      });
    },

    fetchData() {
      this.items = null;
      let parameters =
        /*"?menuId=" +
        this.menuId.toString() +*/
        "?companyMenuId=" + this.customerPageId.toString();

      ApiService.setHeader();
      ApiService.get("Dashboard", "CompanyMenuPrivateDetail" + parameters).then(
        ({ data }) => {
          this.kolecko = 1;
          this.title = data.Name;
          this.description = data.Description;
          this.Name = data.Name;
          this.NameSK = data.NameSK;
          this.NameCZ = data.NameCZ;
          this.NameEN = data.NameEN;
          this.CompanyMenuId = data.CompanyMenuId;
          this.MenuCode = data.MenuCode;
          this.Order = data.PageOrder;
          this.HtmlContent = data.HtmlContent;
          this.Visible = data.Visible || data.Visible == 1 ? true : false;

          if (data.Order == 4) {
            this.VisibleMail = 1;
          }
          this.EnabledFields = true;
          //PageOrder 4      len pre nastenku
        },
      );
    },
  },
};
</script>
