<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <!--Begin::Rocne vyuctovanie uzivatela-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid>
              <div class="col-lg-5">
                <span class="d-flex flex-row align-items-center mr-sm-3 mr-0">
                  <label for="dF" class="mr-1" style="font-weight: 500">
                    Kod:
                  </label>
                  <b-form-input id="dF" v-model="inputCode"></b-form-input>
                  <b-button
                    variant="secondary"
                    class="ml-md-2 ml-sm-3 mt-3 mt-md-0"
                    :disabled="!inputCode"
                    v-on:click="getCodeInfo()"
                  >
                    Go!
                  </b-button>
                </span>
              </div>

              <div
                class="col-lg-6"
                style="margin-top: 10px;"
                v-if="CustomerCode"
              >
                <b-row class="mb-3">
                  <b-col class="col-6">
                    <label>IP</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ DbIP1 }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Meno zakaznika</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ CustomerName }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Kod zakaznika</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ CustomerCode }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Je aktivny</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ IsAktivny }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Ma EULA</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ maeula }}</label>
                  </b-col>
                </b-row>
                <b-button
                  variant="secondary"
                  class="ml-md-2 ml-sm-3 mt-3 mt-md-0"
                  v-on:click="getCodeUserInfo()"
                >
                  Zisti uzivatela
                </b-button>
              </div>

              <div class="col-lg-6" style="margin-top: 10px;" v-if="VS">
                <b-row class="mb-3">
                  <b-col class="col-6">
                    <label>VS</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ VS }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Name</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ Name }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Address</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ Address }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Uzivatel(0)/funkcionar(1)</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ UnitType }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Typ uzivatela</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ UserType }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Typ funkcionara</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ FuncType }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Byva od:</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ DtValidFrom }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Byva do:</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ DtValidTo }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>ObjectId</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ ObjectId }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>UnitCode</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ UnitCode }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>UnitNumber</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ UnitNumber }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Popis</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ UnitDescription }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Zakazany portal(VCS)</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ LiNoPortal }}</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>Domfunkc je povoleny na portali</label>
                  </b-col>
                  <b-col class="col-6">
                    <label>{{ Domfunkc_is_portal }}</label>
                  </b-col>
                </b-row>
              </div>
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!kolecko">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Rocne vyuctovanie uzivatela-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import Loader from "@/views/components/Loader.vue";

export default {
  name: "admin-check-code",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      errors: [],
      title: null,
      description: null,
      menuId: null,
      inputCode: null,
      items: null,
      kolecko: null,
      sortBy: "",
      sortDirection: "desc",
      DbIP1: null,
      CustomerName: null,
      CustomerCode: null,
      IsAktivny: null,
      maeula: null,
      VS: null,
      Name: null,
      Address: null,
      UnitType: null,
      UserType: null,
      FuncType: null,
      DtValidFrom: null,
      DtValidTo: null,
      ObjectId: null,
      UnitCode: null,
      UnitNumber: null,
      UnitDescription: null,
      LiNoPortal: null,
      Odstah: null,
      Domfunkc_is_portal: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.kolecko = 1;
    },
    getCodeInfo() {
      this.kolecko = null;
      this.items = null;
      this.VS = null;
      this.errors = [];
      let parameters = "?code=" + this.inputCode.toString();

      ApiService.setHeader();
      ApiService.get("Auth", "CodeInfo" + parameters).then(({ data }) => {
        this.DbIP1 = data.DbIP1;
        this.CustomerName = data.CustomerName;
        this.CustomerCode = data.CustomerCode;
        this.IsAktivny = data.IsAktivny;
        this.maeula = data.maeula;
        this.kolecko = 1;
        if (!this.CustomerCode) this.errors.push("Nenajdeny zakaznik");
      });
    },
    getCodeUserInfo() {
      this.items = null;
      this.kolecko = null;
      this.errors = [];
      let parameters = "?registrationCode=" + this.inputCode.toString();

      ApiService.setHeader();
      ApiService.get("Registration", "CodeUserInfo" + parameters).then(
        ({ data }) => {
          this.VS = data.VS;

          this.Name = data.Name;
          this.Address = data.Address;
          this.UnitType = data.UnitType;
          this.UserType = data.UserType;
          this.FuncType = data.FuncType;
          this.DtValidFrom = data.DtValidFrom;
          this.DtValidTo = data.DtValidTo;
          this.ObjectId = data.ObjectId;
          this.UnitCode = data.UnitCode;
          this.UnitNumber = data.UnitNumber;
          this.UnitDescription = data.UnitDescription;
          this.LiNoPortal = data.LiNoPortal;
          this.Odstah = data.Odstah;
          this.Domfunkc_is_portal = data.Domfunkc_is_portal;
          if (!this.VS) this.errors.push("Nenajdeny uzivatel");
          this.kolecko = 1;
        },
      );
    },
  },
};
</script>
