const ID_TOKEN_KEY = "id_token";
const REFRESH_TOKEN_AFTER = "refresh_token_after";
const ID_REFRESH_TOKEN_KEY = "id_refresh_token";
const ID_UNIT = "id_unit";
const UNIT_TYPE = "unit_type";
const PORTAL_ID = "portal_id";
const USERNAME = "username";
const CURRENCY = "currency";
const NOTIF = "notif";
const DISABLEDNOTIF = "disablednotif";
const ROUTE = "redir_path_x";
const ROUTE1 = "Route";
const ROUTE2 = "route";

import router from "@/router";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = (token, expires_in, refresh_token) => {
  let refresh_token_after = new Date();
  refresh_token_after.setSeconds(
    refresh_token_after.getSeconds() + expires_in / 2,
  );

  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(REFRESH_TOKEN_AFTER, refresh_token_after);
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, refresh_token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_AFTER);
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
  window.localStorage.removeItem(ID_UNIT);
  window.localStorage.removeItem(UNIT_TYPE);
  window.localStorage.removeItem(PORTAL_ID);
  window.localStorage.removeItem(USERNAME);
  window.localStorage.removeItem(CURRENCY);
  window.localStorage.removeItem(NOTIF);
  window.localStorage.removeItem(DISABLEDNOTIF);
  window.localStorage.removeItem(ROUTE1);
  window.localStorage.removeItem(ROUTE2);
};

export const needRefresh = () => {
  let refresh_token_after = window.localStorage.getItem(REFRESH_TOKEN_AFTER);
  if (refresh_token_after == null || refresh_token_after == undefined)
    return false;

  let now = new Date();
  let date_refresh_token_after = Date.parse(refresh_token_after);

  if (now > date_refresh_token_after) return true;
  return false;
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

export const getUnit = () => {
  return window.localStorage.getItem(ID_UNIT);
};
export const getPortalId = () => {
  return window.localStorage.getItem(PORTAL_ID);
};
export const saveUnit = (unitId, unitType, portalID) => {
  window.localStorage.setItem(ID_UNIT, unitId);
  window.localStorage.setItem(UNIT_TYPE, unitType);
  window.localStorage.setItem(PORTAL_ID, portalID);
};

export const getUsername = () => {
  return window.localStorage.getItem(USERNAME);
};

export const saveUsername = (username) => {
  window.localStorage.setItem(USERNAME, username);
};

export const getNotif = () => {
  return window.localStorage.getItem(NOTIF);
};

export const setNotif = (notif) => {
  window.localStorage.setItem(NOTIF, notif);
};

export const getDisabledNotif = () => {
  return window.localStorage.getItem(DISABLEDNOTIF);
};

export const setDisabledNotif = (disablednotif) => {
  window.localStorage.setItem(DISABLEDNOTIF, disablednotif);
};

export const getCurrency = () => {
  return window.localStorage.getItem(CURRENCY);
};

export const saveCurrency = (currency) => {
  window.localStorage.setItem(CURRENCY, currency);
};

export const getRoute = () => {
  return window.localStorage.getItem(ROUTE);
};

export const setRoute = (route) => {
  window.localStorage.setItem(ROUTE, route);
};
export const loginRoute = () => {
  let route = getRoute();

  setRoute(null);
  if (route == "null" || route == "undefined" || route == null || !route) {
    router.push({ name: "dashboard" });
  } else {
    router.push({ path: route });
  }
  return;
};

export default {
  getToken,
  saveToken,
  destroyToken,
  needRefresh,
  getRefreshToken,
  getUnit,
  getPortalId,
  saveUnit,
  getUsername,
  saveUsername,
  getCurrency,
  saveCurrency,
  getNotif,
  setNotif,
  getDisabledNotif,
  setDisabledNotif,
  getRoute,
  setRoute,
  loginRoute,
};
