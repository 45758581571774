<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Bids-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(WWW)="data">
                  <b-link
                    :href="'//' + data.item.WWW"
                    target="_blank"
                    class="kt-link"
                  >
                    {{ data.item.WWW }}
                  </b-link>
                </template>
                <template v-slot:cell(Email)="data">
                  <a :href="`mailto:${data.item.Email}`" class="kt-link">
                    {{ data.item.Email }}
                  </a>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Bids-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";

export default {
  name: "partnersUser",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "Description",
          label: this.$i18n.t("COSTS.TABLE.ANALYSIS"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Partner",
          label: this.$i18n.t("INVOICE_DETAIL.CUSTOMER"),
          sortable: true,
          sortDirection: "desc",
          class: "table-mobile",
        },
        {
          key: "Telephone",
          label: this.$i18n.t("ACCOUNTPROFILE.PHONE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Email",
          label: this.$i18n.t("ACCOUNTPROFILE.EMAIL"),
          sortable: true,
          class: "text-center table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "WWW",
          label: this.$i18n.t("OBJECTOFFICIALS.TABLE.WWW"),
          sortable: true,
          class: "text-center table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Detail",
          label: this.$i18n.t("BUILDINGELEMENTS.TABLE.NOTE"),
          sortable: true,
          class: "text-right table-no-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },

  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("DashBoard", "Partners" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Partners;

        if (data.Partners) this.transformData(data.Partners);
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Description: items[i].Description,
          Partner: items[i].Partner,
          Telephone: items[i].Telephone,
          Email: items[i].Email,
          WWW: items[i].WWW,
          Detail: items[i].Detail,
        };
      }
    },
  },
};
</script>
