<template>
  <div>
    <InvoicesDocs v-bind:invoicesDocs="invoicesDocs" />
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::UnpaidDebtsObject-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
              >
                <template v-slot:cell(DocCount)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.value > 1"
                    v-on:click="
                      showInvoicesDocs(data.item.RecKey, data.item.Tablename)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    v-if="data.value == 1"
                    v-on:click="
                      showSingleDoc(data.item.RecKey, data.item.Tablename)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(PaymentStatusIcon)="data">
                  <span
                    v-if="
                      data.item.PaymentStatus != null &&
                        data.item.PaymentStatus != 'N'
                    "
                    class="poschodoch-note-list"
                    v-b-tooltip.hover
                    :title="$i18n.t('UNPAID_DEBTS.TABLE.IS_BEING_VERIFIED')"
                  >
                    <span class="poschodoch-note ml-3"></span>
                  </span>
                </template>

                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        {{ data.value.VS + " " + data.value.Name }}
                      </div>
                    </div>
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        {{
                          data.value.ApartmentType +
                            ", " +
                            data.value.ApartmentNumber
                        }}
                      </div>
                    </div>
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <b>
                          {{ data.value.ClaimName + " " + data.value.Period }}
                        </b>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span
                          v-bind:class="[
                            data.value.ToBePaid -
                              (data.value.Paid != 0 ? data.value.Paid : 0) <
                            0
                              ? 'color-danger'
                              : '',
                          ]"
                        >
                          {{
                            getFormatService().formatNumberToLocal(
                              data.value.ToBePaid -
                                (data.value.Paid != 0 ? data.value.Paid : 0),
                            )
                          }}
                        </span>
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount > 1"
                            v-on:click="
                              showInvoicesDocs(
                                data.item.RecKey,
                                data.item.Tablename,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.RecKey,
                                data.item.Tablename,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        {{
                          $t("UNPAID_DEBTS.TABLE.PAYMENT_DUE") +
                            getFormatService().formatDateToLocal(
                              data.value.DueDate,
                            )
                        }}
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span
                          v-if="
                            data.item.PaymentStatus != null &&
                              data.item.PaymentStatus != 'N'
                          "
                          class="poschodoch-note-list"
                          v-b-tooltip.hover
                          :title="
                            $i18n.t('UNPAID_DEBTS.TABLE.IS_BEING_VERIFIED')
                          "
                        >
                          <span class="poschodoch-note ml-3"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::UnpaidDebtsObject-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";
import InvoicesDocs from "@/views/pages/domus/InvoicesDocs.vue";

export default {
  name: "unpaidDebtsObject",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    InvoicesDocs,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      invoicesDocs: null,
      fields: [
        {
          key: "VS",
          label: this.$i18n.t("UNPAID_DEBTS.TABLE.VS"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "ApartmentNumber",
          label: this.$i18n.t("UNPAID_DEBTS.TABLE.APARTMENT_NUMBER"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
        },
        {
          key: "ApartmentType",
          label: this.$i18n.t("UNPAID_DEBTS.TABLE.APARTMENT_TYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Name",
          label: this.$i18n.t("UNPAID_DEBTS.TABLE.NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "ClaimName",
          label: this.$i18n.t("UNPAID_DEBTS.TABLE.CLAIM_NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Period",
          label: this.$i18n.t("UNPAID_DEBTS.TABLE.PERIOD"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "ToBePaid",
          label: this.addCurrencyToColumn(
            this.$i18n.t("UNPAID_DEBTS.TABLE.TO_BE_PAID"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (value < 0) {
              item._cellVariants = { ToBePaid: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Paid",
          label: this.addCurrencyToColumn(
            this.$i18n.t("UNPAID_DEBTS.TABLE.PAID"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DueDate",
          label: this.$i18n.t("UNPAID_DEBTS.TABLE.DUE_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        /*         {
          key: "PaymentStatus",
          label: this.$i18n.t("UNPAID_DEBTS.TABLE.PAYMENT_STATUS"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: value => {
            return value == "O" ? this.$i18n.t("UNPAID_DEBTS.TABLE.IS_BEING_VERIFIED") : "";
          } 
        },   */
        {
          key: "PaymentStatusIcon",
          label: "",
          sortable: false,
          class: "table-no-mobile text-center",
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDocs = data;

        parameters =
          "?docId=" + this.invoicesDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.invoicesDocs.Documents[0].FileName;

          FileService.downloadFile(fileName, binaryContent);
        });
      });
    },
    showInvoicesDocs(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDocs = data;
        this.$bvModal.show("modal-invoices-docs");
      });
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      window.open("/UnpaidDebtsObjectPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("UNPAID_DEBTS.TABLE.VS"),
        this.$i18n.t("UNPAID_DEBTS.TABLE.APARTMENT_NUMBER"),
        this.$i18n.t("UNPAID_DEBTS.TABLE.APARTMENT_TYPE"),
        this.$i18n.t("UNPAID_DEBTS.TABLE.NAME"),
        this.$i18n.t("UNPAID_DEBTS.TABLE.CLAIM_NAME"),
        this.$i18n.t("UNPAID_DEBTS.TABLE.PERIOD"),
        this.addCurrencyToColumn(this.$i18n.t("UNPAID_DEBTS.TABLE.TO_BE_PAID")),
        this.addCurrencyToColumn(this.$i18n.t("UNPAID_DEBTS.TABLE.PAID")),
        this.$i18n.t("UNPAID_DEBTS.TABLE.DUE_DATE"),
        this.$i18n.t("UNPAID_DEBTS.TABLE.PAYMENT_STATUS"),
      ];
      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.VS,
          item.ApartmentNumber,
          item.ApartmentType,
          item.Name,
          item.ClaimName,
          item.Period,
          FormatService.formatNumberToLocalForCSV(item.ToBePaid),
          FormatService.formatNumberToLocalForCSV(item.Paid),
          FormatService.formatDateToLocal(item.DueDate),
          item.PaymentStatus == "O"
            ? this.$i18n.t("UNPAID_DEBTS.TABLE.IS_BEING_VERIFIED")
            : "",
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?paymentType=NZ&paymentStatus=ON&menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "UnpaidDebts" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.UnpaidDebts;

        //if (this.menuId == 449)
        //  this.fields.splice(0, 4);

        this.transformData(this.items);
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          VS: items[i].VS,
          ApartmentNumber: items[i].ApartmentNumber,
          ApartmentType: items[i].ApartmentType,
          Name: items[i].Name,
          ClaimName: items[i].ClaimName,
          Period: items[i].Period,
          ToBePaid: items[i].ToBePaid,
          Paid: items[i].Paid,
          DueDate: items[i].DueDate,
          PaymentStatus: items[i].PaymentStatus,
          DocCount: items[i].DocCount,
        };
      }
    },
  },
};
</script>
