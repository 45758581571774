<template>
  <div class="col-lg-5 col-xs-12 col-md-6 col-sm-7 mt-1">
    <b-card
      v-show="serviceMessagesVisible"
      class="mb-3 text-center"
      text-variant="black"
      style="border-style: hidden; background-color: var(--company-color) !important"
    >
      <b-card-body>
        <!-- Iterate over each message in the serviceMessages array -->
        <div
          class="row justify-content-center"
          v-for="(message, index) in serviceMessages"
          :key="index"
        >
          <div class="col text-center">
            <!-- Display each message in a centered span -->
            <span class="mt-3 poschodoch-homepage-text">
              {{ message.message }}
            </span>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <KTPortlet>
      <template v-slot:body>
        <div class="kt-login__body">
          <div class="kt-login__form text-center">
            <div class="kt-login__title mb-4">
              <h3>{{ $t("AUTH.REGISTRATION_CODE.TITLE") }}</h3>
            </div>
            <div class="kt-login__title mb-4">
              <h6>{{ $t("AUTH.REGISTRATION_CODE.TEXT") }}</h6>
            </div>
            <b-form class="kt-form" @submit.stop.prevent="onSubmit">
              <div
                role="alert"
                v-bind:class="{ show: errorsl && errorsl.length }"
                class="alert fade alert-danger mt-2 mb-2"
              >
                <div class="alert-text" v-for="(error, i) in errorsl" :key="i">
                  {{ error }}
                </div>
              </div>
              <div
                role="alert"
                v-bind:class="{ show: errors && errors.length }"
                class="alert fade alert-danger mt-2 mb-2"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>
              <b-form-group
                id="registration-code-group"
                label
                label-for="registration-code-input"
              >
                <b-form-input
                  id="registration-code-input"
                  name="registration-code-input"
                  v-model="$v.form.registrationCode.$model"
                  :state="validateState('registrationCode')"
                  aria-describedby="registration-code-live-feedback"
                  :placeholder="placeholderRegCode"
                ></b-form-input>

                <b-form-invalid-feedback id="registration-code-live-feedback">
                  {{ $t("AUTH.REGISTRATION_CODE.REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="text-left" v-if="errorsl.length > 0">
                <h6 small class="font-weight-light ml-1 mb-2">
                  {{ $t("AUTH.REGISTRATION_CODE.HINT1") }}
                </h6>
                <h6 small class="font-weight-light ml-1 mb-0">
                  {{ $t("AUTH.REGISTRATION_CODE.HINT2") }}
                </h6>
                <h6 small class="font-weight-light ml-1 mb-0">
                  {{ $t("AUTH.REGISTRATION_CODE.HINT3") }}
                </h6>
                <h6 small class="font-weight-light ml-1 mb-2">
                  {{ $t("AUTH.REGISTRATION_CODE.HINT4") }}
                </h6>
                <h6 small class="font-weight-light ml-1 mb-0">
                  {{ $t("AUTH.REGISTRATION_CODE.HINT5") }}
                </h6>
              </div>

              <div class="kt-login__actions mt-4">
                <b-button block type="submit" id="kt_submit" variant="primary">
                  {{ $t("AUTH.REGISTRATION_CODE.SEND_BUTTON") }}
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { mapState } from "vuex";
import { REGISTRATION_CODE } from "@/store/auth.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import UIService from "@/common/ui.service";

export default {
  mixins: [validationMixin],
  name: "registrationCode",
  components: {
    KTPortlet,
  },
  data() {
    return {
      serviceMessages: [], // Array to store multiple service messages
      serviceMessagesVisible: false,
      form: {
        registrationCode: "",
        HintVisible: false,
      },
      placeholderRegCode: this.$i18n.t(
        "AUTH.REGISTRATION_CODE.PLACEHOLDER_REGCODE",
      ),
      haveAcc: false,
      errorsl: [],
    };
  },
  validations: {
    form: {
      registrationCode: {
        required,
        isRegCodeValid(code) {
          const regex = /^[^oO!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]{11}|[^oO!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]{12}$/;
          return regex.test(code);
          // any letters or digits except for letter 'O' (and 'o' therefore) ... length 11 or 12
        },
      },
    },
  },
  mounted: function() {
    //set default brand color
    UIService.changeSiteColorCustomer();
    this.loadServiceMessages();
    if (this.$route.params.haveAccount) this.haveAcc = true;
  },
  methods: {
    async loadServiceMessages() {
      try {
        this.serviceMessages = [];
        const response = await fetch(
          "/assets/media/files/service_messages.json",
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        this.serviceMessages = data.serviceMessages;

        if (this.serviceMessages) {
          this.serviceMessagesVisible = true;
        }
      } catch (error) {
        console.error(
          "There was a problem with the fetch operation (service_message):",
          error,
        );
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        registrationCode: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.errorsl = [];
      const registrationCode = this.$v.form.registrationCode.$model;

      UIService.addButtonSpinner("kt_submit");

      this.$store
        .dispatch(REGISTRATION_CODE, {
          RegistrationCode: registrationCode,
          HaveAccount: this.haveAcc,
        })
        .catch((error) => {
          // this.errorsl.push(error.data.error)
          this.HintVisible = true;
          console.log(error);
          UIService.removeButtonSpinner("kt_submit");
        });

      UIService.removeButtonSpinner("kt_submit");
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    },
  },
};
</script>
