<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Signature Sheet-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <div class="row">
                <b-col>
                  <div class="mb-4">
                    <div class="mb-2">{{ $i18n.t("SHEETINPUT.TEXT1") }}</div>
                    <b-form-input
                      v-model="inputText1"
                      type="text"
                      :placeholder="$i18n.t('SIGNATURESHEET.PLACEHOLDER1')"
                    ></b-form-input>
                  </div>
                </b-col>
              </div>
              <div class="row">
                <b-col sm="12" lg="3">
                  <div class="mb-3">
                    <div class="mb-2">{{ $i18n.t("SHEETINPUT.USERS") }}</div>
                    <b-form-select
                      v-model="inputUsers"
                      :options="optionsUsers"
                      v-on:change="fetchData()"
                    ></b-form-select>
                  </div>
                </b-col>
                <b-col sm="12" lg="3">
                  <div class="mb-3">
                    <div class="mb-2">{{ $i18n.t("SHEETINPUT.ROOMS") }}</div>
                    <b-form-select
                      v-model="inputRooms"
                      :options="optionsRooms"
                      v-on:change="fetchData()"
                    ></b-form-select>
                  </div>
                </b-col>
                <b-col sm="12" lg="3">
                  <div class="mb-3">
                    <div class="mb-2">{{ $i18n.t("SHEETINPUT.PORTIONS") }}</div>
                    <b-form-select
                      v-model="inputPortion"
                      :options="optionsPortions"
                      v-on:change="fetchData()"
                    ></b-form-select>
                  </div>
                </b-col>
                <b-col sm="12" lg="3" v-if="gateOptions.length > 1">
                  <div class="mb-3">
                    <div class="mb-2">
                      {{ $i18n.t("REPAIRFUND.FILTER.GATE") }}
                    </div>
                    <b-form-select
                      v-model="inputGate"
                      :options="gateOptions"
                      v-on:change="fetchData()"
                    ></b-form-select>
                  </div>
                </b-col>
              </div>
              <div class="mb-3">{{ objectNumber + " " + objectAddress }}</div>

              <!--Begin::Main table element -->
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
              >
                <template v-slot:head(Portion)="data">
                  <span v-if="inputPortion != '3'">{{ data.label }}</span>
                  <span v-else></span>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Signature Sheet-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import FileService from "@/common/file.service";

export default {
  name: "signatureSheet",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      menuId: null,
      title: null,
      description: null,
      inputText1: null,
      inputUsers: "A",
      inputRooms: "T",
      inputPortion: 1,
      inputGate: null,
      items: null,
      objectAddress: null,
      objectNumber: null,
      gateOptions: [
        { value: null, text: this.$i18n.t("REPAIRFUND.FILTER.PLACEHOLDER") },
      ],
      optionsUsers: [
        { value: "A", text: this.$i18n.t("SHEETS.OPTIONS.ALL") },
        { value: "V", text: this.$i18n.t("SHEETS.OPTIONS.OWNERS") },
        { value: "N", text: this.$i18n.t("SHEETS.OPTIONS.TENANTS") },
      ],
      optionsRooms: [
        { value: "T", text: this.$i18n.t("SHEETS.OPTIONS.ALLB") },
        { value: "V", text: this.$i18n.t("SHEETS.OPTIONS.NOB") },
        { value: "B", text: this.$i18n.t("SHEETS.OPTIONS.YESB") },
      ],
      optionsPortions: [
        { value: "1", text: this.$i18n.t("SHEETS.OPTIONS.PERC") },
        { value: "2", text: this.$i18n.t("SHEETS.OPTIONS.FRAC") },
        { value: "3", text: this.$i18n.t("SHEETS.OPTIONS.HIDE") },
      ],
      fields: [
        {
          key: "VS",
          label: this.$i18n.t("SIGNATURESHEET.TABLE.VS"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "ApartmentNr",
          label: this.$i18n.t("SIGNATURESHEET.TABLE.APARTMENTNR"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Name",
          label: this.$i18n.t("SIGNATURESHEET.TABLE.NAME"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Portion",
          label: this.$i18n.t("SIGNATURESHEET.TABLE.PORTION"),
          sortable: false,
          sortDirection: "desc",
          hidden: true,
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    //this.userType = this.$route.query.userType;
    //this.roomType = this.$route.query.roomType;
    //this.portion = this.$route.query.portion;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.inputUsers != null)
        parameters += "&userType=" + this.inputUsers.toString();
      else parameters += "&userType=A";
      if (this.inputRooms != null)
        parameters += "&roomType=" + this.inputRooms.toString();
      else parameters += "&roomType=T";
      if (this.inputPortion != null)
        parameters += "&portion=" + this.inputPortion.toString();
      else parameters += "&portion=1";
      if (this.inputGate != null)
        parameters += "&gate=" + this.inputGate.toString();
      if (this.inputText1 != null) parameters += "&text1=" + this.inputText1;

      // Pridáme objectAddress a objectNumber do parametrov
      if (this.objectAddress != null)
        parameters +=
          "&objectAddress=" + encodeURIComponent(this.objectAddress);
      if (this.objectNumber != null)
        parameters += "&objectNumber=" + encodeURIComponent(this.objectNumber);

      window.open("/signatureSheetPrint" + parameters);
    },
    downloadCSV() {
      let header = [];
      let rows = [];
      if (this.inputPortion != "3") {
        header = [
          this.$i18n.t("SIGNATURESHEET.TABLE.VS"),
          this.$i18n.t("SIGNATURESHEET.TABLE.APARTMENTNR"),
          this.$i18n.t("SIGNATURESHEET.TABLE.NAME"),
          this.$i18n.t("SIGNATURESHEET.TABLE.PORTION"),
        ];
        this.items.forEach(function(item) {
          let row = [item.VS, item.ApartmentNr, item.Name, item.Portion];
          rows.push(row);
        });
      } else {
        header = [
          this.$i18n.t("SIGNATURESHEET.TABLE.VS"),
          this.$i18n.t("SIGNATURESHEET.TABLE.APARTMENTNR"),
          this.$i18n.t("SIGNATURESHEET.TABLE.NAME"),
        ];
        this.items.forEach(function(item) {
          let row = [item.VS, item.ApartmentNr, item.Name];
          rows.push(row);
        });
      }

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";
      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    setGateOptions(items) {
      for (let i = 0; i < items.length; i++) {
        let gate = items[i];
        this.gateOptions.push({ value: gate.Gate, text: gate.Text });
      }
    },
    fetchData() {
      this.items = null;
      this.gateOptions.splice(1, this.gateOptions.length - 1);
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.inputUsers != null)
        parameters += "&userType=" + this.inputUsers.toString();
      else parameters += "&userType=A";
      if (this.inputRooms != null)
        parameters += "&roomType=" + this.inputRooms.toString();
      else parameters += "&roomType=T";
      if (this.inputPortion != null)
        parameters += "&portion=" + this.inputPortion.toString();
      else parameters += "&portion=1";
      if (this.inputGate != null)
        parameters += "&gate=" + this.inputGate.toString();

      ApiService.setHeader();
      ApiService.get("Object", "SignatureSheet" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.objectAddress = data.ObjectAddress;
          this.objectNumber = data.ObjectNumber;
          this.items = data.SignatureSheet;
          if (data.ParmGate != null) this.setGateOptions(data.ParmGate);

          // Update the object address and number based on the selected gate
          if (this.inputGate) {
            const selectedGate = this.gateOptions.find(
              (gate) => gate.value === this.inputGate,
            );
            if (selectedGate) {
              this.objectAddress = selectedGate.text;
            }
          }
        },
      );
    },
  },
};
</script>
