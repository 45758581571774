<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <KTPortlet>
        <!--Begin::Data -->
        <template v-slot:body>
          <b-container fluid>
            <div
              v-if="PrizeForPortal"
              v-html="PrizeForPortal"
              class="mt-4 font-weight-bold text-center"
            ></div>
            <div
              v-if="PrizeForPortal2"
              v-html="PrizeForPortal2"
              class="font-weight-bold text-center"
            ></div>
            <div v-html="htmlContent" class="p-4"></div>
          </b-container>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";

export default {
  name: "registrationTerms",
  components: {
    KTPortlet,
  },
  data() {
    return {
      htmlContent: null,
      parameterType: null,
      PrizeForPortal: null,
      PrizeForPortal2: null,
    };
  },
  mounted: function() {
    UIService.changeSiteColorCustomer();
    //if (this.$route.params.type) {
    //this.parameterType = this.$route.params.type;
    this.parameterType = this.$route.query.type;
    //}
    this.fetchData();
    /*if (!localStorage.getItem("registrationTerms")) {
      this.$router.replace({ name: "login" });
    }

    this.initializeTerms(localStorage.getItem("registrationTerms"));*/
  },
  methods: {
    fetchData() {
      let parameters = "?Type=A";
      //let parmAp = "Registration";

      if (this.parameterType == "0") {
        parameters = "?Type=A";
        ApiService.setHeader();
        ApiService.get("Registration", "Terms" + parameters)
          .then(({ data }) => {
            this.customerName = data.CustomerName;
            this.htmlContent = data.Content;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.parameterType == "1") {
        parameters = "?Type=Z&code=" + localStorage.getItem("registrationCode");
        ApiService.setHeader();
        ApiService.get("Registration", "Terms" + parameters)
          .then(({ data }) => {
            this.customerName = data.CustomerName;
            this.htmlContent = data.Content;
            this.PrizeForPortal = data.FooterText;
            this.PrizeForPortal2 = data.FooterText2;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.parameterType == "2") {
        parameters = "?Type=A";
        //parmAp = "Dashboard";
        ApiService.setHeader();
        ApiService.get("Registration", "Terms" + parameters)
          .then(({ data }) => {
            this.customerName = data.CustomerName;
            this.htmlContent = data.Content;
            //this.PrizeForPortal = data.FooterText;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.parameterType == "3") {
        parameters = "?Type=Z";
        //parmAp = "Dashboard";
        ApiService.setHeader();
        ApiService.get("Dashboard", "CommercialTerms" + parameters)
          .then(({ data }) => {
            this.customerName = data.CustomerName;
            this.htmlContent = data.Content;
            this.PrizeForPortal = data.FooterText;
            this.PrizeForPortal2 = data.FooterText2;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      //Podmienky poskytovateľa pre unbru, pouzijem registration terms...lebo...nemam zakaznika
      if (this.parameterType == "4") {
        parameters = "?Type=ESK";
        //parmAp = "Dashboard";
        ApiService.setHeader();
        ApiService.get("Registration", "Terms" + parameters)
          .then(({ data }) => {
            this.customerName = data.CustomerName;
            this.htmlContent = data.Content;
            //this.PrizeForPortal = data.FooterText;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      //Podmienky poskytovateľa pre unbru, pouzijem registration terms...lebo...nemam zakaznika...to iste len ceska enbra
      if (this.parameterType == "5") {
        parameters = "?Type=ECZ";
        //parmAp = "Dashboard";
        ApiService.setHeader();
        ApiService.get("Registration", "Terms" + parameters)
          .then(({ data }) => {
            this.customerName = data.CustomerName;
            this.htmlContent = data.Content;
            //this.PrizeForPortal = data.FooterText;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.parameterType == "6") {
        parameters = "?Type=PSK";
        //parmAp = "Dashboard";
        ApiService.setHeader();
        ApiService.get("Registration", "Terms" + parameters)
          .then(({ data }) => {
            this.customerName = data.CustomerName;
            this.htmlContent = data.Content;
            //this.PrizeForPortal = data.FooterText;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      /*ApiService.setHeader();
      ApiService.get(parmAp.toString(), parameters.toString()).then(
        ({ data }) => {
          this.customerName = data.CustomerName;
          this.htmlContent = data.Content;
        }
      ).catch((error) => {
          console.log(error);         
      });*/
    },
    /*initializeTerms(terms) {
      this.htmlContent = terms;
    },*/
  },
};
</script>
