<template>
  <div>
    <AnnualBillingDetail v-bind:annualBillingDetail="annualBillingDetail" />
    <AnnualBillingDocs v-bind:annualBillingDocs="annualBillingDocs" />

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Rocne vyuctovanie uzivatela-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="poschodoch-anbual-billing-table"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(DocCount)="data">
                  <div
                    @click.stop
                    class="h-100 w-100 p-2 d-flex align-items-center justify-content-center"
                    style="cursor: default"
                  >
                    <a
                      href="javascript:void(0)"
                      v-if="data.value > 1"
                      v-on:click="
                        showAnnualBillingDocs(
                          data.item.RecKey,
                          data.item.TableName,
                        )
                      "
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      v-if="data.value == 1"
                      v-on:click="
                        showSingleDoc(data.item.RecKey, data.item.TableName)
                      "
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-download poschodoch-icon-medium"></i>
                    </a>
                  </div>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div
                    @click.stop
                    class="ml-3 d-flex flex-column align-items-start w-100"
                  >
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <a
                          href="javascript:void(0)"
                          v-if="data.item != null"
                          v-on:click="
                            showAnnualBillingDetail(
                              menuId,
                              data.item.PeriodId,
                              data.item.Account,
                              data.item.Group,
                              data.item.AccountType,
                            )
                          "
                          class="kt-link"
                        >
                          {{ data.value.Name }}
                        </a>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right"
                      >
                        <span
                          v-bind:class="{
                            'table-danger': data.value.FinalBalance < 0,
                          }"
                        >
                          {{ data.value.FinalBalance }}
                        </span>
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount > 1"
                            v-on:click="
                              showAnnualBillingDocs(
                                data.item.RecKey,
                                data.item.TableName,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.RecKey,
                                data.item.TableName,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(Name)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="
                      showAnnualBillingDetail(
                        menuId,
                        data.item.PeriodId,
                        data.item.Account,
                        data.item.Group,
                        data.item.AccountType,
                      )
                    "
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                  <span v-if="!(data.item != null)">{{ data.value }}</span>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Rocne vyuctovanie uzivatela-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import AnnualBillingDetail from "@/views/pages/domus/AnnualBillingDetail.vue";
import AnnualBillingDocs from "@/views/pages/domus/AnnualBillingDocs.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "annualbilling",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    AnnualBillingDetail,
    AnnualBillingDocs,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      annualBillingDetail: null,
      annualBillingDocs: null,
      items: null,
      fields: [
        {
          key: "Name",
          label: this.$i18n.t("ANNUALBILLING.TABLE.NAME"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "PaymentOrder",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.PAYMENTORDER"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            if (item.PaymentOrder < 0) {
              item._cellVariants = { PaymentOrder: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Cost",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.COST"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Cost < 0) {
              item._cellVariants = { Cost: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Difference",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.DIFFERENCE"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Difference < 0) {
              item._cellVariants = { Difference: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "BalanceDate",
          label: this.$i18n.t("ANNUALBILLING.TABLE.BALANCEDATE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "AccountBalanceBefore",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.ACCOUNTBALANCEBEFORE"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            if (item.AccountBalanceBefore < 0) {
              item._cellVariants = { AccountBalanceBefore: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "FinalBalance",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.FINALBALANCE"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            if (item.FinalBalance < 0) {
              item._cellVariants = { FinalBalance: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Text",
          label: this.$i18n.t("ANNUALBILLING.TABLE.TEXT"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center p-0 table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showAnnualBillingDetail(
        this.menuId,
        record.PeriodId,
        record.Account,
        record.Group,
        record.AccountType,
      );
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    showAnnualBillingDocs(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.annualBillingDocs = data;
        this.$bvModal.show("modal-annualBilling-docs");
      });
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.annualBillingDocs = data;

        parameters =
          "?docId=" + this.annualBillingDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.annualBillingDocs.Documents[0].FileName;

          FileService.downloadFile(fileName, binaryContent);
        });
      });
    },
    showAnnualBillingDetail(menuId, periodId, account, group, accountType) {
      let parameters = "?menuId=" + menuId.toString();
      if (periodId != null) parameters += "&periodId=" + periodId.toString();
      if (account != null) parameters += "&account=" + account.toString();
      if (group != null) parameters += "&group=" + group.toString();
      if (accountType != null)
        parameters += "&accountType=" + accountType.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "AnnualBillingDetail" + parameters).then(
        ({ data }) => {
          console.log(data);
          this.annualBillingDetail = data;
          this.$bvModal.show("modal-annualBilling-detail");
        },
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      window.open("/AnnualBillingPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("ANNUALBILLING.TABLE.NAME"),
        this.addCurrencyToColumn(
          this.$i18n.t("ANNUALBILLING.TABLE.PAYMENTORDER"),
        ),
        this.addCurrencyToColumn(this.$i18n.t("ANNUALBILLING.TABLE.COST")),
        this.addCurrencyToColumn(
          this.$i18n.t("ANNUALBILLING.TABLE.DIFFERENCE"),
        ),
        this.$i18n.t("ANNUALBILLING.TABLE.BALANCEDATE"),
        this.addCurrencyToColumn(
          this.$i18n.t("ANNUALBILLING.TABLE.ACCOUNTBALANCEBEFORE"),
        ),
        this.addCurrencyToColumn(
          this.$i18n.t("ANNUALBILLING.TABLE.FINALBALANCE"),
        ),
        this.$i18n.t("ANNUALBILLING.TABLE.TEXT"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.Name,
          FormatService.formatNumberToLocalForCSV(item.PaymentOrder),
          FormatService.formatNumberToLocalForCSV(item.Cost),
          FormatService.formatNumberToLocalForCSV(item.Difference),
          FormatService.formatDateToLocal(item.BalanceDate),
          FormatService.formatNumberToLocalForCSV(item.AccountBalanceBefore),
          FormatService.formatNumberToLocalForCSV(item.FinalBalance),
          item.Text,
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "AnnualBilling" + parameters).then(({ data }) => {
        this.transformData(data.AnnualBilling);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.AnnualBilling;
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Name: items[i].Name,
          FinalBalance: items[i].FinalBalance,
        };
      }
    },
  },
};
</script>
