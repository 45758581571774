<template>
  <div>
    <a id="csv-download-link" href style="display: none"></a>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-bind:title="title" v-show="items || itemsHeat">
          <template v-slot:body>
            <b-container fluid v-if="items || itemsHeat">
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Consumption-->
        <KTPortlet v-bind:title="subTitle" v-show="items || items === null">
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsWater"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '800px', height: '400px' }">
                  <Chart
                    ref="annual-consumption-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsWater"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div
                class="poschodoch-legend"
                id="annual-consumption-water-chart-legend"
              />
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Consumption-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Consumption-->
        <KTPortlet
          v-bind:title="subTitleHeat"
          v-show="itemsHeat || itemsHeat === null"
        >
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="itemsHeat">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHeat"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '800px', height: '400px' }">
                  <Chart
                    ref="annual-consumption-heat-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHeat"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div
                class="poschodoch-legend"
                id="annual-consumption-heat-chart-legend"
              />
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHeat">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Consumption-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import { mapGetters } from "vuex";

export default {
  name: "annual-consumption",
  components: {
    KTPortlet,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      description: null,
      subTitle: null,
      subTitleHeat: null,
      menuId: null,
      items: null,
      itemsHeat: null,
      chartOptionsWater: null,
      chartOptionsHeat: null,
      chartLabels: [],
      chartLabelsHeat: [],
      charts: [1],
      chartDataServices: [],
      datasetsHeat: [],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    createChart() {
      this.charts = [this.charts[0] + 1];
      this.chartOptionsWater = {
        type: "bar",
        data: {
          datasets: this.chartDataServices,
          labels: this.chartLabels,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "annual-consumption-water-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(),
                },
                stacked: true,
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHeat() {
      //this.charts = [this.charts[0] + 1];
      this.chartOptionsHeat = {
        type: "bar",
        data: {
          datasets: this.datasetsHeat,
          labels: this.chartLabelsHeat,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "annual-consumption-heat-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(),
                },
                stacked: true,
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(),
                },
                stacked: false,
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    transformData(data) {
      let seriesLabels = [];
      let seriesData = {};
      let chartData = [];
      let labels = [];

      for (let i = 0; i < data.Series.length; i++) {
        seriesLabels.push(data.Series[i].Label);
        seriesData[data.Series[i].Label] = [];

        for (let j = 0; j < data.Series[i].Data.length; j++) {
          seriesData[data.Series[i].Label].push({
            x: data.Series[i].Data[j].Period,
            y: data.Series[i].Data[j].Value,
          });
          if (!labels.includes(data.Series[i].Data[j].Period))
            labels.push(data.Series[i].Data[j].Period);
        }
      }

      for (let i = 0; i < seriesLabels.length; i++) {
        let room = seriesLabels[i];
        let color = this.layoutConfig("colors.state.info");
        switch (i % 16) {
          case 0:
            color = this.layoutConfig("colors.graph.color0");
            break;
          case 1:
            color = this.layoutConfig("colors.graph.color1");
            break;
          case 2:
            color = this.layoutConfig("colors.graph.color2");
            break;
          case 3:
            color = this.layoutConfig("colors.graph.color3");
            break;
          case 4:
            color = this.layoutConfig("colors.graph.color4");
            break;
          case 5:
            color = this.layoutConfig("colors.graph.color5");
            break;
          case 6:
            color = this.layoutConfig("colors.graph.color6");
            break;
          case 7:
            color = this.layoutConfig("colors.graph.color7");
            break;
          case 8:
            color = this.layoutConfig("colors.graph.color8");
            break;
          case 9:
            color = this.layoutConfig("colors.graph.color9");
            break;
          case 10:
            color = this.layoutConfig("colors.graph.color10");
            break;
          case 11:
            color = this.layoutConfig("colors.graph.color11");
            break;
          case 12:
            color = this.layoutConfig("colors.graph.color12");
            break;
          case 13:
            color = this.layoutConfig("colors.graph.color13");
            break;
          case 14:
            color = this.layoutConfig("colors.graph.color14");
            break;
          case 15:
            color = this.layoutConfig("colors.graph.color15");
            break;
        }

        chartData.push({
          data: seriesData[room],
          label: room,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        });
      }

      this.chartLabels = labels;
      this.chartDataServices = chartData;
    },
    transformDataHeat(data) {
      let seriesLabels = [];
      let seriesData = {};
      let labels = [];
      let seriesDatasets = [];

      for (let i = 0; i < data.Series.length; i++) {
        seriesLabels.push(data.Series[i].Label);
        seriesData[data.Series[i].Label] = [];

        for (let j = 0; j < data.Series[i].Data.length; j++) {
          seriesData[data.Series[i].Label].push({
            x: data.Series[i].Data[j].Period,
            y: data.Series[i].Data[j].Value,
          });
          if (!labels.includes(data.Series[i].Data[j].Period))
            labels.push(data.Series[i].Data[j].Period);
        }
      }

      for (let i = 0; i < seriesLabels.length; i++) {
        let room = seriesLabels[i];
        let color = this.layoutConfig("colors.state.info");
        switch (i % 16) {
          case 0:
            color = this.layoutConfig("colors.graph.color0");
            break;
          case 1:
            color = this.layoutConfig("colors.graph.color1");
            break;
          case 2:
            color = this.layoutConfig("colors.graph.color2");
            break;
          case 3:
            color = this.layoutConfig("colors.graph.color3");
            break;
          case 4:
            color = this.layoutConfig("colors.graph.color4");
            break;
          case 5:
            color = this.layoutConfig("colors.graph.color5");
            break;
          case 6:
            color = this.layoutConfig("colors.graph.color6");
            break;
          case 7:
            color = this.layoutConfig("colors.graph.color7");
            break;
          case 8:
            color = this.layoutConfig("colors.graph.color8");
            break;
          case 9:
            color = this.layoutConfig("colors.graph.color9");
            break;
          case 10:
            color = this.layoutConfig("colors.graph.color10");
            break;
          case 11:
            color = this.layoutConfig("colors.graph.color11");
            break;
          case 12:
            color = this.layoutConfig("colors.graph.color12");
            break;
          case 13:
            color = this.layoutConfig("colors.graph.color13");
            break;
          case 14:
            color = this.layoutConfig("colors.graph.color14");
            break;
          case 15:
            color = this.layoutConfig("colors.graph.color15");
            break;
        }

        seriesDatasets.push({
          data: seriesData[room],
          label: room,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        });
      }

      this.datasetsHeat = seriesDatasets;
      this.chartLabelsHeat = labels;
    },
    fetchData() {
      // Annual Consumption services
      this.items = null;
      this.chartOptionsWater = null;
      let parameters =
        "?type=E&TypeOfData=S&RangeOfData=U&menuId=" + this.menuId.toString();

      ApiService.get("Flat", "AnnualCosts" + parameters)
        .then(({ data }) => {
          this.transformData(data);
          this.createChart();

          this.title = data.Title;
          this.description = data.Description;
          this.subTitle = data.Subtitle;
          this.items = data.Series;
        })
        .catch(() => {
          this.items = undefined;
        });

      // Annual Consumption heat
      this.itemsHeat = null;
      this.chartOptionsHeat = null;
      parameters =
        "?type=U&TypeOfData=S&RangeOfData=U&menuId=" + this.menuId.toString();

      ApiService.get("Flat", "AnnualCosts" + parameters)
        .then(({ data }) => {
          this.transformDataHeat(data);
          this.createChartHeat();

          this.subTitleHeat = data.Subtitle;
          this.itemsHeat = data.Series;

          if (!this.items) {
            this.title = data.Title;
            this.description = data.Description;
          }
        })
        .catch(() => {
          this.itemsHeat = undefined;
        });
    },
  },
};
</script>
