<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Request list-->
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">
                  {{ $i18n.t("REQUEST_LIST.TITLEUSER") }}
                </slot>
              </span>
            </h3>
            <b-button
              variant="primary"
              class="btn-sm"
              v-on:click="createNewRequest"
              v-if="addButton == 1"
            >
              <i class="fas fa-plus-circle mb-1"></i>
              <span>{{ $i18n.t("REQUEST_LIST.NEWREQUEST") }}</span>
            </b-button>
          </template>
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Subject)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="
                      showRequestDetail(
                        data.item.RequestId,
                        data.item.RequestState,
                      )
                    "
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                  <span v-if="!(data.item != null)">{{ data.value }}</span>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span v-if="data.value.RequestType" class="text-left">
                          {{ data.value.RequestType }}
                        </span>
                        <span class="text-left">
                          <a
                            href="javascript:void(0)"
                            v-if="data.item != null"
                            v-on:click="
                              showRequestDetail(
                                data.item.RequestId,
                                data.item.RequestState,
                              )
                            "
                            class="kt-link"
                          >
                            {{ data.value.Subject }}
                          </a>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span class="text-left" style="font-weight: 500">
                          {{ data.value.RequestNumber }}
                        </span>
                        <span class="text-left">
                          {{ data.value.RequestStateText }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Request list-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import FileService from "@/common/file.service";

export default {
  name: "requestListUser",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      addButton: null,
      fields: [
        {
          key: "RequestNumber",
          label: this.$i18n.t("REQUEST_LIST.TABLE.REQUEST_ID"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile poschodoch-requestList-RequestNumber",
        },
        {
          key: "Subject",
          label: this.$i18n.t("REQUEST_LIST.TABLE.SUBJECT"),
          sortable: true,
          sortDirection: "desc",
          class: "text-left table-no-mobile poschodoch-requestList-Subject",
        },
        {
          key: "RequestType",
          label: this.$i18n.t("REQUEST_LIST.TABLE.REQUEST_TYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "RequestStateText",
          label: this.$i18n.t("REQUEST_LIST.TABLE.REQUEST_STATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "CreationDate",
          label: this.$i18n.t("REQUEST_LIST.TABLE.CREATION_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DateOfChange",
          label: this.$i18n.t("REQUEST_LIST.TABLE.DATE_OF_CHANGE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showRequestDetail(record.RequestId, record.RequestState);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    print() {
      let parameters =
        "?menuId=" + this.menuId.toString() + "&ObjectRequests=0";
      window.open("/requestListUserPrint" + parameters);
    },
    createNewRequest() {
      this.$router.push({
        name: "domus-new-request",
        query: { menuId: this.menuId },
      });
    },
    showRequestDetail(requestId, requestState) {
      if (requestState == "RO") {
        this.$router.push({
          name: "domus-new-request",
          query: { menuId: this.menuId, requestId: requestId },
        });
      } else {
        this.$router.push({
          name: "domus-request-detail",
          query: { menuId: this.menuId, requestId: requestId },
        });
      }
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("REQUEST_LIST.TABLE.SUBJECT"),
        this.$i18n.t("REQUEST_LIST.TABLE.REQUEST_TYPE"),
        this.$i18n.t("REQUEST_LIST.TABLE.REQUEST_STATE"),
        this.$i18n.t("REQUEST_LIST.TABLE.REQUEST_ID"),
        this.$i18n.t("REQUEST_LIST.TABLE.CREATION_DATE"),
        this.$i18n.t("REQUEST_LIST.TABLE.DATE_OF_CHANGE"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.Subject,
          item.RequestType,
          item.RequestStateText,
          item.RequestNumber,
          FormatService.formatDateToLocal(item.CreationDate),
          FormatService.formatDateToLocal(item.DateOfChange),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName =
        (this.title || this.$i18n.t("REQUEST_LIST.TITLEUSER")) + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?menuId=" + this.menuId.toString() + "&ObjectRequests=0";

      ApiService.setHeader();
      ApiService.get("Request", "List" + parameters).then(({ data }) => {
        this.transformData(data.Requests);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Requests;

        this.addButton = data.AddButton;
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Subject: items[i].Subject,
          RequestType: items[i].RequestType,
          RequestStateText: items[i].RequestStateText,
          RequestId: items[i].RequestId,
          RequestNumber: items[i].RequestNumber,
          CreationDate: FormatService.formatDateToLocal(items[i].CreationDate),
          DateOfChange: FormatService.formatDateToLocal(items[i].DateOfChange),
        };
      }
    },
  },
};
</script>
