<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Filter -->
        <KTPortlet
          v-bind:title="title"
          v-show="itemsColdWater || itemsHotWater || itemsHeat"
        >
          <template v-slot:body>
            <b-container
              fluid
              v-if="itemsColdWater || itemsHotWater || itemsHeat"
            >
              <b-row v-if="!hidden">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div
                    class="d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center"
                  >
                    <ul
                      class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills m-0 mr-0 mr-md-3"
                      role="tablist"
                    >
                      <li class="nav-item" v-for="index in years" :key="index">
                        <a
                          v-on:click="fetchData(index, month)"
                          data-tab="0"
                          class="nav-link"
                          href="javascript:void(0)"
                          v-bind:class="{ active: index == year }"
                        >
                          {{ index }}
                        </a>
                      </li>
                    </ul>
                    <div
                      class="poschodoch-month-dropdown d-flex align-items-center mt-3 mt-md-0"
                    >
                      <label
                        for="daily-consumption-dropdown-month"
                        class="m-0 mr-3"
                      >
                        {{ $i18n.t("DAILY_CONSUMPTION.FILTERMONTH") }}
                      </label>
                      <button
                        id="previous-month"
                        type="button"
                        class="poschodoch-previous-btn page-link mr-3 mr-md-2"
                        v-on:click="previousMonth(month)"
                        :disabled="isPreviousBtnDisabled"
                      >
                        ‹
                      </button>
                      <b-dropdown
                        id="daily-consumption-dropdown-month"
                        :text="getFormatService().getMonthName(month)"
                        variant="light"
                      >
                        <b-dropdown-item
                          v-for="month in months"
                          :key="month"
                          v-on:click="fetchData(year, month)"
                        >
                          {{ getFormatService().getMonthName(month) }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <button
                        id="next-month"
                        type="button"
                        class="poschodoch-next-btn page-link ml-3 ml-md-2"
                        v-on:click="nextMonth(month)"
                        :disabled="isNextBtnDisabled"
                      >
                        ›
                      </button>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="hidden2">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div class="d-flex flex-row">
                    <div
                      class="ml-md-2 mt-3 mt-md-0 d-flex flex-sm-row flex-column"
                    >
                      <span
                        class="d-flex flex-row align-items-center mr-sm-3 mr-0"
                      >
                        <label for="dF" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("DAILY_CONSUMPTION_AVERAGE_OBJECT.FROM") }}
                        </label>
                        <b-form-input
                          id="dF"
                          v-model="inputDateFrom"
                          type="date"
                        ></b-form-input>
                      </span>
                      <span
                        class="d-flex flex-row align-items-center mt-sm-0 mt-3"
                      >
                        <label for="dT" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("DAILY_CONSUMPTION_AVERAGE_OBJECT.TO") }}
                        </label>
                        <b-form-input
                          id="dT"
                          v-model="inputDateTo"
                          type="date"
                        ></b-form-input>
                      </span>
                      <div
                        class="d-flex align-items-sm-center align-self-center"
                      >
                        <b-button
                          variant="primary"
                          class="ml-sm-3 ml-0 mt-3 mt-sm-0"
                          v-on:click="
                            fetchData(null, null, inputDateFrom, inputDateTo)
                          "
                        >
                          {{
                            $i18n.t("DAILY_CONSUMPTION_AVERAGE_OBJECT.BUTTONOK")
                          }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-sm-center">
                    <b-button
                      variant="secondary"
                      class="ml-md-2 ml-sm-3 mt-3 mt-md-0"
                      v-on:click="
                        hidden2 = !hidden2;
                        hidden = !hidden;
                        fetchData();
                      "
                    >
                      {{ $i18n.t("DAILY_CONSUMPTION_AVERAGE_OBJECT.BUTTON2") }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTPortlet>
        <!--End::Filter -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption AO Cold Water-->
        <KTPortlet
          v-bind:title="subTitleColdWater"
          v-show="itemsColdWater || itemsColdWater === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="isEmptyColdWater === 0">
            <TableContextMenu
              :functionCSV="downloadColdWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container
              fluid
              v-if="isEmptyColdWater === 1"
              style="color:#616365;"
            >
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="isEmptyColdWater === 0">
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsColdWater"
                class="poschodoch-consumption-graph"
              >
                <!--Begin::Main chart element -->
                <div :style="{ minWidth: '800px', height: '400px' }">
                  <Chart
                    ref="cold-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsColdWater"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->
              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkCold"
                    v-on:change="showHideDatasetColdWater(2)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span v-bind:class="{ legCenbra: color, legC: !color }">
                    {{
                      $i18n.t(
                        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_COLD_WATER.LEGEND_WATER",
                      )
                    }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkColdAverage"
                    v-on:change="showHideDatasetColdWater(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legF">
                    {{
                      $i18n.t(
                        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_COLD_WATER.LEGEND_AVERAGE_WATER",
                      )
                    }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkColdObject"
                    v-on:change="showHideDatasetColdWater(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legCA">
                    {{
                      $i18n.t(
                        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_COLD_WATER.LEGEND_OBJECT_WATER",
                      )
                    }}
                  </span>
                </div>
              </b-row>
              <!--End::Legend -->
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkCorrectedCold"
                  v-on:change="toggleCorrectedData('cold')"
                  size="lg"
                  style
                  v-bind:checked="showCorrectedCold"
                  switch
                ></b-form-checkbox>
                <span>
                  {{
                    $i18n.t(
                      "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.SHOW_CORRECTED",
                    )
                  }}
                </span>
              </div>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsColdWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption AO Cold Water-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption AO Hot water-->
        <KTPortlet
          v-bind:title="subTitleHotWater"
          v-show="itemsHotWater || itemsHotWater === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="isEmptyHotWater === 0">
            <TableContextMenu
              :functionCSV="downloadHotWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container
              fluid
              v-if="isEmptyHotWater === 1"
              style="color:#616365;"
            >
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="isEmptyHotWater === 0">
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHotWater"
                class="poschodoch-consumption-graph"
              >
                <!--Begin::Main chart element -->
                <div :style="{ minWidth: '800px', height: '400px' }">
                  <Chart
                    ref="hot-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHotWater"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->
              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHot"
                    v-on:change="showHideDatasetHotWater(2)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span v-bind:class="{ legHenbra: color, legH: !color }">
                    {{
                      $i18n.t(
                        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HOT_WATER.LEGEND_WATER",
                      )
                    }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHotAverage"
                    v-on:change="showHideDatasetHotWater(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legF">
                    {{
                      $i18n.t(
                        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HOT_WATER.LEGEND_AVERAGE_WATER",
                      )
                    }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHotObject"
                    v-on:change="showHideDatasetHotWater(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legHA">
                    {{
                      $i18n.t(
                        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HOT_WATER.LEGEND_OBJECT_WATER",
                      )
                    }}
                  </span>
                </div>
              </b-row>
              <!--End::Legend -->
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkCorrectedHot"
                  v-on:change="toggleCorrectedData('hot')"
                  size="lg"
                  style
                  v-bind:checked="showCorrectedHot"
                  switch
                ></b-form-checkbox>
                <span>
                  {{
                    $i18n.t(
                      "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.SHOW_CORRECTED",
                    )
                  }}
                </span>
              </div>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHotWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption AO Hot water-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption AO Heat-->
        <KTPortlet
          v-bind:title="subTitleHeat"
          v-show="itemsHeat || itemsHeat === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="isEmptyHeat === 0">
            <TableContextMenu :functionCSV="downloadHeatCSV"></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="isEmptyHeat === 1" style="color:#616365;">
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="isEmptyHeat === 0">
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHeat"
                class="poschodoch-consumption-graph"
              >
                <!--Begin::Main chart element -->
                <div :style="{ minWidth: '800px', height: '400px' }">
                  <Chart
                    ref="heat-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHeat"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->
              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHeat"
                    v-on:change="showHideDatasetHeat(2)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span v-bind:class="{ legHeenbra: color, legHe: !color }">
                    {{
                      $i18n.t(
                        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.LEGEND_HEAT",
                      )
                    }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHeatAverage"
                    v-on:change="showHideDatasetHeat(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legF">
                    {{
                      $i18n.t(
                        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.LEGEND_AVERAGE_HEAT",
                      )
                    }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHeatComparable"
                    v-on:change="showHideDatasetHeat(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legHA">
                    {{
                      $i18n.t(
                        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.LEGEND_OBJECT_HEAT",
                      )
                    }}
                  </span>
                </div>
              </b-row>
              <!--End::Legend -->
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkCorrectedHeat"
                  v-on:change="toggleCorrectedData('heat')"
                  size="lg"
                  style
                  v-bind:checked="showCorrectedHeat"
                  switch
                ></b-form-checkbox>
                <span>
                  {{
                    $i18n.t(
                      "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.SHOW_CORRECTED",
                    )
                  }}
                </span>
              </div>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHeat">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption AO Heat-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet>
          <template v-slot:body>
            <b-container fluid v-if="description">
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";

export default {
  name: "daily-consumption-average-object",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      hidden: false,
      hidden2: false,
      showCorrectedCold: false,
      showCorrectedHot: false,
      showCorrectedHeat: false,
      year: null,
      years: null,
      yearFrom: null,
      yearTo: null,
      month: null,
      months: null,
      monthFrom: null,
      monthTo: null,
      dateFrom: null,
      dateTo: null,
      inputDateFrom: null,
      inputDateTo: null,
      charts: [1],
      itemsDatesCold: [],
      itemsDatesHot: [],
      itemsDatesHeat: [],
      // cold water
      subTitleColdWater: null,
      itemsColdWater: null,
      itemsObjectColdWater: null,
      chartOptionsColdWater: null,
      chartLabelsColdWater: [],
      chartDatasetLabelColdWater: this.$i18n.t(
        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_COLD_WATER.LEGEND_WATER",
      ),
      chartDataColdWater: [],
      chartDataObjectColdWater: [],
      chartDataAverageColdWater: [],
      chartDatasetLabelAverageColdWater: this.$i18n.t(
        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_COLD_WATER.LEGEND_AVERAGE_WATER",
      ),
      chartDatasetLabelObjectColdWater: this.$i18n.t(
        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_COLD_WATER.LEGEND_OBJECT_WATER",
      ),
      // Hot water
      subTitleHotWater: null,
      itemsHotWater: null,
      itemsObjectHotWater: null,
      chartOptionsHotWater: null,
      chartLabelsHotWater: [],
      chartDatasetLabelHotWater: this.$i18n.t(
        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HOT_WATER.LEGEND_WATER",
      ),
      chartDataHotWater: [],
      chartDataObjectHotWater: [],
      chartDataAverageHotWater: [],
      chartDatasetLabelAverageHotWater: this.$i18n.t(
        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HOT_WATER.LEGEND_AVERAGE_WATER",
      ),
      chartDatasetLabelObjectHotWater: this.$i18n.t(
        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HOT_WATER.LEGEND_OBJECT_WATER",
      ),
      // heat
      subTitleHeat: null,
      itemsHeat: null,
      itemsObjectHeat: null,
      itemsApartmentHeat: null,
      chartOptionsHeat: null,
      chartLabelsHeat: [],
      chartDatasetLabelHeat: this.$i18n.t(
        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.LEGEND_HEAT",
      ),
      chartDataHeat: [],
      chartDataObjectHeat: [],
      chartDataAverageHeat: [],
      chartDatasetLabelAverageHeat: this.$i18n.t(
        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.LEGEND_AVERAGE_HEAT",
      ),
      chartDatasetLabelObjectHeat: this.$i18n.t(
        "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.LEGEND_OBJECT_HEAT",
      ),
      chartTooltipsColdWater: [],
      chartTooltipsHotWater: [],
      chartTooltipsHeat: [],
      // colors
      colorhot: null,
      colorcold: null,
      colorheat: null,
      colortooltip: null,
      color: null,
      isEmptyColdWater: 0,
      isEmptyHotWater: 0,
      isEmptyHeat: 0,
    };
  },
  mounted() {
    this.colorhot = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.danger");
    this.colorcold = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-cold")
      : this.layoutConfig("colors.state.info");
    this.colorheat = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-heat")
      : this.layoutConfig("colors.state.brand");
    this.colortooltip = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.brand");
    this.color = window.location.host.includes("enbra");
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    isPreviousBtnDisabled() {
      return !this.isMonthInRange(this.month - 1);
    },
    isNextBtnDisabled() {
      return !this.isMonthInRange(this.month + 1);
    },
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    isMonthInRange(monthToCheck) {
      return monthToCheck != null && this.months.includes(monthToCheck);
    },
    previousMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth - 1))
        this.fetchData(this.year, selectedMonth - 1);
    },
    nextMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth + 1))
        this.fetchData(this.year, selectedMonth + 1);
    },
    showHideDatasetColdWater(index) {
      this.chartOptionsColdWater.data.datasets[index].hidden = !this
        .chartOptionsColdWater.data.datasets[index].hidden;
      this.chartOptionsColdWater.chart.update();
    },
    showHideDatasetHotWater(index) {
      this.chartOptionsHotWater.data.datasets[index].hidden = !this
        .chartOptionsHotWater.data.datasets[index].hidden;
      this.chartOptionsHotWater.chart.update();
    },
    showHideDatasetHeat(index) {
      this.chartOptionsHeat.data.datasets[index].hidden = !this.chartOptionsHeat
        .data.datasets[index].hidden;
      this.chartOptionsHeat.chart.update();
    },
    toggleCorrectedData(type) {
      if (type === "cold") {
        this.showCorrectedCold = !this.showCorrectedCold;
        this.fetchDataColdWater(
          this.year,
          this.month,
          this.inputDateFrom,
          this.inputDateTo,
        );
      } else if (type === "hot") {
        this.showCorrectedHot = !this.showCorrectedHot;
        this.fetchDataHotWater(
          this.year,
          this.month,
          this.inputDateFrom,
          this.inputDateTo,
        );
      } else if (type === "heat") {
        this.showCorrectedHeat = !this.showCorrectedHeat;
        this.fetchDataHeat(
          this.year,
          this.month,
          this.inputDateFrom,
          this.inputDateTo,
        );
      }
    },
    createChartColdWater() {
      this.charts = [this.charts[0] + 1];
      this.chartOptionsColdWater = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.chartDataObjectColdWater,
              label: this.chartDatasetLabelObjectColdWater,
              fill: true,
              borderColor: this.layoutConfig("colors.state.primary"),
              borderWidth: 3,
              backgroundColor: "rgba(170,170,170,0.3)",
              type: "line",
            },
            {
              data: this.chartDataAverageColdWater,
              label: this.chartDatasetLabelAverageColdWater,
              fill: true,
              borderColor: "rgba(160,100,160,0.3)",
              borderWidth: 3,
              backgroundColor: "rgba(160,100,160,0.3)",
              type: "line",
            },
            {
              data: this.chartDataColdWater,
              label: this.chartDatasetLabelColdWater,
              fill: false,
              borderColor: this.colorcold,
              backgroundColor: this.colorcold,
              type: "bar",
            },
          ],
          labels: this.chartLabelsColdWater,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colortooltip,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem) => {
                let title = this.getFormatService().formatDateToLocal(
                  this.chartTooltipsColdWater[tooltipItem[0].index],
                );
                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_COLD_WATER.AX_X",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_COLD_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHotWater() {
      this.chartOptionsHotWater = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.chartDataObjectHotWater,
              label: this.chartDatasetLabelObjectHotWater,
              fill: true,
              borderColor: this.layoutConfig("colors.state.primary"),
              borderWidth: 3,
              backgroundColor: "rgba(170,170,170,0.3)",
              type: "line",
            },
            {
              data: this.chartDataAverageHotWater,
              label: this.chartDatasetLabelAverageHotWater,
              fill: true,
              borderColor: "rgba(160,100,160,0.3)",
              borderWidth: 3,
              backgroundColor: "rgba(160,100,160,0.3)",
              type: "line",
            },
            {
              data: this.chartDataHotWater,
              label: this.chartDatasetLabelHotWater,
              fill: false,
              borderColor: this.colorhot,
              backgroundColor: this.colorhot,
              type: "bar",
            },
          ],
          labels: this.chartLabelsHotWater,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colortooltip,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem) => {
                let title = this.getFormatService().formatDateToLocal(
                  this.chartTooltipsHotWater[tooltipItem[0].index],
                );
                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HOT_WATER.AX_X",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HOT_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHeat() {
      this.chartOptionsHeat = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.chartDataObjectHeat,
              label: this.chartDatasetLabelObjectHeat,
              fill: true,
              borderColor: this.layoutConfig("colors.state.primary"),
              borderWidth: 3,
              backgroundColor: "rgba(170,170,170,0.3)",
              type: "line",
            },
            {
              data: this.chartDataAverageHeat,
              label: this.chartDatasetLabelAverageHeat,
              fill: true,
              borderColor: "rgba(160,100,160,0.3)",
              borderWidth: 3,
              backgroundColor: "rgba(160,100,160,0.3)",
              type: "line",
            },
            {
              data: this.chartDataHeat,
              label: this.chartDatasetLabelHeat,
              fill: false,
              borderColor: this.colorheat,
              backgroundColor: this.colorheat,
              type: "bar",
            },
          ],
          labels: this.chartLabelsHeat,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colortooltip,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem) => {
                let title = this.getFormatService().formatDateToLocal(
                  this.chartTooltipsHeat[tooltipItem[0].index],
                );
                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.AX_X",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_AVERAGE_OBJECT.CHART_HEAT.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    setYearRange(yearFrom, yearTo) {
      if (this.years == null) {
        this.years = [];
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    setMonthRange(pRok, pMesiac = "") {
      this.months = [];
      this.year = pRok;
      if (pRok == this.yearFrom) {
        for (let i = this.monthFrom; i <= 12; i++) {
          this.months.push(i);
        }
      } else if (pRok == this.yearTo) {
        for (let i = 1; i <= this.monthTo; i++) {
          this.months.push(i);
        }
      } else {
        this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }
      if (pMesiac == null) {
        this.month = "Zvoľte mesiac";
      }
    },
    transformDataColdWater(data) {
      let consumptionColdWater = [];
      let consumptionObjectColdWater = [];
      let consumptionAverageColdWater = [];
      let labels = [];
      let tooltipsCold = [];
      this.itemsDatesCold = [];
      this.isEmptyColdWater = 1;

      // Adjust DateTo if it falls on October 31st for any year
      const dateTo = new Date(data.DateTo);
      if (dateTo.getMonth() === 9 && dateTo.getDate() === 31) {
        // October is month 9 (0-based index)
        dateTo.setDate(1);
        dateTo.setMonth(10); // November is month 10
        data.DateTo = dateTo.toISOString().slice(0, 10); // Format as YYYY-MM-DD
      }

      for (let i = 0; i < data.Consumption.length; i++) {
        consumptionColdWater.push({
          x: data.Consumption[i].Date,
          y: data.Consumption[i].Consumption,
        });
        this.itemsDatesCold.push(data.Consumption[i].Date);
        tooltipsCold.push(data.Consumption[i].Date);
        if (data.Consumption[i].Consumption != null) {
          this.isEmptyColdWater = 0;
        }
      }
      for (let i = 0; i < data.ConsumptionObject.length; i++) {
        consumptionObjectColdWater.push({
          x: data.ConsumptionObject[i].Date,
          y: data.ConsumptionObject[i].Consumption,
        });
        if (data.ConsumptionObject[i].Consumption != null) {
          this.isEmptyColdWater = 0;
        }
      }
      for (let i = 0; i < data.ConsumptionAverage.length; i++) {
        consumptionAverageColdWater.push({
          x: data.ConsumptionAverage[i].Day,
          y: data.ConsumptionAverage[i].Consumption,
        });
        if (data.ConsumptionAverage[i].Consumption != null) {
          this.isEmptyColdWater = 0;
        }
      }
      var currentDate = new Date(data.DateFrom);
      while (currentDate <= new Date(data.DateTo)) {
        labels.push(currentDate.getDate());
        currentDate.setDate(currentDate.getDate() + 1);
      }
      this.chartTooltipsColdWater = tooltipsCold;
      this.chartLabelsColdWater = labels;
      this.chartDataColdWater = consumptionColdWater;
      this.chartDataObjectColdWater = consumptionObjectColdWater;
      this.chartDataAverageColdWater = consumptionAverageColdWater;
    },
    transformDataHotWater(data) {
      let consumptionHotWater = [];
      let consumptionObjectHotWater = [];
      let consumptionAverageHotWater = [];
      let labels = [];
      let tooltipsHot = [];
      this.itemsDatesHot = [];
      this.isEmptyHotWater = 1;

      // Adjust DateTo if it falls on October 31st for any year
      const dateTo = new Date(data.DateTo);
      if (dateTo.getMonth() === 9 && dateTo.getDate() === 31) {
        // October is month 9 (0-based index)
        dateTo.setDate(1);
        dateTo.setMonth(10); // November is month 10
        data.DateTo = dateTo.toISOString().slice(0, 10); // Format as YYYY-MM-DD
      }

      for (let i = 0; i < data.Consumption.length; i++) {
        consumptionHotWater.push({
          x: data.Consumption[i].Date,
          y: data.Consumption[i].Consumption,
        });
        this.itemsDatesHot.push(data.Consumption[i].Date);
        tooltipsHot.push(data.Consumption[i].Date);
        if (data.Consumption[i].Consumption != null) {
          this.isEmptyHotWater = 0;
        }
      }
      for (let i = 0; i < data.ConsumptionObject.length; i++) {
        consumptionObjectHotWater.push({
          x: data.ConsumptionObject[i].Date,
          y: data.ConsumptionObject[i].Consumption,
        });
        if (data.ConsumptionObject[i].Consumption != null) {
          this.isEmptyHotWater = 0;
        }
      }
      for (let i = 0; i < data.ConsumptionAverage.length; i++) {
        consumptionAverageHotWater.push({
          x: data.ConsumptionAverage[i].Day,
          y: data.ConsumptionAverage[i].Consumption,
        });
        if (data.ConsumptionAverage[i].Consumption != null) {
          this.isEmptyHotWater = 0;
        }
      }
      var currentDate = new Date(data.DateFrom);
      while (currentDate <= new Date(data.DateTo)) {
        labels.push(currentDate.getDate());
        currentDate.setDate(currentDate.getDate() + 1);
      }
      this.chartTooltipsHotWater = tooltipsHot;
      this.chartLabelsHotWater = labels;
      this.chartDataHotWater = consumptionHotWater;
      this.chartDataObjectHotWater = consumptionObjectHotWater;
      this.chartDataAverageHotWater = consumptionAverageHotWater;
    },
    transformDataHeat(data) {
      let consumptionHeat = [];
      let consumptionObjectHeat = [];
      let consumptionAverageHeat = [];
      let labels = [];
      let tooltipsHeat = [];
      this.itemsDatesHeat = [];
      this.isEmptyHeat = 1;

      // Adjust DateTo if it falls on October 31st for any year
      const dateTo = new Date(data.DateTo);
      if (dateTo.getMonth() === 9 && dateTo.getDate() === 31) {
        // October is month 9 (0-based index)
        dateTo.setDate(1);
        dateTo.setMonth(10); // November is month 10
        data.DateTo = dateTo.toISOString().slice(0, 10); // Format as YYYY-MM-DD
      }

      for (let i = 0; i < data.Consumption.length; i++) {
        consumptionHeat.push({
          x: data.Consumption[i].Date,
          y: data.Consumption[i].Consumption,
        });
        this.itemsDatesHeat.push(data.Consumption[i].Date);
        tooltipsHeat.push(data.Consumption[i].Date);
        if (data.Consumption[i].Consumption != null) {
          this.isEmptyHeat = 0;
        }
      }
      for (let i = 0; i < data.ConsumptionObject.length; i++) {
        consumptionObjectHeat.push({
          x: data.ConsumptionObject[i].Date,
          y: data.ConsumptionObject[i].Consumption,
        });
        if (data.ConsumptionObject[i].Consumption != null) {
          this.isEmptyHeat = 0;
        }
      }
      for (let i = 0; i < data.ConsumptionAverage.length; i++) {
        consumptionAverageHeat.push({
          x: data.ConsumptionAverage[i].Day,
          y: data.ConsumptionAverage[i].Consumption,
        });
        if (data.ConsumptionAverage[i].Consumption != null) {
          this.isEmptyHeat = 0;
        }
      }
      var currentDate = new Date(data.DateFrom);
      while (currentDate <= new Date(data.DateTo)) {
        labels.push(currentDate.getDate());
        currentDate.setDate(currentDate.getDate() + 1);
      }
      this.chartTooltipsHeat = tooltipsHeat;
      this.chartLabelsHeat = labels;
      this.chartDataHeat = consumptionHeat;
      this.chartDataObjectHeat = consumptionObjectHeat;
      this.chartDataAverageHeat = consumptionAverageHeat;
    },
    downloadColdWaterCSV() {
      this.downloadCSV(
        this.subTitleColdWater,
        [
          "",
          this.chartDatasetLabelColdWater,
          this.chartDatasetLabelObjectColdWater,
        ],
        this.itemsDatesCold,
        this.itemsColdWater,
        this.itemsObjectColdWater,
      );
    },
    downloadHotWaterCSV() {
      this.downloadCSV(
        this.subTitleHotWater,
        [
          "",
          this.chartDatasetLabelHotWater,
          this.chartDatasetLabelObjectHotWater,
        ],
        this.itemsDatesHot,
        this.itemsHotWater,
        this.itemsObjectHotWater,
      );
    },
    downloadHeatCSV() {
      this.downloadCSV(
        this.subTitleHeat,
        [
          "",
          this.chartDatasetLabelHeat,
          this.chartDatasetLabelObjectHeat,
          this.chartDatasetLabelComparableHeat,
        ],
        this.itemsDatesHeat,
        this.itemsHeat,
        this.itemsObjectHeat,
        this.itemsApartmentHeat,
      );
    },
    downloadCSV(title, header, labels, items, itemsObject, itemsApartment) {
      itemsObject = itemsObject || [];
      itemsApartment = itemsApartment || [];
      let values = [
        ...items.map(function(v) {
          return [
            header[1],
            v.Day,
            FormatService.formatNumberToLocalForCSV(v.Consumption),
            v.Date,
          ];
        }),
        ...itemsObject.map(function(v) {
          return [
            header[2],
            v.Day,
            FormatService.formatNumberToLocalForCSV(v.Consumption),
            v.Date,
          ];
        }),
        ...itemsApartment.map(function(v) {
          return [
            header[3],
            v.Day,
            FormatService.formatNumberToLocalForCSV(v.Consumption),
            v.Date,
          ];
        }),
      ];
      let result = FileService.generateChartCSVwithDate(header, labels, values);
      let fileName = title + ".csv";
      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(selectedYear = null, selectedMonth = null, sDateFrom, sDateTo) {
      // Fetch data for cold water, hot water, and heat
      this.fetchDataColdWater(selectedYear, selectedMonth, sDateFrom, sDateTo);
      this.fetchDataHotWater(selectedYear, selectedMonth, sDateFrom, sDateTo);
      this.fetchDataHeat(selectedYear, selectedMonth, sDateFrom, sDateTo);
    },
    fetchDataColdWater(
      selectedYear = null,
      selectedMonth = null,
      sDateFrom,
      sDateTo,
    ) {
      this.itemsColdWater = null;
      this.itemsObjectColdWater = null;
      this.chartOptionsColdWater = null;
      let parameters = this.showCorrectedCold
        ? "?type=SK&menuId=" + this.menuId.toString()
        : "?type=S&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();
      if (sDateFrom != null) parameters += "&dateFrom=" + sDateFrom;
      if (sDateTo != null) parameters += "&dateTo=" + sDateTo;

      ApiService.setHeader();
      ApiService.get("Flat", "DailyConsumptionCompare" + parameters)
        .then(({ data }) => {
          this.transformDataColdWater(data);
          this.createChartColdWater();
          this.title = data.Title;
          this.description = data.Description;
          this.subTitleColdWater = data.Subtitle;
          this.itemsColdWater = data.Consumption;
          this.itemsObjectColdWater = data.ConsumptionObject;
          this.year = data.Year;
          this.yearFrom = data.YearFrom;
          this.yearTo = data.YearTo;
          this.monthFrom = data.MonthFrom;
          this.monthTo = data.MonthTo;
          this.dateFrom = data.DateFrom;
          this.dateTo = data.DateTo;
          this.setYearRange(data.YearFrom, data.YearTo);
          this.setMonthRange(this.year, selectedMonth);
          this.month = data.Month;
          this.inputDateFrom = this.dateFrom;
          this.inputDateTo = this.dateTo;
        })
        .catch(() => {
          this.itemsColdWater = undefined;
          this.itemsObjectColdWater = undefined;
        });
    },
    fetchDataHotWater(
      selectedYear = null,
      selectedMonth = null,
      sDateFrom,
      sDateTo,
    ) {
      this.itemsHotWater = null;
      this.itemsObjectHotWater = null;
      this.chartOptionsHotWater = null;
      let parameters = this.showCorrectedHot
        ? "?type=TK&menuId=" + this.menuId.toString()
        : "?type=T&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();
      if (sDateFrom != null) parameters += "&dateFrom=" + sDateFrom.toString();
      if (sDateTo != null) parameters += "&dateTo=" + sDateTo.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "DailyConsumptionCompare" + parameters)
        .then(({ data }) => {
          this.transformDataHotWater(data);
          this.createChartHotWater();
          this.subTitleHotWater = data.Subtitle;
          this.itemsHotWater = data.Consumption;
          if (!this.itemsColdWater) {
            this.title = data.Title;
            this.description = data.Description;
            this.itemsObjectHotWater = data.ConsumptionObject;
            this.year = data.Year;
            this.yearFrom = data.YearFrom;
            this.yearTo = data.YearTo;
            this.monthFrom = data.MonthFrom;
            this.monthTo = data.MonthTo;
            this.dateFrom = data.DateFrom;
            this.dateTo = data.DateTo;
            this.setYearRange(data.YearFrom, data.YearTo);
            this.setMonthRange(this.year, selectedMonth);
            this.month = data.Month;
            this.inputDateFrom = this.dateFrom;
            this.inputDateTo = this.dateTo;
          }
        })
        .catch(() => {
          this.itemsHotWater = undefined;
          this.itemsObjectHotWater = undefined;
        });
    },
    fetchDataHeat(
      selectedYear = null,
      selectedMonth = null,
      sDateFrom,
      sDateTo,
    ) {
      this.itemsHeat = null;
      this.itemsObjectHeat = null;
      this.itemsApartmentHeat = null;
      this.chartOptionsHeat = null;
      let parameters = this.showCorrectedHeat
        ? "?type=UK&menuId=" + this.menuId.toString()
        : "?type=U&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();
      if (sDateFrom != null) parameters += "&dateFrom=" + sDateFrom.toString();
      if (sDateTo != null) parameters += "&dateTo=" + sDateTo.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "DailyConsumptionCompare" + parameters)
        .then(({ data }) => {
          this.transformDataHeat(data);
          this.createChartHeat();
          this.subTitleHeat = data.Subtitle;
          this.itemsHeat = data.Consumption;
          this.itemsObjectHeat = data.ConsumptionObject;
          this.itemsApartmentHeat = data.ComparableApartment;
          if (!this.itemsColdWater && !this.itemsHotWater) {
            this.title = data.Title;
            this.description = data.Description;
            this.year = data.Year;
            this.yearFrom = data.YearFrom;
            this.yearTo = data.YearTo;
            this.monthFrom = data.MonthFrom;
            this.monthTo = data.MonthTo;
            this.dateFrom = data.DateFrom;
            this.dateTo = data.DateTo;
            this.setYearRange(data.YearFrom, data.YearTo);
            this.setMonthRange(this.year, selectedMonth);
            this.month = data.Month;
            this.inputDateFrom = this.dateFrom;
            this.inputDateTo = this.dateTo;
          }
        })
        .catch(() => {
          this.itemsHeat = undefined;
          this.itemsObjectHeat = undefined;
          this.itemsApartmentHeat = undefined;
        });
    },
  },
};
</script>
