<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Voting Sheet-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <div class="row">
                <b-col>
                  <div class="mb-4">
                    <div class="mb-2">{{ $i18n.t("SHEETINPUT.TEXT1") }}</div>
                    <b-form-input
                      v-model="inputText1"
                      type="text"
                      :placeholder="$i18n.t('VOTINGSHEET.PLACEHOLDER1')"
                    ></b-form-input>
                  </div>
                </b-col>
              </div>
              <div class="row">
                <b-col>
                  <div class="mb-4">
                    <div class="mb-2">{{ $i18n.t("SHEETINPUT.TEXT2") }}</div>
                    <b-form-textarea
                      v-model="inputText2"
                      rows="3"
                      :placeholder="$i18n.t('VOTINGSHEET.PLACEHOLDER2')"
                    ></b-form-textarea>
                  </div>
                </b-col>
              </div>
              <div class="row">
                <b-col sm="12" lg="6">
                  <div class="mb-4">
                    <div class="mb-2">{{ $i18n.t("SHEETINPUT.USERS") }}</div>
                    <b-form-select
                      v-model="inputUsers"
                      :options="optionsUsers"
                      v-on:change="fetchData()"
                    ></b-form-select>
                  </div>
                </b-col>
                <b-col sm="12" lg="6">
                  <div class="mb-4">
                    <div class="mb-2">{{ $i18n.t("SHEETINPUT.ROOMS") }}</div>
                    <b-form-select
                      v-model="inputRooms"
                      :options="optionsRooms"
                      v-on:change="fetchData()"
                    ></b-form-select>
                  </div>
                </b-col>
              </div>
              <div class="mb-3">{{ objectAddress }}</div>
              <!--Begin::Main table element -->
              <b-table
                show-empty
                size="md"
                stacked="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
              ></b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Voting Sheet-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "votingSheet",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      menuId: null,
      title: null,
      description: null,
      userType: null,
      roomType: null,
      objectAddress: null,
      objectNumber: null,
      items: null,
      inputText1: null,
      inputText2: null,
      optionsUsers: [
        { value: "A", text: this.$i18n.t("SHEETS.OPTIONS.ALL") },
        { value: "V", text: this.$i18n.t("SHEETS.OPTIONS.OWNERS") },
        { value: "N", text: this.$i18n.t("SHEETS.OPTIONS.TENANTS") },
      ],
      optionsRooms: [
        { value: "T", text: this.$i18n.t("SHEETS.OPTIONS.ALLB") },
        { value: "V", text: this.$i18n.t("SHEETS.OPTIONS.NOB") },
        { value: "B", text: this.$i18n.t("SHEETS.OPTIONS.YESB") },
      ],
      inputUsers: "A",
      inputRooms: "T",
      fields: [
        {
          key: "VS",
          label: this.$i18n.t("VOTINGSHEET.TABLE.VS"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "ApartmentNr",
          label: this.$i18n.t("VOTINGSHEET.TABLE.APARTMENTNR"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Name",
          label: this.$i18n.t("VOTINGSHEET.TABLE.NAME"),
          sortable: false,
          sortDirection: "desc",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.userType = this.$route.query.userType;
    this.roomType = this.$route.query.roomType;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.inputUsers != null)
        parameters += "&userType=" + this.inputUsers.toString();
      else parameters += "&userType=A";
      if (this.inputRooms != null)
        parameters += "&roomType=" + this.inputRooms.toString();
      else parameters += "&roomType=T";

      if (this.inputText1 != null)
        localStorage.setItem("sheetTxt1", this.inputText1);
      else localStorage.setItem("sheetTxt1", "");
      if (this.inputText2 != null)
        localStorage.setItem("sheetTxt2", this.inputText2);
      else localStorage.setItem("sheetTxt2", "");

      window.open("/votingSheetPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("VOTINGSHEET.TABLE.VS"),
        this.$i18n.t("VOTINGSHEET.TABLE.APARTMENTNR"),
        this.$i18n.t("VOTINGSHEET.TABLE.NAME"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [item.VS, item.ApartmentNr, item.Name];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = "Hlasovací hárok" + `_.csv`;

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.inputUsers != null)
        parameters += "&userType=" + this.inputUsers.toString();
      else parameters += "&userType=A";
      if (this.inputRooms != null)
        parameters += "&roomType=" + this.inputRooms.toString();
      else parameters += "&roomType=T";

      ApiService.setHeader();
      ApiService.get("Object", "VotingSheet" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.objectAddress = data.ObjectAddress;
        this.objectNumber = data.ObjectNumber;
        this.items = data.VotingSheet;
      });
    },
  },
};
</script>
