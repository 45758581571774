<template>
  <div>
    <div class="row h-100">
      <div class="col-lg-12 col-xl-12 h-100">
        <!-- Error Alert -->
        <div v-if="errors.length" class="alert alert-danger mt-2 mb-5">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <KTPortlet :title="title">
          <template v-slot:toolbar>
            <b-button variant="secondary" @click="back">
              {{ $i18n.t("CUSTOMERMAIL.BACK") }}
            </b-button>
          </template>
          <template v-slot:body>
            <form @submit.prevent>
              <div
                v-if="customerLimitUsers"
                style="display: flex"
                class="mb-5 ml-1 mt-3"
              >
                <b-form-checkbox
                  id="checkLimitUsers"
                  v-model="emailHrom.LimitUsers"
                  class="ml-2"
                  size="lg"
                  style
                  checked="false"
                  switch
                ></b-form-checkbox>
                <span class="mr-5 mt-1">
                  {{ $i18n.t("CUSTOMERMAIL.LIMITUSERS") }}
                </span>
              </div>
              <div class="form-group">
                <label for="subject">
                  {{ $i18n.t("CUSTOMERMAIL.TABLE.EMAILSUBJECT") }}
                </label>
                <b-form-input
                  id="subject"
                  v-model="emailHrom.Subject"
                  type="text"
                  required
                  ref="subjectInput"
                ></b-form-input>
              </div>
              <div class="form-group">
                <label for="content">
                  {{ $i18n.t("CUSTOMERMAIL.TABLE.EMAILCONTENT") }}
                </label>
                <editor
                  v-model="emailHrom.Content"
                  api-key="ssm8a9c38382if53p3ovm1100t6jnzi43ig0huhllvfofint"
                  :init="editorInit"
                  ref="contentEditor"
                />
              </div>
              <div
                v-if="!emailHrom.DateInstructionToSendEmail"
                class="kt-align-right mt-3"
              >
                <b-button
                  variant="primary"
                  class="mr-2"
                  @click="save"
                  id="save_button"
                >
                  {{ $i18n.t("CUSTOMERMAIL.SAVEBUTTON") }}
                </b-button>
                <b-button
                  variant="success"
                  class="mr-2 send-button"
                  @click="send"
                  id="send_button"
                  v-b-tooltip.hover
                  :title="confirmTitle"
                >
                  {{ $i18n.t("CUSTOMERMAIL.SENDBUTTON") }}
                </b-button>
              </div>
            </form>
            <!-- Email Detail Table -->
            <div v-if="emailHrom.DateInstructionToSendEmail">
              <h5>{{ $i18n.t("CUSTOMERMAIL.TABLE.EMAILDETAIL") }}</h5>
              <b-form-input
                v-model="filter"
                :placeholder="placeholderSearch"
                class="mb-2"
              ></b-form-input>
              <b-table
                show-empty
                hover
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="paginatedEmailDetails"
                :fields="emailDetailFields"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="sm"
                :filter-function="customFilter"
              >
                <template #cell(StatusSent)="data">
                  <i
                    v-b-tooltip.hover
                    :title="
                      `${
                        data.item.StatusSent == 1
                          ? $t('CUSTOMERMAIL.TABLE.EMAILSENT')
                          : $t('CUSTOMERMAIL.TABLE.EMAILUNSENT')
                      }`
                    "
                    :class="
                      `fa fa-circle ${
                        data.item.StatusSent == 1
                          ? 'text-success'
                          : 'text-secondary'
                      }`
                    "
                  ></i>
                </template>
                <template #cell(DateSent)="data">
                  <span v-if="data.item.DateSent">
                    {{
                      getFormatService().formatDateToLocal(data.item.DateSent)
                    }}
                  </span>
                  <span v-else>
                    {{ $i18n.t("CUSTOMERMAIL.TABLE.NOTSENT") }}
                  </span>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="email-detail-table"
                class="my-0"
              ></b-pagination>
            </div>
            <!--Begin::Loader -->
            <b-container fluid v-if="loader">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import Editor from "@tinymce/tinymce-vue";
import UIService from "@/common/ui.service";
import i18nService from "@/common/i18n.service.js";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";

export default {
  name: "CustomerMailerDetail",
  components: {
    KTPortlet,
    Loader,
    editor: Editor,
  },
  data() {
    return {
      emailHrom: {
        ID: null,
        Subject: "",
        Content: "",
        DateInstructionToSendEmail: null,
        EmailDetail: [], // Initialize as an empty array
        LimitUsers: false,
        Operation: "U",
      },
      loader: 0,
      customerMailId: null,
      customerLimitUsers: null,
      placeholderSearch: this.$i18n.t("CUSTOMERMAIL.TABLE.SEARCH"),
      errors: [],
      confirmTitle: this.$i18n.t("CUSTOMERMAIL.CONFIRMTITLE"),
      title: this.$i18n.t("CUSTOMERMAIL.TABLE.TITLE"),
      emailDetailFields: [
        { key: "StatusSent", label: "", sortable: true, filterable: true },
        {
          key: "Email",
          label: this.$i18n.t("CUSTOMERMAIL.TABLE.EMAIL"),
          sortable: true,
          filterable: true,
        },
        {
          key: "DateSent",
          label: this.$i18n.t("CUSTOMERMAIL.TABLE.DATESENT"),
          sortable: true,
          filterable: true,
        },
      ],
      editorInit: {
        height: 500,
        menubar: false,
        language:
          i18nService.getActiveLanguage() === "cz"
            ? "cs"
            : i18nService.getActiveLanguage(),
        invalid_elements: "script",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen table image",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | table | image | help",
      },
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      filter: "",
      showUnsentOnly: false,
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    filteredEmailDetails() {
      if (!this.filter) {
        return this.emailHrom.EmailDetail;
      }
      const textFilter = this.filter.toLowerCase();
      return this.emailHrom.EmailDetail.filter((item) => {
        return item.Email.toLowerCase().includes(textFilter);
      });
    },
    paginatedEmailDetails() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredEmailDetails.slice(start, end);
    },
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.customerMailId = this.$route.query.customerMailId;
    this.customerLimitUsers = this.$route.query.customerLimitUsers;
    this.fetchData();
    this.loader = 0;
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    validateForm() {
      this.errors = [];
      let focusSet = false;

      if (!this.emailHrom.Subject) {
        this.errors.push(this.$i18n.t("CUSTOMERMAIL.ERRORS.SUBJECT_REQUIRED"));
        if (!focusSet) {
          this.$refs.subjectInput.focus();
          focusSet = true;
        }
      }

      if (!this.emailHrom.Content) {
        this.errors.push(this.$i18n.t("CUSTOMERMAIL.ERRORS.CONTENT_REQUIRED"));
        if (!focusSet) {
          const editor = this.$refs.contentEditor.editor;
          editor.focus();
          focusSet = true;
        }
      }

      return this.errors.length === 0;
    },
    save() {
      if (!this.validateForm()) {
        return;
      }
      UIService.addButtonSpinner("save_button");
      console.log(this.emailHrom.LimitUsers);
      // Convert boolean to integer
      this.emailHrom.LimitUsers = this.emailHrom.LimitUsers ? 1 : 0;
      console.log(this.emailHrom.LimitUsers);
      return ApiService.post("Email/PostMailDetail", this.emailHrom)
        .then(() => {
          UIService.removeButtonSpinner("save_button");
          this.$router.push({ name: "domus-customerMailer" });
        })
        .catch((response) => {
          this.errors.push(response.response.data.error);
          UIService.removeButtonSpinner("save_button");
        });
    },
    async send() {
      if (!this.validateForm()) {
        return;
      }
      // Ask for user confirmation
      const userConfirmed = confirm(this.$i18n.t("CUSTOMERMAIL.CONFIRMSEND"));
      if (!userConfirmed) {
        return; // Exit the method if the user cancels
      }
      UIService.addButtonSpinner("send_button");
      // Convert boolean to integer
      this.emailHrom.LimitUsers = this.emailHrom.LimitUsers ? 1 : 0;
      await ApiService.post("Email/PostMailDetail", this.emailHrom)
        .then(async (response) => {
          this.emailHrom.ID = response.data.EmailID;
          this.emailHrom.Operation = "O";
          await ApiService.post("Email/PostMailDetail", this.emailHrom);
        })
        .then(() => {
          UIService.removeButtonSpinner("send_button");
          this.$router.push({ name: "domus-customerMailer" });
        })
        .catch((error) => {
          this.errors.push(error.response.data.error);
          UIService.removeButtonSpinner("send_button");
        });
    },
    back() {
      this.$router.push({ name: "domus-customerMailer" });
    },
    customFilter(item, filter) {
      if (!filter) {
        return true;
      }
      const textFilter = filter.toLowerCase();
      return item.Email.toLowerCase().includes(textFilter);
    },
    fetchData() {
      this.loader = 1;
      if (this.customerMailId) {
        let parameters = "?EmailId=" + this.customerMailId.toString();
        ApiService.setHeader();
        ApiService.get("Email", "GetMailDetail" + parameters)
          .then(({ data }) => {
            this.emailHrom.ID = data.ID;
            this.emailHrom.Subject = data.EmailSubject;
            this.emailHrom.Content = data.EmailContent;
            this.emailHrom.DateInstructionToSendEmail =
              data.DateInstructionToSendEmail;
            this.emailHrom.LimitUsers = data.LimitUsers == 1 ? true : false;
            this.emailHrom.EmailDetail = Array.isArray(data.EmailDetail)
              ? data.EmailDetail
              : []; // Ensure it's an array
            this.totalRows = this.emailHrom.EmailDetail.length;
            this.loader = 0; // Hide loader after data is fetched
          })
          .catch((error) => {
            console.error(error);
            this.loader = 0; // Hide loader in case of error
          });
      } else {
        this.loader = 0; // Hide loader if no customerMailId
      }
    },
  },
};
</script>
