const config = {
  VERSION: window.__env.api["version"] || "1.0.0",
  API_URL: window.__env.api["apiUrl"] || "https://api-poschodoch.ana.sk/api",
  FACEBOOK_URL: process.env.VUE_APP_FACEBOOK_URL,
  GOOGLE_URL: process.env.VUE_APP_GOOGLE_URL,
  GOOGLE_CLIENT_ID: process.env.VUE_APP_GOOGLE_CLIENT_ID,
};

export { config };

export default {
  install(Vue) {
    Vue.appConfig = config;
    Vue.prototype.$appConfig = config;
  },
};
