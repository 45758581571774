<template>
  <ul class="nav poschodoch-context-menu">
    <li class="nav-item" v-if="!!functionPrint">
      <a
        href="javascript:void(0)"
        class="nav-link"
        data-toggle="tooltip"
        v-bind:title="$t('TABLE_CONTEXT_MENU.PRINT')"
        v-on:click="functionPrint()"
      >
        <i class="nav-link-icon flaticon2-printer poschodoch-icon-medium"></i>
      </a>
    </li>
    <li class="nav-item" v-if="!!functionCSV">
      <a
        href="javascript:void(0)"
        class="nav-link"
        data-toggle="tooltip"
        v-bind:title="$t('TABLE_CONTEXT_MENU.EXPORT_TO_CSV')"
        v-on:click="functionCSV()"
      >
        <i class="nav-link-icon flaticon2-file poschodoch-icon-medium"></i>
      </a>
      <a v-bind:id="FileDownloadLinkID" href style="display: none"></a>
    </li>
    <li class="nav-item" v-if="!!functionDownload">
      <a
        href="javascript:void(0)"
        class="nav-link"
        data-toggle="tooltip"
        v-bind:title="$t('TABLE_CONTEXT_MENU.DOWNLOAD')"
        v-on:click="functionDownload()"
      >
        <i class="nav-link-icon flaticon2-download poschodoch-icon-medium"></i>
      </a>
      <a v-bind:id="FileDownloadLinkID" href style="display: none"></a>
    </li>
  </ul>
</template>

<script>
import { FILE_DOWNLOAD_LINK_ID } from "@/common/file.service";

export default {
  name: "TableContextMenu",
  data() {
    return {
      FileDownloadLinkID: FILE_DOWNLOAD_LINK_ID,
    };
  },
  props: {
    functionCSV: Function,
    functionPrint: Function,
    functionDownload: Function,
  },
  components: {},
  mounted() {},
};
</script>
