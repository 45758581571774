<template>
  <div class="row">
    <div class="col-12 d-flex flex-column">
      <b-card
        v-show="serviceMessagesVisible"
        class="mb-3 text-center"
        text-variant="black"
        style="border-style: hidden; background-color: var(--company-color) !important"
      >
        <b-card-body>
          <!-- Iterate over each message in the serviceMessages array -->
          <div
            class="row justify-content-center"
            v-for="(message, index) in serviceMessages"
            :key="index"
          >
            <div class="col text-center">
              <!-- Display each message in a centered span -->
              <span class="mt-3 poschodoch-homepage-text">
                {{ message.message }}
              </span>
            </div>
          </div>
        </b-card-body>
      </b-card>
      <!-- nemazat, v pripade poruchy portalu len jednu odpoznamkujeme -
        <b-card
          text-variant="black"
          style="background-color: rgba(211, 109, 7, 1)"
        >
          <b-card-body>
            <div class="row">
              <div class="text-center">
                <span class="mt-3 poschodoch-homepage-text ">
                    {{ $t("AUTH.GENERAL.TEXT1") }}                    
                </span>
              </div>              
            </div>    
            </b-card-body>
        </b-card> 
        
        <b-card
          text-variant="black"
          style="background-color: rgba(211, 109, 7, 1)"
        >
          <b-card-body>
            <div class="row">
              <div class="text-center">
                <span class="mt-3 poschodoch-homepage-text ">
                    {{ $t("AUTH.GENERAL.TEXT2") }} 
                </span>
              </div>              
            </div>    
            </b-card-body>
        </b-card>-->
      <div
        class="poschodoch-homepage-above d-flex align-items-center flex-column justify-content-center align-self-center"
      >
        <b-button
          block
          class="mb-0 mb-sm-3"
          id="sign_in"
          variant="primary"
          @click="signIn"
        >
          {{ $t("AUTH.GENERAL.SIGNIN_BUTTON") }}
        </b-button>
        <b-button
          block
          class="mb-0 mb-sm-3"
          id="sign_up"
          variant="primary"
          @click="signUp"
        >
          {{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}
        </b-button>
        <b-button
          block
          class="mb-0 mb-sm-3"
          id="sign_up"
          variant="primary"
          @click="demoLogin"
          v-if="hideDemo == 0"
        >
          {{ $t("HOME.DEMO") }}
        </b-button>
        <b-button
          block
          id="install_pwa_app"
          variant="primary"
          @click="installApp"
          v-show="isInstallButtonVisible"
        >
          {{ $t("HOME.INSTALL") }}
        </b-button>
        <b-button
          block
          id="try_pwa_app"
          variant="primary"
          @click="tryApp"
          v-show="isTryButtonVisible"
        >
          {{ $t("HOME.APP") }}
        </b-button>
      </div>

      <div class="poschodoch-homepage">
        <b-card
          text-variant="black"
          style="background-color: rgba(255, 255, 255, 1)"
        >
          <b-card-body>
            <div class="row">
              <b-col sm="12" lg="4" class="mb-5">
                <h3 class="mb-4 poschodoch-homepage-title">
                  {{ nazovVelky }}
                </h3>
                <span class="mt-3 poschodoch-homepage-text">
                  {{ $t("HOME.MAINTEXT_LEFT") }}
                </span>
              </b-col>
              <b-col sm="12" lg="8" class="mb-5">
                <h3 class="mb-4 poschodoch-homepage-title">
                  {{ $t("HOME.MAINTITLE_RIGHT") }}
                </h3>
                <span class="mt-3 poschodoch-homepage-text">
                  {{ $t("HOME.MAINTEXT_RIGHT_1") }}
                  <br />
                  <br />
                  {{ $t("HOME.MAINTEXT_RIGHT_2") }}
                </span>
              </b-col>
            </div>

            <!-- separator line -->
            <hr class="poschodoch-separator-line-homepage" />
            <!-- separator line -->

            <div
              class="row mt-5 mb-5"
              style="padding-left: 10px; padding-right: 10px;"
            >
              <v-app>
                <v-hover v-slot="{ hover }">
                  <v-carousel
                    :hide-delimiters="true"
                    :hide-delimiter-background="true"
                    :cycle="!hover"
                    interval="5000"
                  >
                    <v-carousel-item v-for="(item, i) in bannerItems" :key="i">
                      <div class="row">
                        <b-col sm="12" lg="4">
                          <h3 class="mb-4 poschodoch-homepage-title">
                            {{ $t(item.titleText) }}
                          </h3>
                          <span class="poschodoch-homepage-text-smaller">
                            {{ $t(item.subText) }}
                          </span>
                        </b-col>
                        <b-col
                          sm="12"
                          lg="8"
                          class="poschodoch-homepage-bannerimage"
                        >
                          <img
                            :src="item.src"
                            style="width: 100% !important; border: 1px solid rgba(30,30,45,0.2)"
                          />
                        </b-col>
                      </div>
                    </v-carousel-item>
                  </v-carousel>
                </v-hover>
              </v-app>
            </div>

            <!-- separator line -->
            <hr class="poschodoch-separator-line-homepage" />
            <!-- separator line -->

            <div class="row mt-5 mb-5 poschodoch-homepage-functions">
              <h3
                class="mb-4 poschodoch-homepage-title"
                style="padding-left: 10px; padding-right: 10px;"
              >
                {{ $t("HOME.FUNCTIONS.TITLE") }}
              </h3>
              <div class="row text-center">
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\DenneSpotreby.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.DENNESPOT") }}
                  </span>
                </b-col>
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\FondOprav.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.FONDOPRAV") }}
                  </span>
                </b-col>
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\Udalosti.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.UDALOSTI") }}
                  </span>
                </b-col>
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\Dokumenty.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.DOKUMENTY") }}
                  </span>
                </b-col>

                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\RocneVyuctovanie.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.ROCNEVYUCT") }}
                  </span>
                </b-col>
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img src="\assets\media\icons\png\functionIcons\Grafy.png" />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.GRAFY") }}
                  </span>
                </b-col>
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img src="\assets\media\icons\png\functionIcons\Konto.png" />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.KONTO") }}
                  </span>
                </b-col>
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\Zasielky.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.ZASIELKY") }}
                  </span>
                </b-col>

                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\Faktury.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.FAKTURY") }}
                  </span>
                </b-col>
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\Revizie.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.REVIZIE") }}
                  </span>
                </b-col>
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\Kontakty.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.KONTAKTY") }}
                  </span>
                </b-col>
                <b-col
                  sm="4"
                  md="3"
                  lg="2"
                  class="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src="\assets\media\icons\png\functionIcons\Ziadosti.png"
                  />
                  <span class="poschodoch-homepage-text-smaller">
                    {{ $t("HOME.FUNCTIONS.ZIADOSTI") }}
                  </span>
                </b-col>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <!-- orange part - card footer -->
      <div class="p-5 poschodoch-homepage-below">
        <!-- <a href="#" class="kt-link kt-link--dark" @click="demoLogin">
          {{ $t("HOME.DEMO") }}
        </a> -->
      </div>
      <!-- orange part - card footer  -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import UIService from "@/common/ui.service";
import AppConfig from "@/common/config";
import { isInstallationAvailableByDomain } from "@/common/setUpPWAApp";
import {
  CHANGE_UNIT,
  LOGIN,
  LOGOUT,
  ADD_REGISTRATION_CODE,
} from "@/store/auth.module";

Vue.use(AppConfig);

export default {
  name: "home",
  data() {
    return {
      indicator: {
        clickable: true,
        type: "pill",
        showCounter: false,
      },
      lang: null,
      hideDemo: 0,
      defferedPrompt: undefined,
      isAppInstalled: localStorage.getItem("APP_INSTALLED") ?? false,
      nazovVelky: "",
      serviceMessages: [],
      serviceMessagesVisible: false,
      bannerItems: [
        {
          src: "/assets/media/banners/" + this.lang + "/Grafy_spotrieb.png",
          titleText: "HOME.BANNERS.GS.TITLE",
          subText: "HOME.BANNERS.GS.TEXT",
        },
        {
          src: "/assets/media/banners/" + this.lang + "/Kontakty.png",
          titleText: "HOME.BANNERS.K.TITLE",
          subText: "HOME.BANNERS.K.TEXT",
        },
        {
          src: "/assets/media/banners/" + this.lang + "/FondOprav.png",
          titleText: "HOME.BANNERS.FO.TITLE",
          subText: "HOME.BANNERS.FO.TEXT",
        },
      ],
      bannerItemsPenta: [
        {
          src: "/assets/media/banners/" + this.lang + "/Grafy_spotrieb.png",
          titleText: "HOME.BANNERS.GS.TITLE",
          subText: "HOME.BANNERS.GS.TEXT",
        },
        {
          src: "/assets/media/banners/" + this.lang + "/Kontakty_penta.png",
          titleText: "HOME.BANNERS.K.TITLE",
          subText: "HOME.BANNERS.K.TEXT",
        },
        {
          src: "/assets/media/banners/" + this.lang + "/FondOprav.png",
          titleText: "HOME.BANNERS.FO.TITLE",
          subText: "HOME.BANNERS.FO.TEXT",
        },
      ],
    };
  },
  beforeCreate() {
    this.lang = localStorage.getItem("language") || "SK";
  },
  mounted: function() {
    //set default brand color
    UIService.changeSiteColorCustomer();
    this.loadServiceMessages();
    if (window.location.host.includes("penta")) {
      this.nazovVelky = this.$i18n.t("HOME.MAINTITLE_LEFT_PENTA");
      this.hideDemo = 1;
      this.bannerItems = this.bannerItemsPenta;
    } else {
      this.nazovVelky = this.$i18n.t("HOME.MAINTITLE_LEFT");
    }

    //redirect to dashboard if already logged in
    if (this.isAuthenticated) {
      this.$router.replace({ name: "dashboard" });
    }

    //remove reg code from local storage if he did not come from registration page
    if (!this.$route.params.haveAccount) {
      localStorage.removeItem("registrationCode");
    }

    //PWA events
    window.addEventListener("beforeinstallprompt", (e) => {
      this.deferredPrompt = e;
      localStorage.removeItem("APP_INSTALLED");
      this.isAppInstalled = false;
    });

    window.addEventListener("appinstalled", () => {
      localStorage.setItem("APP_INSTALLED", true);
      this.isAppInstalled = true;
    });
  },
  methods: {
    async loadServiceMessages() {
      try {
        this.serviceMessages = [];
        const response = await fetch(
          "/assets/media/files/service_messages.json",
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        this.serviceMessages = data.serviceMessages;

        if (this.serviceMessages) {
          this.serviceMessagesVisible = true;
        }
      } catch (error) {
        console.error(
          "There was a problem with the fetch operation (service_message):",
          error,
        );
      }
    },
    signIn() {
      this.$router.push({ name: "login" });
    },
    signUp() {
      this.$router.push({ name: "registrationCode" });
    },
    tryApp() {
      if (
        window.location.host.includes("poschodech") &&
        !window.location.href.includes("Print")
      ) {
        window.open(
          "https://www.anasoft.com/domus/cz/home/Novinky-blog/Novinky/Mobilni-aplikace-POSCHODECH",
          "_blank",
        );
        //window.location.href = "https://www.anasoft.com/domus/cz/home/Novinky-blog/Novinky/Mobilni-aplikace-POSCHODECH";
      } else {
        window.open(
          "https://www.anasoft.com/domus/sk/home/Novinky-blog/Novinky/Mobilna-aplikacia-POSCHODOCH",
          "_blank",
        );
        //window.location.href = "https://www.anasoft.com/domus/sk/home/Novinky-blog/Novinky/Mobilna-aplikacia-POSCHODOCH";
      }
    },
    installApp() {
      if (this.deferredPrompt != undefined) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then(function(choiceResult) {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the install prompt");
          } else {
            console.log("User dismissed the install prompt");
          }
        });
      }
    },
    determineDemoUsername() {
      let username = "demo@poschodoch.sk";

      if (
        window.location.host.includes("poschodech") &&
        !window.location.host.includes("enbra")
      )
        username = "demo@poschodech.cz";
      if (
        window.location.host.includes("mojbyt") &&
        window.location.host.includes("enbra")
      )
        username = "demo@enbra.sk";
      if (
        window.location.host.includes("mujbyt") &&
        window.location.host.includes("enbra")
      )
        username = "demo@enbra.cz";
      if (window.location.host.includes("penta")) username = "demo@penta.sk";

      return username;
    },
    demoLogin() {
      const username = this.determineDemoUsername();
      const password = "Test1234";

      if (localStorage.getItem("registrationCode")) {
        const registrationCode = localStorage.getItem("registrationCode");
        localStorage.removeItem("registrationCode");
        this.$store
          .dispatch(ADD_REGISTRATION_CODE, {
            RegistrationCode: registrationCode,
            Email: username,
          })
          .then(() => {
            console.log("AddRegistrationCode");
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // send login request
      this.$store
        .dispatch(LOGIN, { UserName: username, Password: password })
        // go to which page after successfully login
        .then((data) => {
          if (data.length == 1) {
            // user has only one unit
            let obj = {
              unitID: data[0].UnitId,
              portalID: data[0].PortalId,
              unitType: data[0].UnitType,
            };

            this.$store.dispatch(CHANGE_UNIT, obj).then(() => {
              this.$router.push({ name: "dashboard" });
            });
          } else {
            // route to unit selection if user has more unit available
            this.$router.push({ name: "units" });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {
    isTryButtonVisible: function() {
      if (this.isAppInstalled) return false;
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) return true;
      return false;
    },
    isInstallButtonVisible: function() {
      if (!isInstallationAvailableByDomain()) return false;
      if (this.isAppInstalled) return false;
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) return false;

      return true;
    },
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["layoutConfig", "isAuthenticated"]),
  },
};
</script>
