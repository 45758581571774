<template>
  <b-col xs="12" sm="10" lg="5" class="mx-auto">
    <b-card
      v-show="serviceMessagesVisible"
      class="mb-3 text-center"
      text-variant="black"
      style="border-style: hidden; background-color: var(--company-color) !important"
    >
      <b-card-body>
        <!-- Iterate over each message in the serviceMessages array -->
        <div
          class="row justify-content-center"
          v-for="(message, index) in serviceMessages"
          :key="index"
        >
          <div class="col text-center">
            <!-- Display each message in a centered span -->
            <span class="mt-3 poschodoch-homepage-text">
              {{ message.message }}
            </span>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-card
      text-variant="black"
      style="background-color: rgba(255, 255, 255, 0.85)"
      v-show="isVisibleSK()"
    >
      <b-card-body>
        <!--begin::Body-->
        <div class="kt-login__body">
          <!--<RegistrationAgreementNew v-bind:visibleTerm="visibleTerm"/>-->

          <!--begin::Signin-->
          <div class="kt-login__form">
            <!--begin::Options-->
            <div class="kt-login__options">
              <span class="text-left mb-3">
                {{ $t("AUTH.LOGIN.VYUCTOVANIE1") }}
              </span>

              <a
                href="https://www.anasoft.com/domus/sk/home/Novinky-blog/blog/Rozpocitavanie-nakladov-podla-vyhlasky-503/2022-Z.z"
                target="_blank"
              >
                {{ $t("AUTH.LOGIN.VYUCTOVANIE2") }}
              </a>
            </div>
            <!--end::Options-->

            <!--end::Form-->
          </div>
          <!--end::Signin-->
        </div>
        <!--end::Body-->
      </b-card-body>
    </b-card>
    <hr class="poschodoch-separator-line mt-4 mb-4" v-show="isVisibleSK()" />
    <b-card
      text-variant="black"
      style="background-color: rgba(255, 255, 255, 0.85)"
    >
      <b-card-body>
        <!--begin::Body-->
        <div class="kt-login__body">
          <!--<RegistrationAgreementNew v-bind:visibleTerm="visibleTerm"/>-->

          <!--begin::Signin-->
          <div class="kt-login__form">
            <!--begin::Options-->
            <div class="kt-login__options">
              <div class="mb-2">
                <span class="text-left mb-3">
                  {{ $t("AUTH.LOGIN.LOGIN_EXTERNAL") }}
                </span>
              </div>
              <b-button block variant="facebook" v-on:click="loginWithFacebook">
                <i class="fab fa-facebook-f"></i>
                Facebook
              </b-button>
              <b-button block variant="light mt-3" v-on:click="loginWithGoogle">
                <i class="fab fa-google"></i>
                Google
              </b-button>
            </div>
            <!--end::Options-->

            <!--begin::Form-->
            <b-form class="kt-form" @submit.stop.prevent="onSubmit">
              <div
                role="alert"
                v-bind:class="{ show: errors && errors.length }"
                class="alert fade alert-danger mt-2 mb-2"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <!--begin::Divider-->
              <div class="kt-login__divider mb-2">
                <div>
                  <span class="text-left mb-3">
                    {{ $t("AUTH.LOGIN.LOGIN_WITH_USERNAME_PASSWORD") }}
                  </span>
                </div>
                <div>
                  <small>{{ $t("AUTH.LOGIN.WARNING") }}</small>
                </div>
              </div>
              <!--end::Divider-->

              <b-form-group
                id="example-input-group-1"
                label
                label-for="example-input-1"
              >
                <b-form-input
                  id="example-input-1"
                  name="example-input-1"
                  v-model="$v.form.username.$model"
                  :state="validateState('username')"
                  aria-describedby="input-1-live-feedback"
                  :placeholder="placeholderLogin"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ $t("AUTH.LOGIN.USERNAME_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="example-input-group-2"
                label
                label-for="example-input-2"
              >
                <b-form-input
                  type="password"
                  id="example-input-2"
                  name="example-input-2"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                  aria-describedby="input-2-live-feedback"
                  :placeholder="placeholderPWD"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  {{ $t("AUTH.LOGIN.PASSWORD_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <!--begin::Action-->
              <div class="kt-login__actions mt-4">
                <b-button block type="submit" id="kt_submit" variant="primary">
                  {{ $t("AUTH.LOGIN.BUTTON") }}
                </b-button>
              </div>

              <div class="mt-4">
                <a href="#" class="kt-link kt-link--dark" @click="demoLogin">
                  {{ $t("AUTH.LOGIN.DEMO") }}
                </a>
              </div>
              <div class="mt-2">
                <a
                  href="#"
                  class="kt-link kt-link--dark kt-login__link-forgot"
                  @click="passwordReset"
                >
                  {{ $t("AUTH.FORGOT.TITLE") }}
                </a>
              </div>
              <div class="kt-login__head mt-2">
                <span class="kt-login__signup-label">
                  {{ $t("AUTH.GENERAL.NO_ACCOUNT") }}
                </span>
                &nbsp;&nbsp;
                <router-link
                  class="kt-link kt-link--dark kt-login__signup-link"
                  :to="{ name: 'registrationCode' }"
                >
                  {{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}
                </router-link>
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Form-->
          </div>
          <!--end::Signin-->
        </div>
        <!--end::Body-->
      </b-card-body>
    </b-card>
  </b-col>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import {
  CHANGE_UNIT,
  LOGIN,
  LOGOUT,
  ADD_REGISTRATION_CODE,
  CHECK_REGISTRATION_CODE,
  EXTERNAL_LOGIN,
} from "@/store/auth.module";
import { validationMixin } from "vuelidate";
import { minLength, required, email } from "vuelidate/lib/validators";
import UIService from "@/common/ui.service";
//import RegistrationAgreementNew from "@/views/pages/auth/RegistrationAgreementNew.vue";
import ApiService from "@/common/api.service";
import AppConfig from "@/common/config";
import JwtService from "@/common/jwt.service";

Vue.use(AppConfig);

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      Id: null,
      Path: null,
      codeUnitId: null,
      codeUsersetCode: null,
      codeUnitType: null,
      codePortalId: null,
      serviceMessages: [], // Array to store multiple service messages
      serviceMessagesVisible: false,
      placeholderLogin: this.$i18n.t("AUTH.LOGIN.PLACEHOLDER_LOGIN"),
      placeholderPWD: this.$i18n.t("AUTH.LOGIN.PLACEHOLDER_PWD"),
      form: {
        username: "",
        password: "",
      },
    };
  },
  validations: {
    form: {
      username: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  mounted: function() {
    //set default brand color
    UIService.changeSiteColorCustomer();
    this.loadServiceMessages();
    this.Id = this.$route.query.id;
    this.Path = this.$route.query.path;
    this.Token = this.$route.query.token;

    //this.Path = this.$route.form.params[token];
    //this.Path = this.$route.form.params[reftoken];

    //susedia, resitech - prihlasovanie s tokenom
    if (this.Token) {
      this.$store.dispatch(EXTERNAL_LOGIN, this.Token).then(() => {
        this.$router.push({ name: "dashboard" });
      });
    }

    if (this.Path) {
      JwtService.setRoute(this.Path);
    }
    if (this.Id && (JwtService.getToken() || JwtService.getUnit())) {
      this.$store
        .dispatch(CHECK_REGISTRATION_CODE, this.Id)
        .then((data) => {
          this.codeUnitId = data.UnitId;
          this.codeUsersetCode = data.UsersetCode;
          this.codeUnitType = data.UnitType;
          this.codePortalId = data.PortalId;

          if (JwtService.getToken() || JwtService.getUnit()) {
            let portalid = JwtService.getPortalId();
            if (portalid != this.codePortalId) {
              let obj = {
                unitID: this.codeUnitId,
                portalID: this.codePortalId,
                unitType: this.codeUnitType,
              };
              this.$store.dispatch(CHANGE_UNIT, obj).then(() => {
                JwtService.loginRoute();
              });
            } else {
              JwtService.loginRoute();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (JwtService.getToken() || JwtService.getUnit()) {
      if (!this.Id) {
        JwtService.loginRoute();
      }
      //this.$router.replace({ name: "dashboard" });
    } else {
      this.$store.dispatch(LOGOUT);
    }

    //redirect to dashboard if already logged in
    /*if (this.isAuthenticated) {
      this.$router.replace({ name: "dashboard" });
    }*/

    //remove reg code from local storage if he does not came from registration page
    if (!this.$route.params.haveAccount) {
      localStorage.removeItem("registrationCode");
    }
  },
  components: {
    //RegistrationAgreementNew,
  },
  methods: {
    async loadServiceMessages() {
      try {
        this.serviceMessages = [];
        const response = await fetch(
          "/assets/media/files/service_messages.json",
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        this.serviceMessages = data.serviceMessages;

        if (this.serviceMessages) {
          this.serviceMessagesVisible = true;
        }
      } catch (error) {
        console.error(
          "There was a problem with the fetch operation (service_message):",
          error,
        );
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    loginWithFacebook() {
      let redirectUrl = window.location.protocol + "//" + window.location.host;
      window.location =
        Vue.appConfig.FACEBOOK_URL + redirectUrl + "&scope=email";
    },
    loginWithGoogle() {
      let redirectUrl = window.location.protocol + "//" + window.location.host;
      window.location = Vue.appConfig.GOOGLE_URL + redirectUrl + "&scope=email";
    },
    passwordReset() {
      this.$router.push({ name: "forgotPassword" });
    },
    isVisibleSK() {
      if (
        window.location.host.includes("poschodoch") &&
        !window.location.href.includes("Print")
      ) {
        return true;
      }
    },
    determineDemoUsername() {
      let username = "demo@poschodoch.sk";

      if (
        window.location.host.includes("poschodech") &&
        !window.location.host.includes("enbra")
      )
        username = "demo@poschodech.cz";
      if (
        window.location.host.includes("mojbyt") &&
        window.location.host.includes("enbra")
      )
        username = "demo@enbra.sk";
      if (
        window.location.host.includes("mujbyt") &&
        window.location.host.includes("enbra")
      )
        username = "demo@enbra.cz";
      if (window.location.host.includes("penta")) username = "demo@penta.sk";

      return username;
    },
    demoLogin() {
      const username = this.determineDemoUsername();
      const password = "Test1234";

      if (localStorage.getItem("registrationCode")) {
        const registrationCode = localStorage.getItem("registrationCode");
        localStorage.removeItem("registrationCode");
        this.$store
          .dispatch(ADD_REGISTRATION_CODE, {
            RegistrationCode: registrationCode,
            Email: username,
          })
          .then(() => {
            console.log("AddRegistrationCode");
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // send login request
      this.$store
        .dispatch(LOGIN, { UserName: username, Password: password })
        // go to which page after successfully login
        .then((data) => {
          if (data.length == 1) {
            // user has only one unit
            let obj = {
              unitID: data[0].UnitId,
              portalID: data[0].PortalId,
              unitType: data[0].UnitType,
            };

            this.$store.dispatch(CHANGE_UNIT, obj).then(() => {
              this.$router.push({ name: "dashboard" });
            });
          } else {
            // route to unit selection if user has more unit available
            this.$router.push({ name: "units" });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getUnit(unit, type, database, data) {
      let d = data.filter(function(data) {
        return (
          data.UnitId == unit &&
          data.UnitType == type &&
          data.CustomerCode == database
        );
      });
      return d;
    },
    setUnitLogin(data) {
      if (data.length == 1) {
        // user has only one unit
        let obj = {
          unitID: data[0].UnitId,
          portalID: data[0].PortalId,
          unitType: data[0].UnitType,
        };

        // podmienky ana
        let paramsA = "?Type=AnaTerms";
        ApiService.setHeader();
        ApiService.get("Account", "CheckProperties" + paramsA).then(
          ({ data }) => {
            if (data.Signed === 0) {
              this.$router.push({
                name: "registrationAgreementNew",
                query: { type: "A" },
                params: { ssk: obj },
              });
            } else {
              this.$store.dispatch(CHANGE_UNIT, obj).then(() => {
                // podmienky zak
                let paramsZ = "?Type=ZakTerms";
                ApiService.setHeader();
                ApiService.get("Account", "CheckProperties" + paramsZ).then(
                  ({ data }) => {
                    if (data.Signed === 0) {
                      this.$router.push({
                        name: "registrationAgreementNew",
                        query: { type: "Z" },
                        params: { price: data.Price },
                      });
                    } else {
                      JwtService.loginRoute();
                      //this.$router.push({ name: "dashboard" });
                    }
                  },
                );
              });
            }
          },
        );
      } else {
        let paramsA = "?Type=AnaTerms";
        ApiService.setHeader();
        ApiService.get("Account", "CheckProperties" + paramsA).then(
          ({ data }) => {
            if (data.Signed === 0) {
              this.$router.push({
                name: "registrationAgreementNew",
                query: { type: "A" },
              });
            } else {
              this.$router.push({ name: "units" });
            }
          },
        );
      }
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      if (localStorage.getItem("registrationCode")) {
        const registrationCode = localStorage.getItem("registrationCode");
        localStorage.removeItem("registrationCode");
        this.$store
          .dispatch(ADD_REGISTRATION_CODE, {
            RegistrationCode: registrationCode,
            Email: username,
          })
          .then(() => {
            console.log("AddRegistrationCode");
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      UIService.addButtonSpinner("kt_submit");

      // send login request
      this.$store
        .dispatch(LOGIN, { UserName: username, Password: password })
        // go to which page after successfully login
        .then((data) => {
          if (this.Id) {
            this.$store
              .dispatch(CHECK_REGISTRATION_CODE, this.Id)
              .then(
                (dataC) => {
                  this.codeUnitId = dataC.UnitId;
                  this.codeUsersetCode = dataC.UsersetCode;
                  this.codeUnitType = dataC.UnitType;
                  this.codePortalId = dataC.PortalId;

                  let dataUnit = [];
                  dataUnit = this.getUnit(
                    this.codeUnitId,
                    this.codeUnitType,
                    this.codeUsersetCode,
                    data,
                  );
                  if (dataUnit.length == 1) {
                    data = dataUnit;
                  }
                  this.setUnitLogin(data);
                },
                (error) => {
                  console.log(error);
                  this.setUnitLogin(data);
                },
              )
              .catch((error) => {
                console.log(error);
                this.setUnitLogin(data);
              });
          } else {
            this.setUnitLogin(data);
          }
          UIService.removeButtonSpinner("kt_submit");
        })
        .catch(() => {
          UIService.removeButtonSpinner("kt_submit");
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
