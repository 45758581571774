<template>
  <div>
    <b-modal id="modal-deactivate-unit-list-admin" hide-footer size="lg">
      <template v-slot:modal-title>{{ $t("UNIT_LIST.TITLE") }}</template>
      <div class="d-block text-center">
        <b-table
          hover
          :items="items"
          :fields="fields"
          show-empty
          size="md"
          class="table-white poschodoch-units-table"
          @row-clicked="tableRowClickHandler"
          :tbody-tr-class="pointerRowClass"
        >
          <template v-slot:cell(ConnectedAccounts)="data">
            <a
              href="javascript:void(0)"
              v-if="data.value > 1"
              v-on:click="
                changeUnitWithTxt(
                  data.item.UnitId,
                  data.item.PortalId,
                  data.item.UnitType,
                )
              "
              class="poschodoch-icon-link p-2 d-flex"
            >
              <i class="flaticon-users-1 poschodoch-icon-medium"></i>
              {{ data.value }}
            </a>
          </template>
          <template v-slot:cell(UnitId)="data">
            <b-button
              size="sm"
              variant="primary"
              :id="'unit_button_' + data.value"
              v-on:click="
                deactivateUnit(data.value, data.item.PortalId, data.item.UaID)
              "
              v-if="!data.item.Selected"
            >
              {{ $t("UNIT_LIST.CANCEL_BUTTON") }}
            </b-button>
          </template>
          <template v-slot:cell(Icon)="data">
            <span :class="data.value"></span>
          </template>
          <template v-slot:cell(Mobile)="data">
            <div class="d-flex flex-row align-items-start w-100">
              <div class="d-flex align-items-start mr-3 mt-2">
                <span :class="data.item.Icon"></span>
              </div>
              <div class="d-flex flex-column align-items-start w-100">
                <div class="d-flex w-100 flex-row justify-content-between">
                  <div
                    class="d-flex flex-row align-items-end poschodoch-mobile-right mr-1"
                  >
                    <span class="ml-2" style="font-weight: 600">
                      {{ data.item.Type }}
                    </span>
                    <span class="ml-3">{{ data.item.VarSymb }}</span>
                  </div>
                  <div
                    class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                  >
                    <b-button
                      size="sm"
                      variant="primary"
                      :id="'unit_button_' + data.item.UnitId"
                      v-on:click="
                        deactivateUnit(
                          data.item.UnitId,
                          data.item.PortalId,
                          data.item.UaID,
                        )
                      "
                      v-if="!data.item.Selected"
                    >
                      {{ $t("UNIT_LIST.CANCEL_BUTTON") }}
                    </b-button>
                  </div>
                </div>
                <div class="d-flex w-100 flex-row justify-content-between mt-3">
                  <div
                    class="d-flex flex-row align-items-end poschodoch-mobile-right mr-1"
                  >
                    <span class="ml-2" style="font-weight: 600; opacity: 0.9">
                      {{ fields[4].label }}
                    </span>
                    <span class="ml-3">{{ data.item.PropertyCompany }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </b-table>
        <div class="kt-notification__custom kt-space-between"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
//import { CHANGE_UNIT } from "@/store/auth.module";
//import JwtService from "@/common/jwt.service";
import UIService from "@/common/ui.service";
//import { LOGOUT } from "@/store/auth.module";
//import router from '@/router';

export default {
  name: "unit-list",
  props: {
    items: {},
  },
  data() {
    return {
      //items: [],
      fields: [
        {
          key: "Icon",
          label: "",
          class:
            "poschodoch-icon-medium poschodoch-icon-column table-no-mobile",
          sortable: false,
        },
        {
          key: "VarSymb",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.VAR_SYMBOL"),
          sortable: false,
        },
        {
          key: "Type",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.TYPE"),
          sortable: false,
        },
        {
          key: "Address",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.ADDRESS"),
          sortable: false,
        },
        {
          key: "PropertyCompany",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.PROPERTY_COMPANY"),
          sortable: false,
        },
        {
          key: "UnitId",
          label: "",
          sortable: false,
          class: "table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Selected == true) {
              item._rowVariant = "row-active";
            }
            return value;
          },
        },
        {
          key: "Mobile",
          label: "",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
    };
  },
  methods: {
    tableRowClickHandler(record) {
      this.changeUnit(record.UnitId, record.PortalId, record.uaId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    deactivateUnit(unitId, portalId, uaId) {
      /*let obj = {
        unitID: unitId,
        portalID: portalId,
        unitType: unitType,
      };*/
      UIService.addButtonSpinner("unit_button_" + unitId);

      const answer = window.confirm(this.$i18n.t("ADMINACCOUNT.QU"));
      if (!answer) {
        return;
      }

      let postContent = {
        portalID: portalId,
        uaID: uaId,
      };

      ApiService.setHeader();
      ApiService.post("Account/DeactivateUnit", postContent)
        .then(() => {
          //this.Init();
          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].UnitId == unitId) {
              this.items[i].Selected = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          UIService.removeButtonSpinner("unit_button_" + unitId);
        });

      UIService.removeButtonSpinner("unit_button_" + unitId);
    },
    onDeactivate() {
      let postContent = {
        Type: "ALL",
        Password: "",
        PortalId: this.uaId,
      };

      UIService.addButtonSpinner("d_button");
      ApiService.setHeader();
      ApiService.post("Account/DeactivateService", postContent)
        .then(() => {
          UIService.removeButtonSpinner("d_button");
        })
        .catch((error) => {
          console.log(error);
          UIService.removeButtonSpinner("d_button");
        });

      this.$root.$emit("bv::hide::modal", "modal-deactivate-unit-list-admin");
      //window.location = "/accountProfile?deactivate=1";
    },
    deactivateAccount(pwd) {
      let postContent = {
        Type: "ALL",
        Password: pwd,
      };

      ApiService.setHeader();
      ApiService.post("Account/DeactivateServiceAdmin", postContent).then(
        () => {
          /*this.$store
          .dispatch(LOGOUT)
          .then(() => {
            this.$router.push({ name: "login" });
          })
          .catch((err) => console.log(err));*/
        },
      );
    },
  },
  /*mounted: function() {
    // loading unit list
    //this.uaId = this.$route.query.uaId;
    this.Init();
  },*/
};
</script>
