<template>
  <div>
    <b-modal
      id="modal-movement-detail"
      hide-footer
      size="lg"
      v-show="movementDetail"
      class="poschodoch-modal-movement-detail"
    >
      <template v-slot:modal-title v-if="movementDetail">
        <span
          v-if="movementDetail.NameOfMovement"
          class="poschodoch-modal-title"
        >
          {{ movementDetail.NameOfMovement }}
        </span>
        <span
          v-if="movementDetail.DueDate"
          class="poschodoch-inline-devided-text"
        >
          {{ getFormatService().formatDateToLocal(movementDetail.DueDate) }}
        </span>
        <span
          v-if="movementDetail.Amount"
          class="poschodoch-inline-devided-text"
        >
          {{
            addCurrency(
              getFormatService().formatNumberToLocal(movementDetail.Amount),
            )
          }}
        </span>
      </template>

      <div class="d-block text-center">
        <b-tabs class="mt-1" align="left">
          <b-tab
            :title="$t('MOVEMENT_DETAIL.TABLE_ITEMS.TITLE')"
            v-if="
              movementDetail &&
                movementDetail.Items &&
                movementDetail.Items.length > 0
            "
          >
            <b-table
              hover
              :items="movementDetail.Items"
              :fields="fieldsItems"
            ></b-table>
          </b-tab>
          <b-tab
            :title="$t('MOVEMENT_DETAIL.TABLE_ASSIGNMENTS.TITLE')"
            v-if="
              movementDetail &&
                movementDetail.Assignments &&
                movementDetail.Assignments.length > 0
            "
          >
            <b-table
              hover
              :items="movementDetail.Assignments"
              :fields="fieldsAssignements"
            ></b-table>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FormatService from "@/common/format.service";
import JwtService from "@/common/jwt.service";

export default {
  name: "movement-detail",
  props: {
    movementDetail: {},
  },
  data() {
    return {
      fieldsItems: [
        {
          key: "Description",
          label: this.$i18n.t("MOVEMENT_DETAIL.TABLE_ITEMS.DESCRIPTION"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "Amount",
          label: this.$i18n.t("MOVEMENT_DETAIL.TABLE_ITEMS.AMOUNT"),
          sortable: false,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
      ],
      fieldsAssignements: [
        {
          key: "Description",
          label: this.$i18n.t("MOVEMENT_DETAIL.TABLE_ASSIGNMENTS.DESCRIPTION"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "Amount",
          label: this.$i18n.t("MOVEMENT_DETAIL.TABLE_ASSIGNMENTS.AMOUNT"),
          sortable: false,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
      ],
    };
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return (
        data + " " + FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
  },
};
</script>
