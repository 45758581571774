<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Filter -->
        <KTPortlet
          v-bind:title="title"
          v-show="itemsCold || itemsHot || itemsHeat"
        >
          <template v-slot:body>
            <b-container fluid v-if="itemsCold || itemsHot || itemsHeat">
              <b-row>
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div class="d-flex flex-row">
                    <div
                      class="ml-md-2 mt-3 mt-md-0 d-flex flex-sm-row flex-column"
                    >
                      <span
                        class="d-flex flex-row align-items-center mt-sm-0 mt-3"
                      >
                        <label for="dT" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("METERDAILYREADINGSOBJECT.DATETO") }}
                        </label>
                        <b-form-input
                          id="dT"
                          v-model="inputDateTo"
                          type="date"
                        ></b-form-input>
                      </span>
                      <div
                        class="d-flex align-items-sm-center align-self-center"
                      >
                        <b-button
                          variant="primary"
                          class="ml-sm-3 ml-0 mt-3 mt-sm-0"
                          v-on:click="fetchData(inputDateTo, inputMedium)"
                        >
                          {{ $i18n.t("METERDAILYREADINGSOBJECT.BUTTONOK") }}
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <!--<b-col class="d-flex flex-row  mr-md-3 mr-0 col-lg-3 col-md-2 col-sm-12">
                    <label for="typeAccess" class="mr-1" style="font-weight: 500">
                      {{ $i18n.t("IDENTITY.LABEL.ACCESS") }}:
                    </label>
                    <b-form-select
                      id="typeAccess"
                      v-model="inputMedium"
                      :options="mediumOptions"
                      @change="fetchData(inputDateTo, inputMedium)"                      
                    ></b-form-select>
                  </b-col>-->
                </b-col>
              </b-row>
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
        <!--End::Filter -->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption Water-->
        <KTPortlet v-bind:title="subTitleCold" v-if="itemsCold">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadWaterColdCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="itemsCold">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto; overflow-y: auto; "
                v-if="chartOptionsCold"
                class="poschodoch-consumption-graph"
              >
                <div
                  id="ColdWID"
                  :style="{ minWidth: '800px', minHeight: '300px' }"
                  :height="heightCold"
                >
                  <Chart
                    ref="daily-consumption-chart-cold"
                    id="daily-consumption-chart-cold"
                    v-for="c in chartsCold"
                    :key="c"
                    :height="heightCold"
                    v-bind:options="chartOptionsCold"
                    width="0"
                    responsive="true"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->
              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkCold"
                    v-on:change="showHideDatasetColdWater(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legC">
                    {{
                      $i18n.t(
                        "METERDAILYREADINGSOBJECT.CHARTS.LEGEND_WATER_COLD",
                      )
                    }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkColdObject"
                    v-on:change="showHideDatasetColdWater(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legCA">
                    {{
                      $i18n.t(
                        "METERDAILYREADINGSOBJECT.CHARTS.LEGEND_AVERAGE_WATER_COLD",
                      )
                    }}
                  </span>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsCold">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption Water-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption Water-->
        <KTPortlet v-bind:title="subTitleHot" v-if="itemsHot">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadWaterHotCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="itemsHot">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHot"
                class="poschodoch-consumption-graph-hot"
              >
                <div
                  :style="{ minWidth: '800px', minHeight: '300px' }"
                  :height="heightHot"
                >
                  <Chart
                    ref="daily-consumption-chart-hot"
                    v-for="c in chartsHot"
                    :key="c"
                    v-bind:options="chartOptionsHot"
                    :height="heightHot"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->
              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHot"
                    v-on:change="showHideDatasetHotWater(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legH">
                    {{
                      $i18n.t(
                        "METERDAILYREADINGSOBJECT.CHARTS.LEGEND_WATER_HOT",
                      )
                    }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHotObject"
                    v-on:change="showHideDatasetHotWater(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legHA">
                    {{
                      $i18n.t(
                        "METERDAILYREADINGSOBJECT.CHARTS.LEGEND_AVERAGE_WATER_HOT",
                      )
                    }}
                  </span>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHot">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption Water-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption Water-->
        <KTPortlet v-bind:title="subTitleHeat" v-if="itemsHeat">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu :functionCSV="downloadHeatCSV"></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="itemsHeat">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHeat"
                class="poschodoch-consumption-graph"
              >
                <div
                  :style="{ minWidth: '800px', minHeight: '300px' }"
                  :height="heightHeat"
                >
                  <Chart
                    ref="daily-consumption-chart-heat"
                    v-for="c in chartsHeat"
                    :key="c"
                    v-bind:options="chartOptionsHeat"
                    :height="heightHeat"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->
              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHeat"
                    v-on:change="showHideDatasetHeat(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legHe">
                    {{ $i18n.t("METERDAILYREADINGSOBJECT.CHARTS.LEGEND_HEAT") }}
                  </span>
                </div>
                <div style="display: flex" class="mr-5">
                  <b-form-checkbox
                    id="checkHeatObject"
                    v-on:change="showHideDatasetHeat(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span class="legHeA">
                    {{
                      $i18n.t(
                        "METERDAILYREADINGSOBJECT.CHARTS.LEGEND_AVERAGE_HEAT",
                      )
                    }}
                  </span>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHeat">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption Water-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
//import ColorService from "@/common/color.service";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";

export default {
  name: "domus-meter-daily-readings-object",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    Chart,
  },
  data() {
    return {
      heightCold: 300,
      heightHot: 300,
      heightHeat: 300,
      title: null,
      description: null,
      subTitle: null,
      subTitleRooms: null,
      menuId: null,
      hidden: false,
      hidden2: false,
      itemsCold: null,
      itemsHot: null,
      itemsHeat: null,
      year: null,
      years: null,
      yearFrom: null,
      yearTo: null,
      month: null,
      months: null,
      monthFrom: null,
      monthTo: null,
      inputDateFrom: null,
      inputDateTo: null,
      inputSearch: null,
      inputMedium: null,
      itemsColdUnit: null,
      chartOptionsCold: null,
      chartOptionsHot: null,
      chartOptionsHeat: null,
      mediumOptions: [],
      chartLabelsCold: [],
      chartLabelsHot: [],
      chartLabelsHeat: [],
      chartDataColdWaterObject: [],
      chartDataHotWaterObject: [],
      chartDataHeatObject: [],
      chartDatasetLabelHotWater: this.$i18n.t(
        "METERDAILYREADINGSOBJECT.CHARTS.HOT_WATER",
      ),
      chartDatasetLabelHeat: this.$i18n.t(
        "METERDAILYREADINGSOBJECT.CHARTS.HEAT",
      ),
      chartDataHotWater: [],
      chartsCold: [1],
      chartsHot: [1],
      chartsHeat: [1],
      chartDatasetLabelColdWater: this.$i18n.t(
        "METERDAILYREADINGSOBJECT.CHARTS.COLD_WATER",
      ),
      chartDataColdWater: [],
      chartDataHeat: [],
      chartTooltipsColdWater: [],
      chartTooltipsHotWater: [],
      chartTooltipsHeat: [],
      chartRooms: [],
      datasetsRooms: [],
      //colors
      colorhot: null,
      colorcold: null,
      colorheat: null,
      colortooltip: null,
      sortBy: "",
      sortDirection: "desc",
      totalRows: 1,
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
    };
  },
  mounted() {
    this.colorhot = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.danger");
    this.colorcold = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-cold")
      : this.layoutConfig("colors.state.info");
    this.colorheat = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-heat")
      : this.layoutConfig("colors.state.brand");
    this.colortooltip = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.brand");
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    isPreviousBtnDisabled() {
      return !this.isMonthInRange(this.month - 1);
    },
    isNextBtnDisabled() {
      return !this.isMonthInRange(this.month + 1);
    },
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    createChartCold() {
      this.chartOptionsCold = {
        type: "horizontalBar",
        data: {
          datasets: [
            {
              data: this.chartDataColdWaterObject,
              label: this.chartDatasetLabelColdWater,
              fill: true,
              //borderColor: this.layoutConfig("colors.state.primary"),
              borderWidth: 3,
              //backgroundColor: "rgba(97,97,97,0.3)",
              //Color: "rgba(97,97,97,0.3)",
              borderColor: "rgba(170,170,170,0.3)",
              backgroundColor: "rgba(170,170,170,0.3)",
              type: "line",
            },
            {
              data: this.chartDataColdWater,
              label: this.chartDatasetLabelColdWater,
              fill: false,
              borderColor: this.colorcold,
              backgroundColor: this.colorcold,
            },
          ],
          labels: this.chartLabelsCold,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legendDiv: "daily-consumption-chart-cold-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          /*animation: {
            animateScale: true,
            animateRotate: true,
            onComplete: function () {
              //var chartHeight = data.ColdWater.length * 31 + 200;
              this.options.animation.onComplete = null;
              var G1 = document.getElementById('ColdWID');
              G1.style.height = 500;
              this.Chart.canvas.parentNode.style.height = 500;
            }
          },*/
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colorcold,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: () => {
                //let title = this.getFormatService().formatDateToLocal(this.chartTooltipsColdWater[tooltipItem[0].index]);
                let title = ""; //tooltipItem[0].label;
                //let title = tooltipItem[0].label;
                return title;
              },
              label: (tooltipItem) => {
                let label = tooltipItem.value;
                /*if (tooltipItem.datasetIndex == 0 ) {
                  label = tooltipItem.label;                
                };*/
                return label;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "METERDAILYREADINGSOBJECT.CHARTS.AX_X_COLD",
                  ),
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            yAxes: [
              {
                //barPercentage: 0.3,
                //barThickness: 20,
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "METERDAILYREADINGSOBJECT.CHARTS.AX_Y_COLD",
                  ),
                },
                ticks: {
                  beginAtZero: true,
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },

    createChartHot() {
      this.chartOptionsHot = {
        type: "horizontalBar",
        data: {
          datasets: [
            {
              data: this.chartDataHotWaterObject,
              label: this.chartDatasetLabelHotWater,
              fill: true,
              //borderColor: this.layoutConfig("colors.state.primary"),
              borderWidth: 3,
              borderColor: "rgba(170,170,170,0.3)",
              backgroundColor: "rgba(170,170,170,0.3)",
              type: "line",
            },
            {
              data: this.chartDataHotWater,
              label: this.chartDatasetLabelHotWater,
              fill: false,
              borderColor: this.colorhot,
              backgroundColor: this.colorhot,
            },
          ],
          labels: this.chartLabelsHot,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "daily-consumption-chart-hot-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colorhot,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: () => {
                //let title = this.getFormatService().formatDateToLocal(this.chartTooltipsColdWater[tooltipItem[0].index]
                let title = ""; //tooltipItem[0].label;
                return title;
              },
              label: (tooltipItem) => {
                let label = tooltipItem.value;
                /*if (tooltipItem.datasetIndex == 0 ) {
                  label = tooltipItem.label;                
                };*/
                return label;
              },
            },
          },
          indexAxis: "y",
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "METERDAILYREADINGSOBJECT.CHARTS.AX_X_HOT",
                  ),
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "METERDAILYREADINGSOBJECT.CHARTS.AX_Y_HOT",
                  ),
                },
                ticks: {
                  beginAtZero: true,
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },

    createChartHeat() {
      this.chartOptionsHeat = {
        type: "horizontalBar",
        data: {
          datasets: [
            {
              data: this.chartDataHeatObject,
              label: this.chartDatasetLabelHeat,
              fill: true,
              //borderColor: this.layoutConfig("colors.state.primary"),
              borderWidth: 3,
              borderColor: "rgba(170,170,170,0.3)",
              backgroundColor: "rgba(170,170,170,0.3)",
              type: "line",
            },
            {
              data: this.chartDataHeat,
              label: this.chartDatasetLabelHeat,
              fill: false,
              borderColor: this.colorheat,
              backgroundColor: this.colorheat,
            },
          ],
          labels: this.chartLabelsHeat,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "daily-consumption-chart-heat-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colorheat,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: () => {
                //let title = this.getFormatService().formatDateToLocal(this.chartTooltipsColdWater[tooltipItem[0].index]
                let title = ""; //tooltipItem[0].label;
                return title;
              },
              label: (tooltipItem) => {
                let label = tooltipItem.value;
                /*if (tooltipItem.datasetIndex == 0 ) {
                  label = tooltipItem.label;                
                };*/
                return label;
              },
            },
          },
          indexAxis: "y",
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "METERDAILYREADINGSOBJECT.CHARTS.AX_X_HEAT",
                  ),
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "METERDAILYREADINGSOBJECT.CHARTS.AX_Y_HEAT",
                  ),
                },
                ticks: {
                  beginAtZero: true,
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    showHideDatasetColdWater(index) {
      this.chartOptionsCold.data.datasets[index].hidden = !this.chartOptionsCold
        .data.datasets[index].hidden;
      this.chartOptionsCold.chart.update();
    },
    showHideDatasetHotWater(index) {
      this.chartOptionsHot.data.datasets[index].hidden = !this.chartOptionsHot
        .data.datasets[index].hidden;
      this.chartOptionsHot.chart.update();
    },
    showHideDatasetHeat(index) {
      this.chartOptionsHeat.data.datasets[index].hidden = !this.chartOptionsHeat
        .data.datasets[index].hidden;
      this.chartOptionsHeat.chart.update();
    },
    transformData(data) {
      let consumptionHotWater = [];
      let consumptionColdWater = [];
      let consumptionHeat = [];
      let consumptionColdWaterObject = [];
      let consumptionHotWaterObject = [];
      let consumptionHeatObject = [];
      let labelsCold = [];
      let labelsHot = [];
      let labelsHeat = [];
      let tooltipsCold = [];
      let tooltipsHot = [];
      let tooltipsHeat = [];
      let pixH = 21;
      if (data.ColdWater) {
        for (let i = 0; i < data.ColdWater.length; i++) {
          consumptionColdWater.push({
            x: data.ColdWater[i].Consumption,
            y: data.ColdWater[i].Apartment,
          });
          consumptionColdWaterObject.push({
            x: data.ColdWater[i].Consumption2,
            y: data.ColdWater[i].Apartment,
          });
          tooltipsCold.push(data.ColdWater[i].DateTo);
          this.chartDatasetLabelColdWater = data.ColdWater[i].Type;
          labelsCold.push(data.ColdWater[i].Apartment);
        }
        this.heightCold = data.ColdWater.length * pixH + 200;
      }
      if (data.HotWater) {
        for (let i = 0; i < data.HotWater.length; i++) {
          consumptionHotWater.push({
            x: data.HotWater[i].Consumption,
            y: data.HotWater[i].Apartment,
          });
          consumptionHotWaterObject.push({
            x: data.HotWater[i].Consumption2,
            y: data.HotWater[i].Apartment,
          });
          tooltipsHot.push(data.HotWater[i].DateTo);
          this.chartDatasetLabelHotWater = data.HotWater[i].Type;
          labelsHot.push(data.HotWater[i].Apartment);
        }
        this.heightHot = data.HotWater.length * pixH + 200;
      }

      if (data.Heat) {
        for (let i = 0; i < data.Heat.length; i++) {
          consumptionHeat.push({
            x: data.Heat[i].Consumption,
            y: data.Heat[i].Apartment,
          });
          consumptionHeatObject.push({
            x: data.Heat[i].Consumption2,
            y: data.Heat[i].Apartment,
          });
          tooltipsHeat.push(data.Heat[i].DateTo);
          this.chartDatasetLabelHeat = data.Heat[i].Type;
          labelsHeat.push(data.Heat[i].Apartment);
        }
        this.heightHeat = data.Heat.length * pixH + 200;
      }
      /* var currentDate = new Date(data.DateFrom);
      while (currentDate <= new Date(data.DateTo)) {
        labels.push(currentDate.getDate());
        currentDate.setDate(currentDate.getDate() + 1);
      }*/

      this.chartLabelsCold = labelsCold;
      this.chartLabelsHot = labelsHot;
      this.chartLabelsHeat = labelsHeat;
      this.chartDataHotWater = consumptionHotWater;
      this.chartDataColdWater = consumptionColdWater;
      this.chartDataHeat = consumptionHeat;
      this.chartTooltipsColdWater = tooltipsCold;
      this.chartTooltipsHotWater = tooltipsHot;
      this.chartTooltipsHeat = tooltipsHeat;
      this.chartDataColdWaterObject = consumptionColdWaterObject;
      this.chartDataHotWaterObject = consumptionHotWaterObject;
      this.chartDataHeatObject = consumptionHeatObject;
    },

    downloadWaterColdCSV() {
      this.downloadCSV(
        this.subTitle,
        [
          "",
          this.chartDatasetLabelColdWater,
          this.chartDatasetLabelColdWater + " - Avg",
        ],
        this.chartLabelsCold,
        this.itemsCold,
        this.itemsCold,
      );
    },
    downloadWaterHotCSV() {
      this.downloadCSV(
        this.subTitle,
        [
          "",
          this.chartDatasetLabelHotWater,
          this.chartDatasetLabelHotWater + " - Avg",
        ],
        this.chartLabelsHot,
        this.itemsHot,
        this.itemsHot,
      );
    },
    downloadHeatCSV() {
      this.downloadCSV(
        this.subTitle,
        ["", this.chartDatasetLabelHeat, this.chartDatasetLabelHeat + " - Avg"],
        this.chartLabelsHeat,
        this.itemsHeat,
        this.itemsHeat,
      );
    },
    downloadRoomsCSV() {
      this.downloadCSV(
        this.subTitleRooms,
        ["", ...this.chartRooms],
        this.chartLabelsRooms,
        this.itemsRooms,
      );
    },
    downloadCSV(title, header, days, items, itemsObject) {
      let values = items
        .map(function(v) {
          return [
            header[1],
            v.Apartment,
            FormatService.formatNumberToLocalForCSV(v.Consumption),
          ];
        })
        .concat(
          itemsObject.map(function(v) {
            return [
              header[2],
              v.Apartment,
              FormatService.formatNumberToLocalForCSV(v.Consumption2),
            ];
          }),
        );

      let result = FileService.generateChartCSV(header, days, values);
      let fileName = title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(sDateTo, sInputMedium) {
      // water
      this.itemsCold = null;
      this.itemsHot = null;
      this.itemsHeat = null;
      this.chartOptions = null;
      this.mediumOptions = null;
      let parameters = "?menuId=" + this.menuId.toString();

      if (sDateTo != null) parameters += "&dateTo=" + sDateTo;
      if (sInputMedium != null) {
        parameters += "&type=" + sInputMedium;
      }

      ApiService.setHeader();
      ApiService.get("Flat", "MeterDailyReadingsObject" + parameters)
        .then(({ data }) => {
          this.transformData(data);
          this.createChartCold();
          this.createChartHot();
          this.createChartHeat();
          this.mediumOptions = data.Medium;
          this.title = data.Title;
          this.description = data.Description;

          //this.itemsColdUnit = "m3";
          this.subTitle = data.Subtitle; // + this.itemsColdUnit;

          this.subTitleCold =
            data.Subtitle +
            " - " +
            this.$i18n.t("METERDAILYREADINGSOBJECT.CHARTS.COLD_WATER");
          this.subTitleHot =
            data.Subtitle +
            " - " +
            this.$i18n.t("METERDAILYREADINGSOBJECT.CHARTS.HOT_WATER");
          this.subTitleHeat =
            data.Subtitle +
            " - " +
            this.$i18n.t("METERDAILYREADINGSOBJECT.CHARTS.HEAT");
          this.itemsCold = data.ColdWater;
          //if (this.itemsCold) this.itemsColdUnit = this.itemsCold[0].Unit;

          //this.itemsHot = data.HotdWater;
          //this.itemsHeat = data.Heat;
          this.itemsHot = data.HotWater;
          this.itemsHeat = data.Heat;

          this.inputDateTo = data.DateTo;
        })
        .catch(() => {
          this.items = undefined;
        });
    },
  },
};
</script>
