<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <!--Begin::Nova ziadost-->
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <span>
                <slot name="title">{{ $i18n.t("NEW_REQUEST.TITLEPUB") }}</slot>
              </span>
            </h3>
          </template>
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-2"
              @click="SendSaveRequest('send')"
              id="send_button"
            >
              {{ $i18n.t("REQUEST_DETAIL.SENDBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row">
              <!-- <div class="col-lg-6 col-sm-12">                -->

              <b-form-group
                class="col-lg-6 col-sm-12"
                :label="labelName"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="name"
                  type="text"
                  @change="isEdited = true"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-lg-6 col-sm-12"
                :label="labelSurname"
                label-for="input-surname"
              >
                <b-form-input
                  id="input-surname"
                  v-model="surname"
                  :state="validateState('surname')"
                  type="text"
                  @change="isEdited = true"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-lg-6 col-sm-12"
                :label="labelStreet"
                label-for="input-street"
              >
                <b-form-input
                  id="input-street"
                  v-model="street"
                  :state="validateState('street')"
                  type="text"
                  @change="isEdited = true"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-lg-6 col-sm-12"
                :label="labelCity"
                label-for="input-city"
              >
                <b-form-input
                  id="input-city"
                  v-model="city"
                  :state="validateState('city')"
                  type="text"
                  @change="isEdited = true"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-lg-6 col-sm-12"
                :label="labelMobile"
                label-for="input-mobile"
              >
                <b-form-input
                  id="input-mobile"
                  v-model="mobile"
                  :state="validateState('mobile')"
                  type="text"
                  @change="isEdited = true"
                  required
                ></b-form-input>
              </b-form-group>
              <!--</div> -->

              <!-- <div class="col-lg-6 col-sm-12">-->

              <b-form-group
                class="col-lg-6 col-sm-12"
                :label="labelEmail"
                label-for="input-email"
              >
                <b-form-input
                  id="input-email"
                  v-model="email"
                  type="email"
                  :state="validateState('email')"
                  @change="isEdited = true"
                  required
                ></b-form-input>
              </b-form-group>
              <!-- </div>-->

              <div class="col-lg-12">
                <b-form-group
                  :label="labelDescription"
                  label-for="input-description"
                >
                  <b-form-textarea
                    id="input-description"
                    v-model="description"
                    type="text"
                    rows="3"
                    @change="isEdited = true"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>

            <b-form-group
              v-if="meraceSaved.length > 0"
              :label="labelMeterOld"
              label-for="input-merace-old"
            >
              <!--<div class="col-12 mb-5" v-if="meraceSaved.length > 0"> -->
              <!--<h5 class="mb-4">{{ $i18n.t("NEW_REQUEST.OLDMETERS") }}</h5> -->

              <div class="col-12 ml-1 mt-2">
                <div v-for="merac in meraceSaved" :key="merac.number">
                  <span class="ml-1">
                    {{ $i18n.t("NEW_REQUEST.METERNR") }} {{ merac.number }}
                  </span>
                  <span class="ml-3">
                    {{ $i18n.t("NEW_REQUEST.STATE") }} {{ merac.stav }}
                  </span>
                </div>
              </div>

              <!--</div> -->
            </b-form-group>
            <div class="row">
              <b-form-group
                class="col-lg-4 col-sm-12"
                :label="labelMeterNr"
                label-for="input-cislo-meraca"
              >
                <b-form-input
                  id="input-cislo-meraca"
                  v-model="merac"
                  type="text"
                  :state="validateState('merac')"
                  @change="isEdited = true"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-lg-4 col-sm-12"
                :label="labelReadingDate"
                label-for="input-datum-odpoctu"
              >
                <b-form-datepicker
                  id="input-datum-odpoctu"
                  v-model="datumOdpoctu"
                  :placeholder="placeholderDate"
                  :state="validateState('datumOdpoctu')"
                  @change="isEdited = true"
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group
                class="col-lg-4 col-sm-12"
                :label="labelState"
                label-for="input-merac-stav"
              >
                <b-form-input
                  id="input-merac-stav"
                  v-model="meracStav"
                  type="number"
                  :state="validateState('meracStav')"
                  @change="isEdited = true"
                ></b-form-input>
              </b-form-group>
            </div>

            <b-modal ref="my-modal" hide-footer :title="labelTitleYesNo">
              <div class="d-block text-center">
                <h5>{{ $i18n.t("NEW_REQUEST.METERLEAVE") }}</h5>
              </div>
              <div class="row justify-content-center">
                <b-button
                  class="col-sm-5 mr-2"
                  variant="primary"
                  @click="YesModal"
                >
                  {{ $i18n.t("NEW_REQUEST.YES") }}
                </b-button>
                <b-button
                  class="col-sm-5 mr-2"
                  variant="secondary"
                  @click="NoModal"
                >
                  {{ $i18n.t("NEW_REQUEST.NO") }}
                </b-button>
              </div>
            </b-modal>

            <div>
              <h5 class="mb-4">{{ $i18n.t("NEW_REQUEST.UPLOADPUB") }}</h5>
              <div class="row">
                <div class="col-12">
                  <b-form-group>
                    <b-form-file
                      v-model="files"
                      :placeholder="placeholderFile"
                      @input="getBase64File"
                    ></b-form-file>
                  </b-form-group>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>

        <KTPortlet v-if="Type == 1">
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <span>
                <slot name="title">
                  {{ $i18n.t("NEW_REQUEST.TITLEPUBPERSON") }}
                </slot>
              </span>
            </h3>
          </template>
          <template v-slot:body>
            <!-- Nahlasenie osobomesiacov -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>

              <b-form-row>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelYear"
                    label-for="input-osobomesiace-rok"
                  >
                    <b-form-input
                      id="input-osobomesiace-rok"
                      v-model="rok"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelJanuary"
                    label-for="input-osobomesiace-januar"
                  >
                    <b-form-input
                      id="input-osobomesiace-1"
                      v-model="januar"
                      type="number"
                      @change="updateVal(januar, 1)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelFebruary"
                    label-for="input-osobomesiace-februar"
                  >
                    <b-form-input
                      id="input-osobomesiace-2"
                      v-model="februar"
                      type="number"
                      @change="updateVal(februar, 2)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelMarch"
                    label-for="input-osobomesiace-marec"
                  >
                    <b-form-input
                      id="input-osobomesiace-3"
                      v-model="marec"
                      type="number"
                      @change="updateVal(marec, 3)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelApril"
                    label-for="input-osobomesiace-april"
                  >
                    <b-form-input
                      id="input-osobomesiace-4"
                      v-model="april"
                      type="number"
                      @change="updateVal(april, 4)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelMay"
                    label-for="input-osobomesiace-maj"
                  >
                    <b-form-input
                      id="input-osobomesiace-5"
                      v-model="maj"
                      type="number"
                      @change="updateVal(maj, 5)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelJune"
                    label-for="input-osobomesiace-jun"
                  >
                    <b-form-input
                      id="input-osobomesiace-6"
                      v-model="jun"
                      type="number"
                      @change="updateVal(jun, 6)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelJuly"
                    label-for="input-osobomesiace-jul"
                  >
                    <b-form-input
                      id="input-osobomesiace-7"
                      v-model="jul"
                      type="number"
                      @change="updateVal(jul, 7)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelAugust"
                    label-for="input-osobomesiace-august"
                  >
                    <b-form-input
                      id="input-osobomesiace-8"
                      v-model="august"
                      type="number"
                      @change="updateVal(august, 8)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelSeptember"
                    label-for="input-osobomesiace-september"
                  >
                    <b-form-input
                      id="input-osobomesiace-9"
                      v-model="september"
                      type="number"
                      @change="updateVal(september, 9)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelOctober"
                    label-for="input-osobomesiace-oktober"
                  >
                    <b-form-input
                      id="input-osobomesiace-10"
                      v-model="oktober"
                      type="number"
                      @change="updateVal(oktober, 10)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelNovember"
                    label-for="input-osobomesiace-november"
                  >
                    <b-form-input
                      id="input-osobomesiace-11"
                      v-model="november"
                      type="number"
                      @change="updateVal(november, 11)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-2">
                  <b-form-group
                    :label="labelDecember"
                    label-for="input-osobomesiace-december"
                  >
                    <b-form-input
                      id="input-osobomesiace-12"
                      v-model="december"
                      type="number"
                      @change="updateVal(december, 12)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </div>
          </template>
        </KTPortlet>

        <KTPortlet>
          <template v-slot:body>
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <!--<label for="robot" class="col-sm-2 col-form-label"></label>-->

                <!--<div class="g-recaptcha" :data-sitekey="rcapt_sig_key"></div>-->

                <!-- <vue-recaptcha ref="recaptcha"
                  @verify="onVerify" @expired="onExpire" v-model="robot" sitekey="6LflTnMUAAAAALVqcLb9HYoGwQ9NIOAP_EPhLZhL">
                </vue-recaptcha>-->
                <!--<div class="g-recaptcha" data-sitekey="6LflTnMUAAAAALVqcLb9HYoGwQ9NIOAP_EPhLZhL"></div>-->
                <vue-recaptcha
                  id="recaptcha"
                  ref="recaptcha"
                  v-if="!robot"
                  @verify="onVerify"
                  v-model="robot"
                  sitekey="6LflTnMUAAAAALVqcLb9HYoGwQ9NIOAP_EPhLZhL"
                  :loadRecaptchaScript="true"
                  @expired="onExpire"
                ></vue-recaptcha>
              </div>

              <div class="col-lg-6 col-sm-12">
                <div class="kt-align-right">
                  <b-button
                    variant="primary"
                    class="mr-2"
                    @click="SendSaveRequest('send')"
                    id="send_button"
                  >
                    {{ $i18n.t("REQUEST_DETAIL.SENDBUTTON") }}
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <!--End::Nova ziadost-->

        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
//import Vue from 'vue';
import KTPortlet from "@/views/partials/content/Portlet.vue";
import FormatService from "@/common/format.service";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import VueRecaptcha from "vue-recaptcha";

export default {
  mixins: [validationMixin],

  name: "reading-public",
  components: {
    KTPortlet,
    VueRecaptcha,
  },

  data() {
    return {
      rcapt_sig_key: "6LflTnMUAAAAALVqcLb9HYoGwQ9NIOAP_EPhLZhL",
      rcapt_id: 0,
      Filename: null,
      base64file: null,
      Type: null,
      errors: [],
      robot: false,
      usersetcode: null,
      menuId: null,
      description: null,
      descriptiontest: null,
      Code: null,
      photo: null,
      email: null,
      mobile: null,
      subject: null,
      files: [],
      meraceSaved: [],
      isEdited: false,
      // Odpocty meracov requestTypeId == 1
      showRequestType1: false,
      cisloMeracaId: null,
      meters: [],
      datumOdpoctu: null,
      meracStav: null,
      // Nahlasenie osobomesiacov requestTypeId == 3
      showRequestType3: false,
      rok:
        new Date().getMonth() > 5
          ? new Date().getFullYear()
          : new Date().getFullYear() - 1,
      januar: null,
      februar: null,
      marec: null,
      april: null,
      maj: null,
      jun: null,
      jul: null,
      august: null,
      september: null,
      oktober: null,
      november: null,
      december: null,
      merac: null,
      name: null,
      names: null,
      surname: null,
      city: null,
      street: null,
      labelName: this.$i18n.t("USERINFO.NAME"),
      labelSurname: this.$i18n.t("USERINFO.SURNAME") + "*",
      labelCity: this.$i18n.t("USERINFO.CITY") + "*",
      labelStreet: this.$i18n.t("USERINFO.STREET") + "*",
      labelEmail: this.$i18n.t("NEW_REQUEST.LABEL.EMAIL") + "*",
      // labelEmailDesc: this.$i18n.t("NEW_REQUEST.LABEL.EMAILDESC"),
      labelMobile: this.$i18n.t("NEW_REQUEST.LABEL.MOBILE") + "*",
      labelSubject: this.$i18n.t("NEW_REQUEST.LABEL.SUBJECT"),
      labelDescription: this.$i18n.t("NEW_REQUEST.LABEL.DESCRIPTION"),
      placeholderFile: this.$i18n.t("NEW_REQUEST.UPLOADPLACEHOLDER"),
      labelFile: this.$i18n.t("NEW_REQUEST.LABEL.FILE"),
      labelMeterNr: this.$i18n.t("NEW_REQUEST.LABEL.METERNR"),
      placeholderDate: this.$i18n.t("NEW_REQUEST.DATEPLACEHOLDER"),
      labelReadingDate: this.$i18n.t("NEW_REQUEST.LABEL.READINGDATE"),
      labelState: this.$i18n.t("NEW_REQUEST.LABEL.STATE"),
      labelYear: this.$i18n.t("NEW_REQUEST.LABEL.YEAR"),
      labelJanuary: this.$i18n.t("COMMON.MONTHS.MONTH_1") + "*",
      labelFebruary: this.$i18n.t("COMMON.MONTHS.MONTH_2") + "*",
      labelMarch: this.$i18n.t("COMMON.MONTHS.MONTH_3") + "*",
      labelApril: this.$i18n.t("COMMON.MONTHS.MONTH_4") + "*",
      labelMay: this.$i18n.t("COMMON.MONTHS.MONTH_5") + "*",
      labelJune: this.$i18n.t("COMMON.MONTHS.MONTH_6") + "*",
      labelJuly: this.$i18n.t("COMMON.MONTHS.MONTH_7") + "*",
      labelAugust: this.$i18n.t("COMMON.MONTHS.MONTH_8") + "*",
      labelSeptember: this.$i18n.t("COMMON.MONTHS.MONTH_9") + "*",
      labelOctober: this.$i18n.t("COMMON.MONTHS.MONTH_10") + "*",
      labelNovember: this.$i18n.t("COMMON.MONTHS.MONTH_11") + "*",
      labelDecember: this.$i18n.t("COMMON.MONTHS.MONTH_12") + "*",
      labelMeterOld: this.$i18n.t("NEW_REQUEST.OLDMETERS") + "*",
      labelTitleYesNo: this.$i18n.t("NEW_REQUEST.NOTICE"),
    };
  },
  validations: {
    merac: {
      required,
    },
    datumOdpoctu: {
      required,
    },
    meracStav: {
      required,
    },
    surname: {
      required,
    },
    street: {
      required,
    },
    city: {
      required,
    },
    email: {
      required,
      email,
    },
    mobile: {
      required,
      validatePhone(value) {
        /* povodne s pluskom
          let regex = /^\+[0-9]{10,}$/;
          if (/^\+421[0-9]{9}$/.test(value)) regex = /^\+4219[0-9]{8}$/;
          if (/^\+420[0-9]{9}$/.test(value)) regex = /^\+420[67][0-9]{8}$/;
          */
        let regex = /^[+][1-9]{1}[0-9]{10,}$/;
        if (/^\+421[0-9]{9}$/.test(value)) regex = /^\+4219[0-9]{8}$/;
        if (/^\+420[0-9]{9}$/.test(value)) regex = /^\+420[67][0-9]{8}$/;

        return regex.test(value);
      },
    },
  },
  mounted() {
    this.Code = this.$route.params.code;
    this.Type = this.$route.params.code;

    /*this.Type = this.Type.substring(this.Type.length - 1);

    if (this.Type != "x" ) this.$router.push({ name: "home" });*/

    this.Type = this.Type.substring(this.Type.length - 1);
    //this.Type = this.Code.substring(this.Code.length - 2, this.Code.length - 1);
    this.Code = this.Code.substring(0, 7);

    this.fetchData();
  },

  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");
      console.log(token);
      // Do stuff with the received token.
    },
    NoModal() {
      this.$refs["my-modal"].hide();
      this.$router.push({ name: "home" });
    },
    YesModal() {
      this.$refs["my-modal"].hide();
    },
    onVerify: function(response) {
      if (response) this.robot = true;
    },
    updateVal(value, por) {
      var i;
      //var s;
      for (i = por + 1; i <= 12; i++) {
        /*s = "input-osobomesiace-" + i.toString();            
            var x = document.getElementById(s.toString());            
            x.value = value;            */
        if (i == 1) this.januar = value;
        if (i == 2) this.februar = value;
        if (i == 3) this.marec = value;
        if (i == 4) this.april = value;
        if (i == 5) this.maj = value;
        if (i == 6) this.jun = value;
        if (i == 7) this.jul = value;
        if (i == 8) this.august = value;
        if (i == 9) this.september = value;
        if (i == 10) this.oktober = value;
        if (i == 11) this.november = value;
        if (i == 12) this.december = value;
      }
    },
    onExpire() {
      this.robot = false;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getFormatService() {
      return FormatService;
    },
    getBase64File(file) {
      this.filesSend = [];

      this.isEdited = true;

      //const file = ev.target.files[0];
      const reader = new FileReader();
      this.Filename = file.name;

      reader.readAsDataURL(file);
      reader.onload = (e) =>
        (this.base64file = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, ""));
    },
    SendSaveRequest(send) {
      UIService.addButtonSpinner(send + "_button");
      //this.meraceSaved.push({ "number" : this.merac,  "stav" : this.meracStav} );
      this.$v.$touch();
      if (this.$v.$anyError) {
        UIService.removeButtonSpinner(send + "_button");
        return;
      }
      this.errors = [];
      if (!this.robot) {
        this.errors.push(this.$i18n.t("NEW_REQUEST.ERROR.REQUIREDCAPTCHA"));
        UIService.removeButtonSpinner(send + "_button");

        return;
      }
      //odpocet

      if (
        this.merac == null ||
        this.datumOdpoctu == null ||
        this.meracStav == null
      ) {
        this.errors.push(this.$i18n.t("NEW_REQUEST.ERROR.REQUIRED"));
        UIService.removeButtonSpinner(send + "_button");
        return;
      }

      //osobomesiace
      if (this.Type == 1 && send == "send") {
        if (
          this.januar == null ||
          this.februar == null ||
          this.marec == null ||
          this.april == null ||
          this.maj == null ||
          this.jun == null ||
          this.jul == null ||
          this.august == null ||
          this.september == null ||
          this.oktober == null ||
          this.november == null ||
          this.december == null ||
          this.rok == null
        ) {
          this.errors.push(this.$i18n.t("NEW_REQUEST.ERROR.REQUIREDPERSON"));
          UIService.removeButtonSpinner(send + "_button");
          return;
        }
      }

      this.descriptiontest = this.name + " " + this.surname + "\n";
      this.descriptiontest =
        this.descriptiontest + this.street + " " + this.city + "\n";
      this.descriptiontest =
        this.descriptiontest + "email: " + this.email + "\n";
      this.descriptiontest =
        this.descriptiontest + "mobil: " + this.mobile + "\n";
      this.descriptiontest =
        this.descriptiontest + "poznámka:" + "\n" + this.description;

      let postContent = {
        Type: this.Type,
        Code: this.Code,

        Description: this.descriptiontest,
        Phone: encodeURIComponent(this.mobile),

        Email: this.email,

        FileBinary: this.base64file,
        FileName: this.Filename,

        meterCode: this.merac,

        nameNew: this.name,
        surnameNew: this.surname,
        streetNew: this.street,
        cityNew: this.city,

        MeterReadingNew: this.datumOdpoctu,
        StateNew: this.meracStav,

        January: this.januar,
        February: this.februar,
        March: this.marec,
        April: this.april,
        May: this.maj,
        June: this.jun,
        July: this.jul,
        August: this.august,
        September: this.september,
        October: this.oktober,
        November: this.november,
        December: this.december,
        Year: this.rok,
      };
      ApiService.post("Public/InsertTicket", postContent)
        .then(() => {
          UIService.removeButtonSpinner(send + "_button");
          this.errors = [];
          this.meraceSaved.push({ number: this.merac, stav: this.meracStav });
          this.merac = null;
          this.meracStav = null;
          this.Filename = null;
          this.base64file = null;
          this.files = [];

          this.$refs["my-modal"].show();
          /*const answer = window.confirm(this.$i18n.t("NEW_REQUEST.METERLEAVE"));
          if (answer) {
            this.$router.push({ name: "home" });
          }*/
        })
        .catch((response) => {
          this.errors.push(response.response.data.error);
          UIService.removeButtonSpinner(send + "_button");
        });
      this.isEdited = false;

      /*MeterCode
      Filename
      Filebinary*/
      /*ApiService.post("Request/Edit", postContent)
        .then(() => {
          UIService.removeButtonSpinner(send + "_button");
          this.back();
      })
        .catch(() => {
          UIService.removeButtonSpinner(send + "_button");
          this.back();
      });*/

      this.isEdited = false;
    },
    fetchData() {
      /*  let decString= atob(this.qrCode);
      const indexOfFirst = decString.indexOf("#");
      this.usersetcode = decString.substring(0, indexOfFirst);      */
    },
  },
};
</script>
