<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Prehlad upomienok-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Parameters -->
              <b-row class="mb-3">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div class="d-flex">
                    <label
                      style="flex: none; align-self: center; font-weight: 500"
                    >
                      {{ $t("PAYMENTREMINDEROVERVIEW.PARAMETER.DATETO") }}
                    </label>
                    <b-form-input
                      type="date"
                      v-model="parameterDate"
                      class="col-mb-3 mb-sm-0 ml-0 ml-sm-1 mr-0 mr-sm-3"
                    ></b-form-input>
                    <b-button
                      variant="primary mb-3 ml-3"
                      id="parameter_button"
                      @click="fetchData()"
                    >
                      {{ $i18n.t("PAYMENTREMINDEROVERVIEW.PARAMETER.BUTTON") }}
                    </b-button>
                  </div>
                  <b-col
                    class="d-flex flex-row align-items-center mb-3 md-3 mr-0 pr-0 !important col-lg-4 col-md-6 col-sm-12 col-md-6"
                  >
                    <label for="search" class="mr-1" style="font-weight: 500">
                      {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                    </label>
                    <b-form-input
                      v-model="filter"
                      @change="Search()"
                    ></b-form-input>
                  </b-col>
                </b-col>
              </b-row>
              <!--End::Parameters -->
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                responsive
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :filter="filter"
              >
                <template v-slot:head()="data">
                  <span v-b-tooltip.hover :title="data.field.tooltip">
                    {{ data.label }}
                  </span>
                </template>
                <template v-slot:cell(Status)="data">
                  <div class="align-self-center">
                    <i
                      v-if="data.value === 1"
                      class="fas fa-check color-success fa-lg"
                    ></i>
                    <i v-else class="fas fa-times color-danger fa-lg mr-1"></i>
                  </div>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="align-self-center">
                    <i
                      v-if="data.value.Status === 1"
                      class="fas fa-check color-success fa-lg"
                    ></i>
                    <i v-else class="fas fa-times color-danger fa-lg mr-1"></i>
                  </div>
                  <div class="ml-3 d-flex flex-column align-items-start w-100">
                    <span class="poschodoch-payment-reminder-title-mobile">
                      {{
                        data.value.IssueDate +
                          ", " +
                          data.value.PaymentReminderType
                      }}
                    </span>
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          {{
                            $i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.DATETO") +
                              ": " +
                              data.value.ArrearDate
                          }}
                        </span>
                        <span v-if="data.value.SMSDeliveryDate">
                          {{
                            $i18n.t(
                              "PAYMENTREMINDEROVERVIEW.TABLE.SMSDELIVERED",
                            ) +
                              ": " +
                              data.value.SMSDeliveryDate
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right"
                      >
                        <span
                          v-bind:class="{
                            'table-danger': data.value.Arrear < 0,
                          }"
                        >
                          {{ data.value.Arrear }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Prehlad upomienok-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "paymentreminderoverviewhouse",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      filter: null,
      fields: [
        {
          key: "Status",
          label: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.STATUS"),
          tooltip: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TOOLTIP.STATUS"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "PaymentReminderType",
          label: this.$i18n.t(
            "PAYMENTREMINDEROVERVIEW.TABLE.PAYMENTREMINDERTYPE",
          ),
          tooltip: this.$i18n.t(
            "PAYMENTREMINDEROVERVIEW.TOOLTIP.PAYMENTREMINDERTYPE",
          ),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "VS",
          label: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.VS"),
          tooltip: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TOOLTIP.VS"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Name",
          label: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.USER"),
          tooltip: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TOOLTIP.USER"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Arrear",
          label: this.addCurrencyToColumn(
            this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.ARREAR"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Arrear < 0) {
              item._cellVariants = { Arrear: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "PenaltyPayment",
          label: this.addCurrencyToColumn(
            this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.PENALTYPAYMENT"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "PostageCharge",
          label: this.addCurrencyToColumn(
            this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.POSTAGECHARGE"),
          ),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "IssueDate",
          label: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.ISSUEDATE"),
          tooltip: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TOOLTIP.ISSUEDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DueDate",
          label: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.DUEDATE"),
          tooltip: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TOOLTIP.DUEDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "ArrearDate",
          label: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.ARREARDATE"),
          tooltip: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TOOLTIP.ARREARDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "PaymentDate",
          label: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.PAYMENTDATE"),
          tooltip: this.$i18n.t("PAYMENTREMINDEROVERVIEW.TOOLTIP.PAYMENTDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
      parameterDate: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.parameterDate != null)
        parameters += "&dueDate=" + this.parameterDate.toString();
      window.open("/paymentReminderOverviewHousePrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.STATUS"),
        this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.PAYMENTREMINDERTYPE"),
        this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.VS"),
        this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.USER"),
        this.addCurrencyToColumn(
          this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.ARREAR"),
        ),
        this.addCurrencyToColumn(
          this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.PENALTYPAYMENT"),
        ),
        this.addCurrencyToColumn(
          this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.POSTAGECHARGE"),
        ),
        this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.ISSUEDATE"),
        this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.DUEDATE"),
        this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.ARREARDATE"),
        this.$i18n.t("PAYMENTREMINDEROVERVIEW.TABLE.PAYMENTDATE"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.Status,
          item.PaymentReminderType,
          item.VS,
          item.Name,
          FormatService.formatNumberToLocalForCSV(item.Arrear),
          FormatService.formatNumberToLocalForCSV(item.PenaltyPayment),
          FormatService.formatNumberToLocalForCSV(item.PostageCharge),
          FormatService.formatDateToLocal(item.IssueDate),
          FormatService.formatDateToLocal(item.DueDate),
          FormatService.formatDateToLocal(item.ArrearDate),
          FormatService.formatDateToLocal(item.PaymentDate),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.parameterDate != null)
        parameters += "&dueDate=" + this.parameterDate.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "PaymentReminderOverview" + parameters).then(
        ({ data }) => {
          this.transformData(data.PaymentReminderOverview);
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.PaymentReminderOverview;
          this.parameterDate = data.DueDate;
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          IssueDate: FormatService.formatDateToLocal(items[i].IssueDate),
          Arrear: items[i].Arrear,
          PaymentReminderType: items[i].PaymentReminderType,
          VS: items[i].VS,
          Name: items[i].Name,
          ArrearDate: FormatService.formatDateToLocal(items[i].ArrearDate),
          Status: items[i].Status,
          PaymentDate: FormatService.formatDateToLocal(items[i].PaymentDate),
        };
      }
    },
  },
};
</script>
