<template>
  <div>
    <a id="document-download-link" style="display: none"></a>

    <div class="row h-100">
      <div class="col-lg-12 col-xl-12 h-100">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <KTPortlet v-bind:title="title">
          <template v-slot:toolbar>
            <b-button variant="secondary" v-on:click="back()">
              {{ $i18n.t("CUSTOMERPAGE.TABLE.BACK") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="col-lg-9 col-sm-12"></div>
            <div class="row ml-1">
              <!-- <div class="col-lg-6 col-sm-12">                -->
              <b-form-group class="col-lg-12 col-sm-12" label-for="input-name">
                <b-form-input
                  class="col-lg-2"
                  type="color"
                  :value="customerColor"
                  v-model="customerColor"
                  style="width: 100px; height: 100px;"
                ></b-form-input>
                <b-button
                  variant="secondary"
                  class="btn-sm mr-2 mt-3"
                  @click="onReset()"
                  id="reset_button"
                >
                  {{ $i18n.t("CUSTOMERLIST.RESET") }}
                </b-button>
                <b-button
                  variant="secondary"
                  class="btn-sm mr-2 mt-3"
                  @click="onOriginal()"
                  id="original_button"
                >
                  {{ $i18n.t("CUSTOMERLIST.ORIGINAL") }}
                </b-button>
              </b-form-group>
              <b-form-group class="col-lg-1 col-sm-12"></b-form-group>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <b-form-group>
                <b-form-file
                  v-model="files"
                  :placeholder="placeholderFile"
                  @input="getBase64File"
                ></b-form-file>
              </b-form-group>
            </div>
            <div>
              <b-img
                center
                v-bind:src="'data:image/jpeg;base64,' + base64file"
                alt="Center image"
              ></b-img>
            </div>

            <div class="col-lg-12 col-sm-12">
              <div class="kt-align-right mt-3">
                <b-button
                  variant="primary"
                  class="mr-2"
                  @click="onUlozTo()"
                  id="send_button"
                >
                  {{ $i18n.t("CUSTOMERLIST.SENDBUTTON") }}
                </b-button>
              </div>
            </div>

            <!--Begin::Loader -->
            <b-container fluid v-if="!kolecko">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title2">{{ title2 }}</slot>
              </span>
            </h3>
            <b-button
              variant="primary"
              class="btn-sm"
              v-on:click="createNewEula"
            >
              <i class="fas fa-plus-circle mb-1"></i>
              <span>{{ $i18n.t("CUSTOMERLIST.NEWEULA") }}</span>
            </b-button>
          </template>
          <template v-slot:body>
            <!--Begin::Users -->
            <b-container fluid v-if="itemsUsers">
              <b-row class="mt-3">
                <b-col
                  class="poschodoch-rows-count"
                  v-if="itemsUsers.length > 10"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelectUsers"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPageUsers"
                      id="perPageSelectUsers"
                      size="sm"
                      :options="pageOptionsUsers"
                      @change="
                        getFormatService().formatSetPerPage(perPageUsers)
                      "
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                hover
                size="md"
                stacked="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="itemsUsers"
                :fields="fieldsUsers"
                :current-page="currentPageUsers"
                :per-page="perPageUsers"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="notranslate"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Link)="data">
                  <a
                    v-if="data"
                    href="javascript:void(0)"
                    v-on:click="tableRowClickHandler(data.item)"
                    class="kt-notification__item"
                  >
                    <i class="fas fa-user poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(EulaSK)="data">
                  <b-form-checkbox
                    v-model="data.item.EulaSK"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(EulaCZ)="data">
                  <b-form-checkbox
                    v-model="data.item.EulaCZ"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(EulaEN)="data">
                  <b-form-checkbox
                    v-model="data.item.EulaEN"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
              </b-table>
              <b-row
                v-if="itemsUsers && itemsUsers.length > 10"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPageUsers"
                    :total-rows="totalRowsUsers"
                    :per-page="perPageUsers"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-container>
            <!--End::Users -->

            <!--Begin::Description -->
            <!-- <b-container fluid v-if="itemsUsers || itemsObjects || itemsAdmins">
              <b-row class="justify-content-end">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container> -->
            <!--End::Description -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script src="/path/to/tinymce-vue.min.js"></script>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import Loader from "@/views/components/Loader.vue";
import Editor from "@tinymce/tinymce-vue";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
//import i18nService from "@/common/i18n.service.js";

export default {
  name: "domus-customer-admin-detail",
  components: {
    KTPortlet,
    Loader,
    editor: Editor,
  },
  data() {
    return {
      title: this.$i18n.t("CUSTOMERLIST.TITLEEDIT"),
      title2: this.$i18n.t("CUSTOMERLIST.TITLE2"),
      kolecko: 0,
      customerId: null,
      Filename: null,
      base64file: null,
      customerColor: null,
      errors: [],
      files: [],
      menuId: null,
      description: null,
      customerColorOriginal: null,
      placeholderFile: this.$i18n.t("NEW_REQUEST.UPLOADPLACEHOLDER"),
      itemsUsers: null,
      currentPageUsers: 1,
      perPageUsers: FormatService.formatGetPerPage(),
      pageOptionsUsers: FormatService.formatGetPerPageOption(),
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      fieldsUsers: [
        {
          key: "Prize",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.PRIZE"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "EulaSK",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.EULASK"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "EulaCZ",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.EULACZ"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "EulaEN",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.EULAEN"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "DateFrom",
          label: this.$i18n.t("CUSTOMERLIST.DATEFROM"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
      ],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.customerId = this.$route.query.customerId;
    this.fetchData();
  },
  methods: {
    tableRowClickHandler(record) {
      //<DeactivateUnit />

      this.showAdminEulaDetail(record.Id);
    },
    pointerRowClass() {
      return "pointer-row";
    },
    createNewEula() {
      this.$router.push({
        name: "domus-eula-admin-detail",
        query: {
          menuId: this.menuId,
          eulaId: null,
          customerId: this.customerId,
        },
      });
    },
    showAdminEulaDetail(eulaId) {
      this.$router.push({
        name: "domus-eula-admin-detail",
        query: {
          menuId: this.menuId,
          eulaId: eulaId,
          customerId: this.customerId,
        },
      });
    },
    onUlozTo() {
      this.EnabledFields = false;
      UIService.addButtonSpinner("send_button");

      let postContent = {
        Logo: this.base64file,
        Color: this.customerColor,
        LogoName: this.Filename,
        CustomerId: this.customerId,
      };

      ApiService.post("Auth/AdminCustomerDetailPost", postContent)
        .then(() => {
          UIService.removeButtonSpinner("send_button");
          this.$router.push({
            name: "domus-customer_list_admin",
            query: { menuId: this.menuId },
          });
        })
        .catch((response) => {
          this.errors.push(response.response.data.error);
          UIService.removeButtonSpinner("send_button");
        });
    },
    onOriginal() {
      this.customerColor = this.customerColorOriginal;
    },
    onReset() {
      this.customerColor = "#EC7A08";
    },
    getBase64File(file) {
      this.filesSend = [];

      this.isEdited = true;

      //const file = ev.target.files[0];
      const reader = new FileReader();
      this.Filename = file.name;

      reader.readAsDataURL(file);
      reader.onload = (e) =>
        (this.base64file = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, ""));
    },
    back() {
      this.$router.push({
        name: "domus-customer_list_admin",
        query: { menuId: this.menuId },
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].EulaSK = items[i].EulaSK == 1 ? true : false;
        items[i].EulaCZ = items[i].EulaCZ == 1 ? true : false;
        items[i].EulaEN = items[i].EulaEN == 1 ? true : false;

        items[i].Mobile = {
          Prize: items[i].Prize,
          EulaSK: items[i].EulaSK,
          EulaCZ: items[i].EulaCZ,
          EulaEN: items[i].EulaEN,
          DateFrom: items[i].DateFrom,
        };
      }
    },
    fetchData() {
      this.items = null;
      let parameters =
        /*"?menuId=" +
        this.menuId.toString() +*/
        "?customerId=" + this.customerId.toString();

      ApiService.setHeader();
      ApiService.get("Auth", "AdminCustomerDetail" + parameters).then(
        ({ data }) => {
          this.files = [];
          this.base64file = data.LogoContent;
          this.customerColor = data.BackgroundColor;
          this.customerColorOriginal = data.BackgroundColor;
          this.kolecko = 1;
          this.title =
            this.title +
            " " +
            data.CompanyCode +
            " (" +
            data.PropertyCompany +
            ")";

          this.transformData(data.EULA);
          this.itemsUsers = data.EULA;
        },
      );
    },
  },
};
</script>
