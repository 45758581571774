/**
 * Service to work with date, number formats
 */
const PerPage = "PerPage";

import i18n from "./plugins/vue-i18n";
import store from "@/store";
import objectPath from "object-path";

const FormatService = {
  config: store.getters.layoutConfig(),

  formatDateTimeToLocal(value) {
    if (value == null || value == "") return "";

    let date = new Date(value);
    return date.toLocaleString();
  },

  formatDateToLocal(value) {
    if (value == null || value == "") return "";

    let date = new Date(value);
    return date.toLocaleDateString();
  },

  formatDateForInput(value) {
    if (value == null || value == "") return "";

    let date = new Date(value);

    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();

    if (dd < 10) dd = "0" + dd;

    if (mm < 10) mm = "0" + mm;

    return yyyy + "-" + mm + "-" + dd;
  },

  formatDateToDDMM(value) {
    if (value == null || value == "") return "";

    let date = new Date(value);

    var dd = date.getDate();
    var mm = date.getMonth() + 1;

    if (dd < 10) dd = "0" + dd;

    if (mm < 10) mm = "0" + mm;

    return dd + "." + mm + ".";
  },

  formatDateToDDMMYYYY(value) {
    if (value == null || value == "") return "";

    let date = new Date(value);

    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();

    if (dd < 10) dd = "0" + dd;

    if (mm < 10) mm = "0" + mm;

    return dd + "." + mm + "." + yyyy;
  },

  formatNumberToLocal(value) {
    if (value == null || value == "") return "";

    let number = Number(value);

    return number.toLocaleString("sk-SK", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },

  formatNumberToLocalNoFractionDigits(value) {
    if (value == null || value == "") return "";

    let number = Number(value);

    return number.toLocaleString("sk-SK", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  },

  formatNumberToLocalForCSV(value) {
    return this.formatNumberToLocal(value)
      .toString()
      .replace(/\s+/g, "");
  },

  formatCurrency(value) {
    if (value == null) return "";

    let valueUpper = value.toUpperCase();
    if (valueUpper == "EUR") return "€";

    return value;
  },

  getMonthName(index) {
    if (index) return i18n.t("COMMON.MONTHS.MONTH_" + index);
  },

  normalizeString(value) {
    if (value == null) return "";

    let normalized = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return normalized;
  },
  formatGetPerPage() {
    let getPage = window.localStorage.getItem(PerPage);
    return getPage;
    //return 15;
  },
  formatSetPerPage(setPage) {
    window.localStorage.setItem(PerPage, setPage);
  },
  formatGetPerPageMin() {
    return 10;
  },
  formatGetPerPageOption() {
    return [2, 10, 15, 20, 30, 100];
  },
  getRebrandColorGraf(rColor) {
    let retColor = null;
    /*povodny mounted
    this.colorhot = (window.location.host.includes("enbra")) ? this.layoutConfig("colors.graph.color-hot") : this.layoutConfig("colors.state.danger");
    this.colorcold = (window.location.host.includes("enbra")) ? this.layoutConfig("colors.graph.color-cold") : this.layoutConfig("colors.state.info");
    this.colorheat = (window.location.host.includes("enbra")) ? this.layoutConfig("colors.graph.color-heat") : this.layoutConfig("colors.state.brand");
    this.colortooltip = (window.location.host.includes("enbra")) ? this.layoutConfig("colors.graph.color-hot") : this.layoutConfig("colors.state.brand");

    if (rColor == "hot") retColor = this.layoutConfig("colors.state.danger");
    if (rColor == "cold") retColor = this.layoutConfig("colors.state.info");
    if (rColor == "heat") retColor = this.layoutConfig("colors.state.brand");
    if (rColor == "tooltip") retColor = this.layoutConfig("colors.state.brand");*/

    if (rColor == "hot")
      retColor = objectPath.get(this.config, "colors.state.danger");
    if (rColor == "cold")
      retColor = objectPath.get(this.config, "colors.state.info");
    if (rColor == "heat")
      retColor = objectPath.get(this.config, "colors.state.brand");
    if (rColor == "tooltip")
      retColor = objectPath.get(this.config, "colors.state.brand");

    if (window.location.host.includes("enbra")) {
      if (rColor == "hot")
        retColor = objectPath.get(this.config, "colors.graph.color-hot");
      if (rColor == "cold")
        retColor = objectPath.get(this.config, "colors.graph.color-cold");
      if (rColor == "heat")
        retColor = objectPath.get(this.config, "colors.graph.color-heat");
      if (rColor == "tooltip")
        retColor = objectPath.get(this.config, "colors.graph.color-hot");
    }
    if (window.location.host.includes("penta")) {
      if (rColor == "hot")
        retColor = objectPath.get(this.config, "colors.graph.color-hot-penta");
      if (rColor == "cold")
        retColor = objectPath.get(this.config, "colors.graph.color-cold-penta");
      if (rColor == "heat")
        retColor = objectPath.get(this.config, "colors.graph.color-heat-penta");
      if (rColor == "tooltip")
        retColor = objectPath.get(this.config, "colors.graph.color-heat-penta");
    }
    return retColor;
  },
};

export default FormatService;
