/**
 * Service to work with files
 */
export const FILE_DOWNLOAD_LINK_ID = "file-download-link";

const FileService = {
  BOM: "\ufeff",
  EOL: "\r\n",

  convertBase64ToByteArray(content) {
    let decodedFileContent = window.atob(content);
    let decodedFileContentLength = decodedFileContent.length;

    var bytes = new Uint8Array(decodedFileContentLength);
    for (var i = 0; i < decodedFileContentLength; i++) {
      bytes[i] = decodedFileContent.charCodeAt(i);
    }

    return bytes.buffer;
  },

  generateCSV(header, rows) {
    let result = {
      content: this.BOM + header.join(";") + this.EOL,
      contentType: "text/csv",
    };

    rows.forEach(function(rowArray) {
      let row = rowArray.join(";");
      result.content += row + FileService.EOL;
    });

    return result;
  },
  generateChartCSVwithDate(header, labels, values) {
    let rows = [];

    labels.forEach(function(column) {
      let rowArray = [];
      rowArray.push(column);

      header.forEach(function(label) {
        if (label) {
          let value = values.find(function(v) {
            return v[0] == label && v[3] == column;
          });
          rowArray.push(value ? value[2] || "" : "");
        }
      });

      rows.push(rowArray);
    });

    return FileService.generateCSV(header, rows);
  },

  generateChartCSV(header, labels, values) {
    let rows = [];
    labels.forEach(function(column) {
      let rowArray = [];
      rowArray.push(column);
      header.forEach(function(label) {
        if (label) {
          let value = values.find(function(v) {
            return v[0] == label && v[1] == column;
          });
          rowArray.push(value ? value[2] || "" : "");
        }
      });

      rows.push(rowArray);
    });

    return FileService.generateCSV(header, rows);
  },

  downloadFile(fileName, content, contentType, downloadButtonId) {
    downloadButtonId = downloadButtonId || FILE_DOWNLOAD_LINK_ID;

    let fileURL = window.URL.createObjectURL(
      new Blob([content], { type: contentType }),
    );

    let fileLink = document.getElementById(downloadButtonId);
    fileLink.href = fileURL;
    fileLink.setAttribute("download", fileName);
    fileLink.click();
  },

  openFile(fileName, content, contentType) {
    let blob = new Blob([content], { type: contentType });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // MS browsers solution (edge, IE)
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else if (
      window.navigator &&
      window.navigator.userAgent.includes("Firefox")
    ) {
      // FF browser solution (same as download)
      let fileURL = window.URL.createObjectURL(blob);

      let fileLink = document.getElementById("pdf-download-link");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName);
      fileLink.click();
    } else {
      // Chrome,Opera browsers solution (default)
      window.open(URL.createObjectURL(blob), "_blank");
    }
  },
};

export default FileService;
