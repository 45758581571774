<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Bids-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu :functionCSV="downloadCSV"></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <!--Begin::Data -->
          <template v-slot:body>
            <!--Begin::Filter and paging -->
            <b-row class="mb-4 align-items-end">
              <b-col>
                <b-form-checkbox
                  class="col-6 mb-3"
                  id="disassembled"
                  size="md"
                  v-on:input="showHideDates()"
                  checked="true"
                  v-model="checkedDate"
                >
                  {{ $i18n.t("USERLISTOFFICIALS.CHECKUZI") }}
                </b-form-checkbox>
              </b-col>

              <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                <label for="search" class="mr-1" style="font-weight: 500">
                  {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                </label>
                <b-form-input v-model="filter"></b-form-input>
              </b-col>
            </b-row>
            <!--End::Filter and paging -->
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
                :filter="filter"
              >
                <!--:filter-function="filterTable" Nastavim si tooltip na header -->
                <template v-slot:head()="data">
                  <span v-b-tooltip.hover :title="data.field.tooltip">
                    {{ data.label }}
                  </span>
                </template>
                <!-- Nastavim si gulicku a tooltip na riadku -->
                <template v-slot:cell(PortalAccess)="data">
                  <i
                    v-b-tooltip.hover
                    :title="
                      `${
                        data.value === 1
                          ? $t('USERLISTOFFICIALS.PORTALACCESSTRUE')
                          : $t('USERLISTOFFICIALS.PORTALACCESSFALSE')
                      }`
                    "
                    :class="
                      `fa fa-circle ${
                        data.value === 1 ? 'text-success' : 'text-secondary'
                      }`
                    "
                  ></i>
                </template>
                <template v-slot:cell(Category)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="
                      showUserListOfficialsDetail(menuId, data.item.UserId)
                    "
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                  <span v-if="!(data.item != null)">{{ data.value }}</span>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.item != null"
                            v-on:click="
                              showUserListOfficialsDetail(
                                menuId,
                                data.item.UserId,
                              )
                            "
                            class="kt-link"
                          >
                            {{ data.item.Name }}
                          </a>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span>
                          {{ data.value.DtOdst }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-row align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span></span>
                        <span class="ml-2">
                          {{ data.item.Street + ", " }}
                        </span>
                        <span class="ml-3">
                          {{ data.item.City + ", " }}
                        </span>
                        <span class="ml-2">
                          {{ data.item.PSC }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Bids-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import FileService from "@/common/file.service";

export default {
  name: "domus-user-list-officials",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      InsurenceEventsDetail: null,
      criteria: new Date().toISOString().slice(0, 10),
      items: null,
      checkedDate: true,
      filter: null,
      fields: [
        {
          key: "PortalAccess",
          label: "P",
          tooltip: this.$i18n.t("USERLISTOFFICIALS.PORTALACCESSTOOLTIP"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Name",
          label: this.$i18n.t("USERLISTOFFICIALS.NAME"),
          sortable: true,
          sortDirection: "asc",
          class: "table-no-mobile",
        },
        {
          key: "UnitNumber",
          label: this.$i18n.t("USERLISTOFFICIALS.UNITNUMBER"),
          sortable: true,
          sortDirection: "asc",
          class: "text-center  table-no-mobile",
        },
        {
          key: "UnitStock",
          label: this.$i18n.t("USERLISTOFFICIALS.UNITSTOCK"),
          sortable: true,
          sortDirection: "asc",
          class: "text-center  table-no-mobile",
        },
        {
          key: "UserType",
          label: this.$i18n.t("USERLISTOFFICIALS.USERTYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-center  table-no-mobile",
        },
        {
          key: "UnitDescriptionSK",
          label: this.$i18n.t("USERLISTOFFICIALS.UNITDESCRIPTIONSK"),
          sortable: true,
          sortDirection: "desc",
          class: "text-center  table-no-mobile",
        },
        {
          key: "ExtUnitNumber",
          label: this.$i18n.t("USERLISTOFFICIALS.EXTUNITNUMBER"),
          sortable: true,
          sortDirection: "desc",
          class: "text-center  table-no-mobile",
        },
        {
          key: "DtPrist",
          label: this.$i18n.t("USERLISTOFFICIALS.DTPRIST"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DtOdst",
          label: this.$i18n.t("USERLISTOFFICIALS.DTODST"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "PocetOsobPredpis",
          label: this.$i18n.t("USERLISTOFFICIALS.PERSONS"),
          sortable: true,
          sortDirection: "asc",
          class: "text-center  table-no-mobile",
        },
        {
          key: "Street",
          label: this.$i18n.t("USERLISTOFFICIALS.STREET"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "PSC",
          label: this.$i18n.t("USERLISTOFFICIALS.PSC"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "City",
          label: this.$i18n.t("USERLISTOFFICIALS.CITY"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Mobile",
          label: "",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  computed: {
    /*filter() {
      return this.criteria;
    },*/
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showUserListOfficialsDetail(this.menuId, record.UserId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    showUserListOfficialsDetail(menuId, UserId) {
      this.$router.push({
        name: "domus-user-list-officials-detail",
        query: { menuId: menuId, userId: UserId },
      });
    },
    filterTable(row, filter) {
      if (filter && row.DtPrist && row.DtOdst) {
        var date1 = new Date(row.DtPrist);
        var date2 = new Date(filter);
        var date3 = new Date(row.DtOdst);
        if (date1 <= date2 && date2 <= date3) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    showHideDates() {
      /*if (this.checkedDate){
        this.criteria = null;
      } else {
        this.criteria = new Date().toISOString().slice(0, 10);        
      };*/
      this.fetchData();
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("USERLISTOFFICIALS.PORTALACCESSTOOLTIP"),
        this.$i18n.t("USERLISTOFFICIALS.NAME"),
        this.$i18n.t("USERLISTOFFICIALS.UNITNUMBER"),
        this.$i18n.t("USERLISTOFFICIALS.UNITSTOCK"),
        this.$i18n.t("USERLISTOFFICIALS.USERTYPE"),
        this.$i18n.t("USERLISTOFFICIALS.UNITDESCRIPTIONSK"),
        this.$i18n.t("USERLISTOFFICIALS.EXTUNITNUMBER"),
        this.$i18n.t("USERLISTOFFICIALS.DTPRIST"),
        this.$i18n.t("USERLISTOFFICIALS.DTODST"),
        this.$i18n.t("USERLISTOFFICIALS.PERSONS"),
        this.$i18n.t("USERLISTOFFICIALS.STREET"),
        this.$i18n.t("USERLISTOFFICIALS.PSC"),
        this.$i18n.t("USERLISTOFFICIALS.CITY"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.PortalAccess,
          item.Name,
          item.UnitNumber,
          item.UnitStock,
          item.UserType,
          item.UnitDescriptionSK,
          item.ExtUnitNumber,
          FormatService.formatDateToLocal(item.DtPrist),
          FormatService.formatDateToLocal(item.DtOdst),
          item.PocetOsobPredpis,
          item.Street,
          item.PSC,
          item.City,
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let check = this.checkedDate ? 1 : 0;
      let parameters = "?menuId=" + this.menuId.toString();
      parameters += "&current=" + check.toString();

      ApiService.setHeader();
      ApiService.get("Object", "UserListOfficials" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.UserListOfficials;

          if (data.UserListOfficials)
            this.transformData(data.UserListOfficials);
        },
      );
    },

    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].DtOdstLocale = FormatService.formatDateToLocal(
          items[i].DtOdst,
        );
        items[i].DtPristLocale = FormatService.formatDateToLocal(
          items[i].DtPrist,
        );
        items[i].Mobile = {
          UserId: items[i].UserId,
          Name: items[i].Name,
          PocetOsobPredpis: items[i].PocetOsobPredpis,
          Street: items[i].Street,
          PSC: items[i].PSC,
          City: items[i].City,
          UnitNumber: items[i].UnitNumber,
          ExtUnitNumber: items[i].ExtUnitNumber,
          DtOdst:
            items[i].DtOdst === null
              ? null
              : this.$i18n.t("USERLISTOFFICIALS.DATEO") +
                FormatService.formatDateToLocal(items[i].DtOdst),
          PortalAccess: items[i].PortalAccess,
        };
      }
    },
  },
};
</script>
