<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <b-modal
      id="modal-documents-upload"
      hide-footer
      size="lg"
      class="poschodoch-modal-documents-upload"
      @close="onClose"
      no-close-on-esc
      no-close-on-backdrop
      static
    >
      <div class="d-block text-center">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-3"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
          <div
            role="alert"
            v-if="successText && successText.length"
            v-bind:class="{ show: successText && successText.length }"
            class="alert fade alert-success mt-2 mb-3"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in successText" :key="i">
              {{ error }}
            </div>
          </div>
        </template>
        <b-form-group v-if="categories && categories.length">
          <label>{{ $t("DOCUMENTS.FILTER.CATEGORY") }}</label>
          <b-form-select
            required
            autofocus
            v-model="selectedCategory"
            :options="categoryOptions"
            :placeholder="placeholderCategory"
          />
        </b-form-group>
        <b-form-group>
          <b-form-file
            :file-name-formatter="formatNames"
            drop-placeholder="Drag and drop here"
            browse-text="Upload files..."
            multiple
            v-model="files"
            :placeholder="placeholderFile"
            @input="getBase64File"
          ></b-form-file>
        </b-form-group>
      </div>
      <template v-slot:modal-title>
        <span class="poschodoch-modal-title">
          {{ $i18n.t("DOCUMENTS.UPLOAD.UPLOAD_NEW_FILES") }}
        </span>
        <span
          class="poschodoch-note-list"
          v-b-tooltip.hover
          :title="$t('DOCUMENTS.UPLOAD.HINT')"
        >
          <span class="poschodoch-note ml-2"></span>
        </span>
      </template>
      <div class="d-block text-center">
        <b-table
          :items="uploadedFilesList"
          :fields="fields"
          v-if="uploadedFilesList.length > 0"
        >
          <template #cell(name2)="data">
            <b-form-input
              class="col-9"
              type="text"
              v-model="data.item.name2"
            ></b-form-input>
          </template>
          <template #cell(extension)="data">
            <span>{{ data.value }}</span>
          </template>
          <template #cell(status)="data">
            <span>{{ data.value }}</span>
          </template>
          <template #cell(actions)="data">
            <a
              href="javascript:void(0)"
              @click="deleteFile(data.item)"
              class="p-2"
              v-show="data.item.status !== $t('DOCUMENTS.UPLOAD.OK')"
            >
              <i
                class="flaticon2-delete color-danger poschodoch-icon-medium"
              ></i>
            </a>
          </template>
        </b-table>
        <b-button
          id="upload_button"
          class="btn"
          v-if="showUploadButton"
          variant="primary"
          @click="onSubmit('upload')"
        >
          {{ $i18n.t("DOCUMENTS.UPLOAD.UPLOAD_TEXT") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import MenuService from "@/common/menu.service";

export default {
  name: "DocumentsUploadModal",
  props: {
    filterCategory: {
      type: String,
      required: false,
    },
    categories: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      menuId: null,
      errors: [],
      successText: [],
      files: [],
      uploadedFilesList: [],
      filesToUpload: [],
      showUploadButton: false,
      placeholderCategory: this.$i18n.t(
        "DOCUMENTS.UPLOAD.CATEGORY_PLACEHOLDER",
      ),
      placeholderFile: this.$i18n.t("NEW_REQUEST.UPLOADPLACEHOLDER"),
      fields: [
        {
          key: "name2",
          label: this.$i18n.t("DOCUMENTS.UPLOAD.DOC_NAME"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "extension",
          label: this.$i18n.t("DOCUMENTS.UPLOAD.EXTENSION"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "status",
          label: this.$i18n.t("DOCUMENTS.UPLOAD.STATUS"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "actions",
          label: "Akcie",
          sortable: false,
          class: "text-left",
        },
      ],
      selectedCategory: this.filterCategory,
      categoryOptions: [],
    };
  },
  computed: {},
  mounted() {
    this.menuId = this.$route.query.menuId;
  },
  methods: {
    onClose(event) {
      let notUploadedFilesList = this.uploadedFilesList.filter(
        (x) => x.status === this.$i18n.t("DOCUMENTS.UPLOAD.READY"),
      );
      if (notUploadedFilesList.length > 0) {
        const userConfirmed = window.confirm(
          this.$i18n.t("DOCUMENTS.UPLOAD.NOTUPLOADEDFILESALERT"),
        );
        if (!userConfirmed) {
          event.preventDefault();
          return;
        }
      }
      if (this.uploadedFilesList.length > 0) window.location.reload();
    },
    formatNames() {
      let placeholderBformFile = this.uploadedFilesList.filter(
        (file) => file.status !== this.$i18n.t("DOCUMENTS.UPLOAD.OK"),
      );
      return placeholderBformFile.length === 1
        ? placeholderBformFile[0].name
        : `${placeholderBformFile.length} ${this.$i18n.t(
            "DOCUMENTS.UPLOAD.READYTOBEUPLOADED",
          )}`;
    },
    getBase64File(files) {
      // Clear previous errors
      this.errors = [];
      // Check if a category is selected
      if (!this.selectedCategory) {
        this.errors.push(this.$i18n.t("DOCUMENTS.UPLOAD.CATEGORY_PLACEHOLDER"));
        return;
      }
      files.forEach((file) => {
        if (file.size > 15728640) {
          alert(
            `${this.$i18n.t("DOCUMENTS.UPLOAD.TOOBIGFILE")} - ${file.name}`,
          );
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.uploadedFilesList.push({
              name: file.name,
              name2: file.name.replace(/\.[^/.]+$/, ""),
              extension: file.name.split(".").pop(),
              content: e.target.result.replace("data:", "").replace(/^.+,/, ""),
              size: file.size,
              status: this.$i18n.t("DOCUMENTS.UPLOAD.READY"),
              category: this.selectedCategory,
            });
          };
        }
      });
      if (this.files.length > 0) this.showUploadButton = true;
    },
    deleteFile(file) {
      this.uploadedFilesList = this.uploadedFilesList.filter(
        (f) =>
          f.name !== file.name &&
          f.status !== this.$i18n.t("DOCUMENTS.UPLOAD.OK"),
      );
      if (this.uploadedFilesList.length === 0) {
        this.showUploadButton = false;
      }
    },
    onSubmit(buttonID) {
      // Clear previous errors
      this.errors = [];
      this.successText = [];
      this.filesToUpload = [];
      this.uploadedFilesList = this.uploadedFilesList.filter(
        (file) => file.status !== this.$i18n.t("DOCUMENTS.UPLOAD.OK"),
      );
      UIService.addButtonSpinner(buttonID + "_button");
      for (let i = 0; i < this.uploadedFilesList.length; i++) {
        this.filesToUpload.push({
          FileId: this.menuId,
          Filename: this.uploadedFilesList[i].name,
          Docname: this.uploadedFilesList[i].name2,
          Filebinary: this.uploadedFilesList[i].content,
          Size: this.uploadedFilesList[i].size / 1024,
          Category: this.uploadedFilesList[i].category,
        });
      }
      let postContent = {
        Files: this.filesToUpload,
        MenuCode: MenuService.getMenuCodeFromID(this.menuId),
      };
      ApiService.post("Document/UploadDWP", postContent)
        .then((response) => {
          this.uploadedFilesList.forEach((subor) => {
            subor.status = this.$i18n.t("DOCUMENTS.UPLOAD.OK");
          });
          const successMessage =
            this.$i18n.t("DOCUMENTS.UPLOAD.UPLOADEDFILESCOUNT") +
            response.data.PocetVlozenychPriloh;
          this.successText.push(successMessage);
          UIService.removeButtonSpinner(buttonID + "_button");
          this.showUploadButton = false;
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.error
            ? error.response.data.error
            : this.$i18n.t("AUTHJS.SOMETHINGWRONG") + error;
          this.errors.push(errorMessage);
          UIService.removeButtonSpinner(buttonID + "_button");
        })
        .finally(() => {
          this.files = [];
          setTimeout(() => {
            this.errors = [];
            this.successText = [];
          }, 3000);
        });
    },
    setCategoryOptions(categories) {
      this.categoryOptions = [];
      if (categories) {
        // Map categories to an array of objects with value and text properties
        const newOptions = categories.map((category) => ({
          value: category.value,
          text: category.text,
        }));
        // Concatenate the new options to the existing categoryOptions
        this.categoryOptions = this.categoryOptions.concat(newOptions);
      }
    },
    setSelectedCategory(category) {
      this.selectedCategory = category;
    },
  },
};
</script>
