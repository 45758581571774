<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-bind:title="null">
          <template v-slot:title>
            <span class="kt-portlet__head-title">
              {{ title }} {{ meterType }} {{ meterNumber }}
            </span>
          </template>

          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
            <b-button variant="secondary" v-on:click="back()">
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <!--End::Context menu-->

          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row>
                <b-col class="col-4 pt-2 pt-lg-2 pt-sm-4 mb-3">
                  <!--Begin::Checkbox -->
                  <b-form-checkbox
                    id="dailymeters"
                    v-model="checkeddailymeters"
                    v-on:input="fetchData()"
                  >
                    {{ $i18n.t("METERREADINGS.CHECKBOXDAILY") }}
                  </b-form-checkbox>
                  <!--End::Checkbox -->
                </b-col>
                <!--Begin::Count of rows per page -->
                <b-col
                  v-if="items && items.length > 0"
                  class="poschodoch-rows-count mb-2"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-cols-xl="11"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                      @change="getFormatService().formatSetPerPage(perPage)"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!--End::Count of rows per page -->
              </b-row>

              <b-table
                v-if="items"
                show-empty
                hover
                :items="items"
                :fields="fields"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :current-page="currentPage"
                :per-page="perPage"
              >
                <template v-slot:cell(Link)="data">
                  <div>
                    <a
                      href="javascript:void(0)"
                      v-if="data.item.Link != null"
                      @click="PhotoLink(data.item.Link)"
                      class="kt-link"
                    >
                      <i class="poschodoch-icon-open"></i>
                    </a>
                  </div>
                </template>
              </b-table>

              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="items.length"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";
import GridService from "@/common/grid.service";

export default {
  name: "meterReading-detail",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      meterId: null,
      checkeddailymeters: false,
      fields: [
        {
          key: "MeterNumber",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.METERNUMBER"),
          sortable: true,
          class: "text-left table-no-mobile",
        },
        {
          key: "MeterType",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.METERTYPE"),
          sortable: true,
          class: "text-right table-no-mobile",
        },
        {
          key: "ClimbingIron",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.CLIMBINGIRON"),
          sortable: true,
          class: "text-right table-no-mobile",
        },
        {
          key: "ReadingDate",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.READINGDATE"),
          sortable: true,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "State",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.STATE"),
          sortable: true,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "ReadingCode",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.READINGCODE"),
          sortable: true,
          class: "text-right",
        },
        {
          key: "Description",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.DESCRIPTION"),
          sortable: true,
          class: "text-right table-no-mobile",
        },
        {
          key: "Link",
          label: this.$i18n.t("METERREADING_DETAIL.TABLE.LINK"),
          sortable: true,
          class: "text-right",
        },
      ],
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
      meterNumber: null,
      meterType: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.meterId = this.$route.query.meterId;
    let bContent = GridService.getGridFilter("invoices");
    if (bContent) {
      this.currentPage = bContent.currentPage;
    }
    this.fetchData();
  },
  methods: {
    back() {
      this.$router.push({
        name: "domus-meter-readings",
        query: { menuId: this.menuId },
      });
    },
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    PhotoLink(link) {
      //window.location = link;
      window.open(link, "_blank", "noreferrer");
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("METERREADING_DETAIL.TABLE.METERNUMBER"),
        this.$i18n.t("METERREADING_DETAIL.TABLE.METERTYPE"),
        this.$i18n.t("METERREADING_DETAIL.TABLE.CLIMBINGIRON"),
        this.$i18n.t("METERREADING_DETAIL.TABLE.READINGDATE"),
        this.$i18n.t("METERREADING_DETAIL.TABLE.STATE"),
        this.$i18n.t("METERREADING_DETAIL.TABLE.READINGCODE"),
        this.$i18n.t("METERREADING_DETAIL.TABLE.DESCRIPTION"),
        this.$i18n.t("METERREADING_DETAIL.TABLE.LINK"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.MeterNumber,
          item.MeterType,
          item.ClimbingIron,
          FormatService.formatDateToLocal(item.ReadingDate),
          FormatService.formatNumberToLocalForCSV(item.State),
          item.ReadingCode,
          item.Description,
          item.Link,
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + `_${this.meterNumber}_${this.meterType}.csv`;

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.meterId != null)
        parameters += "&meterId=" + this.meterId.toString();

      window.open("/meterReadingDetailPrint" + parameters);
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?menuId=" +
        this.menuId.toString() +
        "&meterId=" +
        this.meterId.toString();
      if (this.checkeddailymeters) {
        parameters += "&daily=1";
      }

      ApiService.setHeader();
      ApiService.get("Flat", "MeterReadingDetail" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.MeterReadingDetail;
          this.currentPage = 1;
          this.totalRows = this.items.length;

          this.meterNumber = data.MeterNumber;
          this.meterType = data.MeterType;
        },
      );
    },
  },
};
</script>
