// Czech
export const locale = {
  MENU: {
    NEW: "nové",
    UNIT_LIST: "Moje prostory",
  },
  LAYOUT: {
    FOOTER: {
      LEGAL: "Všeobecné podmínky portálu",
      PRIVACY: "Pravidla ochrany osobních údajů",
      LEGAL_A: "Podmínky provozovatele",
      LEGAL_Z: "Podmínky poskytovatele",
      DATETO: "Údaje aktuální k:",
    },
    FOOTER_LOGIN: {
      LEFT_1: "ANASOFT APR, spol. s.r.o.",
      LEFT_2: "Mlynská dolina 41, 811 02 Bratislava, Slovenská republika",
      LEFT_3: "tel: +421 2 3223 4484",
      RIGHT_1: "ISO 9001, 14001, 27001, 20000-1",
      RIGHT_2: "IČ: 313 615 52; IČ DPH: SK2020345778",
      RIGHT_3:
        "Společnost zapsána v obchodním rejstříku Městským soudu Bratislava III, Oddíl Sro, vl. č. 6042/B",
    },
    FOOTER_LOGIN_CZ: {
      LEFT_1: "ANASOFT s.r.o.",
      LEFT_2: "Hrnčířská 2985, 470 01 Česká Lípa, Česká republika",
      LEFT_3: "tel: +420 481 130 100",
      RIGHT_1: "IČ: 499 04 949; DIČ: CZ49904949",
      RIGHT_2:
        "Společnost zapsána v obch. rejstříku vedeném Krajským soudem v Ústí nad Labem, odd. C, vl. 25247",
      RIGHT_3: "",
    },
    FOOTER_LOGIN_ENBRA: {
      LEFT_1: "ENBRA SLOVAKIA s.r.o.",
      LEFT_2: "Zvolenská cesta 29, 97401 Banská Bystrica, Slovenská republika",
      LEFT_3: "tel: +421 2 434 141 46",
      RIGHT_1: "IČ: 31624189; IČ DPH: SK2020456119",
      RIGHT_2:
        "Společnost zapsána v obchodním rejstříku Okr. soudu Banská Bystrica, Oddíl Sro, vl. č. 2587/S",
      RIGHT_3: "",
    },
    FOOTER_LOGIN_ENBRA_CZ: {
      LEFT_1: "ENBRA, a.s.",
      LEFT_2: "Durďákova 5, 613 00 Brno, Česká republika",
      LEFT_3: "tel: +420 533 03 99 03",
      RIGHT_1: "IČ: 44015844; DIČ: CZ44015844",
      RIGHT_2:
        "Společnost zapsána v obch. rejstříku vedeném Krajským soudem v Brně, odd. B, vl. 6085",
      RIGHT_3: "",
    },
    FOOTER_LOGIN_PENTA: {
      LEFT_1: "Penta Real Estate Residential Services s.r.o.",
      LEFT_2:
        "Digital Park II, Einsteinova 25, 85101 Bratislava, Slovenská republika",
      LEFT_3: "Tel:  +421 2 577 88 111",
      RIGHT_1: "",
      RIGHT_2: "",
      RIGHT_3: "",
    },
    PRINT_HEADER: {
      PRINT_DATE: "Datum tisku: ",
    },
  },
  AUTHJS: {
    DB_NOT_AV: "Databáze správce je z technických důvodů nedostupná",
    SOMETHINGWRONG: "Něco se pokazilo. Pracujeme na tom...",
    WRONGREGCODE: "Nesprávný registrační kód.",
    NOTALLOWED:
      "Přístup nepovolen! Kontaktujte, prosím, Vašeho správce Spokojené bydlení",
    ACCNOTVALID: "ÚČet není validní.",
  },
  COMMON: {
    LOADER: {
      LOADING_DATA_FROM_SERVER: "Načítám data ze serveru ...",
    },
    LIST: {
      ROW_COUNT_PER_PAGE: "Záznamů na stránku",
      NO_RECORDS: "Nebyly nalezeny žádné záznamy",
      NO_RECORDS_FILTERED:
        "Nebyly nalezeny žádné záznamy splňující zadaná kritéria",
    },
    MONTHS: {
      MONTH_1: "Leden",
      MONTH_2: "Únor",
      MONTH_3: "Březen",
      MONTH_4: "Duben",
      MONTH_5: "Květen",
      MONTH_6: "Červen",
      MONTH_7: "Červenec",
      MONTH_8: "Srpen",
      MONTH_9: "Září",
      MONTH_10: "Říjen",
      MONTH_11: "Listopad",
      MONTH_12: "Prosinec",
    },
  },
  FACEBOOK: {
    TITLE: "Přihlášení přes facebook",
  },
  GOOGLE: {
    TITLE: "Přihlášení přes google",
  },
  TABLE_CONTEXT_MENU: {
    PRINT: "Tisk",
    EXPORT_TO_CSV: "Export do CSV",
    DOWNLOAD: "Stáhnout",
  },
  UNIT_LIST: {
    TITLE: "Seznam prostor",
    CHANGE_BUTTON: "Změnit",
    SELECT_BUTTON: "Vybrat",
    CANCEL_BUTTON: "Odpojit",
    TABLE: {
      VAR_SYMBOL: "Var. symbol / Objekt",
      PROPERTY_COMPANY: "Správce",
      ADDRESS: "Adresa",
      TYPE: "Typ prostoru / Funkce",
      UNIT_NUMBER: "Číslo bytu / objektu",
    },
  },
  ACCOUNT: {
    TABLE: {
      PERIOD: "Měsíc",
      NAME_OF_MOVEMENT: "Typ pohybu",
      DETAIL_OF_MOVEMENT: "Detail pohybu",
      DUE_DATE: "Datum splat. / úhrady",
      AMOUNT: "Částka",
      BALANCE: "Zůstatek",
    },
    BALANCEON: "Zůstatek k ",
    DASHBOARD_TOOLTIP:
      "Zůstatek na Vašem účtu u správce (předpisy, platby, vyúčtování). Kladný zůstatek znamená přeplatek, záporný nedoplatek.",
  },
  PAYMENTORDER: {
    TABLE: {
      ITEM: "Položka",
      AMOUNT: "Částka",
      FOOTER: "Celkem",
    },
  },
  DECISIONS: {
    TABLE: {
      ACCEPTANCEWAY: "Přijato",
      VALIDITY: "Platnost",
      DESCRIPTION: "Popis",
      DATE: "Datum",
    },
  },
  PAYMENTREMINDEROVERVIEW: {
    TABLE: {
      PAYMENTREMINDERTYPE: "Typ",
      ARREAR: "Nedoplatek",
      PENALTYPAYMENT: "Penále",
      POSTAGECHARGE: "Poštovné",
      ISSUEDATE: "Dt. vystavení",
      DUEDATE: "Dt. splatnosti",
      ARREARDATE: "Dt. nedoplatku",
      STATUS: "Stav",
      SMSDELIVERYDATE: "Dt. doručení SMS",
      SMSDELIVERED: "SMS doručena",
      PAYMENTDATE: "Dt. úhrady",
      DATETO: "k datu",
      USER: "Uživatel",
      VS: "VS",
    },
    TOOLTIP: {
      STATUS: "Stav upomínky",
      PAYMENTREMINDERTYPE: "Typ upomínky",
      VS: "Variabilní symbol",
      USER: "Titul, jméno a příjmení uživatele prostoru",
      ISSUEDATE: "Datum vystavení",
      DUEDATE: "Datum splatnosti",
      ARREARDATE: "Datum nedoplatku",
      PAYMENTDATE: "Datum úhrady",
      SMSDELIVERYDATE: "Datum doručení SMS",
    },
    PARAMETER: {
      DATETO: "k datu:",
      BUTTON: "Načíst",
    },
  },
  METERREADINGS: {
    TABLE: {
      METERNUMBER: "Měřič",
      METERTYPE: "Druh měřiče",
      CLIMBINGIRON: "Umístění",
      ASSEMBLYDATE: "Datum montáže",
      DISASSEMBLYDATE: "Datum demontáže",
      HISTORY: "Historie",
      VERIFICATIONDATE: "Platný do",
      SEALS: "Plomby",
      LASTREADINGDATE: "Datum posledního odečtu",
      LASTREADINGDATEMOBILE: "Poslední odečet",
    },
    CHECKBOXLABEL: "Zobrazit také demontované měřiče",
    CHECKBOXDAILY: "Zobrazit také denní odpočty",
    CHECKBOXLABEL_NEGATIVE: "Nezobrazují se demontované měřiče",
    CHECKBOXLABEL_POSITIVE: "Zobrazují se i demontované měřiče",
  },
  ANNUALBILLING: {
    TABLE: {
      NAME: "Období vyúčtování",
      PAYMENTORDER: "Předpis",
      COST: "Náklad",
      DIFFERENCE: "Rozdíl",
      TEXT: "Výsledek",
      BALANCEDATE: "Stav konta (k datu)",
      ACCOUNTBALANCEBEFORE: "Stav konta",
      FINALBALANCE: "Celkový zůstatek",
      PERIODID: "ID období",
      ACCOUNT: "Konto",
      GROUP: "Skupina",
      ACCOUNTTYPE: "Typ konta",
      DOCCOUNT: "Počet dokumentů",
    },
  },
  OBJECTOFFICIALS: {
    TABLE: {
      ORDER: "Pořadí",
      OBJECT: "Objekt",
      NAME: "Jméno",
      PHONE: "Telefon / Mobil",
      EMAIL: "E-mail",
      ADDRESS: "Adresa",
      TYPE: "Typ",
      FUNCTION: "Funkce",
      PHOTO: "Fotka",
      WWW: "Odkaz",
    },
  },
  INSPECTION: {
    TABLE: {
      INSPECTIONID: "ID revize",
      OBJECT: "Objekt",
      NAME: "Název revize",
      VALIDTO: "Platnost do",
      LASTINSPECTION: "Poslední revize",
      DEFECTS: "Nalezeny závady",
      DEFECTSALL: "Závady (nalezeny / neodstraněné)",
      TODO: "Neodstraněné závady",
      STATE: "Stav",
    },
  },
  BUILDINGELEMENTS: {
    TABLE: {
      NAME: "Konstrukční prvek",
      COUNT: "Počet",
      COST: "Náklad",
      WARRANTYDATE: "Záruka do",
      STATEOFELEMENTS: "Stav",
      NOTE: "Poznámka",
    },
  },
  COSTS: {
    TABLE: {
      CODE: "Kód",
      ANALYSIS: "Služba",
      UNITOFMEASURE: "Měrné jednotky",
      CONSUMPTION: "Spotřeba",
      AMOUNT: "Náklad",
    },
  },
  BIDS: {
    TABLE: {
      DOCNAME: "Název dokumentu",
      FILENAME: "Cesta k souboru",
      DOCID: "ID dokumentu",
      CATEGORY: "Výběrové řízení",
      SOURCENAME: "Zdroj",
      CATEGORY2: "Název",
      STATUS: "Stav",
      DATEFROM: "Datum od",
      DATETO: "Datum do",
      FROM: "od ",
      TO: "do ",
    },
    FILTER: {
      DOC_NAME: "Vyhledat dokument",
      CATEGORY: "Výběrové řízení",
      PLACEHOLDER: "Všechny",
    },
  },
  REPAIRFUND: {
    TABLE: {
      AMOUNT: "Částka",
      DATE: "Datum",
      EVIDENCE: "Doklad",
      DESCRIPTION: "Popis",
    },
    FILTER: {
      GATE: "Vchod",
      VS: "Variabilní symbol",
      PLACEHOLDER: "Všechny",
      VIEW: "Zobrazit",
    },
    TITLEADD: "Zůstatek k ",
    FINALBALANCE: "Konečný stav",
    PARAMETER_YEAR: "za Rok:",
    VIEWOPTIONS: {
      1: "Tvorba a čerpání spolu",
      2: "Tvorba",
      3: "Čerpání",
      4: "Nejprve tvorba a pak čerpání",
    },
    OPENBALANCE: "Počáteční stav",
    FINBALANCE: "Konečný stav",
    PRODUCTION: "Tvorba",
    DRAWING: "Čerpání",
    PERIOD: "Období",
    MONTHS: "Měsíce",
    BALANCE: "Zůstatek",
  },
  INVOICES: {
    TABLE: {
      ID: "ID",
      OBJECT: "Centrum",
      CUSTOMER: "Dodavatel / Odběratel",
      AMOUNT: "Částka",
      AMOUNTPAID: "Uhrazená částka",
      DATEOFREGISTRATION: "Datum evidence",
      DUEDATE: "Datum splatnosti",
      PAYMENTDATE: "Datum platby",
      SERVICES: "Služby",
      INVOICEROWS: "Řádky faktury",
      ANALYSEROWS: "Řádky analýzy",
      CURRENCY: "Měna",
      DOCID: "Doc ID",
      DESCRIPTION: "Popis",
      VS: "Variabilní symbol",
    },
  },
  OBJECTACCOUNT: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Popis účtu",
      BANK: "Banka",
      FINALBALANCE: "Konečný zůstatek",
      BANKACCOUNT: "Číslo účtu",
      ISSUEDATE: "Stav k datu",
      ISSUEDATEMOBILE: "Stav k",
    },
  },
  SHEETS: {
    OPTIONS: {
      ALL: "Všichni",
      OWNERS: "Vlastníci",
      TENANTS: "Nájemníci",
      ALLB: "Všechny",
      NOB: "Nebyty",
      YESB: "Byty",
      PERC: "Procentem",
      FRAC: "Čitatel/Jmenovatel",
      HIDE: "Nezobrazovat",
    },
  },
  VOTINGSHEET: {
    TABLE: {
      VS: "Variabilní symbol",
      NAME: "Jméno",
      OBJECTNUMBER: "Číslo objektu",
      OBJECTADDRESS: "Adresa objektu",
      GATENUMBER: "Číslo brány",
      GATEADDRESS: "Adresa brány",
      APARTMENTNR: "Číslo prostoru",
      SIGNATURE: "Podpis",
      YES: "Souhlasím",
      NO: "Nesouhlasím",
      YESYES: "Ano",
      NONO: "Ne",
    },
    PLACEHOLDER1: "Název hlasování",
    PLACEHOLDER2: "Bližší popis hlasování",
    NAME: "Hlasovací list",
  },
  SHEETINPUT: {
    USERS: "Uživatelé",
    ROOMS: "Prostory",
    PORTIONS: "Podíl",
    DATE: "Datum k",
    BUTTON: "Zobrazit",
    TEXT1: "Název",
    TEXT2: "Popis",
  },
  SIGNATURESHEET: {
    TABLE: {
      VS: "Variabilní symbol",
      APARTMENTNR: "Číslo prostoru",
      NAME: "Jméno",
      GATENUMBER: "Číslo brány",
      GATEADDRESS: "Adresa brány",
      PORTION: "Spoluvlastnický podíl",
      SIGNATURE: "Podpis",
    },
    PLACEHOLDER1: "Název podpisového listu",
  },
  ACCOUNTPROFILE: {
    DROPDOWNUSER: {
      ADDSPACE: "Přidání prostoru",
      CANCELSPACE: "Odpojení prostoru",
      NOTIFICATIONSETTINGS: "Nastavení upozornění",
      PROFILE: "Profil",
      INPROGRESS: "",
    },
    NOTE:
      "Změna kontaktních údajů v profilu účtu neupravuje automaticky kontaktní údaje u Vašeho správce. Pro nahlášení změn správci použijte odkaz Moje požadavky",
    TITLE: "Profilové údaje",
    EMAIL: "E-mail",
    EMAIL_NEW: "Nový e-mail",
    PHONE: "Telefonní číslo",
    PHONE_NEW: "Nové telefonní číslo",
    PWD: "Heslo",
    PWD_NEW: "Nové heslo",
    PWD_OLD: "Původní heslo",
    PWD_NEWCONFIRM: "Zopakovat nové heslo",
    PLACEHOLDER_PWD: "Vaše nové heslo",
    REDIRECT:
      "Vaše konto bylo zrušeno. Budete přesměrován na úvodní stránku... ",
    REDIRECT2:
      "Registrace proběhla úspěšně. Budete přesměrováni na úvodní stránku, přihlaste se... ",
    CODE: "Kód",
    VALIDATION: {
      PWD_CONFIRM: "Nové hesla se musí zhodovat",
      PWD:
        "Heslo musí mít alespoň 8 znaků a zároveň obsahovat alespoň 1 znak z následujících 3 kategorií: Číslo, Velké a malé písmeno", // ENG
      PHONE:
        "Číslo je povinné. Musí mít délku minimálně 10 znaků, čísla pro Českou a Slovenskou republiku (+420/+421) musí být platná čísla u daných operátorů.",
      EMAIL: "E-mail musí být validní e-mailová adresa",
    },
    BUTTON: {
      EMAIL: "Změnit e-mail",
      EMAIL2: "Aktivovat přihlásení emailem",
      PHONE: "Změnit telefonní číslo",
      PWD: "Změnit heslo",
      PHOTOUPLOAD: "Nahrát fotografii",
      PHOTODELETE: "Vymazat fotografii",
      DISCONNECT: "Odpojit",
      CONNECT: "Připojit",
      DELETE_ACCOUNT: "Zrušit konto",
      CODE: "Potvrdit verifikační kód",
      OK: "OK",
    },
    ALERT: {
      EMAIL: "Zadali jste e-mail v nesprávném formátu",
      PHONE: "Zadali jste Tel. číslo v nesprávném formátu (+421/+420)",
      PWD:
        "Zadali jste heslo v nesprávném formátu (Min. 8 znaků, 1 velké písmeno, 1 malé písmeno, 1 číslo)",
    },
    SOCIAL: {
      GOOGLE_ON: "Přihlášený přes Google",
      GOOGLE_OFF: "Přihlášení přes Google",
      FACEBOOK_ON: "Přihlášený přes Facebook",
      FACEBOOK_OFF: "Přihlášení přes Facebook",
    },
    OPERATIONS: {
      SEC1: {
        TITLE: "Změna e-mailu",
        TEXT:
          "Uveďte nový e-mail, na který se Vám zašle verifikační kód a potvrďte ho heslem. Změna kontaktních údajů v profilu účtu neupravuje automaticky kontaktní údaje u Vašeho správce. Pro nahlášení změn správci, použijte sekci v menu Moje požadavky.",
      },
      SEC2: {
        TITLE: "Změna telefonního čísla",
        TEXT:
          "Uveďte nové telefonní číslo, na které se Vám zašle verifikační kód a potvrďte ho heslem. Změna kontaktních údajů v profilu účtu neupravuje automaticky kontaktní údaje u Vašeho správce. Pro nahlášení změn správci, použijte sekci v menu Moje požadavky.",
      },
      SEC3: {
        TITLE: "Změna hesla",
        TEXT:
          "Uveďte původní heslo, nové heslo a potvrďte ho jeho opakovaným zadáním",
      },
      SEC4: {
        TITLE: "Aktivovat přihlášení e-mailem",
        TEXT: "Vytvořením hesla se budete moct hlásit pomocí emailu a hesla",
      },
      SEC5: {
        TITLE: "Zrušení konta",
        TEXT:
          "Zrušením konta ztratíte přístup k údajům o přiřazených prostorech. Přístup k nim získáte jen novou registrací. Skutečně si přejete zrušit konto?",
      },
      SECCODE: {
        TITLE: "Verifikace kódem",
        TEXT_SMS: "Uveďte verifikační kód doručený přes SMS",
        TEXT_EMAIL: "Uveďte verifikační kód doručený e-mailem",
      },
      SECPWDCFM: {
        TITLE: "Změna hesla pomocí emailu",
        TEXT: "Na Váš e-mail jsme Vám zaslali odkaz na změnu hesla",
      },
    },
    CONNECTEDACCOUNTS:
      "K následujícím prostorům přistupují i jiná konta než vaše:",
    TITLEPHOTO: "Profilová fotografie",
    TITLEPHOTODESC1: "Fotografii změníte nahráním nové",
    TITLEPHOTODESC2: "Velikost fotografie může být ",
    TITLEPHOTODESC3: "maximálně 2MB",
    CONSENTPHOTO:
      "Zaškrtnutím tohoto pole souhlasím s tím, že se bude moje fotografie zobrazovat u mých kontaktních údajů v rámci bytového domu v systému DOMUS.",
    CONSENTFULL:
      "„Zaškrtnutím pole uživatel uděluje Provozovateli osobních údajů souhlas ke zpracování osobních údajů v rozsahu jeho fotografie, bere na vědomí a souhlasí s tím, že jeho fotografie bude zveřejněna u kontaktních údajů v domě za účelem identifikace a bude přístupná osobám v domě zaměstnancům správce,",
    CONSENTFULL_2:
      " po dobu trvání funkce, do které byla dotčená osoba jmenovaná nebo do doby, než se rozhodne zveřejnění fotografie ukončit a to odkliknutím tohoto pole. Tento souhlas je možné kdykoliv odvolat stejným způsobem jakým byl udělen.“ Více informací najdete v ",
    CONSENTFULL_LINK: "Pravidla ochrany osobních údajů.",
  },
  EVENTS: {
    TABLE: {
      SUBJECT: "Předmět",
      RESPONSIBLE: "Zodpovědný",
      DATE: "Datum",
      TYPE: "Typ",
    },
    SEARCH: "Vyhledat dle předmětu",
    FROM: "od: ",
    TO: "do: ",
    PRIORITY: {
      TEXT: "Priorita",
      1: "Nízká",
      2: "Střední",
      3: "Vysoká",
    },
  },
  IDENTITY: {
    TITLE: "Převzetí identity",
    FILTER: {
      ALL: "Všechny",
      USERS: "Uživatelé",
      OFFICIALS: "Funkcionáři",
      ADMINS: "Správcové",
      PLACEHOLDERCUSTOMER: "Zvolte kód zákazníka",
    },
    LABEL: {
      CUSTOMER: "Zákazník",
      ACCESS: "Typ přístupu",
      SEARCH: "Hledat",
    },
    ADMINS_TABLE: {
      TITLE: "Správcové",
      CODE: "Kód zákazníka",
      LOGINNAME: "Login",
      NAME: "Jméno",
      SURNAME: "Příjmení",
    },
    OBJECTS_TABLE: {
      TITLE: "Funkcionáři",
      NAME: "Jméno",
      SURNAME: "Příjmení",
      VCS: "Číslo objektu",
      ACCESSFROM: "Přístup od",
      ACCESSTO: "Přístup do",
      ADDRESS: "Ulice",
      CITY: "Město",
    },
    USERS_TABLE: {
      TITLE: "Uživatelé",
      NAME: "Jméno",
      SURNAME: "Příjmení",
      VS: "Var. symbol",
      ACCESSFROM: "Přístup od",
      ACCESSTO: "Přístup do",
      ADDRESS: "Ulice",
      CITY: "Město",
      TYPE: "V/N",
    },
  },
  METERREADING_DETAIL: {
    TABLE: {
      METERNUMBER: "Číslo měřiče",
      METERTYPE: "Typ měřiče",
      CLIMBINGIRON: "Umístění",
      STATE: "Stav",
      READINGDATE: "Datum odečtu",
      READINGCODE: "Kód odečtu",
      DESCRIPTION: "Popis",
      LINK: "Fotka",
    },
  },
  ANNUALBILLING_DETAIL: {
    AMOUNT: "Celkem",
    CUSTOMIZEDAMOUNT: "Celkem po úpravě nákladů",
    ACCOUNTBALANCETO: "Stav konta k ",
    TABLE: {
      ITEM: "Položka",
      PAYMENTORDER: "Předpis",
      COST: "Náklad",
      DIFFERENCE: "Rozdíl",
      ORDER: "Příkaz",
    },
  },
  INSPECTION_DETAIL: {
    TABLE: {
      DATE: "Datum",
      VALIDTO: "Platnost do",
      NAMEOFENGINEER: "Provedl",
      EXPENSE: "Náklady",
      DEFECTS: "Nalezeny závady",
      DEFECTSALL: "Závady (nalezeny / neodstraněné)",
      TODO: "Neodstraněné závady",
      LASTINSPECTION: "Datum odstranění",
      STATE: "Stav",
    },
  },
  OBJECTACCOUNT_DETAIL: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Popis účtu",
      CURRENCY: "Měna",
      INITIALBALANCE: "Počáteční stav",
      INITIALDATE: "Počáteční datum",
      RELATIVEBALANCE: "Relativní stav",
      FINALBALANCE: "Konečný stav",
      BANKACCOUNT: "Účet (IBAN)",
      BANK: "Banka",
      ISSUEDATE: "Datum vydání",
      VS: "Variabilní symbol",
      TEXT: "Operace",
      DATE: "Datum",
      AMOUNT: "Částka",
      DESCRIPTION: "Popis",
    },
    TITLEADD: "Stav k ",
    PARAMETER_YEAR: "Rok:",
    PARAMETER_MONTH: "Měsíc:",
    PARAMETER_REPORT: "Zobrazovat nepotvrzené výpisy",
  },
  INVOICE_DETAIL: {
    VS: "Variabilní symbol",
    CUSTOMER: "Dodavatel",
    INV_DESC: "Předmět",
    AMOUNT: "K úhradě",
    AMOUNTPAID: "Uhrazená částka",
    DOCS: "Dokumenty",
    CUSTOMERDF: "Dodavatel",
    CUSTOMEROF: "Odběratel",
    TYPE: "Typ faktury",
    TABLE: {
      DELIVERYPOINT: "Odběrné místo",
      NAME: "Služba/Popis",
      VALUE: "Částka",
      AMOUNT: "Množství",
      DATEFROM: "Datum od",
      DATETO: "Datum do",
      DATEFROM_MOBILE: "Od",
      DATETO_MOBILE: "do",
      READINGCODE: "Kód odečtu",
      ROWDESC: "Popis",
      HOUSE: "Středisko",
      GATE: "Činnost",
      INVOICE_ROWS: "Řádky faktury",
      INVOICE_ANALYSIS_ROWS: "Technické údaje",
    },
  },
  COST_DETAIL: {
    TABLE: {
      SUBSCRIBERSITE: "Odběrné místo",
      BUDGET: "Rozpočet",
      CONSUMPTION: "Spotřeba",
      AMOUNT: "Částka",
      DATEFROM: "Datum od",
      DATETO: "Datum do",
      ANALYSIS: "Služba",
      UNITOFMEASURE: "Měrná jednotka",
      CURRENCY: "Měna",
      MARK: "Známka",
      DATE: "Datum",
      TYPEOFSOURCE: "Typ zdroje",
      DATEFROM_MOBILE: "Od",
      DATETO_MOBILE: "do",
    },
  },
  MOVEMENT_DETAIL: {
    TITLE: "Detail pohybu",
    ACCOUNT_NR: "Číslo účtu",
    AMOUNT: "Částka",
    IBAN: "IBAN",
    VAR_SYMBOL: "Variabilní symbol",
    TABLE_ITEMS: {
      TITLE: "Položky",
      DESCRIPTION: "Popis",
      AMOUNT: "Částka",
    },
    TABLE_ASSIGNMENTS: {
      TITLE: "Přiřazení",
      DESCRIPTION: "Popis",
      AMOUNT: "Částka",
    },
  },
  CUSTOMERMAIL: {
    BACK: "Zpět",
    SAVEBUTTON: "Uložit",
    SENDBUTTON: "Odeslat",
    TABLE: {
      EMAILSUBJECT: "Předmět",
      EMAILCONTENT: "Obsah",
      EMAILDETAIL: "Seznam adres",
      DATEIINSTRUCTIONTOSENDEMAIL: "Datum odeslání",
      DATELASTCHANGE: "Datum změny",
      EMAILCOUNTALL: "Počet emailů",
      EMAILCOUNTSENT: "Počet odeslaných emailů",
      EMAILSENT: "Email byl odeslán",
      EMAILUNSENT: "Email nebyl odeslán",
      NOTSENT: "Neodeslán",
      TITLE: "Hromadný email",
      EMAIL: "Email",
      DATESENT: "Datum odeslání",
      SHOWUNSENTONLY: "Zobraz jen neodeslané",
      SEARCH: "Hledej email...",
    },
    ERRORS: {
      SUBJECT_REQUIRED: "Předmět emailu je povinné pole",
      CONTENT_REQUIRED: "Obsah emailu je povinné pole",
    },
    LIMITUSERS: "Posílat pouze uživatelům mimo paušál a mimo kontroly spotřeby",
    NEWMAIL: "Nový email",
    CONFIRMTITLE:
      "Tato akce odešle e-mail všem příjemcům. Před pokračováním potvrďte.",
    CONFIRMSEND:
      "Operace odeslání je nevratná a po potvrzení hromadně odesílá emaily. Opravdu chceš pokračovat?",
  },
  DOCUMENTS: {
    FILTER: {
      DOC_NAME: "Vyhledat dokument",
      CATEGORY: "Kategorie",
      PLACEHOLDER: "Všechny",
    },
    TABLE: {
      DOC_NAME: "Název",
      CREATION_DATE: "Nahraný",
      CATEGORY: "Kategorie",
      DOWNLOAD: "Stáhnout",
      SERVICE: "Odeslání",
      DATECRE: "Datum vystavení",
      DOC_NAME2: "Název přílohy",
      CREATED_BY: "Přidal",
    },
    UPLOAD: {
      HINT:
        "Jedna příloha nesmí přesáhnout 15 MB. Pokud chcete upravit název dokumentu, můžete tak učinit v tabulce po jeho výběru.",
      UPLOAD_NEW_FILES: "Přidat dokument",
      UPLOAD_TEXT: "Nahrát",
      DOC_NAME: "Název dokumentu",
      TOOBIGFILE: "Dokument je příliš velký (více než 15 MB).",
      EXTENSION: "Přípona ",
      STATUS: "Stav",
      READY: "Připraveno",
      OK: "Nahráno",
      UPLOADEDFILESCOUNT: "Počet vložených dokumentů: ",
      DELETEQUESTION: "Opravdu chcete tento dokument smazat?",
      READYTOBEUPLOADED: "soubory připravené k nahrání...",
      NOTUPLOADEDFILESALERT: "Mate nenahrané soubory. Chcete pokračovat?",
      UPLOADING: "Nahrávám...",
      CATEGORY_PLACEHOLDER: "Vyberte kategorii...",
    },
  },
  REQUEST_LIST: {
    TABLE: {
      SUBJECT: "Předmět",
      NAME: "Nahlásil",
      REQUEST_TYPE: "Typ",
      REQUEST_STATE: "Stav",
      CREATION_DATE: "Datum odeslání",
      DATE_OF_CHANGE: "Datum změny",
      REQUEST_ID: "Číslo",
    },
    FILTER: {
      ALL: "Všechny požadavky",
      HOUSE: "Požadavky vystavené na dům",
      USER: "Požadavky jiných uživatelů",
    },
    NEWREQUEST: "Nový požadavek",
    TITLEUSER: "Seznam mých požadavků",
    TITLEHOUSE: "Seznam požadavků domu",
    CATEGORY: "Typ požadavků",
  },
  REQUEST_DETAIL: {
    TITLE: "Detail požadavku",
    TYPE: "Typ požadavku",
    DESCRIPTION: "Obsah",
    SUBJECT: "Předmět",
    FILE: "Soubor s přílohou",
    CONTACTMAIL: "Kontaktní e-mail",
    CONTACTPHONE: "Kontaktní mobil",
    RESPONSIBLE: "Zodpovědná osoba",
    STATE: "Stav požadavku",
    REQUESTID: "Číslo požadavku",
    PARAMSTITLE: "Parametry",
    CHATTITLE: "Změny a komentáře",
    CONTACTMAILPLACEHOLDER: "E-mail pro kontaktování",
    CONTACTPHONEPLACEHOLDER: "Telefon pro kontaktování",
    MESSAGEPLACEHOLDER: "Tady pište zprávu",
    CANCELBUTTON: "Storno",
    SENDBUTTON: "Odeslat",
    SAVEBUTTON: "Uložit",
    FILEATTACHMENTCOMMENT: "Přiložená dokumentace",
    FILEUNAVAILABLEDESC:
      "Nahrání souborů je možné jenom při stavu požadavku 'Rozpracována'",
    BUTTON_FILE_SAVE: "Odeslat soubor",
    FILE_ATTACHED: "Přiložený soubor: ",
    SPR: "Správce",
  },
  NEW_REQUEST: {
    TITLE: "Nový požadavek",
    BACKBUTTON: "Zpět",
    LABEL: {
      REQTYPE: "Typ požadavku",
      EMAIL: "Kontaktní e-mail",
      EMAILDESC: "Váš e-mail nebudeme nikdy sdílet s nikým jiným",
      MOBILE: "Kontaktní mobil (ve tvaru +420720xxxxxx)",
      SUBJECT: "Předmět",
      DESCRIPTION: "Popisek",
      FILE: "Soubor s přílohou",
      METERNR: "Číslo měřiče*",
      READINGDATE: "Datum odečtu*",
      STATE: "Stav na měřiči*",
      OLDTITLE: "Starý titul před",
      OLDTITLE2: "Starý titul po",
      NEWTITLE: "Nový titul před",
      NEWTITLE2: "Nový titul po",
      OLDNAME: "Původní jméno",
      NEWNAME: "Nové jméno",
      OLDSURNAME: "Původní příjmení",
      NEWSURNAME: "Nové příjmení",
      OLDSTREET: "Původní ulice",
      NEWSTREET: "Nová ulice",
      OLDCITY: "Původní město",
      NEWCITY: "Nové město",
      OLDPSC: "Původní PSČ",
      NEWPSC: "Nové PSČ",
      YEAR: "Rok*",
      CHANGEDATE: "Datum změny od*",
      PERSONCOUNT: "Počet osob*",
      MAINTENANCE: "Nahláška se týká",
      RADIO1: "Mého prostoru",
      RADIO2: "Vchodu",
      RADIO3: "Domu",
      DELETE_FILES: "Zrušit přiložený soubor",
      DELETE_FILES_PRE: "Zrušené - ",
      SUMA: "Suma *",
      BU: "Bankový účet *",
      DESCVYD: "Popis výdavku *",
    },
    ERROR: {
      REQUIRED:
        "Před odesláním, vyplňte ,prosím, všechna povinná pole(*) ve formuláři.",
      REQUIREDPERSON:
        "Před odesláním, vyplňte ,prosím, všechna povinná pole(*) ve formuláři osoběměsíců.",
      REQUIREDCAPTCHA:
        "Před odesláním musíte potvrdit, že nejste robot (captcha).",
    },
    UPLOAD: "Přiložená dokumentace",
    UPLOADED: "Přiložené soubory k požadavku",
    UPLOADPLACEHOLDER: "Vyberte soubor nebo ho sem přetáhněte ...",
    DATEPLACEHOLDER: "Žádné datum není zvoleno",
    OLDNAMEPLACEHOLDER: "Původní jméno",
    OLDSURNAMEPLACEHOLDER: "Původní příjmení",
    OLDSTREETPLACEHOLDER: "Původní ulice",
    OLDCITYPLACEHOLDER: "Původní město",
    OLDPSCPLACEHOLDER: "Původní PSČ",
    PARAMETERS: "Parametry",
    CONFIRMLEAVE: "Máte neuložené změny! Opravdu chcete opustit stránku?",
    SENDED: "Odeslaná",
    ROZP: "Rozpracovaná",
    METERNR: "Číslo měřiče:",
    STATE: "Stav:",
    OLDMETERS: "Předešlé odpočty, které jste už zadali: ",
    TITLEPUB: "Sběrný formulář pro samoodečet měřičů",
    TITLEPUBPERSON: "Sběrný formulář pro zadání osoboměsíců",
    UPLOADPUB: "Přiložit soubor",
    METERLEAVE:
      "Údaje odečtu byly odeslány, chcete nahrát údaje dalšího měřiče?",
    NOTICE: "Oznam",
    YES: "Ano",
    NO: "Ne",
  },
  MONTHLY_CONSUMPTION: {
    CHART_WATER: {
      HOT_WATER: "Teplá voda",
      COLD_WATER: "Studená voda",
      AX_X: "Měsíc",
      AX_Y: "Spotřeba vody [m3]",
    },
    CHART_ROOMS: {
      AX_X: "Měsíc",
      AX_Y: "Spotřeba tepla  [Dílky, kWh, GJ]",
      S1: "Kuchyň",
      S2: "Obytná místnost č.1",
    },
    BUTTON1: "Jiné období",
    BUTTON2: "Roky",
    BUTTONOK: "Načíst",
    FROM: "od: ",
    TO: "do: ",
    SHOW_CORRECTED: "Zobrazit korigované hodnoty",
    TABLE: {
      TYPE: "Typ",
      NUMBER: "Č. měřiče",
      ROOM: "Místnost",
      VALUEFROM: "Stav od",
      VALUETO: "Stav do",
      CONSUMPTION: "Spotřeba",
      COEFFICIENT: "Koef. korekce",
      CONSUMPTION_COEFFICIENT: "Korig. spotřeba",
      INITCONSUMPTION: "Spotřeba od zač. roku",
      YOUR_CONSUMPTION: "Vaše kumulativní spotřeba",
    },
  },
  MONTHLY_CONSUMPTION_MORE_YEARS: {
    CHART_COLD_WATER: {
      AX_X: "Měsíc",
      AX_Y: "Spotřeba studené vody [m3]",
    },
    CHART_HOT_WATER: {
      AX_X: "Měsíc",
      AX_Y: "Spotřeba teplé vody [m3]",
    },
    CHART_HEAT: {
      AX_X: "Měsíc",
      AX_Y: "Spotřeba tepla [Dílky, kWh, GJ]",
      TAKE_CLIMATIC_CONDITIONS: "Zohlednit klimatické podmínky",
    },
    LINE: "Křivka",
    BAR: "Sloupce",
  },
  DAILY_CONSUMPTION_MORE_YEARS: {
    CHART_COLD_WATER: {
      AX_X: "Den",
      AX_Y: "Spotřeba studené vody [m3]",
    },
    CHART_HOT_WATER: {
      AX_X: "Den",
      AX_Y: "Spotřeba teplé vody [m3]",
    },
    CHART_HEAT: {
      AX_X: "Den",
      AX_Y: "[Dílky anebo kWh]",
    },
    MENUTITLE: "Zvolte zobrazované roky(max.3)",
  },
  DAILY_CONSUMPTION_AVERAGE_OBJECT: {
    CHART_COLD_WATER: {
      LEGEND_WATER: "Moje spotřeba",
      LEGEND_AVERAGE_WATER: "Průměrná spotřeba",
      LEGEND_OBJECT_WATER: "Referenční spotřeba",
      AX_X: "Den",
      AX_Y: "Spotřeba vody [litry]",
    },
    CHART_HOT_WATER: {
      LEGEND_WATER: "Moje spotřeba",
      LEGEND_AVERAGE_WATER: "Průměrná spotřeba",
      LEGEND_OBJECT_WATER: "Referenční spotřeba",
      AX_X: "Den",
      AX_Y: "Spotřeba vody [litry]",
    },
    CHART_HEAT: {
      LEGEND_HEAT: "Moje spotřeba",
      LEGEND_AVERAGE_HEAT: "Průměrná spotřeba",
      LEGEND_OBJECT_HEAT: "Referenční spotřeba",
      AX_X: "Den",
      AX_Y: "[Dílky, kWh, GJ]",
      SHOW_CORRECTED: "Zobrazit korigované hodnoty",
    },
    BUTTON1: "Vlastní data",
    BUTTON2: "Měsíce",
    BUTTONOK: "Načíst",
    FROM: "od: ",
    TO: "do: ",
  },
  MONTHLY_CONSUMPTION_AVERAGE_OBJECT: {
    CHART_COLD_WATER: {
      LEGEND_WATER: "Moje spotřeba",
      LEGEND_AVERAGE_WATER: "Průměrná spotřeba",
      LEGEND_OBJECT_WATER: "Referenční spotřeba",
      AX_X: "Měsíc",
      AX_Y: "Spotřeba vody [litry]",
    },
    CHART_HOT_WATER: {
      LEGEND_WATER: "Moje spotřeba",
      LEGEND_AVERAGE_WATER: "Průměrná spotřeba",
      LEGEND_OBJECT_WATER: "Referenční spotřeba",
      AX_X: "Měsíc",
      AX_Y: "Spotřeba vody [litry]",
    },
    CHART_HEAT: {
      LEGEND_HEAT: "Moje spotřeba",
      LEGEND_AVERAGE_HEAT: "Průměrná spotřeba",
      LEGEND_OBJECT_HEAT: "Referenční spotřeba",
      //LEGEND_COMPARABLE_HEAT: "Spotřeba v porovnatelném bytě",
      AX_X: "Měsíc",
      AX_Y: "Spotřeba tepla [Dílky, kWh, GJ]",
      SHOW_CORRECTED: "Zobrazit korigované hodnoty",
    },
    BUTTON1: "Jiné období",
    BUTTON2: "Roky",
    BUTTONOK: "Načíst",
    FROM: "od: ",
    TO: "do: ",
  },
  CONSUMPTION_FOR_PERIOD: {
    BUTTONOK: "Načíst",
    FROM: "od: ",
    TO: "do: ",
  },
  CONSUMPTION: {
    CHART_COLD_WATER: {
      LEGEND_WATER: "Spotřeba v mém bytě",
      LEGEND_AVERAGE_WATER: "Průměrná spotřeba v domě",
      AX_X: "Rok",
      AX_Y: "Spotřeba vody [m3]",
    },
    CHART_HOT_WATER: {
      LEGEND_WATER: "Spotřeba v mém bytě",
      LEGEND_AVERAGE_WATER: "Průměrná spotřeba v domě",
      AX_X: "Rok",
      AX_Y: "Spotřeba vody [m3]",
    },
    CHART_HEAT: {
      LEGEND_HEAT: "Spotřeba v mém bytě",
      LEGEND_AVERAGE_HEAT: "Průměrná spotřeba v domě",
      AX_X: "Rok",
      AX_Y: "[Dílky, kWh, GJ]",
    },
  },
  DAILY_CONSUMPTION: {
    CHART_WATER: {
      HOT_WATER: "Teplá voda",
      COLD_WATER: "Studená voda",
      HEAT: "Teplo",
      AX_X_HEAT: "Den",
      AX_Y_HEAT: "Spotřeba tepla [Dílky, kWh, GJ]",
      AX_X: "Den",
      AX_Y: "Spotřeba vody [litry]",
    },
    CHART_ROOMS: {
      AX_X: "Den",
      AX_Y: "[Dílky, kWh, GJ]",
    },
    BUTTON1: "Jiné období",
    BUTTON2: "Měsíce",
    BUTTONOK: "Načíst",
    FROM: "od: ",
    TO: "do: ",
    FILTERMONTH: "Měsíc",
    EMPTY: "Za zvolené období nejsou údaje k dispozici.",
  },
  BALANCES: {
    TABLE: {
      VS: "Variabilní symbol",
      APARTMENT_NUMBER: "Číslo bytu",
      APARTMENT_TYPE: "Typ prostoru",
      NAME: "Jméno a příjmení",
      BALANCE: "Zůstatek k datu",
      LAST_PAYMENT_DATE: "Poslední platba",
    },
    PARAMETER: {
      DATE: "k datu:",
      BUTTON: "Načíst",
    },
  },
  UNPAID_PREPAYMENTS: {
    TABLE: {
      USER: "Jméno",
      APARTMENT_NUMBER: "Č. př.",
      PERIOD: "Období",
      DUE_DATE: "Dt. splatnosti",
      TYPE: "Typ předpisu",
      AMOUNT: "Suma",
      PAID: "Uhrazeno",
      TO_BE_PAID: "Neuhrazeno",
    },
    TOOLTIP: {
      APARTMENT_NUMBER: "Číslo prostoru",
      DUE_DATE: "V případě platby se jedná o datum přijetí dané platby.",
      TYPE: "V případě platby se zobrazí typ platby.",
      PAID:
        "V případě zobrazení platby se zobrazuje jen část platby, která není přiřazena k žádnému předpisu do zadaného data.",
    },
    PARAMETER: {
      DATE: "k datu:",
      BUTTON: "Načíst",
    },
  },
  UNPAID_DEBTS: {
    TABLE: {
      VS: "Variabilní symbol",
      APARTMENT_NUMBER: "Číslo bytu",
      APARTMENT_TYPE: "Typ prostoru",
      NAME: "Jméno a příjmení",
      CLAIM_NAME: "Pohledávka",
      PERIOD: "Období",
      TO_BE_PAID: "Má zaplatit",
      PAID: "Zaplatil",
      DUE_DATE: "Datum splatnosti",
      PAYMENT_STATUS: "Stav",
      IS_BEING_VERIFIED: "ověřuje se",
      //ALREADY_PAID: " již uhrazeno",
      PAYMENT_DUE: "Splatnost ",
    },
  },
  AUTH: {
    GENERAL: {
      NO_ACCOUNT: "Nemáte účet?",
      SIGNUP_BUTTON: "Registrace",
      SIGNUP_ADDSPACE: "Přidání prostoru",
      SIGNIN_BUTTON: "Přihlášení",
      SIGNOUT_BUTTON: "Odhlášení",
      FORGOT_BUTTON: "Zapomněli jste heslo?",
      BACK_BUTTON: "Zpět",
      BACK_TOLOGIN: "Návrat na stránku loginu",
      PLACEHOLDER_EMAIL: "E-mailová adresa",
      CONTINUE: "Pokračovat",
      DISCONNECT_AND_CANCEL: "Odpojit a zrušit konto",
      TEXT1:
        "Technická odstávka. Z důvodu servisní údržby bude portál a související služby 20.9.2021 dočasně nedostupné. Děkujeme za pochopení.",
      TEXT2:
        "Probíhá aktualizace portálu. Některé služby mohou být dočasně nedostupné. Děkujeme za pochopení.",
    },
    LOGIN: {
      BUTTON: "Přihlásit se",
      LOGIN_EXTERNAL: "Přihlaste se přes",
      LOGIN_WITH_USERNAME_PASSWORD:
        "Nebo se přihlaste e-mailovou adresou a heslem",
      USERNAME_REQUIRED:
        "Použijte e-mailovú adresu, kterou jste uvedli při registraci",
      PASSWORD_REQUIRED: "Heslo je povinné",
      PLACEHOLDER_LOGIN: "E-mail",
      PLACEHOLDER_PWD: "Heslo",
      DEMO: "Nebo vyzkoušejte demo přístup",
      WARNING:
        "(*původní přihlašovací jména používané před 1.7.2021 již nejsou akceptovány. Vaše přihlašovací jméno bylo změněno na emailovou adresu, kterou jste použili při původní registraci)",
      VYUCTOVANIE1: "Přečtěte si: ",
      VYUCTOVANIE2: "Elektronické hlasování na portálu POSCHODECH.CZ",
    },
    UNITS: {
      TITLE: "Výběr prostoru",
      SELECT_BUTTON: "Vyber",
    },
    FORGOT: {
      TITLE: "Zapomněli jste heslo?",
    },
    REGISTER: {
      TITLE: "Registrace",
      TITLE_REG: "Zaregistruj mě",
      TEXT_MOBIL:
        "Zadejte, prosím, kontrolní kód, který Vám přišel v SMS zprávě",
      TEXT_EMAIL: "Zadejte, prosím, kontrolní kód, který Vám přišel emailem",
      TITLE_PHONE:
        "Pro pokračování v registraci bude na Váš mobilní telefon zaslán kontrolní kód. Prosím, zadejte číslo Vašeho mobilního telefonu:",
      SEND_CODE: "Poslat kód",
      SEND_BUTTON: "Odeslat",
      DIVIDER: "NEBO",
      LOGIN: "Přihlásit se",
      ACCOUNT_EXISTS: "Už máte účet?",
      VALIDATION: {
        EMAIL: "E-mail je povinný. Musíte zadat platnou e-mailovou adresu.",
        PWD:
          "Heslo je povinné. Musí mít alespoň 8 znaků a zároveň obsahovat alespoň 1 znak z následujících tří kategorií: Číslo, Velké a malé písmeno",
        PHONE:
          "Číslo je povinné. Musí mít délku minimálně 10 znaků, čísla pro Českou a Slovenskou republiku (+420/+421) musí být platná čísla u daných operátorů.",
        CODE_EMAIL: "Verifikační kód doručený E-mailem je povinný.",
        CODE_SMS: "Verifikační kód doručený přes SMS je povinný.",
      },
      PLACEHOLDER_PHONE: "Tel. číslo",
      PLACEHOLDER_CODE_EMAIL: "Verifikační kód doručený E-mailem",
      PLACEHOLDER_CODE_SMS: "Verifikační kód doručený přes SMS",
      STARTOVER:
        "Spletli jste se v registraci? Vraťte se na první krok a můžete kdykoliv začít znovu.",
      CODE_RESEND: "Znovu odeslat verifikační kód",
    },
    REGISTRATION_CODE: {
      TITLE: "Zadejte registrační kód",
      TEXT:
        "Pro získání přístupu na stránky portálu potřebujete registrační kód, který identifikuje Váš prostor. Kód získáte od Vašeho správce domu.",
      REQUIRED:
        "Registrační kód je povinný. Může obsahovat jen číslice, velké a malé písmena s vyjímkou písmena 'O'/'o'. Délka musí být 11 anebo 12 znaků.",
      SEND_BUTTON: "Odeslat",
      PLACEHOLDER_REGCODE: "Registrační kód",
      HINT1: "Nejčastější příčiny nesprávného kódu: ",
      HINT2: "- překlep v některém znaku, Kód má 11 nebo 12 znaků.",
      HINT3:
        "- kód není ještě aktivní. Nové vygenerované kódy jsou platné až druhý den, standardně do 24h.",
      HINT4:
        "- kód může být z bezpečnostních důvodů zneplatněn správcem nebo administrátorem.",
      HINT5:
        "Pokud Váš kód není akceptován ani druhý den, kontaktujte prosím Vašeho správce.",
    },
    REGISTRATION_AGREEMENT: {
      TITLE: "Obchodní podmínky a cena",
      HEAD: "Potvrzení obchodních podmínek a ceny",
      NAME: "Jméno",
      ADDRESS: "Adresa",
      VARIABLE_SYMBOL: "Variabilní symbol",
      AGREE_BUTTON: "Souhlasím",
      PRICE_CHECKBOX: "Souhlasím se stanovenou cenou",
      PRICE_TEXT: "Správcem stanovená cena za poskytnutí přístupu je ",
      PRICE_TEXT2:
        "V případě aktivní služby Kontrola spotřeby (dálkové odečty) je cena za přístup na portál zahrnuta v poplatku za tuto službu.",
      CUSTOMER_TEXT: "Poskytuje správce: ",
      LEGAL_AGREEMENT_CHECKBOX:
        "Přečetl/a jsem si a souhlasím s podmínkami poskytovatele ",
      LEGAL_AGREEMENT_CHECKBOX_ANA:
        "Přečetl/a jsem si a souhlasím s podmínkami provozovatele",
      GDPR_AGREEMENT_CHECKBOX:
        "Přečetl/a jsem si a souhlasím s pravidly ochrany osobních údajů",
      ANASOFT_TEXT: "Portál provozuje společnost ANASOFT APR, spol. s r.o.",
      REQUIRED:
        "Pro pokračování je nutno odsouhlasit (“zaškrtnout”) všechny podmínky.",
      TEXT_ACCESS: "Přístup k datům prostoru:",
    },
  },
  SPEEDOMETER: {
    VALUETEXT: "Aktuální spotřeba: ",
  },
  RESETPASSWORD: {
    TITLE: "Zadejte nové heslo",
    BUTTON: "Vytvořit heslo",
    TEXT: "Heslo bylo změněno. Můžete se přihlásit.",
  },
  FORGOTPASSWORD: {
    TITLE: "Zadejte e-mail účtu",
    BUTTON: "Resetovat heslo",
  },
  VOTING: {
    BUTTON: "Odeslat",
    TXT: "Zadejte kód hlasování od Vášho správce:",
    MAINTITLE: "Hlasovací listina",
    SECONDTITLE: "Období kdy se koná hlasování:",
    BUILDING_MANAGER: "Správce:",
    OWNER: "Vlastník:",
    EMAIL_TXT: "Emailová adresa pro zaslání potvrzení:",
    PHONE_TXT: "Hlasovací kód zaslaný na:",
    FILL_ALL: "Vyplň najednou za všechny prostory:",
    CONSENT:
      "Potvrzuji, že jsem osobou uvedenou výše a jako vlastník uvedených prostor, zapsaný na LV v příslušném katastru nemovitostí, jsem oprávněn hlasovat. V případě potvrzení nepravdivé informace jsem si vědom své trestněprávní odpovědnosti a odpovědnosti za způsobenou škodu.",
    SEND_CONFIRM:
      "Nezodpovědeli jste všechny otázky. Váš hlasovací list může být neplatný. Chcete opravdu odeslat?",
    SENT_TXT:
      "Vaše hlasování bylo odeslané. V krátké době obdržíte email s potvrzením",
    BUTTON_BACK: "Na stránku přihlásení",
  },
  PACKAGE: {
    BUTTON: "Odeslat",
    TXT: "Zadejte kód:",
    WRONGPASSWD: "Nesprávné heslo",
    ENTRY: "(Přístup k zásilce je chráněn heslem)",
    PACKAGE: "Zásilka",
  },
  USERINFO: {
    ADDRESS: "Adresa",
    VS: "VS",
    TYPE: "Typ",
    NAME: "Jméno",
    SURNAME: "Příjmení",
    DEGREE: "Titul",
    PERSON: "Počet osob",
    EMAIL: "E-mail",
    MOBIL: "Telefon",
    ADDRESS2: "Adresát",
    STREET: "Ulice",
    CITY: "Město",
    SIPO: "SIPO",
    BANKACCOUNT: "Bankovní účet",
    ELECTRONICINVOICE: "Elektronická faktura",
    PAYMENTORDER: "Platební příkaz",
    PERSONALINFO: "Osobní údaje",
    CONTACTINFO: "Kontaktní adresa",
    PAYMANTINFO: "Platební informace",
    OTHERSINFO: "Další informace",
    OTHERSINFO2: " ",
    ACTIVE: "aktivní",
    INACTIVE: "neaktivní",
  },
  CUSTREPORT: {
    BUTTON: "Generovat PDF výstup",
    MAINTITLE: "CUST REPORT NADPIS",
    FIRSTLISTOPTION: "-- Zvolte možnost prosím --",
    EMPTYVALIDATION: "Pole nesmí zůstat prázdné (bez hodnoty)",
  },
  DAILYCONSUMPTIONOBJECT: {
    FLATNUMBER: "Jméno/Číslo jednotky",
    VARSYMB: "Variabilní symbol",
    CODE: "Služba",
    METERNUMBER: "Číslo měřiče",
    LOCATION: "Umístění",
    STATE: "Stav",
    SV: "SV",
    TV: "TV",
    UK: "ÚT",
    DATE: "Zadejte datum:",
  },
  INVOICESAPPROVAL: {
    TITLE: "Faktura - daňový doklad",
    VS: "Variabilní symbol",
    DODAV: "Dodavatel",
    PREDMET: "Předmět",
    SUMAKU: "Částka k úhradě",
    UDAJKFAK: "Údaje o faktuře",
    RIADKYFAKT: "Řádky faktury",
    DOVOD: "Důvod:",
    NIE: "Ne",
    ANO: "Ano",
    DOVODHLASKA: "Důvod změny stavu je povinný údaj!",
    DAKUEJME: "Děkujeme za vyřízení!",
    ANONIEHLASKA:
      "Fakturu můžete schválit stisknutím tlačítka 'Ano', nebo zamítnout stisknutím tlačítka 'Ne'.",
    FILE: "Soubor",
    NAZOV: "Název",
    SPOLU: "Celkem",
    MENA: "Měna",
    SHOWERRORINT: "Fakturu nelze schválit nakolik nejsou stavy schvalování",
  },
  SHOWPICTURE: {
    INFO: "Info o odečtu",
    METERNUMBER: "Číslo měřiče",
    READINGDATE: "Datum odečtu",
    STATE: "Stav",
  },
  BETA: {
    MSG: "Testovací provoz",
  },
  EULAPRICES: {
    SUM: "Cena za přístup",
    DATE: "Datum platnosti od",
    TITLE: "Cena poskytovatele za přístup",
  },
  HOME: {
    MAINTITLE_LEFT: "POSCHODECH",
    MAINTITLE_LEFT_PENTA: "Services by PENTA",
    MAINTITLE_RIGHT: "Bydlete s přehledem",
    MAINTEXT_LEFT: "Přístup k údajům o Vašem bytě od správce.",
    MAINTEXT_RIGHT_1:
      "Portál poschodech.cz je určen nájemníkům a vlastníkům bytových a nebytových jednotek. Cílem portálu je poskytnout veškeré informace o bytě a domě.",
    MAINTEXT_RIGHT_2:
      "Webový portál poschodech.cz zpřístupňuje finanční a technické údaje o bytech a domech jejich vlastníkům. Přístup na portál umožňuje správce poskytnutím registračního kódu na požádání. Údaje jsou zpřístupněny z informačního systému DOMUS, a proto správce musí používat tento systém.",
    BANNERS: {
      GS: {
        TITLE: "Grafy spotřeb",
        TEXT:
          "Přehledy spotřeb vody, tepla a chladu v bytě jednoduše a rychle. Porovnávání spotřeb vůči průměru domu, vůči jiným obdobím ...",
      },
      K: {
        TITLE: "Kontakty",
        TEXT: "Přístup k důležitým kontaktům na správce nebo z domu.",
      },
      FO: {
        TITLE: "Příspěvek na správu domu a pozemku",
        TEXT:
          "Dokonalý přehled tvorby a čerpání z příspěvku na správu domu a pozemku. Měsíční přehledy s detailním rozpisem si můžete stáhnout jako PDF dokument.",
      },
    },
    FUNCTIONS: {
      TITLE: "Na portálu dále najdete:",
      DENNESPOT: "Denní spotřeby",
      FONDOPRAV: "Příspěvek na správu domu a pozemku",
      UDALOSTI: "Události v domě - nástěnka",
      DOKUMENTY: "Dokumenty domu - zápisy ze schůzí, revizní zprávy ...",
      ROCNEVYUCT: "Roční vyúčtování",
      GRAFY: "Grafy nákladů",
      KONTO: "Účet - platební bilance",
      ZASIELKY: "Zaslané dokumenty od správce",
      FAKTURY: "Faktury domu",
      REVIZIE: "Revize",
      KONTAKTY: "Kontakty domu",
      ZIADOSTI: "Žádosti",
    },
    DEMO: "Vyzkoušejte demo přístup",
    INSTALL: "Mobilní aplikace",
    APP: "Vyzkoušejte i mobilní aplikaci",
  },
  DASHBOARD: {
    COMMUNITY: "Naše společenství vlastníků",
  },
  CUSTOMERPAGE: {
    TABLE: {
      NAME: "Název stránky",
      NAMESK: "Název stránky SK",
      NAMECZ: "Název stránky CZ",
      NAMEEN: "Název stránky EN",
      PUBLISH: "Publikovat (Zveřejnit změnu)",
      SEND: "Odeslat notifikační emaily",
      SENDBUTTON: "Uložit změny",
      ORDER: "Umístění",
      NAMEOFPAGE: "Název stránky",
      ISNEW: "Změněna",
      DATE: "Datum změny",
      BACK: "Zpět",
      VISIBLE: "Viditelná",
    },
  },
  INSURANCEEVENTS: {
    NAME: "Název",
    STAV: "Stav",
    DATECREATION: "Datum vzniku",
    NUMBER: "Číslo pojistné události",
    DOCUMENTS: "Dokumenty",
    OBJECT: "Místo vzniku pojistné události",
    CAUSE: "Příčina vzniku škody",
    DAMAGE: "Rozsah poškození",
    RECIEVER: "Příjemce plnění",
    SUMDAMAGE: "Suma škody",
    SUM: "Suma plnění",
  },
  USERLISTOFFICIALS: {
    NAME: "Jméno (firma)",
    UNITNUMBER: "Číslo jednotky",
    EXTUNITNUMBER: "Ext. číslo jednotky",
    STREET: "Ulice",
    PSC: "PSČ",
    CITY: "Město",
    PCSCITY: "PSČ, Město",
    ODD1: "Kontakty",
    ODD2: "Další údaje uživatele",
    TEL: "Telefon",
    MOBIL: "Mobil",
    EMAIL: "E-mail",
    VS: "Variabilní symbol",
    SIPO: "Spojovací číslo SIPO",
    UNITDESCRIPTIONSK: "Typ prostoru",
    USERTYPE: "Nájemce / vlastník",
    UNITSTOCK: "Poschodí / Podlaží",
    PERSONS: "Počet osob",
    PAYINFO: "Platební informace",
    DTPRIST: "Datum prist.",
    DTODST: "Datum odst.",
    CHECKUZI: "Aktuální uživatelé",
    PORTALACCESSTRUE: "Uživatel s registrovaným přístupem",
    PORTALACCESSFALSE: "Uživatel bez registrovaného přístupu",
    PORTALACCESSTOOLTIP: "Registrace na portálu",
    DATEO: "Do: ",
    ODD3: "Údaje bytu",
    ODD4: "Plochy",
    PERSONCOUNT: "Počet osob",
    ROOMS: "Pokojovost",
    CUT: "Spoluvlastnický podíl",
  },
  ADMINACCOUNT: {
    LOGINNAME: "Login",
    CODE: "Kód správce",
    STATE: "Stav",
    MOBIL: "Mobil",
    CREATEDATE: "Datum vytvoření",
    DETACH: "Odpojit všechny prostory a zrušit konto",
    QU: "Jste si jisti?",
    ADDMANAGER: "Přidej správce",
    CHANGE: "Změnit email pro: ",
    NEWMANAGER: "Nový email pro správce",
    EMAIL: "Email",
    BTNCHANGE: "Změna emailu",
    BTNNEW: "Vytvoř správce",
    MODALTITLE: " :",
    TITLE: "Administrace správců",
  },
  REGLOGLIST: {
    LOGINNAME: "Login",
    CODE: "Kód správce",
    FROM: "Datum",
    TABLETITLE: "Události",
    TITLE: "Registrační log",
    REGSTEP: "Krok",
    REGPARM: "Parametry",
    CREATEDATE: "Datum vytvoření",
  },
  METERDAILYREADINGS: {
    CODE: "kód",
    TYPE: "Médium",
    STATE: "stav",
    CONSUMPTION: "Spotřeba",
    DATE: "datum",
    APARTMENT: "Prostor",
    METERNUMBER: "Číslo měřiče",
    DATEFROM: "Datum od",
    DATETO: "Datum do",
    STATETO: "Stav do",
    UNIT: "Mj",
    STATEFROM: "Stav od",
    MEDIUM: "Médium",
    APARTMENTNUMBER: "Číslo bytu",
    ERRORDATE: "Datum do musí být větší než datum od",
  },
  METERDAILYREADINGSOBJECT: {
    CHARTS: {
      AX_X_COLD: "Spotřeba vody [l]",
      AX_Y_COLD: "Prostor",
      AX_X_HOT: "Spotřeba vody [l]",
      AX_Y_HOT: "Prostor",
      AX_X_HEAT: "Spotřeba tepla [Dílky, kWh, GJ]",
      AX_Y_HEAT: "Prostor",
      HOT_WATER: "Teplá voda",
      COLD_WATER: "Studená voda",
      HEAT: "Teplo",
      LEGEND_WATER_COLD: "Spotřeba v prostoru",
      LEGEND_AVERAGE_WATER_COLD: "Průměrná spotřeba v domě",
      LEGEND_WATER_HOT: "Spotřeba v prostoru",
      LEGEND_AVERAGE_WATER_HOT: "Průměrná spotřeba v domě",
      LEGEND_HEAT: "Spotřeba v prostoru",
      LEGEND_AVERAGE_HEAT: "Průměrná spotřeba v domě",
    },
    BUTTONOK: "Načíst",
    DATETO: "Datum",
  },
  ACCOUNTLIST: {
    TABLE: {
      VS: "Variabilní symbol",
      NAME: "Jméno",
      TYPE: "Typ",
      DETAIL_OF_MOVEMENT: "Detail pohybu",
      DUE_DATE: "Datum splat. / úhrady",
      AMOUNT: "Suma",
    },
    TYPE: "Typ",
    FILTER: {
      ALL: "Všechny",
      N: "Náklad",
      P: "Platba",
      Z: "Zůstatek",
    },
  },
  CUSTOMERLIST: {
    TITLE: "Administrace zákazníků",
    TITLE2: "Všeobecné podmínky zákazníka",
    TITLEEDIT: "Nastavení zákazníka:",
    RESET: "Přednastavená",
    ORIGINAL: "Původní",
    SENDBUTTON: "Uložiť zmeny",
    BUTTONFREESK: "Zadarmo(SK)",
    BUTTONFREECZ: "Zdarma (CZ)",
    BUTTONFREEEN: "Free(EN)",
    TEXTFREESK: "0,00 Eur s DPH/mesiac (zadarmo)",
    TEXTFREECZ: "0,00 Kč s DPH/měsíc (zdarma)",
    TEXTFREEEN: "0,00 Eur VAT inc./month (free of charge)",
    NEWEULA: "Nové podmínky",
    DATEFROM: "Datum platnosti od",
    CONTENTSK: "Obsah SK",
    CONTENTCZ: "Obsah CZ",
    CONTENTEN: "Obsah EN",
    APRREQ: "Vyžadovat (opatovně) potvrzení podmínek",
    TABLE: {
      NAME: "Jméno",
      CODE: "Kód",
      CITY: "Město",
      PRIZE: "Cena",
      COLOR: "Barva",
      EULASK: "Eula SK",
      EULACZ: "Eula CZ",
      EULAEN: "Eula EN",
      LOGO: "Logo",
    },
  },
  NOTIFICATIONSETTINGS: {
    TITLE: "Nastavení upozornění",
    DESCRIPTION:
      "Změna nastavení upozornění u správce proběhne do 24h(* - nastavení upozornění určuje správce)",
    WATCH: "Neupozorňujeme",
    NOTWATCH: "Pouze na poschodech.cz",
    WATCHWITHMAIL: "E-mailem i na poschodech.cz",
    WATCH1: "Vypnuté",
    NOTWATCH1: "(Jen na poschodech.cz)",
    WATCHWITHMAIL1: "(E-mailem a na poschodech.cz)",
    SETMANAGER: "Nastavuje správce",
    APPLY: "Aplikovat na všechny mé prostory",
    OK: "OK",
    APPLIED: "Nastavení byla změněna",
  },
  EVENT_DETAIL: {
    DESCRIPTION: "Popis",
    SUBJECT: "Předmět",
    NEW: "Nová",
    EDIT: "Editovat",
    DELETE: "Smazat",
    SAVE: "Uložit",
    BACKBUTTON: "Zpět",
    UPLOAD: "Přiložit soubory",
    UPLOAD2: "Přiložit soubory *",
    FILEATTACHMENTCOMMENT: "Přiložená dokumentace",
    DELETE_FILES: "Zrušit přiložení souborů",
    UPLOADED: "Přiložené soubory",
    UPLOADPLACEHOLDER: "Vyberte soubor nebo jej sem přetáhněte...",
    UPLOADPLACEHOLDER2: "Vyberte soubor nebo jej sem přetáhněte... *",
    DELETE_FILES_PRE: "Zrušeno - ",
    NEWEVENT: "Přidat událost",
    PLACE: "Místo",
    DATEFROM: "Datum od",
    DATETO: "do",
    ONLYOWNED: "Moje",
    NOTIFI: "Seznámit ostatní uživatele o vykonání změn.",
    ZADAL: "Zadavatel",
    EVENTCOUNT: "Denně lze zadat maximálně 3 události",
    LENGTHERROR: "Maximální velikost souborů je 15MB",
    REQUIRED: "Nevyplněná povinná pole",
    TOMANYFILES: "Maximální počet souborů je 5",
    REQUIREDDATE: "It is necessary to have both event dates filled in",
    ELEVOTING: "Elektronicky hlasovalo",
    ELENOTVOTING: "Elektronicky nehlasovalo",
    WRITINGVOTING: "Písomne hlasuje",
    ACTUAL: "Aktuálny stav",
    VS: "Variabilný symbol",
    OBJECT: "Číslo bytu",
    NAME: "Meno",
    VOTE: "Stav",
    TOOLTIP: "Hlasoval",
    TOOLTIPNOPE: "Nehlasoval",
    TOOLTIPWRITING: "Písomne",
    TOOLTIPELE: "Elektronicky",
    TOOLTIPDATE: "Dátum hlasovania",
    TOOLTIPHLAS: "Spôsob hlasovania",
    TITLERUNING: "Hlasovanie - Priebežný stav",
    TITLEDONE: "Hlasovanie - Konečný stav",
    CSV: {
      OWNER: "Vlastník",
      EMAIL: "Email",
      VS: "VS",
      SPACE: "Priestor",
      CUT: "Podiel",
      QUESTION: "Otázka",
      VOTE: "Hlasovanie",
      DATEVOTE: "Čas Vyplnenia",
    },
  },
  NOTIFICATIONS: {
    CREATION_DATE: "Datum a čas",
    DESCRIPTION: "Popis",
    NOTIF: "Upozornění",
    NOTE:
      "Změna nastavení notifikace u správce proběhne do 24h(* - nastavení notifikace urcuje správce)",
  },
  CASHREGISTER: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Popis pokladny",
      BANK: "Číslo",
      FINALBALANCE: "Konečný stav",
      BANKACCOUNT: "Číslo účtu",
      ISSUEDATE: "Stav k datu",
      ISSUEDATEMOBILE: "Stav k",
    },
  },
  CASHREGISTER_DETAIL: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Popis účtu",
      CURRENCY: "Měna",
      INITIALBALANCE: "Počáteční stav",
      INITIALDATE: "Počáteční datum",
      RELATIVEBALANCE: "Relativní stav",
      FINALBALANCE: "Konečný stav",
      BANKACCOUNT: "Účet (IBAN)",
      BANK: "Číslo",
      ISSUEDATE: "Datum vydání",
      VS: "Variabilní symbol",
      TEXT: "Text",
      DATE: "Datum ",
      AMOUNT: "Suma",
      DESCRIPTION: "Popis",
    },
    TITLEADD: "Stav k ",
    PARAMETER_YEAR: "Rok:",
    PARAMETER_MONTH: "Měsíc:",
    PARAMETER_REPORT: "Zobrazovat nepotvrzené výpisy",
  },
};
