<template>
  <div>
    <div class="row poschodoch-profile">
      <div class="col-lg-12 col-xl-12">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <!--Begin::Acc profile-->
        <KTPortlet v-show="showSecMain">
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">{{ title }}</slot>
              </span>
            </h3>
          </template>
          <template v-slot:body>
            <div class="row justify-content-center">
              <div class="col-lg-9 col-sm-12">
                <div class="d-flex mb-4">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.EMAIL") }}
                  </span>
                  <div class="mr-5" style="width: 100%">
                    <span class="ml-3 mr-3" style="vertical-align: sub">
                      {{ oldEmail }}
                    </span>
                  </div>
                  <b-button
                    @click="
                      showSecMain = false;
                      showSec1 = true;
                    "
                    v-if="havePWD"
                    variant="primary"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.EMAIL") }}
                  </b-button>
                  <b-button
                    @click="sendResetPWDMail(oldEmail)"
                    id="reset_pwd"
                    v-if="!havePWD"
                    variant="primary"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.EMAIL2") }}
                  </b-button>
                </div>

                <div class="d-flex mb-4" v-if="havePWD">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.PWD") }}
                  </span>
                  <div class="mr-5" style="width: 100%">
                    <span class="ml-3 mr-3" style="vertical-align: sub">
                      {{ oldPassword }}
                    </span>
                  </div>
                  <b-button
                    @click="sendResetPWDMail(oldEmail)"
                    id="reset_pwd"
                    variant="primary"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.PWD") }}
                  </b-button>
                </div>

                <div class="d-flex mb-4">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.PHONE") }}
                  </span>
                  <div class="mr-5" style="width: 100%">
                    <span class="ml-3 mr-3" style="vertical-align: sub">
                      {{ oldPhone }}
                    </span>
                  </div>
                  <b-button
                    @click="
                      showSecMain = false;
                      showSec2 = true;
                    "
                    variant="primary"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.PHONE") }}
                  </b-button>
                </div>

                <div class="d-flex mb-4">
                  <span
                    style="width: 14rem"
                    class="d-flex justify-content-center"
                  >
                    <i class="poschodoch-icon-google"></i>
                  </span>
                  <div class="mr-5" style="width: 100%">
                    <span
                      class="ml-3 mr-3"
                      style="vertical-align: sub"
                      v-if="googleID"
                    >
                      {{ $i18n.t("ACCOUNTPROFILE.SOCIAL.GOOGLE_ON") }}
                    </span>
                    <span
                      class="ml-3 mr-3"
                      style="vertical-align: sub"
                      v-if="!googleID"
                    >
                      {{ $i18n.t("ACCOUNTPROFILE.SOCIAL.GOOGLE_OFF") }}
                    </span>
                  </div>
                  <b-button
                    @click="socialNetworksFirstStep('G', 'deactivate')"
                    id="G_actdeact"
                    v-if="googleID"
                    variant="primary"
                    style="width: 22rem; height: fit-content"
                    :disabled="!havePWD && !facebookID && !appleID"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.DISCONNECT") }}
                  </b-button>
                  <b-button
                    @click="socialNetworksFirstStep('G', 'activate')"
                    id="G_actdeact"
                    v-if="!googleID"
                    variant="primary"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.CONNECT") }}
                  </b-button>
                </div>

                <div class="d-flex mb-4">
                  <span
                    style="width: 14rem"
                    class="d-flex justify-content-center"
                  >
                    <i class="poschodoch-icon-facebook"></i>
                  </span>
                  <div class="mr-5" style="width: 100%">
                    <span
                      class="ml-3 mr-3"
                      style="vertical-align: sub"
                      v-if="facebookID"
                    >
                      {{ $i18n.t("ACCOUNTPROFILE.SOCIAL.FACEBOOK_ON") }}
                    </span>
                    <span
                      class="ml-3 mr-3"
                      style="vertical-align: sub"
                      v-if="!facebookID"
                    >
                      {{ $i18n.t("ACCOUNTPROFILE.SOCIAL.FACEBOOK_OFF") }}
                    </span>
                  </div>
                  <b-button
                    @click="socialNetworksFirstStep('F', 'deactivate')"
                    id="F_actdeact"
                    v-if="facebookID"
                    variant="primary"
                    :disabled="!havePWD && !googleID && !appleID"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.DISCONNECT") }}
                  </b-button>
                  <b-button
                    @click="socialNetworksFirstStep('F', 'activate')"
                    id="F_actdeact"
                    v-if="!facebookID"
                    variant="primary"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.CONNECT") }}
                  </b-button>
                </div>

                <div class="d-flex mb-4">
                  <span style="width: 14rem"></span>
                  <div class="mr-5" style="width: 100%"></div>
                  <b-button
                    @click="
                      showSecMain = false;
                      showSecDelete = true;
                    "
                    variant="danger"
                    style="
                      width: 22rem;
                      height: fit-content;
                      color: #ffffff !important;
                    "
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.DELETE_ACCOUNT") }}
                  </b-button>
                </div>
              </div>

              <div
                class="col-xl-10 col-lg-10 col-sm-12"
                v-if="connectedAccounts.length != 0"
              >
                <span>{{ $i18n.t("ACCOUNTPROFILE.CONNECTEDACCOUNTS") }}</span>
                <div v-for="acc in connectedAccounts" :key="acc">{{ acc }}</div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-show="showSec1">
          <template v-slot:title>
            <span>{{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SEC1.TITLE") }}</span>
          </template>
          <template v-slot:toolbar>
            <b-button
              variant="secondary"
              v-on:click="
                showSecMain = true;
                showSec1 = false;
                password = null;
              "
            >
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="d-flex mb-12 justify-content-left mb-3 pb-3">
              <span>
                {{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SEC1.TEXT") }}
              </span>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-9 col-sm-12">
                <div class="d-flex mb-4 justify-content-center">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.EMAIL") }}
                  </span>
                  <div class="mr-5" style="width: 50%">
                    <b-form-input
                      id="input-old-Email1"
                      v-model="oldEmail"
                      type="email"
                      class="ml-3 mr-3"
                      disabled
                    ></b-form-input>
                  </div>
                  <div style="width: 2%"></div>
                </div>
                <div class="d-flex mb-4 justify-content-center">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.EMAIL_NEW") }}
                  </span>
                  <!-- :state="validateEmail" v-model="email" -->
                  <div class="mr-5" style="width: 50%">
                    <b-form-input
                      id="input-Email"
                      v-model="$v.email.$model"
                      :state="validateState('email')"
                      aria-describedby="email-live-feedback"
                      type="email"
                      class="ml-3 mr-3"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="email-live-feedback"
                      class="ml-3"
                    >
                      {{ $i18n.t("ACCOUNTPROFILE.VALIDATION.EMAIL") }}
                    </b-form-invalid-feedback>
                  </div>
                  <div style="width: 2%"></div>
                </div>
                <div class="d-flex mb-4 justify-content-center">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.PWD") }}
                  </span>
                  <div class="mr-5" style="width: 50%">
                    <b-form-input
                      id="input-Password1"
                      :state="validatePWD"
                      v-model="password"
                      :type="visibilityPWD"
                      class="ml-3 mr-3"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validatePWD" class="ml-3">
                      {{ $i18n.t("ACCOUNTPROFILE.VALIDATION.PWD") }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="mt-2" style="width: 2%">
                    <a
                      @click="showPassword()"
                      style="opacity: 0.9"
                      class="notunderlined ml-0"
                      v-if="visibilityPWD == 'password'"
                    >
                      <i class="fas fa-eye-slash poschodoch-icon-medium"></i>
                    </a>
                    <a
                      @click="hidePassword()"
                      style="opacity: 0.9"
                      class="notunderlined ml-0"
                      v-if="visibilityPWD == 'text'"
                    >
                      <i class="fas fa-eye poschodoch-icon-medium"></i>
                    </a>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <b-button
                    @click="changeRequest(email, password, 0)"
                    id="email_changeReq"
                    variant="primary"
                    :disabled="!(validateEmail && validatePWD)"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.EMAIL") }}
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-show="showSec2">
          <template v-slot:title>
            <span>{{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SEC2.TITLE") }}</span>
          </template>
          <template v-slot:toolbar>
            <b-button
              variant="secondary"
              v-on:click="
                showSecMain = true;
                showSec2 = false;
                password = null;
              "
            >
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="d-flex mb-12 justify-content-left mb-3 pb-3">
              <span>
                {{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SEC2.TEXT") }}
              </span>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-9 col-sm-12">
                <div class="d-flex mb-4 justify-content-center">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.PHONE") }}
                  </span>
                  <div class="mr-5" style="width: 50%">
                    <b-form-input
                      id="input-old-Phone"
                      v-model="oldPhone"
                      type="tel"
                      class="ml-3 mr-3"
                      disabled
                    ></b-form-input>
                  </div>
                  <div style="width: 2%"></div>
                </div>
                <div class="d-flex mb-4 justify-content-center">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.PHONE_NEW") }}
                  </span>
                  <div class="mr-5" style="width: 50%">
                    <b-form-input
                      id="input-Phone"
                      :state="validatePhone"
                      v-model="phone"
                      type="tel"
                      class="ml-3 mr-3"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="validatePhone"
                      class="ml-3"
                    >
                      {{ $i18n.t("ACCOUNTPROFILE.VALIDATION.PHONE") }}
                    </b-form-invalid-feedback>
                  </div>
                  <div style="width: 2%"></div>
                </div>
                <div class="d-flex mb-4 justify-content-center">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.PWD") }}
                  </span>
                  <div class="mr-5" style="width: 50%">
                    <b-form-input
                      id="input-Password2"
                      :state="validatePWD"
                      v-model="password"
                      :type="visibilityPWD"
                      class="ml-3 mr-3"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validatePWD" class="ml-3">
                      {{ $i18n.t("ACCOUNTPROFILE.VALIDATION.PWD") }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="mt-2" style="width: 2%">
                    <a
                      @click="showPassword()"
                      style="opacity: 0.9"
                      class="notunderlined ml-0"
                      v-if="visibilityPWD == 'password'"
                    >
                      <i class="fas fa-eye-slash poschodoch-icon-medium"></i>
                    </a>
                    <a
                      @click="hidePassword()"
                      style="opacity: 0.9"
                      class="notunderlined ml-0"
                      v-if="visibilityPWD == 'text'"
                    >
                      <i class="fas fa-eye poschodoch-icon-medium"></i>
                    </a>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <b-button
                    @click="changeRequest(phone, password, 1)"
                    id="phone_changeReq"
                    variant="primary"
                    :disabled="!(validatePhone && validatePWD)"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.PHONE") }}
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-show="showSecDelete">
          <template v-slot:title>
            <span>{{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SEC5.TITLE") }}</span>
          </template>
          <template v-slot:toolbar>
            <b-button
              variant="secondary"
              v-on:click="
                showSecMain = true;
                showSecDelete = false;
                password = null;
              "
            >
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row justify-content-center">
              <div class="col-lg-9 col-sm-12">
                <div
                  class="d-flex mb-4 justify-content-center"
                  style="width: 50%"
                >
                  <span>
                    {{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SEC5.TEXT") }}
                  </span>
                </div>
                <div class="d-flex mb-4 justify-content-center">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.PWD") }}
                  </span>
                  <div class="mr-5" style="width: 50%">
                    <b-form-input
                      id="input-Password3"
                      :state="validatePWD"
                      v-model="password"
                      :type="visibilityPWD"
                      class="ml-3 mr-3"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validatePWD" class="ml-3">
                      {{ $i18n.t("ACCOUNTPROFILE.VALIDATION.PWD") }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="mt-2" style="width: 2%">
                    <a
                      @click="showPassword()"
                      style="opacity: 0.9"
                      class="notunderlined ml-0"
                      v-if="visibilityPWD == 'password'"
                    >
                      <i class="fas fa-eye-slash poschodoch-icon-medium"></i>
                    </a>
                    <a
                      @click="hidePassword()"
                      style="opacity: 0.9"
                      class="notunderlined ml-0"
                      v-if="visibilityPWD == 'text'"
                    >
                      <i class="fas fa-eye poschodoch-icon-medium"></i>
                    </a>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <b-button
                    @click="deactivateAccount(password)"
                    id="deactivate_acc"
                    variant="danger"
                    :disabled="!validatePWD"
                    style="
                      width: 22rem;
                      height: fit-content;
                      color: #ffffff !important;
                    "
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.DELETE_ACCOUNT") }}
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-show="showSecCode">
          <template v-slot:title>
            <span>
              {{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SECCODE.TITLE") }}
            </span>
          </template>
          <template v-slot:toolbar>
            <b-button
              variant="secondary"
              v-on:click="
                showSecMain = true;
                showSecCode = false;
              "
            >
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row justify-content-center">
              <div class="col-lg-9 col-sm-12">
                <div
                  class="d-flex mb-4 justify-content-center"
                  style="width: 50%"
                >
                  <span v-if="verifyBySmsText">
                    {{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SECCODE.TEXT_SMS") }}
                  </span>
                  <span v-else>
                    {{
                      $i18n.t("ACCOUNTPROFILE.OPERATIONS.SECCODE.TEXT_EMAIL")
                    }}
                  </span>
                </div>
                <div class="d-flex mb-4 justify-content-center">
                  <span style="width: 14rem; margin-top: 0.65rem !important">
                    {{ $i18n.t("ACCOUNTPROFILE.CODE") }}
                  </span>
                  <div class="mr-5" style="width: 50%">
                    <b-form-input
                      id="input-Code"
                      v-model="code"
                      type="text"
                      class="ml-3 mr-3"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <b-button
                    @click="changeConfirmCode(code, codeSmsEmail)"
                    id="code_confirm"
                    variant="primary"
                    style="width: 22rem; height: fit-content"
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.CODE") }}
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-show="showSecPWDCFM">
          <template v-slot:body>
            <div class="row justify-content-center">
              <div class="col-lg-9 col-sm-12">
                <div class="d-flex mb-4 justify-content-center">
                  <span>
                    {{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SECPWDCFM.TEXT") }}
                  </span>
                </div>
                <div class="row justify-content-center">
                  <b-button
                    @click="okButtonPWD()"
                    variant="primary"
                    style="
                      width: 22rem;
                      height: fit-content;
                      color: #ffffff !important;
                    "
                  >
                    {{ $i18n.t("ACCOUNTPROFILE.BUTTON.OK") }}
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <!--End::Acc profile-->
        <!--Begin::Acc photo-->
        <KTPortlet>
          <template v-slot:title>
            <div
              class="d-flex mt-3 mb-3"
              style="align-items: flex-start; flex-direction: column"
            >
              <h3 class="kt-portlet__head-title mr-4">
                <span>
                  <slot name="title">{{ titlePhoto }}</slot>
                </span>
              </h3>
              <span style="font-weight: 200">
                {{ $i18n.t("ACCOUNTPROFILE.TITLEPHOTODESC1") }}
              </span>
              <div>
                <span style="font-weight: 200">
                  {{ $i18n.t("ACCOUNTPROFILE.TITLEPHOTODESC2") }}
                </span>
                <span style="font-weight: 800">
                  {{ $i18n.t("ACCOUNTPROFILE.TITLEPHOTODESC3") }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div
              class="col-12"
              style="display: flex; flex-direction: column; align-items: center"
            >
              <img
                width="185"
                v-if="photo != null && photo != ''"
                alt="Picture"
                v-bind:src="'data:image/png;base64,' + photo"
              />
              <img
                width="185"
                v-else
                alt="Picture"
                src="@/assets/media/users/default.jpg"
              />
              <b-form-file
                @change="getBase64File"
                accept="image/*"
                v-model="photoFile"
                class="mt-3"
                style="width: auto"
              ></b-form-file>
              <div class="row">
                <b-button
                  @click="UploadPhoto(base64photo)"
                  id="photo_upload"
                  class="mt-2 mr-2"
                  variant="primary"
                >
                  {{ $i18n.t("ACCOUNTPROFILE.BUTTON.PHOTOUPLOAD") }}
                </b-button>
                <b-button
                  @click="
                    photoFile = null;
                    DeletePhoto();
                  "
                  id="photo_delete"
                  class="mt-2"
                  v-if="photo != null && photo != ''"
                >
                  {{ $i18n.t("ACCOUNTPROFILE.BUTTON.PHOTODELETE") }}
                </b-button>
              </div>
              <div class="col-6" v-if="photoConsentVisible == 1">
                <b-form-checkbox
                  id="checkbox-photo"
                  v-model="photoConsent"
                  name="checkbox-photo"
                  class="mt-2"
                  @change="UploadConsent(!photoConsent)"
                >
                  {{ $i18n.t("ACCOUNTPROFILE.CONSENTPHOTO") }}
                </b-form-checkbox>
                <hr class="poschodoch-separator-line mt-4 mb-4" />
                <ul class="poschodoch-note-list">
                  <li class="poschodoch-note">
                    {{ $i18n.t("ACCOUNTPROFILE.CONSENTFULL")
                    }}{{ $i18n.t("ACCOUNTPROFILE.CONSENTFULL_2") }}
                    <router-link
                      :to="{ name: 'gdprTerms' }"
                      target="_blank"
                      class="kt-link"
                    >
                      {{ $i18n.t("ACCOUNTPROFILE.CONSENTFULL_LINK") }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </KTPortlet>
        <!--End::Acc photo-->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import JwtService from "@/common/jwt.service";
import { LOGOUT } from "@/store/auth.module";
import AppConfig from "@/common/config";
import VueGapi from "vue-gapi";
import UIService from "@/common/ui.service";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

Vue.use(AppConfig);

// create the Google 'options' object
const googleConfig = {
  clientId: Vue.appConfig.GOOGLE_CLIENT_ID,
  scope: "https://www.googleapis.com/auth/userinfo.profile",
};

// Use the plugin and pass along the configuration
Vue.use(VueGapi, googleConfig);

export default {
  mixins: [validationMixin],
  name: "accountProfile",
  components: {
    KTPortlet,
  },
  data() {
    return {
      oldEmail: null,
      oldPhone: null,
      oldPassword: null,
      newPassword: null,
      newPWDConfirm: null,
      havePWD: null,
      facebookID: null,
      googleID: null,
      appleID: null,
      title: null,
      email: null,
      phone: null,
      photo: null,
      errors: [],
      photoConsent: null,
      photoConsentVisible: null,
      connectedAccounts: [],
      password: null,
      titlePhoto: null,
      photoFile: null,
      base64photo: null,
      ipAddress: "",
      visibilityPWD: "password",
      showSecMain: true,
      showSec1: false,
      showSec2: false,
      showSecDelete: false,
      showSecCode: false,
      showSecPWDCFM: false,
      code: null,
      codeSmsEmail: null,
      serviceID: null,
      serviceEmail: null,
      verifyBySmsText: false,
    };
  },
  computed: {
    validatePWD() {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,50}$/.test(
        this.password,
      );
    },
    validateOldPWD() {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,50}$/.test(
        this.oldPassword,
      );
    },
    validateNewPWD() {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,50}$/.test(
        this.newPassword,
      );
    },
    validateNewConfirmPWD() {
      if (this.newPassword != this.newPWDConfirm) return false;
      else
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(
          this.newPWDConfirm,
        );
    },
    validatePhone() {
      /* povodne s pluskom
      let regex = /^\+[0-9]{10,}$/;
      if (/^\+421[0-9]{9}$/.test(phone)) regex = /^\+4219[0-9]{8}$/;
      if (/^\+420[0-9]{9}$/.test(phone)) regex = /^\+420[67][0-9]{8}$/;
      */
      let regex = /^[+][1-9]{1}[0-9]{10,}$/;
      if (/^\+421[0-9]{9}$/.test(this.phone)) regex = /^\+4219[0-9]{8}$/;
      if (/^\+420[0-9]{9}$/.test(this.phone)) regex = /^\+420[67][0-9]{8}$/;

      return regex.test(this.phone);
    },
    validateEmail() {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.email,
      );
    },
  },
  validations: {
    email: {
      required,
      email,
      isEmailValid(email) {
        return email.length < 200;
      },
    },
  },
  mounted() {
    this.fetchData();

    //if came from fb, google, apple with id param
    let parameters = this.$route.query;

    if (parameters.deactivate == 1) {
      this.showSecMain = false;
      this.showSecDelete = true;
    }

    if (parameters.token && parameters.type === "facebook") {
      ApiService.get(
        "https://graph.facebook.com/me?fields=name,email&access_token=" +
          parameters.token,
      ).then((facebookInfo) => {
        this.serviceID = facebookInfo.data.id;
        this.serviceEmail = facebookInfo.data.email;

        if (parameters.action === "deactivate") this.deactivateService("F");
        else if (parameters.action === "activate") this.activateService("F");
      });
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    showPassword() {
      this.visibilityPWD = "text";
    },
    hidePassword() {
      this.visibilityPWD = "password";
    },
    getFormatService() {
      return FormatService;
    },
    getBase64File(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (e) => (this.base64photo = e.target.result);
    },
    UploadPhoto(PhotoFile) {
      UIService.addButtonSpinner("photo_upload");
      if (PhotoFile != null) {
        let cislo = PhotoFile.indexOf(",");
        PhotoFile = PhotoFile.substring(cislo + 1, PhotoFile.length);

        let postContent = {
          photo: PhotoFile,
        };
        ApiService.post("Account/UpdateProfile", postContent)
          .then(() => {
            UIService.removeButtonSpinner("photo_upload");
          })
          .catch(() => {
            UIService.removeButtonSpinner("photo_upload");
          });

        setTimeout(function() {
          window.location.reload();
        }, 300);
      } else {
        UIService.removeButtonSpinner("photo_upload");
      }
    },
    DeletePhoto() {
      let postContent = {
        photo: "",
      };

      UIService.addButtonSpinner("photo_delete");
      ApiService.post("Account/UpdateProfile", postContent)
        .then(() => {
          UIService.removeButtonSpinner("photo_delete");
        })
        .catch(() => {
          UIService.removeButtonSpinner("photo_delete");
        });

      setTimeout(function() {
        window.location.reload();
      }, 300);
    },
    UploadConsent(tick) {
      let tickNr = null;
      if (tick == false) tickNr = 0;
      if (tick == true) tickNr = 1;

      let postContent = {
        value: tickNr,
        browser: navigator.userAgent,
      };
      setTimeout(function() {
        ApiService.post("Account/UpdateConsent", postContent).then(() => {});
      }, 1000);
    },
    deactivateAccount(pwd) {
      let postContent = {
        Type: "ALL",
        Password: pwd,
      };

      UIService.addButtonSpinner("deactivate_acc");
      ApiService.setHeader();
      ApiService.post("Account/DeactivateService", postContent)
        .then(() => {
          this.$store
            .dispatch(LOGOUT)
            .then(() => {
              this.$router.push({ name: "login" });
            })
            .catch((err) => console.log(err));
          UIService.removeButtonSpinner("deactivate_acc");
        })
        .catch(() => {
          UIService.removeButtonSpinner("deactivate_acc");
        });
    },
    socialNetworksFirstStep(type, action) {
      const rootUrl = window.location.protocol + "//" + window.location.host;
      UIService.addButtonSpinner(type + "_actdeact");

      if (type == "F") {
        let redirectUrl = rootUrl + "/?profile-facebook-" + action;
        window.location =
          Vue.appConfig.FACEBOOK_URL + redirectUrl + "&scope=email";
        UIService.removeButtonSpinner(type + "_actdeact");
      } else if (type == "G") {
        this.$gapi
          .login()
          .then(({ currentUser, hasGrantedScopes }) => {
            if (currentUser) {
              if (hasGrantedScopes) {
                const user = this.$gapi.getUserData();
                if (user != null) {
                  this.serviceID = user.id;
                  this.serviceEmail = user.email;
                  UIService.removeButtonSpinner(type + "_actdeact");
                  if (action === "deactivate") this.deactivateService("G");
                  else if (action === "activate") this.activateService("G");
                }
              } else {
                console.log(
                  "The current google user does not have a guaranteed scope",
                );
              }
            } else {
              console.log("The current user is not sign-in google account");
            }
            UIService.removeButtonSpinner(type + "_actdeact");
          })
          .catch(() => {
            UIService.removeButtonSpinner(type + "_actdeact");
          });
      }
    },
    activateService(type) {
      let postContent = {
        Type: type,
        Email: this.serviceEmail,
        ServiceId: this.serviceID,
      };

      UIService.addButtonSpinner(type + "_actdeact");
      ApiService.setHeader();
      ApiService.post("Account/ActivateService", postContent)
        .then(() => {
          UIService.removeButtonSpinner(type + "_actdeact");
          setTimeout(
            function() {
              this.fetchData();
              window.location = window.location.origin + "/AccountProfile";
            }.bind(this),
            300,
          );
        })
        .catch((err) => {
          UIService.removeButtonSpinner(type + "_actdeact");
          console.log(err);
          window.location = window.location.origin + "/AccountProfile";
        });
    },
    deactivateService(type) {
      let postContent = {
        Type: type,
        ServiceId: this.serviceID,
      };

      UIService.addButtonSpinner(type + "_actdeact");
      ApiService.setHeader();
      ApiService.post("Account/DeactivateService", postContent)
        .then(() => {
          UIService.removeButtonSpinner(type + "_actdeact");
          setTimeout(
            function() {
              this.fetchData();
              window.location = window.location.origin + "/AccountProfile";
            }.bind(this),
            300,
          );
        })
        .catch((err) => {
          UIService.removeButtonSpinner(type + "_actdeact");
          console.log(err);
          window.location = window.location.origin + "/AccountProfile";
        });
    },
    changeRequest(p, pwd, reqID) {
      let postContent = { Password: pwd };
      let buttonID;
      switch (reqID) {
        case 0:
          postContent.Email = p;
          this.codeSmsEmail = "email";
          buttonID = "email_changeReq";
          this.verifyBySmsText = false;
          break;
        case 1:
          postContent.PhoneNumber = encodeURIComponent(p);
          this.codeSmsEmail = "sms";
          buttonID = "phone_changeReq";
          this.verifyBySmsText = true;
          break;
      }

      UIService.addButtonSpinner(buttonID);
      ApiService.setHeader();
      ApiService.post("Account/ChangeRequest", postContent)
        .then(() => {
          this.showSecCode = true;
          this.showSec1 = false;
          this.showSec2 = false;
          UIService.removeButtonSpinner(buttonID);
        })
        .catch(() => {
          UIService.removeButtonSpinner(buttonID);
        });
    },
    changeConfirmCode(code, SmsOrEmail) {
      this.errors = [];
      UIService.addButtonSpinner("code_confirm");
      if (SmsOrEmail === "sms") {
        ApiService.setHeader();
        ApiService.post("Account/ChangePhoneNumber", { Code: code })
          .then(() => {
            this.showSecMain = true;
            this.showSecCode = false;
            UIService.removeButtonSpinner("code_confirm");

            setTimeout(function() {
              window.location.reload();
            }, 300);
          })
          .catch(() => {
            UIService.removeButtonSpinner("code_confirm");
          });
      } else if (SmsOrEmail === "email") {
        ApiService.setHeader();
        ApiService.post("Account/ChangeEmail", { Code: code })
          .then(() => {
            JwtService.saveUsername(this.email);
            this.showSecMain = true;
            this.showSecCode = false;
            UIService.removeButtonSpinner("code_confirm");

            setTimeout(function() {
              window.location.reload();
            }, 300);
          })
          .catch((response) => {
            this.errors.push(response.response.data.error);
            UIService.removeButtonSpinner("code_confirm");
          });
      }
    },
    sendResetPWDMail(mail) {
      let postContent = { Email: mail, Type: "P" };

      UIService.addButtonSpinner("reset_pwd");
      ApiService.setHeader();
      ApiService.post("Account/ChangeRequest", postContent)
        .then(() => {
          this.showSecPWDCFM = true;
          this.showSecMain = false;
          UIService.removeButtonSpinner("reset_pwd");
        })
        .catch(() => {
          UIService.removeButtonSpinner("reset_pwd");
        });
    },
    okButtonPWD() {
      this.showSecPWDCFM = false;
      this.showSecMain = true;

      setTimeout(function() {
        window.location.reload();
      }, 300);
    },
    fetchData() {
      ApiService.setHeader();
      ApiService.get("Account", "Profile").then(({ data }) => {
        this.title = this.$i18n.t("ACCOUNTPROFILE.TITLE");
        this.oldEmail = data.Email;
        this.oldPhone = data.Phone;
        this.photo = data.Photo;
        if (data.PhotoConsent == 0) this.photoConsent = false;
        if (data.PhotoConsent == 1) this.photoConsent = true;
        this.photoConsentVisible = data.PhotoConsentVisible;
        this.connectedAccounts = data.ConnectedAccounts;
        this.titlePhoto = this.$i18n.t("ACCOUNTPROFILE.TITLEPHOTO");

        this.havePWD = data.HavePassword;
        this.facebookID = data.FacebookId;
        this.googleID = data.GoogleId;
        this.appleID = data.AppleId;
      });
    },
  },
};
</script>
