<template>
  <b-col xs="12" sm="10" lg="10">
    <b-card
      v-show="serviceMessagesVisible"
      class="mb-3 text-center"
      text-variant="black"
      style="border-style: hidden; background-color: var(--company-color) !important"
    >
      <b-card-body>
        <!-- Iterate over each message in the serviceMessages array -->
        <div
          class="row justify-content-center"
          v-for="(message, index) in serviceMessages"
          :key="index"
        >
          <div class="col text-center">
            <!-- Display each message in a centered span -->
            <span class="mt-3 poschodoch-homepage-text">
              {{ message.message }}
            </span>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-card
      text-variant="black"
      style="background-color: rgba(255, 255, 255, 0.85)"
    >
      <b-card-body>
        <!--begin::Body-->
        <div class="kt-login__body">
          <!--begin::Signin-->
          <div class="kt-login__form">
            <div class="kt-login__title">
              <h3 v-show="!showSecDelete">{{ $t("AUTH.UNITS.TITLE") }}</h3>
            </div>

            <!--begin::Form-->
            <b-form class="kt-form mt-4" @submit.stop.prevent="onSubmit">
              <b-form-group v-show="!showSecDelete">
                <div v-if="items && items.length > 0" class="text-center">
                  <template>
                    <!-- errors -->
                    <div
                      role="alert"
                      v-if="errors && errors.length"
                      v-bind:class="{ show: errors && errors.length }"
                      class="alert fade alert-danger mt-2 mb-5"
                      style="flex-direction: column"
                    >
                      <div
                        class="alert-text"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                    <!-- errors -->
                  </template>
                  <b-table
                    hover
                    :items="items"
                    :fields="fields"
                    size="md"
                    class="table-white poschodoch-units-table"
                    @row-clicked="tableRowClickHandler"
                    :tbody-tr-class="pointerRowClass"
                  >
                    <template v-slot:cell(ConnectedAccounts)="data">
                      <a
                        href="javascript:void(0)"
                        v-if="data.value > 1"
                        v-on:click="
                          selectUnitWithTxt(
                            data.item.UnitId,
                            data.item.PortalId,
                            data.item.UnitType,
                          )
                        "
                        class="poschodoch-icon-link p-2 d-flex"
                      >
                        <i class="flaticon-users-1 poschodoch-icon-medium"></i>
                        {{ data.value }}
                      </a>
                    </template>
                    <template v-slot:cell(UnitId)="data">
                      <b-button
                        size="sm"
                        variant="primary"
                        :id="'unit_button_' + data.value"
                        v-on:click="
                          selectUnit(
                            data.value,
                            data.item.PortalId,
                            data.item.UnitType,
                          )
                        "
                        v-if="!data.item.Selected"
                      >
                        {{ $t("UNIT_LIST.SELECT_BUTTON") }}
                      </b-button>
                    </template>
                    <template v-slot:cell(Icon)="data">
                      <span :class="data.value"></span>
                    </template>
                    <template v-slot:cell(Mobile)="data">
                      <!-- <div class="d-flex flex-row align-items-start w-100">
                        <div class="d-flex align-items-start mr-3">
                          <span :class="data.item.Icon"></span>
                        </div>
                        <div class="d-flex flex-column align-items-start w-100 col-6">
                          <span style="font-weight: 600;">{{ data.item.Type }}</span>
                          <span style="font-weight: 600; opacity: 0.9;">{{ fields[3].label}}</span>
                          <span style="font-weight: 600; opacity: 0.9;">{{ fields[4].label}}</span>
                        </div>
                        <div class="d-flex flex-column align-items-start w-100 col-3">
                          <span>{{ data.item.VarSymb }}</span>
                          <span>{{ data.item.Address }}</span>
                          <span>{{ data.item.PropertyCompany }}</span>
                        </div>
                        <div class="d-flex flex-column align-items-end w-100 col-3">
                          <b-button
                            size="sm"
                            variant="primary"
                            :id="'unit_button_' + data.item.UnitId"
                            v-on:click="selectUnit(data.item.UnitId, data.item.PortalId, data.item.UnitType)"
                            v-if="!data.item.Selected"
                          >{{ $t("UNIT_LIST.SELECT_BUTTON") }}</b-button>
                        </div>
                      </div> -->
                      <div class="d-flex flex-row align-items-start w-100">
                        <div class="d-flex align-items-start mr-3 mt-2">
                          <span :class="data.item.Icon"></span>
                        </div>
                        <div class="d-flex flex-column align-items-start w-100">
                          <div
                            class="
                              d-flex
                              w-100
                              flex-row
                              justify-content-between
                            "
                          >
                            <div
                              class="
                                d-flex
                                flex-row
                                align-items-end
                                poschodoch-mobile-right
                                mr-1
                              "
                            >
                              <span class="ml-2" style="font-weight: 600">
                                {{ data.item.Type }}
                              </span>
                              <span class="ml-3">{{ data.item.VarSymb }}</span>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-column
                                align-items-end
                                poschodoch-mobile-right
                                mr-1
                              "
                            >
                              <b-button
                                size="sm"
                                variant="primary"
                                :id="'unit_button_' + data.item.UnitId"
                                v-on:click="
                                  selectUnit(
                                    data.item.UnitId,
                                    data.item.PortalId,
                                    data.item.UnitType,
                                  )
                                "
                                v-if="!data.item.Selected"
                              >
                                {{ $t("UNIT_LIST.SELECT_BUTTON") }}
                              </b-button>
                            </div>
                          </div>
                          <div
                            class="
                              d-flex
                              w-100
                              flex-row
                              justify-content-between
                              mt-3
                            "
                          >
                            <div>
                              <span
                                class="ml-2"
                                style="font-weight: 600; opacity: 0.9"
                              >
                                {{ fields[3].label }}
                              </span>
                              <span class="ml-3">{{ data.item.Address }}</span>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-column
                                align-items-end
                                poschodoch-mobile-right
                                mr-1
                              "
                            >
                              <a
                                href="javascript:void(0)"
                                v-if="data.item.ConnectedAccounts > 1"
                                v-on:click="
                                  selectUnitWithTxt(
                                    data.item.UnitId,
                                    data.item.PortalId,
                                    data.item.UnitType,
                                  )
                                "
                                class="poschodoch-icon-link p-2 d-flex"
                              >
                                <i
                                  class="
                                    flaticon-users-1
                                    poschodoch-icon-medium
                                  "
                                ></i>
                                {{ data.item.ConnectedAccounts }}
                              </a>
                            </div>
                          </div>
                          <div
                            class="
                              d-flex
                              w-100
                              flex-row
                              justify-content-between
                              mt-3
                            "
                          >
                            <div
                              class="
                                d-flex
                                flex-row
                                align-items-end
                                poschodoch-mobile-right
                                mr-1
                              "
                            >
                              <span
                                class="ml-2"
                                style="font-weight: 600; opacity: 0.9"
                              >
                                {{ fields[4].label }}
                              </span>
                              <span class="ml-3">
                                {{ data.item.PropertyCompany }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>

                <div v-if="items && items.length == 0">
                  <b-alert show variant="danger">
                    Užívateľ nemá priradený žiadny priestor
                  </b-alert>
                </div>

                <!--begin::Divider-->
                <div class="kt-login__divider">
                  <div class="kt-divider">
                    <span></span>
                  </div>
                </div>
                <!--end::Divider-->

                <b-col lg="4" sm="12" class="mx-auto">
                  <div class="kt-login__options mt-4" v-if="ButtonsVisible">
                    <b-button
                      id="units_logout"
                      block
                      v-on:click="onRegister()"
                      variant="secondary"
                    >
                      {{ $t("AUTH.GENERAL.SIGNUP_ADDSPACE") }}
                    </b-button>
                  </div>
                  <div class="kt-login__options mt-4">
                    <b-button
                      id="units_logout"
                      block
                      v-on:click="onLogout()"
                      variant="secondary"
                    >
                      {{ $t("AUTH.GENERAL.SIGNOUT_BUTTON") }}
                    </b-button>
                  </div>
                  <div class="kt-login__options mt-4" v-if="ButtonsVisible">
                    <b-button
                      id="units_logout"
                      block
                      v-on:click="onDeactivate()"
                      variant="secondary"
                    >
                      {{ $t("ACCOUNTPROFILE.BUTTON.DELETE_ACCOUNT") }}
                    </b-button>
                  </div>
                </b-col>
              </b-form-group>
              <KTPortlet v-show="showSecDelete">
                <template v-slot:title>
                  <h3>{{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SEC5.TITLE") }}</h3>
                </template>
                <template v-slot:body>
                  <div class="row justify-content-center">
                    <div class="col-lg-9 col-sm-12">
                      <div
                        class="d-flex mb-4 justify-content-center"
                        style="width: 100%"
                      >
                        <span>
                          {{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SEC5.TEXT") }}
                        </span>
                      </div>
                      <div class="d-flex mb-4 justify-content-center">
                        <div class="col-2 text-right mt-2">
                          <span
                            style="width: 14rem; margin-top: 0.65rem !important"
                          >
                            {{ $i18n.t("ACCOUNTPROFILE.PWD") }}
                          </span>
                        </div>
                        <div class="col-8">
                          <b-form-input
                            id="input-Password3"
                            :state="validatePWD"
                            v-model="password"
                            :type="visibilityPWD"
                            class="ml-3 mr-3"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="validatePWD"
                            class="ml-3"
                          >
                            {{ $i18n.t("ACCOUNTPROFILE.VALIDATION.PWD") }}
                          </b-form-invalid-feedback>
                        </div>
                        <div class="col-2 mt-2">
                          <a
                            @click="showPassword()"
                            style="opacity: 0.9"
                            class="notunderlined ml-0"
                            v-if="visibilityPWD == 'password'"
                          >
                            <i
                              class="fas fa-eye-slash poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            @click="hidePassword()"
                            style="opacity: 0.9"
                            class="notunderlined ml-0"
                            v-if="visibilityPWD == 'text'"
                          >
                            <i class="fas fa-eye poschodoch-icon-medium"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <b-button
                          @click="deactivateAccount(password)"
                          id="deactivate_acc"
                          variant="danger"
                          :disabled="!validatePWD || disabledButton"
                          style="
                            width: 22rem;
                            height: fit-content;
                            color: #ffffff !important;
                          "
                        >
                          {{ DeactiveateText }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </template>
              </KTPortlet>
            </b-form>
            <!--end::Form-->
          </div>
          <!--end::Signin-->
        </div>
        <!--end::Body-->
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import { mapState } from "vuex";
import { CHANGE_UNIT, LOGOUT } from "@/store/auth.module";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "units",
  components: {
    KTPortlet,
  },
  data() {
    return {
      items: null,
      showSecDelete: false,
      password: null,
      visibilityPWD: "password",
      intervalid: null,
      disabledButton: false,
      serviceMessages: [], // Array to store multiple service messages
      serviceMessagesVisible: false,
      ButtonsVisible: false,
      DeactiveateText: this.$i18n.t("ACCOUNTPROFILE.BUTTON.DELETE_ACCOUNT"),
      fields: [
        {
          key: "Icon",
          label: "",
          class:
            "poschodoch-icon-medium poschodoch-icon-column table-no-mobile",
          sortable: false,
        },
        {
          key: "VarSymb",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.VAR_SYMBOL"),
          sortable: true,
        },
        {
          key: "Type",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.TYPE"),
          sortable: true,
        },
        {
          key: "Address",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.ADDRESS"),
          sortable: true,
        },
        {
          key: "PropertyCompany",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.PROPERTY_COMPANY"),
          sortable: true,
        },
        {
          key: "UnitId",
          label: "",
          sortable: false,
          class: "table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Selected == true) {
              item._rowVariant = "row-active";
            }
            return value;
          },
        },
        {
          key: "ConnectedAccounts",
          label: "",
          sortable: false,
          class: "table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Selected == true) {
              item._rowVariant = "row-active";
            }
            return value;
          },
        },
        {
          key: "Mobile",
          label: "",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
    };
  },
  mounted: function() {
    //set default brand color
    UIService.changeSiteColorCustomer();
    this.loadServiceMessages();
    // get list of units
    ApiService.setHeader();
    ApiService.get("Auth/UnitList").then(({ data }) => {
      this.transformData(data);
      this.items = data;
      if (this.items.length === 0) {
        this.ButtonsVisible = true;
      }
    });
  },
  methods: {
    async loadServiceMessages() {
      try {
        this.serviceMessages = [];
        const response = await fetch(
          "/assets/media/files/service_messages.json",
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        this.serviceMessages = data.serviceMessages;

        if (this.serviceMessages) {
          this.serviceMessagesVisible = true;
        }
      } catch (error) {
        console.error(
          "There was a problem with the fetch operation (service_message):",
          error,
        );
      }
    },
    showPassword() {
      this.visibilityPWD = "text";
    },
    hidePassword() {
      this.visibilityPWD = "password";
    },
    tableRowClickHandler(record) {
      this.selectUnit(record.UnitId, record.PortalId, record.UnitType);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        //set icon
        if (items[i].UnitType == 0) {
          items[i].Icon = "poschodoch-icon-byt";
        } else {
          items[i].Icon = "flaticon-home-2";
        }

        items[i].Mobile = {
          Icon: items[i].Icon,
          VarSymb: items[i].VarSymb,
          Type: items[i].Type,
          Address: items[i].Address,
          PropertyCompany: items[i].PropertyCompany,
          UnitId: items[i].UnitId,
          ConnectedAccounts: items[i].ConnectedAccounts,
        };
      }
    },
    selectUnit(unitId, portalId, unitType) {
      let obj = {
        unitID: unitId,
        portalID: portalId,
        unitType: unitType,
      };
      UIService.addButtonSpinner("unit_button_" + unitId);
      this.$store
        .dispatch(CHANGE_UNIT, obj)
        .then(() => {
          // podmienky zak
          let paramsZ = "?Type=ZakTerms";
          ApiService.setHeader();
          ApiService.get("Account", "CheckProperties" + paramsZ).then(
            ({ data }) => {
              if (data.Signed === 0) {
                this.$router.push({
                  name: "registrationAgreementNew",
                  query: { type: "Z" },
                  params: { price: data.Price },
                });
              } else {
                JwtService.loginRoute();
              }
            },
          );
        })
        .catch(() => {
          UIService.removeButtonSpinner("unit_button_" + unitId);
        });
    },
    selectUnitWithTxt(unitId, portalId, unitType) {
      let obj = {
        unitID: unitId,
        portalID: portalId,
        unitType: unitType,
      };

      this.$store.dispatch(CHANGE_UNIT, obj).then(() => {
        this.$router.push({ name: "domus-connected-accounts" });
      });
    },
    onDeactivate() {
      this.showSecDelete = true;
    },
    startTimer() {
      let i = 1;
      this.intervalid = setInterval(() => {
        if (i == 5) {
          clearInterval(this.intervalid);
          this.$router.push({ name: "login" });
        }
        this.DeactiveateText =
          this.$i18n.t("ACCOUNTPROFILE.REDIRECT") + (5 - i);
        i++;
      }, 1000);
      //this.timerId = this.intervalid._id
    },
    deactivateAccount(password) {
      let postContent = {
        Type: "All",
        Password: password,
      };
      this.disabledButton = true;
      ApiService.setHeader();
      ApiService.post("Account/DeactivateService", postContent)
        .then(() => {
          this.startTimer();
        })
        .catch((err) => {
          console.log(err);
          window.location = window.location.origin + "/Home";
        });
    },
    onRegister() {
      this.$router.replace({
        name: "registrationCode",
        params: { haveAccount: true },
      });
    },
    onLogout() {
      UIService.addButtonSpinner("units_logout");

      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }))
        .catch(() => {
          UIService.removeButtonSpinner("units_logout");
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    validatePWD() {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(
        this.password,
      );
    },
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    },
  },
};
</script>
