<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-show="hasItems">
          <template v-slot:body v-bind:title="title">
            <b-container fluid>
              <b-row>
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div
                    class="d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center"
                  >
                    <ul
                      class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills m-0 mr-0 mr-md-3"
                      role="tablist"
                    >
                      <li class="nav-item" v-for="index in years" :key="index">
                        <a
                          @click="fetchData(index, month)"
                          class="nav-link"
                          :class="{ active: index == year }"
                        >
                          {{ index }}
                        </a>
                      </li>
                    </ul>
                    <div
                      class="poschodoch-month-dropdown d-flex align-items-center mt-3 mt-md-0"
                    >
                      <label
                        for="daily-consumption-dropdown-month"
                        class="m-0 mr-3"
                      >
                        {{ $i18n.t("DAILY_CONSUMPTION.FILTERMONTH") }}
                      </label>
                      <button
                        id="previous-month"
                        type="button"
                        class="poschodoch-previous-btn page-link mr-3 mr-md-2"
                        v-on:click="previousMonth(month)"
                        :disabled="isPreviousBtnDisabled"
                      >
                        ‹
                      </button>
                      <b-dropdown
                        id="daily-consumption-dropdown-month"
                        :text="getFormatService().getMonthName(month)"
                        variant="light"
                      >
                        <b-dropdown-item
                          v-for="month in months"
                          :key="month"
                          v-on:click="fetchData(year, month)"
                        >
                          {{ getFormatService().getMonthName(month) }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <button
                        id="next-month"
                        type="button"
                        class="poschodoch-next-btn page-link ml-3 ml-md-2"
                        v-on:click="nextMonth(month)"
                        :disabled="isNextBtnDisabled"
                      >
                        ›
                      </button>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center"
                  >
                    <h4>{{ title }}</h4>
                  </div>
                  <div
                    class="d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center"
                  >
                    <ul
                      class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills m-0 mr-0 mr-md-3"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          @click="toggleGraf('line')"
                          class="nav-link"
                          :class="{ active: 'line' == graf }"
                        >
                          {{ labelLine }}
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          @click="toggleGraf('bar')"
                          class="nav-link"
                          :class="{ active: 'bar' == graf }"
                        >
                          {{ labelBar }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row" v-for="section in sections" :key="section.key">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet :title="section.subTitle">
          <template v-slot:toolbar v-if="!section.isEmpty">
            <TableContextMenu
              :functionCSV="section.downloadCSV"
            ></TableContextMenu>
          </template>
          <template v-slot:body>
            <b-container fluid v-if="section.isEmpty" style="color:#616365;">
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-else>
              <b-row>
                <b-col lg="6" md="12" sm="12" class="mb-3">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{ $t("MONTHLY_CONSUMPTION.TABLE.NUMBER") }}</th>
                          <th v-if="section.key === 'heat'">
                            {{ $t("MONTHLY_CONSUMPTION.TABLE.ROOM") }}
                          </th>
                          <th>
                            {{ $t("MONTHLY_CONSUMPTION.TABLE.VALUEFROM") }}
                          </th>
                          <th>{{ $t("MONTHLY_CONSUMPTION.TABLE.VALUETO") }}</th>
                          <th>
                            {{ $t("MONTHLY_CONSUMPTION.TABLE.CONSUMPTION") }}
                          </th>
                          <th v-if="section.key === 'heat'">
                            {{ $t("MONTHLY_CONSUMPTION.TABLE.COEFFICIENT") }}
                          </th>
                          <th v-if="section.key === 'heat'">
                            {{
                              $t(
                                "MONTHLY_CONSUMPTION.TABLE.CONSUMPTION_COEFFICIENT",
                              )
                            }}
                          </th>
                          <th>
                            {{
                              $t("MONTHLY_CONSUMPTION.TABLE.INITCONSUMPTION")
                            }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in section.dataItems"
                          :key="index"
                          :class="{
                            'font-weight-bold': isSummaryRow(
                              index,
                              section.dataItems.length,
                            ),
                          }"
                        >
                          <td>{{ item.Number }}</td>
                          <td v-if="section.key === 'heat'">{{ item.Room }}</td>
                          <td>{{ item.ValueFrom }}</td>
                          <td>{{ item.ValueTo }}</td>
                          <td>{{ item.Consumption }}</td>
                          <td v-if="section.key === 'heat'">
                            {{ item.Coefficient }}
                          </td>
                          <td v-if="section.key === 'heat'">
                            {{ item.ConsumptionCoefficient }}
                          </td>
                          <td>{{ item.InitialConsumption }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <b-row class="mt-3">
                      <b-col>
                        <div
                          v-for="compItem in section.compareItems"
                          :key="compItem.Type"
                          class="comp-item-widget"
                        >
                          <div
                            class="percentage-frame"
                            v-if="
                              (compItem.TotalApartmentConsumptionComparePercentage !==
                                null &&
                                compItem.TotalApartmentConsumptionComparePercentage !==
                                  0) ||
                                (compItem.TotalApartmentConsumptionLastYearPercentage !==
                                  null &&
                                  compItem.TotalApartmentConsumptionLastYearPercentage !==
                                    0)
                            "
                          >
                            <span class="compare-text-bold">
                              {{
                                $t("MONTHLY_CONSUMPTION.TABLE.YOUR_CONSUMPTION")
                              }}
                            </span>
                            <p
                              v-if="
                                compItem.TotalApartmentConsumptionComparePercentage !==
                                  null &&
                                  compItem.TotalApartmentConsumptionComparePercentage !==
                                    0
                              "
                              :class="{
                                'percentage-negative':
                                  compItem.TotalApartmentConsumptionComparePercentage <
                                  0,
                                'percentage-positive':
                                  compItem.TotalApartmentConsumptionComparePercentage >=
                                  0,
                              }"
                            >
                              {{
                                Math.abs(
                                  compItem.TotalApartmentConsumptionComparePercentage,
                                )
                              }}%
                            </p>
                            <p
                              v-if="
                                compItem.TotalApartmentConsumptionComparePercentage !==
                                  null &&
                                  compItem.TotalApartmentConsumptionComparePercentage !==
                                    0
                              "
                              class="compare-text"
                            >
                              {{
                                compItem.TotalApartmentConsumptionCompareText
                              }}
                            </p>
                            <p
                              v-if="
                                compItem.TotalApartmentConsumptionLastYearPercentage !==
                                  null &&
                                  compItem.TotalApartmentConsumptionLastYearPercentage !==
                                    0
                              "
                              :class="{
                                'percentage-negative':
                                  compItem.TotalApartmentConsumptionLastYearPercentage <
                                  0,
                                'percentage-positive':
                                  compItem.TotalApartmentConsumptionLastYearPercentage >=
                                  0,
                              }"
                            >
                              {{
                                Math.abs(
                                  compItem.TotalApartmentConsumptionLastYearPercentage,
                                )
                              }}%
                            </p>
                            <p
                              v-if="
                                compItem.TotalApartmentConsumptionLastYearPercentage !==
                                  null &&
                                  compItem.TotalApartmentConsumptionLastYearPercentage !==
                                    0
                              "
                              class="compare-text"
                            >
                              {{
                                compItem.TotalApartmentConsumptionLastYearText
                              }}
                            </p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col lg="6" md="12" sm="12">
                  <div
                    v-if="section.chartOptions"
                    class="poschodoch-consumption-graph"
                    style="width: 100%; overflow-x: auto"
                  >
                    <Chart
                      ref="section.chartRef"
                      v-for="c in charts"
                      :key="c"
                      :options="section.chartOptions"
                      height="400"
                      width="0"
                    ></Chart>
                  </div>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid v-if="!section.chartOptions">
              <Loader />
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet>
          <template v-slot:body>
            <b-container fluid v-if="description">
              <b-row class="justify-content-end">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";

// Import icons

export default {
  name: "monthly-consumption-overview",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      tableSums: {},
      year: null,
      years: [],
      yearFrom: null,
      yearTo: null,
      month: null,
      months: [],
      monthFrom: null,
      monthTo: null,
      inputDateFrom: null,
      inputDateTo: null,
      graf: "bar",
      charts: [1],
      sections: this.initializeSections(),
      labelLine: this.$i18n.t("MONTHLY_CONSUMPTION_MORE_YEARS.LINE"),
      labelBar: this.$i18n.t("MONTHLY_CONSUMPTION_MORE_YEARS.BAR"),
      icons: {
        cold_Water: "/assets/media/icons/cold_water.jpg",
        hot_Water: "/assets/media/icons/hot_water.jpg",
        heat: "/assets/media/icons/heat.jpg",
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    hasItems() {
      return this.sections.some(
        (section) => section.items || section.items === null,
      );
    },
    isPreviousBtnDisabled() {
      return !this.isMonthInRange(this.month - 1);
    },
    isNextBtnDisabled() {
      return !this.isMonthInRange(this.month + 1);
    },
  },
  methods: {
    getRebrandColorGraf(sectionKey) {
      return FormatService.getRebrandColorGraf(sectionKey);
    },
    initializeSections() {
      const legendsTemplate = (id, labelKey, checked, onChange) => ({
        id,
        label: this.$i18n.t(labelKey),
        checked,
        onChange,
      });
      const sectionTemplate = (key, subTitle, downloadCSV, legends) => ({
        key,
        subTitle,
        items: null,
        compareItems: null,
        dataItems: null,
        chartOptions: null,
        isEmpty: null,
        chartRef: `${key}-chart`,
        legends,
        downloadCSV,
      });
      return [
        sectionTemplate("cold_Water", null, this.downloadColdCSV, [
          legendsTemplate(
            "checkKorigS",
            "MONTHLY_CONSUMPTION.SHOW_CORRECTED",
            this.korigovaneS,
            this.toggleKorigovaneS,
          ),
        ]),
        sectionTemplate("hot_Water", null, this.downloadhot_WaterCSV, [
          legendsTemplate(
            "checkKorigT",
            "MONTHLY_CONSUMPTION.SHOW_CORRECTED",
            this.korigovaneT,
            this.toggleKorigovaneT,
          ),
        ]),
        sectionTemplate("heat", null, this.downloadHeatCSV, [
          legendsTemplate(
            "checkClimaticConditions",
            "MONTHLY_CONSUMPTION_MORE_YEARS.CHART_HEAT.TAKE_CLIMATIC_CONDITIONS",
            this.takeClimaticConditions,
            this.toggleClimaticConditions,
          ),
          legendsTemplate(
            "checkKorigU",
            "MONTHLY_CONSUMPTION.SHOW_CORRECTED",
            this.korigovaneU,
            this.toggleKorigovaneU,
          ),
        ]),
      ];
    },
    async fetchData(selectedYear = null, selectedMonth = null) {
      this.year = selectedYear || this.year;
      this.month = selectedMonth || this.month;
      this.resetSectionData();
      await Promise.all(
        this.sections.map((section) => this.fetchSectionData(section)),
      );
    },
    isSummaryRow(index, length) {
      return length > 1 && index === length - 1;
    },
    resetSectionData() {
      this.sections.forEach((section) => {
        section.items = null;
        section.chartOptions = null;
        this.tableSums[section.key] = { Consumption: 0, InitialConsumption: 0 };
      });
    },
    async fetchSectionData(section) {
      try {
        const chartData = await this.fetchChartData(section);
        this.transformData(section, chartData);
        const tableData = await this.fetchTableData(section);
        this.transformTableData(section, tableData);
      } catch (error) {
        section.items = undefined;
        console.error(error);
      }
    },
    async fetchChartData(section) {
      const parameters = this.buildParameters(section.key);
      const response = await ApiService.get(
        "Flat",
        `MonthlyConsumptionOverview${parameters}`,
      );
      return response.data;
    },
    async fetchTableData(section) {
      const parameters = this.buildParameters(section.key, true);
      const response = await ApiService.get(
        "Flat",
        `MonthlyConsumptionTableOverView${parameters}`,
      );
      return response.data;
    },
    buildParameters(type, isTable = false) {
      let mappedType = "";
      if (type === "cold_Water") {
        mappedType = "S";
      } else if (type === "hot_Water") {
        mappedType = "T";
      } else if (type === "heat") {
        mappedType = "U";
      }
      let parameters = `?type=${mappedType}&menuId=${this.menuId}`;
      if (this.isModeEnabled(type)) parameters += "&mode=1";
      if (this.year != null) parameters += `&year=${this.year}`;
      if (this.year != null && this.month != null) {
        const dateFrom = `${this.year}-01-01`;
        const lastDayOfMonth = new Date(this.year, this.month, 0).getDate();
        const dateTo = `${this.year}-${String(this.month).padStart(
          2,
          "0",
        )}-${String(lastDayOfMonth).padStart(2, "0")}`;
        parameters += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
        if (isTable)
          parameters = `?month=${this.year}${String(this.month).padStart(
            2,
            "0",
          )}&type=${mappedType}&menuId=${this.menuId}`;
      }
      return parameters;
    },
    isModeEnabled(type) {
      switch (type) {
        case "cold_Water":
          return this.korigovaneS;
        case "hot_Water":
          return this.korigovaneT;
        case "heat":
          return this.korigovaneU || this.takeClimaticConditions;
        default:
          return false;
      }
    },
    transformData(section, data) {
      section.subTitle = data.Subtitle;
      section.items = data.Consumption;
      this.updateMainData(data);

      // Group data by Label and aggregate consumption values
      const groupedData = data.Consumption.reduce((acc, item) => {
        if (!acc[item.Label]) {
          acc[item.Label] = 0;
        }
        acc[item.Label] += parseFloat(item.Consumption) || 0;
        return acc;
      }, {});

      // Sort the data by month
      const labels = Object.keys(groupedData).sort(
        (a, b) => new Date(a) - new Date(b),
      );
      const consumptionData = labels.map((label) => ({
        x: label,
        y: groupedData[label].toFixed(3),
      }));

      const isEmpty = consumptionData.every((data) => data.y === null);

      section.chartOptions = this.createChartOptions(
        labels,
        consumptionData,
        data.Year,
        section.key,
      );
      section.isEmpty = isEmpty;
    },
    updateMainData(data) {
      if (!this.title) {
        this.title = data.Title;
        this.description = data.Description;
      }
      this.year = data.Year;
      this.yearFrom = data.YearFrom;
      this.yearTo = data.YearTo;
      this.monthFrom = data.MonthFrom;
      this.monthTo = data.MonthTo;
      this.inputDateFrom = data.DateFrom;
      this.inputDateTo = data.DateTo;
      this.setYearRange(data.YearFrom, data.YearTo);
      this.setMonthRange(this.year, this.month);
      this.month = data.Month;
    },
    createChartOptions(labels, consumptionData, year, sectionKey) {
      const chartData = [
        {
          data: consumptionData,
          label: year,
          fill: false,
          borderColor: FormatService.getRebrandColorGraf(
            sectionKey.split("_")[0],
          ),
          backgroundColor: FormatService.getRebrandColorGraf(
            sectionKey.split("_")[0],
          ),
        },
      ];
      return {
        type: this.graf,
        data: { datasets: chartData, labels: labels },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: { animateScale: true, animateRotate: true },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: FormatService.getRebrandColorGraf(
              sectionKey.split("_")[0],
            ),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem) =>
                `${this.$i18n.t("MONTHLY_CONSUMPTION.CHART_WATER.AX_X")}: ${
                  tooltipItem[0].label
                }`,
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    `MONTHLY_CONSUMPTION_MORE_YEARS.CHART_${sectionKey.toUpperCase()}.AX_X`,
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    `MONTHLY_CONSUMPTION_MORE_YEARS.CHART_${sectionKey.toUpperCase()}.AX_Y`,
                  ),
                },
                ticks: { min: 0 },
              },
            ],
          },
        },
      };
    },
    transformTableData(section, data) {
      section.dataItems = data.Consumption.filter(
        (item) => item.Type !== "COMP",
      ).map((item) => {
        const transformedItem = {
          ...item,
          ValueFrom: parseFloat(item.ValueFrom).toFixed(3),
          ValueTo: parseFloat(item.ValueTo).toFixed(3),
          Consumption: parseFloat(item.Consumption).toFixed(3),
          InitialConsumption: parseFloat(item.InitialConsumption).toFixed(3),
        };
        if (section.key === "heat") {
          transformedItem.ConsumptionCoefficient = (
            parseFloat(item.Consumption) * parseFloat(item.Coefficient)
          ).toFixed(3);
        }
        return transformedItem;
      }); //.filter(item => !isNaN(item.ValueFrom) && !isNaN(item.ValueTo) && !isNaN(item.Consumption) && !isNaN(item.InitialConsumption));

      section.compareItems = data.Consumption.filter(
        (item) => item.Type == "COMP",
      ).map((item) => ({
        ...item,
      }));

      if (section.dataItems.length > 1)
        this.calculateSums(section.key, section.dataItems);
    },
    calculateSums(sectionKey, dataItems) {
      const sums = dataItems.reduce(
        (acc, item) => {
          acc.Consumption += parseFloat(item.Consumption) || 0;
          if (sectionKey === "heat") {
            acc.ConsumptionCoefficient +=
              parseFloat(item.ConsumptionCoefficient) || 0;
          }
          acc.InitialConsumption += parseFloat(item.InitialConsumption) || 0;
          return acc;
        },
        { Consumption: 0, InitialConsumption: 0, ConsumptionCoefficient: 0 },
      );
      this.$set(this.tableSums, sectionKey, sums);
      dataItems.push({
        Type: "",
        Number: "",
        Room: "",
        ValueFrom: "",
        ValueTo: "",
        Consumption: sums.Consumption.toFixed(3),
        Coefficient: "",
        ConsumptionCoefficient:
          sectionKey === "heat" ? sums.ConsumptionCoefficient.toFixed(3) : "",
        InitialConsumption: sums.InitialConsumption.toFixed(3),
      });
    },
    toggleGraf(type) {
      this.graf = type;
      this.fetchData();
    },
    getFormatService() {
      return FormatService;
    },
    isMonthInRange(monthToCheck) {
      return monthToCheck != null && monthToCheck >= 1 && monthToCheck <= 12;
    },
    previousMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth - 1))
        this.fetchData(this.year, selectedMonth - 1);
    },
    nextMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth + 1))
        this.fetchData(this.year, selectedMonth + 1);
    },
    setYearRange(yearFrom, yearTo) {
      if (!this.years.length) {
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    setMonthRange(year, month = "") {
      this.months = [];
      this.year = year;
      if (year == this.yearFrom) {
        for (let i = this.monthFrom; i <= 12; i++) {
          this.months.push(i);
        }
      } else if (year == this.yearTo) {
        for (let i = 1; i <= this.monthTo; i++) {
          this.months.push(i);
        }
      } else {
        this.months = Array.from({ length: 12 }, (_, i) => i + 1);
      }
      if (!month) {
        this.month = this.months[this.months.length - 1];
      }
    },
    toggleKorigovaneS() {
      this.korigovaneS = !this.korigovaneS;
      this.fetchData();
    },
    toggleKorigovaneT() {
      this.korigovaneT = !this.korigovaneT;
      this.fetchData();
    },
    toggleKorigovaneU() {
      this.korigovaneU = !this.korigovaneU;
      this.fetchData();
    },
    toggleClimaticConditions() {
      this.takeClimaticConditions = !this.takeClimaticConditions;
      this.fetchData();
    },
    downloadColdCSV() {
      this.downloadCSV("cold_Water");
    },
    downloadhot_WaterCSV() {
      this.downloadCSV("hot_Water");
    },
    downloadHeatCSV() {
      this.downloadCSV("heat");
    },
    generateCSVContent(header, values) {
      const headerLine = header.join(";");
      const valueLines = values
        .map((valueArray) => valueArray.map((value) => `"${value}"`).join(";"))
        .join("\n");
      return `${headerLine}\n${valueLines}`;
    },
    downloadCSV(sectionKey) {
      const section = this.sections.find((s) => s.key === sectionKey);
      if (!section) {
        console.error(`Section with key ${sectionKey} not found.`);
        return;
      }
      const header = [
        this.$i18n.t("MONTHLY_CONSUMPTION.CHART_WATER.AX_X"),
        this.$i18n.t("MONTHLY_CONSUMPTION.TABLE.CONSUMPTION"),
      ];
      const values = section.items.map((item) => [
        item.Label,
        item.Consumption,
      ]);
      const csvContent = this.generateCSVContent(header, values);
      const fileName = `${section.subTitle}.csv`;
      FileService.downloadFile(fileName, csvContent, "text/csv");
    },
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
};
</script>

<style scoped>
.comp-item-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.percentage-frame {
  border: 5px solid var(--company-color);
  padding: 10px;
  border-radius: 30px;
  text-align: center;
  width: fit-content;
}

.percentage-frame .percentage {
  margin-top: 10px; /* Adjust the value as needed */
}

.percentage {
  font-size: 1.5em;
  font-weight: bold;
}

.percentage-negative {
  color: green;
  font-size: 1.5em;
  font-weight: bold;
}

.percentage-positive {
  color: red;
  font-size: 1.5em;
  font-weight: bold;
}

.compare-text {
  font-size: 1em;
}

.compare-text-bold {
  font-size: 1.5em;
  font-weight: bold;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.icon-container img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .poschodoch-consumption-graph {
    order: 2;
  }

  .table-responsive {
    order: 1;
  }
}

.icon-image {
  width: 32px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
}
</style>
