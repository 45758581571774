<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Identity takeover-->
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">{{ title }}</slot>
              </span>
            </h3>
          </template>
          <template v-slot:body>
            <!-- Filtre -->
            <div
              class="row d-flex flex-row mb-3 justify-content-md-start justify-content-center"
            >
              <div
                class="ml-md-2 mt-3 mt-md-0 d-flex flex-md-row flex-column col-12-xl col-12 mb-3"
              >
                <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                  <label for="customer" class="mr-1" style="font-weight: 500">
                    {{ $i18n.t("IDENTITY.LABEL.CUSTOMER") }}:
                  </label>
                  <b-form-select
                    id="customer"
                    v-model="inputCustomer"
                    :options="customerOptions"
                    @change="
                      fetchData(inputCustomer);
                      disableAccess = false;
                    "
                  ></b-form-select>
                </b-col>
                <span class="d-flex flex-row align-items-center mr-md-3 mr-0">
                  <label for="dF" class="mr-1" style="font-weight: 500">
                    {{ $i18n.t("REGLOGLIST.FROM") }}
                  </label>
                  <b-form-input
                    id="dF"
                    v-model="inputDateFrom"
                    @input="fetchData(inputCustomer, inputSearch)"
                    type="date"
                  ></b-form-input>
                </span>
                <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                  <label for="search" class="mr-1" style="font-weight: 500">
                    {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                  </label>
                  <b-form-input
                    v-model="inputSearch"
                    @input="fetchData(inputCustomer, inputSearch)"
                  ></b-form-input>
                </b-col>
              </div>
            </div>
            <!-- Filtre -->

            <!--Begin::Users -->
            <b-container
              fluid
              v-if="itemsUsers && (inputAccess == null || inputAccess == 'U')"
            >
              <b-row class="mt-3">
                <b-col sm="12" md="6" lg="6" style="align-self: flex-end">
                  <span class="poschodoch-identity-titles">
                    {{ $i18n.t("REGLOGLIST.TABLETITLE") }}
                  </span>
                </b-col>
                <b-col
                  class="poschodoch-rows-count"
                  v-if="itemsUsers.length > 10"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelectUsers"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPageUsers"
                      id="perPageSelectUsers"
                      size="sm"
                      :options="pageOptionsUsers"
                      @change="
                        getFormatService().formatSetPerPage(perPageUsers)
                      "
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                hover
                size="md"
                stacked="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="itemsUsers"
                :fields="fieldsUsers"
                :current-page="currentPageUsers"
                :per-page="perPageUsers"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="notranslate"
                :tbody-tr-class="pointerRowClass"
              ></b-table>
              <b-row
                v-if="itemsUsers && itemsUsers.length > 10"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPageUsers"
                    :total-rows="totalRowsUsers"
                    :per-page="perPageUsers"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-container>
            <!--End::Users -->

            <!--Begin::Description -->
            <!-- <b-container fluid v-if="itemsUsers || itemsObjects || itemsAdmins">
              <b-row class="justify-content-end">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container> -->
            <!--End::Description -->
          </template>
        </KTPortlet>
        <!--End::Identity takeover-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
//import UIService from "@/common/ui.service";
//import JwtService from "@/common/jwt.service";

export default {
  name: "regLogList",
  components: {
    KTPortlet,
  },
  data() {
    return {
      menuId: null,
      title: this.$i18n.t("REGLOGLIST.TITLE"),
      description: "future description 3333",
      disableAccess: true,
      customerId: null,
      itemsUsers: null,
      itemsUnits: null,
      inputDateFrom: null,

      fieldsUsers: [
        {
          key: "Code",
          label: this.$i18n.t("REGLOGLIST.CODE"),
          sortable: true,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "DtStart",
          label: this.$i18n.t("REGLOGLIST.CREATEDATE"),
          sortable: true,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateTimeToLocal(value);
          },
        },
        {
          key: "RegStep",
          label: this.$i18n.t("REGLOGLIST.REGSTEP"),
          sortable: true,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Email",
          label: this.$i18n.t("REGLOGLIST.LOGINNAME"),
          sortable: true,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "RegParm",
          label: this.$i18n.t("REGLOGLIST.REGPARM"),
          sortable: true,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
      ],

      inputCustomer: null,
      inputAccess: null,
      inputSearch: null,
      customerOptions: [
        {
          value: null,
          text: this.$i18n.t("IDENTITY.FILTER.PLACEHOLDERCUSTOMER"),
          disabled: true,
        },
      ],
      totalRowsUsers: 1,
      currentPageUsers: 1,
      perPageUsers: FormatService.formatGetPerPage(),
      pageOptionsUsers: FormatService.formatGetPerPageOption(),

      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.inputDateFrom = FormatService.formatDateForInput(new Date());
    this.fetchCustomers();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    pointerRowClass() {
      return "pointer-row";
    },
    fetchCustomers() {
      ApiService.setHeader();
      ApiService.get("Auth", "CustomerList").then(({ data }) => {
        this.setCustomerOptions(data.Customers);
      });
    },
    setCustomerOptions(items) {
      if (items.length != 1) {
        for (let i = 0; i < items.length; i++) {
          this.customerOptions.push({
            value: items[i].CustomerId,
            text: items[i].Code,
          });
        }
      } else {
        this.customerOptions = [];
        this.customerOptions.push({ value: null, text: items[0].Code });
        this.fetchData(items[0].CustomerId);
      }
    },

    fetchData(chosenCustomerId, search) {
      let parameters = "?menuId=" + this.menuId.toString();
      if (chosenCustomerId != null)
        parameters += "&customerId=" + chosenCustomerId.toString();
      if (search != null) parameters += "&search=" + search.toString();
      if (this.inputDateFrom != null) {
        parameters += "&dateFrom=" + this.inputDateFrom.toString();
      } else {
        parameters +=
          "&dateFrom=" + FormatService.formatDateForInput(new Date());
      }

      ApiService.setHeader();
      ApiService.get("Auth", "RegLogList" + parameters).then(({ data }) => {
        this.itemsUsers = data.Users;

        this.totalRowsUsers = this.itemsUsers.length;
      });
    },
  },
};
</script>
