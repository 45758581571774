<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <KTPortlet v-bind:title="title">
        <template v-slot:body>
          <b-container fluid>
            <div>
              <b-row class="justify-content-md-center mb-5">
                <div class="col-lg-6 col-sm-12 d-flex">
                  <div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("USERINFO.ADDRESS") }}
                      </span>
                      {{ Address }}
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("USERINFO.VS") }}
                      </span>
                      {{ VS }}
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("USERINFO.TYPE") }}
                      </span>
                      {{ Type }}
                    </div>
                  </div>
                </div>
              </b-row>
              <hr class="poschodoch-separator-line mt-4 mb-4" />
              <b-row class="justify-content-md-left">
                <b-col cols="12" md="5">
                  <div class="row d-flex mb-1">
                    <span style="width: 20rem; font-weight: 600">
                      {{ $i18n.t("USERINFO.PERSONALINFO") }}
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="justify-content-md-left">
                <b-col cols="12" md="5">
                  <div class="row d-flex">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.NAME") }}
                    </span>
                    {{ Name }}
                  </div>
                  <div class="row d-flex">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.SURNAME") }}
                    </span>
                    {{ Surname }}
                  </div>
                  <div class="row d-flex mb-5">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.DEGREE") }}
                    </span>
                    {{ Degree }}
                  </div>
                </b-col>

                <b-col cols="12" md="5">
                  <div class="row d-flex">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.PERSON") }}
                    </span>
                    {{ Persons }}
                  </div>
                  <div class="row d-flex">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.EMAIL") }}
                    </span>
                    {{ Email }}
                  </div>
                  <div class="row d-flex mb-5">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.MOBIL") }}
                    </span>
                    {{ Phone }}
                  </div>
                </b-col>
              </b-row>

              <hr class="poschodoch-separator-line mt-4 mb-4" />
              <b-row class="justify-content-md-left">
                <b-col cols="12" md="5">
                  <div class="row d-flex mb-1">
                    <span style="width: 20rem; font-weight: 600">
                      {{ $i18n.t("USERINFO.CONTACTINFO") }}
                    </span>
                  </div>
                  <div class="row mr-5 d-flex">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.ADDRESS2") }}
                    </span>
                    {{ Name + " " + Surname }}
                  </div>
                  <div class="row mr-5 d-flex">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.STREET") }}
                    </span>
                    {{ Street }}
                  </div>
                  <div class="row mr-5 d-flex mb-5">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.CITY") }}
                    </span>
                    {{ City + " " + PostCode }}
                  </div>
                </b-col>

                <b-col cols="12" md="5">
                  <div class="row d-flex mb-1">
                    <span style="width: 20rem; font-weight: 600">
                      {{ $i18n.t("USERINFO.PAYMANTINFO") }}
                    </span>
                  </div>
                  <div class="row d-flex">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.SIPO") }}
                    </span>
                    {{ SIPO }}
                  </div>
                  <div class="row d-flex mb-5">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("USERINFO.BANKACCOUNT") }}
                    </span>
                    {{ BankAccount }}
                  </div>
                </b-col>
              </b-row>

              <hr class="poschodoch-separator-line mt-4 mb-4" />
              <b-row class="justify-content-md-left mb-5">
                <div class="col-lg-6 col-sm-12 d-flex">
                  <div>
                    <div class="row d-flex mb-1">
                      <span style="width: 10rem; font-weight: 600">
                        {{ $i18n.t("USERINFO.OTHERSINFO") }}
                      </span>
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("USERINFO.ELECTRONICINVOICE") }}
                      </span>
                      <span
                        v-bind:class="{
                          'text-danger': C1 === 0,
                          'text-success': C1 === 1,
                        }"
                      >
                        {{ ElectronicInvoice }}
                      </span>
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("USERINFO.PAYMENTORDER") }}
                      </span>
                      {{ addCurrency(PaymentOrder) }}
                    </div>
                  </div>
                </div>
              </b-row>
            </div>

            <b-row class="justify-content-end" v-if="description">
              <b-col lg="12">
                <hr class="poschodoch-separator-line mt-4 mb-4" />
                <ul class="poschodoch-note-list">
                  <li class="poschodoch-note">{{ description }}</li>
                </ul>
              </b-col>
            </b-row>
          </b-container>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";

import JwtService from "@/common/jwt.service";

export default {
  name: "events-detail",
  components: {
    KTPortlet,
  },
  data() {
    return {
      userInfoData: null,
      menuId: null,
      title: null,
      items: null,
      description: null,
      Address: null,
      BankAccount: null,
      City: null,
      Degree: null,
      Degree2: null,
      ElectronicInvoice: null,
      Email: null,
      Name: null,
      PaymentOrder: null,
      Persons: null,
      Phone: null,
      PostCode: null,
      SIPO: null,
      Street: null,
      Surname: null,
      Title: null,
      Type: null,
      VS: null,
      C1: 0,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return (
        data + " " + FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        "a" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    fetchData() {
      //this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "UserInfo" + parameters).then(({ data }) => {
        //this.transformData(data.CostDetail);
        this.userInfoData = data;

        this.Address = data.Address;
        this.BankAccount = data.BankAccount;
        this.City = data.City;
        this.Degree = data.Degree;
        this.Degree2 = data.Degree2;
        this.description = data.Description;
        if (data.ElectronicInvoice == "E") {
          this.ElectronicInvoice = this.$i18n.t("USERINFO.ACTIVE");
          this.C1 = 1;
        } else {
          this.ElectronicInvoice = this.$i18n.t("USERINFO.INACTIVE");
        }

        this.Email = data.Email;
        this.Name = data.Name;
        this.PaymentOrder = data.PaymentOrder;
        this.Persons = data.Persons;
        this.Phone = data.Phone;
        this.PostCode = data.PostCode;
        this.SIPO = data.SIPO;
        this.Street = data.Street;
        this.Surname = data.Surname;
        this.title = data.Title;
        this.Type = data.Type;
        this.VS = data.VS;
      });
    },
  },
};
</script>
