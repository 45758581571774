<template>
  <div>
    <div class="col-lg-12 col-xl-12">
      <KTPortlet>
        <template v-slot:body>
          <b-container fluid>
            <div class="kt-header-brand" v-if="usersetcode == 'handimex'">
              <hr class="poschodoch-separator-line mt-4 mb-4" />
              <b-img
                class="img-fluid"
                src="/assets/media/QR/handimex_logo.png"
              ></b-img>
            </div>
            <div v-if="usersetcode == 'presov'">
              <hr class="poschodoch-separator-line mt-4 mb-4" />
              <b-img
                class="img-fluid"
                src="/assets/media/QR/presov_logo.png"
              ></b-img>
              <div class="kt-heading kt-heading--sm kt-heading--space-sm"></div>
              <table style="width:90%">
                <tr class="qr">
                  <th style="width:60%" align="left">
                    <strong>KONTAKT:</strong>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
                <tr class="qr">
                  <div
                    class="kt-heading kt-heading--sm kt-heading--space-sm"
                  ></div>
                </tr>
                <tr class="qr">
                  <td>Centrálny dispečing:</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr class="qr">
                  <td>(051) 7567 601</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr class="qr">
                  <td>0908 021 322</td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
            <div v-if="usersetcode == 'pisek'">
              <hr class="poschodoch-separator-line mt-4 mb-4" />
              <b-img src="/assets/media/QR/pisek_logo.png" center></b-img>
              <div class="kt-heading kt-heading--sm kt-heading--space-sm"></div>
              <b-row class="mb-4 ml-1">
                <b-col cols="12" lg="6">
                  <table style="width:300px">
                    <tr class="qr">
                      <th style="width:90%" align="left">
                        <strong>KONTAKT:</strong>
                      </th>
                    </tr>
                    <tr class="qr">
                      <div
                        class="kt-heading kt-heading--sm kt-heading--space-sm"
                      ></div>
                    </tr>
                    <tr class="qr">
                      <td>Domovní a bytová správa města Písek</td>
                    </tr>
                    <tr class="qr">
                      <td>Fügnerovo nám. 42, Písek 397 01</td>
                    </tr>
                    <tr class="qr">
                      <td>tel.: 382789011, fax: 382789038</td>
                    </tr>
                    <tr class="qr">
                      <td>IČO: 00512362, DIČ: CZ00512362</td>
                    </tr>
                    <tr class="qr">
                      <td>
                        e-mail:
                        <a href="mailto:info@dbspisek.cz">info@dbspisek.cz</a>
                      </td>
                    </tr>
                    <tr class="qr">
                      <td>
                        <a href="https://www.dbspisek.cz" target="_blank">
                          www.dbspisek.cz
                        </a>
                      </td>
                    </tr>
                  </table>
                </b-col>
                <b-col cols="12" lg="6">
                  <table style="width:250px">
                    <tr class="qr">
                      <th><strong>Důležitá telefonní čísla:</strong></th>
                      <th></th>
                    </tr>
                    <tr class="qr">
                      <div
                        class="kt-heading kt-heading--sm kt-heading--space-sm"
                      ></div>
                      <div
                        class="kt-heading kt-heading--sm kt-heading--space-sm"
                      ></div>
                    </tr>
                    <tr class="qr">
                      <td>ZÁCHRANKA</td>
                      <td>155</td>
                    </tr>
                    <tr class="qr">
                      <td>HASIČI</td>
                      <td>150</td>
                    </tr>
                    <tr class="qr">
                      <td>POLICIE</td>
                      <td>158</td>
                    </tr>
                    <tr class="qr">
                      <td>MĚSTSKÁ POLICIE</td>
                      <td>156</td>
                    </tr>
                    <tr class="qr">
                      <td>POHOTOVOST VODA/ELEKTRO</td>
                      <td>156</td>
                    </tr>
                    <tr class="qr">
                      <td></td>
                      <td></td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </div>

            <b-row class="justify-content-end" v-if="description">
              <b-col lg="12">
                <hr class="poschodoch-separator-line mt-4 mb-4" />
                <ul class="poschodoch-note-list">
                  <li class="poschodoch-note">{{ description }}</li>
                </ul>
              </b-col>
            </b-row>
          </b-container>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import FormatService from "@/common/format.service";

export default {
  name: "show-meter-picture",
  components: {
    KTPortlet,
  },
  data() {
    return {
      usersetcode: null,
      menuId: null,
      description: null,
      qrCode: null,
      photo: null,
    };
  },
  mounted() {
    this.qrCode = this.$route.params.code;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },

    fetchData() {
      let decString = atob(this.qrCode);
      const indexOfFirst = decString.indexOf("#");
      this.usersetcode = decString.substring(0, indexOfFirst);
    },
  },
};
</script>
