<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <!--Begin::Nova ziadost-->
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <span>
                <slot name="title">{{ $i18n.t("NEW_REQUEST.TITLE") }}</slot>
              </span>
            </h3>
          </template>
          <template v-slot:toolbar>
            <!--            <b-button
              variant="primary"
              class="mr-2"
              @click="SendSaveRequest('save')"
              id="save_button"
            >
              {{ $i18n.t("REQUEST_DETAIL.SAVEBUTTON") }}
            </b-button>
            <b-button
              variant="primary"
              class="mr-2"
              @click="SendSaveRequest('send')"
              id="send_button"
            >
              {{ $i18n.t("REQUEST_DETAIL.SENDBUTTON") }}
            </b-button> -->
            <b-button variant="secondary" @click="back">
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <b-form-group
                  :label="labelRequestType"
                  label-for="input-request-type"
                >
                  <b-form-select
                    v-model="requestTypeId"
                    type="text"
                    :options="requestTypes"
                    :state="validateState('requestTypeId')"
                    @change="
                      requestTypeChange($event);
                      isEdited = true;
                    "
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group :label="labelSubject" label-for="input-subject">
                  <b-form-input
                    id="input-subject"
                    v-model="subject"
                    type="text"
                    :state="validateState('subject')"
                    @change="isEdited = true"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6 col-sm-12">
                <b-form-group :label="labelMobile" label-for="input-mobile">
                  <b-form-input
                    id="input-mobile"
                    v-model="mobile"
                    :state="validateState('mobile')"
                    type="text"
                    @change="isEdited = true"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="labelEmail" label-for="input-email">
                  <b-form-input
                    id="input-email"
                    v-model="email"
                    type="email"
                    :state="validateState('email')"
                    @change="isEdited = true"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12">
                <b-form-group
                  :label="labelDescription"
                  label-for="input-description"
                >
                  <b-form-textarea
                    id="input-description"
                    v-model="description"
                    :state="validateState('description')"
                    type="text"
                    rows="3"
                    @change="isEdited = true"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>
            <!--Begin::Description -->
            <b-row class="justify-content-end" v-if="descriptionBot">
              <b-col lg="12">
                <hr class="poschodoch-separator-line mt-4 mb-4" />
                <ul class="poschodoch-note-list">
                  <li class="poschodoch-note">{{ descriptionBot }}</li>
                </ul>
              </b-col>
            </b-row>
            <!--End::Description -->
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType1">
          <template v-slot:body>
            <!-- Odpocty meracov -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>

              <b-form-group
                :label="labelMeterNr"
                label-for="input-cislo-meraca"
              >
                <b-form-select
                  id="input-cislo-meraca"
                  v-model="cisloMeracaId"
                  :options="meters"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                :label="labelReadingDate"
                label-for="input-datum-odpoctu"
              >
                <b-form-datepicker
                  id="input-datum-odpoctu"
                  v-model="datumOdpoctu"
                  :placeholder="placeholderDate"
                  required
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group :label="labelState" label-for="input-merac-stav">
                <b-form-input
                  id="input-merac-stav"
                  v-model="meracStav"
                  @change="isEdited = true"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType2">
          <template v-slot:body>
            <!-- Zmena osobnych udajov -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>
              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldTitle"
                    label-for="input-udaje-stare-meno"
                  >
                    <b-form-input
                      id="input-udaje-stare-titulpred"
                      v-model="oldTitle"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewTitle"
                    label-for="input-udaje-nove-titolpred"
                  >
                    <b-form-input
                      id="input-udaje-nove-meno"
                      v-model="newTitle"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldTitle2"
                    label-for="input-udaje-stare-titulpo"
                  >
                    <b-form-input
                      id="input-udaje-stare-titulpo"
                      v-model="oldTitle2"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewTitle2"
                    label-for="input-udaje-nove-titulpo"
                  >
                    <b-form-input
                      id="input-udaje-nove-meno"
                      v-model="newTitle2"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldName"
                    label-for="input-udaje-stare-meno"
                  >
                    <b-form-input
                      id="input-udaje-stare-meno"
                      v-model="oldMeno"
                      :placeholder="placeholderOldMeno"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewName"
                    label-for="input-udaje-nove-meno"
                  >
                    <b-form-input
                      id="input-udaje-nove-meno"
                      v-model="newMeno"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldSurname"
                    label-for="input-udaje-stare-priezvisko"
                  >
                    <b-form-input
                      id="input-udaje-stare-priezvisko"
                      v-model="oldPriezvisko"
                      :placeholder="placeholderOldPriezvisko"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewSurname"
                    label-for="input-udaje-nove-priezvisko"
                  >
                    <b-form-input
                      id="input-udaje-nove-priezvisko"
                      v-model="newPriezvisko"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldStreet"
                    label-for="input-udaje-stara-ulica"
                  >
                    <b-form-input
                      id="input-udaje-stara-ulica"
                      v-model="oldUlica"
                      :placeholder="placeholderOldUlica"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewStreet"
                    label-for="input-udaje-nova-ulica"
                  >
                    <b-form-input
                      id="input-udaje-nova-ulica"
                      v-model="newUlica"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldCity"
                    label-for="input-udaje-stare-mesto"
                  >
                    <b-form-input
                      id="input-udaje-stare-mesto"
                      v-model="oldMesto"
                      :placeholder="placeholderOldMesto"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewCity"
                    label-for="input-udaje-nove-mesto"
                  >
                    <b-form-input
                      id="input-udaje-nove-mesto"
                      v-model="newMesto"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldPSC"
                    label-for="input-udaje-stare-psc"
                  >
                    <b-form-input
                      id="input-udaje-stare-psc"
                      v-model="oldPSC"
                      :placeholder="placeholderOldPSC"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewPSC"
                    label-for="input-udaje-nove-psc"
                  >
                    <b-form-input
                      id="input-udaje-nove-psc"
                      v-model="newPSC"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType3">
          <template v-slot:body>
            <!-- Nahlasenie osobomesiacov -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>

              <b-form-row>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelYear"
                    label-for="input-osobomesiace-rok"
                  >
                    <b-form-input
                      id="input-osobomesiace-rok"
                      v-model="rok"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelJanuary"
                    label-for="input-osobomesiace-januar"
                  >
                    <b-form-input
                      id="input-osobomesiace-januar"
                      v-model="januar"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelFebruary"
                    label-for="input-osobomesiace-februar"
                  >
                    <b-form-input
                      id="input-osobomesiace-februar"
                      v-model="februar"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelMarch"
                    label-for="input-osobomesiace-marec"
                  >
                    <b-form-input
                      id="input-osobomesiace-marec"
                      v-model="marec"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelApril"
                    label-for="input-osobomesiace-april"
                  >
                    <b-form-input
                      id="input-osobomesiace-april"
                      v-model="april"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelMay"
                    label-for="input-osobomesiace-maj"
                  >
                    <b-form-input
                      id="input-osobomesiace-maj"
                      v-model="maj"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelJune"
                    label-for="input-osobomesiace-jun"
                  >
                    <b-form-input
                      id="input-osobomesiace-jun"
                      v-model="jun"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelJuly"
                    label-for="input-osobomesiace-jul"
                  >
                    <b-form-input
                      id="input-osobomesiace-jul"
                      v-model="jul"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelAugust"
                    label-for="input-osobomesiace-august"
                  >
                    <b-form-input
                      id="input-osobomesiace-august"
                      v-model="august"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelSeptember"
                    label-for="input-osobomesiace-september"
                  >
                    <b-form-input
                      id="input-osobomesiace-september"
                      v-model="september"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelOctober"
                    label-for="input-osobomesiace-oktober"
                  >
                    <b-form-input
                      id="input-osobomesiace-oktober"
                      v-model="oktober"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelNovember"
                    label-for="input-osobomesiace-november"
                  >
                    <b-form-input
                      id="input-osobomesiace-november"
                      v-model="november"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelDecember"
                    label-for="input-osobomesiace-december"
                  >
                    <b-form-input
                      id="input-osobomesiace-december"
                      v-model="december"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType4">
          <template v-slot:body>
            <!-- Zmena osob predpisu -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <b-form-group
                    :label="labelChangeDate"
                    label-for="input-zmenaosob-datum-zmeny-od"
                  >
                    <b-form-datepicker
                      id="input-zmenaosob-datum-zmeny-od"
                      v-model="datumZmenyOd"
                      :placeholder="placeholderDate"
                      required
                    ></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <b-form-group
                    :label="labelPersonCount"
                    label-for="input-zmenaosob-pocet-osob"
                  >
                    <b-form-input
                      id="input-zmenaosob-pocet-osob"
                      v-model="pocetOsob"
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType5">
          <template v-slot:body>
            <!-- Nahlaska na udrzbu -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <b-form-group
                    :label="labelMaintenance"
                    label-for="input-nahlaska-udrzba"
                    class="mb-0"
                  >
                    <b-form-select
                      id="input-nahlaska-udrzba"
                      v-model="maintenanceVal"
                      :options="maintenanceOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType6">
          <template v-slot:body>
            <!-- Vydavky-->
            <div>
              <div class="row">
                <div class="col-lg-3 col-sm-12">
                  <b-form-group
                    :label="labelSuma + '(' + currency + ')*'"
                    label-for="input-suma"
                  >
                    <b-form-input
                      id="input-suma"
                      v-model="suma"
                      type="text"
                      @change="isEdited = true"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group :label="labelBU" label-for="input-bu">
                    <b-form-input
                      id="input-bu"
                      v-model="bu"
                      type="text"
                      @change="isEdited = true"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-12">
                  <b-form-group
                    :label="labelDescriptionVyd"
                    label-for="input-descriptionvyd"
                  >
                    <b-form-textarea
                      id="input-description-vyd"
                      v-model="descriptionvyd"
                      type="text"
                      rows="3"
                      @change="isEdited = true"
                      required
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="filesOld.length > 0">
          <template v-slot:body>
            <div>
              <h5 class="mb-4">{{ $i18n.t("NEW_REQUEST.UPLOADED") }}</h5>
              <div class="row">
                <div class="col-12">
                  <div class="ml-2">
                    <p v-for="file in filesOld" :key="file.FileId">
                      {{ file.Filename }}
                    </p>
                  </div>
                  <a
                    href="javascript:void(0)"
                    v-if="ShowButtonDeleteFiles"
                    @click="
                      deleteAllDoc();
                      isEdited = true;
                    "
                    class="p-2"
                  >
                    <i
                      class="flaticon2-delete color-danger poschodoch-icon-medium mr-2"
                    ></i>
                    {{ $i18n.t("NEW_REQUEST.LABEL.DELETE_FILES") }}
                  </a>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet>
          <template v-slot:body>
            <div>
              <h5 class="mb-4">{{ labelFilePL }}</h5>
              <div class="row">
                <div class="col-12">
                  <b-form-group>
                    <b-form-file
                      multiple
                      v-model="files"
                      :placeholder="placeholderFile"
                      @input="getBase64File"
                    ></b-form-file>
                  </b-form-group>
                </div>
                <div class="col-12" v-if="reqFileName">
                  <span>
                    {{
                      $i18n.t("REQUEST_DETAIL.FILEATTACHMENTCOMMENT") +
                        " " +
                        reqFileName
                    }}
                  </span>
                  <a
                    href="javascript:void(0)"
                    @click="
                      reqFileName = '';
                      isEdited = true;
                    "
                    class="p-2"
                  >
                    <i
                      class="flaticon2-delete color-danger poschodoch-icon-medium"
                    ></i>
                  </a>
                </div>
              </div>
            </div>

            <!--            <div class="kt-align-right">
              <b-button
              variant="primary"
              class="mr-2"
              @click="SendSaveRequest('save')"
              id="save_button"
            >
              {{ $i18n.t("REQUEST_DETAIL.SAVEBUTTON") }}
            </b-button>
            <b-button
              variant="primary"
              class="mr-2"
              @click="SendSaveRequest('send')"
              id="send_button"
            >
              {{ $i18n.t("REQUEST_DETAIL.SENDBUTTON") }}
            </b-button>
            <b-button variant="secondary" @click="back">
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
            </div> -->
          </template>
        </KTPortlet>
        <KTPortlet>
          <template v-slot:body>
            <div class="kt-align-right">
              <b-button
                variant="primary"
                class="mr-2"
                @click="SendSaveRequest('save')"
                id="save_button"
              >
                {{ $i18n.t("REQUEST_DETAIL.SAVEBUTTON") }}
              </b-button>
              <b-button
                variant="primary"
                class="mr-2"
                @click="SendSaveRequest('send')"
                id="send_button"
              >
                {{ $i18n.t("REQUEST_DETAIL.SENDBUTTON") }}
              </b-button>
              <b-button variant="secondary" @click="back">
                {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
              </b-button>
            </div>
          </template>
        </KTPortlet>
        <!--End::Nova ziadost-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "newRequest",
  components: {
    KTPortlet,
  },
  data() {
    return {
      errors: [],
      //form: {
      requestTypeId: null,
      email: null,
      mobile: null,
      subject: null,
      description: null,
      //},
      files: [],
      filesOld: [],
      filesSend: [],
      ShowButtonDeleteFiles: true,
      unitData: null,
      isEdited: false,
      menuId: null,
      requestId: null,
      requestTypes: [],
      requestTypesComp: [],
      requestTypesCont: [],
      requestTypesDesc: [],
      file: null,
      base64file: null,
      reqItems: null,
      reqCode: null,
      reqState: null,
      reqStateText: this.$i18n.t("NEW_REQUEST.SENDED"),
      reqName: null,
      reqFileName: "",
      reqCreationDate: null,
      reqChangeDate: null,
      reqEmail: null,
      reqMobile: null,
      reqPhone: null,
      // Odpocty meracov requestTypeId == 1
      showRequestType1: false,
      cisloMeracaId: null,
      meters: [],
      datumOdpoctu: null,
      meracStav: null,
      // Zmena osobnych udajov requestTypeId == 2
      showRequestType2: false,
      oldTitle: null,
      oldTitle2: null,
      oldMeno: null,
      oldPriezvisko: null,
      oldUlica: null,
      oldMesto: null,
      oldPSC: null,
      newTitle: null,
      newTitle2: null,
      newMeno: null,
      newPriezvisko: null,
      newUlica: null,
      newMesto: null,
      newPSC: null,
      // Nahlasenie osobomesiacov requestTypeId == 3
      showRequestType3: false,
      rok: null,
      januar: null,
      februar: null,
      marec: null,
      april: null,
      maj: null,
      jun: null,
      jul: null,
      august: null,
      september: null,
      oktober: null,
      november: null,
      december: null,
      // Zmena osob predpisu requestTypeId == 4
      showRequestType4: false,
      datumZmenyOd: null,
      pocetOsob: null,
      // Nahlaska na udrzbu requestTypeId == 5
      showRequestType5: false,
      showRequestType6: false,
      maintenanceVal: "C",
      maintenanceOptions: [
        { value: "B", text: this.$i18n.t("NEW_REQUEST.LABEL.RADIO1") },
        { value: "V", text: this.$i18n.t("NEW_REQUEST.LABEL.RADIO2") },
        { value: "C", text: this.$i18n.t("NEW_REQUEST.LABEL.RADIO3") },
      ],

      // localization variables
      labelRequestType: this.$i18n.t("NEW_REQUEST.LABEL.REQTYPE"),
      labelEmail: this.$i18n.t("NEW_REQUEST.LABEL.EMAIL"),
      // labelEmailDesc: this.$i18n.t("NEW_REQUEST.LABEL.EMAILDESC"),
      labelMobile: this.$i18n.t("NEW_REQUEST.LABEL.MOBILE") + ":",
      labelSubject: this.$i18n.t("NEW_REQUEST.LABEL.SUBJECT"),
      labelDescription: this.$i18n.t("NEW_REQUEST.LABEL.DESCRIPTION"),
      placeholderFile: this.$i18n.t("NEW_REQUEST.UPLOADPLACEHOLDER"),
      labelFile: this.$i18n.t("NEW_REQUEST.LABEL.FILE"),
      labelMeterNr: this.$i18n.t("NEW_REQUEST.LABEL.METERNR"),
      placeholderDate: this.$i18n.t("NEW_REQUEST.DATEPLACEHOLDER"),
      labelReadingDate: this.$i18n.t("NEW_REQUEST.LABEL.READINGDATE"),
      labelState: this.$i18n.t("NEW_REQUEST.LABEL.STATE"),

      labelOldTitle: this.$i18n.t("NEW_REQUEST.LABEL.OLDTITLE"),
      labelOldTitle2: this.$i18n.t("NEW_REQUEST.LABEL.OLDTITLE2"),
      labelNewTitle: this.$i18n.t("NEW_REQUEST.LABEL.NEWTITLE"),
      labelNewTitle2: this.$i18n.t("NEW_REQUEST.LABEL.NEWTITLE2"),

      labelOldName: this.$i18n.t("NEW_REQUEST.LABEL.OLDNAME"),
      labelNewName: this.$i18n.t("NEW_REQUEST.LABEL.NEWNAME"),
      labelOldSurname: this.$i18n.t("NEW_REQUEST.LABEL.OLDSURNAME"),
      labelNewSurname: this.$i18n.t("NEW_REQUEST.LABEL.NEWSURNAME"),
      labelOldStreet: this.$i18n.t("NEW_REQUEST.LABEL.OLDSTREET"),
      labelNewStreet: this.$i18n.t("NEW_REQUEST.LABEL.NEWSTREET"),
      labelOldCity: this.$i18n.t("NEW_REQUEST.LABEL.OLDCITY"),
      labelNewCity: this.$i18n.t("NEW_REQUEST.LABEL.NEWCITY"),
      labelOldPSC: this.$i18n.t("NEW_REQUEST.LABEL.OLDPSC"),
      labelNewPSC: this.$i18n.t("NEW_REQUEST.LABEL.NEWPSC"),
      placeholderOldMeno: this.$i18n.t("NEW_REQUEST.OLDNAMEPLACEHOLDER"),
      placeholderOldPriezvisko: this.$i18n.t(
        "NEW_REQUEST.OLDSURNAMEPLACEHOLDER",
      ),
      placeholderOldUlica: this.$i18n.t("NEW_REQUEST.OLDSTREETPLACEHOLDER"),
      placeholderOldMesto: this.$i18n.t("NEW_REQUEST.OLDCITYPLACEHOLDER"),
      placeholderOldPSC: this.$i18n.t("NEW_REQUEST.OLDPSCPLACEHOLDER"),
      labelYear: this.$i18n.t("NEW_REQUEST.LABEL.YEAR"),
      labelJanuary: this.$i18n.t("COMMON.MONTHS.MONTH_1") + "*",
      labelFebruary: this.$i18n.t("COMMON.MONTHS.MONTH_2") + "*",
      labelMarch: this.$i18n.t("COMMON.MONTHS.MONTH_3") + "*",
      labelApril: this.$i18n.t("COMMON.MONTHS.MONTH_4") + "*",
      labelMay: this.$i18n.t("COMMON.MONTHS.MONTH_5") + "*",
      labelJune: this.$i18n.t("COMMON.MONTHS.MONTH_6") + "*",
      labelJuly: this.$i18n.t("COMMON.MONTHS.MONTH_7") + "*",
      labelAugust: this.$i18n.t("COMMON.MONTHS.MONTH_8") + "*",
      labelSeptember: this.$i18n.t("COMMON.MONTHS.MONTH_9") + "*",
      labelOctober: this.$i18n.t("COMMON.MONTHS.MONTH_10") + "*",
      labelNovember: this.$i18n.t("COMMON.MONTHS.MONTH_11") + "*",
      labelDecember: this.$i18n.t("COMMON.MONTHS.MONTH_12") + "*",
      labelChangeDate: this.$i18n.t("NEW_REQUEST.LABEL.CHANGEDATE"),
      labelPersonCount: this.$i18n.t("NEW_REQUEST.LABEL.PERSONCOUNT"),
      labelMaintenance: this.$i18n.t("NEW_REQUEST.LABEL.MAINTENANCE"),
      labelSuma: this.$i18n.t("NEW_REQUEST.LABEL.SUMA"),
      labelBU: this.$i18n.t("NEW_REQUEST.LABEL.BU"),
      labelDescriptionVyd: this.$i18n.t("NEW_REQUEST.LABEL.DESCVYD"),
      labelFilePL: this.$i18n.t("NEW_REQUEST.UPLOAD"),
      descriptionBot: null,
      descriptionvyd: null,
      bu: null,
      suma: null,
      currency: "Eur",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    subject: {
      required,
    },
    description: {
      required,
    },
    requestTypeId: {
      required,
    },
    mobile: {
      required,
      validatePhone(value) {
        let regex = /^[+][1-9]{1}[0-9]{10,}$/;
        if (/^\+421[0-9]{9}$/.test(value)) regex = /^\+4219[0-9]{8}$/;
        if (/^\+420[0-9]{9}$/.test(value)) regex = /^\+420[67][0-9]{8}$/;

        return regex.test(value);
      },
    },
  },
  computed: {},

  mounted() {
    this.menuId = this.$route.query.menuId;
    this.requestId = this.$route.query.requestId;
    this.fetchInfo();
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.leaving);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.leaving);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdited) {
      const answer = window.confirm(this.$i18n.t("NEW_REQUEST.CONFIRMLEAVE"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    leaving(event) {
      if (!this.isEdited) return;
      event.preventDefault();
      event.returnValue = "";
    },
    fillMeters(items) {
      this.meters.push({ value: null, text: "-" });

      for (let i = 0; i < items.length; i++) {
        this.meters.push({ value: items[i].Value, text: items[i].Code });
      }
    },
    fillRequestTypes(items) {
      for (let i = 0; i < items.length; i++) {
        this.requestTypes.push({ value: items[i].Value, text: items[i].Code });
        this.requestTypesComp.push({
          value: items[i].Value,
          text: items[i].Code,
          description: items[i].Description,
          cont: items[i].Cont,
        });
        this.requestTypesCont.push({
          value: items[i].Value,
          text: items[i].Cont,
        });
        this.requestTypesDesc.push({
          value: items[i].Value,
          text: items[i].Description,
        });
      }
    },
    deleteAllDoc() {
      for (let i = 0; i < this.filesOld.length; i++) {
        if (
          !this.filesOld[i].Filename.includes(
            this.$i18n.t("NEW_REQUEST.LABEL.DELETE_FILES_PRE"),
          )
        )
          this.filesOld[i].Filename =
            this.$i18n.t("NEW_REQUEST.LABEL.DELETE_FILES_PRE") +
            this.filesOld[i].Filename;
      }
      this.ShowButtonDeleteFiles = false;
    },
    getBase64File(files) {
      this.filesSend = [];
      //for (let i = 0; i < ev.target.files.length; i++){
      for (let i = 0; i < files.length; i++) {
        this.isEdited = true;

        const file = files[i];
        const reader = new FileReader();

        reader.readAsDataURL(file);
        //pridal som 2x replace do resulto, toto filtruje z URI formatu len base64
        //this.files[i].FileName = ev.target.files[i].name;
        //reader.onload = (e) => (this.FileBinary = e.target.result.replace("data:", "").replace(/^.+,/, "") );
        reader.onload = (e) =>
          this.filesSend.push({
            FileName: file.name,
            FileBinary: e.target.result
              .replace("data:", "")
              .replace(/^.+,/, ""),
          });

        /*reader.onload = function(e) {

          //this.files.push( [{ FileName: file.name,  FileBinary: e.target.result.replace("data:", "").replace(/^.+,/, "") }  ]);
        //let obj = { "FileName" : file.name,  "FileBinary" : e.target.result.replace("data:", "").replace(/^.+,/, "") } ;
        let obj = { FileName : file.name,  FileBinary : e.target.result.replace("data:", "").replace(/^.+,/, "") } ;
        this.filesSend.push(obj);          
        };*/
      }
    },
    SendSaveRequest(sendsave) {
      UIService.addButtonSpinner(sendsave + "_button");

      this.$v.$touch();
      if (this.$v.$anyError) {
        UIService.removeButtonSpinner(sendsave + "_button");
        return;
      }

      let reqSt = "";
      if (sendsave == "send") {
        reqSt = "NO";
        this.reqStateText = this.$i18n.t("NEW_REQUEST.SENDED");
      }
      if (sendsave == "save") {
        reqSt = "RO";
        this.reqStateText = this.$i18n.t("NEW_REQUEST.ROZP");
      }

      //odpocet
      if (this.showRequestType1 && sendsave == "send") {
        if (
          this.cisloMeracaId == null ||
          this.datumOdpoctu == null ||
          this.meracStav == null
        ) {
          this.errors.push(this.$i18n.t("NEW_REQUEST.ERROR.REQUIRED"));
          UIService.removeButtonSpinner(sendsave + "_button");
          return;
        }
      }

      //osobomesiace
      if (this.showRequestType3 && sendsave == "send") {
        if (
          this.januar == null ||
          this.februar == null ||
          this.marec == null ||
          this.april == null ||
          this.maj == null ||
          this.jun == null ||
          this.jul == null ||
          this.august == null ||
          this.september == null ||
          this.oktober == null ||
          this.november == null ||
          this.december == null ||
          this.rok == null
        ) {
          this.errors.push(this.$i18n.t("NEW_REQUEST.ERROR.REQUIRED"));
          UIService.removeButtonSpinner(sendsave + "_button");
          return;
        }
      }
      //predpis
      if (this.showRequestType4 && sendsave == "send") {
        if (this.datumZmenyOd == null || this.pocetOsob == null) {
          this.errors.push(this.$i18n.t("NEW_REQUEST.ERROR.REQUIRED"));
          UIService.removeButtonSpinner(sendsave + "_button");
          return;
        }
      }
      //vydavky
      if (this.showRequestType6 && sendsave == "send") {
        if (
          this.suma == null ||
          !this.suma ||
          this.bu == null ||
          !this.bu ||
          this.descriptionvyd == null ||
          !this.descriptionvyd ||
          (this.filesSend.length == 0 && this.filesOld.length == 0) ||
          (this.filesOld.find((name) =>
            name.Filename.includes(
              this.$i18n.t("NEW_REQUEST.LABEL.DELETE_FILES_PRE"),
            ),
          ) &&
            this.filesOld.length > 0 &&
            this.filesSend.length == 0)
        ) {
          this.errors.push(this.$i18n.t("NEW_REQUEST.ERROR.REQUIRED"));
          UIService.removeButtonSpinner(sendsave + "_button");
          return;
        }
      }
      this.errors = [];

      if (!this.requestId) this.requestId = null;
      if (this.file) this.reqFileName = this.file.name;
      if (this.filesOld) {
        for (let i = 0; i < this.filesOld.length; i++) {
          if (
            this.filesOld[i].Filename.includes(
              this.$i18n.t("NEW_REQUEST.LABEL.DELETE_FILES_PRE"),
            )
          )
            this.filesOld[i].Filename = "";
          this.filesOld[i].Filebinary = "";
        }
      }
      if (this.filesSend) {
        for (let i = 0; i < this.filesSend.length; i++) {
          //let obj = { "Filename" : this.filesSend[i].FileName,  "Filebinary" : this.filesSend[i].FileBinary, "FileId": null } ;
          this.filesOld.push({
            FileId: i,
            Filename: this.filesSend[i].FileName,
            Filebinary: this.filesSend[i].FileBinary,
          });
        }
      }
      /*for (let i = 0; i < this.filesOld.length; i++) {          
          //this.filesAll.push({ "Filename" : this.filesOld[i].FileName,  "Filebinary" : this.filesOld[i].FileBinary, "FileId": this.filesOld[i].FileId } );
          this.filesAll.push({ "Filename" : "",  "Filebinary" : "", "FileId": "" } );
      };*/

      let postContent = {
        RequestId: this.requestId,
        /*DbName: "anadomus",
        UnitID: localStorage.getItem("id_unit"),
        UnitType: localStorage.getItem("unit_type"),*/
        Subject: this.subject,
        Description: this.description,
        Phone: encodeURIComponent(this.mobile),
        MobilePhone: encodeURIComponent(this.mobile),
        Email: this.email,
        RequestCode: this.requestTypeId,
        RequestStateText: this.reqStateText,
        RequestState: reqSt,
        FileBinary: this.base64file,
        FileName: this.reqFileName,
        Name: this.reqName,
        ReportDate: this.reqChangeDate,
        NameNew: this.newMeno,
        SurnameNew: this.newPriezvisko,
        StreetNew: this.newUlica,
        PostcodeNew: this.newPSC,
        CityNew: this.newMesto,
        MeterId: this.cisloMeracaId,
        MeterReadingNew: this.datumOdpoctu,
        StateNew: this.meracStav,
        January: this.januar,
        February: this.februar,
        March: this.marec,
        April: this.april,
        May: this.maj,
        June: this.jun,
        July: this.jul,
        August: this.august,
        September: this.september,
        October: this.oktober,
        November: this.november,
        December: this.december,
        Year: this.rok,
        Count: this.pocetOsob,
        VType: this.maintenanceVal,
        DateChange: this.datumZmenyOd,
        //Files: this.files,
        Files: this.filesOld,
        TitleNew: this.newTitle,
        Title2New: this.newTitle2,
        Suma: this.suma,
        BU: this.bu,
        DescVyd: this.descriptionvyd,
      };

      ApiService.post("Request/Edit", postContent)
        .then(() => {
          UIService.removeButtonSpinner(sendsave + "_button");
          this.back();
        })
        .catch(() => {
          UIService.removeButtonSpinner(sendsave + "_button");
          this.back();
        });
      this.isEdited = false;
      //this.back();
    },
    fetchInfo() {
      this.reqItems = null;
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.requestId)
        parameters += "&requestId=" + this.requestId.toString();

      ApiService.setHeader();
      ApiService.get("Request", "Detail" + parameters).then(({ data }) => {
        this.reqItems = data;
        if (data.Files) this.filesOld = data.Files;
        //this.transformData(data.Files);

        this.reqCode = data.RequestType;
        this.reqState = data.RequestState;
        //this.reqStateText = data.RequestStateText;
        this.reqName = data.Name;
        this.reqFileName = data.Filename;
        this.reqCreationDate = data.CreationDate;
        this.reqChangeDate = data.DateOfChange;
        this.reqEmail = data.Email;
        this.reqMobile = data.MobilePhone;
        this.reqPhone = data.Phone;
        this.requestTypeId = data.RequestType;
        this.title = data.Title;
        if (this.reqMobile) this.mobile = this.reqMobile;
        if (this.reqEmail) this.email = this.reqEmail;
        if (!this.description) this.description = data.RequestDescription;
        if (!this.subject) this.subject = data.Subject;

        if (!this.email) this.email = this.reqEmail;
        if (!this.mobile) this.mobile = this.reqMobile;

        if (data.DateChange) this.datumZmenyOd = data.DateChange;
        if (data.Count) this.pocetOsob = data.Count;
        if (data.NameNew) this.newMeno = data.NameNew;
        if (data.SurnameNew) this.newPriezvisko = data.SurnameNew;
        if (data.StreetNew) this.newUlica = data.StreetNew;
        if (data.PostCodeNew) this.newPSC = data.PostCodeNew;
        if (data.CityNew) this.newMesto = data.CityNew;
        if (data.Year) this.rok = data.Year;
        if (data.January) this.januar = data.January;
        if (data.February) this.februar = data.February;
        if (data.March) this.marec = data.March;
        if (data.April) this.april = data.April;
        if (data.May) this.maj = data.May;
        if (data.June) this.jun = data.June;
        if (data.July) this.jul = data.July;
        if (data.August) this.august = data.August;
        if (data.September) this.september = data.September;
        if (data.October) this.oktober = data.October;
        if (data.November) this.november = data.November;
        if (data.December) this.december = data.December;
        if (data.MeterReadingNew) this.datumOdpoctu = data.MeterReadingNew;
        if (data.StateNew) this.meracStav = data.StateNew;
        if (data.MeterId) this.cisloMeracaId = data.MeterId;
        if (data.VType) this.maintenanceVal = data.VType;

        if (data.Vyd_Suma) this.suma = data.Vyd_Suma;
        if (data.Vyd_BU) this.bu = data.Vyd_BU;
        if (data.Vyd_Popis) this.descriptionvyd = data.Vyd_Popis;

        if (this.reqCode == "odpocet") {
          this.showRequestType1 = true;
        }
        if (this.reqCode == "osob_udaje") {
          this.showRequestType2 = true;
        }
        if (this.reqCode == "osobomesiace") {
          this.showRequestType3 = true;
        }
        if (this.reqCode == "predpis_osoby") {
          this.showRequestType4 = true;
        }
        if (this.reqCode == "hlasudr") {
          this.showRequestType5 = true;
        }
        if (this.reqCode == "vydavky") {
          this.showRequestType6 = true;
          this.labelFilePL = this.$i18n.t("NEW_REQUEST.UPLOAD2");
        }
      });

      ApiService.setHeader();
      ApiService.get("Dashboard", "UnitData").then(({ data }) => {
        this.unitData = data;
        this.currency = data.Currency;
        if (data.NameOld) this.oldMeno = data.NameOld;
        if (data.SurnameOld) this.oldPriezvisko = data.SurnameOld;
        if (data.StreetOld) this.oldUlica = data.StreetOld;
        if (data.PSCOld) this.oldPSC = data.PSCOld;
        if (data.CityOld) this.oldMesto = data.CityOld;
        if (data.TitleOld) this.oldTitle = data.TitleOld;
        if (data.Title2Old) this.oldTitle2 = data.Title2Old;

        if (!this.meters[0]) this.fillMeters(data.Meters);
        if (!this.requestTypes[0]) this.fillRequestTypes(data.RequestsType);
        this.requestTypesComp = data.RequestsType;
      });

      parameters = "";
      ApiService.setHeader();
      ApiService.get("Account", "Profile" + parameters).then(({ data }) => {
        //this.reqEmail = data.Email;
        //this.reqMobile = data.Phone;

        if (!this.email) this.email = data.Email;
        if (!this.mobile) this.mobile = data.Phone;
      });
    },
    back() {
      // Momentalne neni moznost cez request-list-house pridavat requesty cize button moze ist vzdy na list-user
      //
      // let namePlug;
      // if (this.menuId == -103) namePlug = "domus-request-list-house";
      // if (this.menuId == -104) namePlug = "domus-request-list-user";

      this.$router.push({
        name: "domus-request-list-user",
        query: { menuId: this.menuId },
      });
    },
    /*transformData(items) {
      for (let i = 0; i < items.length; i++) {
        this.files[i] = { file: {
          name: items[i].FileName,  
          length: 0,
          }
        };
      }
    },*/
    requestTypeChange(event) {
      this.showRequestType1 = false;
      this.showRequestType2 = false;
      this.showRequestType3 = false;
      this.showRequestType4 = false;
      this.showRequestType5 = false;
      this.showRequestType6 = false;
      this.labelFilePL = this.$i18n.t("NEW_REQUEST.UPLOAD");
      if (
        this.description == null ||
        this.description == "" ||
        this.requestTypesCont.find((item) => item.text === this.description)
      ) {
        this.description = this.requestTypesCont.find(
          (item) => item.value === event,
        ).text;
      }
      if (
        this.subject == null ||
        this.subject == "" ||
        this.requestTypes.find((item) => item.text === this.subject)
      ) {
        this.subject = this.requestTypes.find(
          (item) => item.value === event,
        ).text;
      }

      this.descriptionBot = this.requestTypesDesc.find(
        (item) => item.value === event,
      ).text;

      if (this.requestTypeId == "odpocet") {
        this.showRequestType1 = true;
      }
      if (this.requestTypeId == "osob_udaje") {
        this.showRequestType2 = true;
      }
      if (this.requestTypeId == "osobomesiace") {
        this.showRequestType3 = true;
      }
      if (this.requestTypeId == "predpis_osoby") {
        this.showRequestType4 = true;
      }
      if (this.requestTypeId == "hlasudr") {
        this.showRequestType5 = true;
      }
      if (this.requestTypeId == "vydavky") {
        this.showRequestType6 = true;
        this.labelFilePL = this.$i18n.t("NEW_REQUEST.UPLOAD2");
      }
    },
  },
};
</script>
