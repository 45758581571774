<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Predpisy-->
        <KTPortlet v-bind:title="title" class="poschodoch-print">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :refreshed="print()"
                class="poschodoch-summary-table"
              >
                <template v-slot:custom-foot>
                  <tr class="poschodoch-summary-row">
                    <td>{{ $t("PAYMENTORDER.TABLE.FOOTER") }}</td>
                    <td class="text-right">
                      {{ getFormatService().formatNumberToLocal(amount) }}
                    </td>
                  </tr>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Predpisy-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "paymentOrder-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      amount: null,
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "Item",
          label: this.$i18n.t("PAYMENTORDER.TABLE.ITEM"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("PAYMENTORDER.TABLE.AMOUNT"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    fetchData() {
      this.items = null;
      let tit = "";
      let docTitle = "";
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "PaymentOrder" + parameters).then(({ data }) => {
        this.amount = data.Amount;
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.PaymentOrder;
        tit = this.title;
      });

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
