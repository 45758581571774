<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <div class="row">
      <div class="col-lg-8 col-xl-8 col-md-8">
        <!--Begin::Request detail-->
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">{{ $i18n.t("REQUEST_DETAIL.TITLE") }}</slot>
              </span>
            </h3>
          </template>
          <template v-slot:toolbar>
            <!-- no button action yet -->
            <!--<b-button variant="secondary">
              {{ $i18n.t("REQUEST_DETAIL.CANCELBUTTON") }}
            </b-button>-->
          </template>
          <template v-slot:body>
            <div class="row">
              <!-- v-if="requestState != 'Rozpracovaná'" -->
              <div class="col-lg-6 col-sm-12">
                <b-form-group label="" label-for="input-subject">
                  <slot name="label">
                    {{ $i18n.t("REQUEST_DETAIL.SUBJECT") }}
                  </slot>
                  <b-form-input
                    id="input-subject"
                    v-model="subject"
                    type="text"
                    readonly
                    disabled
                    class="mt-2"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6 col-sm-12">
                <b-form-group label="" label-for="input-requestStateTxt">
                  <slot name="label">
                    {{ $i18n.t("REQUEST_DETAIL.STATE") }}
                  </slot>
                  <b-form-input
                    id="input-requestStateTxt"
                    v-model="requestStateText"
                    type="text"
                    readonly
                    disabled
                    class="mt-2"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group label="" label-for="input-content">
                  <slot name="label">
                    {{ $i18n.t("REQUEST_DETAIL.DESCRIPTION") }}
                  </slot>
                  <b-form-textarea
                    id="input-content"
                    v-model="requestDescription"
                    type="text"
                    rows="3"
                    readonly
                    disabled
                    class="mt-2"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet class="poschodoch-chat">
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">
                  {{ $i18n.t("REQUEST_DETAIL.CHATTITLE") }}
                </slot>
              </span>
            </h3>
          </template>
          <template v-slot:body>
            <div class="poschodoch-chat-window" id="poschodoch-chat-window_id">
              <Chat
                v-if="visible"
                style="border-radius: 0px"
                :participants="participants"
                :myself="myself"
                :messages="messages"
                :placeholder="placeholderMessage"
                :colors="colors"
                :border-style="borderStyle"
                :hide-close-button="hideCloseButton"
                :close-button-icon-size="closeButtonIconSize"
                :submit-icon-size="submitIconSize"
                :submit-image-icon-size="30"
                :load-more-messages="
                  toLoad.length > 0 ? loadMoreMessages : null
                "
                :async-mode="asyncMode"
                :scroll-bottom="scrollBottom"
                :display-header="false"
                :send-images="false"
                :profile-picture-config="profilePictureConfig"
                :timestamp-config="timestampConfig"
                :link-options="linkOptions"
                :accept-image-types="'.png, .jpeg'"
                @onImageClicked="onImageClicked"
                @onImageSelected="onImageSelected"
                @onMessageSubmit="onMessageSubmit"
                @onType="onType"
                @onClose="onClose"
              />
            </div>
            <div class="ml-3 mr-3 mb-3">
              <div>
                <b-form-group
                  label=""
                  label-for="input-file"
                  v-if="requestUser == 1"
                >
                  <div class="row ">
                    <div class="col-8">
                      <b-form-file
                        id="input-file"
                        v-model="file"
                        :placeholder="placeholderFile"
                        @input="getBase64File"
                        class=" mt-2"
                      ></b-form-file>
                    </div>
                    <div class="col-4 mt-2">
                      <b-button
                        variant="primary"
                        class="pull-right mr-2"
                        @click="onFileSubmit()"
                        id="save_button"
                      >
                        {{ $i18n.t("REQUEST_DETAIL.BUTTON_FILE_SAVE") }}
                      </b-button>
                    </div>
                  </div>
                  <!--   <div  class="container-send-message icon-send-message">
                    <span aria-label="Send icon" role="img" class="material-design-icon send-icon">
                      <svg fill="var(--company-color)" width="25" height="25" viewBox="0 0 24 24" class="material-design-icon__svg"><path d="M2,21L23,12L2,3V10L17,12L2,14V21Z"><title>Send icon</title></path></svg>
                      </span>
                  </div>-->
                </b-form-group>
              </div>
            </div>
          </template>
        </KTPortlet>
        <!--End::Request detail-->
      </div>
      <div class="col-lg-4 col-xl-4 col-md-4">
        <KTPortlet>
          <template v-slot:body>
            <div class="row">
              <!-- v-if="requestState != 'Rozpracovaná'" -->
              <div class="col-12">
                <b-form-group label="" label-for="input-requestType">
                  <slot name="label">{{ $i18n.t("REQUEST_DETAIL.TYPE") }}</slot>
                  <b-form-input
                    id="input-requestType"
                    v-model="requestType"
                    type="text"
                    readonly
                    disabled
                    class="mt-2"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="" label-for="input-requestNumber">
                  <slot name="label">
                    {{ $i18n.t("REQUEST_DETAIL.REQUESTID") }}
                  </slot>
                  <b-form-input
                    id="input-requestNumber"
                    v-model="requestNumber"
                    type="text"
                    readonly
                    disabled
                    class="mt-2"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="" label-for="input-name">
                  <slot name="label">
                    {{ $i18n.t("REQUEST_DETAIL.RESPONSIBLE") }}
                  </slot>
                  <b-form-input
                    id="input-name"
                    v-model="responsiblePerson"
                    type="text"
                    readonly
                    disabled
                    class="mt-2"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="" label-for="input-contactEmail">
                  <slot name="label">
                    {{ $i18n.t("REQUEST_DETAIL.CONTACTMAIL") }}
                  </slot>
                  <b-form-input
                    id="input-contactEmail"
                    v-model="contactEmail"
                    type="text"
                    :placeholder="placeholderContactEmail"
                    readonly
                    disabled
                    class="mt-2"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="" label-for="input-contactMobile">
                  <slot name="label">
                    {{ $i18n.t("REQUEST_DETAIL.CONTACTPHONE") }}
                  </slot>
                  <b-form-input
                    id="input-contactMobile"
                    v-model="contactMobile"
                    type="text"
                    :placeholder="placeholderContactPhone"
                    readonly
                    disabled
                    class="mt-2"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="filesOld.length > 0">
          <template v-slot:body>
            <div>
              <h5 class="mb-4">{{ $i18n.t("NEW_REQUEST.UPLOADED") }}</h5>
              <div class="row">
                <div class="col-12 ml-2 mt-2">
                  <div v-for="file in filesOld" :key="file.FileId">
                    <a
                      href="javascript:void(0)"
                      v-on:click="downloadFile(file.FileId, file.Filename)"
                    >
                      <i class="flaticon-download poschodoch-icon-medium "></i>
                    </a>
                    <span class="ml-2">
                      {{ file.Filename }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType1">
          <template v-slot:body>
            <!-- Odpocty meracov -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{
                  "prilozit dokumentaciu" + $i18n.t("NEW_REQUEST.PARAMETERS")
                }}
              </h5>

              <b-form-group
                :label="labelMeterNr"
                label-for="input-cislo-meraca"
              >
                <b-form-input
                  id="input-cislo-meraca"
                  v-model="cisloMeracaId"
                  readonly
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                :label="labelReadingDate"
                label-for="input-datum-odpoctu"
              >
                <b-form-datepicker
                  id="input-datum-odpoctu"
                  v-model="datumOdpoctu"
                  :placeholder="placeholderDate"
                  readonly
                  disabled
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group :label="labelState" label-for="input-merac-stav">
                <b-form-input
                  id="input-merac-stav"
                  v-model="meracStav"
                  type="text"
                  readonly
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType2">
          <template v-slot:body>
            <!-- Zmena osobnych udajov -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldName"
                    label-for="input-udaje-stare-meno"
                  >
                    <b-form-input
                      id="input-udaje-stare-meno"
                      v-model="oldMeno"
                      :placeholder="placeholderOldMeno"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewName"
                    label-for="input-udaje-nove-meno"
                  >
                    <b-form-input
                      id="input-udaje-nove-meno"
                      v-model="newMeno"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldSurname"
                    label-for="input-udaje-stare-priezvisko"
                  >
                    <b-form-input
                      id="input-udaje-stare-priezvisko"
                      v-model="oldPriezvisko"
                      :placeholder="placeholderOldPriezvisko"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewSurname"
                    label-for="input-udaje-nove-priezvisko"
                  >
                    <b-form-input
                      id="input-udaje-nove-priezvisko"
                      v-model="newPriezvisko"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldStreet"
                    label-for="input-udaje-stara-ulica"
                  >
                    <b-form-input
                      id="input-udaje-stara-ulica"
                      v-model="oldUlica"
                      :placeholder="placeholderOldUlica"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewStreet"
                    label-for="input-udaje-nova-ulica"
                  >
                    <b-form-input
                      id="input-udaje-nova-ulica"
                      v-model="newUlica"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldCity"
                    label-for="input-udaje-stare-mesto"
                  >
                    <b-form-input
                      id="input-udaje-stare-mesto"
                      v-model="oldMesto"
                      :placeholder="placeholderOldMesto"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewCity"
                    label-for="input-udaje-nove-mesto"
                  >
                    <b-form-input
                      id="input-udaje-nove-mesto"
                      v-model="newMesto"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                    :label="labelOldPSC"
                    label-for="input-udaje-stare-psc"
                  >
                    <b-form-input
                      id="input-udaje-stare-psc"
                      v-model="oldPSC"
                      :placeholder="placeholderOldPSC"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="labelNewPSC"
                    label-for="input-udaje-nove-psc"
                  >
                    <b-form-input
                      id="input-udaje-nove-psc"
                      v-model="newPSC"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType3">
          <template v-slot:body>
            <!-- Nahlasenie osobomesiacov -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>

              <b-form-row>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelYear"
                    label-for="input-osobomesiace-rok"
                  >
                    <b-form-input
                      id="input-osobomesiace-rok"
                      v-model="rok"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelJanuary"
                    label-for="input-osobomesiace-januar"
                  >
                    <b-form-input
                      id="input-osobomesiace-januar"
                      v-model="januar"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelFebruary"
                    label-for="input-osobomesiace-februar"
                  >
                    <b-form-input
                      id="input-osobomesiace-februar"
                      v-model="februar"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelMarch"
                    label-for="input-osobomesiace-marec"
                  >
                    <b-form-input
                      id="input-osobomesiace-marec"
                      v-model="marec"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelApril"
                    label-for="input-osobomesiace-april"
                  >
                    <b-form-input
                      id="input-osobomesiace-april"
                      v-model="april"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelMay"
                    label-for="input-osobomesiace-maj"
                  >
                    <b-form-input
                      id="input-osobomesiace-maj"
                      v-model="maj"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelJune"
                    label-for="input-osobomesiace-jun"
                  >
                    <b-form-input
                      id="input-osobomesiace-jun"
                      v-model="jun"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelJuly"
                    label-for="input-osobomesiace-jul"
                  >
                    <b-form-input
                      id="input-osobomesiace-jul"
                      v-model="jul"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelAugust"
                    label-for="input-osobomesiace-august"
                  >
                    <b-form-input
                      id="input-osobomesiace-august"
                      v-model="august"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelSeptember"
                    label-for="input-osobomesiace-september"
                  >
                    <b-form-input
                      id="input-osobomesiace-september"
                      v-model="september"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelOctober"
                    label-for="input-osobomesiace-oktober"
                  >
                    <b-form-input
                      id="input-osobomesiace-oktober"
                      v-model="oktober"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelNovember"
                    label-for="input-osobomesiace-november"
                  >
                    <b-form-input
                      id="input-osobomesiace-november"
                      v-model="november"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-6 col-sm-3">
                  <b-form-group
                    :label="labelDecember"
                    label-for="input-osobomesiace-december"
                  >
                    <b-form-input
                      id="input-osobomesiace-december"
                      v-model="december"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType4">
          <template v-slot:body>
            <!-- Zmena osob predpisu -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <b-form-group
                    :label="labelChangeDate"
                    label-for="input-zmenaosob-datum-zmeny-od"
                  >
                    <b-form-datepicker
                      id="input-zmenaosob-datum-zmeny-od"
                      v-model="datumZmenyOd"
                      :placeholder="placeholderDate"
                      readonly
                      disabled
                    ></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <b-form-group
                    :label="labelPersonCount"
                    label-for="input-zmenaosob-pocet-osob"
                  >
                    <b-form-input
                      id="input-zmenaosob-pocet-osob"
                      v-model="pocetOsob"
                      type="text"
                      readonly
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showRequestType5">
          <template v-slot:body>
            <!-- Nahlaska na udrzbu -->
            <div>
              <h5 class="mb-4" style="display: none">
                {{ $i18n.t("NEW_REQUEST.PARAMETERS") }}
              </h5>
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <b-form-group
                    :label="labelMaintenance"
                    label-for="input-nahlaska-udrzba"
                    class="mb-0"
                  >
                    <b-form-select
                      id="input-nahlaska-udrzba"
                      v-model="maintenanceVal"
                      :options="maintenanceOptions"
                      disabled
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import FileService from "@/common/file.service";
import { Chat } from "vue-quick-chat";
import "vue-quick-chat/dist/vue-quick-chat.css";

export default {
  name: "requestDetail",
  components: {
    KTPortlet,
    Chat,
  },
  data() {
    return {
      // hide stuff
      showFile: false,
      filesOld: [],
      showRequestType1: false,
      showRequestType2: false,
      showRequestType3: false,
      showRequestType4: false,
      showRequestType5: false,
      // main vars
      requestNumber: null,
      requestUser: null,
      title: null,
      description: null,
      menuId: null,
      items: null,
      params: null,
      requestId: null,
      file: null,
      fileAttachment: null,
      fileName: "",
      base64file: null,
      fileId: null,
      requestCode: null,
      requestType: null,
      requestState: null,
      requestStateText: null,
      name: null,
      responsiblePerson: null,
      creationDate: null,
      changeDate: null,
      requestDescription: null,
      subject: null,
      email: null,
      mobile: null,
      phone: null,
      // fileDescUnavailable: this.$i18n.t("REQUEST_DETAIL.FILEUNAVAILABLEDESC"),
      contactEmail: null,
      profileEmail: null,
      placeholderContactEmail: this.$i18n.t(
        "REQUEST_DETAIL.CONTACTMAILPLACEHOLDER",
      ),
      contactMobile: null,
      profileMobile: null,
      placeholderContactPhone: this.$i18n.t(
        "REQUEST_DETAIL.CONTACTPHONEPLACEHOLDER",
      ),
      // chat part
      visible: true,
      participants: [
        {
          name: "User",
          id: 1,
          profilePicture: "/assets/media/poschodoch-custom/user_chat.svg",
        },
      ],
      myself: {
        name: "Admin",
        id: 3,
        profilePicture: "/assets/media/poschodoch-custom/admin_chat.svg",
      },
      messages: [],
      placeholderMessage: this.$i18n.t("REQUEST_DETAIL.MESSAGEPLACEHOLDER"),
      colors: {
        message: {
          myself: {
            bg: "#e4e4e4",
            text: "#48465b",
          },
          others: {
            bg: "var(--company-color)",
            text: "#fff",
          },
          messagesDisplay: {
            bg: "#fff",
          },
        },
        submitIcon: "var(--company-color)",
        submitImageIcon: "var(--company-color)",
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: true,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      toLoad: [
        /*{
          content: "First pre-defined message to show",
          myself: true,
          participantId: 3,
          timestamp: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
            hour: new Date().getHours(),
            minute: new Date().getMinutes(),
            second: 0,
            millisecond: 0,
          },
          uploaded: true,
          viewed: true,
          type: "text",
        },
        {
          content: "Hi, I've got this issue....",
          myself: false,
          participantId: 1,
          timestamp: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
            hour: new Date().getHours(),
            minute: new Date().getMinutes(),
            second: 0,
            millisecond: 0,
          },
          type: "text",
        },*/
      ],
      scrollBottom: {
        messageSent: true,
        messageReceived: false,
      },
      profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        },
      },
      timestampConfig: {
        format: "d.M.yyyy HH:mm",
        relative: false,
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          //className: "myLinkClass",
          className: "disabled",
          /*events: {
            click: function(e) {
              //alert("Link clicked! " + e);
              return ;
            },
            mouseover: function(e) {
              //alert("Link hovered! " + e);
              return ;
            },
          },*/
          format: function(value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            //if (type === "url") value = "#";
            return value;
            //return false;
          },
        },
        others: {
          className: "disabled",
          /*events: {
            click: function(e) {
              //alert("Link clicked! " + e);
              return ;
            },
            mouseover: function(e) {
              //alert("Link hovered! " + e);
              return ;
            },
          },*/
          format: function(value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
            //return false;
          },
        },
      },
      messageLogs: [],
      files: [],
      // localization variables
      labelRequestType: this.$i18n.t("NEW_REQUEST.LABEL.REQTYPE"),
      labelEmail: this.$i18n.t("NEW_REQUEST.LABEL.EMAIL"),
      // labelEmailDesc: this.$i18n.t("NEW_REQUEST.LABEL.EMAILDESC"),
      labelMobile: this.$i18n.t("NEW_REQUEST.LABEL.MOBILE"),
      labelSubject: this.$i18n.t("NEW_REQUEST.LABEL.SUBJECT"),
      labelDescription: this.$i18n.t("NEW_REQUEST.LABEL.DESCRIPTION"),
      placeholderFile: this.$i18n.t("NEW_REQUEST.UPLOADPLACEHOLDER"),
      labelFile: this.$i18n.t("NEW_REQUEST.LABEL.FILE"),
      labelMeterNr: this.$i18n.t("NEW_REQUEST.LABEL.METERNR"),
      placeholderDate: this.$i18n.t("NEW_REQUEST.DATEPLACEHOLDER"),
      labelReadingDate: this.$i18n.t("NEW_REQUEST.LABEL.READINGDATE"),
      labelState: this.$i18n.t("NEW_REQUEST.LABEL.STATE"),
      labelOldName: this.$i18n.t("NEW_REQUEST.LABEL.OLDNAME"),
      labelNewName: this.$i18n.t("NEW_REQUEST.LABEL.NEWNAME"),
      labelOldSurname: this.$i18n.t("NEW_REQUEST.LABEL.OLDSURNAME"),
      labelNewSurname: this.$i18n.t("NEW_REQUEST.LABEL.NEWSURNAME"),
      labelOldStreet: this.$i18n.t("NEW_REQUEST.LABEL.OLDSTREET"),
      labelNewStreet: this.$i18n.t("NEW_REQUEST.LABEL.NEWSTREET"),
      labelOldCity: this.$i18n.t("NEW_REQUEST.LABEL.OLDCITY"),
      labelNewCity: this.$i18n.t("NEW_REQUEST.LABEL.NEWCITY"),
      labelOldPSC: this.$i18n.t("NEW_REQUEST.LABEL.OLDPSC"),
      labelNewPSC: this.$i18n.t("NEW_REQUEST.LABEL.NEWPSC"),
      placeholderOldMeno: this.$i18n.t("NEW_REQUEST.OLDNAMEPLACEHOLDER"),
      placeholderOldPriezvisko: this.$i18n.t(
        "NEW_REQUEST.OLDSURNAMEPLACEHOLDER",
      ),
      placeholderOldUlica: this.$i18n.t("NEW_REQUEST.OLDSTREETPLACEHOLDER"),
      placeholderOldMesto: this.$i18n.t("NEW_REQUEST.OLDCITYPLACEHOLDER"),
      placeholderOldPSC: this.$i18n.t("NEW_REQUEST.OLDPSCPLACEHOLDER"),
      labelYear: this.$i18n.t("NEW_REQUEST.LABEL.YEAR"),
      labelJanuary: this.$i18n.t("COMMON.MONTHS.MONTH_1"),
      labelFebruary: this.$i18n.t("COMMON.MONTHS.MONTH_2"),
      labelMarch: this.$i18n.t("COMMON.MONTHS.MONTH_3"),
      labelApril: this.$i18n.t("COMMON.MONTHS.MONTH_4"),
      labelMay: this.$i18n.t("COMMON.MONTHS.MONTH_5"),
      labelJune: this.$i18n.t("COMMON.MONTHS.MONTH_6"),
      labelJuly: this.$i18n.t("COMMON.MONTHS.MONTH_7"),
      labelAugust: this.$i18n.t("COMMON.MONTHS.MONTH_8"),
      labelSeptember: this.$i18n.t("COMMON.MONTHS.MONTH_9"),
      labelOctober: this.$i18n.t("COMMON.MONTHS.MONTH_10"),
      labelNovember: this.$i18n.t("COMMON.MONTHS.MONTH_11"),
      labelDecember: this.$i18n.t("COMMON.MONTHS.MONTH_12"),
      labelChangeDate: this.$i18n.t("NEW_REQUEST.LABEL.CHANGEDATE"),
      labelPersonCount: this.$i18n.t("NEW_REQUEST.LABEL.PERSONCOUNT"),
      labelMaintenance: this.$i18n.t("NEW_REQUEST.LABEL.MAINTENANCE"),
      // Odpocty meracov requestTypeId == 1
      cisloMeracaId: null,
      datumOdpoctu: null,
      meracStav: null,
      // Zmena osobnych udajov requestTypeId == 2
      oldMeno: null,
      oldPriezvisko: null,
      oldUlica: null,
      oldMesto: null,
      oldPSC: null,
      newMeno: null,
      newPriezvisko: null,
      newUlica: null,
      newMesto: null,
      newPSC: null,
      // Nahlasenie osobomesiacov requestTypeId == 3
      rok: null,
      januar: null,
      februar: null,
      marec: null,
      april: null,
      maj: null,
      jun: null,
      jul: null,
      august: null,
      september: null,
      oktober: null,
      november: null,
      december: null,
      // Zmena osob predpisu requestTypeId == 4
      datumZmenyOd: null,
      pocetOsob: null,
      // Nahlaska na udrzbu requestTypeId == 5
      maintenanceVal: "C",
      maintenanceOptions: [
        { value: "B", text: this.$i18n.t("NEW_REQUEST.LABEL.RADIO1") },
        { value: "V", text: this.$i18n.t("NEW_REQUEST.LABEL.RADIO2") },
        { value: "C", text: this.$i18n.t("NEW_REQUEST.LABEL.RADIO3") },
      ],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.requestId = this.$route.query.requestId;
    this.fetchData();
  },
  methods: {
    downloadFile(docId, fileName) {
      let parameters = "?docId=" + docId.toString() + "&type=ServiceDesk";

      ApiService.setHeader();
      ApiService.get("Document", "GetDWP" + parameters).then(({ data }) => {
        let binaryContent = FileService.convertBase64ToByteArray(
          data.FileContent,
        );

        FileService.downloadFile(
          fileName,
          binaryContent,
          null,
          "document-download-link",
        );
      });
    },
    onType: function(event) {
      //here you can set any behavior
      console.log("user/participant is typing " + event);
    },
    loadMoreMessages(resolve) {
      setTimeout(() => {
        resolve(this.toLoad); //We end the loading state and add the messages
        //Make sure the loaded messages are also added to our local messages copy or they will be lost
        this.messages.unshift(...this.messageLogs);
        this.messages.unshift(...this.toLoad);
        this.toLoad = [];
      }, 1000);
    },
    onMessageSubmit: function(message) {
      /*
       * example simulating an upload callback.
       * It's important to notice that even when your message wasn't send
       * yet to the server you have to add the message into the array
       */
      if (this.requestUser == 0) {
        return;
      }
      if (this.requestId == 0) {
        return;
      }
      message.participantId = 1;
      //message.myself = false;

      this.messages.push(message);

      let postContent = {
        RequestId: this.requestId,
        Text: message.content,
        Role: "P",
        Filename: "",
        Filebinary: "",
        name: this.name,
      };

      ApiService.post("Request/SendLog", postContent).then(() => {
        console.log("POST SendLog for requestId = " + this.requestId);
      });

      /*
       * you can update message state after the server response
       */
      // timeout simulating the request
      setTimeout(() => {
        message.uploaded = true;
      }, 2000);
    },
    onFileSubmit: function() {
      //message.myself = false;
      var date = new Date();
      let message = {
        content: this.$i18n.t("REQUEST_DETAIL.FILE_ATTACHED") + this.Filename,
        myself: false,
        timestamp: {
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDay(),
          hour: date.getHours(),
          minute: date.getMinutes(),
          second: date.getSeconds(),
          millisecond: date.getMilliseconds(),
        },
        participantId: 1,
        type: "text",
      };

      //this.messages.push(message);

      let postContent = {
        RequestId: this.requestId,
        Text: "",
        Role: "P",
        Filename: this.Filename,
        Filebinary: this.base64file,
        name: this.name,
      };
      if (this.base64file != null) {
        ApiService.post("Request/SendLog", postContent).then(() => {
          console.log("POST SendLog for requestId = " + this.requestId);
          this.base64file = null;
          this.Filename = "";

          let parameters =
            "?menuId=" +
            this.menuId.toString() +
            "&requestId=" +
            this.requestId.toString();

          this.messages.push(message);

          ApiService.setHeader();
          ApiService.get("Request", "Detail" + parameters).then(({ data }) => {
            if (data.Files) this.filesOld = data.Files;
          });
        });
      }
      setTimeout(() => {
        message.uploaded = true;
      }, 2000);
    },
    onClose() {
      this.visible = false;
    },
    onImageSelected(files, message) {
      //this.showFile = true;
      let src =
        "https://149364066.v2.pressablecdn.com/wp-content/uploads/2017/03/vue.jpg";
      this.messages.push(message);

      /**
       * This timeout simulates a requisition that uploads the image file to the server.
       * It's up to you implement the request and deal with the response in order to
       * update the message status and the message URL
       */
      setTimeout(
        (res) => {
          message.uploaded = true;
          message.src = res.src;
        },
        3000,
        { src },
      );
    },
    onImageClicked(message) {
      /**
       * This is the callback function that is going to be executed when some image is clicked.
       * You can add your code here to do whatever you need with the image clicked. A common situation is to display the image clicked in full screen.
       //*/
      console.log("Image clicked", message.src);
    },
    getBase64File(file) {
      this.isEdited = true;

      //const file = ev.target.files[0];
      const reader = new FileReader();
      this.Filename = file.name;
      reader.readAsDataURL(file);
      reader.onload = (e) =>
        (this.base64file = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, ""));
    },
    getFormatService() {
      return FormatService;
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?menuId=" +
        this.menuId.toString() +
        "&requestId=" +
        this.requestId.toString();

      ApiService.setHeader();
      ApiService.get("Request", "Detail" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data;
        this.params = data.Parameters;
        this.requestCode = data.RequestCode;
        this.requestType = data.RequestTypeNazov;
        this.requestState = data.RequestState;
        this.requestUser = data.RequestUser;
        this.requestStateText = data.RequestStateText;
        this.name = data.Name;
        this.responsiblePerson = data.ResponsiblePerson;
        this.creationDate = data.CreationDate;
        this.changeDate = data.DateOfChange;
        this.requestDescription = data.RequestDescription;
        this.subject = data.Subject;
        this.email = data.Email;
        this.contactEmail = data.Email;
        this.mobile = data.MobilePhone;
        this.contactMobile = data.MobilePhone;
        this.phone = data.Phone;
        this.requestNumber = data.RequestNumber;
        if (data.DateChange) this.datumZmenyOd = data.DateChange;
        if (data.Count) this.pocetOsob = data.Count;
        if (data.Year) this.rok = data.Year;
        if (data.January) this.januar = data.January;
        if (data.February) this.februar = data.February;
        if (data.March) this.marec = data.March;
        if (data.April) this.april = data.April;
        if (data.May) this.maj = data.May;
        if (data.June) this.jun = data.June;
        if (data.July) this.jul = data.July;
        if (data.August) this.august = data.August;
        if (data.September) this.september = data.September;
        if (data.October) this.oktober = data.October;
        if (data.November) this.november = data.November;
        if (data.December) this.december = data.December;
        if (data.NameNew) this.newMeno = data.NameNew;
        if (data.SurnameNew) this.newPriezvisko = data.SurnameNew;
        if (data.StreetNew) this.newUlica = data.StreetNew;
        if (data.PostCodeNew) this.newPSC = data.PostCodeNew;
        if (data.CityNew) this.newMesto = data.CityNew;
        if (data.MeterReadingNew) this.datumOdpoctu = data.MeterReadingNew;
        if (data.StateNew) this.meracStav = data.StateNew;
        if (data.MeterId) this.cisloMeracaId = data.MeterId;
        if (data.VType) this.maintenanceVal = data.VType;

        this.fileName = data.Filename;
        //this.files = data.Files;
        if (data.Files) this.filesOld = data.Files;

        // this.fileAttachment = ;

        this.requestTypeShow(data.RequestType);
        this.participants[0].name = data.Name;

        ApiService.setHeader();
        ApiService.get("Request", "Logs" + parameters).then(({ data }) => {
          this.messageLogs = this.logsToMessages(data.RequestLogs);
          this.messages = this.logsToMessages(data.RequestLogs);
        });

        if (this.requestUser == 0) {
          var className = document.querySelector(
            "#poschodoch-chat-window_id .container-send-message",
          );
          className.style.visibility = "hidden";
        }
      });

      /*parameters = "";
      ApiService.setHeader();
      ApiService.get("Account", "Profile" + parameters).then(({ data }) => {
        this.profileEmail = data.Email;
        this.profileMobile = data.Phone;

        //if (!this.email) this.contactEmail = this.profileEmail;
        //if (!this.mobile) this.contactMobile = this.profileMobile;
      });*/

      ApiService.setHeader();
      ApiService.get("Dashboard", "UnitData").then(({ data }) => {
        if (data.NameOld) this.oldMeno = data.NameOld;
        if (data.SurnameOld) this.oldPriezvisko = data.SurnameOld;
        if (data.StreetOld) this.oldUlica = data.StreetOld;
        if (data.PSCOld) this.oldPSC = data.PSCOld;
        if (data.CityOld) this.oldMesto = data.CityOld;

        if (this.cisloMeracaId) {
          for (let i = 0; i < data.Meters.length; i++) {
            if (this.cisloMeracaId == data.Meters[i].Value)
              this.cisloMeracaId = data.Meters[i].Code;
          }
        }
      });
    },
    logsToMessages(logs) {
      let msgs = [];
      let Pid = 1;
      let ms = false;
      for (let i = 0; i < logs.length; i++) {
        if (
          /*logs[i].Role != "N" || */ this.participants[0].name != logs[i].Name
        ) {
          Pid = 3;
          ms = true;
          //this.myself.name = logs[i].Name == null ? "Admin" : logs[i].Name;
          this.myself.name = this.$i18n.t("REQUEST_DETAIL.SPR");

          //logs[i].Text = logs[i].Name == null ? "" : logs[i].Name + ': ' ;
          //this.participants[1].name = logs[i].Name
        } else {
          Pid = 1;
          ms = false;
        }
        var a = "";
        if (logs[i].Name != null) {
          a = logs[i].Name + ": ";
        }
        msgs[i] = {
          content: Pid === 3 ? a + logs[i].Text : logs[i].Text,
          myself: ms,

          participantId: Pid,
          timestamp: {
            year: logs[i].Time.substring(0, 4),
            month: logs[i].Time.substring(5, 7),
            day: logs[i].Time.substring(8, 10),
            hour: logs[i].Time.substring(11, 13),
            minute: logs[i].Time.substring(14, 16),
            second: logs[i].Time.substring(17, 19),
            millisecond: logs[i].Time.substring(20, 23),
          },
          type: "text",
        };
      }

      return msgs;
    },
    requestTypeShow(type) {
      this.showRequestType1 = false;
      this.showRequestType2 = false;
      this.showRequestType3 = false;
      this.showRequestType4 = false;
      this.showRequestType5 = false;

      if (type == "odpocet") this.showRequestType1 = true;
      if (type == "osob_udaje") this.showRequestType2 = true;
      if (type == "osobomesiace") this.showRequestType3 = true;
      if (type == "predpis_osoby") this.showRequestType4 = true;
      if (type == "hlasudr") this.showRequestType5 = true;
    },
  },
};
</script>
