<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet
          v-bind:title="title"
          v-show="itemsColdWater || itemsHotWater || itemsHeat"
        >
          <template v-slot:body>
            <b-container
              fluid
              v-if="itemsColdWater || itemsHotWater || itemsHeat"
            >
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12" v-if="ColdVisible">
        <!--Begin::Consumption Cold Water-->
        <KTPortlet
          v-bind:title="subTitleColdWater"
          v-show="itemsColdWater || itemsColdWater === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadColdWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="itemsColdWater">
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsColdWater"
                class="poschodoch-consumption-graph"
              >
                <!--Begin::Main chart element -->
                <div :style="{ minWidth: '300px', height: '400px' }">
                  <Chart
                    ref="cold-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsColdWater"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div class="poschodoch-legend" id="cold-water-chart-legend" />
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsColdWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Consumption Cold Water-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12" v-if="HotVisible">
        <!--Begin::Consumption Hot water-->
        <KTPortlet
          v-bind:title="subTitleHotWater"
          v-show="itemsHotWater || itemsHotWater === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadHotWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="itemsHotWater">
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHotWater"
                class="poschodoch-consumption-graph"
              >
                <!--Begin::Main chart element -->
                <div :style="{ minWidth: '300px', height: '400px' }">
                  <Chart
                    ref="hot-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHotWater"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div class="poschodoch-legend" id="hot-water-chart-legend" />
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHotWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption Hot water-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12" v-if="HeatVisible">
        <!--Begin::Consumption Heat-->
        <KTPortlet
          v-bind:title="subTitleHeat"
          v-show="itemsHeat || itemsHeat === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu :functionCSV="downloadHeatCSV"></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="itemsHeat">
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHeat"
                class="poschodoch-consumption-graph"
              >
                <!--Begin::Main chart element -->
                <div :style="{ minWidth: '300px', height: '400px' }">
                  <Chart
                    ref="heat-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHeat"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div class="poschodoch-legend" id="heat-chart-legend" />
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHeat">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Consumption Heat-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";

export default {
  name: "consumption",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      yearFrom: null,
      yearTo: null,
      charts: [1],
      // cold water
      subTitleColdWater: null,
      itemsColdWater: null,
      chartOptionsColdWater: null,
      chartLabelsColdWater: [],
      chartDatasetLabelColdWater: null,
      chartDataColdWater: [],
      // Hot water
      subTitleHotWater: null,
      itemsHotWater: null,
      chartOptionsHotWater: null,
      chartLabelsHotWater: [],
      chartDatasetLabelHotWater: null,
      chartDataHotWater: [],
      // heat
      subTitleHeat: null,
      itemsHeat: null,
      chartOptionsHeat: null,
      chartLabelsHeat: [],
      chartDatasetLabelHeat: null,
      chartDataHeat: [],
      //colors
      colorhot: null,
      colorcold: null,
      colorheat: null,
      colortooltip: null,
      ColdVisible: true,
      HotVisible: true,
      HeatVisible: true,
    };
  },
  mounted() {
    this.colorhot = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.danger");
    this.colorcold = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-cold")
      : this.layoutConfig("colors.state.info");
    this.colorheat = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-heat")
      : this.layoutConfig("colors.state.brand");
    this.colortooltip = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.brand");
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    createChartColdWater() {
      this.charts = [this.charts[0] + 1];
      this.chartOptionsColdWater = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.chartDataColdWater,
              label: this.chartDatasetLabelColdWater,
              fill: false,
              borderColor: this.colorcold,
              backgroundColor: this.colorcold,
            },
          ],
          labels: this.chartLabelsColdWater,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "cold-water-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colortooltip,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "CONSUMPTION.CHART_COLD_WATER.AX_X",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "CONSUMPTION.CHART_COLD_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHotWater() {
      this.chartOptionsHotWater = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.chartDataHotWater,
              label: this.chartDatasetLabelHotWater,
              fill: false,
              borderColor: this.colorhot,
              backgroundColor: this.colorhot,
            },
          ],
          labels: this.chartLabelsHotWater,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "hot-water-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colortooltip,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t("CONSUMPTION.CHART_HOT_WATER.AX_X"),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t("CONSUMPTION.CHART_HOT_WATER.AX_Y"),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHeat() {
      this.chartOptionsHeat = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.chartDataHeat,
              label: this.chartDatasetLabelHeat,
              fill: false,
              borderColor: this.colorheat,
              backgroundColor: this.colorheat,
            },
          ],
          labels: this.chartLabelsHeat,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "heat-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.colortooltip,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t("CONSUMPTION.CHART_HEAT.AX_X"),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t("CONSUMPTION.CHART_HEAT.AX_Y"),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    transformDataColdWater(data) {
      let consumptionColdWater = [];
      let labels = [];

      for (let i = 0; i < data.Consumption.length; i++) {
        consumptionColdWater.push({
          x: data.Consumption[i].Year,
          y: data.Consumption[i].Consumption,
        });
      }

      for (let i = data.ConsumptionFrom; i <= data.ConsumptionTo; i++) {
        labels.push(i);
      }

      this.chartLabelsColdWater = labels;
      this.chartDataColdWater = consumptionColdWater;
    },
    transformDataHotWater(data) {
      let consumptionHotWater = [];
      let labels = [];

      for (let i = 0; i < data.Consumption.length; i++) {
        consumptionHotWater.push({
          x: data.Consumption[i].Year,
          y: data.Consumption[i].Consumption,
        });
      }

      for (let i = data.ConsumptionFrom; i <= data.ConsumptionTo; i++) {
        labels.push(i);
      }

      this.chartLabelsHotWater = labels;
      this.chartDataHotWater = consumptionHotWater;
    },
    transformDataHeat(data) {
      let consumptionHeat = [];
      let labels = [];

      for (let i = 0; i < data.Consumption.length; i++) {
        consumptionHeat.push({
          x: data.Consumption[i].Year,
          y: data.Consumption[i].Consumption,
        });
      }

      for (let i = data.ConsumptionFrom; i <= data.ConsumptionTo; i++) {
        labels.push(i);
      }

      this.chartLabelsHeat = labels;
      this.chartDataHeat = consumptionHeat;
    },
    downloadColdWaterCSV() {
      this.downloadCSV(
        this.subTitleColdWater,
        this.itemsColdWater,
        this.$i18n.t("CONSUMPTION.CHART_COLD_WATER.AX_X"),
        this.$i18n.t("CONSUMPTION.CHART_COLD_WATER.AX_Y"),
      );
    },
    downloadHotWaterCSV() {
      this.downloadCSV(
        this.subTitleHotWater,
        this.itemsHotWater,
        this.$i18n.t("CONSUMPTION.CHART_HOT_WATER.AX_X"),
        this.$i18n.t("CONSUMPTION.CHART_HOT_WATER.AX_Y"),
      );
    },
    downloadHeatCSV() {
      this.downloadCSV(
        this.subTitleHeat,
        this.itemsHeat,
        this.$i18n.t("CONSUMPTION.CHART_HEAT.AX_X"),
        this.$i18n.t("CONSUMPTION.CHART_HEAT.AX_Y"),
      );
    },
    downloadCSV(title, items, x, y) {
      let header = [];
      let rows = [];

      header.push(x, y);

      if (items) {
        for (let i = parseInt(this.yearFrom); i <= parseInt(this.yearTo); i++) {
          let item = items.find(function(v) {
            return v.Year == i;
          });
          let value = item
            ? FormatService.formatNumberToLocalForCSV(item.Consumption)
            : "";
          rows.push([i, value]);
        }
      }

      let result = FileService.generateCSV(header, rows);
      let fileName = title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(selectedYear = null) {
      // cold water
      this.itemsColdWater = null;
      this.chartOptionsColdWater = null;
      let parameters = "?type=S&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "Consumption" + parameters)
        .then(({ data }) => {
          this.transformDataColdWater(data);
          this.chartDatasetLabelColdWater = data.Medium;
          this.createChartColdWater();

          this.title = data.Title;
          this.subTitleColdWater = data.Subtitle;
          this.description = data.Description;
          this.itemsColdWater = data.Consumption;

          if (this.itemsColdWater.length == 0) {
            this.ColdVisible = false;
          }

          this.yearFrom = data.ConsumptionFrom;
          this.yearTo = data.ConsumptionTo;
        })
        .catch(() => {
          this.itemsColdWater = undefined;
        });

      // Hot water
      this.itemsHotWater = null;
      this.chartOptionsHotWater = null;
      parameters = "?type=T&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "Consumption" + parameters)
        .then(({ data }) => {
          this.transformDataHotWater(data);
          this.chartDatasetLabelHotWater = data.Medium;
          this.createChartHotWater();

          this.subTitleHotWater = data.Subtitle;
          this.itemsHotWater = data.Consumption;

          if (this.itemsHotWater.length == 0) {
            this.HotVisible = false;
          }

          if (!this.itemsColdWater) {
            this.title = data.Title;
            this.description = data.Description;
            this.yearFrom = data.ConsumptionFrom;
            this.yearTo = data.ConsumptionTo;
          }
        })
        .catch(() => {
          this.itemsHotWater = undefined;
        });

      // heat
      this.itemsHeat = null;
      this.chartOptionsHeat = null;
      parameters = "?type=U&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "Consumption" + parameters)
        .then(({ data }) => {
          this.transformDataHeat(data);
          this.chartDatasetLabelHeat = data.Medium;
          this.createChartHeat();

          this.subTitleHeat = data.Subtitle;
          this.itemsHeat = data.Consumption;

          if (this.itemsHeat.length == 0) {
            this.HeatVisible = false;
          }

          if (!this.itemsColdWater && !this.itemsHotWater) {
            this.title = data.Title;
            this.description = data.Description;
            this.yearFrom = data.ConsumptionFrom;
            this.yearTo = data.ConsumptionTo;
          }
        })
        .catch(() => {
          this.itemsHeat = undefined;
        });
    },
  },
};
</script>
