export const ADD_API_ERROR = "addApiError";
export const SAVE_API_ERROR = "setApiError";
export const CLEAR_API_ERROR = "clearApiError";
export const REMOVEALL_API_ERROR = "removeallApiError";

export default {
  state: {
    apiErrors: [],
  },
  actions: {
    [ADD_API_ERROR](context, error) {
      context.commit(SAVE_API_ERROR, error);
    },
    [CLEAR_API_ERROR](context) {
      context.commit(REMOVEALL_API_ERROR);
    },
  },
  mutations: {
    [SAVE_API_ERROR](state, error) {
      state.apiErrors.push(error);
    },
    [REMOVEALL_API_ERROR](state) {
      state.apiErrors = [];
    },
  },
};
