<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Odpocty meracov-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row class="mb-2">
                <b-col class="pt-2 pt-lg-2 pt-sm-4">
                  <!--Begin::Checkbox -->
                  <b-form-checkbox
                    id="disassembled"
                    v-model="checkedDisassembled"
                  >
                    {{ $i18n.t("METERREADINGS.CHECKBOXLABEL") }}
                  </b-form-checkbox>
                  <!--End::Checkbox -->
                </b-col>
                <!--Begin::Count of rows per page -->
                <!-- <b-col class="poschodoch-rows-count">
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col> -->
                <!--End::Count of rows per page -->
              </b-row>

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="poschodoch-meter-readings-table"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Mobile)="data">
                  <div class="align-self-center">
                    <i
                      v-if="!data.item.DisassemblyDate"
                      class="fas fa-check color-success fa-lg"
                    ></i>
                    <i v-else class="fas fa-times color-danger fa-lg mr-1"></i>
                  </div>
                  <div class="ml-3 d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div class="d-flex flex-column align-items-start">
                        <a
                          href="javascript:void(0)"
                          v-if="data.item != null"
                          v-on:click="
                            showMeterReadingDetail(
                              menuId,
                              data.item.MeterId,
                              data.item.History,
                            )
                          "
                          class="kt-link poschodoch-meter-readings-title-mobile"
                        >
                          {{
                            data.value.MeterType + " " + data.value.MeterNumber
                          }}
                        </a>
                        <span>
                          {{
                            $i18n.t("METERREADINGS.TABLE.CLIMBINGIRON") +
                              ": " +
                              data.value.ClimbingIron
                          }}
                        </span>
                      </div>
                      <div
                        v-if="data.value.VerificationDate"
                        class="d-flex flex-column align-items-end"
                      >
                        <span>
                          {{ $i18n.t("METERREADINGS.TABLE.VERIFICATIONDATE") }}
                        </span>
                        <span>{{ data.value.VerificationDate }}</span>
                      </div>
                      <div
                        v-if="data.value.LastReadingDate"
                        class="d-flex flex-column align-items-end"
                      >
                        <span>
                          {{
                            $i18n.t("METERREADINGS.TABLE.LASTREADINGDATEMOBILE")
                          }}
                        </span>
                        <span>{{ data.value.LastReadingDate }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(MeterNumber)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="
                      showMeterReadingDetail(menuId, data.item.MeterId)
                    "
                    class="kt-link"
                  >
                    {{ data.item.MeterType + " " + data.value }}
                  </a>
                  <span v-if="!(data.item != null)">
                    {{ data.item.MeterType + " " + data.value }}
                  </span>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Pagination -->
              <!--               <b-row v-if="items && items.length > 0" class="justify-content-end">
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row> -->
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Odpocty meracov-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "meterreadings",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      meterReadingDetail: null,
      items: null,
      checkedDisassembled: false,
      fields: [
        {
          key: "MeterNumber",
          label: this.$i18n.t("METERREADINGS.TABLE.METERNUMBER"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "ClimbingIron",
          label: this.$i18n.t("METERREADINGS.TABLE.CLIMBINGIRON"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
        },
        {
          key: "AssemblyDate",
          label: this.$i18n.t("METERREADINGS.TABLE.ASSEMBLYDATE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DisassemblyDate",
          label: this.$i18n.t("METERREADINGS.TABLE.DISASSEMBLYDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            if (item.DisassemblyDate != null) {
              item._rowVariant = "secondary";
            } else {
              //item._rowVariant = "disassembled";
            }

            if (!this.checkedDisassembled && item.DisassemblyDate != null) {
              item._rowVariant = "disassembledUncheck";
            }

            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "VerificationDate",
          label: this.$i18n.t("METERREADINGS.TABLE.VERIFICATIONDATE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Seals",
          label: this.$i18n.t("METERREADINGS.TABLE.SEALS"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "LastReadingDate",
          label: this.$i18n.t("METERREADINGS.TABLE.LASTREADINGDATE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
      //totalRows: 1,
      //currentPage: 1,
      //perPage: 20,
      //pageOptions: [20, 40, 60],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showMeterReadingDetail(this.menuId, record.MeterId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    showMeterReadingDetail(menuId, meterId) {
      this.$router.push({
        name: "domus-meter-reading-detail",
        query: { menuId: this.menuId, meterId: meterId },
      });

      let parameters = "?menuId=" + menuId.toString();
      if (meterId != null) parameters += "&meterId=" + meterId.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "MeterReadingDetail" + parameters).then(
        ({ data }) => {
          this.meterReadingDetail = data;
        },
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      parameters += "&disCheck=" + this.checkedDisassembled.toString();

      window.open("/meterReadingsPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("METERREADINGS.TABLE.METERNUMBER"),
        this.$i18n.t("METERREADINGS.TABLE.CLIMBINGIRON"),
        this.$i18n.t("METERREADINGS.TABLE.ASSEMBLYDATE"),
        this.$i18n.t("METERREADINGS.TABLE.DISASSEMBLYDATE"),
        this.$i18n.t("METERREADINGS.TABLE.VERIFICATIONDATE"),
        this.$i18n.t("METERREADINGS.TABLE.SEALS"),
        this.$i18n.t("METERREADINGS.TABLE.LASTREADINGDATE"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.MeterNumber,
          item.ClimbingIron,
          FormatService.formatDateToLocal(item.AssemblyDate),
          FormatService.formatDateToLocal(item.DisassemblyDate),
          FormatService.formatDateToLocal(item.VerificationDate),
          item.Seals,
          FormatService.formatDateToLocal(item.LastReadingDate),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      parameters += "&disassembled=1";

      ApiService.setHeader();
      ApiService.get("Flat", "MeterReadings" + parameters).then(({ data }) => {
        this.transformData(data.MeterReadings);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.MeterReadings;

        //this.totalRows = this.items.length;
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          MeterNumber: items[i].MeterNumber,
          MeterType: items[i].MeterType,
          AssemblyDate: FormatService.formatDateToLocal(items[i].AssemblyDate),
          DisassemblyDate: items[i].DisassemblyDate,
          LastReadingDate: FormatService.formatDateToLocal(
            items[i].LastReadingDate,
          ),
          VerificationDate: FormatService.formatDateToLocal(
            items[i].VerificationDate,
          ),
          ClimbingIron: items[i].ClimbingIron,
        };
      }
    },
  },
};
</script>
