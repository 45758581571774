<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <EventsDetailDocs v-bind:eventsDetailDocs="eventsDetailDocs" />
    <EventsDetailDocsDwp v-bind:eventsDetailDocsDwp="eventsDetailDocsDwp" />
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <KTPortlet v-bind:title="eventType">
          <template v-slot:toolbar>
            <div class="kt-align-right">
              <b-button
                variant="primary"
                class="mr-2"
                @click="SendEditEvent()"
                id="edit_button"
                v-if="editButton === 1 && isReadOnly"
              >
                {{ $i18n.t("EVENT_DETAIL.EDIT") }}
              </b-button>
              <b-button
                variant="primary"
                class="mr-2"
                @click="SendDeleteRequest('delete')"
                id="delete_button"
                v-if="deleteButton === 1"
              >
                {{ $i18n.t("EVENT_DETAIL.DELETE") }}
              </b-button>
              <b-button
                variant="primary"
                class="mr-2"
                @click="SendSaveRequest('save')"
                id="save_button"
                v-if="saveButton === 1"
              >
                {{ $i18n.t("EVENT_DETAIL.SAVE") }}
              </b-button>
              <b-button variant="secondary" @click="back">
                {{ $i18n.t("EVENT_DETAIL.BACKBUTTON") }}
              </b-button>
            </div>
          </template>
          <template v-slot:body>
            <div class="row">
              <div class="col-12" v-if="isReadOnly">
                <div class="row mb-2 ml-1">
                  <!--<div class="align-self-start">
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="priority == 0"
                      class="mr-1"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #0066a1"
                      />
                    </svg>
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="priority == 1"
                      class="mr-1"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #fbbb17"
                      />
                    </svg>
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="priority == 2"
                      class="mr-1"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #c5415d"
                      />
                    </svg>
                  </div>
                  <span class="mt-2" style="font-weight: 500">
                    {{ subject }}
                  </span>
                  -->
                </div>
                <div class="row mb-2 ml-1">
                  <div class="col-lg-4 col-sm-12" v-if="responsible">
                    <img
                      width="40"
                      v-if="photo != null && photo != ''"
                      alt="Picture"
                      v-bind:src="'data:image/png;base64,' + photo"
                    />
                    <img
                      width="40"
                      v-else
                      alt="Picture"
                      src="@/assets/media/users/default.jpg"
                    />
                    <span class="mt-2 ml-1 mb-2">{{ responsible }}</span>
                  </div>

                  <div class="col-lg-4 col-sm-12 mt-2 mb-4" v-if="dateS">
                    <i
                      class="kt-nav__link-icon flaticon-calendar-3 h1 mr-3"
                    ></i>
                    <span class="mt-3">
                      {{ getFormatService().formatDateToLocal(dateS) }}
                    </span>
                  </div>
                  <div
                    class="col-lg-4 col-sm-12 mt-2 mb-4"
                    v-if="timeSpecification"
                  >
                    <i class="kt-nav__link-icon flaticon-time h1 mr-3"></i>
                    <span class="mt-3">{{ timeSpecification }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="isReadOnly && placeSpecification">
                <div class="col-12 ml-2 mt-2 mb-4">
                  <i class="kt-nav__link-icon flaticon-pin h1 mr-3"></i>
                  <span class="mt-3">{{ placeSpecification }}</span>
                </div>
              </div>
              <!--  <div class="col-6" v-if="isReadOnly">
                <div class="row mt-2 mb-4 mr-1 justify-content-end">
                  <span>{{ getFormatService().formatDateToLocal(date) }}</span>
                </div>
                <div
                  class="row mt-2 mb-4 mr-1 justify-content-end"
                  style="margin-top: 2rem !important"
                >
                  <span>{{ timeSpecification }}</span>
                </div>
                <div class="row mb-2 mr-1 justify-content-end">
                  <span>{{ placeSpecification }}</span>
                </div>
              </div> -->

              <div class="col-12 mb-3" v-if="!isReadOnly">
                <b-form-checkbox
                  id="checkKorig"
                  v-on:change="sendNotif = !sendNotif"
                  size="md"
                  style
                  class="ml-1"
                  v-bind:checked="sendNotif"
                  switch
                >
                  {{ $i18n.t("EVENT_DETAIL.NOTIFI") }}
                </b-form-checkbox>
              </div>
              <div class="col-12" v-if="!isReadOnly">
                <slot name="label" style="font-weight: 500">
                  {{ $i18n.t("EVENT_DETAIL.SUBJECT") }}
                </slot>
                <b-form-input
                  class="col-xl-6 mb-3 col-md-12 col-sm-12"
                  id="input-subject_e"
                  v-model="subject"
                  type="text"
                  @change="isEdited = true"
                  required
                ></b-form-input>
              </div>
            </div>

            <div class="col-lg-12 mt-3" v-if="isReadOnly">
              <hr class="poschodoch-separator-line mt-4 mb-4" />
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group label="" label-for="input-text">
                  <div class="row mb-2 ml-1">
                    <div class="align-self-start">
                      <svg
                        width="3px"
                        height="3rem"
                        v-if="priority == 0"
                        class="mr-1"
                      >
                        <rect
                          width="3px"
                          height="3rem"
                          class="poschodoch-rectangle"
                          style="fill: #0066a1"
                        />
                      </svg>
                      <svg
                        width="3px"
                        height="3rem"
                        v-if="priority == 1"
                        class="mr-1"
                      >
                        <rect
                          width="3px"
                          height="3rem"
                          class="poschodoch-rectangle"
                          style="fill: #fbbb17"
                        />
                      </svg>
                      <svg
                        width="3px"
                        height="3rem"
                        v-if="priority == 2"
                        class="mr-1"
                      >
                        <rect
                          width="3px"
                          height="3rem"
                          class="poschodoch-rectangle"
                          style="fill: #c5415d"
                        />
                      </svg>
                    </div>
                    <span class="mt-2" style="font-weight: 500">
                      {{ subject }}
                    </span>
                  </div>
                  <div v-if="isReadOnly" v-html="text" class="p-4"></div>
                  <editor
                    v-if="!isReadOnly"
                    id="tinyMCEID"
                    v-model="text"
                    api-key="ssm8a9c38382if53p3ovm1100t6jnzi43ig0huhllvfofint"
                    :init="{
                      height: 500,
                      menubar: false,
                      language: Lang,
                      invalid_elements: 'script',
                      //language_url : '/tinymce-vue/langs/cs.js',
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen table image',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | table | image | help',
                    }"
                  />
                </b-form-group>
                <hr class="poschodoch-separator-line mt-4 mb-4" />
              </div>
            </div>

            <div class="col-12" v-if="isReadOnly">
              <div class="row mb-2 ml-1" v-if="docCount">
                <a
                  href="javascript:void(0)"
                  v-if="eventsDetailData.DocCount > 1"
                  v-on:click="showCostDetailDocs()"
                  class="poschodoch-icon-link p-2"
                >
                  <i class="flaticon-file-2 h1 "></i>
                </a>
                <a
                  href="javascript:void(0)"
                  v-if="eventsDetailData.DocCount == 1"
                  v-on:click="
                    showSingleDoc(
                      eventsDetailData.RecKey,
                      eventsDetailData.TableName,
                    )
                  "
                  class="poschodoch-icon-link p-2"
                >
                  <i class="flaticon-download h1"></i>
                </a>
                <span class="mt-3 ml-2">
                  {{ $i18n.t("EVENT_DETAIL.FILEATTACHMENTCOMMENT") }}
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-12 col-xl-6 mb-3" v-if="!isReadOnly">
                <label for="dN" class="mr-1" style="font-weight: 500">
                  {{ $i18n.t("EVENT_DETAIL.PLACE") }}
                </label>
                <b-form-input
                  id="input-subject_e"
                  v-model="placeSpecification"
                  type="text"
                  @change="isEdited = true"
                ></b-form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6 col-xl-3" v-if="!isReadOnly">
                <label for="dF" class="mr-1" style="font-weight: 500">
                  {{ $i18n.t("EVENTS.FROM") }}
                </label>
                <b-form-input
                  id="dF"
                  v-model="dateFrom"
                  type="date"
                ></b-form-input>
              </div>
              <div class="col-sm-12 col-md-6 col-xl-3" v-if="!isReadOnly">
                <label for="dT" class="mr-1" style="font-weight: 500">
                  {{ $i18n.t("EVENTS.TO") }}
                </label>
                <b-form-input
                  id="dT"
                  v-model="dateTo"
                  type="date"
                ></b-form-input>
              </div>
            </div>

            <!--  <b-row class="justify-content-end" v-if="description">
              <b-col lg="12">
                <hr class="poschodoch-separator-line mt-4 mb-4" />
                <ul class="poschodoch-note-list">
                  <li class="poschodoch-note">{{ description }}</li>
                </ul>
              </b-col>
            </b-row>-->
          </template>
        </KTPortlet>
        <KTPortlet v-if="filesOld.length > 0 && !isReadOnly">
          <template v-slot:body>
            <div>
              <h5 class="mb-4">{{ $i18n.t("EVENT_DETAIL.UPLOADED") }}</h5>
              <div class="row">
                <div class="col-12">
                  <div class="ml-2">
                    <p v-for="file in filesOld" :key="file.FileId">
                      {{ file.Filename }}
                    </p>
                  </div>
                  <a
                    href="javascript:void(0)"
                    v-if="ShowButtonDeleteFiles"
                    @click="
                      deleteAllDoc();
                      isEdited = true;
                    "
                    class="p-2"
                  >
                    <i
                      class="flaticon2-delete color-danger poschodoch-icon-medium mr-2"
                    ></i>
                    {{ $i18n.t("EVENT_DETAIL.DELETE_FILES") }}
                  </a>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="!isReadOnly">
          <template v-slot:body>
            <div>
              <h5 class="mb-4">{{ $i18n.t("EVENT_DETAIL.UPLOAD") }}</h5>
              <div class="row">
                <div class="col-12">
                  <b-form-group>
                    <b-form-file
                      multiple
                      v-model="files"
                      :placeholder="placeholderFile"
                      @input="getBase64File"
                    ></b-form-file>
                  </b-form-group>
                </div>
                <div class="col-12" v-if="reqFileName">
                  <span>
                    {{
                      $i18n.t("EVENT_DETAIL.FILEATTACHMENTCOMMENT") +
                        " " +
                        reqFileName
                    }}
                  </span>
                  <a
                    href="javascript:void(0)"
                    @click="
                      reqFileName = '';
                      isEdited = true;
                    "
                    class="p-2"
                  >
                    <i
                      class="flaticon2-delete color-danger poschodoch-icon-medium"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>

        <!-- TU    -->

        <KTPortlet v-bind:title="title2" v-if="items && items.length > 0">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionDownload="download"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-md-12 col-12 col-xl-6 mb-3">
                <!--Begin::Main chart element -->
                <div
                  style="width: 100%; overflow-x: auto"
                  v-if="chartVotersOptions"
                  class="poschodoch-consumption-graph"
                >
                  <div :style="{ minWidth: '300px', height: '150px' }">
                    <Chart
                      ref="heat-chart"
                      v-for="c in charts"
                      :key="c"
                      v-bind:options="chartVotersOptions"
                      height="400"
                      width="0"
                    ></Chart>
                  </div>
                </div>
                <div
                  class="poschodoch-legend"
                  id="daily-consumption-chart-legend"
                />
              </div>
              <div class="col-lg-6 col-sm-12 col-md-12 col-12 col-xl-6 mb-3">
                <div class="row">
                  <!--<label class="mr-3 mb-1" style="font-weight: 500">
                    {{ $i18n.t("EVENT_DETAIL.ACTUAL") }}
                  </label>                  -->
                </div>
                <div class="row mt-3 ">
                  <label
                    class="mr-3 pt-2 col-xl-6 col-lg-8 col-sm-10 col-10 col-md-8"
                    style="font-weight: 500"
                  >
                    {{ $i18n.t("EVENT_DETAIL.ELEVOTING") }}
                  </label>
                  <label
                    class="mr-1 btn btn-label-success "
                    style="width: 50px; background-color: #0abb87 ;color: #FFFFFF"
                  >
                    {{ CountVoting }}
                  </label>
                </div>
                <div class="row">
                  <label
                    class="mr-3 pt-2 col-xl-6 col-lg-8 col-sm-10 col-10 col-md-8"
                    style="font-weight: 500"
                  >
                    {{ $i18n.t("EVENT_DETAIL.ELENOTVOTING") }}
                  </label>
                  <label
                    class="mr-1 btn btn-label-danger"
                    style="width: 50px; background-color: #C5415D; color: #FFFFFF"
                  >
                    {{ CountNotVoting }}
                  </label>
                </div>
                <div class="row mb-3">
                  <label
                    class="mr-3 pt-2 col-xl-6 col-lg-8 col-sm-10 col-10 col-md-8"
                    style="font-weight: 500"
                  >
                    {{ $i18n.t("EVENT_DETAIL.WRITINGVOTING") }}
                  </label>
                  <label
                    class="mr-1 btn btn-label-warning"
                    style="width: 50px; background-color: #fbbb17 ;color: #FFFFFF"
                  >
                    {{ Count }}
                  </label>
                </div>
              </div>
            </div>
            <b-row>
              <!--Begin::Filter -->
              <b-col sm="12" md="6" lg="4">
                <div
                  class="row ml-2 d-flex flex-row mb-3 justify-content-md-start justify-content-center"
                >
                  <b-col
                    class="d-flex flex-row align-items-center mr-md-3 mr-0"
                  >
                    <label for="search" class="mr-1" style="font-weight: 500">
                      {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                    </label>
                    <b-form-input v-model="filter"></b-form-input>
                  </b-col>
                </div>
              </b-col>
              <!--End::Filter -->

              <!--Begin::Count of rows per page -->
              <b-col class="poschodoch-rows-count">
                <b-form-group
                  v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                  label-cols-sm="10"
                  label-cols-md="10"
                  label-cols-lg="9"
                  label-cols-xl="10"
                  label-align-sm="right"
                  label-align-lg="right"
                  label-align-md="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0 mr-1"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                    @change="getFormatService().formatSetPerPage(perPage)"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <!--End::Count of rows per page -->
            </b-row>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :filter="filter"
                :filterControl="true"
                @filtered="onFiltered"
                :filterIncludedFields="filterOn"
                class="poschodoch-events-table"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Mobile)="data">
                  <div class="align-self-start">
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="data.item.Typ == '0'"
                      class="mr-3"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #C5415D"
                      />
                    </svg>
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="data.item.Typ == '1'"
                      class="mr-3"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #0abb87"
                      />
                    </svg>
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="data.item.Typ == '2'"
                      class="mr-3"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #fbbb17"
                      />
                    </svg>
                  </div>
                  <div
                    class="d-flex flex-column align-items-start justify-content-center w-100 poschodoch-voting-table-content"
                  >
                    <div
                      class="poschodoch-voting-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          {{ data.value.Name }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right"
                      >
                        <span>
                          {{}}
                        </span>
                      </div>
                    </div>

                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          {{
                            $i18n.t("EVENT_DETAIL.TOOLTIPDATE") +
                              ": " +
                              getFormatService().formatDateToLocal(
                                data.value.DateHlas,
                              )
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span>
                          {{ data.value.SposobHlas }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(Typ)="data">
                  <svg width="3px" height="3rem" v-if="data.item.Typ == '0'">
                    <rect
                      width="3px"
                      height="3rem"
                      class="poschodoch-rectangle"
                      style="fill: #C5415D"
                    />
                  </svg>
                  <svg width="3px" height="3rem" v-if="data.item.Typ == '1'">
                    <rect
                      width="3px"
                      height="3rem"
                      class="poschodoch-rectangle"
                      style="fill: #0abb87"
                    />
                  </svg>
                  <svg width="3px" height="3rem" v-if="data.item.Typ == '2'">
                    <rect
                      width="3px"
                      height="3rem"
                      class="poschodoch-rectangle"
                      style="fill: #fbbb17"
                    />
                  </svg>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description2">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description2 }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Prehlad upomienok-->
        <!--  TU  -->
      </div>
    </div>
  </div>
</template>

<script src="/path/to/tinymce-vue.min.js"></script>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import EventsDetailDocs from "@/views/pages/domus/EventsDetailDocs.vue";
import EventsDetailDocsDwp from "@/views/pages/domus/EventsDetailDocsDwp.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";
import UIService from "@/common/ui.service";
import Editor from "@tinymce/tinymce-vue";
import i18nService from "@/common/i18n.service.js";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import Loader from "@/views/components/Loader.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import Chart from "@/views/components/Chart.vue";

export default {
  name: "events-detail",
  components: {
    KTPortlet,
    EventsDetailDocs,
    EventsDetailDocsDwp,
    editor: Editor,
    Loader,
    TableContextMenu,
    Chart,
  },
  data() {
    return {
      errors: [],
      files: [],
      filesOld: [],
      filesSend: [],
      eventsDetailData: null,
      menuId: null,
      title: null,
      title2: null,
      items: null,
      itemsCsv: null,
      description: null,
      dateFrom: null,
      dateTo: null,
      inputDateFrom: null,
      inputDateTo: null,
      inputTextFilter: null,
      subject: null,
      responsible: null,
      text: null,
      eventsDetailDocs: null,
      eventsDetailDocsDwp: null,
      docCount: null,
      tableName: null,
      eventId: null,
      priority: null,
      photo: null,
      dateS: null,
      eventType: null,
      placeSpecification: null,
      timeSpecification: null,
      isReadOnly: false,
      ShowButtonDeleteFiles: true,
      reqFileName: "",
      addButton: 0,
      deleteButton: 0,
      editButton: 0,
      saveButton: 0,
      sendNotif: false,
      placeholderFile: this.$i18n.t("EVENT_DETAIL.UPLOADPLACEHOLDER"),
      EnabledFields: false,
      HtmlContent: "",
      CountVoting: 0,
      CountNotVoting: 0,
      Count: 0,
      detailID: null,
      Lang:
        i18nService.getActiveLanguage() == "cz"
          ? "cs"
          : i18nService.getActiveLanguage(),
      //Date: null,
      fields: [
        {
          key: "Typ",
          label: "",
          sortable: false,
          class: "table-no-mobile",
        },
        {
          key: "Name",
          label: this.$i18n.t("EVENT_DETAIL.NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile poschodoch-events-subject",
        },
        /*{
          key: "CisByt",
          label: this.$i18n.t("EVENT_DETAIL.OBJECT"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right, table-no-mobile poschodoch-events-notSubject",
        },*/
        {
          key: "SposobHlas",
          label: this.$i18n.t("EVENT_DETAIL.TOOLTIPHLAS"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile poschodoch-events-notSubject",
          /*formatter: (value) => {
            return value == "P" ? this.$i18n.t("EVENT_DETAIL.TOOLTIPWRITING") : this.$i18n.t("EVENT_DETAIL.TOOLTIPELE");
          },*/
        },
        {
          key: "DateHlas",
          label: this.$i18n.t("EVENT_DETAIL.TOOLTIPDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile poschodoch-events-notSubject",
          formatter: (value) => {
            return FormatService.formatDateTimeToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
      totalRows: 1,
      currentPage: 1,
      currentPageBack: null,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
      sortDirection: "",
      filter: null,
      filterOn: [],
      filteredItems: null,
      sortDesc: false,
      graf: "pie",
      chartLabels: ["January", "February", "March"],
      chartVotersOptions: null,
      charts: [1],
      chartVotersData: [],
      filter: null,
      filterOn: [],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.eventId = this.$route.query.eventId;
    this.teventId = this.$route.query.teventId;
    this.fetchData();
  },
  methods: {
    back() {
      this.$router.push({
        name: "domus-events",
        query: { menuId: this.menuId },
      });
    },
    createChart() {
      this.charts = [this.charts[0] + 1];
      this.chartVotersOptions = {
        type: "pie",
        data: {
          labels: [
            this.$i18n.t("EVENT_DETAIL.ELEVOTING"),
            this.$i18n.t("EVENT_DETAIL.ELENOTVOTING"),
            this.$i18n.t("EVENT_DETAIL.WRITINGVOTING"),
          ],
          datasets: [
            {
              data: this.chartVotersData,
              label:
                this.$i18n.t("EVENT_DETAIL.ELENOTVOTING") +
                "(" +
                this.CountVoting +
                ")",
              backgroundColor: ["#0abb87", "#C5415D", "#fbbb17"],
              defaultFontSize: 24,

              hoverOffset: 4,
            },
            /*{
            label: this.$i18n.t("EVENT_DETAIL.ELENOTVOTING") + '(' + this.CountVoting + ')',            
            borderColor: '#EF0000',
            backgroundColor: '#EF0000',
          },
          {
            label: this.$i18n.t("EVENT_DETAIL.ELENOTVOTING") + '(' + this.CountNotVoting + ')',            
            borderColor: '#00E000',
            backgroundColor: '#00E000',
          },
          {
            label: this.$i18n.t("EVENT_DETAIL.WRITINGVOTING") + '(' + this.Count + ')',            
            borderColor: '#0066a1',
            backgroundColor: '#0066a1',
          }*/
          ],
        },
        options: {
          //legendDiv: "daily-consumption-chart-legend",
          responsive: true,
          legend: false,
          /*legendCallback: UIService.createChartLegend,
        legend: {
                display: true,
                position: 'bottom',
                labels: {
                  defaultFontSize: 34,
                    // This more specific font property overrides the global property
                    font: {
                      defaultFontSize: 34,
                    }
                }
            }*/
        },
      };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteAllDoc() {
      for (let i = 0; i < this.filesOld.length; i++) {
        if (
          !this.filesOld[i].Filename.includes(
            this.$i18n.t("EVENT_DETAIL.DELETE_FILES_PRE"),
          )
        )
          this.filesOld[i].Filename =
            this.$i18n.t("EVENT_DETAIL.DELETE_FILES_PRE") +
            this.filesOld[i].Filename;
      }
      this.ShowButtonDeleteFiles = false;
    },
    getBase64File(files) {
      this.filesSend = [];
      //for (let i = 0; i < ev.target.files.length; i++){
      if (this.files.length > 5) {
        this.errors.push(this.$i18n.t("EVENT_DETAIL.TOMANYFILES"));
        return;
      }
      for (let i = 0; i < files.length; i++) {
        this.isEdited = true;

        const file = files[i];
        const reader = new FileReader();

        reader.readAsDataURL(file);
        //pridal som 2x replace do resulto, toto filtruje z URI formatu len base64
        //this.files[i].FileName = ev.target.files[i].name;
        //reader.onload = (e) => (this.FileBinary = e.target.result.replace("data:", "").replace(/^.+,/, "") );
        reader.onload = (e) =>
          this.filesSend.push({
            FileName: file.name,
            FileBinary: e.target.result
              .replace("data:", "")
              .replace(/^.+,/, ""),
          });

        /*reader.onload = function(e) {

          //this.files.push( [{ FileName: file.name,  FileBinary: e.target.result.replace("data:", "").replace(/^.+,/, "") }  ]);
        //let obj = { "FileName" : file.name,  "FileBinary" : e.target.result.replace("data:", "").replace(/^.+,/, "") } ;
        let obj = { FileName : file.name,  FileBinary : e.target.result.replace("data:", "").replace(/^.+,/, "") } ;
        this.filesSend.push(obj);          
        };*/
      }
    },
    SendEditEvent() {
      //this.isReadOnly = false;
      this.isReadOnly = !this.isReadOnly;
      this.saveButton = 1;
      //getTinymce().activeEditor.mode.set('design');
    },
    SendDeleteRequest(sendsave) {
      UIService.addButtonSpinner(sendsave + "_button");
      if (this.deleteButton === 1) {
        let postContent = {
          EventId: this.teventId,
          DeleteId: this.teventId,
        };
        ApiService.post("Object/EventEdit", postContent)
          .then(() => {
            UIService.removeButtonSpinner(sendsave + "_button");
            this.back();
          })
          .catch((error) => {
            this.errors.push("Niečo sa pokazilo. Pracujeme na tom..." + error);

            UIService.removeButtonSpinner(sendsave + "_button");
            /*if (response.data.error)
              this.errors.push(response.data.error);
            else
              this.errors.push(
                "Niečo sa pokazilo. Pracujeme na tom..."
            );*/
          });
      }
    },
    SendSaveRequest(sendsave) {
      this.EnabledFields = false;
      UIService.addButtonSpinner(sendsave + "_button");

      if (this.isReadOnly == true) {
        this.errors.push(this.$i18n.t("EVENT_DETAIL.REQUIRED"));
        UIService.removeButtonSpinner(sendsave + "_button");
        return;
      }

      if (
        (!isNaN(this.dateFrom) && isNaN(this.dateTo)) ||
        (isNaN(this.dateFrom) && !isNaN(this.dateTo))
      ) {
        this.errors.push(this.$i18n.t("EVENT_DETAIL.REQUIREDDATE"));
        UIService.removeButtonSpinner(sendsave + "_button");
        return;
      }

      if (this.subject === null || this.text === null) {
        this.errors.push(this.$i18n.t("EVENT_DETAIL.REQUIRED"));
        UIService.removeButtonSpinner(sendsave + "_button");
        return;
      }
      const totalLength = this.filesSend.reduce(function(sum, filesSend) {
        return sum + filesSend.FileBinary.length;
      }, 0);

      if (totalLength > 16000000) {
        this.errors.push(this.$i18n.t("EVENT_DETAIL.LENGTHERROR"));
        UIService.removeButtonSpinner(sendsave + "_button");
        return;
      }
      if (this.filesSend) {
        countFile = countFile + this.filesSend.length;
      }
      let countFile = 0;
      if (this.filesOld) {
        for (let i = 0; i < this.filesOld.length; i++) {
          if (
            this.filesOld[i].Filename.includes(
              this.$i18n.t("EVENT_DETAIL.DELETE_FILES_PRE"),
            )
          ) {
            this.filesOld[i].Filename = "";
            this.filesOld[i].Filebinary = "";
          } else {
            countFile++;
          }
        }
      }
      if (countFile > 5) {
        this.errors.push(this.$i18n.t("EVENT_DETAIL.TOMANYFILES"));
        UIService.removeButtonSpinner(sendsave + "_button");
        return;
      }
      if (this.filesSend) {
        for (let i = 0; i < this.filesSend.length; i++) {
          //let obj = { "Filename" : this.filesSend[i].FileName,  "Filebinary" : this.filesSend[i].FileBinary, "FileId": null } ;
          this.filesOld.push({
            FileId: i,
            Filename: this.filesSend[i].FileName,
            Filebinary: this.filesSend[i].FileBinary,
          });
        }
      }
      this.isReadOnly = true;
      var content = getTinymce().activeEditor.getContent({ format: "text" });
      let postContent = {
        EventId: this.teventId,
        DeleteId: null,
        EventType: "udalost_portal",
        EventPlace: this.placeSpecification,
        FullDescription: content,
        SmallDescription: this.subject,
        SendNotif: this.sendNotif ? 1 : 0,
        Date: this.dateFrom,
        DateTo: this.dateTo,
        Files: this.filesOld,
        HtmlContent: this.text,
      };
      ApiService.post("Object/EventEdit", postContent)
        .then(() => {
          UIService.removeButtonSpinner(sendsave + "_button");
          this.back();
        })
        .catch((error) => {
          if (error.response.data.error) {
            this.errors.push(error.response.data.error);
          } else {
            this.errors.push("Niečo sa pokazilo. Pracujeme na tom..." + error);
          }
          UIService.removeButtonSpinner(sendsave + "_button");
          this.EnabledFields = true;
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return (
        data + " " + FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    showCostDetailDocs() {
      let parameters =
        /*"?tableName=" +
        this.tableName +
        "&recKey=" +
        this.eventId.toString() +*/
        "?menuId=" + this.menuId.toString();
      if (this.eventId) {
        parameters +=
          "&recKey=" + this.eventId.toString() + "&tableName=" + this.tableName;
        ApiService.setHeader();
        ApiService.get("Document", "List" + parameters).then(({ data }) => {
          this.eventsDetailDocs = data;
          this.$bvModal.show("modal-eventsDetail-docs");
        });
      }
      if (this.teventId) {
        parameters +=
          "&recKey=" + this.teventId.toString() + "&tableName=t_udalost";
        ApiService.setHeader();
        ApiService.get("Document", "DWPList" + parameters).then(({ data }) => {
          this.eventsDetailDocsDwp = data;
          this.$bvModal.show("modal-eventsDetail-docs-dwp");
        });
      }
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        /*"&recKey=" +
        recKey.toString() +*/
        "&menuId=" +
        this.menuId.toString();
      let aApi = "";
      let aType = "";
      if (this.eventId) {
        parameters += "&recKey=" + this.eventId.toString();
        aApi = "";
        aType = "";
      }
      if (this.teventId) {
        parameters += "&recKey=" + this.teventId.toString();
        aApi = "DWP";
        aType = "&type=t_udalost";
      }

      ApiService.setHeader();
      ApiService.get("Document", aApi + "List" + parameters).then(
        ({ data }) => {
          this.eventsDetailDocs = data;

          parameters =
            "?docId=" +
            this.eventsDetailDocs.Documents[0].DocId.toString() +
            aType;

          ApiService.setHeader();
          ApiService.get("Document", "Get" + aApi + parameters).then(
            ({ data }) => {
              let binaryContent = FileService.convertBase64ToByteArray(
                data.FileContent,
              );

              let fileName = this.eventsDetailDocs.Documents[0].FileName;

              FileService.downloadFile(
                fileName,
                binaryContent,
                null,
                "document-download-link",
              );
            },
          );
        },
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      window.open("/buildingElementsPrint" + parameters);
    },
    download() {
      //836
      this.itemsCsv = null;
      let parameters = "?votingId=" + this.detailID;
      /*let postContent = {
        votingId = 836
      };*/
      ApiService.setHeader();
      ApiService.get("Voting", "GetVotingAll" + parameters).then(({ data }) => {
        if (!data) {
          return;
        }

        /*let binaryContent = FileService.convertBase64ToByteArray(
            data.Filecontent,
          );*/
        let binaryContent = FileService.convertBase64ToByteArray(
          data.Files[0].FileBinary,
        );

        FileService.downloadFile(
          data.Files[0].File,
          binaryContent,
          null,
          "document-download-link",
        );
      });
    },
    downloadCSV() {
      this.itemsCsv = null;
      let parameters = "?votingId=" + this.detailID;

      ApiService.setHeader();
      ApiService.get("Voting", "GetVotersCsv" + parameters).then(({ data }) => {
        if (!data) {
          return;
        }

        this.itemsCsv = data.Voters;
        let header = [
          this.$i18n.t("EVENT_DETAIL.CSV.OWNER"),
          this.$i18n.t("EVENT_DETAIL.CSV.EMAIL"),
          this.$i18n.t("EVENT_DETAIL.CSV.VS"),
          this.$i18n.t("EVENT_DETAIL.CSV.SPACE"),
          this.$i18n.t("EVENT_DETAIL.CSV.CUT"),
          this.$i18n.t("EVENT_DETAIL.CSV.QUESTION"),
          this.$i18n.t("EVENT_DETAIL.CSV.VOTE"),
          this.$i18n.t("EVENT_DETAIL.CSV.DATEVOTE"),
        ];

        let rows = [];
        this.itemsCsv.forEach(function(item) {
          let row = [
            item.Vlastnik,
            item.Email,
            item.VS,
            item.Priestor,
            item.Podiel,
            item.Otazka,
            item.Hlasovanie,
            FormatService.formatDateTimeToLocal(item.CasVyplnenia),
          ];
          rows.push(row);
        });

        let result = FileService.generateCSV(header, rows);
        let fileName = data.Title + ".csv";

        FileService.downloadFile(fileName, result.content, result.contentType);
      });
    },
    fetchData() {
      this.isReadOnly = true;
      //this.items = null;
      let parameters = "?menuId=" + this.menuId.toString(); // +
      /*"&eventid=" +
        this.eventId.toString();*/
      if (this.eventId) parameters += "&eventid=" + this.eventId.toString();
      if (this.teventId) parameters += "&teventid=" + this.teventId.toString();

      //if (this.eventId){
      ApiService.setHeader();
      ApiService.get("Object", "EventsDetail" + parameters).then(({ data }) => {
        //this.transformData(data.CostDetail);
        this.eventsDetailData = data;
        this.eventType = data.EventType;
        this.saveButton = 0;

        if (data.EventId === null && data.TEventId === null) {
          this.isReadOnly = false;
          this.saveButton = 1;
          //getTinymce().activeEditor.mode.set('design');
        }

        this.addButton = 0;
        this.deleteButton = data.deleteButton;
        this.editButton = data.editButton;
        this.title = data.Title;
        this.description = data.Description;
        if (data.HtmlContent) {
          this.text = data.HtmlContent;
        } else {
          if (data.Text) {
            this.text =
              "<pre class='h4' style= 'font-size: 13px !important; font-weight: 400; font-family: Poppins, Helvetica, sans-serif; '>" +
              data.Text +
              "</pre>";
          } else {
            this.text = "";
          }
        }

        this.docCount = data.DocCount;
        this.tableName = data.TableName;
        this.eventId = data.EventId;
        this.priority = data.Priority;
        this.photo = data.Photo;
        this.subject = data.Subject;
        this.responsible = data.Responsible;

        this.dateFrom = data.DateFrom;
        this.dateTo = data.DateTo;
        this.placeSpecification = data.PlaceSpecification;
        this.timeSpecification = data.TimeSpecification;
        this.dateS = data.Date;
        if (data.Files) this.filesOld = data.Files;

        if (data.PrebUkonc === "P") {
          this.title2 = this.$i18n.t("EVENT_DETAIL.TITLERUNING");
        } else {
          this.title2 = this.$i18n.t("EVENT_DETAIL.TITLEDONE");
        }
        this.detailID = data.DetailID;
        if (this.detailID) {
          this.items = null;
          parameters = "?votingId=" + this.detailID;

          ApiService.setHeader();
          ApiService.get("Voting", "GetVoters" + parameters).then(
            ({ data }) => {
              if (!data) {
                return;
              }

              this.description2 = "Vysledky hlasovania";
              this.currentPage = 1;
              /*let Voting = [];
              Voting.push({ "Object": "1","VS": "130000200","Name": "Jozef Turoci","Vote": "Hlasoval", "Type": "1"});
              Voting.push({ "Object": "2","VS": "130000200","Name": "Peter Pitonak","Vote": "Hlasoval", "Type": "1"});
              Voting.push({ "Object": "3","VS": "130000200","Name": "Marian Tatarka","Vote": "Nehlasoval", "Type": "0"});
              Voting.push({ "Object": "4","VS": "130000200","Name": "Marcela Seckarova","Vote": "Pisomne", "Type": "2"});
              this.items = Voting;*/

              this.items = data.Voters;
              this.totalRows = this.items.length;

              this.CountVoting = data.CountVoting;
              this.CountNotVoting = data.CountNotVoting;
              this.Count = data.Count;
              this.chartVotersData = [
                this.CountVoting,
                this.CountNotVoting,
                this.Count,
              ];
              /*this.CountVoting = data.CountVoting;
              this.CountNotVoting = data.CountNotVoting;
              this.Count = data.Count;*/

              this.transformData(this.items);
              this.createChart();
            },
          );
        }

        //this.items = data.CostDetail;
        // this.currentPage = 1;
        //this.totalRows = this.items.length;

        ///this.unitOfMeasure = data.UnitOfMeasure;
      });
      // }
      //detailne data k eventu
      //await ApiService.setHeader();
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].DateHlasLocale = FormatService.formatDateToLocal(
          items[i].DateHlas,
        );
        items[i].Typ =
          items[i].SposobHlas === "P"
            ? "2"
            : items[i].StavHlas == 0
            ? "0"
            : "1";
        items[i].SposobHlas =
          items[i].SposobHlas == "P"
            ? this.$i18n.t("EVENT_DETAIL.TOOLTIPWRITING")
            : this.$i18n.t("EVENT_DETAIL.TOOLTIPELE");
        items[i].Mobile = {
          Name: items[i].Name,
          CisByt: items[i].CisByt,
          Vote: items[i].Vote,
          SposobHlas: items[i].SposobHlas,
          StavHlas: items[i].StavHlas,
          Typ: items[i].Typ,
          DateHlas: items[i].DateHlas,
        };
      }
    },
  },
};
</script>
