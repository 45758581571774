var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.annualBillingDetail),expression:"annualBillingDetail"}],staticClass:"poschodoch-modal-annualBilling-detail",attrs:{"id":"modal-annualBilling-detail","hide-footer":"","size":"xl"},scopedSlots:_vm._u([(_vm.annualBillingDetail)?{key:"modal-title",fn:function(){return [(_vm.annualBillingDetail.Title)?_c('span',{staticClass:"poschodoch-modal-title"},[_vm._v(" "+_vm._s(_vm.annualBillingDetail.Title)+" ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('div',{staticClass:"d-block text-center"},[(_vm.annualBillingDetail && _vm.annualBillingDetail.AnnualBillingDetail)?_c('b-table',{attrs:{"show-empty":"","hover":"","items":_vm.annualBillingDetail.AnnualBillingDetail,"fields":_vm.fields,"empty-text":_vm.$t('COMMON.LIST.NO_RECORDS')},scopedSlots:_vm._u([{key:"custom-foot",fn:function(){return [_c('tr',{staticClass:"poschodoch-summary-row"},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("ANNUALBILLING_DETAIL.AMOUNT")))]),_c('td',{staticClass:"text-right"},[_c('span',{class:{
                  'color-danger':
                    _vm.annualBillingDetail.AmountOfPaymentOrder < 0,
                }},[_vm._v(" "+_vm._s(_vm.getFormatService().formatNumberToLocal( _vm.annualBillingDetail.AmountOfPaymentOrder ))+" ")])]),_c('td',{staticClass:"text-right"},[_c('span',{class:{
                  'color-danger': _vm.annualBillingDetail.AmountOfCost < 0,
                }},[_vm._v(" "+_vm._s(_vm.getFormatService().formatNumberToLocal( _vm.annualBillingDetail.AmountOfCost ))+" ")])]),_c('td',{staticClass:"text-right"},[_c('span',{class:{
                  'color-danger': _vm.annualBillingDetail.AmountOfDifference < 0,
                }},[_vm._v(" "+_vm._s(_vm.getFormatService().formatNumberToLocal( _vm.annualBillingDetail.AmountOfDifference ))+" ")])])]),_c('tr',{staticClass:"poschodoch-summary-row"},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("ANNUALBILLING_DETAIL.CUSTOMIZEDAMOUNT"))+" ")]),_c('td',{staticClass:"text-right"},[_c('span',{class:{
                  'color-danger':
                    _vm.annualBillingDetail.AmountOfPaymentOrder < 0,
                }},[_vm._v(" "+_vm._s(_vm.getFormatService().formatNumberToLocal( _vm.annualBillingDetail.AmountOfPaymentOrder ))+" ")])]),_c('td',{staticClass:"text-right"},[_c('span',{class:{
                  'color-danger': _vm.annualBillingDetail.CustomizedCost < 0,
                }},[_vm._v(" "+_vm._s(_vm.getFormatService().formatNumberToLocal( _vm.annualBillingDetail.CustomizedCost ))+" ")])]),_c('td',{staticClass:"text-right"},[_c('span',{class:{
                  'color-danger':
                    _vm.annualBillingDetail.CustomizedDifference < 0,
                }},[_vm._v(" "+_vm._s(_vm.getFormatService().formatNumberToLocal( _vm.annualBillingDetail.CustomizedDifference ))+" ")])])]),_c('tr',{staticClass:"poschodoch-summary-row"},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("ANNUALBILLING_DETAIL.ACCOUNTBALANCETO"))+" "+_vm._s(_vm.getFormatService().formatDateToLocal( _vm.annualBillingDetail.BalanceDate ))+" ")]),_c('td'),_c('td'),_c('td',{staticClass:"text-right"},[_c('span',{class:{
                  'color-danger':
                    _vm.annualBillingDetail.AccountBalanceBeforeBilling < 0,
                }},[_vm._v(" "+_vm._s(_vm.getFormatService().formatNumberToLocal( _vm.annualBillingDetail.AccountBalanceBeforeBilling ))+" ")])])]),_c('tr',{staticClass:"poschodoch-summary-row"},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.annualBillingDetail.Result))]),_c('td'),_c('td'),_c('td',{staticClass:"text-right"},[_c('span',{class:{
                  'color-danger': _vm.annualBillingDetail.FinalBalance < 0,
                }},[_vm._v(" "+_vm._s(_vm.getFormatService().formatNumberToLocal( _vm.annualBillingDetail.FinalBalance ))+" ")])])])]},proxy:true}],null,false,3634033110)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }