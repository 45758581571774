<template>
  <div>
    <DeactivateUnitAdmin v-bind:items="itemsUnits" />
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Identity takeover-->
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">{{ title }}</slot>
              </span>
            </h3>
          </template>
          <template v-slot:body>
            <!-- Filtre -->
            <div
              class="row d-flex flex-row mb-3 justify-content-md-start justify-content-center"
            >
              <div
                class="ml-md-2 mt-3 mt-md-0 d-flex flex-md-row flex-column col-12-xl col-12 mb-3"
              >
                <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                  <label for="customer" class="mr-1" style="font-weight: 500">
                    {{ $i18n.t("IDENTITY.LABEL.CUSTOMER") }}:
                  </label>
                  <b-form-select
                    id="customer"
                    v-model="inputCustomer"
                    :options="customerOptions"
                    @change="
                      fetchData(inputCustomer);
                      disableAccess = false;
                    "
                  ></b-form-select>
                </b-col>
                <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                  <label for="typeAccess" class="mr-1" style="font-weight: 500">
                    {{ $i18n.t("IDENTITY.LABEL.ACCESS") }}:
                  </label>
                  <b-form-select
                    id="typeAccess"
                    v-model="inputAccess"
                    :options="accessOptions"
                    :disabled="disableAccess"
                  ></b-form-select>
                </b-col>
                <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                  <label for="search" class="mr-1" style="font-weight: 500">
                    {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                  </label>
                  <b-form-input
                    v-model="inputSearch"
                    @input="fetchData(inputCustomer, inputSearch)"
                  ></b-form-input>
                </b-col>
              </div>
            </div>
            <!-- Filtre -->

            <!--Begin::Users -->
            <b-container
              fluid
              v-if="itemsUsers && (inputAccess == null || inputAccess == 'U')"
            >
              <b-row class="mt-3">
                <b-col sm="12" md="6" lg="6" style="align-self: flex-end">
                  <span class="poschodoch-identity-titles">
                    {{ $i18n.t("IDENTITY.USERS_TABLE.TITLE") }}
                  </span>
                </b-col>
                <b-col
                  class="poschodoch-rows-count"
                  v-if="itemsUsers.length > 10"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelectUsers"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPageUsers"
                      id="perPageSelectUsers"
                      size="sm"
                      :options="pageOptionsUsers"
                      @change="
                        getFormatService().formatSetPerPage(perPageUsers)
                      "
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                hover
                size="md"
                stacked="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="itemsUsers"
                :fields="fieldsUsers"
                :current-page="currentPageUsers"
                :per-page="perPageUsers"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="notranslate"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Link)="data">
                  <a
                    v-if="data"
                    href="javascript:void(0)"
                    v-on:click="tableRowClickHandler(data.item)"
                    class="kt-notification__item"
                  >
                    <i class="fas fa-user poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(RegState)="data">
                  <b-form-checkbox
                    v-model="data.item.RegState"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(UnitId)="data">
                  <b-button
                    size="sm"
                    variant="primary"
                    :id="'d_button_' + data.item.UnitId"
                    v-on:click="onDeactivate(data.item.UnitId)"
                  >
                    {{ $t("ADMINACCOUNT.DETACH") }}
                  </b-button>
                </template>
              </b-table>
              <b-row
                v-if="itemsUsers && itemsUsers.length > 10"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPageUsers"
                    :total-rows="totalRowsUsers"
                    :per-page="perPageUsers"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-container>
            <!--End::Users -->

            <!--Begin::Description -->
            <!-- <b-container fluid v-if="itemsUsers || itemsObjects || itemsAdmins">
              <b-row class="justify-content-end">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container> -->
            <!--End::Description -->
          </template>
        </KTPortlet>
        <!--End::Identity takeover-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import UIService from "@/common/ui.service";
//import JwtService from "@/common/jwt.service";
import DeactivateUnitAdmin from "@/views/pages/auth/DeactivateUnitAdmin.vue";

export default {
  name: "adminAccountList",
  components: {
    KTPortlet,
    DeactivateUnitAdmin,
  },
  data() {
    return {
      menuId: null,
      title: this.$i18n.t("IDENTITY.TITLE"),
      description: "future description 3333",
      disableAccess: true,
      customerId: null,
      itemsUsers: null,
      itemsUnits: null,
      fieldsUsers: [
        {
          key: "Email",
          label: this.$i18n.t("ADMINACCOUNT.LOGINNAME"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Code",
          label: this.$i18n.t("ADMINACCOUNT.CODE"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "RegState",
          label: this.$i18n.t("ADMINACCOUNT.STATE"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Phone",
          label: this.$i18n.t("ADMINACCOUNT.MOBIL"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "CreateDate",
          label: this.$i18n.t("ADMINACCOUNT.CREATEDATE"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Link",
          label: "",
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: () => {
            return "<Link>";
          },
        },
        {
          key: "UnitId",
          label: "",
          sortable: false,
          class: "table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Selected == true) {
              item._rowVariant = "row-active";
            }
            return value;
          },
        },
      ],

      inputCustomer: null,
      inputAccess: null,
      inputSearch: null,
      customerOptions: [
        {
          value: null,
          text: this.$i18n.t("IDENTITY.FILTER.PLACEHOLDERCUSTOMER"),
          disabled: true,
        },
      ],
      accessOptions: [
        { value: null, text: this.$i18n.t("IDENTITY.FILTER.ALL") },
        { value: "U", text: this.$i18n.t("IDENTITY.FILTER.USERS") },
        { value: "F", text: this.$i18n.t("IDENTITY.FILTER.OFFICIALS") },
        { value: "S", text: this.$i18n.t("IDENTITY.FILTER.ADMINS") },
      ],
      totalRowsUsers: 1,
      currentPageUsers: 1,
      perPageUsers: FormatService.formatGetPerPage(),
      pageOptionsUsers: FormatService.formatGetPerPageOption(),

      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchCustomers();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      //<DeactivateUnit />

      this.showUnits(record.UnitId);
    },
    pointerRowClass() {
      return "pointer-row";
    },
    showUnits(uaId) {
      let parameters = "?uaId=" + uaId.toString();
      ApiService.setHeader();
      ApiService.get("Auth", "UnitListAdmin" + parameters).then(({ data }) => {
        this.transformData(data);
        this.itemsUnits = data;
        this.$bvModal.show("modal-deactivate-unit-list-admin");
      });
    },
    fetchCustomers() {
      ApiService.setHeader();
      ApiService.get("Auth", "CustomerList").then(({ data }) => {
        this.setCustomerOptions(data.Customers);
      });
    },
    setCustomerOptions(items) {
      if (items.length != 1) {
        for (let i = 0; i < items.length; i++) {
          this.customerOptions.push({
            value: items[i].CustomerId,
            text: items[i].Code,
          });
        }
      } else {
        this.customerOptions = [];
        this.customerOptions.push({ value: null, text: items[0].Code });
        this.fetchData(items[0].CustomerId);
      }
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        // set selected unit
        /*if (items.length == 1) {
          items[i].Selected = true;
        } else {
          items[i].Selected = false;
        }*/
        items[i].Selected = false;

        //set icon
        if (items[i].UnitType == 0) {
          items[i].Icon = "poschodoch-icon-byt";
        } else {
          items[i].Icon = "flaticon-home-2";
        }

        items[i].Mobile = {
          Icon: items[i].Icon,
          VarSymb: items[i].VarSymb,
          Type: items[i].Type,
          Address: items[i].Address,
          PropertyCompany: items[i].PropertyCompany,
          UnitId: items[i].UnitId,
          ConnectedAccounts: items[i].ConnectedAccounts,
        };
      }
    },
    /*    Init() {
      ApiService.setHeader();
      ApiService.get("Auth/UnitList").then(({ data }) => {
        this.transformData(data);
        this.items = data;
      });
    },*/
    onDeactivate(uaId) {
      const answer = window.confirm(this.$i18n.t("ADMINACCOUNT.QU"));
      if (!answer) {
        return;
      }

      let postContent = {
        Type: "ALL",
        Password: uaId,
      };

      UIService.addButtonSpinner("d_button_" + uaId);
      ApiService.setHeader();
      ApiService.post("Account/DeactivateService", postContent)
        .then(() => {
          UIService.removeButtonSpinner("d_button_" + uaId);
          this.fetchData(this.inputCustomer, this.inputSearch);
        })
        .catch((error) => {
          console.log(error);
          UIService.removeButtonSpinner("d_button_" + uaId);
        });
    },
    fetchData(chosenCustomerId, search) {
      let parameters = "?menuId=" + this.menuId.toString();
      if (chosenCustomerId != null)
        parameters += "&customerId=" + chosenCustomerId.toString();
      if (search != null) parameters += "&search=" + search.toString();

      ApiService.setHeader();
      ApiService.get("Auth", "AccountList" + parameters).then(({ data }) => {
        this.itemsUsers = data.Users;

        this.totalRowsUsers = this.itemsUsers.length;
      });
    },
  },
};
</script>
