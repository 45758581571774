import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import i18nService from "@/common/i18n.service.js";
import { ADD_API_ERROR } from "@/store/api.module";
import store from "@/store";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = Vue.appConfig.API_URL;
  },

  setBaseUrl(apiUrl) {
    Vue.axios.defaults.baseURL = apiUrl;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;

    // set language from local storage
    let lang = i18nService.getActiveLanguage();
    Vue.axios.defaults.headers.common["X-Poschodoch-Language"] = lang;
  },
  setHeaderExternal(obj) {
    Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${obj}`;

    // set language from local storage
    let lang = i18nService.getActiveLanguage();
    Vue.axios.defaults.headers.common["X-Poschodoch-Language"] = lang;
  },
  setLoginHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = ``;

    // set language from local storage
    let lang = i18nService.getActiveLanguage();
    Vue.axios.defaults.headers.common["X-Poschodoch-Language"] = lang;
  },
  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(({ response }) => {
      if (response.data.error) {
        if (!response.data.error.includes("Exception IDX10609:")) {
          store.dispatch(ADD_API_ERROR, response.data.error);
        }
      } else
        store.dispatch(ADD_API_ERROR, "Niečo sa pokazilo. Pracujeme na tom...");
    });
  },
  /**
   * GET bez slash znaku (pre Google registraciu) -> tam nemoze koncit url na "/" z niakeho dovodu a inac sa neda odstranit
   * @param resource
   * @returns {*}
   */
  getNoSlash(resource) {
    return Vue.axios.get(`${resource}`).catch(({ response }) => {
      if (response.data.error) {
        store.dispatch(ADD_API_ERROR, response.data.error);
      } else
        store.dispatch(ADD_API_ERROR, "Niečo sa pokazilo. Pracujeme na tom...");
    });
  },

  /**
   * MBL doplnam to sem koli poblic strankam, ktore nepreberaju error cez add_api_error
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  getWithError(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
