<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Filter-->
        <KTPortlet
          v-bind:title="title"
          v-show="items || itemsHotWater || itemsHeat"
        >
          <template v-slot:body>
            <b-container fluid v-if="items || itemsHotWater || itemsHeat">
              <b-row>
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div class="d-flex flex-row">
                    <div
                      class="ml-md-2 mt-3 mt-md-0 d-flex flex-sm-row flex-column"
                    >
                      <span
                        class="d-flex flex-row align-items-center mr-sm-3 mr-0"
                      >
                        <label for="dF" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("CONSUMPTION_FOR_PERIOD.FROM") }}
                        </label>
                        <b-form-input
                          id="dF"
                          v-model="inputDateFrom"
                          type="date"
                          placeholder="MM-DD"
                        ></b-form-input>
                      </span>

                      <span
                        class="d-flex flex-row align-items-center mt-sm-0 mt-3"
                      >
                        <label for="dT" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("CONSUMPTION_FOR_PERIOD.TO") }}
                        </label>
                        <b-form-input
                          id="dT"
                          v-model="inputDateTo"
                          type="date"
                        ></b-form-input>
                      </span>
                      <div
                        class="d-flex align-items-sm-center align-self-center"
                      >
                        <b-button
                          variant="primary"
                          class="ml-sm-3 ml-0 mt-3 mt-sm-0"
                          v-on:click="fetchData(inputDateFrom, inputDateTo)"
                        >
                          {{ $i18n.t("CONSUMPTION_FOR_PERIOD.BUTTONOK") }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTPortlet>
        <!--End::Filter-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-xl-4 flex-grow-1">
        <!--Begin::ConsumptionForPeriod Cold W-->
        <KTPortlet v-bind:title="subTitle" v-show="items || items === null">
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="isEmptyColdWater === 0">
            <TableContextMenu
              :functionCSV="downloadColdWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container
              fluid
              v-if="isEmptyColdWater === 1"
              style="color:#616365;"
            >
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="isEmptyColdWater === 0">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsColdWater"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '200px', height: '420px' }">
                  <Chart
                    ref="consumptionFP-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsColdWater"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkKorigS"
                  v-on:change="
                    korigovaneS = !korigovaneS;
                    fetchDataS(inputDateFrom, inputDateTo);
                  "
                  size="md"
                  style=""
                  class="ml-3"
                  v-bind:checked="korigovaneS"
                  switch
                ></b-form-checkbox>
                <span>
                  {{ $i18n.t("MONTHLY_CONSUMPTION.SHOW_CORRECTED") }}
                </span>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <!--<div class="poschodoch-legend" id="consumptionFP-chart-legend" />-->
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsColdWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::ConsumptionForPeriod Cold W-->
      </div>
      <div class="col-lg-4 col-xl-4">
        <!--Begin::ConsumptionForPeriod Hot W-->
        <KTPortlet
          v-bind:title="subTitleHotWater"
          v-show="itemsHotWater || itemsHotWater === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="isEmptyHotWater === 0">
            <TableContextMenu
              :functionCSV="downloadHotWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container
              fluid
              v-if="isEmptyHotWater === 1"
              style="color:#616365;"
            >
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="isEmptyHotWater === 0">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHotWater"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '200px', height: '420px' }">
                  <Chart
                    ref="consumptionFP-hot-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHotWater"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkKorigT"
                  v-on:change="
                    korigovaneT = !korigovaneT;
                    fetchDataT(inputDateFrom, inputDateTo);
                  "
                  size="md"
                  style=""
                  class="ml-3"
                  v-bind:checked="korigovaneT"
                  switch
                ></b-form-checkbox>
                <span>
                  {{ $i18n.t("MONTHLY_CONSUMPTION.SHOW_CORRECTED") }}
                </span>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend 
              <div
                class="poschodoch-legend"
                id="consumptionFP-hot-water-chart-legend"
              />
              End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHotWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::ConsumptionForPeriod Hot W-->
      </div>
      <div class="col-lg-4 col-xl-4">
        <!--Begin::ConsumptionForPeriod Heat-->
        <KTPortlet
          v-bind:title="subTitleHeat"
          v-show="itemsHeat || itemsHeat === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar v-if="isEmptyHeat === 0">
            <TableContextMenu :functionCSV="downloadHeatCSV"></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="isEmptyHeat === 1" style="color:#616365;">
              <i
                class="flaticon2-warning"
                style="font-weight: 150; height: 120%; color: #616365"
              ></i>
              {{ $i18n.t("DAILY_CONSUMPTION.EMPTY") }}
            </b-container>
            <b-container fluid v-if="isEmptyHeat === 0">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHeat"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '200px', height: '400px' }">
                  <Chart
                    ref="consumptionFP-heat-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHeat"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <div
                style="display: flex"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-form-checkbox
                  id="checkClimaticConditions"
                  v-on:change="
                    takeClimaticConditions = !takeClimaticConditions;
                    fetchDataU(inputDateFrom, inputDateTo);
                  "
                  size="md"
                  style
                  v-bind:checked="takeClimaticConditions"
                  switch
                ></b-form-checkbox>
                <span>
                  {{
                    $i18n.t(
                      "MONTHLY_CONSUMPTION_MORE_YEARS.CHART_HEAT.TAKE_CLIMATIC_CONDITIONS",
                    )
                  }}
                </span>
                <b-form-checkbox
                  id="checkKorigU"
                  v-on:change="
                    korigovaneU = !korigovaneU;
                    fetchDataU(inputDateFrom, inputDateTo);
                  "
                  size="md"
                  style=""
                  class="ml-3"
                  v-bind:checked="korigovaneU"
                  switch
                ></b-form-checkbox>
                <span>
                  {{ $i18n.t("MONTHLY_CONSUMPTION.SHOW_CORRECTED") }}
                </span>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend 
              <div
                class="poschodoch-legend"
                id="consumptionFP-heat-chart-legend"
              />
              End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHeat">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::ConsumptionForPeriod Heat-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-xl-4 mb-4">
        <b-card
          class="poschodoch-card"
          v-if="ConsumptionStatus_showLoader == 1"
        >
          <!--Begin::Budiky Studena-->

          <KTPortlet style="display: inline">
            <template v-slot:title>
              <h3
                v-if="budikyItems"
                class="kt-portlet__head-title poschodoch-dashboard-link"
              >
                <span
                  class="poschodoch-dashboard-title"
                  style="text-decoration: none"
                >
                  <slot name="title">{{ budikyTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="budikyDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </template>
            <template v-slot:body>
              <div
                v-if="budikyItems"
                class="col-12 d-flex justify-content-center"
              >
                <vue-speedometer
                  :forceRender="true"
                  :segments="2"
                  :height="200"
                  :width="300"
                  needleColor="black"
                  valueFormat="d"
                  :needleHeightRatio="0.7"
                  :segmentColors="['#0abb87', '#C5415D']"
                  :value="getBudikyValue(budikyPercConsumption)"
                  :maxValue="100"
                  :minValue="-100"
                  :customSegmentStops="[-100, 0, 100]"
                  :currentValueText="budikyValueText"
                  :maxSegmentLabels="0"
                  valueTextFontSize="1.1rem"
                  valueTextFontWeight="400"
                  class="domus-budiky-text"
                  textColor="#48465b"
                />
              </div>
              <!--Begin::Loader -->
              <b-container fluid v-if="!budikyItems">
                <Loader />
              </b-container>
              <!--End::Loader -->
            </template>
          </KTPortlet>

          <!--End::Budiky Studena-->
        </b-card>
      </div>

      <div class="col-lg-4 col-xl-4 mb-4">
        <b-card
          class="poschodoch-card"
          v-if="ConsumptionStatus2_showLoader == 1"
        >
          <!--Begin::Budiky Tepla-->
          <KTPortlet style="display: inline">
            <template v-slot:title>
              <h3
                v-if="budikyHotItems"
                class="kt-portlet__head-title poschodoch-dashboard-link"
              >
                <span
                  class="poschodoch-dashboard-title"
                  style="text-decoration: none"
                >
                  <slot name="title">{{ budikyHotTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="budikyHotDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </template>
            <template v-slot:body>
              <div
                v-if="budikyHotItems"
                class="col-12 d-flex justify-content-center"
              >
                <vue-speedometer
                  :forceRender="true"
                  :segments="2"
                  :height="200"
                  :width="300"
                  needleColor="black"
                  valueFormat="d"
                  :needleHeightRatio="0.7"
                  :segmentColors="['#0abb87', '#C5415D']"
                  :value="getBudikyValue(budikyHotPercConsumption)"
                  :maxValue="100"
                  :minValue="-100"
                  :customSegmentStops="[-100, 0, 100]"
                  :currentValueText="budikyHotValueText"
                  :maxSegmentLabels="0"
                  valueTextFontSize="1.1rem"
                  valueTextFontWeight="400"
                  class="domus-budiky-text"
                  textColor="#48465b"
                />
              </div>
              <!--Begin::Loader -->
              <b-container fluid v-if="!budikyHotItems">
                <Loader />
              </b-container>
              <!--End::Loader -->
            </template>
          </KTPortlet>
          <!--End::Budiky Tepla-->
        </b-card>
      </div>
      <div class="col-lg-4 col-xl-4 mb-4">
        <b-card
          class="poschodoch-card"
          v-if="ConsumptionStatus3_showLoader == 1"
        >
          <!--Begin::Budiky Heat-->

          <KTPortlet style="display: inline">
            <template v-slot:title>
              <h3
                v-if="budikyHeatItems"
                class="kt-portlet__head-title poschodoch-dashboard-link"
              >
                <span
                  class="poschodoch-dashboard-title"
                  style="text-decoration: none"
                >
                  <slot name="title">{{ budikyHeatTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="budikyHeatDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </template>
            <template v-slot:body>
              <div
                v-if="budikyHeatItems"
                class="col-12 d-flex justify-content-center"
              >
                <vue-speedometer
                  :forceRender="true"
                  :segments="2"
                  :height="200"
                  :width="300"
                  needleColor="black"
                  valueFormat="d"
                  :needleHeightRatio="0.7"
                  :segmentColors="['#0abb87', '#C5415D']"
                  :value="getBudikyValue(budikyHeatPercConsumption)"
                  :maxValue="100"
                  :minValue="-100"
                  :customSegmentStops="[-100, 0, 100]"
                  :currentValueText="budikyHeatValueText"
                  :maxSegmentLabels="0"
                  valueTextFontSize="1.1rem"
                  valueTextFontWeight="400"
                  class="domus-budiky-text"
                  textColor="#48465b"
                />
              </div>
              <!--Begin::Loader -->
              <b-container fluid v-if="!budikyHeatItems">
                <Loader />
              </b-container>
              <!--End::Loader -->
            </template>
          </KTPortlet>
        </b-card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet>
          <template v-slot:body>
            <b-container fluid v-if="description">
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";
import VueSpeedometer from "vue-speedometer";
import MenuService from "@/common/menu.service";

export default {
  name: "consumption-for-period",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    Chart,
    VueSpeedometer,
  },
  data() {
    return {
      title: null,
      description: null,
      subTitle: null,
      subTitleHotWater: null,
      subTitleHeat: null,
      menuId: null,
      items: null,
      itemsHotWater: null,
      itemsHeat: null,
      inputDateFrom: FormatService.formatDateForInput(
        new Date(new Date().getFullYear(), 0, 1),
      ), // "2020-01-01"
      inputDateTo: FormatService.formatDateForInput(new Date()),
      chartOptionsColdWater: null,
      chartOptionsHotWater: null,
      chartOptionsHeat: null,
      chartLabels: [],
      chartLabelsHotWater: [],
      chartLabelsHeat: [],
      chartDatasetLabelHotWater: this.$i18n.t(
        "MONTHLY_CONSUMPTION.CHART_WATER.HOT_WATER",
      ),
      charts: [1],
      chartDatasetLabelColdWater: this.$i18n.t(
        "MONTHLY_CONSUMPTION.CHART_WATER.COLD_WATER",
      ),
      chartDataColdWater: [],
      chartDataHotWater: [],
      datasetsHeat: [],
      isEmptyHeat: null,
      isEmptyColdWater: null,
      isEmptyHotWater: null,
      korigovaneS: false,
      korigovaneT: false,
      korigovaneU: false,
      takeClimaticConditions: false,
      // budiky Studena
      budikyTitle: "title",
      budikyDescription: "description",
      budikyItems: null,
      budikyActualConsumption: null,
      budikyDiffConsumption: null,
      budikyPercConsumption: null,
      budikyValueText: null,
      budikyLastReadingDate: null,
      // budiky Tepla
      budikyHotTitle: "title",
      budikyHotDescription: "description",
      budikyHotItems: null,
      budikyHotActualConsumption: null,
      budikyHotDiffConsumption: null,
      budikyHotPercConsumption: null,
      budikyHotValueText: null,
      budikyHotLastReadingDate: null,
      // budiky Heat
      budikyHeatTitle: "title",
      budikyHeatDescription: "description",
      budikyHeatItems: null,
      budikyHeatActualConsumption: null,
      budikyHeatDiffConsumption: null,
      budikyHeatPercConsumption: null,
      budikyHeatValueText: null,
      budikyHeatLastReadingDate: null,
      ConsumptionStatus_showLoader: 0,
      ConsumptionStatus2_showLoader: 0,
      ConsumptionStatus3_showLoader: 0,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    showHideDatasetColdWater(index) {
      this.chartOptionsColdWater.data.datasets[index].hidden = !this
        .chartOptionsColdWater.data.datasets[index].hidden;
      this.chartOptionsColdWater.chart.update();
    },
    showHideDatasetHotWater(index) {
      this.chartOptionsHotWater.data.datasets[index].hidden = !this
        .chartOptionsHotWater.data.datasets[index].hidden;
      this.chartOptionsHotWater.chart.update();
    },
    showHideDatasetHeat(index) {
      this.chartOptionsHeat.data.datasets[index].hidden = !this.chartOptionsHeat
        .data.datasets[index].hidden;
      this.chartOptionsHeat.chart.update();
    },
    getBudikyValue(percentage) {
      let PercV = 0;
      PercV = percentage;
      if (percentage > 1) PercV = 1;
      if (percentage < -1) PercV = -1;

      return parseFloat(PercV * 100);
    },
    createChart() {
      // this.charts = [this.charts[0] + 1];
      this.chartOptionsColdWater = {
        type: "bar",
        data: {
          datasets: this.chartDataColdWater,
          labels: this.chartLabels,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,

          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "MONTHLY_CONSUMPTION.CHART_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHotWater() {
      //this.charts = [this.charts[0] + 1];
      this.chartOptionsHotWater = {
        type: "bar",
        data: {
          datasets: this.chartDataHotWater,
          labels: this.chartLabelsHotWater,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,

          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "MONTHLY_CONSUMPTION.CHART_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHeat() {
      //this.charts = [this.charts[0] + 1];
      this.chartOptionsHeat = {
        type: "bar",
        data: {
          datasets: this.datasetsHeat,
          labels: this.chartLabelsHeat,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,

          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                },
                stacked: false,
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "MONTHLY_CONSUMPTION.CHART_ROOMS.AX_Y",
                  ),
                },
                stacked: false,
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    transformData(data) {
      let yearLabels = [];
      let consumptionColdWater = [];
      let coldWaterData = [];
      let labels = [];
      this.isEmptyColdWater = 1;

      for (let i = 0; i < data.Series.length; i++) {
        yearLabels.push(data.Series[i].Year);

        coldWaterData.push({
          x: data.Series[i].Year,
          y: data.Series[i].TotalConsumption,
        });
        if (data.Series[i].TotalConsumption != null) {
          this.isEmptyColdWater = 0;
        }
      }

      labels.push(
        FormatService.formatDateToDDMM(data.DateFrom) +
          " - " +
          FormatService.formatDateToDDMM(data.DateTo),
      );

      consumptionColdWater.push({
        data: coldWaterData,
        label: labels,
        fill: false,
        borderColor: this.layoutConfig("colors.state.info"),
        backgroundColor: this.layoutConfig("colors.state.info"),
      });

      this.chartLabels = yearLabels;
      this.chartDataColdWater = consumptionColdWater;
    },
    transformDataHotWater(data) {
      let consumptionHotWater = [];
      let yearLabels = [];
      let hotWaterData = [];
      let labels = [];
      this.isEmptyHotWater = 1;

      for (let i = 0; i < data.Series.length; i++) {
        yearLabels.push(data.Series[i].Year);

        hotWaterData.push({
          x: data.Series[i].Year,
          y: data.Series[i].TotalConsumption,
        });
        if (data.Series[i].TotalConsumption != null) {
          this.isEmptyHotWater = 0;
        }
      }

      labels.push(
        FormatService.formatDateToDDMM(data.DateFrom) +
          " - " +
          FormatService.formatDateToDDMM(data.DateTo),
      );

      consumptionHotWater.push({
        data: hotWaterData,
        label: labels,
        fill: false,
        borderColor: this.layoutConfig("colors.state.danger"),
        backgroundColor: this.layoutConfig("colors.state.danger"),
      });

      this.chartLabelsHotWater = yearLabels;
      this.chartDataHotWater = consumptionHotWater;
    },
    transformDataHeat(data) {
      let roomLabels = [];
      let heatData = [];
      let labels = [];
      let roomDatasets = [];
      this.isEmptyHeat = 1;

      for (let i = 0; i < data.Series.length; i++) {
        roomLabels.push(data.Series[i].Year);

        heatData.push({
          x: data.Series[i].Year,
          y: data.Series[i].TotalConsumption,
        });
        if (data.Series[i].TotalConsumption != null) {
          this.isEmptyHeat = 0;
        }
      }

      labels.push(
        FormatService.formatDateToDDMM(data.DateFrom) +
          " - " +
          FormatService.formatDateToDDMM(data.DateTo),
      );

      roomDatasets.push({
        data: heatData,
        label: labels,
        fill: false,
        borderColor: this.layoutConfig("colors.state.brand"),
        backgroundColor: this.layoutConfig("colors.state.brand"),
      });

      this.datasetsHeat = roomDatasets;
      this.chartLabelsHeat = roomLabels;
    },
    downloadColdWaterCSV() {
      this.downloadCSV(
        this.subTitle,
        this.items,
        this.$i18n.t("MONTHLY_CONSUMPTION.CHART_WATER.AX_X"),
        this.$i18n.t("MONTHLY_CONSUMPTION.CHART_WATER.AX_Y"),
      );
    },
    downloadHotWaterCSV() {
      this.downloadCSV(
        this.subTitleHotWater,
        this.itemsHotWater,
        this.$i18n.t("MONTHLY_CONSUMPTION.CHART_WATER.AX_X"),
        this.$i18n.t("MONTHLY_CONSUMPTION.CHART_WATER.AX_Y"),
      );
    },
    downloadHeatCSV() {
      this.downloadCSV(
        this.subTitleHeat,
        this.itemsHeat,
        this.$i18n.t("MONTHLY_CONSUMPTION.CHART_ROOMS.AX_X"),
        this.$i18n.t("MONTHLY_CONSUMPTION.CHART_ROOMS.AX_Y"),
      );
    },
    downloadCSV(title, items, x, y) {
      let header = [];
      let rows = [];

      header.push(x, y);

      if (items) {
        items.forEach(function(item) {
          rows.push([
            item.Year,
            FormatService.formatNumberToLocalForCSV(item.TotalConsumption),
          ]);
        });
      }

      let result = FileService.generateCSV(header, rows);
      let fileName = title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(sDateFrom, sDateTo) {
      // cold water
      this.items = null;
      this.chartOptionsColdWater = null;
      let parameters = "?type=S&menuId=" + this.menuId.toString();

      if (sDateFrom == null) sDateFrom = this.inputDateFrom;
      if (sDateTo == null) sDateTo = this.inputDateTo;

      parameters += "&dateFrom=" + sDateFrom;
      parameters += "&dateTo=" + sDateTo;

      ApiService.setHeader();
      ApiService.get("Flat", "PeriodConsumptionYears" + parameters)
        .then(({ data }) => {
          this.transformData(data);
          this.createChart();

          this.inputDateFrom = data.DateFrom;
          this.inputDateTo = data.DateTo;
          this.title = data.Title;
          this.description = data.Description;
          this.subTitle = data.Subtitle;
          this.items = data.Series;
        })
        .catch(() => {
          this.items = undefined;
        });

      // hot water
      this.itemsHotWater = null;
      this.chartOptionsHotWater = null;
      let parameters2 = "?type=T&menuId=" + this.menuId.toString();

      parameters2 += "&dateFrom=" + sDateFrom;
      parameters2 += "&dateTo=" + sDateTo;

      ApiService.get("Flat", "PeriodConsumptionYears" + parameters2)
        .then(({ data }) => {
          this.transformDataHotWater(data);
          this.createChartHotWater();

          this.subTitleHotWater = data.Subtitle;
          this.itemsHotWater = data.Series;
          this.inputDateFrom = data.DateFrom;
          this.inputDateTo = data.DateTo;
          if (!this.items) {
            this.title = data.Title;
            this.description = data.Description;
          }
        })
        .catch(() => {
          this.itemsHotWater = undefined;
        });

      // heat
      this.itemsHeat = null;
      this.chartOptionsHeat = null;
      let parameters3 = "?type=U&menuId=" + this.menuId.toString();

      parameters3 += "&dateFrom=" + sDateFrom;
      parameters3 += "&dateTo=" + sDateTo;

      ApiService.get("Flat", "PeriodConsumptionYears" + parameters3)
        .then(({ data }) => {
          this.transformDataHeat(data);
          this.createChartHeat();

          this.subTitleHeat = data.Subtitle;
          this.itemsHeat = data.Series;
          this.inputDateFrom = data.DateFrom;
          this.inputDateTo = data.DateTo;
          if (!this.items && !this.itemsHotWater) {
            this.title = data.Title;
            this.description = data.Description;
          }
        })
        .catch(() => {
          this.itemsHeat = undefined;
        });

      this.ConsumptionStatus_showLoader = 1;
      parameters =
        "?type=S&menuId=" + MenuService.getMenuID("ConsumptionStatus");
      ApiService.get("Flat", "ConsumptionStatus" + parameters)
        .then(({ data }) => {
          this.budikyTitle = data.Title;
          this.budikyDescription = data.Description;
          this.budikyItems = data;
          if (!this.budikyItems) this.ConsumptionStatus_showLoader = 0;
          this.budikyActualConsumption = parseFloat(data.ActualConsumption);
          this.budikyDiffConsumption = parseFloat(data.DiffConsumption);
          this.budikyPercConsumption = parseFloat(data.PercConsumption);
          this.budikyLastReadingDate = data.LastReadingDate;

          this.budikyValueText =
            this.$i18n.t("SPEEDOMETER.VALUETEXT") +
            this.getFormatService().formatNumberToLocal(
              this.budikyActualConsumption,
            ) +
            " " +
            data.Unit +
            " (";
          if (this.budikyPercConsumption > 0) this.budikyValueText += "+";
          this.budikyValueText +=
            Math.round(this.budikyPercConsumption * 100) + "%)";
        })
        .catch(({ response }) => {
          if (response.data.error)
            this.errors.push(response.data.error + " (ConsumptionStatus)");
          else
            this.errors.push(
              this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (ConsumptionStatus)",
            );
        });

      this.ConsumptionStatus2_showLoader = 1;
      parameters =
        "?type=T&menuId=" + MenuService.getMenuID("ConsumptionStatus");
      ApiService.get("Flat", "ConsumptionStatus" + parameters)
        .then(({ data }) => {
          this.budikyHotTitle = data.Title;
          this.budikyHotDescription = data.Description;
          this.budikyHotItems = data;
          if (!this.budikyHotItems) this.ConsumptionStatus2_showLoader = 0;
          this.budikyHotActualConsumption = parseFloat(data.ActualConsumption);
          this.budikyHotDiffConsumption = parseFloat(data.DiffConsumption);
          this.budikyHotPercConsumption = parseFloat(data.PercConsumption);
          this.budikyHotLastReadingDate = data.LastReadingDate;

          this.budikyHotValueText =
            this.$i18n.t("SPEEDOMETER.VALUETEXT") +
            this.getFormatService().formatNumberToLocal(
              this.budikyHotActualConsumption,
            ) +
            " " +
            data.Unit +
            " (";
          if (this.budikyHotPercConsumption > 0) this.budikyHotValueText += "+";
          this.budikyHotValueText +=
            Math.round(this.budikyHotPercConsumption * 100) + "%)";
        })
        .catch(({ response }) => {
          if (response.data.error)
            this.errors.push(response.data.error + " (ConsumptionStatus)");
          else
            this.errors.push(
              this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (ConsumptionStatus)",
            );
        });

      this.ConsumptionStatus3_showLoader = 1;
      parameters =
        "?type=U&menuId=" + MenuService.getMenuID("ConsumptionStatus");
      ApiService.get("Flat", "ConsumptionStatus" + parameters)
        .then(({ data }) => {
          this.budikyHeatTitle = data.Title;
          this.budikyHeatDescription = data.Description;
          this.budikyHeatItems = data;
          if (!this.budikyHeatItems) this.ConsumptionStatus3_showLoader = 0;
          this.budikyHeatActualConsumption = parseFloat(data.ActualConsumption);
          this.budikyHeatDiffConsumption = parseFloat(data.DiffConsumption);
          this.budikyHeatPercConsumption = parseFloat(data.PercConsumption);
          this.budikyHeatLastReadingDate = data.LastReadingDate;

          this.budikyHeatValueText =
            this.$i18n.t("SPEEDOMETER.VALUETEXT") +
            this.getFormatService().formatNumberToLocal(
              this.budikyHeatActualConsumption,
            ) +
            " " +
            data.Unit +
            " (";
          if (this.budikyHeatPercConsumption > 0)
            this.budikyHeatValueText += "+";
          this.budikyHeatValueText +=
            Math.round(this.budikyHeatPercConsumption * 100) + "%)";
        })
        .catch(({ response }) => {
          if (response.data.error)
            this.errors.push(response.data.error + " (ConsumptionStatus)");
          else
            this.errors.push(
              this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (ConsumptionStatus)",
            );
        });
    },
    fetchDataS(sDateFrom, sDateTo) {
      // cold water
      this.items = null;
      this.chartOptionsColdWater = null;
      let parameters = "?type=S&menuId=" + this.menuId.toString();
      if (this.korigovaneS) parameters += "&mode=1";

      parameters += "&dateFrom=" + sDateFrom;
      parameters += "&dateTo=" + sDateTo;

      ApiService.setHeader();
      ApiService.get("Flat", "PeriodConsumptionYears" + parameters)
        .then(({ data }) => {
          this.transformData(data);
          this.createChart();

          this.inputDateFrom = data.DateFrom;
          this.inputDateTo = data.DateTo;
          this.title = data.Title;
          this.description = data.Description;
          this.subTitle = data.Subtitle;
          this.items = data.Series;
        })
        .catch(() => {
          this.items = undefined;
        });
    },
    fetchDataT(sDateFrom, sDateTo) {
      // hot water
      this.itemsHotWater = null;
      this.chartOptionsHotWater = null;
      let parameters = "?type=T&menuId=" + this.menuId.toString();
      if (this.korigovaneT) parameters += "&mode=1";

      parameters += "&dateFrom=" + sDateFrom;
      parameters += "&dateTo=" + sDateTo;

      ApiService.get("Flat", "PeriodConsumptionYears" + parameters)
        .then(({ data }) => {
          this.transformDataHotWater(data);
          this.createChartHotWater();

          this.subTitleHotWater = data.Subtitle;
          this.itemsHotWater = data.Series;
          this.inputDateFrom = data.DateFrom;
          this.inputDateTo = data.DateTo;
          if (!this.items) {
            this.title = data.Title;
            this.description = data.Description;
          }
        })
        .catch(() => {
          this.itemsHotWater = undefined;
        });
    },
    fetchDataU(sDateFrom, sDateTo) {
      // heat
      this.itemsHeat = null;
      this.chartOptionsHeat = null;
      let parameters = "";

      if (this.takeClimaticConditions)
        parameters = "?type=P&menuId=" + this.menuId.toString();
      else parameters = "?type=U&menuId=" + this.menuId.toString();
      if (this.korigovaneU) parameters += "&mode=1";

      parameters += "&dateFrom=" + sDateFrom;
      parameters += "&dateTo=" + sDateTo;

      ApiService.get("Flat", "PeriodConsumptionYears" + parameters)
        .then(({ data }) => {
          this.transformDataHeat(data);
          this.createChartHeat();

          this.subTitleHeat = data.Subtitle;
          this.itemsHeat = data.Series;
          this.inputDateFrom = data.DateFrom;
          this.inputDateTo = data.DateTo;
          if (!this.items && !this.itemsHotWater) {
            this.title = data.Title;
            this.description = data.Description;
          }
        })
        .catch(() => {
          this.itemsHeat = undefined;
        });
    },
  },
};
</script>
