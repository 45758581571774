var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.movementDetail),expression:"movementDetail"}],staticClass:"poschodoch-modal-movement-detail",attrs:{"id":"modal-movement-detail","hide-footer":"","size":"lg"},scopedSlots:_vm._u([(_vm.movementDetail)?{key:"modal-title",fn:function(){return [(_vm.movementDetail.NameOfMovement)?_c('span',{staticClass:"poschodoch-modal-title"},[_vm._v(" "+_vm._s(_vm.movementDetail.NameOfMovement)+" ")]):_vm._e(),(_vm.movementDetail.DueDate)?_c('span',{staticClass:"poschodoch-inline-devided-text"},[_vm._v(" "+_vm._s(_vm.getFormatService().formatDateToLocal(_vm.movementDetail.DueDate))+" ")]):_vm._e(),(_vm.movementDetail.Amount)?_c('span',{staticClass:"poschodoch-inline-devided-text"},[_vm._v(" "+_vm._s(_vm.addCurrency( _vm.getFormatService().formatNumberToLocal(_vm.movementDetail.Amount) ))+" ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('div',{staticClass:"d-block text-center"},[_c('b-tabs',{staticClass:"mt-1",attrs:{"align":"left"}},[(
            _vm.movementDetail &&
              _vm.movementDetail.Items &&
              _vm.movementDetail.Items.length > 0
          )?_c('b-tab',{attrs:{"title":_vm.$t('MOVEMENT_DETAIL.TABLE_ITEMS.TITLE')}},[_c('b-table',{attrs:{"hover":"","items":_vm.movementDetail.Items,"fields":_vm.fieldsItems}})],1):_vm._e(),(
            _vm.movementDetail &&
              _vm.movementDetail.Assignments &&
              _vm.movementDetail.Assignments.length > 0
          )?_c('b-tab',{attrs:{"title":_vm.$t('MOVEMENT_DETAIL.TABLE_ASSIGNMENTS.TITLE')}},[_c('b-table',{attrs:{"hover":"","items":_vm.movementDetail.Assignments,"fields":_vm.fieldsAssignements}})],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }