<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::UNPAID_PREPAYMENTS-->
        <KTPortlet v-bind:title="title" class="poschodoch-print">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Parameters -->
              <b-row class="mb-3">
                <b-col sm="6" md="6" lg="3" xl="3">
                  <label>{{ $t("UNPAID_PREPAYMENTS.PARAMETER.DATE") }}</label>
                  &nbsp;
                  <label>{{ parameterDate }}</label>
                </b-col>
              </b-row>
              <!--End::Parameters -->

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :refreshed="print()"
              ></b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Balances-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "unpaidPrePayments-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "User",
          label: this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.USER"),
          sortable: true,
          sortDirection: "desc",
          thStyle: { paddingTop: "37px" },
        },
        {
          key: "ApartmentNumber",
          label: this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.APARTMENT_NUMBER"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "Period",
          label: this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.PERIOD"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "DueDate",
          label: this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.DUE_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Type",
          label: this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.TYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.AMOUNT"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Paid",
          label: this.addCurrencyToColumn(
            this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.PAID"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Paid < 0) {
              item._cellVariants = { Paid: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "ToBePaid",
          label: this.addCurrencyToColumn(
            this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.TO_BE_PAID"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.ToBePaid < 0) {
              item._cellVariants = { ToBePaid: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
      ],
      sortBy: "",
      sortDirection: "desc",
      parameterDate: FormatService.formatDateToLocal(this.$route.query.dueDate),
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      let tit = "";
      let docTitle = "";

      if (this.parameterDate != null)
        parameters += "&dueDate=" + this.parameterDate.toString();

      ApiService.setHeader();
      ApiService.get("Object", "UnpaidPrePayments" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.UnpaidPrePayments;
          this.parameterDate = data.DueDate;
          tit = this.title;
        },
      );

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
