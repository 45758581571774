<template>
  <div>
    <b-modal id="modal-change-email-unit-admin" hide-footer size="lg">
      <template v-slot:modal-title>
        {{ $t("ADMINACCOUNT.MODALTITLE") + items.CustomerValue }}
      </template>

      <span v-if="items.UaID == null" class="ml-2" style="font-weight: 600">
        {{ $i18n.t("ADMINACCOUNT.NEWMANAGER") }}
      </span>

      <span v-if="items.UaID !== null" class="ml-2" style="font-weight: 600">
        {{ $i18n.t("ADMINACCOUNT.CHANGE") }}
      </span>

      <div class="d-block text-center">
        <div class="kt-notification__custom kt-space-between mt-3">
          <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
            <label for="search" class="mr-1" style="font-weight: 500">
              {{ $i18n.t("ADMINACCOUNT.EMAIL") }}:
            </label>
            <b-form-input v-model="items.UaEmail"></b-form-input>
          </b-col>
          <b-button
            variant="primary"
            :id="'unit_button'"
            class="btn-sm ml-3"
            v-on:click="
              ButtonClick(items.UaID, items.UaEmail, items.CustomerID)
            "
          >
            <span v-if="items.UaID == null">
              {{ $i18n.t("ADMINACCOUNT.BTNNEW") }}
            </span>
            <span v-if="items.UaID !== null">
              {{ $i18n.t("ADMINACCOUNT.BTNCHANGE") }}
            </span>
          </b-button>
        </div>
      </div>

      <div class="kt-notification__custom kt-space-between"></div>

      <div
        class="row d-flex flex-row mb-3 justify-content-md-end justify-content-center mt-3"
      >
        <b-row class="mt-3">
          <b-col sm="12" md="6" lg="6" style="align-self: flex-end">
            <b-button variant="secondary" v-on:click="ButtonBack()">
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
//import { CHANGE_UNIT } from "@/store/auth.module";
//import JwtService from "@/common/jwt.service";
import UIService from "@/common/ui.service";
//import { LOGOUT } from "@/store/auth.module";
//import router from '@/router';

export default {
  name: "unit-list",
  props: {
    items: {},
  },
  data() {
    return {
      menuId: null,
      inputSearch: null,
      UaEmail: null,
      //items: [],
    };
  },
  methods: {
    tableRowClickHandler(record) {
      this.changeUnit(record.UnitId, record.PortalId, record.UnitType);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    ButtonBack() {
      this.$bvModal.hide("modal-change-email-unit-admin");
    },
    ButtonClick(pID, pEmail, pCuID) {
      UIService.addButtonSpinner("unit_button");

      let postContent = {
        portalID: pID,
        email: pEmail,
        CustomerID: pCuID,
      };

      ApiService.setHeader();
      ApiService.post("Auth/ChangeCreateMail", postContent)
        .then(() => {
          UIService.removeButtonSpinner("unit_button");
          this.$bvModal.hide("modal-change-email-unit-admin");
          this.items.Close = !this.items.Close;
          //this.$root.$parent.fetchData();
          //location.reload();
        })
        .catch((error) => {
          console.log(error);
          //UIService.removeButtonSpinner("unit_button");
        });
    },
  },
  //mounted: function() {
  // loading unit list
  //this.uaId = this.$route.query.uaId;
  //this.Init();
  //this.UaEmail = items.UaEmail;
  //},
};
</script>
