import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/print",
      component: () => import("@/views/theme/Print"),
      children: [
        {
          path: "/accountPrint",
          name: "domus-account-print",
          component: require("@/views/pages/domus/AccountPrint.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            year: route.query.year,
          }),
        },
        {
          path: "/accountListManagerPrint",
          name: "domus-account-list-manager-print",
          component: require("@/views/pages/domus/AccountListManagerPrint.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            year: route.query.year,
            type: route.query.type,
          }),
        },
        {
          path: "/paymentorderPrint",
          name: "domus-paymentOrder-print",
          component: require("@/views/pages/domus/PaymentOrderPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/paymentReminderOverviewPrint",
          name: "domus-paymentReminderOverview-print",
          component: require("@/views/pages/domus/PaymentReminderOverviewPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },

        {
          path: "/paymentReminderOverviewHousePrint",
          name: "domus-paymentReminderOverviewHouse-print",
          component: require("@/views/pages/domus/PaymentReminderOverviewHousePrint.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            dueDate: route.query.dueDate,
          }),
        },
        {
          path: "/annualBillingPrint",
          name: "domus-annualBilling-print",
          component: require("@/views/pages/domus/AnnualBillingPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/meterReadingsPrint",
          name: "domus-meterReadings-print",
          component: require("@/views/pages/domus/MeterReadingsPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/bidsPrint",
          name: "domus-bids-print",
          component: require("@/views/pages/domus/BidsPrint.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/buildingElementsPrint",
          name: "domus-buildingElements-print",
          component: require("@/views/pages/domus/BuildingElementsPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/costsPrint",
          name: "domus-costs-print",
          component: require("@/views/pages/domus/CostsPrint.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/inspectionPrint",
          name: "domus-inspection-print",
          component: require("@/views/pages/domus/InspectionPrint.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/invoicesPrint",
          name: "domus-invoices-print",
          component: require("@/views/pages/domus/InvoicesPrint.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/meterDailyReadingsPrint",
          name: "domus-meter-daily-readings-print",
          component: require("@/views/pages/domus/MeterDailyReadingsPrint.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            month: route.query.month,
            year: route.query.year,
            dateFrom: route.query.dateFrom,
            dateTo: route.query.dateTo,
            Search: route.query.Search,
            type: route.query.type,
            title: route.query.title,
          }),
        },
        {
          path: "/objectAccountPrint",
          name: "domus-objectAccount-print",
          component: require("@/views/pages/domus/ObjectAccountPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/cashRegisterPrint",
          name: "domus-cashRegister-print",
          component: require("@/views/pages/domus/CashRegisterPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/objectOfficialsPrint",
          name: "domus-objectOfficials-print",
          component: require("@/views/pages/domus/ObjectOfficialsPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/repairFundPrint",
          name: "domus-repairFund-print",
          component: require("@/views/pages/domus/RepairFundPrint.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/objectAccountDetailPrint",
          name: "domus-objectAccountDetail-print",
          component: require("@/views/pages/domus/ObjectAccountDetailPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/cashRegisterDetailPrint",
          name: "domus-cashRegisterDetail-print",
          component: require("@/views/pages/domus/CashRegisterDetailPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/meterReadingDetailPrint",
          name: "domus-meterReadingDetail-print",
          component: require("@/views/pages/domus/MeterReadingDetailPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/costDetailPrint",
          name: "domus-costDetail-print",
          component: require("@/views/pages/domus/CostDetailPrint.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/votingSheetPrint",
          name: "domus-votingSheet-print",
          component: require("@/views/pages/domus/VotingSheetPrint.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            userType: route.query.userType,
            roomType: route.query.roomType,
            text1: route.query.text1,
            text2: route.query.text2,
          }),
        },
        {
          path: "/balancesPrint",
          name: "domus-balances-print",
          component: require("@/views/pages/domus/BalancesPrint.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            dueDate: route.query.dueDate,
          }),
        },
        {
          path: "/unpaidPrePaymentsPrint",
          name: "domus-unpaidPrePayments-print",
          component: require("@/views/pages/domus/UnpaidPrePaymentsPrint.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            dueDate: route.query.dueDate,
          }),
        },
        {
          path: "/unpaidPrePaymentsForUserPrint",
          name: "domus-unpaidPrePaymentsForUser-print",
          component: require("@/views/pages/domus/UnpaidPrePaymentsForUserPrint.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            dueDate: route.query.dueDate,
          }),
        },
        {
          path: "/unpaidDebtsPrint",
          name: "domus-unpaidDebts-print",
          component: require("@/views/pages/domus/UnpaidDebtsPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/unpaidDebtsObjectPrint",
          name: "domus-unpaidDebtsObject-print",
          component: require("@/views/pages/domus/UnpaidDebtsObjectPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/signatureSheetPrint",
          name: "domus-signatureSheet-print",
          component: require("@/views/pages/domus/SignatureSheetPrint.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            userType: route.query.userType,
            roomType: route.query.roomType,
            portion: route.query.portion,
            text1: route.query.text1,
          }),
        },
        {
          path: "/requestListHousePrint",
          name: "domus-requestListHouse-print",
          component: require("@/views/pages/domus/RequestListHousePrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/requestListUserPrint",
          name: "domus-requestListUser-print",
          component: require("@/views/pages/domus/RequestListUserPrint.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/dailyConsumptionObjectPrint",
          name: "domus-dailyConsumptionObject-print",
          component: require("@/views/pages/domus/DailyConsumptionObjectPrint.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            date: route.query.date,
            type: route.query.type,
          }),
        },
      ],
    },
    {
      path: "/",
      redirect: "/home",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/custom-page",
          name: "domus-custom-page",
          component: require("@/views/pages/domus-custom/Page.vue").default,
          props: (route) => ({ id: route.query.id }),
        },
        {
          path: "/documentListObject",
          name: "domus-documents-object",
          component: require("@/views/pages/domus/DocumentListObject.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/documentListUser",
          name: "domus-documents-user",
          component: require("@/views/pages/domus/DocumentListUser.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/documentListAdmin",
          name: "domus-documents-admin",
          component: require("@/views/pages/domus/DocumentListAdmin.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/account",
          name: "domus-account",
          component: require("@/views/pages/domus/Account.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/accountListManager",
          name: "domus-account_list_manager",
          component: require("@/views/pages/domus/AccountListManager.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/adminCustomerList",
          name: "domus-customer_list_admin",
          component: require("@/views/pages/domus/AdminCustomerList.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/adminCustomerDetail",
          name: "domus-customer-admin-detail",
          component: require("@/views/pages/domus/AdminCustomerDetail.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            customerPageId: route.query.customerPageId,
          }),
        },
        {
          path: "/adminEulaDetail",
          name: "domus-eula-admin-detail",
          component: require("@/views/pages/domus/AdminEulaDetail.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            eulaId: route.query.eulaId,
            customerId: route.query.customerId,
          }),
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: require("@/views/pages/Dashboard.vue").default,
        },
        {
          path: "/consumption",
          name: "domus-consumption",
          component: require("@/views/pages/domus/Consumption.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/insurenceEvents",
          name: "domus-insurence-events",
          component: require("@/views/pages/domus/InsurenceEvents.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/insurenceEventsDetail",
          name: "domus-insurence-events-detail",
          component: require("@/views/pages/domus/InsurenceEventsDetail.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            invoiceId: route.query.eventId,
          }),
        },
        {
          path: "/userListOfficials",
          name: "domus-user-list-officials",
          component: require("@/views/pages/domus/UserListOfficials.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/userListOfficialsDetail",
          name: "domus-user-list-officials-detail",
          component: require("@/views/pages/domus/UserListOfficialsDetail.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            userId: route.query.userId,
          }),
        },
        {
          path: "/consumptionForPeriod",
          name: "domus-consumption-for-period",
          component: require("@/views/pages/domus/ConsumptionForPeriod.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/monthlyConsumption",
          name: "domus-monthly-consumption",
          component: require("@/views/pages/domus/MonthlyConsumption.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/monthlyConsumptionOverview",
          name: "domus-monthly-consumption-overview",
          component: require("@/views/pages/domus/MonthlyConsumptionOverview.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/monthlyConsumptionMoreYears",
          name: "domus-monthly-consumption-more-years",
          component: require("@/views/pages/domus/MonthlyConsumptionMoreYears.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/dailyConsumption",
          name: "domus-daily-consumption",
          component: require("@/views/pages/domus/DailyConsumption.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/meterDailyReadings",
          name: "domus-meter-daily-readings",
          component: require("@/views/pages/domus/MeterDailyReadings.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/meterDailyReadingsUser",
          name: "domus-meter-daily-readings-user",
          component: require("@/views/pages/domus/MeterDailyReadingsUser.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/meterDailyReadingsObject",
          name: "domus-meter-daily-readings-object",
          component: require("@/views/pages/domus/MeterDailyReadingsObject.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/dailyConsumptionMoreYears",
          name: "domus-daily-consumption-more-years",
          component: require("@/views/pages/domus/DailyConsumptionMoreYears.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/dailyConsumptionMoreYearsComp",
          name: "domus-daily-consumption-more-years-comp",
          component: require("@/views/pages/domus/DailyConsumptionMoreYearsComp.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/dailyConsumptionAverageObject",
          name: "domus-daily-consumption-average-object",
          component: require("@/views/pages/domus/DailyConsumptionAverageObject.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/monthlyConsumptionAverageObject",
          name: "domus-monthly-consumption-average-object",
          component: require("@/views/pages/domus/MonthlyConsumptionAverageObject.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/averageDayConsumption",
          name: "domus-average-day-consumption",
          component: require("@/views/pages/domus/AverageDayConsumption.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/annualCosts",
          name: "domus-annual-costs",
          component: require("@/views/pages/domus/AnnualCosts.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/annualConsumption",
          name: "domus-annual-consumption",
          component: require("@/views/pages/domus/AnnualConsumption.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/paymentOrder",
          name: "domus-payment-order",
          component: require("@/views/pages/domus/PaymentOrder.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/paymentReminderOverview",
          name: "domus-payment-reminder-overview",
          component: require("@/views/pages/domus/PaymentReminderOverview.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/paymentReminderOverviewHouse",
          name: "domus-payment-reminder-overview-house",
          component: require("@/views/pages/domus/PaymentReminderOverviewHouse.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/decisions",
          name: "domus-decisions",
          component: require("@/views/pages/domus/Decisions.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/annualBilling",
          name: "domus-annual-billing",
          component: require("@/views/pages/domus/AnnualBilling.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/meterReadings",
          name: "domus-meter-readings",
          component: require("@/views/pages/domus/MeterReadings.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/objectOfficials",
          name: "domus-object-officials",
          component: require("@/views/pages/domus/ObjectOfficials.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/inspection",
          name: "domus-inspection",
          component: require("@/views/pages/domus/Inspection.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/buildingElements",
          name: "domus-building-elements",
          component: require("@/views/pages/domus/BuildingElements.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/costs",
          name: "domus-costs",
          component: require("@/views/pages/domus/Costs.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/bids",
          name: "domus-bids",
          component: require("@/views/pages/domus/Bids.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/repairFund",
          name: "domus-repair-fund",
          component: require("@/views/pages/domus/RepairFund.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/invoices",
          name: "domus-invoices",
          component: require("@/views/pages/domus/Invoices.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/objectAccount",
          name: "domus-object-account",
          component: require("@/views/pages/domus/ObjectAccount.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/objectAccountDetail",
          name: "domus-object-account-detail",
          component: require("@/views/pages/domus/ObjectAccountDetail.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            accountId: route.query.accountId,
          }),
        },
        {
          path: "/cashRegister",
          name: "domus-cashRegister",
          component: require("@/views/pages/domus/CashRegister.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/cashRegisterDetail",
          name: "domus-cashRegister-detail",
          component: require("@/views/pages/domus/CashRegisterDetail.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            accountId: route.query.accountId,
          }),
        },
        {
          path: "/meterReadingDetail",
          name: "domus-meter-reading-detail",
          component: require("@/views/pages/domus/MeterReadingDetail.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            meterId: route.query.meterId,
          }),
        },
        {
          path: "/costDetail",
          name: "domus-cost-detail",
          component: require("@/views/pages/domus/CostDetail.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            code: route.query.code,
            year: route.query.year,
          }),
        },
        {
          path: "/bidsDetail",
          name: "domus-bids-detail",
          component: require("@/views/pages/domus/BidsDetail.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            bidId: route.query.bidId,
          }),
        },
        {
          path: "/votingSheet",
          name: "domus-voting-sheet",
          component: require("@/views/pages/domus/VotingSheet.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            userType: route.query.userType,
            roomType: route.query.roomType,
          }),
        },
        {
          path: "/signatureSheet",
          name: "domus-signature-sheet",
          component: require("@/views/pages/domus/SignatureSheet.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/requestListHouse",
          name: "domus-request-list-house",
          component: require("@/views/pages/domus/RequestListHouse.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/requestListUser",
          name: "domus-request-list-user",
          component: require("@/views/pages/domus/RequestListUser.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/requestPenta",
          name: "domus-request-penta",
          component: require("@/views/pages/domus/RequestPenta.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/newRequest",
          name: "domus-new-request",
          component: require("@/views/pages/domus/NewRequest.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            requestId: route.query.requestId,
          }),
        },
        {
          path: "/requestDetail",
          name: "domus-request-detail",
          component: require("@/views/pages/domus/RequestDetail.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            requestId: route.query.requestId,
          }),
        },
        {
          path: "/balances",
          name: "domus-balances",
          component: require("@/views/pages/domus/Balances.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/unpaidPrePayments",
          name: "domus-unpaidPrePayments",
          component: require("@/views/pages/domus/UnpaidPrePayments.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/unpaidPrePaymentsForUser",
          name: "domus-unpaidPrePaymentsForUser",
          component: require("@/views/pages/domus/UnpaidPrePaymentsForUser.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/unpaidDebts",
          name: "domus-unpaidDebts",
          component: require("@/views/pages/domus/UnpaidDebts.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/unpaidDebtsObject",
          name: "domus-unpaidDebts-object",
          component: require("@/views/pages/domus/UnpaidDebtsObject.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/accountProfile",
          name: "domus-account-profile",
          component: require("@/views/pages/domus/AccountProfile.vue").default,
        },
        {
          path: "/unitsNotif",
          name: "domus-units-notif",
          component: require("@/views/pages/domus/UnitsNotif.vue").default,
        },
        {
          path: "/events",
          name: "domus-events",
          component: require("@/views/pages/domus/Events.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/eventsDetail",
          name: "domus-events-detail",
          component: require("@/views/pages/domus/EventsDetail.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            eventId: route.query.eventId,
          }),
        },
        {
          path: "/notifications",
          name: "domus-notifications",
          component: require("@/views/pages/domus/Notifications.vue").default,
          props: (route) => ({
            notifId: route.query.notifId,
          }),
        },
        {
          path: "/identityTakeover",
          name: "domus-identity-takeover",
          component: require("@/views/pages/domus/IdentityTakeover.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/adminAccountList",
          name: "domus-admin-account-list",
          component: require("@/views/pages/domus/AdminAccountList.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/adminManagerList",
          name: "domus-admin-manager-list",
          component: require("@/views/pages/domus/AdminManagerList.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/RegLogList",
          name: "domus-reg-log-list",
          component: require("@/views/pages/domus/RegLogList.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/connectedAccounts",
          name: "domus-connected-accounts",
          component: require("@/views/pages/domus/ConnectedAccountsText.vue")
            .default,
        },
        {
          path: "/invoicesDetail",
          name: "domus-invoices-detail",
          component: require("@/views/pages/domus/InvoicesDetail.vue").default,
          props: (route) => ({
            menuId: route.query.menuId,
            invoiceId: route.query.invoiceId,
          }),
        },
        {
          path: "/userInfo",
          name: "domus-user-info",
          component: require("@/views/pages/domus/UserInfo.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/custReport",
          name: "domus-custReport",
          component: require("@/views/pages/domus/CustReportParams.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/dailyConsumptionObject",
          name: "domus-daily-consumption-object",
          component: require("@/views/pages/domus/DailyConsumptionObject.vue")
            .default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/eulaPrices",
          name: "domus-eula-prices",
          component: require("@/views/pages/domus/EulaPrices.vue").default,
        },
        {
          path: "/customerPages",
          name: "domus-customerPages",
          component: require("@/views/pages/domus/CustomerPages.vue").default,
          //props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/customerPageDetail",
          name: "domus-customerPage-detail",
          component: require("@/views/pages/domus/CustomerPageDetail.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            customerPageId: route.query.invoiceId,
          }),
        },
        {
          path: "/customerMailer",
          name: "domus-customerMailer",
          component: require("@/views/pages/domus/CustomerMailer.vue").default,
          //props: (route) => ({ menuId: route.query.menuId }),
        },
        {
          path: "/customerMailerDetail",
          name: "domus-customerMailer-detail",
          component: require("@/views/pages/domus/CustomerMailerDetail.vue")
            .default,
          props: (route) => ({
            menuId: route.query.menuId,
            customerMailerId: route.query.invoiceId,
          }),
        },
        {
          path: "/adminCheckCode",
          name: "admin-check-code",
          component: require("@/views/pages/domus/AdminCheckCode.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
        /* vyhadzujem docasne, to keby si to po 3x rozmysleli*/
        {
          path: "/partnersUser",
          name: "domus-partners-user",
          component: require("@/views/pages/domus/PartnersUser.vue").default,
          props: (route) => ({ menuId: route.query.menuId }),
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: require("@/views/pages/error/Error-1.vue").default,
        },
        {
          path: "error-2",
          name: "error-2",
          component: require("@/views/pages/error/Error-2.vue").default,
        },
        {
          path: "error-3",
          name: "error-3",
          component: require("@/views/pages/error/Error-3.vue").default,
        },
        {
          path: "error-4",
          name: "error-4",
          component: require("@/views/pages/error/Error-4.vue").default,
        },
        {
          path: "error-5",
          name: "error-5",
          component: require("@/views/pages/error/Error-5.vue").default,
        },
        {
          path: "error-6",
          name: "error-6",
          component: require("@/views/pages/error/Error-6.vue").default,
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "home",
          path: "/home",
          component: require("@/views/pages/auth/Home").default,
        },
        {
          name: "login",
          path: "/login",
          component: require("@/views/pages/auth/Login").default,
        },
        {
          name: "units",
          path: "/units",
          component: require("@/views/pages/auth/Units").default,
        },
        {
          name: "register",
          path: "/register",
          component: require("@/views/pages/auth/Register").default,
        },
        {
          name: "registrationCode",
          path: "/registrationCode",
          component: require("@/views/pages/auth/RegistrationCode").default,
        },
        {
          name: "registrationAgreement",
          path: "/registrationAgreement",
          component: require("@/views/pages/auth/RegistrationAgreement")
            .default,
        },

        {
          name: "registrationAgreementNew",
          path: "/registrationAgreementNew",
          component: require("@/views/pages/auth/RegistrationAgreementNew")
            .default,
          props: (route) => ({ type: route.query.type }),
        },
        {
          name: "registrationTerms",
          path: "/registrationTerms",
          component: require("@/views/pages/auth/RegistrationTerms").default,
          props: (route) => ({ menuId: route.query.type }),
        },
        {
          name: "gdprTerms",
          path: "/gdprTerms",
          component: require("@/views/pages/auth/GDPRTerms").default,
        },
        {
          name: "facebook",
          path: "/facebook",
          component: require("@/views/pages/auth/Facebook").default,
        },
        {
          name: "google",
          path: "/google",
          component: require("@/views/pages/auth/Google").default,
        },
        {
          name: "resetPassword",
          path: "/resetPassword",
          component: require("@/views/pages/auth/ResetPassword").default,
        },
        {
          name: "forgotPassword",
          path: "/forgotPassword",
          component: require("@/views/pages/auth/ForgotPassword").default,
        },
        {
          path: "/hlas",
          name: "domus-voting",
          component: require("@/views/pages/domus/Voting.vue").default,
          props: (route) => ({ voteId: route.query.id }),
        },
        {
          path: "/cp:code",
          name: "domus-bids-public",
          component: require("@/views/pages/domus/BidsPublic.vue").default,
        },
        {
          path: "/detailcp",
          name: "domus-bids-public-detail",
          component: require("@/views/pages/domus/BidsPublicDetail.vue")
            .default,
          props: (route) => ({
            code: route.query.code,
            bidId: route.query.bidId,
          }),
        },
        {
          path: "/zas",
          name: "domus-documents-pub",
          component: require("@/views/pages/domus/DocumentsPub.vue").default,
          props: (route) => ({ voteId: route.query.id }),
        },
        {
          path: "/sf:code",
          name: "domus-invoices-approval",
          component: require("@/views/pages/domus/InvoicesApproval.vue")
            .default,
        },
        {
          path: "/showpic",
          name: "show-meter-picture",
          component: require("@/views/pages/domus/ShowPicture.vue").default,
          props: (route) => ({
            pictureId: route.query.PictureId,
          }),
        },
        {
          path: "/code:code",
          name: "domus-qr-code",
          component: require("@/views/pages/domus/QRCode.vue").default,
        },
        {
          path: "/odpocet-:code",
          name: "reading-public",
          component: require("@/views/pages/domus/ReadingPub.vue").default,
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: require("@/views/pages/error/Error-6.vue").default,
    },
  ],
});
