<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Signature Sheet-->
        <KTPortlet v-bind:title="title" class="poschodoch-print">
          <!--Begin::Data -->
          <template v-slot:body>
            <div class="row">
              <b-col
                sm="9"
                lg="9"
                class="mb-2"
                style="
                  font-size: 1.2rem;
                  font-weight: 500;
                  white-space: pre-wrap;
                "
              >
                <b>{{ text1 }}</b>
              </b-col>
              <!-- "Datum tlace: " je v novom print headeri -->
            </div>
            <b-container fluid v-if="items">
              <div class="mb-3">{{ objectNumber + " " + objectAddress }}</div>

              <!--Begin::Main table element -->
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :refreshed="print()"
                thead-class="mb-5"
              >
                <template v-slot:head(Portion)="data">
                  <span v-if="portion != '3'">{{ data.label }}</span>
                  <span v-else></span>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Signature Sheet-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";

export default {
  name: "signatureSheet-Print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      menuId: null,
      title: null,
      description: null,
      text1: null,
      userType: null,
      roomType: null,
      portion: null,
      gate: null,
      objectAddress: null,
      objectNumber: null,
      items: null,
      fields: [
        {
          key: "VS",
          label: this.$i18n.t("SIGNATURESHEET.TABLE.VS"),
          sortable: false,
          sortDirection: "desc",
          thClass: "pb-5",
        },
        {
          key: "ApartmentNr",
          label: this.$i18n.t("SIGNATURESHEET.TABLE.APARTMENTNR"),
          sortable: false,
          sortDirection: "desc",
          thClass: "pb-5",
        },
        {
          key: "Name",
          label: this.$i18n.t("SIGNATURESHEET.TABLE.NAME"),
          sortable: false,
          sortDirection: "desc",
          thClass: "pb-5",
        },
        {
          key: "Portion",
          label: this.$i18n.t("SIGNATURESHEET.TABLE.PORTION"),
          sortable: false,
          sortDirection: "desc",
          hidden: true,
          thClass: "pb-5",
        },
        {
          key: "Signature",
          label: this.$i18n.t("SIGNATURESHEET.TABLE.SIGNATURE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-center podpis-column",
          thClass: "pb-5",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.userType = this.$route.query.userType;
    this.roomType = this.$route.query.roomType;
    this.portion = this.$route.query.portion;
    this.gate = this.$route.query.gate;
    this.text1 = this.$route.query.text1;
    this.objectAddress = this.$route.query.objectAddress;
    this.objectNumber = this.$route.query.objectNumber;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    fetchData() {
      this.items = null;
      let tit = "";
      let docTitle = "";
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.userType != null)
        parameters += "&userType=" + this.userType.toString();
      else parameters += "&userType=A";
      if (this.roomType != null)
        parameters += "&roomType=" + this.roomType.toString();
      else parameters += "&roomType=T";
      if (this.portion != null)
        parameters += "&portion=" + this.portion.toString();
      else parameters += "&portion=1";
      if (this.gate != null) parameters += "&gate=" + this.gate.toString();

      ApiService.setHeader();
      ApiService.get("Object", "SignatureSheet" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.SignatureSheet;
          tit = this.title;
        },
      );
      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
