const POSCHODOCH_MANIFEST_SK_URL = "app/poschodoch_sk_manifest.json";
const POSCHODOCH_MANIFEST_CZ_URL = "app/poschodoch_cz_manifest.json";
const POSCHODOCH_MANIFEST_EN_URL = "app/poschodoch_en_manifest.json";

export function getPWAManifestFileURL() {
  var linkManifest = "";

  if (
    window.location.host.includes("poschodoch") &&
    !window.location.href.includes("Print")
  ) {
    const lang = localStorage.getItem("language");
    if (lang === "en") {
      linkManifest = POSCHODOCH_MANIFEST_EN_URL;
    } else if (lang === "cz") {
      linkManifest = POSCHODOCH_MANIFEST_CZ_URL;
    } else {
      linkManifest = POSCHODOCH_MANIFEST_SK_URL;
    }
  } else if (
    window.location.host.includes("poschodech") &&
    !window.location.href.includes("Print")
  ) {
    linkManifest = POSCHODOCH_MANIFEST_CZ_URL;
  }

  return linkManifest;
}

export function isInstallationAvailableByDomain() {
  if (
    window.location.host.includes("poschodoch") &&
    !window.location.href.includes("Print")
  ) {
    return true;
  } else if (
    window.location.host.includes("poschodech") &&
    !window.location.href.includes("Print")
  ) {
    return true;
  }
  return false;
}
