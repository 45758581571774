<template>
  <div>
    <!-- Error box -->
    <!-- <b-container v-if="errors && errors.length" class="mb-5">
      <div
        role="alert"
        v-bind:class="{ show: errors && errors.length }"
        class="alert fade alert-danger mt-2 mb-2"
        style="flex-direction: column;"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>
    </b-container> -->
    <!-- Error box -->
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Object Officials-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="poschodoch-avatar-table"
              >
                <template v-slot:cell(Mobile)="data">
                  <div class="align-self-start">
                    <img
                      v-if="data.value.Photo"
                      v-bind:src="'data:image/png;base64,' + data.value.Photo"
                      width="49"
                      height="56"
                      class="poschodoch-avatar-img"
                    />
                    <img
                      src="@/assets/media/poschodoch-custom/default_user.svg"
                      width="49"
                      height="56"
                      class="poschodoch-avatar-img"
                      v-else
                    />
                  </div>
                  <div
                    class="d-flex flex-column align-items-start justify-content-center w-100 poschodoch-officials-table-content"
                  >
                    <span class="poschodoch-officials-title-mobile">
                      {{ data.value.Name + " (" + data.value.Function + ")" }}
                    </span>
                    <div
                      class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span v-if="data.value.Email">
                          {{ $i18n.t("OBJECTOFFICIALS.TABLE.EMAIL") + ": " }}
                          <a
                            :href="`mailto:${data.value.Email}`"
                            class="kt-link"
                          >
                            {{ data.value.Email }}
                          </a>
                        </span>
                        <span v-if="data.value.Phone">
                          {{
                            $i18n.t("OBJECTOFFICIALS.TABLE.PHONE") +
                              ": " +
                              data.value.Phone
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right"
                      >
                        <span v-if="data.value.Address">
                          {{
                            $i18n.t("OBJECTOFFICIALS.TABLE.ADDRESS") +
                              ": " +
                              data.value.Address
                          }}
                        </span>
                        <span v-if="data.value.Object">
                          {{ data.value.Object }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(Photo)="data">
                  <img
                    v-if="data.value"
                    v-bind:src="'data:image/png;base64,' + data.value"
                    width="49"
                    height="56"
                    class="poschodoch-avatar-img"
                  />
                  <img
                    src="@/assets/media/poschodoch-custom/default_user.svg"
                    width="49"
                    height="56"
                    class="poschodoch-avatar-img"
                    v-else
                  />
                </template>
                <template v-slot:cell(Email)="data">
                  <a
                    v-if="data.value"
                    :href="`mailto:${data.value}`"
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Object Officials-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "objectOfficials",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      imgContent: [],
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "Photo",
          label: "",
          class: "poschodoch-avatar-column table-no-mobile",
          sortable: true,
        },
        {
          key: "Name",
          label: this.$i18n.t("OBJECTOFFICIALS.TABLE.NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Function",
          label: this.$i18n.t("OBJECTOFFICIALS.TABLE.FUNCTION"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Type",
          label: this.$i18n.t("OBJECTOFFICIALS.TABLE.TYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Phone",
          label: this.$i18n.t("OBJECTOFFICIALS.TABLE.PHONE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
        },
        {
          key: "Email",
          label: this.$i18n.t("OBJECTOFFICIALS.TABLE.EMAIL"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
        },
        {
          key: "Object",
          label: this.$i18n.t("OBJECTOFFICIALS.TABLE.OBJECT"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
        },
        {
          key: "Address",
          label: this.$i18n.t("OBJECTOFFICIALS.TABLE.ADDRESS"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
        },
        // {
        //   key: "Order",
        //   label: this.$i18n.t("OBJECTOFFICIALS.TABLE.ORDER"),
        //   sortable: true,
        //   sortDirection: "desc"
        // },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
      // errors: [],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      window.open("/objectOfficialsPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("OBJECTOFFICIALS.TABLE.ORDER"),
        this.$i18n.t("OBJECTOFFICIALS.TABLE.OBJECT"),
        this.$i18n.t("OBJECTOFFICIALS.TABLE.NAME"),
        this.$i18n.t("OBJECTOFFICIALS.TABLE.PHONE"),
        this.$i18n.t("OBJECTOFFICIALS.TABLE.EMAIL"),
        this.$i18n.t("OBJECTOFFICIALS.TABLE.ADDRESS"),
        this.$i18n.t("OBJECTOFFICIALS.TABLE.TYPE"),
        this.$i18n.t("OBJECTOFFICIALS.TABLE.FUNCTION"),
        this.$i18n.t("OBJECTOFFICIALS.TABLE.PHOTO"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.Order,
          item.Object,
          item.Name,
          item.Phone,
          item.Email,
          item.Address,
          item.Type,
          item.Function,
        ];

        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "ObjectOfficials" + parameters).then(
        ({ data }) => {
          this.transformData(data.ObjectOfficials);

          this.title = data.Title;
          this.description = data.Description;
          this.items = data.ObjectOfficials;
        },
      );
      // .catch(({ response }) => {
      //   if (response.data.error) this.errors.push(response.data.error + ' (ObjectOfficials)');
      //   else this.errors.push('Niečo sa pokazilo. Pracujeme na tom...' + ' (ObjectOfficials)');
      // });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Address: items[i].Address,
          Email: items[i].Email,
          Function: items[i].Function,
          Name: items[i].Name,
          Object: items[i].Object,
          Order: items[i].Order,
          Phone: items[i].Phone,
          Photo: items[i].Photo,
          Type: items[i].Type,
        };
      }
    },
  },
};
</script>
