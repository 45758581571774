<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-bind:title="title" class="poschodoch-print">
          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-table
                v-if="items"
                show-empty
                hover
                :items="items"
                :fields="fields"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :current-page="currentPage"
                :per-page="perPage"
                :refreshed="print()"
              >
                <template v-slot:head(Consumption)="data">
                  <span>{{ data.label + " [" + unitOfMeasure + "]" }}</span>
                </template>
              </b-table>

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "costDetail-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      unitOfMeasure: null,
      code: null,
      year: null,
      fields: [
        {
          key: "SubscriberSite",
          label: this.$i18n.t("COST_DETAIL.TABLE.SUBSCRIBERSITE"),
          sortable: false,
          class: "text-left",
        },
        {
          key: "Budget",
          label: this.$i18n.t("COST_DETAIL.TABLE.BUDGET"),
          sortable: false,
          class: "text-right",
        },
        {
          key: "DateFrom",
          label: this.$i18n.t("COST_DETAIL.TABLE.DATEFROM"),
          sortable: false,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DateTo",
          label: this.$i18n.t("COST_DETAIL.TABLE.DATETO"),
          sortable: false,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Consumption",
          label: this.$i18n.t("COST_DETAIL.TABLE.CONSUMPTION"),
          sortable: false,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("COST_DETAIL.TABLE.AMOUNT"),
          ),
          sortable: false,
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
      ],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.code = this.$route.query.code;
    this.year = this.$route.query.year;
    this.fetchData();
  },
  methods: {
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?menuId=" + this.menuId.toString() + "&code=" + this.code.toString();
      let tit = "";
      let docTitle = "";
      if (this.year != null) parameters += "&year=" + this.year.toString();

      ApiService.setHeader();
      ApiService.get("Object", "CostDetail" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.CostDetail;

        this.unitOfMeasure = data.UnitOfMeasure;
        tit = this.title;
      });

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
