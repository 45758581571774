<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Filter -->
        <KTPortlet>
          <template v-slot:body>
            <b-container fluid>
              <b-row v-if="!hidden">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div
                    class="d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center"
                  >
                    <ul
                      class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills m-0 mr-0 mr-md-3"
                      role="tablist"
                    >
                      <li class="nav-item" v-for="index in years" :key="index">
                        <a
                          v-on:click="fetchData(index, month)"
                          data-tab="0"
                          class="nav-link"
                          href="javascript:void(0)"
                          v-bind:class="{ active: index == year }"
                        >
                          {{ index }}
                        </a>
                      </li>
                    </ul>

                    <div
                      class="poschodoch-month-dropdown d-flex align-items-center mt-3 mt-md-0"
                    >
                      <label
                        for="daily-consumption-dropdown-month"
                        class="m-0 mr-3"
                      >
                        {{ $i18n.t("DAILY_CONSUMPTION.FILTERMONTH") }}
                      </label>
                      <button
                        id="previous-month"
                        type="button"
                        class="poschodoch-previous-btn page-link mr-3 mr-md-2"
                        v-on:click="previousMonth(month)"
                        :disabled="isPreviousBtnDisabled"
                      >
                        ‹
                      </button>
                      <b-dropdown
                        id="daily-consumption-dropdown-month"
                        :text="getFormatService().getMonthName(month)"
                        variant="light"
                      >
                        <b-dropdown-item
                          v-for="month in months"
                          :key="month"
                          v-on:click="
                            fetchData(
                              year,
                              month,
                              null,
                              null,
                              inputSearch,
                              inputMedium,
                            )
                          "
                        >
                          {{ getFormatService().getMonthName(month) }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <button
                        id="next-month"
                        type="button"
                        class="poschodoch-next-btn page-link ml-3 ml-md-2"
                        v-on:click="nextMonth(month)"
                        :disabled="isNextBtnDisabled"
                      >
                        ›
                      </button>
                    </div>
                  </div>
                  <span
                    class="d-flex flex-row align-items-center mt-sm-0 mt-3 ml-3"
                  >
                    <label for="search" class="mr-1" style="font-weight: 500">
                      {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                    </label>
                    <b-form-input
                      v-model="inputSearch"
                      @input="
                        fetchData(
                          year,
                          month,
                          null,
                          null,
                          inputSearch,
                          inputMedium,
                        )
                      "
                    ></b-form-input>
                  </span>
                  <b-col
                    class="d-flex flex-row align-items-center mr-md-3 mr-0 col-lg-2 col-md-2"
                  >
                    <label
                      for="typeAccess"
                      class="mr-1"
                      style="font-weight: 500"
                    >
                      {{ $i18n.t("METERDAILYREADINGS.MEDIUM") }}:
                    </label>
                    <b-form-select
                      id="typeAccess"
                      v-model="inputMedium"
                      :options="mediumOptions"
                      @change="
                        fetchData(
                          year,
                          month,
                          null,
                          null,
                          inputSearch,
                          inputMedium,
                        )
                      "
                    ></b-form-select>
                  </b-col>
                  <b-button
                    variant="secondary"
                    class="ml-md-5 mt-3 mt-md-0"
                    v-on:click="
                      hidden2 = !hidden2;
                      hidden = !hidden;
                      fetchData(year, month);
                    "
                  >
                    {{ $i18n.t("DAILY_CONSUMPTION.BUTTON1") }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="hidden2">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div
                    class="d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center"
                  >
                    <div
                      class="ml-md-2 mt-3 mt-md-0 d-flex flex-sm-row flex-column"
                    >
                      <span
                        class="d-flex flex-row align-items-center mr-sm-4 mr-0"
                      >
                        <label for="dF" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("DAILY_CONSUMPTION.FROM") }}
                        </label>
                        <b-form-input
                          id="dF"
                          v-model="inputDateFrom"
                          type="date"
                        ></b-form-input>
                      </span>
                      <span
                        class="d-flex flex-row align-items-center mt-sm-0 mt-3"
                      >
                        <label for="dT" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("DAILY_CONSUMPTION.TO") }}
                        </label>
                        <b-form-input
                          id="dT"
                          v-model="inputDateTo"
                          type="date"
                        ></b-form-input>
                      </span>
                      <div
                        class="d-flex align-items-sm-center align-self-center"
                      >
                        <b-button
                          variant="primary"
                          class="ml-sm-3 ml-0 mt-3 mt-sm-0"
                          v-on:click="
                            fetchData(
                              null,
                              null,
                              inputDateFrom,
                              inputDateTo,
                              inputSearch,
                              inputMedium,
                            )
                          "
                        >
                          {{ $i18n.t("DAILY_CONSUMPTION.BUTTONOK") }}
                        </b-button>
                      </div>
                      <span
                        class="d-flex flex-row align-items-center mt-sm-0 mt-3 ml-3"
                      >
                        <label
                          for="search"
                          class="mr-1"
                          style="font-weight: 500"
                        >
                          {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                        </label>
                        <b-form-input
                          v-model="inputSearch"
                          @input="
                            fetchData(
                              year,
                              month,
                              null,
                              null,
                              inputSearch,
                              inputMedium,
                            )
                          "
                        ></b-form-input>
                      </span>
                      <b-col
                        class="d-flex flex-row align-items-center mr-md-3 mr-0 col-lg-3 col-md-2"
                      >
                        <label
                          for="typeAccess"
                          class="mr-1"
                          style="font-weight: 500"
                        >
                          {{ $i18n.t("METERDAILYREADINGS.MEDIUM") }}:
                        </label>
                        <b-form-select
                          id="typeAccess"
                          v-model="inputMedium"
                          :options="mediumOptions"
                          @change="
                            fetchData(
                              year,
                              month,
                              null,
                              null,
                              inputSearch,
                              inputMedium,
                            )
                          "
                        ></b-form-select>
                      </b-col>
                      <!--<span
                        class="d-flex flex-row align-items-center mt-sm-0 mt-3 ml-3 col-4"
                      >
                        <label for="search" class="mr-1" style="font-weight: 500">
                          {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                        </label>
                        <b-form-input
                          v-model="inputSearch"
                          @input="fetchData(null, null, inputDateFrom, inputDateTo, inputSearch, inputMedium)"
                        ></b-form-input>
                      </span>-->
                    </div>
                  </div>

                  <!--<b-col class="d-flex flex-row align-items-center mr-md-3 mr-0 col-3">
                    <label for="typeAccess" class="mr-1" style="font-weight: 500">
                      {{ $i18n.t("METERDAILYREADINGS.MEDIUM") }}:
                    </label>
                    <b-form-select
                      id="typeAccess"
                      v-model="inputMedium"
                      :options="mediumOptions"
                      @change="fetchData(year, month, null, null, inputSearch, inputMedium)"                      
                    ></b-form-select>
                  </b-col>-->
                  <div class="d-flex align-items-sm-center">
                    <b-button
                      variant="secondary"
                      class="ml-md-2 ml-sm-3 mt-3 mt-md-0"
                      v-on:click="
                        hidden2 = !hidden2;
                        hidden = !hidden;
                        fetchData();
                      "
                    >
                      {{ $i18n.t("DAILY_CONSUMPTION.BUTTON2") }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <!--Begin::Description 
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              End::Description -->
            </b-container>
          </template>
        </KTPortlet>
        <!--End::Filter -->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!-- Tabulka -->
        <KTPortlet v-bind:title="subTitle">
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="itemsTable">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="itemsTable"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Amount)="data">
                  {{ data.value }} {{ data.item.Currency }}
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span class="text-left">
                          {{ data.value.Type + ", " }}
                          {{ $i18n.t("METERDAILYREADINGS.METERNUMBER") + ": " }}
                          {{ data.value.MeterNumber }}
                        </span>
                        <!-- <span v-if="data.value.PaymentDate">
                          {{ getFormatService().formatDateToLocal(data.value.PaymentDate) }}
                        </span>-->
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span class="poschodoch-table-title-mobile text-right">
                          {{ data.value.Consumption }} {{ data.value.Unit }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-row align-items-end poschodoch-mobile-left mr-1"
                      >
                        <span class="text-left">
                          {{
                            getFormatService().formatDateToLocal(
                              data.item.DateFrom,
                            )
                          }}
                          {{
                            ", " + $i18n.t("METERDAILYREADINGS.STATE") + ": "
                          }}
                          {{ data.item.StateFrom }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span class="text-right">
                          {{
                            getFormatService().formatDateToLocal(
                              data.item.DateTo,
                            )
                          }}
                          {{
                            ", " + $i18n.t("METERDAILYREADINGS.STATE") + ": "
                          }}
                          {{ data.item.StateTo }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->
              <!--Begin::Pagination -->
              <b-row
                v-if="itemsTable && itemsTable.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!itemsTable">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!-- konec tabulka -->
        <!--Begin::Daily Consumption Water-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12"></div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";

export default {
  name: "domus-meter-daily-readings-user",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      subTitle: null,
      subTitleRooms: null,
      menuId: null,
      hidden: false,
      hidden2: false,
      items: null,
      itemsRooms: null,
      itemsTable: null,
      year: null,
      years: null,
      yearFrom: null,
      yearTo: null,
      month: null,
      months: null,
      monthFrom: null,
      monthTo: null,
      inputDateFrom: null,
      inputDateTo: null,
      inputSearch: null,
      inputMedium: null,

      mediumOptions: [],

      //colors
      colorhot: null,
      colorcold: null,
      colorheat: null,
      colortooltip: null,
      sortBy: "",
      sortDirection: "desc",
      totalRows: 1,
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),

      fields: [
        /*        {
          key: "Apartment",
          label: this.$i18n.t("METERDAILYREADINGS.APARTMENT"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },*/

        {
          key: "MeterNumber",
          label: this.$i18n.t("METERDAILYREADINGS.METERNUMBER"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Type",
          label: this.$i18n.t("METERDAILYREADINGS.TYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DateFrom",
          label: this.$i18n.t("METERDAILYREADINGS.DATEFROM"),
          sortable: true,
          class: "text-center table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DateTo",
          label: this.$i18n.t("METERDAILYREADINGS.DATETO"),
          sortable: true,
          class: "text-center table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "StateFrom",
          label: this.$i18n.t("METERDAILYREADINGS.STATEFROM"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
        },
        {
          key: "StateTo",
          label: this.$i18n.t("METERDAILYREADINGS.STATETO"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
        },
        {
          key: "Consumption",
          label: this.$i18n.t("METERDAILYREADINGS.CONSUMPTION"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
        },
        {
          key: "Unit",
          label: this.$i18n.t("METERDAILYREADINGS.UNIT"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        /*{
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center p-0 table-no-mobile",
        },*/
        {
          key: "Mobile",
          label: "",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
    };
  },
  mounted() {
    this.colorhot = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.danger");
    this.colorcold = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-cold")
      : this.layoutConfig("colors.state.info");
    this.colorheat = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-heat")
      : this.layoutConfig("colors.state.brand");
    this.colortooltip = window.location.host.includes("enbra")
      ? this.layoutConfig("colors.graph.color-hot")
      : this.layoutConfig("colors.state.brand");
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    isPreviousBtnDisabled() {
      return !this.isMonthInRange(this.month - 1);
    },
    isNextBtnDisabled() {
      return !this.isMonthInRange(this.month + 1);
    },
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    isMonthInRange(monthToCheck) {
      return (
        monthToCheck != null &&
        monthToCheck != -1 &&
        this.months != null &&
        this.months.includes(monthToCheck)
      );
    },
    previousMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth - 1))
        this.fetchData(this.year, selectedMonth - 1);
    },
    nextMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth + 1))
        this.fetchData(this.year, selectedMonth + 1);
    },
    setYearRange(yearFrom, yearTo) {
      if (this.years == null) {
        this.years = [];
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    setMonthRange(pRok, pMesiac = "") {
      this.months = [];
      this.year = pRok;
      if (pRok == this.yearFrom) {
        for (let i = this.monthFrom; i <= 12; i++) {
          this.months.push(i);
        }
      } else if (pRok == this.yearTo) {
        for (let i = 1; i <= this.monthTo; i++) {
          this.months.push(i);
        }
      } else {
        this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }

      if (pMesiac == null) {
        this.month = "Zvoľte mesiac";
      }
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();

      if (this.year != null) parameters += "&year=" + this.year.toString();
      if (this.month != null) parameters += "&month=" + this.month.toString();

      if (this.inputDateFrom != null)
        parameters += "&dateFrom=" + this.inputDateFrom;
      if (this.inputDateTo != null) parameters += "&dateTo=" + this.inputDateTo;

      if (this.inputSearch != null) parameters += "&Search=" + this.inputSearch;
      if (this.inputMedium != null) {
        parameters += "&type=" + this.inputMedium;
      }
      parameters += "&title=" + this.subTitle;
      window.open("/meterDailyReadingsPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("METERDAILYREADINGS.APARTMENT"),
        this.$i18n.t("METERDAILYREADINGS.TYPE"),
        this.$i18n.t("METERDAILYREADINGS.METERNUMBER"),
        this.$i18n.t("METERDAILYREADINGS.DATEFROM"),
        this.$i18n.t("METERDAILYREADINGS.DATETO"),
        this.$i18n.t("METERDAILYREADINGS.STATEFROM"),
        this.$i18n.t("METERDAILYREADINGS.STATETO"),
        this.$i18n.t("METERDAILYREADINGS.CONSUMPTION"),
        this.$i18n.t("METERDAILYREADINGS.UNIT"),
      ];

      let rows = [];
      this.itemsTable.forEach(function(item) {
        let row = [
          item.Apartment,
          item.Type,
          item.MeterNumber,
          item.DateFrom,
          item.DateTo,
          FormatService.formatNumberToLocalForCSV(item.StateFrom),
          FormatService.formatNumberToLocalForCSV(item.StateTo),
          FormatService.formatNumberToLocalForCSV(item.Consumption),
          item.Unit,
        ];

        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    transformDataTable(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Apartment: items[i].Apartment,
          Type: items[i].Type,
          MeterNumber: items[i].MeterNumber,
          DateTo: items[i].DateTo,
          StateTo: items[i].StateTo,
          Unit: items[i].Unit,
          DateFrom: items[i].DateFrom,
          StateFrom: items[i].StateFrom,
          Consumption: items[i].Consumption,
        };
      }
    },
    fetchData(
      selectedYear = null,
      selectedMonth = null,
      sDateFrom,
      sDateTo,
      sInputSearch,
      sInputMedium,
    ) {
      this.itemsTable = null;

      let parameters = "?menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();

      if (sDateFrom != null) parameters += "&dateFrom=" + sDateFrom;
      if (sDateTo != null) parameters += "&dateTo=" + sDateTo;

      if (sInputSearch != null) {
        parameters += "&Search=" + sInputSearch;
      } else {
        if (this.inputSearch != null) {
          parameters += "&Search=" + this.inputSearch;
        }
      }

      if (sInputMedium != null) {
        parameters += "&type=" + sInputMedium;
      } else {
        if (this.inputMedium != null) {
          parameters += "&type=" + this.inputMedium;
        }
        //parameters += "&type=";
      }

      ApiService.get("Flat", "MeterDailyReadings" + parameters)
        .then(({ data }) => {
          //this.transformDataRooms(data);
          //this.createChartRooms();

          this.subTitleRooms = data.Subtitle;
          this.itemsRooms = data.Consumption;
          this.mediumOptions = data.Medium;

          this.description = data.Description;
          if (data.Consumption) this.transformDataTable(data.Consumption);

          this.itemsTable = data.Consumption;

          if (this.itemsTable) {
            this.title = data.Title;
            this.description = data.Description;
            this.subTitle = data.Subtitle;
            this.year = data.Year;
            this.yearFrom = data.YearFrom;
            this.yearTo = data.YearTo;
            this.monthFrom = data.MonthFrom;
            this.monthTo = data.MonthTo;
            this.inputDateFrom = data.DateFrom;
            this.inputDateTo = data.DateTo;
            this.setYearRange(data.YearFrom, data.YearTo);
            this.setMonthRange(this.year, selectedMonth);
            this.month = data.Month;

            this.currentPage = 1;
            this.totalRows = this.itemsTable.length;
          }
        })
        .catch(() => {
          this.itemsRooms = undefined;
        });
    },
  },
};
</script>
