<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <!--Begin::customerMailer-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row>
                <div
                  class="d-flex flex-row mb-3 justify-content-md-start justify-content-center"
                >
                  <div
                    class="ml-md-2 mt-3 mt-md-0 d-flex flex-md-row flex-column justify-content-between w-100"
                  >
                    <div class="d-flex align-items-md-center align-self-center">
                      <b-button
                        variant="primary"
                        class="md-3 ml-0 mt-3 mt-md-0"
                        v-on:click="createNewMail"
                      >
                        <i class="fas fa-plus-circle mb-1"></i>
                        <span>{{ $i18n.t("CUSTOMERMAIL.NEWMAIL") }}</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-row>
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(EmailSubject)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="showCustomerMailDetail(data.item.EmailId)"
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left mr-1"
                      >
                        <a
                          href="javascript:void(0)"
                          v-if="data.item != null"
                          v-on:click="showCustomerMailDetail(data.item.EmailId)"
                          class="kt-link"
                        >
                          {{ data.value.EmailSubject }}
                        </a>
                      </div>
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{
                              getFormatService().formatDateToLocal(
                                data.value.DateInstructionToSendEmail,
                              )
                            }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::customerMailer-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";

export default {
  name: "customerMailer",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      errors: [],
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "EmailSubject",
          label: this.$i18n.t("CUSTOMERMAIL.TABLE.EMAILSUBJECT"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DateInstructionToSendEmail",
          label: this.$i18n.t("CUSTOMERMAIL.TABLE.DATEIINSTRUCTIONTOSENDEMAIL"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DateLastChange",
          label: this.$i18n.t("CUSTOMERMAIL.TABLE.DATELASTCHANGE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "EmailCountAll",
          label: this.$i18n.t("CUSTOMERMAIL.TABLE.EMAILCOUNTALL"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
        },
        {
          key: "EmailCountSent",
          label: this.$i18n.t("CUSTOMERMAIL.TABLE.EMAILCOUNTSENT"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showCustomerMailDetail(record.EmailId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },

    showCustomerMailDetail(customerMailId) {
      this.$router.push({
        name: "domus-customerMailer-detail",
        query: {
          menuId: this.menuId,
          customerLimitUsers: this.customerLimitUsers,
          customerMailId: customerMailId,
        },
      });
    },
    createNewMail() {
      this.$router.push({
        name: "domus-customerMailer-detail",
        query: {
          menuId: this.menuId,
          customerLimitUsers: this.customerLimitUsers,
        },
      });
    },
    fetchData() {
      this.items = null;
      let parameters = ""; // parametre prazdne
      ApiService.setHeader();
      ApiService.get("Email", "GetMailList" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.customerLimitUsers = data.ShowLimitUsers;
          this.items = data.EmailHrom || []; // Ensure items is an array
          this.transformData(this.items);
        },
        (error) => {
          this.errors.push(error.message || "Failed to fetch data");
        },
      );
    },
    transformData(items) {
      if (!Array.isArray(items)) {
        this.errors.push("Invalid data format");
        return;
      }
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          EmailId: items[i].EmailId,
          EmailSubject: items[i].EmailSubject,
          DateInstructionToSendEmail: items[i].DateInstructionToSendEmail,
          DateLastChange: items[i].DateLastChange,
          EmailCountAll: items[i].EmailCountAll,
          EmailCountSent: items[i].EmailCountSent,
        };
      }
    },
  },
};
</script>
