import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "./common/config";
import ApiService from "./common/api.service";
import { VERIFY_AUTH, NIC } from "./store/auth.module";
import JwtService from "@/common/jwt.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";

import { CLEAR_API_ERROR } from "@/store/api.module";

import "./common/registerSW";

// Global configuration
Vue.use(config);

// API service init
ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  store.dispatch(CLEAR_API_ERROR);

  // ak nahodou sa este neaplikoval rule z web.config (redirect non-www na www) tak refresh ho tu aplikuje
  if (
    !window.location.href.includes("www.") &&
    (window.location.href.includes("poschodoch.sk") ||
      window.location.href.includes("poschodech.cz"))
  ) {
    window.location.reload();
  }

  if (to.redirectedFrom && to.redirectedFrom.includes("access_token")) {
    let parameters = to.redirectedFrom;
    let start = parameters.indexOf("access_token=") + "access_token=".length;
    let end = parameters.indexOf("&data_access_expiration_time");
    let token = parameters.substring(start, end);
    let action;

    // facebook registration redirect
    if (window.location.href.includes("register-facebook")) {
      next({
        path: "/register",
        replace: true,
        query: {
          token: token,
          type: "facebook",
        },
      });
    } else if (window.location.href.includes("profile-facebook")) {
      // facebook profile connect/disconnect redirect
      if (window.location.href.includes("profile-facebook-deactivate"))
        action = "deactivate";
      else if (window.location.href.includes("profile-facebook-activate"))
        action = "activate";

      next({
        path: "/accountProfile",
        replace: true,
        query: {
          token: token,
          type: "facebook",
          action: action,
        },
      });
    } else {
      // facebook login redirect
      next({
        path: "/facebook",
        replace: true,
        query: {
          token: token,
        },
      });
    }
  }

  if (
    to.redirectedFrom &&
    !to.path.includes("google") &&
    window.location.href &&
    window.location.href.includes("code") &&
    window.location.href.includes("www.googleapis.com") &&
    window.location.href.includes("&scope=email")
  ) {
    let parameters = window.location.href;
    let start = parameters.indexOf("code=") + "code=".length;
    let end = parameters.indexOf("&scope=email");
    let code = parameters.substring(start, end);
    let action;

    if (code && code.includes("%2F")) code = code.replace("%2F", "/");

    // google register redirect
    if (window.location.href.includes("register-google")) {
      next({
        path: "/register",
        replace: true,
        query: {
          type: "google",
          code: code,
        },
      });
    } else if (window.location.href.includes("profile-google")) {
      // google profile connect/disconnect redirect
      if (window.location.href.includes("profile-google-deactivate"))
        action = "deactivate";
      else if (window.location.href.includes("profile-google-activate"))
        action = "activate";

      next({
        path: "/accountProfile",
        replace: true,
        query: {
          type: "google",
          code: code,
          action: action,
        },
      });
    } else {
      // google login redirect
      next({
        path: "/google",
        replace: true,
        query: {
          code: code,
        },
      });
    }
  }

  if (
    from.fullPath === "/" &&
    to.fullPath != "/" &&
    to.fullPath != "/login" &&
    !to.fullPath.includes("/login?") &&
    (to.fullPath.includes("/cp") ||
      to.fullPath.includes("/hlas") ||
      to.fullPath.includes("/zas?") ||
      to.fullPath.includes("/sf") ||
      to.fullPath.includes("/showpic") ||
      to.fullPath.includes("/code") ||
      to.fullPath.includes("/odpocet-"))
  ) {
    Promise.all([store.dispatch(NIC)]).then(next);
  } else {
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  }

  if (
    from.fullPath === "/" &&
    to.fullPath != "/" &&
    to.fullPath != "/login" &&
    !to.fullPath.includes("/login?") &&
    !JwtService.getToken()
  ) {
    //window.localStorage.setItem("redir_path_x", to.fullPath);
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach((to, from) => {
  // koli strankam robenym cez rovnaky subor ktore router bere ako rovnaku path (Account.vue -> Account 1;2;3;4;5;6 napriklad)
  // to.fullPath.includes("zas?id"), koli dokumentpub, aby po zmene linky refreslo
  if (
    to.name === from.name &&
    (to.fullPath.includes("menuId") ||
      to.fullPath.includes("zas?id") ||
      to.fullPath.includes("registrationAgreementNew"))
  ) {
    setTimeout(function() {
      window.location.reload();
    }, 100);
  }
});

// // Load JSON configuration file first
// fetch("config.json")
//   .then((res) => res.json())
//   .then((config) => {

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  beforeCreate: function() {
    // console.log("* VUE APP Config *", this.$appConfig);
    //console.log("config.json", config);
    //ApiService.setBaseUrl(config["API_URL"]);
  },
}).$mount("#app");

// });
