<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Bilancia platieb a predpisov-->
        <KTPortlet v-bind:title="title" class="poschodoch-print">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :refreshed="print()"
                class="poschodoch-account-table"
              >
                <template v-slot:cell(NameOfMovement)="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Bilancia platieb a predpisov-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "account-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "VarSymb",
          label: this.$i18n.t("ACCOUNTLIST.TABLE.VS"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Name",
          label: this.$i18n.t("ACCOUNTLIST.TABLE.NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "TypeOfMovement",
          label: this.$i18n.t("ACCOUNTLIST.TABLE.TYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value) => {
            if (value == "N") return this.$i18n.t("ACCOUNTLIST.FILTER.N");
            if (value == "P") return this.$i18n.t("ACCOUNTLIST.FILTER.P");
            if (value == "Z") return this.$i18n.t("ACCOUNTLIST.FILTER.Z");
          },
        },
        {
          key: "NameOfMovement",
          label: this.$i18n.t("ACCOUNTLIST.TABLE.DETAIL_OF_MOVEMENT"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "DueDate",
          label: this.$i18n.t("ACCOUNTLIST.TABLE.DUE_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ACCOUNTLIST.TABLE.AMOUNT"),
          ),
          sortable: true,
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
      ],
      totalRows: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      year: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.year = this.$route.query.year;
    this.type = this.$route.query.type;
    this.fetchData();
  },
  methods: {
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      let tit = "";
      let docTitle = "";

      if (this.year != null) parameters += "&year=" + this.year.toString();
      if (this.type != null) parameters += "&type=" + this.type.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "AccountListManager" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.Account;
          this.totalRows = this.items.length;
          this.year = data.Year;
          tit = this.title;
        },
      );

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
