<template>
  <div>
    <a id="document-download-link" style="display: none"></a>

    <div class="row h-100">
      <div class="col-lg-12 col-xl-12 h-100">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <KTPortlet v-bind:title="title">
          <template v-slot:toolbar>
            <b-button variant="secondary" v-on:click="back()">
              {{ $i18n.t("CUSTOMERPAGE.TABLE.BACK") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <div class="kt-align-right mt-3">
                  <b-button
                    variant="primary"
                    class="mr-2"
                    @click="onUlozTo()"
                    id="send_button"
                  >
                    {{ $i18n.t("CUSTOMERPAGE.TABLE.SENDBUTTON") }}
                  </b-button>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12">
              <div style="display: flex" class="mb-5 ml-1 mt-3">
                <b-form-checkbox
                  id="checkVisible"
                  v-model="AprReq"
                  class="ml-2"
                  size="lg"
                  style
                  checked="false"
                  switch
                ></b-form-checkbox>
                <span class="mr-5 mt-1">
                  {{ $i18n.t("CUSTOMERLIST.APRREQ") }}
                </span>
              </div>
            </div>
            <div class="row ml-1">
              <!-- <div class="col-lg-6 col-sm-12">                -->

              <b-form-group
                class="col-lg-4 col-sm-12"
                :label="labelDate"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="DateFrom"
                  type="date"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="row ml-1">
              <!-- <div class="col-lg-6 col-sm-12">                -->

              <b-form-group
                class="col-lg-4 col-sm-12"
                :label="labelPrize"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="Prize"
                  type="text"
                  required
                ></b-form-input>
                <b-button
                  variant="secondary"
                  class="btn-sm mr-2 mt-3"
                  @click="onEula(1)"
                  id="reset_button"
                >
                  {{ $i18n.t("CUSTOMERLIST.BUTTONFREESK") }}
                </b-button>
                <b-button
                  variant="secondary"
                  class="btn-sm mr-2 mt-3"
                  @click="onEula(2)"
                  id="original_button"
                >
                  {{ $i18n.t("CUSTOMERLIST.BUTTONFREECZ") }}
                </b-button>
                <b-button
                  variant="secondary"
                  class="btn-sm mr-2 mt-3"
                  @click="onEula(3)"
                  id="original_button"
                >
                  {{ $i18n.t("CUSTOMERLIST.BUTTONFREEEN") }}
                </b-button>
              </b-form-group>
            </div>

            <b-form-group
              class="col-lg-12 col-sm-12"
              :label="labelNameSK"
              label-for="mce-eula-sk"
            >
              <!--<div class="col-lg-12 col-sm-12 h-100"> -->
              <div id="app">
                <editor
                  v-model="EulaSK"
                  id="mce-eula-sk"
                  api-key="ssm8a9c38382if53p3ovm1100t6jnzi43ig0huhllvfofint"
                  :init="{
                    height: 500,
                    menubar: false,
                    language: Lang,
                    invalid_elements: 'script',
                    //language_url : '/tinymce-vue/langs/cs.js',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen table image',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | table | image | code | help',
                  }"
                />
              </div>
            </b-form-group>
            <b-form-group
              class="col-lg-12 col-sm-12"
              :label="labelNameCZ"
              label-for="mce-eula-cz"
            >
              <div id="app">
                <editor
                  v-model="EulaCZ"
                  id="mce-eula-cz"
                  api-key="ssm8a9c38382if53p3ovm1100t6jnzi43ig0huhllvfofint"
                  :init="{
                    height: 500,
                    menubar: false,
                    language: Lang,
                    invalid_elements: 'script',
                    //language_url : '/tinymce-vue/langs/cs.js',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen table image',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | table | image | code | help',
                  }"
                />
              </div>
            </b-form-group>
            <b-form-group
              class="col-lg-12 col-sm-12"
              :label="labelNameEN"
              label-for="mce-eula-en"
            >
              <div id="app">
                <editor
                  v-model="EulaEN"
                  id="mce-eula-en"
                  api-key="ssm8a9c38382if53p3ovm1100t6jnzi43ig0huhllvfofint"
                  :init="{
                    height: 500,
                    menubar: false,
                    language: Lang,
                    invalid_elements: 'script',
                    //language_url : '/tinymce-vue/langs/cs.js',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen table image',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | table | image | code | help',
                  }"
                />
              </div>
            </b-form-group>
            <!--</div>-->

            <b-container fluid h-100>
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="kt-align-right mt-3">
                    <b-button
                      variant="primary"
                      class="mr-2"
                      @click="onUlozTo()"
                      id="send_button"
                    >
                      {{ $i18n.t("CUSTOMERPAGE.TABLE.SENDBUTTON") }}
                    </b-button>
                  </div>
                </div>
              </div>

              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!kolecko">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script src="/path/to/tinymce-vue.min.js"></script>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import Loader from "@/views/components/Loader.vue";
import Editor from "@tinymce/tinymce-vue";
import UIService from "@/common/ui.service";
import i18nService from "@/common/i18n.service.js";

export default {
  name: "domus-eula-admin-detail",
  components: {
    KTPortlet,
    Loader,
    editor: Editor,
  },
  data() {
    return {
      kolecko: 0,
      eulaId: null,
      customerPageId: null,
      errors: [],
      menuId: null,
      title: this.$i18n.t("CUSTOMERLIST.TITLE2"),
      description: null,
      Prize: null,
      Id: null,
      DateFrom: null,
      AprReq: null,

      EulaSK: null,
      EulaCZ: null,
      EulaEN: null,

      Lang:
        i18nService.getActiveLanguage() == "cz"
          ? "cs"
          : i18nService.getActiveLanguage(),
      labelNameSK: this.$i18n.t("CUSTOMERLIST.CONTENTSK"),
      labelNameCZ: this.$i18n.t("CUSTOMERLIST.CONTENTCZ"),
      labelNameEN: this.$i18n.t("CUSTOMERLIST.CONTENTEN"),
      labelDate: this.$i18n.t("CUSTOMERLIST.DATEFROM"),
      labelPrize: this.$i18n.t("CUSTOMERLIST.TABLE.PRIZE"),
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.eulaId = this.$route.query.eulaId;
    this.customerId = this.$route.query.customerId;

    this.fetchData();
  },
  methods: {
    onUlozTo() {
      this.EnabledFields = false;
      UIService.addButtonSpinner("send_button");

      let postContent = {
        Prize: this.Prize,
        Id: this.Id,
        DateFrom: this.DateFrom,
        AprReq: this.AprReq,
        CustomerId: this.customerId,
        EulaSK: this.EulaSK,
        EulaCZ: this.EulaCZ,
        EulaEN: this.EulaEN,
        AprReq: this.AprReq || this.AprReq == 1 ? 1 : 0,
      };

      ApiService.post("Auth/AdminEulaDetailPost", postContent)
        .then(() => {
          UIService.removeButtonSpinner("send_button");
          this.$router.push({
            name: "domus-customer-admin-detail",
            query: { menuId: this.menuId, customerId: this.customerId },
          });
        })
        .catch((response) => {
          this.errors.push(response.response.data.error);
          UIService.removeButtonSpinner("send_button");
          this.EnabledFields = true;
        });
    },
    back() {
      this.$router.push({
        name: "domus-customer-admin-detail",
        query: { menuId: this.menuId, customerId: this.customerId },
      });
    },
    onEula(type) {
      if (type == 1) {
        this.Prize = this.$i18n.t("CUSTOMERLIST.TEXTFREESK");
      }
      if (type == 2) {
        this.Prize = this.$i18n.t("CUSTOMERLIST.TEXTFREECZ");
      }
      if (type == 3) {
        this.Prize = this.$i18n.t("CUSTOMERLIST.TEXTFREEEN");
      }
    },
    fetchData() {
      this.items = null;
      if (this.eulaId === null) {
        this.kolecko = 1;
        this.Id = null;
      }
      {
        let parameters =
          /*"?menuId=" +
          this.menuId.toString() +*/
          "?eulaId=" + this.eulaId.toString();

        ApiService.setHeader();
        ApiService.get("Auth", "AdminEulaDetail" + parameters).then(
          ({ data }) => {
            this.kolecko = 1;

            this.Prize = data.Prize;
            this.Id = data.Id;
            this.DateFrom = data.DateFrom;

            this.EulaSK = data.EulaSK;
            this.EulaCZ = data.EulaCZ;
            this.EulaEN = data.EulaEN;

            this.AprReq = data.AprReq || data.AprReq == 1 ? true : false;
          },
        );
      }
    },
  },
};
</script>
