<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <!--Begin::forgot password-->
      <KTPortlet
        v-show="showMain"
        style="background-color: rgba(255, 255, 255, 0.85)"
      >
        <template v-slot:body>
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="kt-login__title mb-4">
                <h3 class="text-center">
                  {{ $i18n.t("FORGOTPASSWORD.TITLE") }}
                </h3>
              </div>
              <div
                role="alert"
                v-bind:class="{ show: errors && errors.length }"
                class="alert fade alert-danger mt-2 mb-2"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>
              <div class="d-flex mb-4 justify-content-center">
                <span style="margin-top: 0.65rem !important">
                  {{ $i18n.t("ACCOUNTPROFILE.EMAIL") }}
                </span>
                <div class="mr-5" style="min-width: 80%">
                  <b-form-input
                    id="input-Email"
                    :state="validateEmail"
                    v-model="email"
                    type="email"
                    class="ml-3 mr-3"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validateEmail" class="ml-3">
                    {{ $i18n.t("ACCOUNTPROFILE.VALIDATION.EMAIL") }}
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="row justify-content-center">
                <b-button
                  @click="forgotPWD(email)"
                  id="forgot_pwd"
                  variant="primary"
                  :disabled="!validateEmail"
                  style="width: 22rem; height: fit-content"
                >
                  {{ $i18n.t("FORGOTPASSWORD.BUTTON") }}
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </KTPortlet>
      <KTPortlet
        v-show="showCFM"
        style="background-color: rgba(255, 255, 255, 0.85)"
      >
        <template v-slot:body>
          <div class="row justify-content-center">
            <div class="col-lg-9 col-sm-12">
              <div class="d-flex mb-4 justify-content-center">
                <span>
                  {{ $i18n.t("ACCOUNTPROFILE.OPERATIONS.SECPWDCFM.TEXT") }}
                </span>
              </div>
              <div class="row justify-content-center">
                <b-button
                  @click="okButtonPWD()"
                  variant="primary"
                  style="
                    width: 22rem;
                    height: fit-content;
                    color: #ffffff !important;
                  "
                >
                  {{ $i18n.t("ACCOUNTPROFILE.BUTTON.OK") }}
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </KTPortlet>
      <!--End::forgot password-->
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import { mapState } from "vuex";
import UIService from "@/common/ui.service";
import { mapGetters } from "vuex";

export default {
  name: "forgotPassword",
  components: {
    KTPortlet,
  },
  data() {
    return {
      userID: null,
      email: null,
      showMain: true,
      showCFM: false,
      errors: [],
    };
  },
  computed: {
    validateEmail() {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.email,
      );
    },
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    UIService.changeSiteColorCustomer();
    /*if (window.location.host.includes("penta")) {
      UIService.changeSiteColor(this.layoutConfig("colors.state.brand-penta"));
    }else{
      UIService.changeSiteColor(this.layoutConfig("colors.state.brand"));
    }*/
  },
  methods: {
    forgotPWD(mail) {
      let postContent = {
        Email: mail,
      };
      this.errors = [];
      UIService.addButtonSpinner("forgot_pwd");
      ApiService.setHeader();
      ApiService.post("Auth/ForgottenPassword", postContent)
        .then(() => {
          this.showCFM = true;
          this.showMain = false;
          UIService.removeButtonSpinner("forgot_pwd");
        })
        .catch((response) => {
          if (response.response.data.error)
            this.errors.push(response.response.data.error);
          else this.errors.push("Niečo sa pokazilo. Pracujeme na tom...");
          UIService.removeButtonSpinner("forgot_pwd");
        });
    },
    okButtonPWD() {
      this.$router.push({ name: "domus-account-profile" });
    },
  },
};
</script>
