<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <InvoicesDetailDocs v-bind:invoicesDetailDocs="invoicesDetailDocs" />
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:toolbar>
            <b-button variant="secondary" v-on:click="back()">
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-container fluid v-if="invoicesDetailData">
              <div>
                <div class="col-lg-6 col-sm-12 d-flex">
                  <div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("INVOICE_DETAIL.VS") }}
                      </span>
                      {{ vs }}
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ typeDesc }}
                      </span>
                      {{ customer }}
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("INVOICE_DETAIL.TYPE") }}
                      </span>
                      {{ type }}
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("INVOICE_DETAIL.INV_DESC") }}
                      </span>
                      {{ invoiceDescription }}
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("INVOICE_DETAIL.AMOUNT") }}
                      </span>
                      {{ amount }}
                    </div>
                    <div class="row d-flex">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("INVOICE_DETAIL.AMOUNTPAID") }}
                      </span>
                      {{ amountPaid }}
                    </div>
                    <div class="row d-flex mt-3" v-if="docCount > 0">
                      <span style="width: 10rem; font-weight: 500">
                        {{ $i18n.t("INVOICE_DETAIL.DOCS") }}
                      </span>
                      <a
                        href="javascript:void(0)"
                        v-if="docCount > 1"
                        v-on:click="showInvoicesDetailDocs()"
                        class="poschodoch-icon-link p-2"
                      >
                        <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                      </a>
                      <a
                        href="javascript:void(0)"
                        v-if="docCount == 1"
                        v-on:click="showSingleDoc(recKey, tableName)"
                        class="poschodoch-icon-link p-2"
                      >
                        <i class="flaticon-download poschodoch-icon-medium"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <h5 v-if="invoiceRows && invoiceRows.length > 0">
                    {{ $i18n.t("INVOICE_DETAIL.TABLE.INVOICE_ROWS") }}
                  </h5>
                  <b-table
                    class="poschodoch-invoice-detail"
                    v-if="displayOption !== 'header' && invoiceRows"
                    show-empty
                    hover
                    :items="invoiceRows"
                    :fields="invoiceRowFields"
                    :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                  >
                    <template v-slot:cell(Mobile)="data">
                      <div class="d-flex flex-column align-items-start w-100">
                        <span class="poschodoch-invoices-title-mobile">
                          {{ data.item.RowDescription }}
                        </span>
                        <div
                          class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                        >
                          <div
                            class="d-flex flex-column align-items-start poschodoch-mobile-left"
                          >
                            <span v-if="data.item.Quantity">
                              {{
                                $i18n.t("INVOICE_DETAIL.TABLE.AMOUNT") +
                                  ": " +
                                  getFormatService().formatNumberToLocal(
                                    data.item.Quantity,
                                  )
                              }}
                            </span>
                          </div>
                          <div
                            class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1 poschodoch-invoices-amount"
                          >
                            {{
                              addCurrency(
                                getFormatService().formatNumberToLocal(
                                  data.item.RowAmount,
                                ),
                              )
                            }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </b-table>

                  <h5
                    v-if="invoiceAnalysisRows && invoiceAnalysisRows.length > 0"
                  >
                    {{ $i18n.t("INVOICE_DETAIL.TABLE.INVOICE_ANALYSIS_ROWS") }}
                  </h5>
                  <b-table
                    class="poschodoch-invoice-detail"
                    v-if="
                      displayOption === 'headerRowsAnalysis' &&
                        invoiceAnalysisRows
                    "
                    show-empty
                    hover
                    :items="invoiceAnalysisRows"
                    :fields="invoiceAnalysisRowFields"
                    :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                  >
                    <template v-slot:cell(Amount)="data">
                      {{ data.value }} {{ data.item.Unit }}
                    </template>
                    <template v-slot:cell(Mobile)="data">
                      <div class="d-flex flex-column align-items-start w-100">
                        <span class="poschodoch-invoices-title-mobile">
                          {{ data.item.Name }}
                        </span>
                        <div
                          class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                        >
                          <div
                            class="d-flex flex-column align-items-start poschodoch-mobile-left"
                          >
                            <span v-if="data.item.DateFrom && data.item.DateTo">
                              {{
                                $i18n.t(
                                  "INVOICE_DETAIL.TABLE.DATEFROM_MOBILE",
                                ) +
                                  " " +
                                  getFormatService().formatDateToLocal(
                                    data.item.DateFrom,
                                  ) +
                                  " " +
                                  $i18n.t(
                                    "INVOICE_DETAIL.TABLE.DATETO_MOBILE",
                                  ) +
                                  " " +
                                  getFormatService().formatDateToLocal(
                                    data.item.DateTo,
                                  )
                              }}
                            </span>
                            <span v-if="data.item.DeliveryPoint">
                              {{
                                $i18n.t("INVOICE_DETAIL.TABLE.DELIVERYPOINT") +
                                  ": " +
                                  data.item.DeliveryPoint
                              }}
                            </span>
                            <span v-if="data.item.ReadingCode">
                              {{
                                $i18n.t("INVOICE_DETAIL.TABLE.READINGCODE") +
                                  ": " +
                                  data.item.ReadingCode
                              }}
                            </span>
                            <span v-if="data.item.Amount">
                              {{
                                $i18n.t("INVOICE_DETAIL.TABLE.AMOUNT") +
                                  ": " +
                                  getFormatService().formatNumberToLocal(
                                    data.item.Amount,
                                  ) +
                                  " " +
                                  data.item.Unit
                              }}
                            </span>
                          </div>
                          <div
                            class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1 poschodoch-invoices-amount"
                          >
                            {{
                              addCurrency(
                                getFormatService().formatNumberToLocal(
                                  data.item.Value,
                                ),
                              )
                            }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!invoicesDetailData">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import InvoicesDetailDocs from "@/views/pages/domus/InvoicesDetailDocs.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "invoices-detail",
  components: {
    KTPortlet,
    Loader,
    InvoicesDetailDocs,
  },
  data() {
    return {
      invoicesDetailData: null,
      menuId: null,
      title: null,
      items: null,
      description: null,
      invoicesDetailDocs: null,
      docCount: null,
      tableName: null,
      recKey: null,
      invoiceId: null,
      vs: null,
      customer: null,
      invoiceDescription: null,
      amount: null,
      amountPaid: null,
      type: null,
      invoiceRows: null,
      invoiceAnalysisRows: null,
      displayOption: "header",
      displayOptions: [
        { text: "Len hlavička", value: "header" },
        { text: "Hlavička + riadky faktúr", value: "headerRows" },
        {
          text: "Hlavička + riadky faktúr + riadky analýz",
          value: "headerRowsAnalysis",
        },
      ],
      invoiceAnalysisRowFields: [
        {
          key: "Name",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.NAME"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "DeliveryPoint",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.DELIVERYPOINT"),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "DateFrom",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.DATEFROM"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DateTo",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.DATETO"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "ReadingCode",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.READINGCODE"),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "Amount",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.AMOUNT"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Value",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.VALUE"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return this.addCurrency(FormatService.formatNumberToLocal(value));
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      invoiceRowFields: [
        {
          key: "RowDescription",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.ROWDESC"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "Gate",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.GATE"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "Quantity",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.AMOUNT"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "RowAmount",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.VALUE"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return this.addCurrency(FormatService.formatNumberToLocal(value));
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
    };
  },
  mounted() {
    this.invoiceId = this.$route.query.invoiceId;
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return (
        data + " " + FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    back() {
      this.$router.push({
        name: "domus-invoices",
        query: { menuId: this.menuId },
      });
    },
    showInvoicesDetailDocs() {
      let parameters =
        "?tableName=" +
        this.tableName +
        "&recKey=" +
        this.recKey.toString() +
        "&menuId=" +
        this.menuId.toString();
      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDetailDocs = data;
        this.$bvModal.show("modal-invoicesDetail-docs");
      });
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();
      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDetailDocs = data;
        parameters =
          "?docId=" + this.invoicesDetailDocs.Documents[0].DocId.toString();
        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );
          let fileName = this.invoicesDetailDocs.Documents[0].FileName;
          FileService.downloadFile(
            fileName,
            binaryContent,
            null,
            "document-download-link",
          );
        });
      });
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?menuId=" +
        this.menuId.toString() +
        "&invoiceId=" +
        this.invoiceId.toString();
      ApiService.setHeader();
      ApiService.get("Object", "InvoiceDetail" + parameters).then(
        ({ data }) => {
          this.transformData(data.InvoiceDetail);
          this.invoicesDetailData = data;
          this.items = data.InvoiceDetail;
          this.invoiceRows = data.InvoiceDetail;
          this.invoiceAnalysisRows = data.InvoiceDetailAnalysis;
          this.title = data.Title;
          this.description = data.Description;
          this.docCount = data.DocCount;
          this.tableName = data.TableName;
          this.recKey = data.RecKey;
          this.type = data.Type;
          this.typeDesc =
            this.type == "OF"
              ? this.$i18n.t("INVOICE_DETAIL.CUSTOMEROF")
              : this.$i18n.t("INVOICE_DETAIL.CUSTOMERDF");
          this.vs = data.VS;
          this.customer = data.Customer;
          this.invoiceDescription = data.InvoiceDescription;
          this.amount =
            data.ContainsOthers > 0
              ? null
              : this.addCurrency(
                  FormatService.formatNumberToLocal(data.Amount),
                );
          this.amountPaid =
            data.ContainsOthers > 0
              ? null
              : this.addCurrency(
                  !data.AmountPaid
                    ? FormatService.formatNumberToLocal(0)
                    : FormatService.formatNumberToLocal(data.AmountPaid),
                );

          // Dynamické nastavenie displayOption
          if (this.invoiceAnalysisRows && this.invoiceAnalysisRows.length > 0) {
            this.displayOption = "headerRowsAnalysis";
          } else if (this.invoiceRows && this.invoiceRows.length > 0) {
            this.displayOption = "headerRows";
          } else {
            this.displayOption = "header";
          }
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Unit = items[i].Unit == null ? "" : items[i].Unit;
        items[i].Mobile = {
          Name: items[i].Name,
          DeliveryPoint: items[i].DeliveryPoint,
          DateFrom: items[i].DateFrom,
          DateTo: items[i].DateTo,
          ReadingCode: items[i].ReadingCode,
          Amount: items[i].Amount,
          AmountPaid: !items[i].AmountPaid ? 0 : items[i].AmountPaid,
          Value: items[i].Value,
          Unit: items[i].Unit == null ? "" : items[i].Unit,
        };
      }
    },
  },
};
</script>
