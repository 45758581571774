<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Costs-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row>
                <!--Begin::Filter -->
                <b-col sm="12" md="6" lg="6">
                  <ul
                    class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills"
                    role="tablist"
                  >
                    <li class="nav-item" v-for="index in years" :key="index">
                      <a
                        v-on:click="fetchData(index)"
                        data-tab="0"
                        class="nav-link"
                        href="javascript:void(0)"
                        v-bind:class="{ active: index == year }"
                      >
                        {{ index }}
                      </a>
                    </li>
                  </ul>
                </b-col>
                <!--End::Filter -->
              </b-row>

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Consumption)="data">
                  {{ data.value }} {{ data.item.UnitOfMeasure }}
                </template>
                <template v-slot:cell(Analysis)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="showCostDetail(menuId, data.item.Code)"
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                  <span v-if="!(data.item != null)">{{ data.value }}</span>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Costs-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "costs",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      costDetail: null,
      items: null,
      fields: [
        {
          key: "Analysis",
          label: this.$i18n.t("COSTS.TABLE.ANALYSIS"),
          sortable: false,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "Consumption",
          label: this.$i18n.t("COSTS.TABLE.CONSUMPTION"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(this.$i18n.t("COSTS.TABLE.AMOUNT")),
          sortable: false,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
      ],
      year: null,
      years: null,
      sortBy: "",
      sortDirection: "desc",
      filter: null,
      filterOn: [],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showCostDetail(this.menuId, record.Code);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    setYearRange(yearFrom, yearTo) {
      if (this.years == null) {
        this.years = [];
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    showCostDetail(menuId, code) {
      this.$router.push({
        name: "domus-cost-detail",
        query: { menuId: this.menuId, code: code, year: this.year },
      });

      let parameters = "?menuId=" + menuId.toString();
      if (this.year != null) parameters += "&year=" + this.year.toString();
      if (code != null) parameters += "&code=" + code.toString();

      ApiService.setHeader();
      ApiService.get("Object", "CostDetail" + parameters).then(({ data }) => {
        this.costDetail = data;
      });
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.year != null) parameters += "&year=" + this.year.toString();

      window.open("/costsPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("COSTS.TABLE.ANALYSIS"),
        this.$i18n.t("COSTS.TABLE.CONSUMPTION"),
        this.addCurrencyToColumn(this.$i18n.t("COSTS.TABLE.AMOUNT")),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.Analysis,
          FormatService.formatNumberToLocalForCSV(item.Consumption),
          FormatService.formatNumberToLocalForCSV(item.Amount),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + `_${this.year}.csv`;

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(inputYear = null) {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      if (inputYear != null) parameters += "&year=" + inputYear.toString();

      ApiService.setHeader();
      ApiService.get("Object", "Costs" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Costs;

        this.year = data.Year;
        this.setYearRange(data.YearFrom, data.YearTo);
      });
    },
  },
};
</script>
