<template>
  <div class="container-lg">
    <a id="document-download-link" style="display: none"></a>
    <!--<div class="row">     -->
    <!--<div class="col-lg-12 col-xl-12"> -->

    <!--Begin::Comsumption Elements-->
    <KTPortlet v-bind:title="title">
      <template v-slot:title>
        <h3 class="kt-portlet__head-title mr-4">
          <span>
            <slot name="title">
              {{ $i18n.t("INVOICESAPPROVAL.TITLE") }}
            </slot>
          </span>
        </h3>
      </template>
      <!--Begin::Data -->
      <template v-slot:body>
        <!--  <b-container fluid v-if="FakID"> -->
        <!--Begin::Main table element -->

        <!--<b-row class="mb-4 ml-1">
                <b-col cols="12" lg="5">
                  <div class="row d-flex mb-1">
                    <span style="width: 20rem; font-weight: 600">
                      {{ $i18n.t("USERINFO.PERSONALINFO") }}
                    </span>
                  </div>
                </b-col>
              </b-row>-->
        <b-row class="mb-4 ml-1">
          <b-col cols="12" lg="6">
            <div class="row d-flex mb-1">
              <span style="width: 20rem; font-weight: 600">
                {{ $i18n.t("INVOICESAPPROVAL.UDAJKFAK") }}
              </span>
            </div>
            <div class="row d-flex">
              <span style="width: 10rem; font-weight: 500">
                {{ $i18n.t("INVOICESAPPROVAL.VS") }}
              </span>
              {{ VarSymb }}
            </div>
            <div class="row d-flex">
              <span style="width: 10rem; font-weight: 500">
                {{ $i18n.t("INVOICESAPPROVAL.DODAV") }}
              </span>
              {{ DodavNazov }}
            </div>
            <div class="row d-flex">
              <span style="width: 10rem; font-weight: 500">
                {{ $i18n.t("INVOICESAPPROVAL.PREDMET") }}
              </span>
              {{ Pozn }}
            </div>
            <div class="row d-flex">
              <span style="width: 10rem; font-weight: 500">
                {{ $i18n.t("INVOICESAPPROVAL.SUMAKU") }}
              </span>
              {{
                getFormatService().formatNumberToLocal(SumSpolu) + " " + fMena
              }}
            </div>
          </b-col>

          <b-col cols="12" lg="6">
            <div class="row d-flex mb-1">
              <span style="width: 20rem; font-weight: 600">
                {{ $i18n.t("INVOICESAPPROVAL.RIADKYFAKT") }}
              </span>
            </div>
            <b-table
              show-empty
              hover
              size="md"
              :empty-text="$t('COMMON.LIST.NO_RECORDS')"
              :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
              :items="itemsRF"
              :fields="fieldsRF"
              :current-page="currentPageUsers"
              :per-page="perPageUsers"
              class="poschodoch-invoices-rf"
            ></b-table>
            <b-row
              v-if="itemsRF && itemsRF.length > 10"
              class="justify-content-end"
            >
              <b-col sm="12" md="6" lg="6">
                <b-pagination
                  v-model="currentPageUsers"
                  :total-rows="totalRowsUsers"
                  :per-page="perPageUsers"
                  size="md"
                  class="poschodoch-pagination justify-content-end"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr class="poschodoch-separator-line mt-4 mb-4" />
        <b-table
          show-empty
          hover
          size="md"
          :empty-text="$t('COMMON.LIST.NO_RECORDS')"
          :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
          :items="itemsLibDocs"
          :fields="fieldsLibDocs"
          class="poschodoch-documents-invoices"
        >
          <template v-slot:cell(LibId)="data">
            <a
              href="javascript:void(0)"
              v-on:click="showSingleDoc(data.item.Filename, data.item.LibId)"
              class="poschodoch-icon-link p-2"
            >
              <i class="flaticon-download poschodoch-icon-medium"></i>
            </a>
          </template>
          <template v-slot:cell(Mobile)="data">
            <div class="d-flex flex-column align-items-start w-100">
              <div class="d-flex w-100 flex-row justify-content-between">
                <div
                  class="d-flex flex-column align-items-start poschodoch-mobile-left"
                >
                  <a
                    href="javascript:void(0)"
                    v-on:click="
                      showSingleDoc(data.value.Filename, data.value.LibId)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                </div>
                <span>
                  <span
                    class="d-flex flex-column align-items-start poschodoch-mobile-left mr-1"
                  >
                    {{ data.value.Filename }}
                  </span>
                </span>
              </div>
            </div>
          </template>
        </b-table>

        <b-row sm="12" md="12" lg="12">
          <!-- v-if="!data.item.Selected"-->
          <div v-for="item in itemsButtons" :key="item.Kod">
            <b-button
              key="Kod"
              type="submit"
              variant="primary"
              class="btn-sm ml-4 mb-2"
              :disabled="item.Mobile.btnEnabled == false"
              v-if="item.Mobile.btnVisible == true"
              @click="invoiceApproval(item.Kod)"
            >
              {{ item.Nazov }}
            </b-button>
          </div>
        </b-row>

        <b-row sm="12" md="12" lg="12">
          <label
            for="labelYesNo"
            class="mr-1 ml-2"
            v-if="
              disableAno == false &&
                disableNie == false &&
                ShowErrorInt == false
            "
          >
            {{ $i18n.t("INVOICESAPPROVAL.ANONIEHLASKA") }}
          </label>
        </b-row>
        <b-row sm="12" md="12" lg="12">
          <label
            for="labelShowErrorInt"
            class="mr-1 ml-2 text-danger"
            v-if="ShowErrorInt == true"
          >
            {{ $i18n.t("INVOICESAPPROVAL.SHOWERRORINT") }}
          </label>
        </b-row>

        <b-row sm="12" md="12" lg="12">
          <!-- v-if="!data.item.Selected"-->

          <div id="btnyesno">
            <b-button
              key="btnAno"
              type="b-button"
              variant="primary"
              :disabled="disableAno"
              v-if="visibleAno == true"
              class="btn-sm ml-4 mb-2"
              @click="invoiceApproval('ANO')"
            >
              {{ $i18n.t("INVOICESAPPROVAL.ANO") }}
            </b-button>
            <b-button
              key="btnNie"
              type="b-button"
              variant="primary"
              :disabled="disableNie"
              v-if="visibleNie == true"
              class="btn-sm ml-4 mb-2"
              @click="invoiceApproval('NIE')"
            >
              {{ $i18n.t("INVOICESAPPROVAL.NIE") }}
            </b-button>
          </div>
        </b-row>

        <hr class="poschodoch-separator-line mt-4 mb-4" />

        <div class="col-lg-12">
          <b-form-group>
            <label for="Dovod" class="mr-1">
              {{ $i18n.t("INVOICESAPPROVAL.DOVOD") }}
            </label>
            <b-form-textarea
              id="input-xDovod"
              v-model="xDovod"
              type="text"
              rows="3"
              :disabled="disableDovod"
              @change="isEdited = true"
              :required="VyzadovatDovod == 1"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="col-lg-12" v-if="PoznSpravcu === ''">
          <b-form-group>
            <label for="Dovod" class="mr-1"></label>
            <b-form-textarea
              id="input-PoznSpravcu"
              v-model="PoznSpravcu"
              type="text"
              rows="3"
              :disabled="true"
              @change="isEdited = true"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <!--End::Main table element -->

        <!--Begin::Description -->
        <b-row class="justify-content-end" v-if="description">
          <b-col lg="12">
            <hr class="poschodoch-separator-line mt-4 mb-4" />
            <ul class="poschodoch-note-list">
              <li class="poschodoch-note">{{ description }}</li>
            </ul>
          </b-col>
        </b-row>
        <!--End::Description -->
        <!-- </b-container> -->

        <!--Begin::Loader -->
        <b-container fluid v-if="!FakID">
          <Loader />
        </b-container>
        <!--End::Loader -->
      </template>
      <!--End::Data -->
    </KTPortlet>
    <!--End::Consumption Elements-->
    <!--</div> -->
    <!--</div> >-->
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "domus-invoices-approval",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      InvoiceId: null,
      title: null,
      description: null,
      menuId: null,
      itemsRF: null,
      itemsLibDocs: null,
      itemsButtons: null,
      xDovod: null,
      xNewStav: null,
      xSynchStav: null,
      xExtSchval: null,
      FakID: null,
      SubID: null,
      IntCis: null,
      VarSymb: null,
      Pozn: null,
      StavDokl: null,
      HistID: null,
      fMena: null,
      Suma: null,
      SumZakl: null,
      SumDPH: null,
      SumSpolu: null,
      DodavNazov: null,
      PoznSpravcu: null,
      eMail: null,
      UserName: null,
      VyzadovatDovod: null,
      aStav: null,
      sfCode: null,
      disableDovod: false,
      disableAno: false,
      disableNie: false,
      visibleAno: false,
      visibleNie: false,
      ShowErrorInt: false,
      errors: [],
      fieldsLibDocs: [
        {
          key: "LibId",
          label: "",
          sortable: false,
          sortDirection: "desc",
          class: "text-left, table-no-mobile",
        },
        {
          key: "Filename",
          label: this.$i18n.t("INVOICESAPPROVAL.FILE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-left table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-left table-mobile",
        },
      ],
      fieldsRF: [
        {
          key: "RiadFaktNazov",
          label: this.$i18n.t("INVOICESAPPROVAL.NAZOV"),
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "Spolu",
          label: this.$i18n.t("INVOICESAPPROVAL.SPOLU"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "RFMena",
          label: this.$i18n.t("INVOICESAPPROVAL.MENA"),
          sortable: false,
          class: "text-right",
        },
      ],
      totalRowsUsers: 1,
      currentPageUsers: 1,
      perPageUsers: FormatService.formatGetPerPageMin(),
      pageOptionsUsers: FormatService.formatGetPerPageOption(),
      fieldsButtons: [
        {
          key: "Kod",
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Nazov",
          sortable: true,
          sortDirection: "desc",
          class: "text-right, table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.sfCode = this.$route.params.code;
    //this.showAft = false;
    //this.showPre = true;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    addCurrencyToColumn(column) {
      return column + " [" + FormatService.formatCurrency(this.fMena) + "]";
    },
    showSingleDoc(Name, LibId) {
      let extension = Name.split(".").pop();
      let fileName = Name.substring(0, Name.lastIndexOf("."));
      let fileNameC = fileName + "-" + LibId + "." + extension;
      let parameters = "?invoiceId=" + this.sfCode + "&name=" + fileNameC;

      ApiService.setHeader();
      ApiService.get("Public", "InvoiceGetFile" + parameters).then(
        ({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          FileService.downloadFile(
            Name,
            binaryContent,
            null,
            "document-download-link",
          );
        },
      );
    },
    invoiceApproval(sState) {
      this.xNewStav = sState;

      if (
        sState != "ANO" &&
        this.VyzadovatDovod == "1" &&
        (this.xDovod == null || this.xDovod == "")
      ) {
        this.$bvModal.msgBoxOk(this.$i18n.t("INVOICESAPPROVAL.DOVODHLASKA"));
      } else {
        let postContent = {
          InvoiceId: this.InvoiceId,
          Reason: this.xDovod,
          State: sState,
          Device: "",
        };
        ApiService.setHeader();
        ApiService.post("Public/InvoiceUpdate", postContent).then(() => {
          this.$bvModal
            .msgBoxOk(this.$i18n.t("INVOICESAPPROVAL.DAKUEJME"))
            .then((value) => {
              if (value != null) {
                window.location = "/sf" + this.InvoiceId;
                window.location.reload();
              }
            });
        });
      }
    },
    fetchData() {
      this.itemsLibDocs = null;

      //z parametra
      this.aStav = 0;
      //MAzAMAyAMAzAMA2A
      //sfMKzUMMzRMPjZcT3Gstav0
      //let parameters = "?InvoiceId=" + this.menuId.toString() ;
      //this.InvoiceId = "MAzAMAyAMAzAMA2A";
      if (this.sfCode.includes("stav")) {
        const indexOfFirst = this.sfCode.indexOf("stav");
        this.aStavS = this.sfCode.substring(indexOfFirst + 4, indexOfFirst + 5);
        this.sfCode = this.sfCode.substring(0, indexOfFirst);
        if (this.aStavS != "0") {
          this.aStav = parseInt(this.aStavS);
        }
        //this.stavURL = const indexOfFirst = paragraph.indexOf(searchTerm);
      }
      this.InvoiceId = this.sfCode.toString();
      let parameters = "?InvoiceId=" + this.sfCode.toString();

      ApiService.setHeader();
      ApiService.get("Public", "Invoice" + parameters)
        .then(({ data }) => {
          console.log("Building Elements");
          console.log(data);

          this.title = data.Title;
          this.description = data.Description;

          this.xDovod = data.xDovod;

          this.xNewStav = data.xNewStav;

          this.xSynchStav = data.xSynchStav;

          //esterne schvalovanie
          this.xExtSchval = data.xExtSchval;

          (this.FakID = data.FakID), (this.SubID = data.SubID);
          this.IntCis = data.IntCis;
          this.VarSymb = data.VarSymb;
          this.Pozn = data.Pozn;
          this.StavDokl = data.StavDokl;
          this.HistID = data.HistID;
          this.fMena = data.fMena;
          this.Suma = data.Suma;
          this.SumZakl = data.SumZakl;
          this.SumDPH = data.SumDPH;
          this.SumSpolu = data.SumSpolu;
          this.DodavNazov = data.DodavNazov;
          this.PoznSpravcu = data.PoznSpravcu;
          this.eMail = data.eMail;
          this.UserName = data.UserName;

          //pri post kontrolovat vyplnenny dovod
          this.VyzadovatDovod = data.VyzadovatDovod;
          if (data.Libdocs != null) {
            this.transformDataLibDocs(data.Libdocs);
          }
          if (data.InvoiceRow != null) {
            this.transformDataInvoiceRow(data.InvoiceRow);
          }
          this.itemsRF = data.InvoiceRow;
          this.itemsLibDocs = data.Libdocs;

          if (this.itemsRF != null) {
            this.totalRowsUsers = this.itemsRF.length;
          }

          if (data.State != null) {
            this.transformDataState(data.State, true, "");
          }

          if (this.xSynchStav != 0 && this.xNewStav != null) {
            this.disableDovod = true;
            if (this.xExtSchval == 1) {
              if (this.xNewStav == "ANO") {
                this.disableAno = true;
                this.disableNie = true;
                this.visibleAno = true;
                this.visibleNie = false;
              } else {
                this.disableAno = true;
                this.disableNie = true;
                this.visibleAno = false;
                this.visibleNie = true;
              }

              //ltAnoNieHlaska.Visible = false;
            } else {
              if (data.State != null) {
                this.transformDataState(data.State, false, this.xNewStav);
              }
            }
          }
          if (data.State == null && this.xExtSchval == 0) {
            this.ShowErrorInt = true;
          }
          if (
            this.xSynchStav == 0 &&
            data.State == null &&
            this.xExtSchval == 1
          ) {
            this.disableAno = false;
            this.disableNie = false;
            this.visibleAno = true;
            this.visibleNie = true;
          }
          if (
            (this.aStav == "1" || this.aStav == "2") &&
            this.xSynchStav == 0
          ) {
            if (this.aStav == "1") {
              this.disableAno = true;
              this.disableNie = true;
              this.visibleAno = true;
              this.visibleNie = false;
              this.disableDovod = true;
              //ltAnoNieHlaska.Visible = false;
              let postContent = {
                InvoiceId: this.InvoiceId,
                Reason: "",
                State: "ANO",
                Device: "",
              };
              ApiService.setHeader();
              ApiService.post("Public/InvoiceUpdate", postContent).then(() => {
                this.$bvModal.msgBoxOk(
                  this.$i18n.t("INVOICESAPPROVAL.DAKUEJME"),
                );
              });
            }
            if (this.aStav == "2") {
              this.disableAno = true;
              this.visibleAno = false;
              this.visibleNie = true;

              if (this.VyzadovatDovod == 0) {
                this.disableNie = true;
                this.disableDovod = true;
                let postContent = {
                  InvoiceId: this.InvoiceId,
                  Reason: "",
                  State: "NIE",
                  Device: "",
                };
                ApiService.setHeader();
                ApiService.post("Public/InvoiceUpdate", postContent).then(
                  () => {
                    this.$bvModal.msgBoxOk(
                      this.$i18n.t("INVOICESAPPROVAL.DAKUEJME"),
                    );
                  },
                );
              }
            }
          }
          this.itemsButtons = data.State;
        })
        .catch((response) => {
          this.errors.push(response.response.data.error);
        });
    },
    //itemsRF: null,
    //itemsLibDosc: null,
    //itemsButtons: null,
    transformDataInvoiceRow(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          RFMena: items[i].RFMena,
          RiadFaktNazov: items[i].RiadFaktNazov,
          Spolu: items[i].Spolu,
        };
      }
    },
    transformDataLibDocs(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Filename: items[i].Filename,
          LibId: items[i].LibId,
        };
      }
    },
    // linka nieje dobra, testovat
    transformDataState(items, stEnabbled, stStav) {
      if (items.length > 0) {
        //ltAnoNieHlaska.Visible = false;
        this.disableAno = true;
        this.disableNie = true;
        this.visibleAno = false;
        this.visibleNie = false;
      }
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Nazov: items[i].Nazov,
          Kod: items[i].Kod,
          btnEnabled: stEnabbled,
          btnVisible: stStav === items[i].Kod || stStav === "" ? true : false,
        };
      }
    },
  },
};
</script>
