<template>
  <!--<b-modal id="modal-registration-terms" hide-footer size="lg" v-show="visibleTerm">-->
  <b-col xs="12" sm="10" md="10" lg="8" class="mx-auto">
    <b-card
      text-variant="black"
      style="background-color: rgba(255, 255, 255, 0.85)"
    >
      <b-card-body>
        <!--begin::Body-->
        <!--v-show="!visibleTerm.Signed"-->
        <div class="kt-login__body" id="ana_terms">
          <h3>{{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.HEAD") }}</h3>
          <div
            class="poshodoch-multiline-header d-flex flex-column align-items-start pt-3 pb-3"
          >
            <span class="font-weight-bold">
              {{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.ANASOFT_TEXT") }}
            </span>
            <b-form-checkbox
              v-show="this.type == 'A'"
              v-model="$v.form.checkedLegalAgreementAna.$model"
              :state="validateState('checkedLegalAgreementAna')"
            >
              <router-link
                :to="{ name: 'registrationTerms', query: { type: 2 } }"
                class="kt-link"
                target="_blank"
              >
                {{
                  $i18n.t(
                    "AUTH.REGISTRATION_AGREEMENT.LEGAL_AGREEMENT_CHECKBOX_ANA",
                  )
                }}
              </router-link>
            </b-form-checkbox>
          </div>
          <div class="kt-login__form">
            <b-form class="kt-form" @submit.stop.prevent="onSubmit">
              <!--Begin::Checkbox -->

              <b-form-checkbox
                v-model="$v.form.checkedPrice.$model"
                v-show="this.type == 'Z'"
                :state="validateState('checkedPrice')"
              >
                <router-link
                  :to="{ name: 'registrationTerms', query: { type: 3 } }"
                  class="kt-link"
                  target="_blank"
                >
                  {{
                    $i18n.t(
                      "AUTH.REGISTRATION_AGREEMENT.LEGAL_AGREEMENT_CHECKBOX",
                    )
                  }}
                </router-link>
              </b-form-checkbox>
              <b-form-checkbox
                v-show="this.type == 'Z'"
                v-model="$v.form.gdprAgreement.$model"
                :state="validateState('gdprAgreement')"
              >
                <router-link
                  :to="{ name: 'gdprTerms' }"
                  class="kt-link"
                  target="_blank"
                >
                  {{
                    $i18n.t(
                      "AUTH.REGISTRATION_AGREEMENT.GDPR_AGREEMENT_CHECKBOX",
                    )
                  }}
                </router-link>
              </b-form-checkbox>

              <div class="mt-3" v-show="this.type == 'Z'">
                <span class="font-weight-bold">
                  {{
                    $i18n.t("AUTH.REGISTRATION_AGREEMENT.PRICE_TEXT") + price
                  }}
                </span>
                <p class="font-weight-normal">
                  <span>
                    {{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.PRICE_TEXT2") }}
                  </span>
                </p>
                <b-form-checkbox
                  :state="validateState('checkedLegalAgreement')"
                  v-show="this.type == 'Z'"
                  v-model="$v.form.checkedLegalAgreement.$model"
                >
                  {{ $i18n.t("AUTH.REGISTRATION_AGREEMENT.PRICE_CHECKBOX") }}
                </b-form-checkbox>
              </div>
              <!--End::Checkbox -->
              <hr />
              <div class="mx-auto col-sm-12 col-lg-7">
                <div class="kt-login__actions mt-4">
                  <b-button
                    block
                    type="submit"
                    id="kt_submit"
                    variant="primary"
                  >
                    {{ $t("AUTH.REGISTRATION_AGREEMENT.AGREE_BUTTON") }}
                  </b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
  <!--</b-modal>-->
</template>
<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import ApiService from "@/common/api.service";
//import { ADD_REGISTRATION_CODE } from "@/store/auth.module";
//import JwtService from "@/common/jwt.service";
import { CHANGE_UNIT } from "@/store/auth.module";
import UIService from "@/common/ui.service";

export default {
  mixins: [validationMixin],
  name: "registrationAgreementNew",
  props: {
    visibleTerm: {},
  },
  data() {
    return {
      form: {
        checkedPrice: false,
        checkedLegalAgreement: false,
        gdprAgreement: false,
        checkedLegalAgreementAna: false,
      },
      type: "",
      obj: null,
      price: "",
    };
  },
  validations: {
    form: {
      checkedPrice: {
        checked(val) {
          return this.type === "A" || val === true;
        },
      },
      checkedLegalAgreement: {
        checked(val) {
          return this.type === "A" || val === true;
        },
      },
      checkedLegalAgreementAna: {
        checked(val) {
          return this.type === "Z" || val === true;
        },
      },
      gdprAgreement: {
        checked(val) {
          return this.type === "A" || val === true;
        },
      },
    },
  },
  mounted() {
    console.log(this.$route.params);
    this.type = this.$route.query.type;
    this.obj = this.$route.params.ssk;
    this.price = this.$route.params.price;
    if (this.price == null) {
      this.price = this.$route.query.price;
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      UIService.addButtonSpinner("kt_submit");

      if (this.isAuthenticated) {
        if (this.type == "A") {
          let postContent = {
            Type: "A",
          };

          ApiService.setHeader();
          ApiService.post("Account/ApproveTerms", postContent).then(() => {
            if (this.obj != null) {
              this.$store.dispatch(CHANGE_UNIT, this.obj).then(() => {
                // podmienky zak
                let paramsZ = "?Type=ZakTerms";
                ApiService.setHeader();
                ApiService.get("Account", "CheckProperties" + paramsZ).then(
                  ({ data }) => {
                    if (data.Signed === 0) {
                      UIService.removeButtonSpinner("kt_submit");
                      this.price = data.Price;
                      this.$router.push({
                        name: "registrationAgreementNew",
                        query: { type: "Z", price: this.price },
                        params: { price: this.price },
                      });

                      /*window.location =
                        window.location.origin +
                        "/registrationAgreementNew?type=Z";
                      UIService.removeButtonSpinner("kt_submit");
                      window.location.reload();*/
                    } else {
                      UIService.removeButtonSpinner("kt_submit");
                      this.$router.push({ name: "dashboard" });
                    }
                  },
                );
              });
            } else {
              UIService.removeButtonSpinner("kt_submit");
              this.$router.push({ name: "units" });
            }
          });
        } else {
          let postContent = {
            Type: "Z",
          };

          ApiService.setHeader();
          ApiService.post("Account/ApproveTerms", postContent).then(() => {
            UIService.removeButtonSpinner("kt_submit");
            this.$router.push({ name: "dashboard" });
          });
        }
      } else {
        this.$router.push({ name: "login" });
      }
    },
  },
  computed: {
    ...mapState({
      stateAgreementInfo: (state) => state.auth.stateAgreementInfo,
    }),
    ...mapGetters(["layoutConfig", "isAuthenticated"]),
  },
};
</script>
