<template>
  <div>
    <b-modal
      id="modal-inspection-detail"
      hide-footer
      size="xl"
      v-show="inspectionDetail"
      class="poschodoch-modal-inspection-detail"
    >
      <template v-slot:modal-title v-if="inspectionDetail">
        <span v-if="inspectionDetail.Title" class="poschodoch-modal-title">
          {{ inspectionDetail.Title }}
        </span>
      </template>

      <div class="d-block text-center">
        <b-table
          v-if="inspectionDetail && inspectionDetail.InspectionDetail"
          show-empty
          hover
          :items="inspectionDetail.InspectionDetail"
          :fields="fields"
          :empty-text="$t('COMMON.LIST.NO_RECORDS')"
        >
          <template v-slot:cell(DefectsAll)="data">
            <span>{{ data.item.Defects + " / " }}{{ data.item.Todo }}</span>
          </template>
          <template v-slot:cell(Mobile)="data">
            <div class="ml-3 d-flex flex-column align-items-start w-100">
              <div
                class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
              >
                <div class="d-flex flex-column align-items-start">
                  <span>{{ data.item.NameOfEngineer }}</span>
                  <span>
                    {{
                      $i18n.t("INSPECTION_DETAIL.TABLE.DATE") +
                        ": " +
                        getFormatService().formatDateToLocal(data.item.Date)
                    }}
                  </span>
                  <span v-if="data.item.Defects != 0">
                    {{
                      $i18n.t("INSPECTION_DETAIL.TABLE.DEFECTS") +
                        ": " +
                        data.item.Defects
                    }}
                  </span>
                  <span v-if="data.item.Defects != 0">
                    {{
                      $i18n.t("INSPECTION_DETAIL.TABLE.LASTINSPECTION") +
                        ": " +
                        getFormatService().formatDateToLocal(
                          data.item.LastInspection,
                        )
                    }}
                  </span>
                </div>
                <div class="d-flex flex-column align-items-end">
                  <span>{{ data.item.State }}</span>
                  <span>
                    {{
                      $i18n.t("INSPECTION_DETAIL.TABLE.EXPENSE") +
                        ": " +
                        getFormatService().formatNumberToLocal(
                          data.item.Expense,
                        )
                    }}
                  </span>
                  <span v-if="data.item.Defects != 0">
                    {{
                      $i18n.t("INSPECTION_DETAIL.TABLE.TODO") +
                        ": " +
                        data.item.Todo
                    }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FormatService from "@/common/format.service";
import JwtService from "@/common/jwt.service";

export default {
  name: "inspection-detail",
  props: {
    inspectionDetail: {},
  },
  data() {
    return {
      fields: [
        {
          key: "NameOfEngineer",
          label: this.$i18n.t("INSPECTION_DETAIL.TABLE.NAMEOFENGINEER"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "Date",
          label: this.$i18n.t("INSPECTION_DETAIL.TABLE.DATE"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "ValidTo",
          label: this.$i18n.t("INSPECTION_DETAIL.TABLE.VALIDTO"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Expense",
          label: this.$i18n.t("INSPECTION_DETAIL.TABLE.EXPENSE"),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "DefectsAll",
          label: this.$i18n.t("INSPECTION_DETAIL.TABLE.DEFECTSALL"),
          sortable: true,
          sortDirection: "desc",
          class: "text-center table-no-mobile",
        },
        {
          key: "LastInspection",
          label: this.$i18n.t("INSPECTION_DETAIL.TABLE.LASTINSPECTION"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "State",
          label: this.$i18n.t("INSPECTION_DETAIL.TABLE.STATE"),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
    };
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
  },
};
</script>
