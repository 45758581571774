var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AnnualBillingDetail',{attrs:{"annualBillingDetail":_vm.annualBillingDetail}}),_c('AnnualBillingDocs',{attrs:{"annualBillingDocs":_vm.annualBillingDocs}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('KTPortlet',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('TableContextMenu',{attrs:{"functionCSV":_vm.downloadCSV,"functionPrint":_vm.print}})]},proxy:true},{key:"body",fn:function(){return [(_vm.items)?_c('b-container',{attrs:{"fluid":""}},[_c('b-table',{staticClass:"poschodoch-anbual-billing-table",attrs:{"show-empty":"","hover":"","size":"md","empty-text":_vm.$t('COMMON.LIST.NO_RECORDS'),"items":_vm.items,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-direction":_vm.sortDirection,"tbody-tr-class":_vm.pointerRowClass},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"row-clicked":_vm.tableRowClickHandler},scopedSlots:_vm._u([{key:"cell(DocCount)",fn:function(data){return [_c('div',{staticClass:"h-100 w-100 p-2 d-flex align-items-center justify-content-center",staticStyle:{"cursor":"default"},on:{"click":function($event){$event.stopPropagation();}}},[(data.value > 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showAnnualBillingDocs(
                        data.item.RecKey,
                        data.item.TableName
                      )}}},[_c('i',{staticClass:"flaticon-file-2 poschodoch-icon-medium"})]):_vm._e(),(data.value == 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showSingleDoc(data.item.RecKey, data.item.TableName)}}},[_c('i',{staticClass:"flaticon-download poschodoch-icon-medium"})]):_vm._e()])]}},{key:"cell(Mobile)",fn:function(data){return [_c('div',{staticClass:"ml-3 d-flex flex-column align-items-start w-100",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"},[_c('div',{staticClass:"d-flex flex-column align-items-start poschodoch-mobile-left"},[(data.item != null)?_c('a',{staticClass:"kt-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showAnnualBillingDetail(
                            _vm.menuId,
                            data.item.PeriodId,
                            data.item.Account,
                            data.item.Group,
                            data.item.AccountType
                          )}}},[_vm._v(" "+_vm._s(data.value.Name)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-items-end poschodoch-mobile-right"},[_c('span',{class:{
                          'table-danger': data.value.FinalBalance < 0,
                        }},[_vm._v(" "+_vm._s(data.value.FinalBalance)+" ")]),_c('span',{staticClass:"poschodoch-mobile-document-link"},[(data.item.DocCount > 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showAnnualBillingDocs(
                              data.item.RecKey,
                              data.item.TableName
                            )}}},[_c('i',{staticClass:"flaticon-file-2 poschodoch-icon-medium"})]):_vm._e(),(data.item.DocCount == 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showSingleDoc(
                              data.item.RecKey,
                              data.item.TableName
                            )}}},[_c('i',{staticClass:"flaticon-download poschodoch-icon-medium"})]):_vm._e()])])])])]}},{key:"cell(Name)",fn:function(data){return [(data.item != null)?_c('a',{staticClass:"kt-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showAnnualBillingDetail(
                      _vm.menuId,
                      data.item.PeriodId,
                      data.item.Account,
                      data.item.Group,
                      data.item.AccountType
                    )}}},[_vm._v(" "+_vm._s(data.value)+" ")]):_vm._e(),(!(data.item != null))?_c('span',[_vm._v(_vm._s(data.value))]):_vm._e()]}}],null,false,662953405)}),(_vm.description)?_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"lg":"12"}},[_c('hr',{staticClass:"poschodoch-separator-line mt-4 mb-4"}),_c('ul',{staticClass:"poschodoch-note-list"},[_c('li',{staticClass:"poschodoch-note"},[_vm._v(_vm._s(_vm.description))])])])],1):_vm._e()],1):_vm._e(),(!_vm.items)?_c('b-container',{attrs:{"fluid":""}},[_c('Loader')],1):_vm._e()]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }