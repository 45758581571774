/**
 * Service to work with date, number formats
 */
const GridPage = "GridPage";

import store from "@/store";

const GridService = {
  config: store.getters.layoutConfig(),

  getGridFilter(Name) {
    let page = window.localStorage.getItem(GridPage);
    window.localStorage.setItem(GridPage, null);
    if (page != "null") {
      let pageJS = JSON.parse(page);
      if (pageJS.name == Name) {
        return pageJS;
      }
    }
    return null;
  },

  setGridFilter(page) {
    let a = JSON.stringify(page);
    window.localStorage.setItem(GridPage, a);
  },
};

export default GridService;
