<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Identity takeover-->
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">{{ title }}</slot>
              </span>
            </h3>
          </template>

          <template v-slot:body>
            <!-- Filtre -->
            <div
              class="row d-flex flex-row mb-3 justify-content-md-start justify-content-center"
            >
              <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                <label for="search" class="mr-1" style="font-weight: 500">
                  {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                </label>
                <b-form-input
                  class="col-4"
                  v-model="inputSearch"
                  @change="Search()"
                ></b-form-input>
              </b-col>
            </div>
            <!-- Filtre -->
            <!--Begin::Users -->
            <b-container fluid v-if="itemsUsers">
              <b-row class="mt-3">
                <b-col
                  sm="12"
                  md="6"
                  lg="6"
                  style="align-self: flex-end"
                ></b-col>
                <b-col
                  class="poschodoch-rows-count"
                  v-if="itemsUsers.length > 10"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelectUsers"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPageUsers"
                      id="perPageSelectUsers"
                      size="sm"
                      :options="pageOptionsUsers"
                      @change="
                        getFormatService().formatSetPerPage(perPageUsers)
                      "
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                hover
                size="md"
                stacked="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="itemsUsers"
                :fields="fieldsUsers"
                :current-page="currentPageUsers"
                :per-page="perPageUsers"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="notranslate"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
                :filter="inputSearch"
                @filtered="onFiltered"
              >
                <template v-slot:cell(Link)="data">
                  <a
                    v-if="data"
                    href="javascript:void(0)"
                    v-on:click="tableRowClickHandler(data.item)"
                    class="kt-notification__item"
                  >
                    <i class="fas fa-user poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(Color)="data">
                  <b-form-input
                    type="color"
                    v-model="data.item.Color"
                    style="width: 50px; height: 25px; background-color: white"
                    :disabled="true"
                  ></b-form-input>
                </template>
                <template v-slot:cell(EulaSK)="data">
                  <b-form-checkbox
                    v-model="data.item.EulaSK"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(EulaCZ)="data">
                  <b-form-checkbox
                    v-model="data.item.EulaCZ"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(EulaEN)="data">
                  <b-form-checkbox
                    v-model="data.item.EulaEN"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(Logo)="data">
                  <b-form-checkbox
                    v-model="data.item.Logo"
                    :disabled="true"
                  ></b-form-checkbox>
                </template>
              </b-table>
              <b-row
                v-if="itemsUsers && itemsUsers.length > 10"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPageUsers"
                    :total-rows="totalRowsUsers"
                    :per-page="perPageUsers"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-container>
            <!--End::Users -->

            <!--Begin::Description -->
            <!-- <b-container fluid v-if="itemsUsers || itemsObjects || itemsAdmins">
              <b-row class="justify-content-end">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container> -->
            <!--End::Description -->
          </template>
        </KTPortlet>
        <!--End::Identity takeover-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
//import JwtService from "@/common/jwt.service";

export default {
  name: "domus-customer_list_admin",
  components: {
    KTPortlet,
  },
  data() {
    return {
      menuId: null,
      title: this.$i18n.t("CUSTOMERLIST.TITLE"),
      description: "future description 3333",
      disableAccess: true,
      customerId: null,
      itemsUsers: null,
      inputSearch: null,
      DataTr: {
        CustomerID: null,
        CustomerValue: null,
        UaID: null,
        UaEmail: null,
        Close: false,
      },
      //kod, nazov, mesto, cena, farba (color), Eula SK, EulaCz, EulaEn, Logo
      //Code Name City Prize Color EulaSK EulaCZ EulaEN Logo
      fieldsUsers: [
        {
          key: "Name",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.NAME"),
          sortable: true,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Code",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.CODE"),
          sortable: true,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "City",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.CITY"),
          sortable: true,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Prize",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.PRIZE"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Color",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.COLOR"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "EulaSK",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.EULASK"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "EulaCZ",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.EULACZ"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "EulaEN",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.EULAEN"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Logo",
          label: this.$i18n.t("CUSTOMERLIST.TABLE.LOGO"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
      ],
      totalRowsUsers: 1,
      currentPageUsers: 1,
      perPageUsers: FormatService.formatGetPerPage(),
      pageOptionsUsers: FormatService.formatGetPerPageOption(),

      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      //<DeactivateUnit />

      this.showAdminCustomerDetail(record.Id);
    },
    pointerRowClass() {
      return "pointer-row";
    },
    showAdminCustomerDetail(customerId) {
      this.$router.push({
        name: "domus-customer-admin-detail",
        query: { menuId: this.menuId, customerId: customerId },
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        //Code Name City Prize Color EulaSK EulaCZ EulaEN Logo
        items[i].EulaSK = items[i].EulaSK == 1 ? true : false;
        items[i].EulaCZ = items[i].EulaCZ == 1 ? true : false;
        items[i].EulaEN = items[i].EulaEN == 1 ? true : false;
        items[i].Logo = items[i].Logo != null ? true : false;

        items[i].Mobile = {
          Code: items[i].Code,
          Name: items[i].Name,
          City: items[i].City,
          Prize: items[i].Prize,
          Color: items[i].Color,
          EulaSK: items[i].EulaSK,
          EulaCZ: items[i].EulaCZ,
          EulaEN: items[i].EulaEN,
          Logo: items[i].Logo,
        };
      }
    },
    Search() {
      this.totalRowsUsers = this.itemsUsers.length;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsUsers = filteredItems.length;
      this.currentPage = 1;
    },
    fetchData() {
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Auth", "AdminCustomerList" + parameters).then(
        ({ data }) => {
          this.transformData(data.Customers);
          this.itemsUsers = data.Customers;

          this.totalRowsUsers = this.itemsUsers.length;
        },
      );
    },
  },
};
</script>
