<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <InvoicesDetailDocs v-bind:invoicesDetailDocs="invoicesDetailDocs" />

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--<KTPortlet v-bind:title="title">
          <template v-slot:toolbar>
            <b-button variant="secondary" v-on:click="back()">
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-container fluid>
              <div class="row">
                
                  {{ Name + ", "}} {{ Street + ", " }} {{ PSC + ", " + City }}
                  <div class="col-lg-12 mt-3">
                    <hr class="poschodoch-separator-line mt-4 mb-4" />
                  </div>
                <div class="col-lg-6 col-sm-12 ">
                  <div class="row mt-3">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.ODD1") }}
                    </span>
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.NAME") }}
                    </span>
                    {{ zas_nazov }}
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.STREET") }}
                    </span>
                    {{ zas_ulica }}
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.PCSCITY") }}
                    </span>
                    {{ zas_psc + ", " + zas_mesto }}
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.TEL") }}
                    </span>
                    {{ telefon }}
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.MOBIL") }}
                    </span>
                    {{ mobil }}
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.EMAIL") }}
                    </span>
                    {{ email }}
                  </div>
                </div>

                <div class="col-lg-6 col-sm-12 ">
                  <div class="row mt-3">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.ODD2") }}
                    </span>
                  </div>
                  
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.VS") }}
                    </span>
                    {{ VS }}
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.SIPO") }}
                    </span>
                    {{ evcislosipo }}
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.PAYINFO") }}
                    </span>
                    {{ bankaccount }}
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.PERSONCOUNT") }}
                    </span>
                    {{ bankaccount }}
                  </div>
                </div>
                <div
                  class="col-lg-12 col-sm-12 mt-3"
                  style="padding-left: 0px !important"
                ></div>
                <div class="col-lg-12 mt-3">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                </div>
                              

                <div class="col-lg-12 col-sm-12 ">
                  <div class="row mt-3">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.ODD3") }}
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 ">
                  
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.ROOMS") }}
                    </span>
                    {{ Name }}
                  </div>
                  <div class="row mt-3 ml-1">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("USERLISTOFFICIALS.CUT") }}
                    </span>
                    {{ Street }}
                  </div>                  
                </div>

                <div class="col-lg-3 col-md-5 col-sm-11 ">
                  <div class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between mt-3 ml-1" v-if="plocha1Popis">
                    <span style="width: 20rem; font-weight: 500">
                      {{ plocha1Popis }}
                    </span >                    
                    <span class="d-flex flex-column align-items-end poschodoch-mobile-right ">
                    {{plocha1}}&#178;
                    </span>
                  </div>
                  <div class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between mt-3 ml-1" v-if="plocha2Popis">
                    <span style="width: 20rem; font-weight: 500">
                      {{ plocha2Popis }}
                    </span>
                    <span class="d-flex flex-column align-items-end poschodoch-mobile-right ">
                      {{plocha2}}&#178;
                    </span>
                  </div>
                  <div class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between mt-3 ml-1" v-if="plocha3Popis">
                    <span style="width: 20rem; font-weight: 500">
                      {{ plocha3Popis }}
                    </span>
                    <span class="d-flex flex-column align-items-end poschodoch-mobile-right ">
                      {{plocha3}}&#178;
                    </span>
                  </div>
                  <div class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between mt-3 ml-1" v-if="plocha4Popis">
                    <span style="width: 20rem; font-weight: 500">
                      {{ plocha4Popis }}
                    </span>
                    <span class="d-flex flex-column align-items-end poschodoch-mobile-right ">
                      {{plocha4}}&#178;
                    </span>
                  </div>
                </div>
                <div
                  class="col-lg-12 col-sm-12 mt-3"
                  style="padding-left: 0px !important"
                ></div>

              </div>
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container> -->

        <!--Begin::Loader -->
        <!--<b-container fluid v-if="!title">
              <Loader />
            </b-container>-->
        <!--End::Loader -->
        <!--</template>
        </KTPortlet> -->

        <KTPortlet v-bind:title="title">
          <template v-slot:toolbar>
            <b-button variant="secondary" v-on:click="back()">
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-container fluid>
              <div class="row">
                <i class="flaticon-users-1 poschodoch-icon-large mr-3"></i>
                <div class="mt-3">
                  {{ Name }}
                </div>

                <i
                  class="flaticon-buildings poschodoch-icon-large mr-3 ml-3"
                ></i>
                <div class="mt-3">
                  {{ Street + ", " }} {{ PSC + ", " + City }}
                </div>
              </div>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!title">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>

        <b-card-group columns class="poschodoch-column-card-group">
          <!--Begin::Kontakty -->
          <b-card class="poschodoch-card">
            <KTPortlet style="display: inline">
              <template v-slot:title>
                <h3 class="kt-portlet__head-title ">
                  <i class="flaticon-list poschodoch-icon-large mr-3"></i>
                  <span class="poschodoch-dashboard-title">
                    {{ $i18n.t("USERLISTOFFICIALS.ODD1") }}
                  </span>
                </h3>
              </template>
              <template v-slot:body>
                <b-container class fluid>
                  <div class="poschodoch-dashboard-title">
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.NAME") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ zas_nazov }}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.STREET") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ zas_ulica }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.PCSCITY") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ zas_psc + ", " + zas_mesto }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.TEL") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ telefon }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.MOBIL") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ mobil }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.EMAIL") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ email }}
                      </div>
                    </div>
                  </div>
                </b-container>
              </template>
            </KTPortlet>
          </b-card>

          <!--Begin::udaje bytu -->
          <b-card class="poschodoch-card">
            <KTPortlet style="display: inline">
              <template v-slot:title>
                <h3 class="kt-portlet__head-title ">
                  <i class="flaticon-home poschodoch-icon-large mr-3"></i>
                  <span class="poschodoch-dashboard-title">
                    {{ $i18n.t("USERLISTOFFICIALS.ODD3") }}
                  </span>
                </h3>
              </template>
              <template v-slot:body>
                <b-container class fluid>
                  <div class="poschodoch-dashboard-title">
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.ROOMS") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ PocetIzieb }}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.CUT") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ Podiel }}
                      </div>
                    </div>
                  </div>
                </b-container>
              </template>
            </KTPortlet>
          </b-card>

          <!--Begin::Plochy-->
          <b-card
            class="poschodoch-card"
            v-if="plocha1Popis || plocha2Popis || plocha3Popis || plocha4Popis"
          >
            <KTPortlet style="display: inline">
              <template v-slot:title>
                <h3 class="kt-portlet__head-title ">
                  <i class="flaticon-squares poschodoch-icon-large mr-3"></i>
                  <span class="poschodoch-dashboard-title">
                    {{ $i18n.t("USERLISTOFFICIALS.ODD4") }}
                  </span>
                </h3>
              </template>
              <template v-slot:body>
                <b-container class fluid>
                  <div class="poschodoch-dashboard-title">
                    <div class="row" v-if="plocha1Popis">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ plocha1Popis }}
                        </span>
                      </div>
                      <div class="row col-4 mt-3 justify-content-end">
                        {{ plocha1 }}&#178;
                      </div>
                    </div>
                    <div class="row" v-if="plocha2Popis">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ plocha2Popis }}
                        </span>
                      </div>
                      <div class="row col-4 mt-3 justify-content-end">
                        {{ plocha2 }}&#178;
                      </div>
                    </div>
                    <div class="row" v-if="plocha3Popis">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ plocha3Popis }}
                        </span>
                      </div>
                      <div class="row col-4 mt-3 justify-content-end">
                        {{ plocha3 }}&#178;
                      </div>
                    </div>
                    <div class="row" v-if="plocha4Popis">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ plocha4Popis }}
                        </span>
                      </div>
                      <div class="row col-4 mt-3 justify-content-end">
                        {{ plocha4 }}&#178;
                      </div>
                    </div>
                  </div>
                </b-container>
              </template>
            </KTPortlet>
          </b-card>

          <!--Begin::Dalsie udaje -->
          <b-card class="poschodoch-card">
            <KTPortlet style="display: inline">
              <template v-slot:title>
                <h3 class="kt-portlet__head-title ">
                  <i class="flaticon-user poschodoch-icon-large mr-3"></i>
                  <span class="poschodoch-dashboard-title">
                    {{ $i18n.t("USERLISTOFFICIALS.ODD2") }}
                  </span>
                </h3>
              </template>
              <template v-slot:body>
                <b-container class fluid>
                  <div class="poschodoch-dashboard-title">
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.VS") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ VS }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.SIPO") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ evcislosipo }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.PAYINFO") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ bankaccount }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 mt-3">
                        <span style="font-weight: 500">
                          {{ $i18n.t("USERLISTOFFICIALS.PERSONCOUNT") }}
                        </span>
                      </div>
                      <div class="col-6 mt-3">
                        {{ PocetOsob }}
                      </div>
                    </div>
                  </div>
                </b-container>
              </template>
            </KTPortlet>
          </b-card>
        </b-card-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet>
          <template v-slot:body>
            <b-container fluid v-if="description">
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import InvoicesDetailDocs from "@/views/pages/domus/InvoicesDetailDocs.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "domus-user-list-officials-detail",
  components: {
    KTPortlet,
    Loader,
    InvoicesDetailDocs,
  },
  data() {
    return {
      insurenceDetailData: null,
      menuId: null,
      title: null,
      items: null,
      description: null,
      invoicesDetailDocs: null,
      docCount: null,
      tableName: null,
      recKey: null,
      persons: null,
      bankaccount: null,
      Name: null,
      VS: null,
      Street: null,
      PSC: null,
      City: null,
      UnitNumber: null,
      ExtUnitNumber: null,
      evcislosipo: null,

      telefon: null,
      mobil: null,
      email: null,

      zas_nazov: null,
      zas_nazovx: null,
      zas_ulica: null,
      zas_mesto: null,
      zas_psc: null,
      plocha1: null,
      plocha1Popis: null,
      plocha2: null,
      plocha2Popis: null,
      plocha3: null,
      plocha3Popis: null,
      plocha4: null,
      plocha4Popis: null,
      Podiel: null,
      PocetIzieb: null,
      PocetOsob: null,
      fields: [
        {
          key: "Name",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.NAME"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "DeliveryPoint",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.DELIVERYPOINT"),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "DateFrom",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.DATEFROM"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DateTo",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.DATETO"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "ReadingCode",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.READINGCODE"),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "Value",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.VALUE"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return this.addCurrency(FormatService.formatNumberToLocal(value));
          },
        },
        {
          key: "Amount",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.AMOUNT"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.userId = this.$route.query.userId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return (
        data + " " + FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    back() {
      this.$router.push({
        name: "domus-user-list-officials",
        query: { menuId: this.menuId },
      });
    },
    showInvoicesDetailDocs() {
      let parameters =
        "?tableName=" +
        this.tableName +
        "&recKey=" +
        this.recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDetailDocs = data;
        this.$bvModal.show("modal-invoicesDetail-docs");
      });
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDetailDocs = data;

        parameters =
          "?docId=" + this.invoicesDetailDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.invoicesDetailDocs.Documents[0].FileName;

          FileService.downloadFile(
            fileName,
            binaryContent,
            null,
            "document-download-link",
          );
        });
      });
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      /*"&userId=" +
        this.userId.toString();*/

      if (this.userId) parameters += "&userId=" + this.userId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "UserListOfficialsDetail" + parameters).then(
        ({ data }) => {
          //this.transformData(data.InvoiceDetail);
          //this.invoicesDetailData = data;
          //this.items = data.InvoiceDetail;

          this.title = data.Title;
          this.description = data.Description;
          this.docCount = data.DocCount;
          this.tableName = data.TableName;
          this.recKey = data.RecKey;

          this.Name = data.Name;
          this.VS = data.VS;
          this.Street = data.Street;
          this.PSC = data.PSC;
          this.City = data.City;
          this.UnitNumber = data.UnitNumber;
          this.ExtUnitNumber = data.ExtUnitNumber;

          this.telefon = data.telefon == null ? "N/A" : data.telefon;
          this.mobil = data.mobil == null ? "N/A" : data.mobil;
          this.email = data.email == null ? "N/A" : data.email;
          this.evcislosipo = data.evcislosipo;

          this.zas_nazov = data.zas_nazov;
          this.zas_nazovx = data.zas_nazovx;
          this.zas_ulica = data.zas_ulica;
          this.zas_mesto = data.zas_mesto;
          this.zas_psc = data.zas_psc;
          this.persons = data.persons;
          this.bankaccount = data.BankAccount;

          this.Podiel = data.Podiel;
          this.PocetIzieb = data.PocetIzieb;
          this.PocetOsob = data.PocetOsob;

          this.plocha1 = FormatService.formatNumberToLocal(data.Plocha1) + " m";
          this.plocha1Popis = data.Plocha1Popis;
          this.plocha2 = FormatService.formatNumberToLocal(data.Plocha2) + " m";
          this.plocha2Popis = data.Plocha2Popis;
          this.plocha3 = FormatService.formatNumberToLocal(data.Plocha3) + " m";
          this.plocha3Popis = data.Plocha3Popis;
          this.plocha4 = FormatService.formatNumberToLocal(data.Plocha4) + " m";
          this.plocha4Popis = data.Plocha4Popis;

          /*this.vs = data.VS;
          this.customer = data.Customer;
          this.invoiceDescription = data.InvoiceDescription;
          this.amount = data.Amount;*/

          //this.currentPage = 1;
          //this.totalRows = this.items.length;
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Name: items[i].Name,
          DeliveryPoint: items[i].DeliveryPoint,
          DateFrom: items[i].DateFrom,
          DateTo: items[i].DateTo,
          ReadingCode: items[i].ReadingCode,
          Amount: items[i].Amount,
          Value: items[i].Value,
        };
      }
    },
  },
};
</script>
