import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import router from "@/router";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGIN_WITH_FACEBOOK = "loginWithFacebook";
export const LOGIN_WITH_GOOGLE = "loginWithGoogle";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const REGISTRATION_CODE = "registrationCode";
export const ADD_REGISTRATION_CODE = "addRegistrationCode";
export const ADD_UNIT = "addUnit";
export const UPDATE_USER = "updateUser";
export const CHANGE_UNIT = "changeUnit";
export const VALID_ACCOUNT = "validAccount";
export const CHECK_REGISTRATION_CODE = "checkRegistrationCOde";
export const EXTERNAL_LOGIN = "externalLogin";
export const NIC = "Nic";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const CLEAR_ERROR = "clearError";
export const STORE_AGREEMENT_INFO = "storeAgreementInfo";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken() && !!JwtService.getUnit(),
  stateAgreementInfo: null,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setLoginHeader();
      ApiService.post("Auth/login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);

          if (data.ChangeId != null) {
            window.location = "/resetPassword?id=" + data.ChangeId;
            //window.location.reload();
          } else {
            JwtService.saveUsername(data.username);

            // podmienky ana
            let params = "?Type=AnaTerms";
            ApiService.setHeader();
            ApiService.get("Account", "CheckProperties" + params)
              .then(({ data }) => {
                if (data.Value === 0) {
                  router.push({ name: "registrationAgreementNew" });
                } else {
                  // zoznam priestorov
                  ApiService.setHeader();
                  ApiService.get("Auth/UnitList")
                    .then(({ data }) => {
                      resolve(data);
                    })
                    .catch(({ response }) => {
                      if (response.data.error)
                        context.commit(SET_ERROR, [
                          response.data.error + " (UnitList)",
                        ]);
                      else
                        context.commit(SET_ERROR, [
                          this.$i18n.t("AUTHJS.SOMETHINGWRONG") +
                            //"Niečo sa pokazilo. Pracujeme na tom..." +
                            " (UnitList)",
                        ]);
                      reject(response);
                    });
                }
              })
              .catch(({ response }) => {
                if (response.data.error)
                  context.commit(SET_ERROR, [
                    response.data.error + " (UnitList)",
                  ]);
                else
                  context.commit(SET_ERROR, [
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (UnitList)",
                  ]);
                reject(response);
              });
          }
        })
        .catch(({ response }) => {
          if (response.data.error)
            context.commit(SET_ERROR, [response.data.error + " (Login)"]);
          else
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (Login)",
            ]);
          reject(response);
        });
    });
  },
  [EXTERNAL_LOGIN](context, token) {
    return new Promise((resolve, reject) => {
      JwtService.destroyToken();
      context.commit(CLEAR_ERROR);
      context.commit(PURGE_AUTH);
      ApiService.setHeaderExternal(token);
      ApiService.post("Auth/Externallogin")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);

          if (data.ChangeId != null) {
            window.location = "/resetPassword?id=" + data.ChangeId;
            //window.location.reload();
          } else {
            JwtService.saveUsername(data.username);
            JwtService.saveUnit(data.unitId, data.unitType, data.portalId);
            // podmienky ana
            let params = "?Type=AnaTerms";
            ApiService.setHeader();
            ApiService.get("Account", "CheckProperties" + params)
              .then(({ data }) => {
                if (data.Value === 0) {
                  router.push({ name: "registrationAgreementNew" });
                } else {
                  ApiService.setHeader();
                  ApiService.post(
                    "Auth/changeunit?portalId=" + JwtService.getPortalId(),
                  )
                    .then(({ data }) => {
                      //if (data.IsActive == 1) {
                      context.commit(SET_AUTH, data);
                      //JwtService.saveUnit(obj.unitID, obj.unitType, obj.portalID);
                      resolve(data);
                    })
                    .catch(({ response }) => {
                      JwtService.destroyToken();
                      context.commit(CLEAR_ERROR);
                      context.commit(PURGE_AUTH);
                      if (response.data.error == "350") {
                        context.commit(SET_ERROR, [
                          "Uzivatel nema pristup" + " (Changeunit)",
                        ]);
                      } else {
                        context.commit(SET_ERROR, [
                          //"Databáza správcu je z technických príčin nedostupná." +
                          this.$i18n.t("AUTHJS.DB_NOT_AV") + " (Changeunit)",
                        ]);
                      }
                      reject(response);
                    });
                }
              })
              .catch(({ response }) => {
                JwtService.destroyToken();
                context.commit(CLEAR_ERROR);
                context.commit(PURGE_AUTH);
                if (response.data.error)
                  context.commit(SET_ERROR, [
                    response.data.error + " (UnitList)",
                  ]);
                else
                  context.commit(SET_ERROR, [
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (UnitList)",
                  ]);
                reject(response);
              });
          }
        })
        .catch(({ response }) => {
          JwtService.destroyToken();
          context.commit(CLEAR_ERROR);
          context.commit(PURGE_AUTH);
          if (response.data.error)
            context.commit(SET_ERROR, [response.data.error + " (Login)"]);
          else
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (Login)",
            ]);
          reject(response);
        });
    });
  },
  [LOGIN_WITH_FACEBOOK](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setLoginHeader();
      ApiService.post("Auth/LoginWithFacebookToken", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          JwtService.saveUsername(data.username);

          // TODO: Call AddRegistrationCode API with reg. code from credentials
          // call must go sync with next get // same with google

          // zoznam priestorov
          ApiService.setHeader();
          ApiService.get("Auth/UnitList")
            .then(({ data }) => {
              resolve(data);
            })
            .catch(({ response }) => {
              if (response.data.error)
                context.commit(SET_ERROR, [
                  response.data.error + " (UnitList)",
                ]);
              else
                context.commit(SET_ERROR, [
                  this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (UnitList)",
                ]);
              reject(response);
            });
        })
        .catch(({ response }) => {
          if (response.data.error)
            context.commit(SET_ERROR, [
              response.data.error + " (LoginWithFacebookToken)",
            ]);
          else
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.SOMETHINGWRONG") +
                " (LoginWithFacebookToken)",
            ]);
          reject(response);
        });
    });
  },
  [LOGIN_WITH_GOOGLE](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setLoginHeader();
      ApiService.post("Auth/LoginWithGoogleToken", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          JwtService.saveUsername(data.username);

          // zoznam priestorov
          ApiService.setHeader();
          ApiService.get("Auth/UnitList")
            .then(({ data }) => {
              resolve(data);
            })
            .catch(({ response }) => {
              if (response.data.error)
                context.commit(SET_ERROR, [
                  response.data.error + " (UnitList)",
                ]);
              else
                context.commit(SET_ERROR, [
                  this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (UnitList)",
                ]);
              reject(response);
            });
        })
        .catch(({ response }) => {
          console.log("* WebAPI/Auth/LoginWithGoogleToken *", response);
          if (response.data.error)
            context.commit(SET_ERROR, [
              response.data.error + " (LoginWithGoogleToken)",
            ]);
          else
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (LoginWithGoogleToken)",
            ]);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("Registration/CreateAccount", credentials)
        .then(({ data }) => {
          if (data.warning) {
            let warning = data.warning;
            let params =
              "?registrationCode=" + credentials.RegistrationCode.toString();

            ApiService.setHeader();
            ApiService.get("Registration", "CheckRegistrationCode" + params)
              .then(({ data }) => {
                warning = warning.replaceAll("[VS]", data.VS);
                warning = warning.replaceAll("[Address]", data.Address);

                window.alert(warning);
              })
              .catch(({ response }) => {
                if (response.data.error)
                  context.commit(SET_ERROR, [
                    response.data.error + " (CheckRegistrationCode)",
                  ]);
                else
                  context.commit(SET_ERROR, [
                    this.$i18n.t("AUTHJS.WRONGREGCODE") +
                      " (CheckRegistrationCode)",
                  ]);
                reject(response);
              });
          }

          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data.error)
            context.commit(SET_ERROR, [
              response.data.error + " (CreateAccount)",
            ]);
          else
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (CreateAccount)",
            ]);
          reject(response);
        });
    });
  },
  [REGISTRATION_CODE](context, credentials) {
    return new Promise((resolve, reject) => {
      let parameters =
        "?registrationCode=" + credentials.RegistrationCode.toString();

      ApiService.setHeader();
      ApiService.getWithError(
        "Registration",
        "CheckRegistrationCode" + parameters,
      )
        //ApiService.get("Registration", "CheckRegistrationCode" + parameters)
        .then(({ data }) => {
          console.log("CheckRegistrationCode");
          if (data.PriceVisibility == 2) {
            //"Prístup nepovolený! Kontaktujte, prosím, Vašho správcu Spokojné bývanie"
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.NOTALLOWED"),
              //"Prístup nepovolený! Kontaktujte, prosím, Vašho správcu Spokojné bývanie",
            ]);
            //router.push({ name: "login" });
          } else {
            data = Object.assign(
              {},
              { RegistrationCode: credentials.RegistrationCode },
              data,
            );
            context.commit(STORE_AGREEMENT_INFO, data);
            if (credentials.HaveAccount)
              router.push({
                name: "registrationAgreement",
                params: { haveAccount: true },
              });
            else router.push({ name: "registrationAgreement" });
          }
        })
        .catch(({ response }) => {
          if (response.data.error)
            context.commit(SET_ERROR, [
              response.data.error + " (CheckRegistrationCode)",
            ]);
          else
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.WRONGREGCODE") + " (CheckRegistrationCode)",
            ]);
          reject(response);
        });
    });
  },
  [ADD_REGISTRATION_CODE](context, credentials) {
    return new Promise((resolve, reject) => {
      let parameters = {
        registrationCode: credentials.RegistrationCode,
        email: credentials.Email,
      };

      ApiService.setHeader();
      ApiService.post("Registration/AddRegistrationCode", parameters)
        .then(({ data }) => {
          if (data.warning) {
            let warning = data.warning;
            let params =
              "?registrationCode=" + credentials.RegistrationCode.toString();

            ApiService.setHeader();
            ApiService.get("Registration", "CheckRegistrationCode" + params)
              .then(({ data }) => {
                warning = warning.replaceAll("[VS]", data.VS);
                warning = warning.replaceAll("[Address]", data.Address);

                window.alert(warning);
              })
              .catch(({ response }) => {
                if (response.data.error)
                  context.commit(SET_ERROR, [
                    response.data.error + " (CheckRegistrationCode)",
                  ]);
                else
                  context.commit(SET_ERROR, [
                    this.$i18n.t("AUTHJS.WRONGREGCODE") +
                      " (CheckRegistrationCode)",
                  ]);
                reject(response);
              });
          }
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data.error)
            context.commit(SET_ERROR, [
              response.data.error + " (AddRegistrationCode)",
            ]);
          else
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.WRONGREGCODE") + " (AddRegistrationCode)",
            ]);
          reject(response);
        });
    });
  },
  [CHECK_REGISTRATION_CODE](context, code) {
    return new Promise((resolve, reject) => {
      /*let parameters = {
        registrationCode: code,        
      };*/
      let parameters = "?registrationCode=" + code.toString();
      ApiService.setHeader();
      ApiService.get("Registration/CodeUserUnit", parameters)
        .then(({ data }) => {
          let ret = {
            UsersetCode: data.Usersetcode,
            UnitId: data.UnitID,
            UnitType: data.UnitType,
            PortalId: data.PortalId,
          };
          resolve(ret);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [ADD_UNIT](context, credentials) {
    return new Promise((resolve, reject) => {
      let parameters = {
        registrationCode: credentials.RegistrationCode,
      };

      ApiService.setHeader();
      ApiService.post("Account/AddUnit", parameters)
        .then(({ data }) => {
          if (data.warning) {
            let warning = data.warning;
            let params =
              "?registrationCode=" + credentials.RegistrationCode.toString();

            ApiService.setHeader();
            ApiService.get("Registration", "CheckRegistrationCode" + params)
              .then(({ data }) => {
                warning = warning.replaceAll("[VS]", data.VS);
                warning = warning.replaceAll("[Address]", data.Address);

                window.alert(warning);
              })
              .catch(({ response }) => {
                if (response.data.error)
                  context.commit(SET_ERROR, [
                    response.data.error + " (CheckRegistrationCode)",
                  ]);
                else
                  context.commit(SET_ERROR, [
                    this.$i18n.t("AUTHJS.WRONGREGCODE") +
                      " (CheckRegistrationCode)",
                  ]);
                reject(response);
              });
          }
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data.error)
            context.commit(SET_ERROR, [response.data.error + " (AddUnit)"]);
          else
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.WRONGREGCODE") + " (AddUnit)",
            ]);
          reject(response);
        });
    });
  },
  [VALID_ACCOUNT](context, credentials) {
    return new Promise((resolve, reject) => {
      let parameters = "?email=" + credentials.Email.toString();
      if (credentials.FacebookID)
        parameters += "&facebookID=" + credentials.FacebookID.toString();
      if (credentials.GoogleID)
        parameters += "&googleID=" + credentials.GoogleID.toString();

      ApiService.setHeader();
      ApiService.getWithError("Registration", "ValidAccount" + parameters)
        .then((data) => {
          console.log("ValidAccount");
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data.error)
            context.commit(SET_ERROR, [response.data.error]);
          else
            context.commit(SET_ERROR, [
              this.$i18n.t("AUTHJS.ACCNOTVALID") + " (ValidAccount)",
            ]);
          reject(response);
        });
    });
  },
  async [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      if (JwtService.needRefresh()) {
        await ApiService.post("Auth/refresh", {
          AuthToken: JwtService.getToken(),
          RefreshToken: JwtService.getRefreshToken(),
        })
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
          })
          .catch(({ response }) => {
            JwtService.destroyToken();
            if (response.data.error) {
              if (!response.data.error.includes("Exception IDX10609:")) {
                context.commit(SET_ERROR, [response.data.error + " (Refresh)"]);
                router.push({ name: "login" });
              } else {
                context.commit(CLEAR_ERROR);
                context.commit(PURGE_AUTH);
                window.location.reload();
              }
            } else {
              context.commit(SET_ERROR, [
                this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (Refresh)",
              ]);
              router.push({ name: "login" });
            }
          });
      }
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [NIC](context) {
    context.commit(CLEAR_ERROR);
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  [CHANGE_UNIT](context, obj) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("Auth/changeunit?portalId=" + obj.portalID)
        .then(({ data }) => {
          //if (data.IsActive == 1) {
          context.commit(SET_AUTH, data);
          JwtService.saveUnit(obj.unitID, obj.unitType, obj.portalID);
          resolve(data);
          /*}
          else {
            context.commit(SET_ERROR, [
              "Databáza správcu je z technických príčin nedostupná." + " (Changeunit)",
            ]);
            reject();
          }*/
        })
        .catch(({ response }) => {
          if (response.data.error == "350") {
            context.commit(SET_ERROR, [
              "Uzivatel nema pristup" + " (Changeunit)",
            ]);
          } else {
            context.commit(SET_ERROR, [
              //"Databáza správcu je z technických príčin nedostupná." +
              this.$i18n.t("AUTHJS.DB_NOT_AV") + " (Changeunit)",
            ]);
          }
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [CLEAR_ERROR](state) {
    state.errors = {};
    state.apiErrors = [];
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(
      state.user.auth_token,
      state.user.expires_in,
      state.user.refresh_token,
    );
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [STORE_AGREEMENT_INFO](state, payload) {
    state.stateAgreementInfo = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
