<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <b-modal
      id="modal-eventsDetail-docs-dwp"
      hide-footer
      size="lg"
      v-show="eventsDetailDocsDwp"
      class="poschodoch-modal-eventsDetail-docs-dwp"
    >
      <template v-slot:modal-title v-if="eventsDetailDocsDwp">
        <span v-if="eventsDetailDocsDwp.Title" class="poschodoch-modal-title">
          {{ eventsDetailDocsDwp.Title }}
        </span>
      </template>

      <div class="d-block text-center">
        <b-table
          v-if="eventsDetailDocsDwp && eventsDetailDocsDwp.Documents"
          show-empty
          hover
          :items="eventsDetailDocsDwp.Documents"
          :fields="fields"
          :empty-text="$t('COMMON.LIST.NO_RECORDS')"
        >
          <template v-slot:cell(DocId)="data">
            <a
              href="javascript:void(0)"
              v-on:click="downloadFile(data.item.DocId, data.item.FileName)"
              class="poschodoch-icon-link p-2"
            >
              <i class="flaticon-download poschodoch-icon-medium"></i>
            </a>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import FileService from "@/common/file.service";
import FormatService from "@/common/format.service";
import JwtService from "@/common/jwt.service";

export default {
  name: "eventsDetail-docs-dwp",
  props: {
    eventsDetailDocsDwp: {},
  },
  data() {
    return {
      fields: [
        {
          key: "DocName",
          label: this.$i18n.t("DOCUMENTS.TABLE.DOC_NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "CreationDate",
          label: this.$i18n.t("DOCUMENTS.TABLE.CREATION_DATE"),
          sortable: true,
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateTimeToLocal(value);
          },
        },
        {
          key: "Category",
          label: this.$i18n.t("DOCUMENTS.TABLE.CATEGORY"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "DocId",
          label: "",
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    downloadFile(docId, fileName) {
      let parameters = "?docId=" + docId.toString() + "&type=t_udalost";

      ApiService.setHeader();
      ApiService.get("Document", "GetDWP" + parameters).then(({ data }) => {
        let binaryContent = FileService.convertBase64ToByteArray(
          data.FileContent,
        );

        FileService.downloadFile(
          fileName,
          binaryContent,
          null,
          "document-download-link",
        );
      });
    },
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
  },
};
</script>
