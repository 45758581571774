<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <KTPortlet>
        <template v-slot:body>
          <b-container fluid>
            <div v-html="htmlContent" class="p-4"></div>
          </b-container>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
//import Loader from "@/views/components/Loader.vue";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import UIService from "@/common/ui.service";

export default {
  name: "gdprTerms",
  components: {
    KTPortlet,
    //Loader,
  },
  data() {
    return {
      portalId: null,
      customerName: null,
      htmlContent: null,
    };
  },
  mounted() {
    this.portalId = 3;
    this.fetchData();
    UIService.changeSiteColorCustomer();
  },
  methods: {
    fetchData() {
      let parameters = "?Type=G";

      ApiService.setHeader();
      ApiService.get("Registration", "Terms" + parameters)
        .then(({ data }) => {
          //this.customerName = data.CustomerName;
          this.htmlContent = data.Content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
