<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Notifications list-->
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">
                  {{ title }}
                </slot>
              </span>
            </h3>
          </template>
          <!--Begin::Data -->
          <!-- :tbody-tr-class="read == 1 ? 'table-warning' : 'table-success'" -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Subject)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="postReadNotif(data.item.NotifId)"
                    class="kt-link"
                  ></a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.item != null"
                            v-on:click="
                              postReadNotif(menuId, data.item.NotifId)
                            "
                            class="kt-link"
                          >
                            {{ data.item.Description }}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-row align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span class="ml-2">
                          {{
                            getFormatService().formatDateToLocal(
                              data.item.CreationDate,
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Notifications list-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import MenuService from "@/common/menu.service";

export default {
  name: "domus-notifications",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "Description",
          label: this.$i18n.t("NOTIFICATIONS.DESCRIPTION"),
          sortable: false,

          class: "text-left table-no-mobile",
        },
        {
          key: "CreationDate",
          label: this.$i18n.t("NOTIFICATIONS.CREATION_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateTimeToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "CreationDate",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(item) {
      //item.Read = item.Read==1?0:1;

      if (item.Read == 0) {
        item.Read = 1;
      }
      let postContent = {
        notificationId: item.NotifId,
        OtherNotif: item.OtherNotif,
        OtherUsers: item.OtherUsers,
      };

      ApiService.setHeader();
      ApiService.post("Dashboard/NotificationRead", postContent)
        .then(() => {})
        .catch((error) => {
          if (error.response.data.error) {
            this.errors.push(error.response.data.error);
          } else {
            this.errors.push(this.$i18n.t("AUTHJS.SOMETHINGWRONG") + error);
          }
        });

      //this.showRequestDetail(record.RequestId, record.RequestState);

      let kod = item.Code;
      if (
        kod == "DOC_OBJ" &&
        !window.location.href.includes("/documentListObject")
      ) {
        let parameters = MenuService.getMenuID("DocumentListObject");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-documents-object",
            query: { menuId: parameters },
          });
        }
      } else if (
        kod == "SYS_EVE" &&
        !window.location.href.includes("/events")
      ) {
        let parameters = MenuService.getMenuID("Events");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-events",
            query: { menuId: parameters },
          });
        }
      } else if (
        (kod == "DOC_ZAS" || kod == "DOC_UZI") &&
        !window.location.href.includes("/documentListUser")
      ) {
        let parameters = MenuService.getMenuID("DocumentListUser");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-documents-user",
            query: { menuId: parameters },
          });
        }
      } else if (
        (kod == "MER_ST" ||
          kod == "MER_NS" ||
          kod == "MER_NS_PER" ||
          kod == "MER_0S" ||
          kod == "MER_NOSIG") &&
        !window.location.href.includes("/dailyConsumption")
      ) {
        let parameters = MenuService.getMenuID("DailyConsumption");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-daily-consumption",
            query: { menuId: parameters },
          });
        }
      } else if (
        kod == "PLA_NED" &&
        !window.location.href.includes("/account")
      ) {
        let parameters = MenuService.getMenuID("account");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-account",
            query: { menuId: parameters },
          });
        }
      }
    },
    /*pointerRowClass(item){
      return item.read == 1 ? 'table-warning' : 'table-success'
    },*/
    pointerRowClass(item) {
      // use item parameter if condition is needed
      //return "pointer-row";
      if (item.Read == 1) {
        return { "font-weight-bold": false };
        //return 'table-warning'
      } else {
        return { "font-weight-bold": true };
        //return 'table-success'
      }
    },
    fetchData() {
      this.items = null;
      /*let parameters =
        "?menuId=" + this.menuId.toString() + "&ObjectRequests=1";*/
      let parameters = "?type=1";
      ApiService.setHeader();
      ApiService.get("Dashboard", "GetNotifications" + parameters).then(
        ({ data }) => {
          this.transformData(data.Notifications);

          this.title = this.$i18n.t("NOTIFICATIONS.NOTIF");
          this.description = this.$i18n.t("NOTIFICATIONS.NOTE");
          this.items = data.Notifications;
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Code: items[i].Code,
          Description: items[i].Description,
          NotifId: items[i].NotifId,
          Read: items[i].Read,
          CreationDate: FormatService.formatDateToLocal(items[i].CreationDate),
        };
      }
    },
  },
};
</script>
