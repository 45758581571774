<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <InvoicesDetailDocs v-bind:invoicesDetailDocs="invoicesDetailDocs" />

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:toolbar>
            <b-button variant="secondary" v-on:click="back()">
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-container fluid>
              <div class="row">
                <div class="col-lg-6 col-sm-12 ">
                  <div class="row mt-3">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("INSURANCEEVENTS.STAV") }}
                    </span>
                    {{ State }}
                  </div>
                  <div class="row mt-3">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("INSURANCEEVENTS.DATECREATION") }}
                    </span>
                    {{ getFormatService().formatDateToLocal(DateCreation) }}
                  </div>
                  <div class="row mt-3">
                    <span style="width: 20rem; font-weight: 500">
                      {{ $i18n.t("INSURANCEEVENTS.OBJECT") }}
                    </span>
                    {{ ObjectVCS }}
                  </div>
                </div>
                <div class="mt-3"></div>
                <div class="col-lg-6 col-sm-12 ">
                  <div class="row mt-3">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("INSURANCEEVENTS.RECIEVER") }}
                    </span>
                    {{ Recipient }}
                  </div>
                  <div class="row mt-3">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("INSURANCEEVENTS.SUMDAMAGE") }}
                    </span>
                    {{
                      addCurrency(
                        getFormatService().formatNumberToLocal(AmoutDamage),
                      )
                    }}
                  </div>
                  <div class="row mt-3">
                    <span style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("INSURANCEEVENTS.SUM") }}
                    </span>
                    {{
                      addCurrency(
                        getFormatService().formatNumberToLocal(Amount),
                      )
                    }}
                  </div>
                </div>
                <div
                  class="col-lg-12 col-sm-12 mt-3"
                  style="padding-left: 0px !important"
                >
                  <b-form-group label="" label-for="input-text">
                    <span name="label" style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("INSURANCEEVENTS.CAUSE") }}
                    </span>
                    <b-form-textarea
                      id="input-text-c"
                      v-model="Cause"
                      type="text"
                      rows="10"
                      readonly
                      required
                      class="mt-2"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="" label-for="input-text">
                    <span name="label" style="width: 10rem; font-weight: 500">
                      {{ $i18n.t("INSURANCEEVENTS.DAMAGE") }}
                    </span>
                    <b-form-textarea
                      id="input-text-r"
                      v-model="Range"
                      type="text"
                      rows="10"
                      readonly
                      required
                      class="mt-2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <!--<div class="row mt-3">
                      <span style="width: 20rem; font-weight: 500">
                        {{ $i18n.t("INSURANCEEVENTS.CAUSE") }}
                      </span>
                      {{ Cause }}
                    </div>
                    <div class="row mt-3">
                      <span style="width: 20rem; font-weight: 500">
                        {{ $i18n.t("INSURANCEEVENTS.DAMAGE") }}
                      </span>
                      {{ Range }}
                    </div>-->

                <div class="col-lg-6 col-sm-12 ">
                  <div class="row mt-3" v-if="docCount > 0">
                    <span style="width: 10rem; font-weight: 500" class="mt-3">
                      {{ $i18n.t("INSURANCEEVENTS.DOCUMENTS") }}
                    </span>
                    <a
                      href="javascript:void(0)"
                      v-if="docCount > 0"
                      v-on:click="showInvoicesDetailDocs()"
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                    </a>
                    <!-- <a
                        href="javascript:void(0)"
                        v-if="docCount == 1"
                        v-on:click="showSingleDoc(recKey, tableName)"
                        class="poschodoch-icon-link p-2"
                      >
                        <i class="flaticon-download poschodoch-icon-medium"></i>
                      </a>-->
                  </div>
                </div>
                <div class="mt-3"></div>
              </div>

              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!title">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import InvoicesDetailDocs from "@/views/pages/domus/InvoicesDetailDocs.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "domus-insurence-events-detail",
  components: {
    KTPortlet,
    Loader,
    InvoicesDetailDocs,
  },
  data() {
    return {
      insurenceDetailData: null,
      menuId: null,
      title: null,
      items: null,
      description: null,
      invoicesDetailDocs: null,
      docCount: null,
      tableName: null,
      recKey: null,
      State: null,
      Number: null,
      DateCreation: null,
      ObjectVCS: null,
      Cause: null,
      Range: null,
      Recipient: null,
      AmoutDamage: null,
      Amount: null,

      fields: [
        {
          key: "Name",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.NAME"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "DeliveryPoint",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.DELIVERYPOINT"),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "DateFrom",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.DATEFROM"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DateTo",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.DATETO"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "ReadingCode",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.READINGCODE"),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "Value",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.VALUE"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return this.addCurrency(FormatService.formatNumberToLocal(value));
          },
        },
        {
          key: "Amount",
          label: this.$i18n.t("INVOICE_DETAIL.TABLE.AMOUNT"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.eventId = this.$route.query.eventId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return (
        data + " " + FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    back() {
      this.$router.push({
        name: "domus-insurence-events",
        query: { menuId: this.menuId },
      });
    },
    showInvoicesDetailDocs() {
      let parameters =
        "?tableName=" +
        this.tableName +
        "&recKey=" +
        this.recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDetailDocs = data;
        this.$bvModal.show("modal-invoicesDetail-docs");
      });
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDetailDocs = data;

        parameters =
          "?docId=" + this.invoicesDetailDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.invoicesDetailDocs.Documents[0].FileName;

          FileService.downloadFile(
            fileName,
            binaryContent,
            null,
            "document-download-link",
          );
        });
      });
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?menuId=" +
        this.menuId.toString() +
        "&eventId=" +
        this.eventId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "InsurenceEventsDetail" + parameters).then(
        ({ data }) => {
          //this.transformData(data.InvoiceDetail);
          //this.invoicesDetailData = data;
          //this.items = data.InvoiceDetail;

          this.title = data.Title;
          this.description = data.Description;
          this.docCount = data.DocCount;
          this.tableName = data.TableName;
          this.recKey = data.RecKey;

          this.State = data.State;
          this.Number = data.Number;
          this.DateCreation = data.DateCreation;
          this.ObjectVCS = data.Object;
          this.Cause = data.Cause;
          this.Range = data.Range;
          this.Recipient = data.Recipient;
          this.AmoutDamage = data.AmoutDamage;
          this.Amount = data.Amount;
          /*this.vs = data.VS;
          this.customer = data.Customer;
          this.invoiceDescription = data.InvoiceDescription;
          this.amount = data.Amount;*/

          //this.currentPage = 1;
          //this.totalRows = this.items.length;
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Name: items[i].Name,
          DeliveryPoint: items[i].DeliveryPoint,
          DateFrom: items[i].DateFrom,
          DateTo: items[i].DateTo,
          ReadingCode: items[i].ReadingCode,
          Amount: items[i].Amount,
          Value: items[i].Value,
        };
      }
    },
  },
};
</script>
