<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/plugins/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/

// Demo skins (demo1 only)
@import "assets/sass/global/integration/frameworks/vue/skins";
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/store/config.module";
import { getPWAManifestFileURL } from "@/common/setUpPWAApp";

export default {
  name: "MetronicVue",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/common/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  created() {
    if (
      window.location.host.includes("poschodech") &&
      !window.location.href.includes("Print")
    ) {
      document.title = "Poschodech";
      linkManifest = "app/poschodoch_cz_manifest.json";
    }

    if (
      window.location.host.includes("mujbyt") &&
      !window.location.href.includes("Print")
    ) {
      document.title = "Mujbyt.enbra.cz";
    }

    if (
      window.location.host.includes("mojbyt") &&
      !window.location.href.includes("Print")
    ) {
      document.title = "Mojbyt.enbra.sk ";
    }

    if (
      window.location.host.includes("penta") &&
      !window.location.href.includes("Print")
    ) {
      document.title = "Penta";
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = "https://penta-poschodoch.ana.sk/favicon-penta.ico";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    // var full = window.location.host; // subdomain.domain.com
    // var parts = full.split('.');
    // var sub = parts[0];
    var linkManifest = getPWAManifestFileURL();

    if (linkManifest != "") {
      link = document.createElement("link");
      link.href = linkManifest;
      link.rel = "manifest";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    //      const favicon = document.getElementById("/favicon");
    //      favicon.href = "favicon-enbra.ico";
  },
};
</script>
