//const menuMappings = [];
const menu = "menu";

import ApiService from "@/common/api.service";

export const getMenuID = (menuCode) => {
  if (getCode("summary") != -103) {
    setMenuStart();
  }
  return getCode(menuCode);
};

const getCode = (menuCode) => {
  let menuMappings = window.localStorage.getItem(menu);
  let ret = 0;
  if (menuMappings) {
    let menuMappingsJ = JSON.parse(menuMappings);
    for (let i = 0; i < menuMappingsJ.length; i++) {
      if (menuMappingsJ[i].MenuCode == menuCode) {
        ret = menuMappingsJ[i].MenuId;
      }
    }
  }
  return ret;
};

export const getMenuCodeFromID = (menuId) => {
  let menuMappings = window.localStorage.getItem(menu);
  let ret = 0;
  if (menuMappings) {
    let menuMappingsJ = JSON.parse(menuMappings);
    for (let i = 0; i < menuMappingsJ.length; i++) {
      if (menuMappingsJ[i].MenuId == menuId) {
        ret = menuMappingsJ[i].MenuCode;
      }
    }
  }
  return ret;
};

export const setMenuStart = () => {
  let parameters = "?preview=0";
  ApiService.setHeader();
  ApiService.get("Dashboard", "Menu" + parameters).then(({ data }) => {
    setMenu(data);
  });
};

const setMenu = (Menu) => {
  let a = JSON.stringify(Menu);
  window.localStorage.setItem(menu, a);
};

export default {
  getMenuID,
  getMenuCodeFromID,
  setMenuStart,
};
