<template>
  <div class="poschodoch-voting">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <KTPortlet v-if="showPre">
          <template v-slot:body>
            <!-- errors -->
            <div
              role="alert"
              v-if="errors && errors.length"
              v-bind:class="{ show: errors && errors.length }"
              class="alert fade alert-danger mt-2 mb-5"
              style="flex-direction: column"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>
            <!-- errors -->
            <div class="d-flex mb-4 justify-content-center align-items-center">
              <span>{{ $i18n.t("VOTING.TXT") }}</span>
              <div class="mr-5">
                <b-form-input
                  v-model="votingCode"
                  class="ml-3 mr-3"
                ></b-form-input>
              </div>
            </div>
            <div class="row justify-content-center">
              <b-button
                @click="fetch()"
                id="fetch_button"
                variant="primary"
                style="width: 18rem; height: fit-content"
              >
                {{ $i18n.t("VOTING.BUTTON") }}
              </b-button>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showMain">
          <template v-slot:body>
            <div ref="voteHTML">
              <div
                class="d-flex flex-column mb-4 justify-content-center align-items-center"
              >
                <h3>{{ $i18n.t("VOTING.MAINTITLE") }}</h3>
                <span>{{ votingData.Title }}</span>
                <span class="d-flex flex-row" style="white-space: pre-wrap">
                  {{ $i18n.t("VOTING.SECONDTITLE") }}
                  <div style="font-weight: 500">
                    {{ votingData.SecondTitle }}
                  </div>
                </span>
              </div>
              <div
                class="d-flex flex-column mb-4 justify-content-start align-items-start"
              >
                <span class="d-flex flex-row" style="white-space: pre-wrap">
                  {{ $i18n.t("VOTING.BUILDING_MANAGER") }}
                  <div style="font-weight: 500">{{ votingData.Company }}</div>
                </span>
                <span class="d-flex flex-row" style="white-space: pre-wrap">
                  {{ $i18n.t("VOTING.OWNER") }}
                  <div style="font-weight: 500">{{ votingData.Name }}</div>
                </span>
                <span class="d-flex flex-row" style="white-space: pre-wrap">
                  {{ $i18n.t("VOTING.EMAIL_TXT") }}
                  <div style="font-weight: 500">{{ votingData.Email }}</div>
                </span>
                <span class="d-flex flex-row" style="white-space: pre-wrap">
                  {{ $i18n.t("VOTING.PHONE_TXT") }}
                  <div style="font-weight: 500">{{ votingData.Phone }}</div>
                </span>
              </div>
              <div
                class="d-flex flex-column mb-4 justify-content-start align-items-start"
              >
                <div
                  v-for="(question, inQ) in votingData.Questions"
                  :key="inQ"
                  class="mb-4"
                >
                  <div class="d-flex flex-column">
                    <span style="font-weight: 500">{{ question.Number }}</span>
                    <div v-html="question.Text"></div>
                  </div>
                  <div
                    style="color: #0066a1 !important"
                    class="mt-3"
                    v-if="question.Flat.length > 1"
                  >
                    <span style="font-weight: 500" class="mb-1">
                      {{ $i18n.t("VOTING.FILL_ALL") }}
                    </span>
                    <b-form-radio-group
                      v-model="allValues[inQ]"
                      class="d-flex flex-row"
                    >
                      <div
                        v-for="(answer, inA1) in votingData.Answers"
                        :key="inA1"
                      >
                        <div class="d-flex flex-row">
                          <b-form-radio
                            :value="answer.Value"
                            @change="radioAllValues(answer.Value, inQ)"
                          >
                            {{ answer.Text }}
                          </b-form-radio>
                        </div>
                      </div>
                    </b-form-radio-group>
                  </div>
                  <div
                    v-for="(flat, inF) in question.Flat"
                    :key="inF"
                    class="mt-3"
                  >
                    <span style="font-weight: 500" class="mb-1">
                      {{ flat.Text }}
                    </span>
                    <b-form-radio-group
                      v-model="flat.Value"
                      class="d-flex flex-row"
                    >
                      <div
                        v-for="(answer, inA2) in votingData.Answers"
                        :key="inA2"
                      >
                        <div class="d-flex flex-row">
                          <b-form-radio
                            :value="answer.Value"
                            @change="
                              allValues[inQ] = null;
                              saveHTML();
                            "
                          >
                            {{ answer.Text }}
                          </b-form-radio>
                        </div>
                      </div>
                    </b-form-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-row mb-4 justify-content-start align-items-center"
            >
              <b-form-checkbox
                id="checkConsent"
                v-model="checkConsent"
              ></b-form-checkbox>
              <span>{{ $i18n.t("VOTING.CONSENT") }}</span>
            </div>
            <div class="row justify-content-center">
              <b-button
                @click="sendPost()"
                id="send_button"
                :disabled="!checkConsent"
                variant="primary"
                style="width: 18rem; height: fit-content"
              >
                {{ $i18n.t("VOTING.BUTTON") }}
              </b-button>
            </div>
            <div ref="dateHTML">
              <div class="row justify-content-start no-gutters">
                <span>
                  {{ getFormatService().formatDateToDDMMYYYY(new Date()) }}
                </span>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-if="showEnd">
          <template v-slot:body>
            <div class="d-flex mb-4 justify-content-center align-items-center">
              <h3>{{ $i18n.t("VOTING.SENT_TXT") }}</h3>
            </div>
            <div class="row justify-content-center">
              <b-button
                @click="backToLogin()"
                variant="primary"
                style="width: 18rem; height: fit-content"
              >
                {{ $i18n.t("VOTING.BUTTON_BACK") }}
              </b-button>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import UIService from "@/common/ui.service";

export default {
  name: "voting",
  components: {
    KTPortlet,
  },
  data() {
    return {
      voteId: null,
      votingCode: null,
      votingData: null,
      showPre: true,
      showMain: false,
      showEnd: false,
      checkConsent: false,
      allValues: [],
      voteHtml: null,
      sendConfirm: false,
      sendClicked: false,
    };
  },
  mounted() {
    this.voteId = this.$route.query.id;
    this.apiErrors = [];
    if (this.$route.query.votingCode) {
      this.votingCode = this.$route.query.votingCode;
      this.fetch();
    }
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    radioAllValues(value, qNum) {
      for (let i = 0; i < this.votingData.Questions[qNum].Flat.length; i++) {
        this.votingData.Questions[qNum].Flat[i].Value = value;
      }
    },
    saveHTML() {
      if (this.votingData.Questions[0].Flat.length > 1) {
        this.voteHtml = this.$refs.voteHTML.innerHTML;
        this.voteHtml += this.$refs.dateHTML.innerHTML;
        for (let i = 0; i < this.votingData.Questions[0].Flat.length; i++) {
          this.votingData.Files[i].File = null;
          /*this.votingData.Files[i].File =
            this.$refs.voteHTML.innerHTML + this.$refs.dateHTML.innerHTML;*/
        }
      } else {
        this.voteHtml = this.$refs.voteHTML.innerHTML;
        this.voteHtml += this.$refs.dateHTML.innerHTML;
        this.votingData.Files[0].File = null;
        /*this.votingData.Files[0].File =
          this.$refs.voteHTML.innerHTML + this.$refs.dateHTML.innerHTML;*/
      }
    },
    fetch() {
      let params = "?votingid=" + this.voteId.toString();
      if (this.votingCode)
        params += "&votingcode=" + this.votingCode.toString();

      UIService.addButtonSpinner("fetch_button");
      ApiService.setHeader();
      ApiService.get("Voting", "FormGet" + params)
        .then(({ data }) => {
          this.votingData = data;
          for (let i = 0; i < data.Questions.length; i++) {
            this.allValues.push(null);
          }

          if (this.votingData.Lang != localStorage.getItem("language")) {
            localStorage.setItem("language", this.votingData.Lang);

            window.location =
              window.location.origin +
              "/hlas" +
              "?id=" +
              this.voteId.toString() +
              "&votingCode=" +
              this.votingCode.toString();
          }

          this.showPre = false;
          this.showMain = true;
          UIService.removeButtonSpinner("fetch_button");

          this.$nextTick(function() {
            this.saveHTML();
          });
        })
        .catch(() => {
          UIService.removeButtonSpinner("fetch_button");
        });
    },
    sendPost() {
      let postContent = this.votingData;
      UIService.addButtonSpinner("send_button");
      this.checkForMissingAnswers();

      if (this.sendConfirm) {
        /* check for missing answers */
        if (!this.sendClicked) {
          /* only process POST if the button hasn't been clicked yet (only clickable once per page-rendering) */
          this.sendClicked = true;
          ApiService.setHeader();
          ApiService.post("Voting/FormPost", postContent)
            .then(() => {
              this.showMain = false;
              this.showEnd = true;
              UIService.removeButtonSpinner("send_button");
            })
            .catch(() => {
              UIService.removeButtonSpinner("send_button");
            });
        }
      }
    },
    backToLogin() {
      this.$router.push({ name: "login" });
    },
    checkForMissingAnswers() {
      let check = false;

      for (let i = 0; i < this.votingData.Questions.length; i++) {
        for (let j = 0; j < this.votingData.Questions[i].Flat.length; j++) {
          if (!this.votingData.Questions[i].Flat[j].Value) check = true;
        }
      }

      if (check)
        this.sendConfirm = window.confirm(this.$i18n.t("VOTING.SEND_CONFIRM"));
      else this.sendConfirm = true;
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.api.apiErrors,
    }),
  },
};
</script>
