<template>
  <div>
    <MovementDetail v-bind:movementDetail="movementDetail" />

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Bilancia platieb a predpisov-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <b-button variant="secondary" @click="back">
              {{ $i18n.t("NEW_REQUEST.BACKBUTTON") }}
            </b-button>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row>
                <!--Begin::Filter -->
                <b-col sm="12" md="6" lg="6">
                  <ul
                    class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills"
                    role="tablist"
                  >
                    <li class="nav-item" v-for="index in years" :key="index">
                      <a
                        v-on:click="fetchData(index)"
                        data-tab="0"
                        class="nav-link"
                        href="javascript:void(0)"
                        v-bind:class="{ active: index == year }"
                      >
                        {{ index }}
                      </a>
                    </li>
                  </ul>
                </b-col>
                <!--End::Filter -->

                <!--Begin::Count of rows per page -->
                <b-col class="poschodoch-rows-count">
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                      @change="getFormatService().formatSetPerPage(perPage)"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!--End::Count of rows per page -->
              </b-row>

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                class="poschodoch-account-table"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(TypeNameOfMovement)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="
                      data.item.ItemsCount > 0 || data.item.AssignmentsCount > 0
                    "
                    class="kt-link font-weight-medium"
                    v-on:click="showMovementDetail(data.item.EvpId)"
                  >
                    {{ data.value }}
                  </a>
                  <span
                    class="font-weight-medium"
                    v-if="
                      !(
                        data.item.ItemsCount > 0 ||
                        data.item.AssignmentsCount > 0
                      )
                    "
                  >
                    {{ data.value }}
                  </span>
                </template>
                <template v-slot:cell(Amount)="data">
                  <span
                    class="font-weight-medium"
                    v-bind:class="{ 'table-danger': data.value < 0 }"
                  >
                    {{ getFormatService().formatNumberToLocal(data.value) }}
                  </span>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="
                      data.item.ItemsCount > 0 || data.item.AssignmentsCount > 0
                    "
                    v-on:click="showMovementDetail(data.item.EvpId)"
                    class="poschodoch-account-title-mobile"
                  >
                    {{
                      getFormatService().formatDateToLocal(data.value.DueDate) +
                        " " +
                        data.value.NameOfMovement
                    }}
                  </a>
                  <span
                    class="poschodoch-account-title-mobile"
                    v-if="
                      !(
                        data.item.ItemsCount > 0 ||
                        data.item.AssignmentsCount > 0
                      )
                    "
                  >
                    {{
                      getFormatService().formatDateToLocal(data.value.DueDate) +
                        " " +
                        data.value.NameOfMovement
                    }}
                  </span>
                  <div class="poschodoch-account-content-mobile mt-1">
                    <span
                      v-bind:class="{ 'table-danger': data.value.Balance < 0 }"
                    >
                      {{
                        $i18n.t("ACCOUNT.TABLE.BALANCE") +
                          " : " +
                          addCurrency(
                            getFormatService().formatNumberToLocal(
                              data.value.Balance,
                            ),
                          )
                      }}
                    </span>

                    <span
                      v-bind:class="{ 'table-danger': data.value.Amount < 0 }"
                    >
                      {{
                        addCurrency(
                          getFormatService().formatNumberToLocal(
                            data.value.Amount,
                          ),
                        )
                      }}
                    </span>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Pagination + FooterText -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col
                  sm="12"
                  md="6"
                  lg="6"
                  class="text-lg-left text-md-left text-center mb-3 mb-sm-3 mb-md-0 mb-lg-0"
                >
                  <p></p>
                  {{ footerText }}
                </b-col>
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination + FooterText -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Bilancia platieb a predpisov-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import MovementDetail from "@/views/pages/domus/MovementDetail.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "account",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    MovementDetail,
  },
  data() {
    return {
      title: null,
      description: null,
      footerText: null,
      menuId: null,
      accUnitId: null,
      lastMenuId: null,
      movementDetail: null,
      items: null,
      lastPeriod: "",
      periodClass: "row",
      fields: [
        {
          key: "Period",
          label: this.$i18n.t("ACCOUNT.TABLE.PERIOD"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value, key, item) => {
            item._rowVariant = item.RowClass;
            item._cellVariants = { Period: item.PeriodClass };
            return value;
          },
        },
        {
          key: "TypeNameOfMovement",
          label: this.$i18n.t("ACCOUNT.TABLE.NAME_OF_MOVEMENT"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DetailOfMovement",
          label: this.$i18n.t("ACCOUNT.TABLE.DETAIL_OF_MOVEMENT"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        // {
        //   key: "NameOfMovement",
        //   sortable: false,
        //   sortDirection: "desc",
        //   class: "table-no-mobile",
        // },
        {
          key: "DueDate",
          label: this.$i18n.t("ACCOUNT.TABLE.DUE_DATE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            item._rowVariant = item.RowClass;
            item._cellVariants = { DueDate: item.PeriodClass };
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(this.$i18n.t("ACCOUNT.TABLE.AMOUNT")),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "Balance",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ACCOUNT.TABLE.BALANCE"),
          ),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Balance < 0) {
              item._cellVariants = { Balance: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      year: null,
      years: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.accUnitId = this.$route.query.accUnitId;
    this.lastMenuId = this.$route.query.lastMenuId;
    //this.hideURLParams();
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      if (record.ItemsCount > 0 || record.AssignmentsCount > 0) {
        this.showMovementDetail(record.EvpId);
      }
    },
    pointerRowClass(item) {
      if (item.ItemsCount > 0 || item.AssignmentsCount > 0) {
        return "pointer-row";
      }
      return "";
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    back() {
      window.history.go(-1);
      return false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showMovementDetail(evpId) {
      let parameters = "?evpId=" + evpId.toString();
      if (this.accUnitId != null)
        parameters += "&accUnitId=" + this.accUnitId.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "MovementDetail" + parameters).then(({ data }) => {
        this.movementDetail = data;
        this.$bvModal.show("modal-movement-detail");
      });
    },
    setYearRange(yearFrom, yearTo) {
      if (this.years == null) {
        this.years = [];
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    getURLParameter(name) {
      return decodeURI(
        (RegExp(name + "=" + "(.+?)(&|$)").exec(location.search) || [null])[1],
      );
    },
    hideURLParams() {
      var hide = ["success", "error"];
      for (var h in hide) {
        if (this.getURLParameter(h)) {
          history.replaceState(
            null,
            document.getElementsByTagName("title")[0].innerHTML,
            window.location.pathname,
          );
        }
      }
    },
    transformData(items) {
      let prevPeriod = "";
      let rowClass = "row";
      let periodClass = "";
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          DueDate: items[i].DueDate,
          NameOfMovement: items[i].NameOfMovement,
          Balance: items[i].Balance,
          Amount: items[i].Amount,
        };
        let rowBorderClass = "row-top-bordered";
        // DueDate(Year)*100 + DueDate(Month) (to iste ako SUCET STRINGOV Year*10 + Month)
        let currPeriod =
          items[i].DueDate.substring(0, 4) * 10 +
          items[i].DueDate.substring(5, 7);
        if (prevPeriod == currPeriod) {
          periodClass = "dueDate-sub-item";
          //items[i].DueDate = "";
          items[i].Balance = "";
          items[i].Period = "";
          rowBorderClass = rowBorderClass + " row-middle";
        } else {
          prevPeriod = currPeriod;
          periodClass = "";
          if (rowClass == "row") rowClass = "row-alternate";
          else rowClass = "row";
        }

        items[i].PeriodClass = periodClass;
        items[i].RowClass = rowClass + " " + rowBorderClass;
      }
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.year != null) parameters += "&year=" + this.year.toString();
      if (this.accUnitId != null)
        parameters += "&accUnitId=" + this.accUnitId.toString();
      if (this.lastMenuId != null)
        parameters += "&lastMenuId=" + this.lastMenuId.toString();

      window.open("/accountPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("ACCOUNT.TABLE.NAME_OF_MOVEMENT"),
        this.$i18n.t("ACCOUNT.TABLE.DETAIL_OF_MOVEMENT"),
        this.$i18n.t("ACCOUNT.TABLE.DUE_DATE"),
        this.addCurrencyToColumn(this.$i18n.t("ACCOUNT.TABLE.AMOUNT")),
        this.addCurrencyToColumn(this.$i18n.t("ACCOUNT.TABLE.BALANCE")),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.TypeNameOfMovement,
          item.DetailOfMovement,
          FormatService.formatDateToLocal(item.DueDate),
          FormatService.formatNumberToLocalForCSV(item.Amount),
          FormatService.formatNumberToLocalForCSV(item.Balance),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + `_${this.year}.csv`;

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(selectedYear = null) {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();
      if (this.accUnitId != null)
        parameters += "&accUnitId=" + this.accUnitId.toString();
      if (this.lastMenuId != null)
        parameters += "&lastMenuId=" + this.lastMenuId.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "Account" + parameters).then(({ data }) => {
        this.transformData(data.Account);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Account;
        this.totalRows = this.items.length;
        this.year = data.Year;
        this.currentPage = 1;

        this.footerText = data.FooterText;
        this.setYearRange(data.YearFrom, data.YearTo);
      });
    },
  },
};
</script>
