<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Predpisy-->
        <KTPortlet v-bind:title="title" class="poschodoch-print">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Checkbox -->
              <span class="mb-2" v-if="checkedDisassembled === 'true'">
                {{ $i18n.t("METERREADINGS.CHECKBOXLABEL_POSITIVE") }}
              </span>
              <span class="mb-2" v-if="checkedDisassembled === 'false'">
                {{ $i18n.t("METERREADINGS.CHECKBOXLABEL_NEGATIVE") }}
              </span>
              <!--End::Checkbox -->

              <!--Begin::Main table element -->
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :refreshed="print()"
                class="poschodoch-meter-readings-table"
              >
                <template v-slot:cell(MeterNumber)="data">
                  {{ data.item.MeterType + " " + data.value }}
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Predpisy-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "meterReadings-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      checkedDisassembled: "true",
      items: null,
      fields: [
        {
          key: "MeterNumber",
          label: this.$i18n.t("METERREADINGS.TABLE.METERNUMBER"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "ClimbingIron",
          label: this.$i18n.t("METERREADINGS.TABLE.CLIMBINGIRON"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "AssemblyDate",
          label: this.$i18n.t("METERREADINGS.TABLE.ASSEMBLYDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DisassemblyDate",
          label: this.$i18n.t("METERREADINGS.TABLE.DISASSEMBLYDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (
              this.checkedDisassembled === "false" &&
              item.DisassemblyDate != null
            ) {
              item._rowVariant = "disassembledUncheck";
            }

            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "VerificationDate",
          label: this.$i18n.t("METERREADINGS.TABLE.VERIFICATIONDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Seals",
          label: this.$i18n.t("METERREADINGS.TABLE.SEALS"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "LastReadingDate",
          label: this.$i18n.t("METERREADINGS.TABLE.LASTREADINGDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.checkedDisassembled = this.$route.query.disCheck;
    this.fetchData();
  },
  methods: {
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      let tit = "";
      let docTitle = "";
      parameters += "&disassembled=1";

      ApiService.setHeader();
      ApiService.get("Flat", "MeterReadings" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.MeterReadings;
        tit = this.title;
      });

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
