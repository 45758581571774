<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Request list-->
        <KTPortlet class="poschodoch-print">
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">
                  {{ $i18n.t("REQUEST_LIST.TITLEUSER") }}
                </slot>
              </span>
            </h3>
          </template>

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :refreshed="print()"
              ></b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Request list-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";

export default {
  name: "requestListUser-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "requestNumber",
          label: this.$i18n.t("REQUEST_LIST.TABLE.REQUEST_ID"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Subject",
          label: this.$i18n.t("REQUEST_LIST.TABLE.SUBJECT"),
          sortable: false,
          sortDirection: "desc",
          class: "text-left table-no-mobile poschodoch-requestList-Subject",
        },
        {
          key: "RequestType",
          label: this.$i18n.t("REQUEST_LIST.TABLE.REQUEST_TYPE"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "RequestStateText",
          label: this.$i18n.t("REQUEST_LIST.TABLE.REQUEST_STATE"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "CreationDate",
          label: this.$i18n.t("REQUEST_LIST.TABLE.CREATION_DATE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DateOfChange",
          label: this.$i18n.t("REQUEST_LIST.TABLE.DATE_OF_CHANGE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    fetchData() {
      this.items = null;
      let tit = "";
      let docTitle = "";
      let parameters =
        "?menuId=" + this.menuId.toString() + "&ObjectRequests=0";

      ApiService.setHeader();
      ApiService.get("Request", "List" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Requests;

        tit = this.$i18n.t("REQUEST_LIST.TITLEUSER");
      });

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
