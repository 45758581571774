<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::DocumentListUser-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Filter and paging -->
              <b-row class="mb-4 align-items-end">
                <b-col>
                  <label>{{ $t("DOCUMENTS.FILTER.DOC_NAME") }}</label>
                  <b-form-input v-model="filterDocName"></b-form-input>
                </b-col>
                <b-col>
                  <label>{{ $t("DOCUMENTS.FILTER.CATEGORY") }}</label>
                  <b-form-select
                    v-model="filterCategory"
                    :options="categoryOptions"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <label>{{ $t("COMMON.LIST.ROW_COUNT_PER_PAGE") }}</label>
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    :options="pageOptions"
                    @change="getFormatService().formatSetPerPage(perPage)"
                  ></b-form-select>
                </b-col>
              </b-row>
              <!--End::Filter and paging -->

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-function="filterTable"
                class="poschodoch-documents-table notranslate"
              >
                <template v-slot:cell(DocName)="data">
                  <i
                    class="flaticon2-file-1 poschodoch-icon-large poschodoch-document-icon"
                  ></i>
                  <span class="ml-md-3 ml-sm-0">{{ data.item.DocName }}</span>
                </template>
                <template v-slot:cell(DocId)="data">
                  <a
                    href="javascript:void(0)"
                    v-on:click="
                      downloadFile(data.item.DocId, data.item.FileName)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ data.value.DocName }}
                          </span>
                        </span>
                        <span>
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.CreationDate,
                            )
                          }}
                        </span>
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ $i18n.t("DOCUMENTS.TABLE.CATEGORY") + ": " }}
                          </span>
                          {{ data.value.Category }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <a
                          href="javascript:void(0)"
                          v-on:click="
                            downloadFile(data.value.DocId, data.value.FileName)
                          "
                          class="poschodoch-icon-link p-2"
                        >
                          <i
                            class="flaticon-download poschodoch-icon-medium"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::DocumentListUser-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FileService from "@/common/file.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
//import JwtService from "@/common/jwt.service";

export default {
  name: "documentListUser",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      lastPeriod: "",
      periodClass: "row",
      fields: [
        {
          key: "DocName",
          label: this.$i18n.t("DOCUMENTS.TABLE.DOC_NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "CreationDate",
          label: this.$i18n.t("DOCUMENTS.TABLE.CREATION_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Category",
          label: this.$i18n.t("DOCUMENTS.TABLE.CATEGORY"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Service",
          label: this.$i18n.t("DOCUMENTS.TABLE.SERVICE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DocId",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      categoryOptions: [
        { value: null, text: this.$i18n.t("DOCUMENTS.FILTER.PLACEHOLDER") },
      ],
      filterDocName: null,
      filterCategory: null,
    };
  },
  computed: {
    filter() {
      return { DocName: this.filterDocName, Category: this.filterCategory };
    },
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    filterTable(row, filter) {
      let result = true;

      if (filter.DocName != null) {
        if (
          !FormatService.normalizeString(row.DocName).includes(
            FormatService.normalizeString(filter.DocName),
          )
        ) {
          result = false;
        }
      }

      if (filter.Category != null) {
        if (
          !FormatService.normalizeString(row.Category).includes(
            FormatService.normalizeString(filter.Category),
          )
        ) {
          result = false;
        }
      }

      return result;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    downloadFile(docId, fileName) {
      let parameters = "?docId=" + docId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "Get" + parameters).then(({ data }) => {
        let binaryContent = FileService.convertBase64ToByteArray(
          data.FileContent,
        );

        FileService.downloadFile(
          fileName,
          binaryContent,
          null,
          "document-download-link",
        );
      });
    },
    setCategoryOptions(items) {
      let options = {};

      for (let i = 0; i < items.length; i++) {
        let category = items[i].Category;
        if (options[category] == null) {
          options[category] = category;
          this.categoryOptions.push({ value: category, text: category });
        }
      }
    },
    fetchData() {
      this.items = null;
      let parameters = "?tableName=uzivatelia&menuId=" + this.menuId.toString(); //+"&RecKey=" + JwtService.getUnit();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.transformData(data.Documents);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Documents;
        this.totalRows = this.items.length;
        this.currentPage = 1;

        this.setCategoryOptions(data.Documents);
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          FileName: items[i].FileName,
          DocName: items[i].DocName,
          CreationDate: items[i].CreationDate,
          Category: items[i].Category,
          DocId: items[i].DocId,
        };
      }
    },
  },
};
</script>
