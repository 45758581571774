<template>
  <div>
    <CostDetailDocs v-bind:costDetailDocs="costDetailDocs" />

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row>
                <!--Begin::Count of rows per page -->
                <b-col
                  v-if="items && items.length > 0"
                  class="poschodoch-rows-count mb-2"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                      @change="getFormatService().formatSetPerPage(perPage)"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!--End::Count of rows per page -->
              </b-row>

              <b-table
                v-if="items"
                class="poschodoch-costDetail-table"
                show-empty
                hover
                :items="items"
                :fields="fields"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :current-page="currentPage"
                :per-page="perPage"
              >
                <template v-slot:head(Consumption)="data">
                  <span>{{ data.label + " [" + unitOfMeasure + "]" }}</span>
                </template>
                <template v-slot:cell(DocCount)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.value > 1"
                    v-on:click="
                      showCostDetailDocs(data.item.RecKey, data.item.Tablename)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    v-if="data.value == 1"
                    v-on:click="
                      showSingleDoc(data.item.RecKey, data.item.Tablename)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <span class="poschodoch-invoices-title-mobile">
                      {{ data.value.SubscriberSite }}
                    </span>
                    <div
                      class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span v-if="data.value.DateFrom && data.value.DateTo">
                          {{
                            $i18n.t("COST_DETAIL.TABLE.DATEFROM_MOBILE") +
                              " " +
                              data.value.DateFrom +
                              " " +
                              $i18n.t("COST_DETAIL.TABLE.DATETO_MOBILE") +
                              " " +
                              data.value.DateTo
                          }}
                        </span>
                        <span v-if="data.value.Consumption">
                          {{
                            $i18n.t("COST_DETAIL.TABLE.CONSUMPTION") +
                              ": " +
                              data.value.Consumption +
                              " " +
                              unitOfMeasure
                          }}
                        </span>
                        <span v-if="data.value.Budget">
                          {{
                            $i18n.t("COST_DETAIL.TABLE.BUDGET") +
                              ": " +
                              data.value.Budget
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1 poschodoch-invoices-amount"
                      >
                        {{ addCurrency(data.value.Amount) }}
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount > 1"
                            v-on:click="
                              showCostDetailDocs(
                                data.item.RecKey,
                                data.item.Tablename,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.RecKey,
                                data.item.Tablename,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>

              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="items.length"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import CostDetailDocs from "@/views/pages/domus/CostDetailDocs.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "cost-detail",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    CostDetailDocs,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      unitOfMeasure: null,
      code: null,
      year: null,
      costDetailDocs: null,
      fields: [
        {
          key: "SubscriberSite",
          label: this.$i18n.t("COST_DETAIL.TABLE.SUBSCRIBERSITE"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "Budget",
          label: this.$i18n.t("COST_DETAIL.TABLE.BUDGET"),
          sortable: false,
          class: "text-right table-no-mobile",
        },
        {
          key: "DateFrom",
          label: this.$i18n.t("COST_DETAIL.TABLE.DATEFROM"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DateTo",
          label: this.$i18n.t("COST_DETAIL.TABLE.DATETO"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Consumption",
          label: this.$i18n.t("COST_DETAIL.TABLE.CONSUMPTION"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("COST_DETAIL.TABLE.AMOUNT"),
          ),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.code = this.$route.query.code;
    this.year = this.$route.query.year;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return (
        data + " " + FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.costDetailDocs = data;

        parameters =
          "?docId=" + this.costDetailDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.costDetailDocs.Documents[0].FileName;

          FileService.downloadFile(fileName, binaryContent);
        });
      });
    },
    showCostDetailDocs(recKey, tablename) {
      let parameters =
        "?tableName=" +
        tablename +
        "&recKey=" +
        recKey.toString() +
        "&menuId=183";

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.costDetailDocs = data;
        this.$bvModal.show("modal-costDetail-docs");
      });
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("COST_DETAIL.TABLE.SUBSCRIBERSITE"),
        this.$i18n.t("COST_DETAIL.TABLE.BUDGET"),
        this.$i18n.t("COST_DETAIL.TABLE.DATEFROM"),
        this.$i18n.t("COST_DETAIL.TABLE.DATETO"),
        this.$i18n.t("COST_DETAIL.TABLE.CONSUMPTION"),
        this.addCurrencyToColumn(this.$i18n.t("COST_DETAIL.TABLE.AMOUNT")),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.SubscriberSite,
          item.Budget,
          FormatService.formatDateToLocal(item.DateFrom),
          FormatService.formatDateToLocal(item.DateTo),
          FormatService.formatNumberToLocalForCSV(item.Consumption),
          FormatService.formatNumberToLocalForCSV(item.Amount),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + `_${this.year}.csv`;

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.code != null) parameters += "&code=" + this.code.toString();
      if (this.year != null) parameters += "&year=" + this.year.toString();

      window.open("/costDetailPrint" + parameters);
    },
    fetchData() {
      this.items = null;
      let parameters =
        "?menuId=" + this.menuId.toString() + "&code=" + this.code.toString();
      if (this.year != null) parameters += "&year=" + this.year.toString();

      ApiService.setHeader();
      ApiService.get("Object", "CostDetail" + parameters).then(({ data }) => {
        this.transformData(data.CostDetail);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.CostDetail;
        this.currentPage = 1;
        this.totalRows = this.items.length;

        this.unitOfMeasure = data.UnitOfMeasure;
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          SubscriberSite: items[i].SubscriberSite,
          DateFrom: FormatService.formatDateToLocal(items[i].DateFrom),
          DateTo: FormatService.formatDateToLocal(items[i].DateTo),
          Consumption: FormatService.formatNumberToLocal(items[i].Consumption),
          Budget: items[i].Budget,
          Amount: FormatService.formatNumberToLocal(items[i].Amount),
        };
      }
    },
  },
};
</script>
