var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-deactivate-unit-list-admin","hide-footer":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(_vm._s(_vm.$t("UNIT_LIST.TITLE")))]},proxy:true}])},[_c('div',{staticClass:"d-block text-center"},[_c('b-table',{staticClass:"table-white poschodoch-units-table",attrs:{"hover":"","items":_vm.items,"fields":_vm.fields,"show-empty":"","size":"md","tbody-tr-class":_vm.pointerRowClass},on:{"row-clicked":_vm.tableRowClickHandler},scopedSlots:_vm._u([{key:"cell(ConnectedAccounts)",fn:function(data){return [(data.value > 1)?_c('a',{staticClass:"poschodoch-icon-link p-2 d-flex",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.changeUnitWithTxt(
                data.item.UnitId,
                data.item.PortalId,
                data.item.UnitType
              )}}},[_c('i',{staticClass:"flaticon-users-1 poschodoch-icon-medium"}),_vm._v(" "+_vm._s(data.value)+" ")]):_vm._e()]}},{key:"cell(UnitId)",fn:function(data){return [(!data.item.Selected)?_c('b-button',{attrs:{"size":"sm","variant":"primary","id":'unit_button_' + data.value},on:{"click":function($event){return _vm.deactivateUnit(data.value, data.item.PortalId, data.item.UaID)}}},[_vm._v(" "+_vm._s(_vm.$t("UNIT_LIST.CANCEL_BUTTON"))+" ")]):_vm._e()]}},{key:"cell(Icon)",fn:function(data){return [_c('span',{class:data.value})]}},{key:"cell(Mobile)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-row align-items-start w-100"},[_c('div',{staticClass:"d-flex align-items-start mr-3 mt-2"},[_c('span',{class:data.item.Icon})]),_c('div',{staticClass:"d-flex flex-column align-items-start w-100"},[_c('div',{staticClass:"d-flex w-100 flex-row justify-content-between"},[_c('div',{staticClass:"d-flex flex-row align-items-end poschodoch-mobile-right mr-1"},[_c('span',{staticClass:"ml-2",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(data.item.Type)+" ")]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(data.item.VarSymb))])]),_c('div',{staticClass:"d-flex flex-column align-items-end poschodoch-mobile-right mr-1"},[(!data.item.Selected)?_c('b-button',{attrs:{"size":"sm","variant":"primary","id":'unit_button_' + data.item.UnitId},on:{"click":function($event){return _vm.deactivateUnit(
                        data.item.UnitId,
                        data.item.PortalId,
                        data.item.UaID
                      )}}},[_vm._v(" "+_vm._s(_vm.$t("UNIT_LIST.CANCEL_BUTTON"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"d-flex w-100 flex-row justify-content-between mt-3"},[_c('div',{staticClass:"d-flex flex-row align-items-end poschodoch-mobile-right mr-1"},[_c('span',{staticClass:"ml-2",staticStyle:{"font-weight":"600","opacity":"0.9"}},[_vm._v(" "+_vm._s(_vm.fields[4].label)+" ")]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(data.item.PropertyCompany))])])])])])]}}])}),_c('div',{staticClass:"kt-notification__custom kt-space-between"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }