<template>
  <div>
    <RepairFundDocs v-bind:repairFundDocs="repairFundDocs" />

    <div class="row">
      <div class="col-lg-12 col-xl-12 poschodoch-repair-funds-content">
        <!--Begin::Repair Fund-->
        <KTPortlet v-bind:title="null">
          <template v-slot:title>
            <div class="row">
              <span class="kt-portlet__head-title mt-2">{{ title }}</span>
              <span class="poschodoch-inline-devided-text mt-1"></span>
              <span class="kt-portlet__head-title mt-2">
                {{ titleAddition }}
              </span>
            </div>
          </template>
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>

          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row>
                <!--Begin::Filter -->
                <b-col sm="12" md="6" lg="6">
                  <ul
                    class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills"
                    role="tablist"
                  >
                    <li class="nav-item" v-for="index in years" :key="index">
                      <a
                        v-on:click="fetchData(index)"
                        data-tab="0"
                        class="nav-link"
                        href="javascript:void(0)"
                        v-bind:class="{ active: index == year }"
                      >
                        {{ index }}
                      </a>
                    </li>
                  </ul>
                </b-col>
                <!--End::Filter -->
              </b-row>

              <b-row class="mb-4">
                <!--<b-col
                  sm="6"
                  md="6"
                  lg="3"
                  xl="3"
                  v-if="viewOptions.length > 1"
                  class="d-flex justify-content-center"
                >
                  <label style="align-self: center" class="mr-2">
                    {{ $t("REPAIRFUND.FILTER.VIEW") }}
                  </label>
                  <b-form-select
                    v-model="filterView"
                    :options="viewOptions"
                    v-on:change="fetchData()"
                  ></b-form-select>
                </b-col>-->
                <b-col
                  sm="6"
                  md="6"
                  lg="3"
                  xl="3"
                  v-if="gateOptions.length > 1"
                  class="d-flex justify-content-center mt-2 mt-sm-0"
                >
                  <label style="align-self: center" class="mr-2">
                    {{ $t("REPAIRFUND.FILTER.GATE") }}
                  </label>
                  <b-form-select
                    v-model="filterGate"
                    :options="gateOptions"
                    v-on:change="fetchData()"
                  ></b-form-select>
                </b-col>
                <b-col
                  sm="6"
                  md="6"
                  lg="3"
                  xl="3"
                  v-if="vsOptions.length > 1"
                  class="d-flex justify-content-center mt-2 mt-lg-0"
                >
                  <label style="align-self: center" class="mr-2">
                    {{ $t("REPAIRFUND.FILTER.VS") }}
                  </label>
                  <b-form-select
                    v-model="filterVs"
                    :options="vsOptions"
                    v-on:change="fetchData()"
                  ></b-form-select>
                </b-col>
              </b-row>

              <b-row class="mb-4">
                <b-col sm="12" md="12" lg="2">
                  <span>
                    {{ $t("REPAIRFUND.OPENBALANCE") }}: {{ openingBalance }}
                  </span>
                </b-col>
                <b-col sm="12" md="12" lg="2">
                  <span>
                    {{ $t("REPAIRFUND.PRODUCTION") }}: {{ production }}
                  </span>
                </b-col>
                <b-col sm="12" md="12" lg="2">
                  <span>{{ $t("REPAIRFUND.DRAWING") }}: {{ drawing }}</span>
                </b-col>
                <b-col sm="12" md="12" lg="2">
                  <span>
                    {{ $t("REPAIRFUND.FINBALANCE") }}: {{ finalBalance }}
                  </span>
                </b-col>
              </b-row>

              <b-row class="mb-2" style="font-size: 11px">
                <b-col sm="12" md="6" lg="5">
                  <table
                    size="md"
                    role="table"
                    aria-busy="false"
                    aria-colcount="3"
                    class="table b-table poschodoch-repairFund-table table-hover"
                  >
                    <thead role="rowgroup">
                      <tr role="row" class="poschodoch-repFund-monthTable-row">
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class="text-left"
                        >
                          {{ $t("REPAIRFUND.PERIOD") }}
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="2"
                          class="text-right"
                        >
                          {{ $t("REPAIRFUND.PRODUCTION") }}
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="3"
                          class="text-right"
                        >
                          {{ $t("REPAIRFUND.DRAWING") }}
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class="text-right"
                        >
                          {{ $t("REPAIRFUND.BALANCE") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr
                        role="row"
                        aria-rowindex="1"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_1") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[0],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[0],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[0],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="2"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_2") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[1],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[1],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[1],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="3"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_3") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[2],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[2],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[2],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="4"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_4") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[3],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[3],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[3],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="5"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_5") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[4],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[4],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[4],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="6"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_6") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[5],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[5],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[5],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="7"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_7") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[6],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[6],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[6],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="8"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_8") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[7],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[7],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[7],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="9"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_9") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[8],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[8],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[8],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="10"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_10") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[9],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[9],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[9],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="11"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_11") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[10],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[10],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[10],
                            )
                          }}
                        </td>
                      </tr>
                      <tr
                        role="row"
                        aria-rowindex="12"
                        class="poschodoch-repFund-monthTable-row"
                      >
                        <td role="cell" aria-colindex="1">
                          {{ $t("COMMON.MONTHS.MONTH_12") }}
                        </td>
                        <td role="cell" aria-colindex="2" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              productionCells[11],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="3" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              drawingCells[11],
                            )
                          }}
                        </td>
                        <td role="cell" aria-colindex="4" class="text-right">
                          {{
                            getFormatService().formatNumberToLocal(
                              balanceCells[11],
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="7"
                  v-if="chartOptions"
                  class="poschodoch-repFund-chart"
                >
                  <div class="mt-2 mt-lg-5">
                    <Chart
                      ref="repFund-chart"
                      v-for="c in charts"
                      :key="c"
                      v-bind:options="chartOptions"
                      height="200"
                      width="0"
                    ></Chart>
                  </div>

                  <!--Begin::Legend -->
                  <div class="poschodoch-legend" id="repFund-chart-legend" />
                  <!--End::Legend -->
                </b-col>
              </b-row>

              <!--Begin::Main table element original (+ and -) ; Count of rows per page ; Pagination -->
              <div v-if="filterView == null">
                <div class="row">
                  <b-col
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3"
                    v-if="viewOptions.length > 1"
                    class="d-flex justify-content-center mb-1"
                  >
                    <label style="align-self: center" class="mr-2">
                      {{ $t("REPAIRFUND.FILTER.VIEW") }}
                    </label>
                    <b-form-select
                      v-model="filterView"
                      :options="viewOptions"
                      v-on:change="fetchData()"
                    ></b-form-select>
                  </b-col>

                  <b-col
                    class="poschodoch-rows-count mb-6"
                    sm="6"
                    md="6"
                    lg="8"
                    xl="9"
                  >
                    <b-form-group
                      v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                      label-cols-sm="10"
                      label-cols-md="10"
                      label-cols-lg="10"
                      label-align-sm="right"
                      label-align-lg="right"
                      label-align-md="right"
                      label-size="sm"
                      label-for="perPageSelect"
                      class="mb-0"
                    >
                      <b-form-select
                        v-model="perPage1"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions1"
                        @change="getFormatService().formatSetPerPage(perPage1)"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </div>
                <b-table
                  show-empty
                  hover
                  size="md"
                  :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                  :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage1"
                  :per-page="perPage1"
                  :filter="filter"
                  :filterIncludedFields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-direction="sortDirection"
                  @filtered="onFiltered1"
                  :tbody-tr-class="pointerRowClass"
                >
                  <template v-slot:cell(DocCount)="data">
                    <div
                      @click.stop
                      class="h-100 w-100 p-2 d-flex align-items-center justify-content-center"
                      style="cursor: default"
                    >
                      <a
                        href="javascript:void(0)"
                        v-if="data.value > 1"
                        v-on:click="showRepairFundDocs(data.item.RecKey)"
                        class="poschodoch-icon-link p-2"
                      >
                        <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                      </a>
                      <a
                        href="javascript:void(0)"
                        v-if="data.value == 1"
                        v-on:click="
                          showSingleDoc(data.item.RecKey, data.item.Tablename)
                        "
                        class="poschodoch-icon-link p-2"
                      >
                        <i class="flaticon-download poschodoch-icon-medium"></i>
                      </a>
                    </div>
                  </template>

                  <template v-slot:cell(Mobile)="data">
                    <div
                      @click.stop
                      class="d-flex flex-column align-items-start w-100"
                    >
                      <div
                        class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                      >
                        <div
                          class="d-flex flex-column align-items-start poschodoch-mobile-left"
                        >
                          <span
                            v-if="data.value.Date"
                            class="poschodoch-table-title-mobile text-left"
                          >
                            {{
                              getFormatService().formatDateToLocal(
                                data.value.Date,
                              ) + " "
                            }}
                            <span>
                              {{ data.value.Evidence }}
                            </span>
                          </span>
                          <span class="text-left">
                            {{ data.value.Customer }}
                          </span>
                          <span v-if="data.value.Description" class="text-left">
                            {{ data.value.Description }}
                          </span>
                        </div>
                        <div
                          class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                        >
                          <div class="row">
                            <span
                              class="poschodoch-invoices-amount"
                              v-bind:class="{
                                'table-danger': data.value.Amount < 0,
                              }"
                            >
                              {{
                                addCurrencyWSpace(
                                  getFormatService().formatNumberToLocal(
                                    data.value.Amount,
                                  ),
                                )
                              }}
                            </span>
                          </div>
                          <span class="poschodoch-mobile-document-link">
                            <a
                              href="javascript:void(0)"
                              v-if="data.item.DocCount > 1"
                              v-on:click="showRepairFundDocs(data.item.RecKey)"
                              class="poschodoch-icon-link  p-2"
                            >
                              <i
                                class="flaticon-file-2 poschodoch-icon-medium"
                              ></i>
                            </a>
                            <a
                              href="javascript:void(0)"
                              v-if="data.item.DocCount == 1"
                              v-on:click="
                                showSingleDoc(
                                  data.item.RecKey,
                                  data.item.Tablename,
                                )
                              "
                              class="poschodoch-icon-link p-2"
                            >
                              <i
                                class="flaticon-download poschodoch-icon-medium"
                              ></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:custom-foot>
                    <tr class="poschodoch-summary-row">
                      <td class="text-left table-mobile">
                        <div
                          class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                        >
                          <div
                            class="d-flex flex-column align-items-start poschodoch-mobile-left"
                          >
                            {{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}
                          </div>
                          <div
                            class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                          >
                            <span v-bind:class="{ 'color-danger': sum < 0 }">
                              {{
                                addCurrencyWSpace(
                                  getFormatService().formatNumberToLocal(sum),
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr class="poschodoch-summary-row table-no-mobile">
                      <td class="text-left">
                        {{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}
                      </td>
                      <td class="text-right"></td>
                      <td class="text-right"></td>
                      <td class="text-right">
                        <span v-bind:class="{ 'color-danger': sum < 0 }">
                          {{ getFormatService().formatNumberToLocal(sum) }}
                        </span>
                      </td>
                      <td class="text-right"></td>
                    </tr>
                  </template>
                </b-table>

                <b-row
                  v-if="items && items.length > 0"
                  class="justify-content-end"
                >
                  <b-col sm="12" md="6" lg="6">
                    <b-pagination
                      v-model="currentPage1"
                      :total-rows="totalRows1"
                      :per-page="perPage1"
                      size="md"
                      class="poschodoch-pagination justify-content-end"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
              <!--End::Main table element original (+ and -) ; Count of rows per page ; Pagination -->

              <!--Begin::Main table element plus (+) ; Count of rows per page ; Pagination -->
              <div v-if="filterView == 1 || filterView == 3">
                <div class="row">
                  <b-col
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3"
                    v-if="viewOptions.length > 1"
                    class="d-flex justify-content-center mb-1"
                  >
                    <label style="align-self: center" class="mr-2">
                      {{ $t("REPAIRFUND.FILTER.VIEW") }}
                    </label>
                    <b-form-select
                      v-model="filterView"
                      :options="viewOptions"
                      v-on:change="fetchData()"
                    ></b-form-select>
                  </b-col>

                  <b-col
                    class="poschodoch-rows-count mb-6"
                    sm="6"
                    md="6"
                    lg="8"
                    xl="9"
                  >
                    <b-form-group
                      v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                      label-cols-sm="10"
                      label-cols-md="10"
                      label-cols-lg="10"
                      label-align-sm="right"
                      label-align-lg="right"
                      label-align-md="right"
                      label-size="sm"
                      label-for="perPageSelect"
                      class="mb-0"
                    >
                      <b-form-select
                        v-model="perPage2"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions2"
                        @change="getFormatService().formatSetPerPage(perPage2)"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </div>
                <b-table
                  show-empty
                  hover
                  size="md"
                  :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                  :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                  :items="itemsPlus"
                  :fields="fieldsPlus"
                  :current-page="currentPage2"
                  :per-page="perPage2"
                  :filter="filter"
                  :filterIncludedFields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-direction="sortDirection"
                  @filtered="onFiltered2"
                >
                  <template v-slot:cell(DocCount)="data">
                    <a
                      href="javascript:void(0)"
                      v-if="data.value > 1"
                      v-on:click="showRepairFundDocs(data.item.RecKey)"
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      v-if="data.value == 1"
                      v-on:click="
                        showSingleDoc(data.item.RecKey, data.item.Tablename)
                      "
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-download poschodoch-icon-medium"></i>
                    </a>
                  </template>
                  <template v-slot:cell(Mobile)="data">
                    <div class="d-flex flex-column align-items-start w-100">
                      <div
                        class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                      >
                        <div
                          class="d-flex flex-column align-items-start poschodoch-mobile-left"
                        >
                          <span
                            v-if="data.value.Date"
                            class="poschodoch-table-title-mobile text-left"
                          >
                            {{
                              getFormatService().formatDateToLocal(
                                data.value.Date,
                              ) + " "
                            }}
                            <span
                              v-bind:class="{
                                'table-danger': true,
                              }"
                            >
                              {{ data.value.Evidence }}
                            </span>
                          </span>
                          <span class="text-left">
                            {{ data.value.Description }}
                          </span>
                        </div>
                        <div
                          class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                        >
                          <span
                            v-bind:class="{
                              'table-danger': data.value.Amount < 0,
                            }"
                          >
                            {{
                              addCurrencyWSpace(
                                getFormatService().formatNumberToLocal(
                                  data.value.Amount,
                                ),
                              )
                            }}
                          </span>
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount > 1"
                            v-on:click="showRepairFundDocs(data.item.RecKey)"
                            class="poschodoch-icon-link"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.RecKey,
                                data.item.Tablename,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:custom-foot>
                    <tr class="poschodoch-summary-row">
                      <td class="text-left table-mobile">
                        <div
                          class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                        >
                          <div
                            class="d-flex flex-column align-items-start poschodoch-mobile-left"
                          >
                            {{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}
                          </div>
                          <div
                            class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                          >
                            <span
                              v-bind:class="{ 'color-danger': sumPlus < 0 }"
                            >
                              {{
                                addCurrencyWSpace(
                                  getFormatService().formatNumberToLocal(
                                    sumPlus,
                                  ),
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr class="poschodoch-summary-row table-no-mobile">
                      <td class="text-left">
                        {{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}
                      </td>
                      <td class="text-right"></td>
                      <td class="text-right"></td>
                      <td class="text-right">
                        <span v-bind:class="{ 'color-danger': sumPlus < 0 }">
                          {{ getFormatService().formatNumberToLocal(sumPlus) }}
                        </span>
                      </td>
                      <td class="text-right"></td>
                    </tr>
                  </template>
                </b-table>

                <b-row
                  v-if="itemsPlus && itemsPlus.length > 0"
                  class="justify-content-end"
                >
                  <b-col sm="12" md="6" lg="6">
                    <b-pagination
                      v-model="currentPage2"
                      :total-rows="totalRows2"
                      :per-page="perPage2"
                      size="md"
                      class="poschodoch-pagination justify-content-end"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
              <!--End::Main table element plus (+) ; Count of rows per page ; Pagination -->

              <!--Begin::Main table element minus (-) ; Count of rows per page ; Pagination -->
              <div v-if="filterView == 2 || filterView == 3">
                <div class="row" v-if="filterView == 2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3"
                    v-if="viewOptions.length > 1"
                    class="d-flex justify-content-center mb-1"
                  >
                    <label style="align-self: center" class="mr-2">
                      {{ $t("REPAIRFUND.FILTER.VIEW") }}
                    </label>
                    <b-form-select
                      v-model="filterView"
                      :options="viewOptions"
                      v-on:change="fetchData()"
                    ></b-form-select>
                  </b-col>

                  <b-col
                    class="poschodoch-rows-count mb-6"
                    sm="6"
                    md="6"
                    lg="8"
                    xl="9"
                  >
                    <b-form-group
                      v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                      label-cols-sm="10"
                      label-cols-md="10"
                      label-cols-lg="10"
                      label-align-sm="right"
                      label-align-lg="right"
                      label-align-md="right"
                      label-size="sm"
                      label-for="perPageSelect"
                      class="mb-0"
                    >
                      <b-form-select
                        v-model="perPage3"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions3"
                        @change="getFormatService().formatSetPerPage(perPage3)"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </div>
                <b-table
                  show-empty
                  hover
                  size="md"
                  :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                  :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                  :items="itemsMinus"
                  :fields="fieldsMinus"
                  :current-page="currentPage3"
                  :per-page="perPage3"
                  :filter="filter"
                  :filterIncludedFields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-direction="sortDirection"
                  @filtered="onFiltered3"
                >
                  <template v-slot:cell(DocCount)="data">
                    <a
                      href="javascript:void(0)"
                      v-if="data.value > 1"
                      v-on:click="showRepairFundDocs(data.item.RecKey)"
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      v-if="data.value == 1"
                      v-on:click="
                        showSingleDoc(data.item.RecKey, data.item.Tablename)
                      "
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-download poschodoch-icon-medium"></i>
                    </a>
                  </template>
                  <template v-slot:cell(Mobile)="data">
                    <div class="d-flex flex-column align-items-start w-100">
                      <div
                        class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                      >
                        <div
                          class="d-flex flex-column align-items-start poschodoch-mobile-left"
                        >
                          <span
                            v-if="data.value.Date"
                            class="poschodoch-table-title-mobile text-left"
                          >
                            {{
                              getFormatService().formatDateToLocal(
                                data.value.Date,
                              ) + " "
                            }}
                            <span
                              v-bind:class="{
                                'table-danger': true,
                              }"
                            >
                              {{ data.value.Evidence }}
                            </span>
                          </span>
                          <span class="text-left">
                            {{ data.value.Description }}
                          </span>
                        </div>
                        <div
                          class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                        >
                          <span
                            v-bind:class="{
                              'table-danger': data.value.Amount < 0,
                            }"
                          >
                            {{
                              addCurrencyWSpace(
                                getFormatService().formatNumberToLocal(
                                  data.value.Amount,
                                ),
                              )
                            }}
                          </span>
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount > 1"
                            v-on:click="showRepairFundDocs(data.item.RecKey)"
                            class="poschodoch-icon-link"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.RecKey,
                                data.item.Tablename,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:custom-foot>
                    <tr class="poschodoch-summary-row">
                      <td class="text-left table-mobile">
                        <div
                          class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                        >
                          <div
                            class="d-flex flex-column align-items-start poschodoch-mobile-left"
                          >
                            {{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}
                          </div>
                          <div
                            class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                          >
                            <span
                              v-bind:class="{ 'color-danger': sumMinus < 0 }"
                            >
                              {{
                                addCurrencyWSpace(
                                  getFormatService().formatNumberToLocal(
                                    sumMinus,
                                  ),
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr class="poschodoch-summary-row table-no-mobile">
                      <td class="text-left">
                        {{ $t("ANNUALBILLING_DETAIL.AMOUNT") }}
                      </td>
                      <td class="text-right"></td>
                      <td class="text-right"></td>
                      <td class="text-right">
                        <span v-bind:class="{ 'color-danger': sumMinus < 0 }">
                          {{ getFormatService().formatNumberToLocal(sumMinus) }}
                        </span>
                      </td>
                      <td class="text-right"></td>
                    </tr>
                  </template>
                </b-table>

                <b-row
                  v-if="itemsMinus && itemsMinus.length > 0"
                  class="justify-content-end"
                >
                  <b-col sm="12" md="6" lg="6">
                    <b-pagination
                      v-model="currentPage3"
                      :total-rows="totalRows3"
                      :per-page="perPage3"
                      size="md"
                      class="poschodoch-pagination justify-content-end"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
              <!--End::Main table element minus (-) ; Count of rows per page ; Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Repair Fund-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import RepairFundDocs from "@/views/pages/domus/RepairFundDocs.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";
import Chart from "@/views/components/Chart.vue";
import { mapGetters } from "vuex";

export default {
  name: "repairFund",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    RepairFundDocs,
    Chart,
  },
  data() {
    return {
      title: null,
      titleAddition: null,
      description: null,
      menuId: null,
      repairFundDocs: null,
      items: [],
      itemsPlus: [],
      itemsMinus: [],
      sum: 0,
      sumPlus: 0,
      sumMinus: 0,
      lastSelectedYear: null,
      finalBalance: null,
      openingBalance: null,
      production: null,
      drawing: null,
      productionCells: [],
      drawingCells: [],
      balanceCells: [],
      fields: [
        {
          key: "Date",
          label: this.$i18n.t("REPAIRFUND.TABLE.DATE"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Evidence",
          label: this.$i18n.t("REPAIRFUND.TABLE.EVIDENCE"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Description",
          label: this.$i18n.t("REPAIRFUND.TABLE.DESCRIPTION"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("REPAIRFUND.TABLE.AMOUNT"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center p-0 table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      fieldsPlus: [
        {
          key: "Date",
          label: this.$i18n.t("REPAIRFUND.TABLE.DATE"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Evidence",
          label: this.$i18n.t("REPAIRFUND.TABLE.EVIDENCE"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Description",
          label: this.$i18n.t("REPAIRFUND.TABLE.DESCRIPTION"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("REPAIRFUND.TABLE.AMOUNT"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._rowVariant = "row-hide";
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      fieldsMinus: [
        {
          key: "Date",
          label: this.$i18n.t("REPAIRFUND.TABLE.DATE"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Evidence",
          label: this.$i18n.t("REPAIRFUND.TABLE.EVIDENCE"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Description",
          label: this.$i18n.t("REPAIRFUND.TABLE.DESCRIPTION"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("REPAIRFUND.TABLE.AMOUNT"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            } else {
              item._rowVariant = "row-hide";
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      totalRows1: 1,
      totalRows2: 1,
      totalRows3: 1,
      currentPage1: 1,
      currentPage2: 1,
      currentPage3: 1,
      perPage1: FormatService.formatGetPerPage(),
      perPage2: FormatService.formatGetPerPage(),
      perPage3: FormatService.formatGetPerPage(),
      pageOptions1: FormatService.formatGetPerPageOption(),
      pageOptions2: FormatService.formatGetPerPageOption(),
      pageOptions3: FormatService.formatGetPerPageOption(),
      gate: null,
      year: null,
      years: null,
      sortBy: "",
      sortDirection: "desc",
      filter: null,
      filterOn: [],
      gateOptions: [
        { value: null, text: this.$i18n.t("REPAIRFUND.FILTER.PLACEHOLDER") },
      ],
      vsOptions: [
        { value: null, text: this.$i18n.t("REPAIRFUND.FILTER.PLACEHOLDER") },
      ],
      viewOptions: [
        { value: null, text: this.$i18n.t("REPAIRFUND.VIEWOPTIONS.1") },
        { value: 1, text: this.$i18n.t("REPAIRFUND.VIEWOPTIONS.2") },
        { value: 2, text: this.$i18n.t("REPAIRFUND.VIEWOPTIONS.3") },
        { value: 3, text: this.$i18n.t("REPAIRFUND.VIEWOPTIONS.4") },
      ],
      filterGate: null,
      filterVs: null,
      filterView: null,
      chartOptions: null,
      charts: [1],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.repairFundDocs = data;

        parameters =
          "?docId=" + this.repairFundDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters)
          .then(({ data }) => {
            let binaryContent = FileService.convertBase64ToByteArray(
              data.FileContent,
            );

            let fileName = this.repairFundDocs.Documents[0].FileName;

            FileService.downloadFile(fileName, binaryContent);
          })
          .catch((response) => {
            this.errors.push(response.response.data.error);
          });
      });
    },
    showRepairFundDocs(recKey) {
      let parameters =
        "?tableName=faktury&recKey=" + recKey.toString() + "&menuId=183";

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.repairFundDocs = data;
        this.$bvModal.show("modal-repairFund-docs");
      });
    },
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    addCurrencyWSpace(data) {
      return (
        " " +
        data +
        "\u00A0" +
        FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    onFiltered1(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows1 = filteredItems.length;
      this.currentPage1 = 1;
    },
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
    onFiltered3(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows3 = filteredItems.length;
      this.currentPage3 = 1;
    },
    setYearRange(yearFrom, yearTo) {
      if (this.years == null) {
        this.years = [];
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.year != null) parameters += "&year=" + this.year.toString();
      if (this.filterView != null)
        parameters += "&fv=" + this.filterView.toString();
      else parameters += "&fv=0";

      if (this.filterGate != null)
        parameters += "&Gate=" + this.filterGate.toString();
      if (this.filterVs != null)
        parameters += "&VS=" + this.filterVs.toString();

      window.open("/repairFundPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("REPAIRFUND.TABLE.DATE"),
        this.$i18n.t("REPAIRFUND.TABLE.EVIDENCE"),
        this.$i18n.t("REPAIRFUND.TABLE.DESCRIPTION"),
        this.addCurrencyToColumn(this.$i18n.t("REPAIRFUND.TABLE.AMOUNT")),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          FormatService.formatDateToLocal(item.Date),
          item.Evidence,
          item.Description,
          FormatService.formatNumberToLocalForCSV(item.Amount),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + `_${this.year}.csv`;

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    setGateOptions(items) {
      for (let i = 0; i < items.length; i++) {
        let gate = items[i];
        this.gateOptions.push({ value: gate.Gate, text: gate.Text });
      }
    },
    setVsOptions(items) {
      for (let i = 0; i < items.length; i++) {
        let vs = items[i];
        this.vsOptions.push({ value: vs.VS, text: vs.Text });
      }
    },
    createChart() {
      this.charts = [this.charts[0] + 1];
      this.chartOptions = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.productionCells,
              label: this.$i18n.t("REPAIRFUND.PRODUCTION"),
              fill: false,
              borderColor: "#0abb87",
              borderWidth: 3,
              backgroundColor: "#0abb87",
            },
            {
              data: this.drawingCells.map((value) => -value), // Záporné hodnoty pre Čerpanie  ,
              label: this.$i18n.t("REPAIRFUND.DRAWING"),
              fill: false,
              borderColor: this.layoutConfig("colors.state.danger"),
              borderWidth: 3,
              backgroundColor: this.layoutConfig("colors.state.danger"),
            },
          ],
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "repFund-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                scaleLabel: {
                  fontSize: 11,
                  display: true,
                  labelString: this.$i18n.t("REPAIRFUND.MONTHS"),
                },
                ticks: {
                  fontSize: 11,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                scaleLabel: {
                  fontSize: 11,
                  display: false,
                  labelString: FormatService.formatCurrency(
                    JwtService.getCurrency(),
                  ),
                },
                ticks: {
                  beginAtZero: true,
                  fontSize: 11,
                  callback: function(value) {
                    return (
                      FormatService.formatNumberToLocalNoFractionDigits(value) +
                      " " +
                      FormatService.formatCurrency(JwtService.getCurrency())
                    );
                  },
                },
              },
            ],
          },
        },
      };
    },
    fetchData(inputYear = null) {
      this.items = null;
      this.gateOptions.splice(1, this.gateOptions.length - 1);
      this.vsOptions.splice(1, this.vsOptions.length - 1);
      let parameters = "?menuId=" + this.menuId.toString();

      if (inputYear == null) inputYear = this.lastSelectedYear;
      if (inputYear == null) inputYear = new Date().getFullYear();

      if (inputYear != null) {
        parameters += "&year=" + inputYear.toString();
        this.lastSelectedYear = inputYear;
      }

      if (this.filterGate != null)
        parameters += "&Gate=" + this.filterGate.toString();
      if (this.filterVs != null)
        parameters += "&VS=" + this.filterVs.toString();

      ApiService.setHeader();
      ApiService.get("Object", "RepairFund" + parameters).then(({ data }) => {
        this.transformData(data.RepairFund);

        this.year = data.Year;
        this.setYearRange(data.YearFrom, data.YearTo);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.RepairFund;
        this.finalBalance = this.addCurrencyWSpace(
          this.getFormatService().formatNumberToLocal(data.FinalBalance),
        );
        this.openingBalance = this.addCurrencyWSpace(
          this.getFormatService().formatNumberToLocal(data.OpeningBalance),
        );
        this.production = this.addCurrencyWSpace(
          this.getFormatService().formatNumberToLocal(data.Production),
        );
        this.drawing = this.addCurrencyWSpace(
          this.getFormatService().formatNumberToLocal(data.Drawing),
        );
        this.totalRows1 = this.items.length;
        this.totalRows2 = this.itemsPlus.length;
        this.totalRows3 = this.itemsMinus.length;

        this.productionCells = [
          data.Production01,
          data.Production02,
          data.Production03,
          data.Production04,
          data.Production05,
          data.Production06,
          data.Production07,
          data.Production08,
          data.Production09,
          data.Production10,
          data.Production11,
          data.Production12,
        ];
        this.drawingCells = [
          data.Drawing01,
          data.Drawing02,
          data.Drawing03,
          data.Drawing04,
          data.Drawing05,
          data.Drawing06,
          data.Drawing07,
          data.Drawing08,
          data.Drawing09,
          data.Drawing10,
          data.Drawing11,
          data.Drawing12,
        ];
        this.balanceCells = [
          data.Balance01,
          data.Balance02,
          data.Balance03,
          data.Balance04,
          data.Balance05,
          data.Balance06,
          data.Balance07,
          data.Balance08,
          data.Balance09,
          data.Balance10,
          data.Balance11,
          data.Balance12,
        ];
        this.createChart();

        this.titleAddition =
          this.$i18n.t("REPAIRFUND.TITLEADD") +
          FormatService.formatDateToLocal(data.IssueDate) +
          ": " +
          this.addCurrencyWSpace(
            this.getFormatService().formatNumberToLocal(data.FinalBalance),
          );

        if (data.ParmGate != null) this.setGateOptions(data.ParmGate);
        if (data.ParmVS != null) this.setVsOptions(data.ParmVS);
      });
    },
    transformData(items) {
      let z = 0,
        x = 0;
      (this.sum = 0), (this.sumPlus = 0), (this.sumMinus = 0);
      (this.itemsPlus = []), (this.itemsMinus = []);

      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Date: items[i].Date,
          Evidence: items[i].Evidence,
          Description: items[i].Description,
          Amount: items[i].Amount,
          DocCount: items[i].DocCount,
        };

        if (items[i].Amount > 0) {
          this.itemsPlus[z] = items[i];
          z++;
          this.sumPlus += parseFloat(items[i].Amount);
        } else if (items[i].Amount < 0) {
          this.itemsMinus[x] = items[i];
          x++;
          this.sumMinus += parseFloat(items[i].Amount);
        }
        this.sum += parseFloat(items[i].Amount);
      }
    },
  },
};
</script>
