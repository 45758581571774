<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::UnpaidPrePaymentsForUser-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Parameters -->
              <b-row class="mb-3">
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div class="d-flex">
                    <label
                      style="flex: none; align-self: center; font-weight: 500"
                    >
                      {{ $t("UNPAID_PREPAYMENTS.PARAMETER.DATE") }}
                    </label>
                    <b-form-input
                      type="date"
                      v-model="parameterDate"
                      class="col-mb-3 mb-sm-0 ml-0 ml-sm-1 mr-0 mr-sm-3"
                    ></b-form-input>
                    <b-button
                      variant="primary mb-3 ml-3"
                      id="parameter_button"
                      @click="fetchData()"
                    >
                      {{ $i18n.t("UNPAID_PREPAYMENTS.PARAMETER.BUTTON") }}
                    </b-button>
                  </div>
                  <b-col
                    class="d-flex flex-row align-items-center mb-3 md-3 mr-0 pr-0 !important col-lg-4 col-md-6 col-sm-12 col-md-6"
                  >
                    <label for="search" class="mr-1" style="font-weight: 500">
                      {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                    </label>
                    <b-form-input
                      v-model="filter"
                      @change="Search()"
                    ></b-form-input>
                  </b-col>
                </b-col>
              </b-row>
              <!--End::Parameters -->

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :filter="filter"
              >
                <template v-slot:head()="data">
                  <span v-b-tooltip.hover :title="data.field.tooltip">
                    {{ data.label }}
                  </span>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <b>{{ data.value.DueDate }}</b>
                        </span>
                      </div>
                    </div>
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        {{ data.value.Type + ", " + data.value.ToBePaid }}
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        {{
                          data.value.Period,
                        }}
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::UnpaidPrePaymentsForUser-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";
import GridService from "@/common/grid.service";

export default {
  name: "UnpaidPrePaymentsForUser",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      filter: null,
      fields: [
        {
          key: "Period",
          label: this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.PERIOD"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DueDate",
          label: this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.DUE_DATE"),
          tooltip: this.$i18n.t("UNPAID_PREPAYMENTS.TOOLTIP.DUE_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Type",
          label: this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.TYPE"),
          tooltip: this.$i18n.t("UNPAID_PREPAYMENTS.TOOLTIP.TYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.AMOUNT"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Paid",
          label: this.addCurrencyToColumn(
            this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.PAID"),
          ),
          tooltip: this.$i18n.t("UNPAID_PREPAYMENTS.TOOLTIP.PAID"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (item.Paid < 0) {
              item._cellVariants = { Paid: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "ToBePaid",
          label: this.addCurrencyToColumn(
            this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.TO_BE_PAID"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (item.ToBePaid < 0) {
              item._cellVariants = { ToBePaid: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
      parameterDate: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    let bContent = GridService.getGridFilter("unpaidPrePaymentsForUser");
    if (bContent) {
      this.filter = bContent.filter;
      this.sortBy = bContent.sortBy;
      this.sortDirection = bContent.sortDirection;
    }
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.parameterDate != null)
        parameters += "&dueDate=" + this.parameterDate.toString();
      window.open("/UnpaidPrePaymentsForUserPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.PERIOD"),
        this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.DUE_DATE"),
        this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.TYPE"),
        this.addCurrencyToColumn(
          this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.AMOUNT"),
        ),
        this.addCurrencyToColumn(this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.PAID")),
        this.addCurrencyToColumn(
          this.$i18n.t("UNPAID_PREPAYMENTS.TABLE.TO_BE_PAID"),
        ),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.Period,
          FormatService.formatDateToLocal(item.DueDate),
          item.Type,
          FormatService.formatNumberToLocalForCSV(item.Amount),
          FormatService.formatNumberToLocalForCSV(item.Paid),
          FormatService.formatNumberToLocalForCSV(item.ToBePaid),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      if (this.parameterDate != null)
        parameters += "&dueDate=" + this.parameterDate.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "UnpaidPrePaymentsForUser" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.UnpaidPrePayments;
          this.parameterDate = data.DueDate;

          this.transformData(this.items);
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        //items[i].DueDate = FormatService.formatDateToLocal(items[i].DueDate);
        items[i].Mobile = {
          Period: items[i].Period,
          Type: items[i].Type,
          Amount: items[i].Amount,
          Paid: items[i].Paid,
          ToBePaid: items[i].ToBePaid,
        };
      }
    },
  },
};
</script>
