<template>
  <div>
    <a id="csv-download-link" href style="display: none"></a>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Filter-->
        <KTPortlet
          v-bind:title="title"
          v-show="chartOptionsColdWater || chartOptionsHotWater"
        >
          <template v-slot:body>
            <b-container
              fluid
              v-if="chartOptionsColdWater || chartOptionsHotWater"
            >
              <b-row>
                <b-col sm="12" md="6" lg="6">
                  <ul
                    class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills m-0"
                    role="tablist"
                  >
                    <li class="nav-item" v-for="index in years" :key="index">
                      <a
                        v-on:click="fetchData(index)"
                        data-tab="0"
                        class="nav-link"
                        href="javascript:void(0)"
                        v-bind:class="{ active: index == year }"
                      >
                        {{ index }}
                      </a>
                    </li>
                  </ul>
                </b-col>
              </b-row>
              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
        <!--End::Filter-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-xl-6">
        <!--Begin::Consumption-->
        <KTPortlet
          v-bind:title="subTitleColdWater"
          v-show="chartOptionsColdWater || chartOptionsColdWater === null"
        >
          <template v-slot:body>
            <b-container
              fluid
              v-if="chartOptionsColdWater"
              class="poschodoch-donut-container"
            >
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsColdWater"
                class="poschodoch-consumption-graph"
              >
                <div>
                  <Chart
                    ref="average-day-consumption-cold-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsColdWater"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div
                class="poschodoch-legend"
                id="average-day-consumption-cold-water-chart-legend"
              />
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsColdWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Consumption-->
      </div>

      <div class="col-lg-6 col-xl-6">
        <!--Begin::Consumption-->
        <KTPortlet
          v-bind:title="subTitleHotWater"
          v-show="chartOptionsHotWater || chartOptionsHotWater === null"
        >
          <template v-slot:body>
            <b-container
              fluid
              v-if="chartOptionsHotWater"
              class="poschodoch-donut-container"
            >
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHotWater"
                class="poschodoch-consumption-graph"
              >
                <div>
                  <Chart
                    ref="average-day-consumption-hot-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHotWater"
                    height="400"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div
                class="poschodoch-legend"
                id="average-day-consumption-hot-water-chart-legend"
              />
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHotWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Consumption-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import { mapGetters } from "vuex";
import ColorService from "@/common/color.service";

export default {
  name: "average-day-consumption",
  components: {
    KTPortlet,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      description: null,
      subTitleColdWater: null,
      subTitleHotWater: null,
      menuId: null,
      datasetsColdWater: null,
      datasetsHotWater: null,
      year: null,
      years: null,
      yearFrom: null,
      yearTo: null,
      month: null,
      months: null,
      monthFrom: null,
      monthTo: null,
      chartOptionsColdWater: null,
      chartOptionsHotWater: null,
      chartLabelsColdWater: [],
      chartLabelsHotWater: [],
      charts: [1],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    createChartColdWater() {
      this.charts = [this.charts[0] + 1];
      this.chartOptionsColdWater = {
        type: "doughnut",
        data: {
          datasets: this.datasetsColdWater,
          labels: this.chartLabelsColdWater,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "average-day-consumption-cold-water-chart-legend",
          legend: false,
          legendCallback: UIService.createDoughnutChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
        },
      };
    },
    createChartHotWater() {
      this.charts = [this.charts[0] + 1];
      this.chartOptionsHotWater = {
        type: "doughnut",
        data: {
          datasets: this.datasetsHotWater,
          labels: this.chartLabelsHotWater,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "average-day-consumption-hot-water-chart-legend",
          legend: false,
          legendCallback: UIService.createDoughnutChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
        },
      };
    },
    setYearRange(yearFrom, yearTo) {
      if (this.years == null) {
        this.years = [];
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    transformDataColdWater(data) {
      let datasets = [];
      let labels = [];
      let baseColor = ColorService.hexToRgb(
        this.layoutConfig("colors.state.info"),
      );

      datasets.push({ data: [], backgroundColor: [] });
      for (let i = 0; i < data.length; i++) {
        let ratio = 0.2 + (0.8 * i) / data.length;
        let color =
          "rgb(" +
          parseInt(baseColor.r * ratio) +
          "," +
          parseInt(baseColor.g * ratio) +
          "," +
          parseInt(baseColor.b * ratio) +
          ")";

        datasets[0].data.push(data[i].Consumption);
        datasets[0].backgroundColor.push(color);

        labels.push(data[i].Dayname);
      }

      this.chartLabelsColdWater = labels;
      this.datasetsColdWater = datasets;
    },
    transformDataHotWater(data) {
      let datasets = [];
      let labels = [];
      let baseColor = ColorService.hexToRgb(
        this.layoutConfig("colors.state.danger"),
      );

      datasets.push({ data: [], backgroundColor: [] });
      for (let i = 0; i < data.length; i++) {
        let ratio = 0.2 + (0.8 * i) / data.length;
        let color =
          "rgb(" +
          parseInt(baseColor.r * ratio) +
          "," +
          parseInt(baseColor.g * ratio) +
          "," +
          parseInt(baseColor.b * ratio) +
          ")";

        datasets[0].data.push(data[i].Consumption);
        datasets[0].backgroundColor.push(color);

        labels.push(data[i].Dayname);
      }

      this.chartLabelsHotWater = labels;
      this.datasetsHotWater = datasets;
    },
    fetchData(selectedYear = null) {
      // cold water
      this.chartOptionsColdWater = null;
      let parameters = "?type=S&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "AverageDayConsumption" + parameters)
        .then(({ data }) => {
          this.transformDataColdWater(data.Consumption);
          this.createChartColdWater();

          this.title = data.Title;
          this.description = data.Description;
          this.subTitleColdWater = data.Subtitle;
          this.year = data.Year;
          this.yearFrom = data.YearFrom;
          this.yearTo = data.YearTo;
          this.setYearRange(data.YearFrom, data.YearTo);
        })
        .catch(() => {
          this.chartOptionsColdWater = undefined;
        });

      // Hot water
      this.chartOptionsHotWater = null;
      parameters = "?type=T&menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "AverageDayConsumption" + parameters)
        .then(({ data }) => {
          this.transformDataHotWater(data.Consumption);
          this.createChartHotWater();

          this.subTitleHotWater = data.Subtitle;

          if (!this.chartOptionsColdWater) {
            this.title = data.Title;
            this.description = data.Description;

            this.year = data.Year;
            this.yearFrom = data.YearFrom;
            this.yearTo = data.YearTo;
            this.setYearRange(data.YearFrom, data.YearTo);
          }
        })
        .catch(() => {
          this.chartOptionsHotWater = undefined;
        });
    },
  },
};
</script>
