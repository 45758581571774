<template>
  <div>
    <InvoicesDocs v-bind:invoicesDocs="invoicesDocs" />

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Invoices-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-show="items">
              <b-row>
                <!--Begin::Filter -->
                <b-col sm="12" md="5" lg="5">
                  <ul
                    class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills"
                    role="tablist"
                  >
                    <li class="nav-item" v-for="index in years" :key="index">
                      <a
                        v-on:click="fetchData(index)"
                        data-tab="0"
                        class="nav-link"
                        href="javascript:void(0)"
                        v-bind:class="{ active: index == year }"
                      >
                        {{ index }}
                      </a>
                    </li>
                  </ul>
                </b-col>
                <div
                  class="row d-flex flex-row mb-3 justify-content-md-start justify-content-center"
                >
                  <b-col
                    class="d-flex flex-row align-items-center mr-md-3 mr-0"
                  >
                    <label for="search" class="mr-1" style="font-weight: 500">
                      {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                    </label>
                    <b-form-input v-model="filter"></b-form-input>
                  </b-col>
                </div>
                <!--End::Filter -->

                <!--Begin::Count of rows per page -->
                <b-col class="poschodoch-rows-count">
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                      @change="getFormatService().formatSetPerPage(perPage)"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!--End::Count of rows per page -->
              </b-row>

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                ref="table"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filterControl="true"
                @sort-changed="sortChanged"
                :filter-ignored-fields="IgnoreFilds"
                @filtered="onFiltered"
                class="poschodoch-invoices-table"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <!--
                //MBL pomocne
                :filter-function="filterTable"
                @context-changed="filterTable"
                :sort-direction="sortDirection"
              -->
                <template v-slot:cell(Amount)="data">
                  <span v-if="data.value">
                    {{ data.value }} {{ data.item.Currency }}
                  </span>
                </template>
                <template v-slot:cell(AmountPaid)="data">
                  <span v-if="data.value">
                    {{ data.value }} {{ data.item.Currency }}
                  </span>
                </template>
                <template v-slot:cell(AmountIcon)="data">
                  <span
                    v-if="data.item.ContainsOthers > 0"
                    class="poschodoch-note-list"
                    v-b-tooltip.hover
                    :title="tooltip"
                  >
                    <span class="poschodoch-note ml-3"></span>
                  </span>
                </template>
                <template v-slot:cell(DocCount)="data">
                  <div
                    @click.stop
                    class="h-100 w-100 p-2 d-flex align-items-center justify-content-center"
                    style="cursor: default"
                  >
                    <a
                      href="javascript:void(0)"
                      v-if="data.value > 1"
                      v-on:click="
                        showInvoicesDocs(data.item.RecKey, data.item.TableName)
                      "
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      v-if="data.value == 1"
                      v-on:click="
                        showSingleDoc(data.item.RecKey, data.item.TableName)
                      "
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-download poschodoch-icon-medium"></i>
                    </a>
                  </div>
                </template>
                <template v-slot:cell(VS)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="showInvoiceDetail(data.item.Id)"
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                  <span v-if="!(data.item != null)">{{ data.value }}</span>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div
                    @click.stop
                    class="d-flex flex-column align-items-start w-100"
                  >
                    <div
                      class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span
                          v-if="data.value.DateOfRegistration"
                          class="poschodoch-table-title-mobile text-left"
                        >
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.DateOfRegistration,
                            ) + " "
                          }}
                          <a
                            href="javascript:void(0)"
                            v-if="data.item != null"
                            v-on:click="showInvoiceDetail(data.item.Id)"
                            class="kt-link"
                          >
                            {{ data.value.VS }}
                          </a>
                        </span>
                        <span class="text-left">
                          {{ data.value.Customer }}
                        </span>
                        <span v-if="data.value.Description" class="text-left">
                          {{ data.value.Description }}
                        </span>
                        <!-- <span v-if="data.value.PaymentDate">
                          {{ getFormatService().formatDateToLocal(data.value.PaymentDate) }}
                        </span>-->
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span
                          class="poschodoch-invoices-amount"
                          v-if="data.item.ContainsOthers == 0"
                        >
                          {{
                            addCurrencyWSpace(
                              getFormatService().formatNumberToLocal(
                                data.value.Amount,
                              ),
                            )
                          }}
                        </span>
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount > 1"
                            v-on:click="
                              showInvoicesDocs(
                                data.item.RecKey,
                                data.item.TableName,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.RecKey,
                                data.item.TableName,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Invoices-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import InvoicesDocs from "@/views/pages/domus/InvoicesDocs.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";
import GridService from "@/common/grid.service";

export default {
  name: "invoices",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    InvoicesDocs,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      invoicesDocs: null,
      items: null,
      tooltip: "Faktúra pre viac domov",
      fields: [
        {
          key: "VS",
          label: this.$i18n.t("INVOICES.TABLE.VS"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DateOfRegistration",
          label: this.$i18n.t("INVOICES.TABLE.DATEOFREGISTRATION"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Customer",
          label: this.$i18n.t("INVOICES.TABLE.CUSTOMER"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Description",
          label: this.$i18n.t("INVOICES.TABLE.DESCRIPTION"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Amount",
          label: this.$i18n.t("INVOICES.TABLE.AMOUNT"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (item.ContainsOthers > 0) {
              value = null;
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "AmountPaid",
          label: this.$i18n.t("INVOICES.TABLE.AMOUNTPAID"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value, key, item) => {
            if (item.ContainsOthers > 0) {
              value = null;
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "AmountIcon",
          label: "",
          sortable: false,
          class: "table-no-mobile text-center",
        },
        {
          key: "PaymentDate",
          label: this.$i18n.t("INVOICES.TABLE.PAYMENTDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Services",
          label: this.$i18n.t("INVOICES.TABLE.SERVICES"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center p-0 table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      currentPageBack: null,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
      year: null,
      years: null,
      sortBy: "",
      sortDirection: "",
      filter: null,
      filterOld: null,
      filterOn: [],
      filteredItems: null,
      sortDesc: false,
      IgnoreFilds: ["Id"],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    let bContent = GridService.getGridFilter("invoices");
    if (bContent) {
      this.currentPage = bContent.currentPage;
      this.currentPageBack = bContent.currentPage;
      this.year = bContent.year;
      this.filter = bContent.filter;
      this.sortBy = bContent.sortBy;
      this.sortDesc = bContent.sortDesc;
    }
    this.fetchData();
  },
  /* MBL watcher na premenu filter
  watch: {
    filter: {
        handler: function () {
              this.$refs.table.refresh()
        },
        deep: true
    }
  },*/
  methods: {
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
    },
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showInvoiceDetail(record.Id);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    showInvoicesDocs(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=183";

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDocs = data;
        this.$bvModal.show("modal-invoices-docs");
      });
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.invoicesDocs = data;

        parameters =
          "?docId=" + this.invoicesDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.invoicesDocs.Documents[0].FileName;

          FileService.downloadFile(fileName, binaryContent);
        });
      });
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    addCurrencyWSpace(data) {
      return (
        " " +
        data +
        "\u00A0" +
        FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      if (this.currentPageBack) {
        this.currentPage = this.currentPageBack;
      } else {
        this.currentPage = 1;
      }
    },
    /*    filterTable(){      
    },*/
    Search() {
      //this.totalRows = this.items.length;
    },
    showInvoiceDetail(invoiceID) {
      let postContent = {
        name: "invoices",
        year: this.year,
        currentPage: this.currentPage,
        filter: this.filter,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      };
      GridService.setGridFilter(postContent);

      this.$router.push({
        name: "domus-invoices-detail",
        query: { menuId: this.menuId, invoiceId: invoiceID },
      });
    },
    setYearRange(yearFrom, yearTo) {
      if (this.years == null) {
        this.years = [];
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.year != null) parameters += "&year=" + this.year.toString();

      window.open("/invoicesPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("INVOICES.TABLE.VS"),
        this.$i18n.t("INVOICES.TABLE.DATEOFREGISTRATION"),
        this.$i18n.t("INVOICES.TABLE.CUSTOMER"),
        this.$i18n.t("INVOICES.TABLE.DESCRIPTION"),
        this.$i18n.t("INVOICES.TABLE.AMOUNT"),
        this.$i18n.t("INVOICES.TABLE.PAYMENTDATE"),
        this.$i18n.t("INVOICES.TABLE.SERVICES"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.VS,
          FormatService.formatDateToLocal(item.DateOfRegistration),
          item.Customer,
          item.Description,
          FormatService.formatNumberToLocalForCSV(item.Amount),
          FormatService.formatDateToLocal(item.PaymentDate),
          item.Services,
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + `_${this.date}.csv`;

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(selectedYear = null) {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();

      if (this.year != null) parameters += "&year=" + this.year.toString();

      ApiService.setHeader();
      ApiService.get("Object", "Invoices" + parameters).then(({ data }) => {
        this.transformData(data.Invoices);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Invoices;
        //this.currentPage = 1;
        this.totalRows = this.items.length;

        this.year = data.Year;
        this.setYearRange(data.YearFrom, data.YearTo);
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        //if (items[i].AmountPaid == 0 ){items[i].AmountPaid ="0"}

        if (!items[i].AmountPaid) {
          items[i].AmountPaid = "0";
        }
        if (items[i].ContainsOthers > 0) {
          items[i].Amount = null;
          items[i].AmountPaid = null;
        }
        items[i].PaymentDateLocale = FormatService.formatDateToLocal(
          items[i].PaymentDate,
        );
        items[i].DateOfRegistrationLocale = FormatService.formatDateToLocal(
          items[i].DateOfRegistration,
        );
        items[i].AmountLocale = FormatService.formatNumberToLocal(
          items[i].Amount,
        );
        items[i].AmountPaidLocale = FormatService.formatNumberToLocal(
          items[i].AmountPaid,
        );

        items[i].Mobile = {
          VS: items[i].VS,
          DateOfRegistration: items[i].DateOfRegistration,
          Customer: items[i].Customer,
          Description: items[i].Description,
          Amount: items[i].Amount,
          AmountPaid: !items[i].AmountPaid ? 0 : items[i].AmountPaid,
          PaymentDate: items[i].PaymentDate,
          Services: items[i].Services,
          DocCount: items[i].DocCount,
        };
      }
    },
  },
};
</script>
