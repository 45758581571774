<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <!--Begin::connected accounts-->
      <KTPortlet>
        <!--Begin::Data -->
        <template v-slot:body>
          <b-container fluid v-if="connectedAccountsText">
            <div
              class="h-100 w-100 d-flex align-items-center justify-content-center flex-column"
            >
              <div style="white-space: pre-line">
                {{ connectedAccountsText }}
              </div>
              <b-button
                variant="primary"
                class="mt-3"
                @click="$router.push({ name: 'dashboard' })"
              >
                {{ $t("AUTH.GENERAL.CONTINUE") }}
              </b-button>
            </div>
          </b-container>
          <!--Begin::Loader -->
          <b-container fluid v-if="!connectedAccountsText">
            <Loader />
          </b-container>
          <!--End::Loader -->
        </template>
      </KTPortlet>
      <!--End::connected accounts-->
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import Loader from "@/views/components/Loader.vue";

export default {
  name: "connectedAccounts",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      connectedAccounts: null,
      connectedAccountsText: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      ApiService.setHeader();
      ApiService.get("Dashboard", "CompanyInfo").then(({ data }) => {
        this.connectedAccounts = data.ConnectedAccounts;
        this.connectedAccountsText = data.ConnectedAccountsText;
      });
    },
  },
};
</script>
