// Slovak
export const locale = {
  MENU: {
    NEW: "nové",
    UNIT_LIST: "Moje priestory",
  },
  LAYOUT: {
    FOOTER: {
      LEGAL: "Všeobecné podmienky portálu",
      PRIVACY: "Pravidlá ochrany osobných údajov",
      LEGAL_A: "Podmienky prevádzkovateľa",
      LEGAL_Z: "Podmienky poskytovateľa",
      DATETO: "Údaje aktuálne k:",
    },
    FOOTER_LOGIN: {
      LEFT_1: "ANASOFT APR, spol. s.r.o.",
      LEFT_2: "Mlynská dolina 41, 811 02 Bratislava, Slovenská republika",
      LEFT_3: "tel: +421 2 3223 4484",
      RIGHT_1: "ISO 9001, 14001, 27001, 20000-1",
      RIGHT_2: "IČO: 313 615 52; IČ DPH: SK2020345778",
      RIGHT_3:
        "Spoločnosť registrovaná Mestským súdom Bratislava III, Odd. Sro, vložka č. 6042/B",
    },
    FOOTER_LOGIN_CZ: {
      LEFT_1: "ANASOFT s.r.o.",
      LEFT_2: "Hrnčířská 2985, 470 01 Česká Lípa, Česká republika",
      LEFT_3: "tel: +420 481 130 100",
      RIGHT_1: "IČO: 499 04 949; DIČ: CZ49904949",
      RIGHT_2:
        "Spoločnosť zapísaná v obch. registri vedenom Krajským súdom v Ústí nad Labem, odd. C, vl. 25247",
      RIGHT_3: "",
    },
    FOOTER_LOGIN_ENBRA: {
      LEFT_1: "ENBRA SLOVAKIA s.r.o.",
      LEFT_2: "Zvolenská cesta 29, 97401 Banská Bystrica, Slovenská republika",
      LEFT_3: "tel: +421 2 434 141 46",
      RIGHT_1: "IČO: 31624189; IČ DPH: SK2020456119",
      RIGHT_2:
        "Spoločnosť registrovaná Okresným súdom Banská Bystrica, Odd. Sro, vložka č. 2587/S",
      RIGHT_3: "",
    },
    FOOTER_LOGIN_ENBRA_CZ: {
      LEFT_1: "ENBRA, a.s.",
      LEFT_2: "Durďákova 5, 613 00 Brno, Česká republika",
      LEFT_3: "tel: +420 533 03 99 03",
      RIGHT_1: "IČO: 44015844; DIČ: CZ44015844",
      RIGHT_2:
        "Spoločnosť zapísaná v obch. registri vedenom Krajským súdom v Brne, odd. B, vl. 6085",
      RIGHT_3: "",
    },
    FOOTER_LOGIN_PENTA: {
      LEFT_1: "Penta Real Estate Residential Services s.r.o.",
      LEFT_2:
        "Digital Park II, Einsteinova 25, 85101 Bratislava, Slovenská republika",
      LEFT_3: "Tel:  +421 2 577 88 111",
      RIGHT_1: "",
      RIGHT_2: "",
      RIGHT_3: "",
    },
    PRINT_HEADER: {
      PRINT_DATE: "Dátum tlače: ",
    },
  },
  AUTHJS: {
    DB_NOT_AV: "Databáza správcu je z technických príčin nedostupná",
    SOMETHINGWRONG: "Niečo sa pokazilo. Pracujeme na tom...",
    WRONGREGCODE: "Nesprávny registračný kód.",
    NOTALLOWED:
      "Prístup nepovolený! Kontaktujte, prosím, Vašho správcu Spokojné bývanie",
    ACCNOTVALID: "ÚČet nie je validný.",
  },
  COMMON: {
    LOADER: {
      LOADING_DATA_FROM_SERVER: "Načítavam údaje zo servera...",
    },
    LIST: {
      ROW_COUNT_PER_PAGE: "Záznamov na stránku",
      NO_RECORDS: "Neboli nájdené žiadne záznamy",
      NO_RECORDS_FILTERED:
        "Neboli nájdené žiadne záznamy spĺňajúce zadané kritériá",
    },
    MONTHS: {
      MONTH_1: "Január",
      MONTH_2: "Február",
      MONTH_3: "Marec",
      MONTH_4: "Apríl",
      MONTH_5: "Máj",
      MONTH_6: "Jún",
      MONTH_7: "Júl",
      MONTH_8: "August",
      MONTH_9: "September",
      MONTH_10: "Október",
      MONTH_11: "November",
      MONTH_12: "December",
    },
  },
  FACEBOOK: {
    TITLE: "Prihlásenie prostredníctvom facebooku",
  },
  GOOGLE: {
    TITLE: "Prihlásenie prostredníctvom googlu",
  },
  TABLE_CONTEXT_MENU: {
    PRINT: "Tlačiť",
    EXPORT_TO_CSV: "Export do CSV",
    DOWNLOAD: "Stiahnuť",
  },
  UNIT_LIST: {
    TITLE: "Zoznam priestorov",
    CHANGE_BUTTON: "Zmeniť",
    SELECT_BUTTON: "Vybrať",
    CANCEL_BUTTON: "Odpojiť",
    TABLE: {
      VAR_SYMBOL: "Var. symbol / Objekt",
      PROPERTY_COMPANY: "Správca",
      ADDRESS: "Adresa",
      TYPE: "Typ priestoru / Funkcia",
      UNIT_NUMBER: "Číslo bytu / objektu",
    },
  },
  ACCOUNT: {
    TABLE: {
      PERIOD: "Mesiac",
      NAME_OF_MOVEMENT: "Typ pohybu",
      DETAIL_OF_MOVEMENT: "Detail pohybu",
      DUE_DATE: "Dátum splat. / úhrady",
      AMOUNT: "Suma",
      BALANCE: "Zostatok",
    },
    BALANCEON: "Zostatok k ",
    DASHBOARD_TOOLTIP:
      "Zostatok na Vašom účte u správcu (predpisy, platby, vyúčtovanie). Kladný zostatok znamená preplatok, záporný nedoplatok.",
  },
  PAYMENTORDER: {
    TABLE: {
      ITEM: "Položka",
      AMOUNT: "Suma",
      FOOTER: "Spolu",
    },
  },
  DECISIONS: {
    TABLE: {
      ACCEPTANCEWAY: "Prijaté",
      VALIDITY: "Platnosť",
      DESCRIPTION: "Popis",
      DATE: "Dátum",
    },
  },
  PAYMENTREMINDEROVERVIEW: {
    TABLE: {
      PAYMENTREMINDERTYPE: "Typ",
      ARREAR: "Nedoplatok",
      PENALTYPAYMENT: "Penále",
      POSTAGECHARGE: "Poštovné",
      ISSUEDATE: "Dt. vystavenia",
      DUEDATE: "Dt. splatnosti",
      ARREARDATE: "Dt. nedoplatku",
      STATUS: "Stav",
      SMSDELIVERYDATE: "Dt. doručenia SMS",
      SMSDELIVERED: "SMS doručená",
      PAYMENTDATE: "Dt. úhrady",
      DATETO: "k dátumu",
      USER: "Užívateľ",
      VS: "VS",
    },
    TOOLTIP: {
      STATUS: "Stav upomienky",
      PAYMENTREMINDERTYPE: "Typ upomienky",
      VS: "Variabilný symbol",
      USER: "Titul, meno a priezvisko užívateľa priestoru",
      ISSUEDATE: "Dátum vystavenia",
      DUEDATE: "Dátum splatnosti",
      ARREARDATE: "Dátum nedoplatku",
      PAYMENTDATE: "Dátum úhrady",
      SMSDELIVERYDATE: "Dátum doručenia SMS",
    },
    PARAMETER: {
      DATETO: "k dátumu:",
      BUTTON: "Načítať",
    },
  },
  METERREADINGS: {
    TABLE: {
      METERNUMBER: "Merač",
      METERTYPE: "Druh merača",
      CLIMBINGIRON: "Umiestnenie",
      ASSEMBLYDATE: "Dátum montáže",
      DISASSEMBLYDATE: "Dátum demontáže",
      HISTORY: "História",
      VERIFICATIONDATE: "Platný do",
      SEALS: "Plomby",
      LASTREADINGDATE: "Dátum posledného odpisu",
      LASTREADINGDATEMOBILE: "Posledný odpis",
    },
    CHECKBOXLABEL: "Zobraziť aj demontované merače",
    CHECKBOXDAILY: "Zobraziť aj denné odpočty",
    CHECKBOXLABEL_NEGATIVE: "Nezobrazujú sa demontované merače",
    CHECKBOXLABEL_POSITIVE: "Zobrazujú sa aj demontované merače",
  },
  ANNUALBILLING: {
    TABLE: {
      NAME: "Obdobie vyúčtovania",
      PAYMENTORDER: "Predpis",
      COST: "Náklad",
      DIFFERENCE: "Rozdiel",
      TEXT: "Výsledok",
      BALANCEDATE: "Stav konta (k dátumu)",
      ACCOUNTBALANCEBEFORE: "Stav konta",
      FINALBALANCE: "Celkový zostatok",
      PERIODID: "ID obdobia",
      ACCOUNT: "Konto",
      GROUP: "Skupina",
      ACCOUNTTYPE: "Typ konta",
      DOCCOUNT: "Počet dokumentov",
    },
  },
  OBJECTOFFICIALS: {
    TABLE: {
      ORDER: "Poradie",
      OBJECT: "Objekt",
      NAME: "Meno",
      PHONE: "Telefón / Mobil",
      EMAIL: "Email",
      ADDRESS: "Adresa",
      TYPE: "Typ",
      FUNCTION: "Funkcia",
      PHOTO: "Fotka",
      WWW: "Link",
    },
  },
  INSPECTION: {
    TABLE: {
      INSPECTIONID: "ID revízie",
      OBJECT: "Objekt",
      NAME: "Názov revízie",
      VALIDTO: "Platnosť do",
      LASTINSPECTION: "Posledná revízia",
      DEFECTS: "Nájdené závady",
      DEFECTSALL: "Závady (nájdené / neodstránené)",
      TODO: "Neodstránené závady",
      STATE: "Stav",
    },
  },
  BUILDINGELEMENTS: {
    TABLE: {
      NAME: "Konštrukčný prvok",
      COUNT: "Počet",
      COST: "Náklad",
      WARRANTYDATE: "Záruka do",
      STATEOFELEMENTS: "Stav",
      NOTE: "Poznámka",
    },
  },
  COSTS: {
    TABLE: {
      CODE: "Kód",
      ANALYSIS: "Služba",
      UNITOFMEASURE: "Merné jednotky",
      CONSUMPTION: "Spotreba",
      AMOUNT: "Náklad",
    },
  },
  BIDS: {
    TABLE: {
      DOCNAME: "Názov dokumentu",
      FILENAME: "Cesta k súboru",
      DOCID: "ID dokumentu",
      CATEGORY: "Výberové konanie",
      SOURCENAME: "Zdroj",
      CATEGORY2: "Názov",
      STATUS: "Stav",
      DATEFROM: "Dátum od",
      DATETO: "Dátum do",
      FROM: "od ",
      TO: "do ",
    },
    FILTER: {
      DOC_NAME: "Vyhľadať dokument",
      CATEGORY: "Výberové konanie",
      PLACEHOLDER: "Všetky",
    },
  },
  REPAIRFUND: {
    TABLE: {
      AMOUNT: "Suma",
      DATE: "Dátum",
      EVIDENCE: "Doklad",
      DESCRIPTION: "Popis",
    },
    FILTER: {
      GATE: "Vchod",
      VS: "Variabilný symbol",
      PLACEHOLDER: "Všetky",
      VIEW: "Zobraziť",
    },
    TITLEADD: "Zostatok k ",
    FINALBALANCE: "Konečný stav",
    PARAMETER_YEAR: "za Rok:",
    VIEWOPTIONS: {
      1: "Tvorba a čerpanie spolu",
      2: "Tvorba",
      3: "Čerpanie",
      4: "Najprv tvorba a potom čerpanie",
    },
    OPENBALANCE: "Počiatočný stav",
    FINBALANCE: "Konečný stav",
    PRODUCTION: "Tvorba",
    DRAWING: "Čerpanie",
    PERIOD: "Obdobie",
    MONTHS: "Mesiace",
    BALANCE: "Zostatok",
  },
  INVOICES: {
    TABLE: {
      ID: "ID",
      OBJECT: "Centrum",
      CUSTOMER: "Dodávateľ / Odberateľ",
      AMOUNT: "Suma",
      AMOUNTPAID: "Zaplatená suma",
      DATEOFREGISTRATION: "Dátum evidencie",
      DUEDATE: "Dátum splatnosti",
      PAYMENTDATE: "Dátum platby",
      SERVICES: "Služby",
      INVOICEROWS: "Riadky faktúry",
      ANALYSEROWS: "Riadky analýzy",
      CURRENCY: "Mena",
      DOCID: "Doc ID",
      DESCRIPTION: "Popis",
      VS: "Variabilný symbol",
    },
  },
  OBJECTACCOUNT: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Popis účtu",
      BANK: "Banka",
      FINALBALANCE: "Konečný stav",
      BANKACCOUNT: "Číslo účtu",
      ISSUEDATE: "Stav k dátumu",
      ISSUEDATEMOBILE: "Stav k",
    },
  },
  SHEETS: {
    OPTIONS: {
      ALL: "Všetci",
      OWNERS: "Vlastníci",
      TENANTS: "Nájomníci",
      ALLB: "Všetky",
      NOB: "Nebyty",
      YESB: "Byty",
      PERC: "Percentom",
      FRAC: "Čitateľ/Menovateľ",
      HIDE: "Nezobrazovať",
    },
  },
  VOTINGSHEET: {
    TABLE: {
      VS: "Variabilný symbol",
      NAME: "Meno",
      OBJECTNUMBER: "Číslo objektu",
      OBJECTADDRESS: "Adresa objektu",
      GATENUMBER: "Číslo brány",
      GATEADDRESS: "Adresa brány",
      APARTMENTNR: "Číslo priestoru",
      SIGNATURE: "Podpis",
      YES: "Súhlasím",
      NO: "Nesúhlasím",
      YESYES: "Áno",
      NONO: "Nie",
    },
    PLACEHOLDER1: "Názov hlasovania",
    PLACEHOLDER2: "Bližší popis hlasovania",
    NAME: "Hlasovací hárok",
  },
  SHEETINPUT: {
    USERS: "Užívatelia",
    ROOMS: "Priestory",
    PORTIONS: "Podiel",
    DATE: "Dátum k",
    BUTTON: "Zobraziť",
    TEXT1: "Názov",
    TEXT2: "Popis",
  },
  SIGNATURESHEET: {
    TABLE: {
      VS: "Variabilný symbol",
      APARTMENTNR: "Číslo priestoru",
      NAME: "Meno a priezvisko",
      GATENUMBER: "Číslo brány",
      GATEADDRESS: "Adresa brány",
      PORTION: "Spoluvlastnícky podiel",
      SIGNATURE: "Podpis",
    },
    PLACEHOLDER1: "Názov podpisového hárku",
  },
  ACCOUNTPROFILE: {
    DROPDOWNUSER: {
      ADDSPACE: "Pridanie priestoru",
      CANCELSPACE: "Odpojenie priestoru",
      NOTIFICATIONSETTINGS: "Nastavenie upozornení",
      PROFILE: "Profil",
      INPROGRESS: "",
    },
    NOTE:
      "Zmena týchto kontaktných údajov v profile účtu neupravuje automaticky kontaktné údaje u Vášho správcu. Pre nahlásenie zmien správcovi použite odkaz Moje požiadavky.",
    TITLE: "Profilové údaje",
    EMAIL: "Email",
    EMAIL_NEW: "Nový email",
    PHONE: "Telefónne číslo",
    PHONE_NEW: "Nové telefónne číslo",
    PWD: "Heslo",
    PWD_NEW: "Nové heslo",
    PWD_OLD: "Pôvodné heslo",
    PWD_NEWCONFIRM: "Zopakovať nové heslo",
    PLACEHOLDER_PWD: "Vaše nové heslo",
    REDIRECT:
      "Vaše konto bolo zrušené. Budete presmerovaný na úvodnú stránku... ",
    REDIRECT2:
      "Registrácia prehehla úspešne. Budete presmerovaný na úvodnú stránku, prihláste sa... ",
    CODE: "Kód",
    VALIDATION: {
      PWD_CONFIRM: "Nové heslá sa musia zhodovať",
      PWD:
        "Heslo musí mať aspoň 8 znakov a zároveň obsahovať aspoň 1 znak z nasledovných 3-och kategórii: Číslo, Velké a malé písmeno",
      PHONE:
        "Číslo je povinné. Musí mať dĺžku minimálne 10 znakov, čísla pre Slovenskú a Českú republiku (+421/+420) musia byť platné čísla u daných operátorov.",
      EMAIL: "Email musí byť validná emailová adresa",
    },
    BUTTON: {
      EMAIL: "Zmeniť email",
      EMAIL2: "Aktivovať prihlásenie emailom",
      PHONE: "Zmeniť telefónne číslo",
      PWD: "Zmeniť heslo",
      PHOTOUPLOAD: "Nahrať fotografiu",
      PHOTODELETE: "Zmazať fotografiu",
      DISCONNECT: "Odpojiť",
      CONNECT: "Pripojiť",
      DELETE_ACCOUNT: "Zrušiť konto",
      CODE: "Potvrdiť verifikačný kód",
      OK: "OK",
    },
    ALERT: {
      EMAIL: "Zadali ste email v nesprávnom formáte",
      PHONE: "Zadali ste Tel. číslo v nesprávnom formáte (+421/+420)",
      PWD:
        "Zadali ste heslo v nesprávnom formáte (Min. 8 znakov, 1 velké písmeno, 1 malé písmeno, 1 číslo)",
    },
    SOCIAL: {
      GOOGLE_ON: "Prihlásený cez Google",
      GOOGLE_OFF: "Prihlásenie cez Google",
      FACEBOOK_ON: "Prihlásený cez Facebook",
      FACEBOOK_OFF: "Prihlásenie cez Facebook",
    },
    OPERATIONS: {
      SEC1: {
        TITLE: "Zmena emailu",
        TEXT:
          "Uveďte nový email na ktorý se Vám zašle verifikačný kód a potvrďte ho heslom. Zmena týchto kontaktných údajov v profile účtu neupravuje automaticky kontaktné údaje u Vášho správcu. Pre nahlásenie zmien správcovi použite odkaz Moje požiadavky.",
      },
      SEC2: {
        TITLE: "Zmena telefónneho čísla",
        TEXT:
          "Uveďte nové telefonne číslo na ktoré se Vám zašle verifikačný kód a potvrďte ho heslom. Zmena týchto kontaktných údajov v profile účtu neupravuje automaticky kontaktné údaje u Vášho správcu. Pre nahlásenie zmien správcovi použite odkaz Moje požiadavky.",
      },
      SEC3: {
        TITLE: "Zmena hesla",
        TEXT:
          "Uveďte pôvodné heslo, nové heslo a potvrďte to nové jeho opakovaným zadaním",
      },
      SEC4: {
        TITLE: "Aktivovať prihlásenie emailom",
        TEXT: "Vytvorením hesla sa budete môcť hlásiť pomocou emailu a hesla",
      },
      SEC5: {
        TITLE: "Zrušenie konta",
        TEXT:
          "Zrušením konta stratíte prístup k údajom o priradených priestoroch. Prístup k nim získate len novou registráciou. Skutočne si želáte zrušiť konto?",
      },
      SECCODE: {
        TITLE: "Verifikácia kódom",
        TEXT_SMS: "Uveďte verifikačný kód doručený cez SMS:",
        TEXT_EMAIL: "Uveďte verifikačný kód doručený emailom:",
      },
      SECPWDCFM: {
        TITLE: "Zmena hesla email",
        TEXT: "Na Váš email sme Vám zaslali link na zmenu hesla",
      },
    },
    CONNECTEDACCOUNTS:
      "K nasledovným priestorom pristupujú aj iné kontá ako vaše:",
    TITLEPHOTO: "Profilová fotografia",
    TITLEPHOTODESC1: "Fotografiu zmeníte nahratím novej",
    TITLEPHOTODESC2: "Veľkosť fotografie môže byť ",
    TITLEPHOTODESC3: "maximálne 2MB",
    CONSENTPHOTO:
      "Zaškrtnutím tohto poľa súhlasím s tým, že sa bude moja fotografia zobrazovať pri mojich kontaktných údajoch v rámci bytového domu v systéme DOMUS.",
    CONSENTFULL:
      "„Zaškrtnutím poľa používateľ udeľuje Prevádzkovateľovi osobných údajov súhlas na spracúvanie osobných údajov v rozsahu jeho fotografie, berie na vedomie a súhlasí s tým, že jeho fotografia bude zverejnená pri kontaktných údajoch v dome za účelom identifikácie a bude prístupná osobám v dome zamestnancom správcu,",
    CONSENTFULL_2:
      " počas trvania funkcie, do ktorej bola dotknutá osoba vymenovaná alebo do času, kým sa rozhodne zverejnenie fotografie ukončiť a to odkliknutím tohto poľa. Tento súhlas je možné kedykoľvek odvolať rovnakým spôsobom akým bol udelený.“ Viac informácii nájdete v ",
    CONSENTFULL_LINK: "Pravidlá ochrany osobných údajov.",
  },
  EVENTS: {
    TABLE: {
      SUBJECT: "Predmet",
      RESPONSIBLE: "Zodpovedný",
      DATE: "Dátum",
      TYPE: "Typ",
    },
    SEARCH: "Vyhľadať podľa predmetu",
    FROM: "od: ",
    TO: "do: ",
    PRIORITY: {
      TEXT: "Priorita",
      1: "Nízka",
      2: "Stredná",
      3: "Vysoká",
    },
  },
  IDENTITY: {
    TITLE: "Prevzatie identity",
    FILTER: {
      ALL: "Všetky",
      USERS: "Užívatelia",
      OFFICIALS: "Funkcionári",
      ADMINS: "Správcovia",
      PLACEHOLDERCUSTOMER: "Zvoľte kód zákazníka",
    },
    LABEL: {
      CUSTOMER: "Zákazník",
      ACCESS: "Typ prístupu",
      SEARCH: "Hľadať",
    },
    ADMINS_TABLE: {
      TITLE: "Správcovia",
      CODE: "Kód zákazníka",
      LOGINNAME: "Login",
      NAME: "Meno",
      SURNAME: "Priezvisko",
    },
    OBJECTS_TABLE: {
      TITLE: "Funkcionári",
      NAME: "Meno",
      SURNAME: "Priezvisko",
      VCS: "Číslo objektu",
      ACCESSFROM: "Prístup od",
      ACCESSTO: "Prístup do",
      ADDRESS: "Ulica",
      CITY: "Mesto",
    },
    USERS_TABLE: {
      TITLE: "Užívatelia",
      NAME: "Meno",
      SURNAME: "Priezvisko",
      VS: "Var. symbol",
      ACCESSFROM: "Prístup od",
      ACCESSTO: "Prístup do",
      ADDRESS: "Ulica",
      CITY: "Mesto",
      TYPE: "V/N",
    },
  },
  METERREADING_DETAIL: {
    TABLE: {
      METERNUMBER: "Číslo merača",
      METERTYPE: "Typ merača",
      CLIMBINGIRON: "Umiestnenie",
      STATE: "Stav",
      READINGDATE: "Dátum odpisu",
      READINGCODE: "Kód odpisu",
      DESCRIPTION: "Popis",
      LINK: "Fotka",
    },
  },
  ANNUALBILLING_DETAIL: {
    AMOUNT: "Spolu",
    CUSTOMIZEDAMOUNT: "Spolu po úprave nákladov",
    ACCOUNTBALANCETO: "Stav konta k ",
    TABLE: {
      ITEM: "Položka",
      PAYMENTORDER: "Predpis",
      COST: "Náklad",
      DIFFERENCE: "Rozdiel",
      ORDER: "Príkaz",
    },
  },
  INSPECTION_DETAIL: {
    TABLE: {
      DATE: "Dátum",
      VALIDTO: "Platnosť do",
      NAMEOFENGINEER: "Vykonal",
      EXPENSE: "Náklady",
      DEFECTS: "Nájdené závady",
      DEFECTSALL: "Závady (nájdené / neodstránené)",
      TODO: "Neodstránené závady",
      LASTINSPECTION: "Dátum odstránenia",
      STATE: "Stav",
    },
  },
  OBJECTACCOUNT_DETAIL: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Popis účtu",
      CURRENCY: "Mena",
      INITIALBALANCE: "Počiatočný stav",
      INITIALDATE: "Počiatočný dátum",
      RELATIVEBALANCE: "Relatívny stav",
      FINALBALANCE: "Konečný stav",
      BANKACCOUNT: "Účet (IBAN)",
      BANK: "Banka",
      ISSUEDATE: "Dátum vydania",
      VS: "Variabilný symbol",
      TEXT: "Text",
      DATE: "Dátum",
      AMOUNT: "Suma",
      DESCRIPTION: "Popis",
    },
    TITLEADD: "Stav k ",
    PARAMETER_YEAR: "Rok:",
    PARAMETER_MONTH: "Mesiac:",
    PARAMETER_REPORT: "Zobrazovať nepotvrdené výpisy",
  },
  INVOICE_DETAIL: {
    VS: "Variabilný symbol",
    CUSTOMER: "Dodávateľ",
    INV_DESC: "Predmet",
    AMOUNT: "K úhrade",
    AMOUNTPAID: "Zaplatená suma",
    DOCS: "Dokumenty",
    CUSTOMERDF: "Dodávateľ",
    CUSTOMEROF: "Odberateľ",
    TYPE: "Typ faktúry",
    TABLE: {
      DELIVERYPOINT: "Odberné miesto",
      NAME: "Služba/Popis",
      VALUE: "Suma",
      AMOUNT: "Množstvo",
      DATEFROM: "Dátum od",
      DATETO: "Dátum do",
      DATEFROM_MOBILE: "Od",
      DATETO_MOBILE: "do",
      READINGCODE: "Kód odpočtu",
      ROWDESC: "Popis",
      HOUSE: "Stredisko",
      GATE: "Osob. účet",
      INVOICE_ROWS: "Riadky faktúry",
      INVOICE_ANALYSIS_ROWS: "Technické údaje",
    },
  },
  COST_DETAIL: {
    TABLE: {
      SUBSCRIBERSITE: "Odberné miesto",
      BUDGET: "Rozpočet",
      CONSUMPTION: "Spotreba",
      AMOUNT: "Suma",
      DATEFROM: "Dátum od",
      DATETO: "Dátum do",
      ANALYSIS: "Služba",
      UNITOFMEASURE: "Merná jednotka",
      CURRENCY: "Mena",
      MARK: "Známka",
      DATE: "Dátum",
      TYPEOFSOURCE: "Typ zdroja",
      DATEFROM_MOBILE: "Od",
      DATETO_MOBILE: "do",
    },
  },
  MOVEMENT_DETAIL: {
    TITLE: "Detail pohybu",
    ACCOUNT_NR: "Číslo účtu",
    AMOUNT: "Suma",
    IBAN: "IBAN",
    VAR_SYMBOL: "Variabilný symbol",
    TABLE_ITEMS: {
      TITLE: "Položky",
      DESCRIPTION: "Popis",
      AMOUNT: "Suma",
    },
    TABLE_ASSIGNMENTS: {
      TITLE: "Priradenia",
      DESCRIPTION: "Popis",
      AMOUNT: "Suma",
    },
  },
  CUSTOMERMAIL: {
    BACK: "Späť",
    SAVEBUTTON: "Uložiť",
    SENDBUTTON: "Odoslať",
    TABLE: {
      EMAILSUBJECT: "Predmet",
      EMAILCONTENT: "Obsah",
      EMAILDETAIL: "Zoznam adries",
      DATEIINSTRUCTIONTOSENDEMAIL: "Dátum odoslania",
      DATELASTCHANGE: "Dátum zmeny",
      EMAILCOUNTALL: "Počet emailov",
      EMAILCOUNTSENT: "Počet odoslaných emailov",
      EMAILSENT: "Email bol odoslaný",
      EMAILUNSENT: "Email nebol odoslaný",
      NOTSENT: "Neodoslaný",
      TITLE: "Hromadný email",
      EMAIL: "Email",
      DATESENT: "Dátum odoslania",
      SHOWUNSENTONLY: "Zobraz len neodoslané",
      SEARCH: "Hľadaj email...",
    },
    ERRORS: {
      SUBJECT_REQUIRED: "Predmet emailu je povinné pole",
      CONTENT_REQUIRED: "Obsah emailu je povinné pole",
    },
    LIMITUSERS: "Posielať len užívateľom mimo paušálu a mimo kontroly spotreby",
    NEWMAIL: "Nový email",
    CONFIRMTITLE:
      "Táto akcia odošle e-mail všetkým príjemcom. Pred pokračovaním potvrďte.",
    CONFIRMSEND:
      "Operácia odoslania je nevratná a po potvrdení hromadne odosiela emaily. Naozaj chceš pokračovať?",
  },
  DOCUMENTS: {
    FILTER: {
      DOC_NAME: "Vyhľadať dokument",
      CATEGORY: "Kategória",
      PLACEHOLDER: "Všetky",
    },
    TABLE: {
      DOC_NAME: "Názov",
      CREATION_DATE: "Nahraté",
      CATEGORY: "Kategória",
      DOWNLOAD: "Sťiahnuť",
      SERVICE: "Odoslanie",
      DATECRE: "Dátum vystavenia",
      DOC_NAME2: "Názov prílohy",
      CREATED_BY: "Pridal",
    },
    UPLOAD: {
      HINT:
        "Jednotlivá príloha nesmie prekročiť 15MB. V prípade, že chcete upraviť názov dokumentu, môžete tak spraviť v tabuľke po jeho vybratí.",
      UPLOAD_NEW_FILES: "Pridať dokument",
      UPLOAD_TEXT: "Nahrať",
      DOC_NAME: "Názov dokumentu",
      TOOBIGFILE: "Dokument je príliš veľký (viac ako 15MB)",
      EXTENSION: "Prípona",
      STATUS: "Stav",
      READY: "Pripravený",
      OK: "Nahraný",
      UPLOADEDFILESCOUNT: "Počet vložených dokumentov: ",
      DELETEQUESTION: "Naozaj chcete zmazať tento dokument?",
      READYTOBEUPLOADED: "súborov pripravených na nahratie...",
      NOTUPLOADEDFILESALERT: "Mate nenahrané súbory. Chcete pokračovať?",
      UPLOADING: "Nahrávam...",
      CATEGORY_PLACEHOLDER: "Vyber kategóriu...",
    },
  },
  REQUEST_LIST: {
    TABLE: {
      SUBJECT: "Predmet",
      NAME: "Nahlásil",
      REQUEST_TYPE: "Typ",
      REQUEST_STATE: "Stav",
      CREATION_DATE: "Dátum odoslania",
      DATE_OF_CHANGE: "Dátum zmeny",
      REQUEST_ID: "Číslo",
    },
    FILTER: {
      ALL: "Všetky požiadavky",
      HOUSE: "Požiadavky vystavené na dom",
      USER: "Požiadavky iných užívateľov",
    },
    NEWREQUEST: "Nová požiadavka",
    TITLEUSER: "Zoznam mojich požiadaviek",
    TITLEHOUSE: "Zoznam požiadaviek domu",
    CATEGORY: "Typ požiadaviek",
  },
  REQUEST_DETAIL: {
    TITLE: "Detail požiadavky",
    TYPE: "Typ požiadavky",
    DESCRIPTION: "Obsah",
    SUBJECT: "Predmet",
    FILE: "Súbor s prílohou",
    CONTACTMAIL: "Kontaktný e-mail",
    CONTACTPHONE: "Kontaktný mobil",
    RESPONSIBLE: "Zodpovedná osoba",
    STATE: "Stav požiadavky",
    REQUESTID: "Číslo požiadavky",
    PARAMSTITLE: "Parametre",
    CHATTITLE: "Zmeny a komentáre",
    CONTACTMAILPLACEHOLDER: "Email pre kontaktovanie",
    CONTACTPHONEPLACEHOLDER: "Telefón pre kontaktovanie",
    MESSAGEPLACEHOLDER: "Sem píšte správu",
    CANCELBUTTON: "Storno",
    SENDBUTTON: "Odoslať",
    SAVEBUTTON: "Uložiť",
    FILEATTACHMENTCOMMENT: "Priložená dokumentácia", //zmazat
    FILEUNAVAILABLEDESC:
      "Nahrávanie súborov je možné len pri stave požiadavky 'Rozpracovaná'",
    BUTTON_FILE_SAVE: "Odoslať súbor",
    FILE_ATTACHED: "Priložený súbor: ",
    SPR: "Správca",
  },
  NEW_REQUEST: {
    TITLE: "Nová požiadavka",
    BACKBUTTON: "Späť",
    LABEL: {
      REQTYPE: "Typ požiadavky",
      EMAIL: "Kontaktný e-mail",
      EMAILDESC: "Váš e-mail nebudeme nikdy zdieľať s nikým iným",
      MOBILE: "Kontaktný mobil (v tvare +421900xxxxxx)",
      SUBJECT: "Predmet",
      DESCRIPTION: "Obsah",
      FILE: "Súbor s prílohou", //zmazat popisok
      METERNR: "Číslo merača*",
      READINGDATE: "Dátum odpočtu*",
      STATE: "Stav na merači*",
      OLDTITLE: "Starý titul pred",
      OLDTITLE2: "Starý titul po",
      NEWTITLE: "Nový titul pred",
      NEWTITLE2: "Nový titul po",
      OLDNAME: "Staré meno",
      NEWNAME: "Nové meno",
      OLDSURNAME: "Staré priezvisko",
      NEWSURNAME: "Nové priezvisko",
      OLDSTREET: "Stará ulica",
      NEWSTREET: "Nová ulica",
      OLDCITY: "Staré mesto",
      NEWCITY: "Nové mesto",
      OLDPSC: "Staré PSČ",
      NEWPSC: "Nové PSČ",
      YEAR: "Rok*",
      CHANGEDATE: "Dátum zmeny od*",
      PERSONCOUNT: "Počet osôb*",
      MAINTENANCE: "Nahláška sa týka",
      RADIO1: "Môjho priestoru",
      RADIO2: "Vchodu",
      RADIO3: "Domu",
      DELETE_FILES: "Zrušiť priloženie súborov",
      DELETE_FILES_PRE: "Zrušené - ",
      SUMA: "Suma ",
      BU: "Bankový účet *",
      DESCVYD: "Popis výdavku *",
    },
    ERROR: {
      REQUIRED:
        "Pred odoslaním, vyplňte prosím všetky povinné polia(*) vo formulári.",
      REQUIREDPERSON:
        "Pred odoslaním, vyplňte prosím všetky povinné polia(*) vo formulári osobomesiacov.",
      REQUIREDCAPTCHA:
        "Pred odoslaním musíte potvrdiť, že nie ste robot (captcha).",
    },
    UPLOAD: "Priložiť súbory k požiadavke",
    UPLOAD2: "Priložiť súbory k požiadavke *",
    UPLOADED: "Priložené súbory k požiadavke",
    UPLOADPLACEHOLDER: "Vyberte súbor alebo ho sem potiahnite...",
    UPLOADPLACEHOLDER2: "Vyberte súbor alebo ho sem potiahnite... *",
    DATEPLACEHOLDER: "Žiadny dátum nie je zvolený",
    OLDNAMEPLACEHOLDER: "Staré meno",
    OLDSURNAMEPLACEHOLDER: "Staré priezvisko",
    OLDSTREETPLACEHOLDER: "Stará ulica",
    OLDCITYPLACEHOLDER: "Staré mesto",
    OLDPSCPLACEHOLDER: "Staré PSČ",
    PARAMETERS: "Parametre",
    CONFIRMLEAVE: "Máte neuložené zmeny! Naozaj chcete opustiť stránku?",
    SENDED: "Odoslaná",
    ROZP: "Rozpracovaná",
    METERNR: "Číslo merača:",
    STATE: "Stav:",
    OLDMETERS: "Predošlé odpočty, ktoré ste už zadali: ",
    TITLEPUB: "Zberný formulár pre samoodpočet meračov",
    TITLEPUBPERSON: "Zberný formulár pre zadanie osobomesiacov",
    UPLOADPUB: "Priložiť súbor",
    METERLEAVE:
      "Údaje odpočtu boli odoslané, chcete nahrať údaje ďalšieho merača?",
    NOTICE: "Oznam",
    YES: "Áno",
    NO: "Nie",
  },
  MONTHLY_CONSUMPTION: {
    CHART_WATER: {
      HOT_WATER: "Teplá voda",
      COLD_WATER: "Studená voda",
      AX_X: "Mesiac",
      AX_Y: "Spotreba vody [m3]",
    },
    CHART_ROOMS: {
      AX_X: "Mesiac",
      AX_Y: "Spotreba tepla [Dieliky, kWh, GJ]",
      S1: "Kuchyňa",
      S2: "Obytná miestnosť č.1",
    },
    BUTTON1: "Iné obdobie",
    BUTTON2: "Roky",
    BUTTONOK: "Načítať",
    FROM: "od: ",
    TO: "do: ",
    SHOW_CORRECTED: "Zobraziť korigované hodnoty",
    TABLE: {
      TYPE: "Typ",
      NUMBER: "Č. merača",
      ROOM: "Miestnosť",
      VALUEFROM: "Stav od",
      VALUETO: "Stav do",
      CONSUMPTION: "Spotreba",
      COEFFICIENT: "Koef. korekcie",
      CONSUMPTION_COEFFICIENT: "Korig. spotreba",
      INITCONSUMPTION: "Spotreba od zač. roku",
      YOUR_CONSUMPTION: "Vaša kumulatívna spotreba",
    },
  },
  MONTHLY_CONSUMPTION_MORE_YEARS: {
    CHART_COLD_WATER: {
      AX_X: "Mesiac",
      AX_Y: "Spotreba studenej vody [m3]",
    },
    CHART_HOT_WATER: {
      AX_X: "Mesiac",
      AX_Y: "Spotreba teplej vody [m3]",
    },
    CHART_HEAT: {
      AX_X: "Mesiac",
      AX_Y: "Spotreba tepla [Dieliky, kWh, GJ]",
      TAKE_CLIMATIC_CONDITIONS: "Zohľadniť klimatické podmienky",
    },
    LINE: "Krivka",
    BAR: "Stĺpce",
  },
  DAILY_CONSUMPTION_MORE_YEARS: {
    CHART_COLD_WATER: {
      AX_X: "Deň",
      AX_Y: "Spotreba studenej vody [litre]",
    },
    CHART_HOT_WATER: {
      AX_X: "Deň",
      AX_Y: "Spotreba teplej vody [litre]",
    },
    CHART_HEAT: {
      AX_X: "Deň",
      AX_Y: "[Dieliky, kWh, GJ]",
    },
    MENUTITLE: "Zvoľte zobrazované roky(max.3)",
  },
  DAILY_CONSUMPTION_AVERAGE_OBJECT: {
    CHART_COLD_WATER: {
      LEGEND_WATER: "Moja spotreba",
      LEGEND_AVERAGE_WATER: "Priemerná spotreba",
      LEGEND_OBJECT_WATER: "Referenčná spotreba",
      AX_X: "Deň",
      AX_Y: "Spotreba vody [litre]",
    },
    CHART_HOT_WATER: {
      LEGEND_WATER: "Moja spotreba",
      LEGEND_AVERAGE_WATER: "Priemerná spotreba",
      LEGEND_OBJECT_WATER: "Referenčná spotreba",
      AX_X: "Deň",
      AX_Y: "Spotreba vody [litre]",
    },
    CHART_HEAT: {
      LEGEND_HEAT: "Moja spotreba",
      LEGEND_AVERAGE_HEAT: "Priemerná spotreba",
      LEGEND_OBJECT_HEAT: "Referenčná spotreba",
      AX_X: "Deň",
      AX_Y: "[Dieliky, kWh, GJ]",
      SHOW_CORRECTED: "Zobraziť korigované hodnoty",
    },
    BUTTON1: "Vlastné dátumy",
    BUTTON2: "Mesiace",
    BUTTONOK: "Načítať",
    FROM: "od: ",
    TO: "do: ",
  },
  MONTHLY_CONSUMPTION_AVERAGE_OBJECT: {
    CHART_COLD_WATER: {
      LEGEND_WATER: "Moja spotreba",
      LEGEND_AVERAGE_WATER: "Priemerná spotreba",
      LEGEND_OBJECT_WATER: "Referenčná spotreba",
      AX_X: "Mesiac",
      AX_Y: "Spotreba vody [litre]",
    },
    CHART_HOT_WATER: {
      LEGEND_WATER: "Moja spotreba",
      LEGEND_AVERAGE_WATER: "Priemerná spotreba",
      LEGEND_OBJECT_WATER: "Referenčná spotreba",
      AX_X: "Mesiac",
      AX_Y: "Spotreba vody [litre]",
    },
    CHART_HEAT: {
      LEGEND_HEAT: "Moja spotreba",
      LEGEND_AVERAGE_HEAT: "Priemerná spotreba",
      LEGEND_OBJECT_HEAT: "Referenčná spotreba",
      //LEGEND_COMPARABLE_HEAT: "Spotreba v porovnateľnom byte",
      AX_X: "Mesiac",
      AX_Y: "Spotreba tepla [Dieliky, kWh, GJ]",
      SHOW_CORRECTED: "Zobraziť korigované hodnoty",
    },
    BUTTON1: "Iné obdobie",
    BUTTON2: "Roky",
    BUTTONOK: "Načítať",
    FROM: "od: ",
    TO: "do: ",
  },
  CONSUMPTION_FOR_PERIOD: {
    BUTTONOK: "Načítať",
    FROM: "od: ",
    TO: "do: ",
  },
  CONSUMPTION: {
    CHART_COLD_WATER: {
      LEGEND_WATER: "Spotreba v mojom byte",
      LEGEND_AVERAGE_WATER: "Priemerná spotreba v dome",
      AX_X: "Rok",
      AX_Y: "Spotreba vody [m3]",
    },
    CHART_HOT_WATER: {
      LEGEND_WATER: "Spotreba v mojom byte",
      LEGEND_AVERAGE_WATER: "Priemerná spotreba v dome",
      AX_X: "Rok",
      AX_Y: "Spotreba vody [m3]",
    },
    CHART_HEAT: {
      LEGEND_HEAT: "Spotreba v mojom byte",
      LEGEND_AVERAGE_HEAT: "Priemerná spotreba v dome",
      AX_X: "Rok",
      AX_Y: "[Dieliky, kWh, GJ]",
    },
  },
  DAILY_CONSUMPTION: {
    CHART_WATER: {
      HOT_WATER: "Teplá voda",
      COLD_WATER: "Studená voda",
      HEAT: "Teplo",
      AX_X_HEAT: "Deň",
      AX_Y_HEAT: "Spotreba tepla [Dieliky, kWh, GJ]",
      AX_X: "Deň",
      AX_Y: "Spotreba vody [litre]",
    },
    CHART_ROOMS: {
      AX_X: "Deň",
      AX_Y: "[Dieliky, kWh, GJ]",
    },
    BUTTON1: "Iné obdobie",
    BUTTON2: "Mesiace",
    BUTTONOK: "Načítať",
    FROM: "od: ",
    TO: "do: ",
    FILTERMONTH: "Mesiac",
    EMPTY: " Za zvolené obdobie nie sú údaje k dispozícii.",
  },
  BALANCES: {
    TABLE: {
      VS: "Variabilný symbol",
      APARTMENT_NUMBER: "Číslo bytu",
      APARTMENT_TYPE: "Typ priestoru",
      NAME: "Meno a priezvisko",
      BALANCE: "Zostatok k dátumu",
      LAST_PAYMENT_DATE: "Posledná platba",
    },
    PARAMETER: {
      DATE: "k dátumu:",
      BUTTON: "Načítať",
    },
  },
  UNPAID_PREPAYMENTS: {
    TABLE: {
      USER: "Meno",
      APARTMENT_NUMBER: "Č. pr.",
      PERIOD: "Obdobie",
      DUE_DATE: "Dt. splatnosti",
      TYPE: "Typ predpisu",
      AMOUNT: "Suma",
      PAID: "Uhradené",
      TO_BE_PAID: "Neuhradené",
    },
    TOOLTIP: {
      APARTMENT_NUMBER: "Číslo priestoru",
      DUE_DATE: "V prípade platby ide o dátum prijatia danej platby.",
      TYPE: "V prípade platby sa zobrazí typ platby.",
      PAID:
        "V prípade zobrazenia platby sa zobrazuje len časť platby, ktorá nie je priradená k žiadnemu predpisu do zadaného dátumu.",
    },
    PARAMETER: {
      DATE: "k dátumu:",
      BUTTON: "Načítať",
    },
  },
  UNPAID_DEBTS: {
    TABLE: {
      VS: "Variabilný symbol",
      APARTMENT_NUMBER: "Číslo bytu",
      APARTMENT_TYPE: "Typ priestoru",
      NAME: "Meno a priezvisko",
      CLAIM_NAME: "Pohľadávka",
      PERIOD: "Obdobie",
      TO_BE_PAID: "Má zaplatiť",
      PAID: "Zaplatil",
      DUE_DATE: "Dátum splatnosti",
      PAYMENT_STATUS: "Stav",
      IS_BEING_VERIFIED: "overuje sa",
      //ALREADY_PAID: " už uhradené",
      PAYMENT_DUE: "Splatnosť ",
    },
  },
  AUTH: {
    GENERAL: {
      NO_ACCOUNT: "Nemáte účet?",
      SIGNUP_BUTTON: "Registrácia",
      SIGNUP_ADDSPACE: "Pridanie priestoru",
      SIGNIN_BUTTON: "Prihlásenie",
      SIGNOUT_BUTTON: "Odhlásiť sa",
      FORGOT_BUTTON: "Zabudli ste heslo",
      BACK_BUTTON: "Naspäť",
      BACK_TOLOGIN: "Návrat na stránku prihlásenia",
      PLACEHOLDER_EMAIL: "E-mailová adresa",
      CONTINUE: "Pokračovať",
      DISCONNECT_AND_CANCEL: "Odpojiť a zrušiť konto",
      TEXT1:
        "Technická odstávka. Z dôvodu servisnej údržby bude portál a súvisiace služby 20.9.2021 dočasne nedostupné. Ďakujeme za pochopenie.",
      TEXT2:
        "Probíhá aktualizace portálu. Některé služby mohou být dočasně nedostupné. Děkujeme za pochopení.",
    },
    LOGIN: {
      BUTTON: "Prihlásiť sa",
      LOGIN_EXTERNAL: "Prihláste sa cez",
      LOGIN_WITH_USERNAME_PASSWORD:
        "Alebo sa prihláste emailovou adresou a heslom",
      USERNAME_REQUIRED:
        "Použite emailovú adresu, ktorú ste uviedli pri registrácii",
      PASSWORD_REQUIRED: "Heslo je povinné",
      PLACEHOLDER_LOGIN: "Email",
      PLACEHOLDER_PWD: "Heslo",
      DEMO: "Alebo vyskúšajte demo prístup",
      WARNING:
        "(*pôvodné prihlasovacie mená používané pred 1.7.2021 už nie sú akceptované. Vaše prihlasovacie meno bolo zmenené na emailovú adresu, ktorú ste použili pri pôvodnej registrácii)",
      VYUCTOVANIE1: "Prečítajte si: ",
      VYUCTOVANIE2: "Elektronické hlasovanie na portáli POSCHODOCH.SK",
    },
    UNITS: {
      TITLE: "Výber priestoru",
      SELECT_BUTTON: "Vybrať",
    },
    FORGOT: {
      TITLE: "Zabudli ste heslo?",
    },
    REGISTER: {
      TITLE: "Registrácia",
      TITLE_REG: "Zaregistruj ma",
      TITLE_PHONE:
        "Pre pokračovanie v registrácii bude na Váš mobilný telefón zaslaný kontrolný kód. Prosím, zadajte číslo Vášho mobilného telefónu:",
      SEND_CODE: "Poslať kód",
      SEND_BUTTON: "Odoslať",
      DIVIDER: "ALEBO",
      LOGIN: "Prihlásiť sa",
      ACCOUNT_EXISTS: "Už máte účet?",
      TEXT_MOBIL:
        "Zadajte prosím kontrolný kód, ktorý Vám prišiel v SMS správe",
      TEXT_EMAIL: "Zadajte prosím kontrolný kód, ktorý Vám prišiel Emailom",
      VALIDATION: {
        EMAIL: "Email je povinný. Musíte zadať platnú emailovú adresu.",
        PWD:
          "Heslo je povinné. Musí mať aspoň 8 znakov a zároveň obsahovať aspoň 1 znak z nasledovných 3-och kategórii: Číslo, veľké a malé písmeno",
        PHONE:
          "Číslo je povinné. Musí mať dĺžku minimálne 10 znakov, čísla pre Slovenskú a Českú republiku (+421/+420) musia byť platné čísla u daných operátorov.",
        CODE_EMAIL: "Verifikačný kód doručený Emailom je povinný.",
        CODE_SMS: "Verifikačný kód doručený cez SMS je povinný.",
      },
      PLACEHOLDER_PHONE: "Tel. číslo",
      PLACEHOLDER_CODE_EMAIL: "Verifikačný kód doručený Emailom",
      PLACEHOLDER_CODE_SMS: "Verifikačný kód doručený cez SMS",
      STARTOVER:
        "Pomýlili ste sa v registrácii? Vráťte sa na prvý krok a môzete kedykoľvek začať odznovu.",
      CODE_RESEND: "Znovu odoslať verifikačný kód",
    },
    REGISTRATION_CODE: {
      TITLE: "Zadajte registračný kód",
      TEXT:
        "Pre získanie prístupu na stránky portálu potrebujete registračný kód, ktorý identifikuje Váš priestor. Kód získate od Vášho správcu domu.",
      REQUIRED:
        "Registračný kód je povinný. Môže obsahovať len číslice, velké a malé písmená s výnimkou písmena 'O'/'o'. Dĺžka musí byť 11 alebo 12 znakov.",
      SEND_BUTTON: "Odoslať",
      PLACEHOLDER_REGCODE: "Registračný kód",
      HINT1: "Najčastejšie príčiny nesprávneho kódu: ",
      HINT2: "- preklep v niektorom znaku, Kód má 11 alebo 12 znakov.",
      HINT3:
        "- kód nie je ešte aktívny. Nové vygenerované kódy sú platné až na druhý deň, štandardne do 24h.",
      HINT4:
        "- kód môže byť z bezpečnostných dôvodov zneplatnený správcom alebo administrátorom.",
      HINT5:
        "Ak Váš kód nie je akceptovaný ani na druhý deň, kontaktujte prosím Vášho správcu.",
    },
    REGISTRATION_AGREEMENT: {
      TITLE: "Obchodné podmienky a cena",
      HEAD: "Potvrdenie obchodných podmienok a ceny",
      NAME: "Meno",
      ADDRESS: "Adresa",
      VARIABLE_SYMBOL: "Variabilný symbol",
      AGREE_BUTTON: "Súhlasím",
      PRICE_CHECKBOX: "Súhlasím so stanovenou cenou za prístup ",
      PRICE_TEXT: "Správcom stanovená cena za poskytnutie prístupu je ",
      PRICE_TEXT2:
        "V prípade aktívnej služby Kontrola spotreby (diaľkové odpočty) je cena za prístup na portál zahrnutá v poplatku za túto službu.",
      CUSTOMER_TEXT: "Poskytuje správca: ",
      LEGAL_AGREEMENT_CHECKBOX:
        "Prečítal/a som si a súhlasím s podmienkami poskytovateľa",
      LEGAL_AGREEMENT_CHECKBOX_ANA:
        "Prečítal/a som si a súhlasím s podmienkami prevádzkovateľa",
      GDPR_AGREEMENT_CHECKBOX:
        "Prečítal/a som si a súhlasím s pravidlami ochrany osobných údajov",
      ANASOFT_TEXT: "Portál prevádzkuje spoločnosť ANASOFT APR, spol. s r.o.",
      REQUIRED:
        "Pre pokračovanie je nutné odsúhlasiť (“zaškrtnúť”) všetky podmienky.",
      TEXT_ACCESS: "Prístup k údajom priestoru:",
    },
  },
  SPEEDOMETER: {
    VALUETEXT: "Aktuálna spotreba: ",
  },
  RESETPASSWORD: {
    TITLE: "Zadajte nové heslo",
    BUTTON: "Vytvoriť heslo",
    TEXT: "Vaše heslo bolo zmenené. Môžete sa prihlásiť.",
  },
  FORGOTPASSWORD: {
    TITLE: "Zadajte email účtu",
    BUTTON: "Resetovať heslo",
  },
  VOTING: {
    BUTTON: "Odoslať",
    TXT: "Zadajte kód hlasovania od Vášho správcu:",
    MAINTITLE: "Hlasovacia listina",
    SECONDTITLE: "Obdobie konania hlasovania:",
    BUILDING_MANAGER: "Správca:",
    OWNER: "Vlastník:",
    EMAIL_TXT: "Emailová adresa pre zaslanie potvrdenia:",
    PHONE_TXT: "Hlasovací kód zaslaný na:",
    FILL_ALL: "Vyplň naraz za všetky priestory:",
    CONSENT:
      "Potvrdzujem, že som osobou uvedenou vyššie a ako vlastník uvedených priestorov, zapísaný na LV v príslušnom katastri nehnuteľností, som oprávnený hlasovať. V prípade potvrdenia nepravdivej informácie som si vedomý svojej trestnoprávnej zodpovednosti a zodpovednosti za spôsobenú škodu.",
    SEND_CONFIRM:
      "Neodpovedali ste na všetky otázky. Váš hlasovací list môže byť neplatný. Chcete naozaj odoslať?",
    SENT_TXT:
      "Vaše hlasovanie bolo odoslané. V krátkej dobe obdržíte email s potvrdením",
    BUTTON_BACK: "Na stránku prihlásenia",
  },
  PACKAGE: {
    BUTTON: "Odoslať",
    TXT: "Zadajte heslo:",
    WRONGPASSWD: "Zadané heslo je nesprávne!",
    ENTRY: "(Prístup k zásielke je chránený heslom)",
    PACKAGE: "Zásielka",
  },
  USERINFO: {
    ADDRESS: "Adresa",
    VS: "VS",
    TYPE: "Typ",
    NAME: "Meno",
    SURNAME: "Priezvisko",
    DEGREE: "Titul",
    PERSON: "Počet osôb",
    EMAIL: "Email",
    MOBIL: "Telefón",
    ADDRESS2: "Adresát",
    STREET: "Ulica",
    CITY: "Mesto",
    SIPO: "SIPO",
    BANKACCOUNT: "Bankový účet",
    ELECTRONICINVOICE: "Elektronická faktúra",
    PAYMENTORDER: "Platobný príkaz",
    PERSONALINFO: "Osobné údaje",
    CONTACTINFO: "Kontaktná adresa",
    PAYMANTINFO: "Platobné informácie",
    OTHERSINFO: "Dalšie informácie",
    OTHERSINFO2: " ",
    ACTIVE: "aktívna",
    INACTIVE: "neaktívna",
  },
  CUSTREPORT: {
    BUTTON: "Generovať PDF výstup",
    MAINTITLE: "CUST REPORT NADPIS",
    FIRSTLISTOPTION: "-- Zvoľte možnosť prosím --",
    EMPTYVALIDATION: "Pole nesmie ostať prázdne (bez hodnoty)",
  },
  DAILYCONSUMPTIONOBJECT: {
    FLATNUMBER: "Meno/číslo priestoru",
    VARSYMB: "Variabilný symbol",
    CODE: "Služba",
    METERNUMBER: "Číslo merača",
    LOCATION: "Umiestnenie",
    STATE: "Stav",
    SV: "SV",
    TV: "TV",
    UK: "ÚK",
    DATE: "Zadajte dátum:",
  },
  INVOICESAPPROVAL: {
    TITLE: "Faktúra - daňový doklad",
    VS: "Variabilný symbol",
    DODAV: "Dodávateľ",
    PREDMET: "Predmet",
    SUMAKU: "Suma k úhrade",
    UDAJKFAK: "Údaje o faktúre",
    RIADKYFAKT: "Riadky faktúry",
    DOVOD: "Dôvod:",
    NIE: "Nie",
    ANO: "Áno",
    DOVODHLASKA: "Dôvod zmeny stavu je povinný údaj!",
    DAKUEJME: "Ďakujeme za vybavenie!",
    ANONIEHLASKA:
      "Faktúru môžete schváliť stlačením tlačidla 'Áno', alebo zamietnuť stlačením tlačidla 'Nie'.",
    FILE: "Súbor",
    NAZOV: "Názov",
    SPOLU: "Spolu",
    MENA: "Mena",
    SHOWERRORINT: "Faktúru nemožno schváliť nakoľko nie sú stavy schvaľovania",
  },
  SHOWPICTURE: {
    INFO: "Info o odpočte",
    METERNUMBER: "Číslo merača",
    READINGDATE: "Dátum odpočtu",
    STATE: "Stav",
  },
  BETA: {
    MSG: "Testovacia prevádzka",
  },
  EULAPRICES: {
    SUM: "Cena za prístup",
    DATE: "Dátum platnosti od",
    TITLE: "Cena poskytovateľa za prístup",
  },
  HOME: {
    MAINTITLE_LEFT: "POSCHODOCH",
    MAINTITLE_LEFT_PENTA: "Services by PENTA",
    MAINTITLE_RIGHT: "Bývajte s prehľadom",
    MAINTEXT_LEFT: "Prístup k údajom o Vašom byte od správcu.",
    MAINTEXT_RIGHT_1:
      "Portál poschodoch.sk je určený nájomníkom a vlastníkom bytových a nebytových priestorov. Cieľom portálu je poskytnúť všetky informácie o byte a dome.",
    MAINTEXT_RIGHT_2:
      "Webový portál poschodoch.sk sprístupňuje finančné a technické údaje o bytoch a domoch pre ich vlastníkov. Prístup na portál umožňuje správca poskytnutím registračného kódu na požiadanie. Údaje sú sprístupnené z informačného systému DOMUS, a preto správca musí používať tento systém.",
    BANNERS: {
      GS: {
        TITLE: "Grafy spotrieb",
        TEXT:
          "Prehľady spotrieb vody, tepla a chladu v byte jednoducho a rýchlo. Porovnávanie spotrieb voči priemerom domu, voči iným obdobiam...",
      },
      K: {
        TITLE: "Kontakty",
        TEXT: "Prístup k dôležitým kontaktom na správcu alebo z domu.",
      },
      FO: {
        TITLE: "Fond opráv",
        TEXT:
          "Dokonalý prehľad tvorby a čerpania za Fond údržby a opráv. Súhrnné mesačné prehľady s detailným rozpisom si môžete stiahnuť ako PDF dokument.",
      },
    },
    FUNCTIONS: {
      TITLE: "Na portáli ďalej nájdete:",
      DENNESPOT: "Denné spotreby",
      FONDOPRAV: "Fond opráv",
      UDALOSTI: "Udalosti v dome - nástenka",
      DOKUMENTY: "Dokumenty domu - zápisnice zo schôdzí, revízne správy...",
      ROCNEVYUCT: "Ročné vyúčtovanie",
      GRAFY: "Grafy nákladov",
      KONTO: "Konto - platobná bilancia",
      ZASIELKY: "Zaslané dokumenty od správcu",
      FAKTURY: "Faktúry domu",
      REVIZIE: "Revízie",
      KONTAKTY: "Kontakty domu",
      ZIADOSTI: "Žiadosti",
    },
    DEMO: "Vyskúšajte demo prístup",
    INSTALL: "Mobilná aplikácia",
    APP: "Vyskúšajte aj mobilnú aplikáciu",
  },
  DASHBOARD: {
    COMMUNITY: "Naše spoločenstvo vlastníkov",
  },
  CUSTOMERPAGE: {
    TABLE: {
      NAME: "Názov stránky",
      NAMESK: "Názov stránky SK",
      NAMECZ: "Názov stránky CZ",
      NAMEEN: "Názov stránky EN",
      PUBLISH: "Publikovať (Zverejniť zmenu)",
      SEND: "Odoslať notifikačné maily",
      SENDBUTTON: "Uložiť zmeny",
      ORDER: "Umiestnenie",
      NAMEOFPAGE: "Názov stránky",
      ISNEW: "Zmenená",
      DATE: "Dátum zmeny",
      BACK: "Späť",
      VISIBLE: "Viditeľná",
    },
  },
  INSURANCEEVENTS: {
    NAME: "Názov",
    STAV: "Stav",
    DATECREATION: "Dátum vzniku",
    NUMBER: "Číslo poistnej udalosti",
    DOCUMENTS: "Dokumenty",
    OBJECT: "Miesto vzniku poistnej udalosti",
    CAUSE: "Príčina vzniku škody",
    DAMAGE: "Rozsah poškodenia",
    RECIEVER: "Príjemca plnenia",
    SUMDAMAGE: "Suma škody",
    SUM: "Suma plnenia",
  },
  USERLISTOFFICIALS: {
    NAME: "Meno (firma)",
    UNITNUMBER: "Číslo jednotky",
    EXTUNITNUMBER: "Ext. číslo jednotky",
    STREET: "Ulica",
    PSC: "PSČ",
    CITY: "Mesto",
    PCSCITY: "PSČ, Mesto",
    ODD1: "Kontakty",
    ODD2: "Dalšie údaje užívateľa",
    TEL: "Telefón",
    MOBIL: "Mobil",
    EMAIL: "E-mail",
    VS: "Variabilný symbol",
    SIPO: "Spojovacie číslo SIPO",
    UNITDESCRIPTIONSK: "Typ priestoru",
    USERTYPE: "Nájomca / vlastník",
    UNITSTOCK: "Poschodie / Podlažie",
    PERSONS: "Počet osôb",
    PAYINFO: "Platobné informácie",
    DTPRIST: "Dátum prist.",
    DTODST: "Dátum odst.",
    CHECKUZI: "Len aktuálni užívatelia (inak všetci)",
    DATEO: "Do: ",
    PORTALACCESSTRUE: "Užívateľ so zaregistrovaným prístupom",
    PORTALACCESSFALSE: "Užívateľ bez zaregistrovaného prístupu",
    PORTALACCESSTOOLTIP: "Registrácia na portáli",
    ODD3: "Údaje bytu",
    ODD4: "Plochy",
    PERSONCOUNT: "Počet osôb",
    ROOMS: "Počet izieb",
    CUT: "Spoluvlastnícky podiel",
  },
  ADMINACCOUNT: {
    LOGINNAME: "Login",
    CODE: "Kód správcu",
    STATE: "Stav",
    MOBIL: "Mobil",
    CREATEDATE: "Dátum vytvorenia",
    DETACH: "Odpojiť všetky priestory a zrušiš konto",
    QU: "Ste si isty?",
    ADDMANAGER: "Pridaj správcu",
    CHANGE: "Zmeniť mail pre: ",
    NEWMANAGER: "Nový email pre správcov",
    EMAIL: "Email",
    BTNCHANGE: "Zmena emailu",
    BTNNEW: "Vytvor správcu",
    MODALTITLE: "Zákazník: ",
    TITLE: "Administrácia správcov",
  },
  REGLOGLIST: {
    LOGINNAME: "Login",
    CODE: "Kód správcu",
    FROM: "Dátum",
    TABLETITLE: "Udalosti",
    TITLE: "Registračný log",
    REGSTEP: "Krok",
    REGPARM: "Parametre",
    CREATEDATE: "Dátum vytvorenia",
  },
  METERDAILYREADINGS: {
    CODE: "kod",
    TYPE: "Médium",
    STATE: "stav",
    CONSUMPTION: "Spotreba",
    DATE: "datum",
    APARTMENT: "Priestor",
    METERNUMBER: "Číslo merača",
    DATEFROM: "Dátum od",
    DATETO: "Dátum do",
    STATETO: "Stav do",
    UNIT: "Mj",
    STATEFROM: "Stav od",
    MEDIUM: "Médium",
    APARTMENTNUMBER: "Číslo bytu",
    ERRORDATE: "Dátum do musí byt väčší ako dátum od",
  },
  METERDAILYREADINGSOBJECT: {
    CHARTS: {
      AX_X_COLD: "Spotreba vody [l]",
      AX_Y_COLD: "Priestor",
      AX_X_HOT: "Spotreba vody [l]",
      AX_Y_HOT: "Priestor",
      AX_X_HEAT: "Spotreba tepla [Dieliky, kWh, GJ]",
      AX_Y_HEAT: "Priestor",
      HOT_WATER: "Teplá voda",
      COLD_WATER: "Studená voda",
      HEAT: "Teplo",
      LEGEND_WATER_COLD: "Spotreba v priestore",
      LEGEND_AVERAGE_WATER_COLD: "Priemerná spotreba v dome",
      LEGEND_WATER_HOT: "Spotreba v priestore",
      LEGEND_AVERAGE_WATER_HOT: "Priemerná spotreba v dome",
      LEGEND_HEAT: "Spotreba v priestore",
      LEGEND_AVERAGE_HEAT: "Priemerná spotreba v dome",
    },
    BUTTONOK: "Načítať",
    DATETO: "Dátum",
  },
  ACCOUNTLIST: {
    TABLE: {
      VS: "Variabilný symbol",
      NAME: "Meno",
      TYPE: "Typ",
      DETAIL_OF_MOVEMENT: "Detail pohybu",
      DUE_DATE: "Dátum splat. / úhrady",
      AMOUNT: "Suma",
    },
    TYPE: "Typ",
    FILTER: {
      ALL: "Všetky",
      N: "Náklad",
      P: "Platba",
      Z: "Zostatok",
    },
  },
  CUSTOMERLIST: {
    TITLE: "Administrácia zákazníkov",
    TITLE2: "Všeobecné podmienky zákazníka",
    TITLEEDIT: "Nastavenia zákazníka:",
    RESET: "Prednastavená",
    ORIGINAL: "Pôvodná",
    SENDBUTTON: "Uložiť zmeny",
    BUTTONFREESK: "Zadarmo(SK)",
    BUTTONFREECZ: "Zdarma (CZ)",
    BUTTONFREEEN: "Free(EN)",
    TEXTFREESK: "0,00 Eur s DPH/mesiac (zadarmo)",
    TEXTFREECZ: "0,00 Kč s DPH/měsíc (zdarma)",
    TEXTFREEEN: "0,00 Eur VAT inc./month (free of charge)",
    NEWEULA: "Nové podmienky",
    DATEFROM: "Dátum platnosti od",
    CONTENTSK: "Obsah SK",
    CONTENTCZ: "Obsah CZ",
    CONTENTEN: "Obsah EN",
    APRREQ: "Vyžadovať (opätovne) potvrdenie podmienok",
    TABLE: {
      NAME: "Meno",
      CODE: "Kód",
      CITY: "Mesto",
      PRIZE: "Cena",
      COLOR: "Farba",
      EULASK: "Eula SK",
      EULACZ: "Eula CZ",
      EULAEN: "Eula EN",
      LOGO: "Logo",
    },
  },
  NOTIFICATIONSETTINGS: {
    TITLE: "Nastavenie upozornení",
    DESCRIPTION:
      "Zmena nastavenia upozornenia u správcu prebehne do 24h(* - nastavenie upozornenia určuje správca)",
    WATCH: "Neupozorňujeme",
    NOTWATCH: "Iba na poschodoch.sk",
    WATCHWITHMAIL: "E-mailom a na poschodoch.sk",
    WATCH1: "Vypnuté",
    NOTWATCH1: "(Iba na poschodoch.sk)",
    WATCHWITHMAIL1: "(E-mailom a na poschodoch.sk)",
    SETMANAGER: "Nastavuje správca",
    APPLY: "Aplikovať na všetky moje priestory",
    OK: "OK",
    APPLIED: "Nastavenia boli zmenené",
  },
  EVENT_DETAIL: {
    DESCRIPTION: "Popis",
    SUBJECT: "Predmet",
    NEW: "Nová",
    EDIT: "Editovať",
    DELETE: "Zmazať",
    SAVE: "Uložiť",
    BACKBUTTON: "Späť",
    UPLOAD: "Priložiť súbory",
    FILEATTACHMENTCOMMENT: "Priložená dokumentácia",
    DELETE_FILES: "Zrušiť priloženie súborov",
    UPLOADED: "Priložené súbory",
    UPLOADPLACEHOLDER: "Vyberte súbor alebo ho sem potiahnite...",
    DELETE_FILES_PRE: "Zrušené - ",
    NEWEVENT: "Pridať udalosť",
    PLACE: "Miesto",
    DATEFROM: "Dátum od",
    DATETO: "do",
    ONLYOWNED: "Moje",
    NOTIFI: "Oboznámiť ostatných užívateľov o vykonaní zmien.",
    ZADAL: "Zadávateľ",
    EVENTCOUNT: "Denne je možné zadať maximálne 3 udalosti",
    LENGTHERROR: "Maximálna veľkosť súborov je 15MB",
    REQUIRED: "Nevyplnené povinné polia",
    TOMANYFILES: "Maximálny počet súborov je 5",
    REQUIREDDATE: "Je potrebné mať vyplnené oba dátumy udalosti",
    ELEVOTING: "Elektronicky hlasovalo",
    ELENOTVOTING: "Elektronicky nehlasovalo",
    WRITINGVOTING: "Písomne hlasuje",
    ACTUAL: "Aktuálny stav",
    VS: "Variabilný symbol",
    OBJECT: "Číslo bytu",
    NAME: "Meno",
    VOTE: "Hlasovanie",
    TOOLTIP: "Hlasoval",
    TOOLTIPNOPE: "Nehlasoval",
    TOOLTIPWRITING: "Písomne",
    TOOLTIPELE: "Elektronicky",
    TOOLTIPDATE: "Dátum hlasovania",
    TOOLTIPHLAS: "Spôsob hlasovania",
    TITLERUNING: "Hlasovanie - Priebežný stav",
    TITLEDONE: "Hlasovanie - Konečný stav",
    CSV: {
      OWNER: "Vlastník",
      EMAIL: "Email",
      VS: "VS",
      SPACE: "Priestor",
      CUT: "Podiel",
      QUESTION: "Otázka",
      VOTE: "Hlasovanie",
      DATEVOTE: "Čas Vyplnenia",
    },
  },
  NOTIFICATIONS: {
    CREATION_DATE: "Dátum a čas",
    DESCRIPTION: "Popis",
    NOTIF: "Upozornenia",
    NOTE:
      "Zmena nastavenia notifikacie u správcu prebehne do 24h(* - nastevenie notifikacie urcuje spravca)",
  },
  CASHREGISTER: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Popis pokladne",
      BANK: "Číslo",
      FINALBALANCE: "Konečný stav",
      BANKACCOUNT: "Číslo účtu",
      ISSUEDATE: "Stav k dátumu",
      ISSUEDATEMOBILE: "Stav k",
    },
  },
  CASHREGISTER_DETAIL: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Popis účtu",
      CURRENCY: "Mena",
      INITIALBALANCE: "Počiatočný stav",
      INITIALDATE: "Počiatočný dátum",
      RELATIVEBALANCE: "Relatívny stav",
      FINALBALANCE: "Konečný stav",
      BANKACCOUNT: "Účet (IBAN)",
      BANK: "Číslo",
      ISSUEDATE: "Dátum vydania",
      VS: "Variabilný symbol",
      TEXT: "Text",
      DATE: "Dátum",
      AMOUNT: "Suma",
      DESCRIPTION: "Popis",
    },
    TITLEADD: "Stav k ",
    PARAMETER_YEAR: "Rok:",
    PARAMETER_MONTH: "Mesiac:",
    PARAMETER_REPORT: "Zobrazovať nepotvrdené výpisy",
  },
};
