var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ObjectAccountDocs',{attrs:{"objectAccountDocs":_vm.objectAccountDocs}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('KTPortlet',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('TableContextMenu',{attrs:{"functionCSV":_vm.downloadCSV,"functionPrint":_vm.print}})]},proxy:true},{key:"body",fn:function(){return [(_vm.items)?_c('b-container',{attrs:{"fluid":""}},[_c('b-table',{attrs:{"show-empty":"","hover":"","size":"md","empty-text":_vm.$t('COMMON.LIST.NO_RECORDS'),"items":_vm.items,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-direction":_vm.sortDirection,"tbody-tr-class":_vm.pointerRowClass},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"row-clicked":_vm.tableRowClickHandler},scopedSlots:_vm._u([{key:"cell(FinalBalance)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" "+_vm._s(data.item.Currency)+" ")]}},{key:"cell(AccountDescription)",fn:function(data){return [(data.item != null)?_c('a',{staticClass:"kt-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showObjectAccountDetail(_vm.menuId, data.item.AccountId)}}},[_vm._v(" "+_vm._s(data.value)+" ")]):_vm._e(),(!(data.item != null))?_c('span',[_vm._v(_vm._s(data.value))]):_vm._e()]}},{key:"cell(DocCount)",fn:function(data){return [(data.value > 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showObjectAccountDocs(
                      data.item.Reckey,
                      data.item.TableName
                    )}}},[_c('i',{staticClass:"flaticon-file-2 poschodoch-icon-medium"})]):_vm._e(),(data.value == 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showSingleDoc(data.item.Reckey, data.item.TableName)}}},[_c('i',{staticClass:"flaticon-download poschodoch-icon-medium"})]):_vm._e()]}},{key:"cell(Mobile)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column align-items-start w-100"},[_c('div',{staticClass:"poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"},[_c('div',{staticClass:"d-flex flex-column align-items-start poschodoch-mobile-left"},[_c('span',{staticClass:"text-left"},[(data.item.AccountDescription != null)?_c('a',{staticClass:"kt-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showObjectAccountDetail(
                              _vm.menuId,
                              data.item.AccountId
                            )}}},[_vm._v(" "+_vm._s(data.value.AccountDescription)+" ")]):_vm._e()]),_c('span',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$i18n.t("OBJECTACCOUNT.TABLE.ISSUEDATEMOBILE") + " ")+" "+_vm._s(_vm.getFormatService().formatDateToLocal( data.value.IssueDate ) + ": ")+" "),_c('span',{class:[
                            data.value.FinalBalance < 0
                              ? 'poschodoch-table-title-mobile color-danger'
                              : 'poschodoch-table-title-mobile' ]},[_vm._v(" "+_vm._s(_vm.addCurrency( _vm.getFormatService().formatNumberToLocal( data.value.FinalBalance ) ))+" ")])]),_c('span',{staticClass:"text-left"},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t("OBJECTACCOUNT.TABLE.BANK") + ": ")+" ")]),_vm._v(" "+_vm._s(data.value.Bank)+" ")]),_c('span',{staticClass:"text-left"},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t("OBJECTACCOUNT.TABLE.BANKACCOUNT") + ": ")+" ")]),_vm._v(" "+_vm._s(data.value.BankAccount)+" ")]),_c('span',{staticClass:"poschodoch-mobile-document-link"},[(data.value.DocCount > 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showObjectAccountDocs(
                              data.item.Reckey,
                              data.item.TableName
                            )}}},[_c('i',{staticClass:"flaticon-file-2 poschodoch-icon-medium"})]):_vm._e(),(data.value.DocCount == 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showSingleDoc(
                              data.item.Reckey,
                              data.item.TableName
                            )}}},[_c('i',{staticClass:"flaticon-download poschodoch-icon-medium"})]):_vm._e()])])])])]}}],null,false,1919479321)}),(_vm.description)?_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"lg":"12"}},[_c('hr',{staticClass:"poschodoch-separator-line mt-4 mb-4"}),_c('ul',{staticClass:"poschodoch-note-list"},[_c('li',{staticClass:"poschodoch-note"},[_vm._v(_vm._s(_vm.description))])])])],1):_vm._e()],1):_vm._e(),(!_vm.items)?_c('b-container',{attrs:{"fluid":""}},[_c('Loader')],1):_vm._e()]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }