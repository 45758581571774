<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <RepairFundDocs v-bind:repairFundDocs="repairFundDocs" />
    <EventsDetailDocs v-bind:eventsDetailDocs="eventsDetailDocs" />
    <EventsDetailDocsDwp v-bind:eventsDetailDocsDwp="eventsDetailDocsDwp" />
    <template></template>
    <b-card-group columns class="poschodoch-column-card-group">
      <b-card class="poschodoch-card" v-if="communityInfo">
        <!--Begin::community -->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <h3 class="kt-portlet__head-title ">
              <span class="poschodoch-dashboard-title">
                {{ communityTitle }}
              </span>
            </h3>
          </template>
          <template v-slot:body>
            <b-container class fluid>
              <div class="poschodoch-dashboard-title">
                <div class="row mb-1">
                  {{ communityInfo }}
                </div>
                <div class="row">
                  <div class="col-xl-6 ">
                    <div class="row">
                      {{ communityInfoStreet }}
                    </div>
                    <div class="row">
                      {{ communityInfoCity }}
                    </div>
                    <div class="row">
                      {{ communityInfoIco }}
                    </div>
                    <div class="row">
                      {{ communityInfoData }}
                    </div>
                  </div>
                  <div class=" col-xl-6 ">
                    <div class="row">
                      <span v-if="communityInfowww">
                        {{ "WWW: " }}
                        <a
                          :href="'//' + communityInfowww"
                          target="_blank"
                          class="kt-link"
                        >
                          {{ communityInfowww }}
                        </a>
                      </span>
                    </div>
                    <div class="row">
                      <span v-if="communityInfoemail">
                        {{ $i18n.t("OBJECTOFFICIALS.TABLE.EMAIL") + ": " }}
                        <a
                          :href="`mailto:${communityInfoemail}`"
                          class="kt-link"
                        >
                          {{ communityInfoemail }}
                        </a>
                      </span>
                    </div>
                    <div class="row">
                      <span v-if="communityInfomobil">
                        {{
                          $i18n.t("OBJECTOFFICIALS.TABLE.PHONE") +
                            ": " +
                            communityInfomobil
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </b-container>
          </template>
        </KTPortlet>
      </b-card>
      <!--End::community-->
      <b-card class="poschodoch-card" v-if="noticeBoard">
        <!--Begin::notice-board -->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <h3 class="kt-portlet__head-title ">
              <span class="poschodoch-dashboard-title">
                {{ noticeBoardtitle }}
              </span>
            </h3>
          </template>
          <template v-slot:body>
            <b-container class fluid>
              <div class="poschodoch-dashboard-message">
                <span class="poschodoch-dashboard-title">
                  <div
                    class="kt-portlet pl-5 pr-5 pt-5 pb-5"
                    v-if="noticeBoard"
                    v-html="noticeBoard"
                  ></div>
                </span>
              </div>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!noticeBoard">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </b-card>
      <!--End::notice-board-->
      <b-card class="poschodoch-card" v-if="Account_showLoader == 1">
        <!--Begin::Flat account-->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <a
              v-if="flatAccountDueBalance"
              v-bind:href="'/account' + getDetailParameters('account')"
            >
              <h3 class="kt-portlet__head-title poschodoch-dashboard-link">
                <span class="poschodoch-dashboard-title">
                  <slot name="title">{{ flatAccountTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="flatAccountDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </a>
          </template>
          <template v-slot:body>
            <b-container class fluid>
              <div
                class="poschodoch-dashboard-message"
                v-if="flatAccountDueBalance"
              >
                {{ $i18n.t("ACCOUNT.BALANCEON")
                }}{{
                  getFormatService().formatDateToLocal(flatAccountDueDate)
                }}:
                <span
                  v-bind:class="{ 'table-danger': flatAccountDueBalance < 0 }"
                >
                  {{
                    getFormatService().formatNumberToLocal(
                      flatAccountDueBalance,
                    )
                  }}
                  {{ getCurrencySymbol() }}
                </span>
              </div>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!flatAccountDueBalance">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Flat account-->
      </b-card>
      <b-card class="poschodoch-card" v-if="DailyConsumption_showLoader == 1">
        <!--Begin::Daily consumption-->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <a
              v-if="dailyConsumptionChartOptions"
              v-bind:href="
                '/dailyConsumption' + getDetailParameters('DailyConsumption')
              "
            >
              <h3 class="kt-portlet__head-title poschodoch-dashboard-link">
                <span class="poschodoch-dashboard-title">
                  <slot name="title">{{ dailyConsumptionTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="dailyConsumptionDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </a>
          </template>
          <template v-slot:body>
            <b-container fluid v-if="dailyConsumptionChartOptions">
              <!--Begin::Main chart element -->
              <div
                v-if="dailyConsumptionChartOptions"
                class="poschodoch-consumption-graph"
              >
                <div>
                  <Chart
                    ref="daily-consumption-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="dailyConsumptionChartOptions"
                    height="200"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div
                class="poschodoch-legend-sm"
                id="daily-consumption-chart-legend"
              />
              <!--End::Legend -->
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!dailyConsumptionChartOptions">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </b-card>
      <!--End::Daily consumption-->
      <b-card
        class="poschodoch-card"
        v-if="DailyConsumptionHeat_showLoader == 1"
      >
        <!--Begin::Daily consumption Heat-->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <a
              v-if="dailyConsumptionHeatChartOptions"
              v-bind:href="
                '/dailyConsumption' +
                  getDetailParameters('DailyConsumptionHeat')
              "
            >
              <h3 class="kt-portlet__head-title poschodoch-dashboard-link">
                <span class="poschodoch-dashboard-title">
                  <slot name="title">{{ dailyConsumptionHeatTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="dailyConsumptionHeatDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </a>
          </template>
          <template v-slot:body>
            <b-container fluid v-if="dailyConsumptionHeatChartOptions">
              <!--Begin::Main chart element -->
              <div
                v-if="dailyConsumptionHeatChartOptions"
                class="poschodoch-consumption-graph"
              >
                <div>
                  <Chart
                    ref="daily-consumption-heat-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="dailyConsumptionHeatChartOptions"
                    height="200"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <div
                class="poschodoch-legend"
                id="daily-consumption-heat-chart-legend"
              />
              <!--End::Legend -->
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!dailyConsumptionHeatChartOptions">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily consumption Heat-->
      </b-card>
      <b-card class="poschodoch-card" v-if="RepairFund_showLoader == 1">
        <!--Begin::Repair fund-->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <a
              v-if="repairFundItems"
              v-bind:href="'/repairFund' + getDetailParameters('RepairFund')"
            >
              <h3 class="kt-portlet__head-title poschodoch-dashboard-link">
                <span class="poschodoch-dashboard-title">
                  <slot name="title">{{ repairFundTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="repairFundDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </a>
          </template>
          <template v-slot:body>
            <b-container fluid>
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="repairFundItems"
                :fields="repairFundFields"
                class="poschodoch-summary-table"
              >
                <template v-slot:head(Amount)="data">
                  <span>
                    {{ data.label + " [" + getCurrencySymbol() + "]" }}
                  </span>
                </template>
                <template v-slot:cell(DocCount)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.value > 1"
                    v-on:click="showRepairFundDocs(data.item.RecKey)"
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    v-if="data.value == 1"
                    v-on:click="
                      showSingleDocRepF(data.item.RecKey, data.item.Tablename)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                </template>
              </b-table>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!repairFundItems">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Repair fund-->
      </b-card>
      <b-card class="poschodoch-card" v-if="ObjectOfficials_showLoader == 1">
        <!--Begin::Officials-->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <a
              v-if="officialsItems"
              v-bind:href="
                '/objectOfficials' + getDetailParameters('ObjectOfficials')
              "
            >
              <h3 class="kt-portlet__head-title poschodoch-dashboard-link">
                <span class="poschodoch-dashboard-title">
                  <slot name="title">{{ officialsTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="officialsDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </a>
          </template>
          <template v-slot:body>
            <b-container fluid>
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="officialsItems"
                :fields="officialsFields"
                class="poschodoch-summary-table poschodoch-avatar-table"
              >
                <template v-slot:cell(Mobile)="data">
                  <div class="align-self-start">
                    <img
                      v-if="data.value.Photo"
                      v-bind:src="'data:image/png;base64,' + data.value.Photo"
                      width="46"
                      height="56"
                      class="poschodoch-avatar-img"
                    />
                    <img
                      src="@/assets/media/poschodoch-custom/default_user.svg"
                      width="49"
                      height="56"
                      class="poschodoch-avatar-img"
                      v-else
                    />
                  </div>
                  <div
                    class="d-flex flex-column align-items-start justify-content-center w-100 poschodoch-officials-table-content"
                  >
                    <span class="poschodoch-officials-title-mobile">
                      {{ data.value.Name + " (" + data.value.Function + ")" }}
                    </span>
                    <div
                      class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span v-if="data.value.Email">
                          {{ $i18n.t("OBJECTOFFICIALS.TABLE.EMAIL") + ": " }}
                          <a
                            :href="`mailto:${data.value.Email}`"
                            class="kt-link"
                          >
                            {{ data.value.Email }}
                          </a>
                        </span>
                        <span v-if="data.value.Phone">
                          {{
                            $i18n.t("OBJECTOFFICIALS.TABLE.PHONE") +
                              ": " +
                              data.value.Phone
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right"
                      >
                        <span v-if="data.value.Address">
                          {{
                            $i18n.t("OBJECTOFFICIALS.TABLE.ADDRESS") +
                              ": " +
                              data.value.Address
                          }}
                        </span>
                        <span v-if="data.value.Object">
                          {{ data.value.Object }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(Photo)="data">
                  <img
                    v-if="data.value"
                    v-bind:src="'data:image/png;base64,' + data.value"
                    width="49"
                    height="56"
                    class="poschodoch-avatar-img"
                  />
                  <img
                    src="@/assets/media/poschodoch-custom/default_user.svg"
                    width="49"
                    height="56"
                    class="poschodoch-avatar-img"
                    v-else
                  />
                </template>
              </b-table>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!officialsItems">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Officials-->
      </b-card>

      <b-card class="poschodoch-card" v-if="ObjectAccount_showLoader == 1">
        <!--Begin::Object account-->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <a
              v-if="objectAccountItems"
              v-bind:href="
                '/objectAccount' + getDetailParameters('ObjectAccount')
              "
            >
              <h3 class="kt-portlet__head-title poschodoch-dashboard-link">
                <span class="poschodoch-dashboard-title">
                  <slot name="title">{{ objectAccountTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="objectAccountDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </a>
          </template>
          <template v-slot:body>
            <b-container fluid>
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="objectAccountItems"
                :fields="objectAccountFields"
                class="poschodoch-summary-table poschodoch-object-account-table"
                @row-clicked="tableRowAccountClickHandler"
                :tbody-tr-class="pointerAccountRowClass"
              >
                <template v-slot:cell(Mobile)="data">
                  <div
                    class="d-flex flex-column align-items-start justify-content-center w-100 poschodoch-object-account-content"
                  >
                    <!--<div class="poschodoch-dashboard-message">
                      {{ data.value.AccountDescription }}
                    </div>
                    <div class="poschodoch-dashboard-message">
                      {{ $i18n.t("ACCOUNT.BALANCEON")
                      }}{{
                        getFormatService().formatDateToLocal(
                          data.value.IssueDate,
                        )
                      }}:
                      <span v-bind:class="{
                        'table-danger': data.value.FinalBalance < 0,
                      }">
                        {{
                          getFormatService().formatNumberToLocal(
                            data.value.FinalBalance,
                          )
                        }}
                        {{ getCurrencySymbol() }}
                      </span>-->

                    <div
                      class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left poschodoch-dashboard-message"
                      >
                        <span class="ml-2" style="font-weight: 400 !important">
                          {{ data.value.AccountDescription }}
                        </span>
                        <span
                          class="ml-2"
                          style="font-weight: 400 !important"
                          v-if="data.value.FinalBalance"
                        >
                          {{ $i18n.t("ACCOUNT.BALANCEON")
                          }}{{
                            getFormatService().formatDateToLocal(
                              data.value.IssueDate,
                            )
                          }}:
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right poschodoch-dashboard-message"
                      >
                        <span style="font-weight: 400 !important">
                          {{ data.value.BankAccount }}
                        </span>

                        <span
                          v-if="data.value.FinalBalance"
                          v-bind:class="{
                            'table-danger': data.value.FinalBalance < 0,
                          }"
                        >
                          {{
                            getFormatService().formatNumberToLocal(
                              data.value.FinalBalance,
                            )
                          }}
                          {{ getCurrencySymbol() }}
                        </span>
                      </div>
                    </div>

                    <!--</div>-->
                  </div>
                </template>
              </b-table>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!objectAccountItems">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Object account-->
      </b-card>
      <b-card class="poschodoch-card" v-if="Events_showLoader == 1">
        <!--Begin::Events-->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <a
              v-if="eventsItems"
              v-bind:href="'/events' + getDetailParameters('Events')"
            >
              <h3 class="kt-portlet__head-title poschodoch-dashboard-link">
                <span class="poschodoch-dashboard-title">
                  <slot name="title">{{ eventsTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="eventsDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </a>
          </template>
          <template v-slot:body>
            <b-container fluid>
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="eventsItems"
                :fields="eventsFields"
                class="poschodoch-summary-table poschodoch-events-table poschodoch-avatar-table"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Subject)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="
                      showEventsDetail(data.item.EventId, data.item.TEventId)
                    "
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="align-self-start">
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="data.item.Priority == 0"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #0066a1"
                      />
                    </svg>
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="data.item.Priority == 1"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #fbbb17"
                      />
                    </svg>
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="data.item.Priority == 2"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #c5415d"
                      />
                    </svg>
                  </div>
                  <div
                    class="d-flex flex-column align-items-start justify-content-center w-100 poschodoch-officials-table-content"
                  >
                    <span
                      class="poschodoch-officials-title-mobile"
                      style="color: var(--company-color) !important"
                    >
                      <div
                        @click.stop
                        class="h-100 w-100 p-2 d-flex align-items-center justify-content-center"
                        style="cursor: default"
                      >
                        <a
                          href="javascript:void(0)"
                          v-if="data.item != null"
                          v-on:click="
                            showEventsDetail(
                              data.item.EventId,
                              data.item.TEventId,
                            )
                          "
                          class="kt-link"
                        >
                          {{ data.value.Subject }}
                        </a>
                      </div>
                    </span>
                    <div
                      class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.Date,
                            ) +
                              ", " +
                              data.value.Responsible
                          }}
                        </span>
                      </div>
                      <div
                        v-if="data.item.DocCount"
                        class="d-flex flex-column align-items-end poschodoch-mobile-right"
                      >
                        <a
                          v-if="data.item.DocCount > 1"
                          href="javascript:void(0)"
                          v-on:click="
                            showCostDetailDocs(
                              data.item.EventId,
                              data.item.TEventId,
                              data.item.TableName,
                            )
                          "
                          class="poschodoch-icon-link p-2"
                        >
                          <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          v-if="data.item.DocCount == 1"
                          v-on:click="
                            showSingleDocEv(
                              data.item.EventId,
                              data.item.TEventId,
                              data.item.TableName,
                            )
                          "
                          class="poschodoch-icon-link p-2"
                        >
                          <i
                            class="flaticon-download poschodoch-icon-medium"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(Rectangle)="data">
                  <svg width="3px" height="3rem" v-if="data.item.Priority == 0">
                    <rect
                      width="3px"
                      height="3rem"
                      class="poschodoch-rectangle"
                      style="fill: #0066a1"
                    />
                  </svg>
                  <svg width="3px" height="3rem" v-if="data.item.Priority == 1">
                    <rect
                      width="3px"
                      height="3rem"
                      class="poschodoch-rectangle"
                      style="fill: #fbbb17"
                    />
                  </svg>
                  <svg width="3px" height="3rem" v-if="data.item.Priority == 2">
                    <rect
                      width="3px"
                      height="3rem"
                      class="poschodoch-rectangle"
                      style="fill: #c5415d"
                    />
                  </svg>
                </template>
              </b-table>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!eventsItems">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Events-->
      </b-card>
      <b-card class="poschodoch-card" v-if="ConsumptionStatus_showLoader == 1">
        <!--Begin::Budiky Studena-->
        <div>
          <KTPortlet style="display: inline">
            <template v-slot:title>
              <h3
                v-if="budikyItems"
                class="kt-portlet__head-title poschodoch-dashboard-link"
              >
                <span
                  class="poschodoch-dashboard-title"
                  style="text-decoration: none"
                >
                  <slot name="title">{{ budikyTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="budikyDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </template>
            <template v-slot:body>
              <div v-if="budikyItems" class="d-flex justify-content-center">
                <vue-speedometer
                  :forceRender="true"
                  :segments="2"
                  :height="200"
                  :width="300"
                  needleColor="black"
                  valueFormat="d"
                  :needleHeightRatio="0.7"
                  :segmentColors="['#0abb87', '#C5415D']"
                  :value="getBudikyValue(budikyPercConsumption)"
                  :maxValue="100"
                  :minValue="-100"
                  :customSegmentStops="[-100, 0, 100]"
                  :currentValueText="budikyValueText"
                  :maxSegmentLabels="0"
                  valueTextFontSize="1.1rem"
                  valueTextFontWeight="400"
                  class="domus-budiky-text"
                  textColor="#48465b"
                />
              </div>
              <!--Begin::Loader -->
              <b-container fluid v-if="!budikyItems">
                <Loader />
              </b-container>
              <!--End::Loader -->
            </template>
          </KTPortlet>
        </div>
        <!--End::Budiky Studena-->
      </b-card>
      <b-card class="poschodoch-card" v-if="ConsumptionStatus_showLoader == 1">
        <div>
          <!--Begin::Budiky Tepla-->
          <KTPortlet style="display: inline">
            <template v-slot:title>
              <h3
                v-if="budikyHotItems"
                class="kt-portlet__head-title poschodoch-dashboard-link"
              >
                <span
                  class="poschodoch-dashboard-title"
                  style="text-decoration: none"
                >
                  <slot name="title">{{ budikyHotTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="budikyHotDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </template>
            <template v-slot:body>
              <div v-if="budikyHotItems" class="d-flex justify-content-center">
                <vue-speedometer
                  :forceRender="true"
                  :segments="2"
                  :height="200"
                  :width="300"
                  needleColor="black"
                  valueFormat="d"
                  :needleHeightRatio="0.7"
                  :segmentColors="['#0abb87', '#C5415D']"
                  :value="getBudikyValue(budikyHotPercConsumption)"
                  :maxValue="100"
                  :minValue="-100"
                  :customSegmentStops="[-100, 0, 100]"
                  :currentValueText="budikyHotValueText"
                  :maxSegmentLabels="0"
                  valueTextFontSize="1.1rem"
                  valueTextFontWeight="400"
                  class="domus-budiky-text"
                  textColor="#48465b"
                />
              </div>
              <!--Begin::Loader -->
              <b-container fluid v-if="!budikyHotItems">
                <Loader />
              </b-container>
              <!--End::Loader -->
            </template>
          </KTPortlet>
          <!--End::Budiky Tepla-->
        </div>
      </b-card>
      <b-card class="poschodoch-card" v-if="ConsumptionStatus3_showLoader == 1">
        <!--Begin::Budiky Heat-->

        <KTPortlet style="display: inline">
          <template v-slot:title>
            <h3
              v-if="budikyHeatItems"
              class="kt-portlet__head-title poschodoch-dashboard-link"
            >
              <span
                class="poschodoch-dashboard-title"
                style="text-decoration: none"
              >
                <slot name="title">{{ budikyHeatTitle }}</slot>
              </span>
              <span
                class="poschodoch-note-list"
                v-b-tooltip.hover
                :title="budikyHeatDescription"
              >
                <span class="poschodoch-note ml-2"></span>
              </span>
            </h3>
          </template>
          <template v-slot:body>
            <div
              v-if="budikyHeatItems"
              class="col-12 d-flex justify-content-center"
            >
              <vue-speedometer
                :forceRender="true"
                :segments="2"
                :height="200"
                :width="300"
                needleColor="black"
                valueFormat="d"
                :needleHeightRatio="0.7"
                :segmentColors="['#0abb87', '#C5415D']"
                :value="getBudikyValue(budikyHeatPercConsumption)"
                :maxValue="100"
                :minValue="-100"
                :customSegmentStops="[-100, 0, 100]"
                :currentValueText="budikyHeatValueText"
                :maxSegmentLabels="0"
                valueTextFontSize="1.1rem"
                valueTextFontWeight="400"
                class="domus-budiky-text"
                textColor="#48465b"
              />
            </div>
            <!--Begin::Loader -->
            <b-container fluid v-if="!budikyHeatItems">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </b-card>
      <b-card class="poschodoch-card" v-if="Partners_showLoader == 1">
        <!--Begin::Partners -->
        <KTPortlet style="display: inline">
          <template v-slot:title>
            <a
              v-if="partnersItems"
              v-bind:href="'/partnersUser' + getDetailParameters('Partners')"
            >
              <h3 class="kt-portlet__head-title poschodoch-dashboard-link">
                <span class="poschodoch-dashboard-title">
                  <slot name="title">{{ partnersTitle }}</slot>
                </span>
                <span
                  class="poschodoch-note-list"
                  v-b-tooltip.hover
                  :title="partnersDescription"
                >
                  <span class="poschodoch-note ml-2"></span>
                </span>
              </h3>
            </a>
          </template>
          <template v-slot:body>
            <b-container fluid>
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="partnersItems"
                :fields="partnersFields"
                class="poschodoch-summary-table poschodoch-avatar-table"
              >
                <template v-slot:cell(Mobile)="data">
                  <div class="align-self-start text-left" style="width: 40%">
                    {{ data.value.Description }}
                  </div>
                  <div
                    class="d-flex flex-column align-items-start justify-content-center w-100 poschodoch-officials-table-content"
                  >
                    <span class="poschodoch-officials-title-mobile">
                      {{ data.value.Partner }}
                    </span>
                    <div
                      class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span v-if="data.value.WWW">
                          {{ $i18n.t("OBJECTOFFICIALS.TABLE.WWW") + ": " }}
                          <a
                            :href="'//' + data.value.WWW"
                            target="_blank"
                            class="kt-link"
                          >
                            {{ data.value.WWW }}
                          </a>
                        </span>
                        <span v-if="data.value.Email">
                          {{ $i18n.t("OBJECTOFFICIALS.TABLE.EMAIL") + ": " }}
                          <a
                            :href="`mailto:${data.value.Email}`"
                            class="kt-link"
                          >
                            {{ data.value.Email }}
                          </a>
                        </span>
                        <span v-if="data.value.Telephone">
                          {{
                            $i18n.t("OBJECTOFFICIALS.TABLE.PHONE") +
                              ": " +
                              data.value.Telephone
                          }}
                        </span>
                        <span v-if="data.value.Detail">
                          {{ data.value.Detail }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!partnersItems">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Partners-->
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Chart from "@/views/components/Chart.vue";
import JwtService from "@/common/jwt.service";
import { mapGetters } from "vuex";
import VueSpeedometer from "vue-speedometer";
import FileService from "@/common/file.service";
import RepairFundDocs from "@/views/pages/domus/RepairFundDocs.vue";
import EventsDetailDocs from "@/views/pages/domus/EventsDetailDocs.vue";
import EventsDetailDocsDwp from "@/views/pages/domus/EventsDetailDocsDwp.vue";
import Loader from "@/views/components/Loader.vue";
import { LOGOUT } from "@/store/auth.module";
import MenuService from "@/common/menu.service";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    Chart,
    VueSpeedometer,
    RepairFundDocs,
    EventsDetailDocs,
    EventsDetailDocsDwp,
    Loader,
  },
  data() {
    return {
      //loadre
      Account_showLoader: 0,
      DailyConsumption_showLoader: 0,
      DailyConsumptionHeat_showLoader: 0,
      RepairFund_showLoader: 0,
      ObjectOfficials_showLoader: 0,
      ObjectAccount_showLoader: 0,
      ConsumptionStatus_showLoader: 0,
      ConsumptionStatus2_showLoader: 0,
      ConsumptionStatus3_showLoader: 0,
      Events_showLoader: 0,
      Partners_showLoader: 0,
      //
      menuMappings: {},
      charts: [2],
      errors: [],

      // daily consumption
      dailyConsumptionTitle: "title",
      dailyConsumptionDescription: "description",
      dailyConsumptionHeatTitle: "title",
      dailyConsumptionHeatDescription: "description",
      dailyConsumptionChartOptions: null,
      dailyConsumptionHeatChartOptions: null,
      dailyConsumptionChartLabels: [],
      dailyConsumptionHeatChartLabels: [],
      dailyConsumptionChartDataHotWater: null,
      dailyConsumptionChartDataColdWater: null,
      dailyConsumptionChartDataHeat: null,
      dailyConsumptionChartDatasetLabelHotWater: this.$i18n.t(
        "DAILY_CONSUMPTION.CHART_WATER.HOT_WATER",
      ),
      dailyConsumptionChartDatasetLabelColdWater: this.$i18n.t(
        "DAILY_CONSUMPTION.CHART_WATER.COLD_WATER",
      ),
      dailyConsumptionChartDatasetLabelHeat: this.$i18n.t(
        "DAILY_CONSUMPTION.CHART_WATER.HEAT",
      ),

      chartDatasetLabelHotWater: this.$i18n.t(
        "DAILY_CONSUMPTION.CHART_WATER.HOT_WATER",
      ),
      chartDatasetLabelColdWater: this.$i18n.t(
        "DAILY_CONSUMPTION.CHART_WATER.COLD_WATER",
      ),

      dailyConsumptionYear: null,
      dailyConsumptionData: null,
      dailyConsumptionHeatYear: null,
      dailyConsumptionHeatData: null,
      // flat account
      flatAccountTitle: null,
      flatAccountDescription: this.$i18n.t("ACCOUNT.DASHBOARD_TOOLTIP"),
      flatAccountDueDate: null,
      flatAccountDueBalance: null,
      // repairFund
      repairFundTitle: "title",
      repairFundDescription: "description",
      repairFundItems: null,
      repairFundDocs: null,
      repairFundFields: [
        {
          key: "Description",
          label: this.$i18n.t("REPAIRFUND.TABLE.DESCRIPTION"),
          sortable: false,
        },
        {
          key: "Amount",
          label: this.$i18n.t("REPAIRFUND.TABLE.AMOUNT"),
          sortable: false,
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center poschodoch-repFund-docCount",
        },
      ],
      // officials
      officialsTitle: "title",
      officialsDescription: "description",
      officialsItems: null,
      officialsFields: [
        {
          key: "Photo",
          label: "",
          class: "poschodoch-avatar-column table-no-mobile",
          sortable: false,
        },
        {
          key: "Name",
          label: "Name",
          sortable: false,
          class: "table-no-mobile",
        },
        {
          key: "Function",
          label: "Function",
          sortable: false,
          class: "table-no-mobile",
        },
        {
          key: "Phone",
          label: "Phone",
          sortable: false,
          class: "table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
      // object account
      objectAccountTitle: "title",
      objectAccountDescription: "description",
      objectBankAccount: null,
      objectAccountItems: null,
      objectDetail: null,
      objectAccountFields: [
        {
          key: "AccountDescription",
          label: "AccountDescription",
          sortable: false,
          class: "table-no-mobile",
        },
        {
          key: "BankAccount",
          label: "BankAccount",
          sortable: false,
          class: "table-no-mobile",
        },
        {
          key: "FinalBalance",
          label: this.addCurrencyToColumn("FinalBalance"),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "IssueDate",
          label: "IssueDate",
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
      // budiky Studena
      budikyTitle: "title",
      budikyDescription: "description",
      budikyItems: null,
      budikyActualConsumption: null,
      budikyDiffConsumption: null,
      budikyPercConsumption: null,
      budikyValueText: null,
      budikyLastReadingDate: null,
      // budiky Tepla
      budikyHotTitle: "title",
      budikyHotDescription: "description",
      budikyHotItems: null,
      budikyHotActualConsumption: null,
      budikyHotDiffConsumption: null,
      budikyHotPercConsumption: null,
      budikyHotValueText: null,
      budikyHotLastReadingDate: null,
      // budiky Heat
      budikyHeatTitle: "title",
      budikyHeatDescription: "description",
      budikyHeatItems: null,
      budikyHeatActualConsumption: null,
      budikyHeatDiffConsumption: null,
      budikyHeatPercConsumption: null,
      budikyHeatValueText: null,
      budikyHeatLastReadingDate: null,
      // events
      eventsItems: null,
      eventsTitle: "title",
      eventsDescription: "description",
      eventsDetailDocs: null,
      eventsDetailDocsDwp: null,
      eventsFields: [
        {
          key: "Rectangle",
          label: "",
          sortable: false,
          class: "table-no-mobile",
        },
        {
          key: "Subject",
          label: this.$i18n.t("EVENTS.TABLE.SUBJECT"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Responsible",
          label: this.$i18n.t("EVENTS.TABLE.RESPONSIBLE"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Date",
          label: this.$i18n.t("EVENTS.TABLE.DATE"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
      //community
      communityTitle: "title",
      communityDescription: "description",
      communityInfo: null,
      communityInfoStreet: null,
      communityInfoCity: null,
      communityInfoIco: null,
      communityInfoData: null,
      communityInfowww: null,
      communityInfoemail: null,
      communityInfomobil: null,

      //noticeBoard
      noticeBoard: null,
      // partners
      partnersTitle: "title",
      partnersDescription: "description",
      partnersItems: null,
      partnersFields: [
        {
          key: "Description",
          label: this.$i18n.t("BIDS.TABLE.CATEGORY2"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Partner",
          label: this.$i18n.t("BIDS.TABLE.CATEGORY2"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Telephone",
          label: this.$i18n.t("BIDS.TABLE.STATUS"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Email",
          label: this.$i18n.t("BIDS.TABLE.DATEFROM"),
          sortable: false,
          class: " table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "WWW",
          label: this.$i18n.t("BIDS.TABLE.DATETO"),
          sortable: false,
          class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Detail",
          label: this.$i18n.t("BIDS.TABLE.DATETO"),
          sortable: false,
          class: "table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showEventsDetail(record.EventId, record.TEventId);
    },
    tableRowAccountClickHandler(record) {
      if (this.objectDetail === 1) {
        this.showAccountDetail(record.AccountId);
      }
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    pointerAccountRowClass() {
      // use item parameter if condition is needed
      if (this.objectDetail === 1) {
        return "pointer-row";
      } else {
        return "";
      }
    },
    getCurrencySymbol() {
      return FormatService.formatCurrency(JwtService.getCurrency());
    },
    addCurrencyToColumn(column) {
      return column + " [" + this.getCurrencySymbol() + "]";
    },
    dailyConsumptionCreateChart() {
      // Use the hot and cold water data directly from the instance properties
      const hotWaterData = this.dailyConsumptionChartDataHotWater.map((item) =>
        parseFloat(item.y),
      );
      const coldWaterData = this.dailyConsumptionChartDataColdWater.map(
        (item) => parseFloat(item.y),
      );

      // Get the labels from the 'x' field, assuming both hot and cold water data have the same dates
      const labels = this.dailyConsumptionChartDataHotWater
        .filter((item, index, self) => {
          // Filter to ensure only unique dates are used
          return self.findIndex((i) => i.x === item.x) === index;
        })
        .map((item) => {
          // Format the date as DD.MM.YYYY
          const [year, month, day] = item.x.split("-");
          return `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}`;
        });

      // Create the chart with both hot and cold water data
      this.charts = [this.charts[0] + 1]; // Increment chart ID for Vue's reactive updates
      this.dailyConsumptionChartOptions = {
        type: "bar",
        data: {
          datasets: [
            {
              data: hotWaterData,
              label: this.dailyConsumptionChartDatasetLabelHotWater,
              fill: false,
              borderColor: FormatService.getRebrandColorGraf("hot"),
              backgroundColor: FormatService.getRebrandColorGraf("hot"),
            },
            {
              data: coldWaterData,
              label: this.dailyConsumptionChartDatasetLabelColdWater,
              fill: false,
              borderColor: FormatService.getRebrandColorGraf("cold"),
              backgroundColor: FormatService.getRebrandColorGraf("cold"),
            },
          ],
          labels: labels,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendDiv: "daily-consumption-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: FormatService.getRebrandColorGraf("hot"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem) => {
                const dataIndex = tooltipItem[0].index;
                const dateStr = this.dailyConsumptionChartDataHotWater[
                  dataIndex
                ].x; // Get the date from hot water data
                const [year, month, day] = dateStr.split("-");
                const formattedDay = day.padStart(2, "0");
                const formattedMonth = month.padStart(2, "0");
                return `${formattedDay}.${formattedMonth}.${year}`;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION.CHART_WATER.AX_X",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION.CHART_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    dailyConsumptionHeatCreateChart() {
      this.charts = [this.charts[0] + 1];
      this.dailyConsumptionHeatChartOptions = {
        type: "bar",
        data: {
          datasets: [
            {
              data: this.dailyConsumptionChartDataHeat,
              label: this.dailyConsumptionChartDatasetLabelHeat,
              fill: false,
              borderColor: FormatService.getRebrandColorGraf("heat"),
              backgroundColor: FormatService.getRebrandColorGraf("heat"),
            },
          ],
          labels: this.dailyConsumptionHeatChartLabels,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          //  legendDiv: "daily-consumption-chart-legend",
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: FormatService.getRebrandColorGraf("hot"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem /* , data */) => {
                let title = tooltipItem[0].label + ".";
                if (
                  this.dailyConsumptionHeatData[
                    tooltipItem[0].index
                  ].Date.substring(5, 6) == 0
                ) {
                  title += this.dailyConsumptionHeatData[
                    tooltipItem[0].index
                  ].Date.substring(6, 7);
                } else
                  title += this.dailyConsumptionHeatData[
                    tooltipItem[0].index
                  ].Date.substring(5, 7);

                title += "." + this.dailyConsumptionHeatYear;

                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION.CHART_WATER.AX_X_HEAT",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION.CHART_WATER.AX_Y_HEAT",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    dailyConsumptionTransformData(data) {
      let consumptionHotWater = [];
      let consumptionColdWater = [];
      let labels = [];

      if (data != null)
        for (let i = 0; i < data.length; i++) {
          if (data[i].Code == "T") {
            consumptionHotWater.push({
              x: data[i].Date,
              y: data[i].Consumption,
            });
            labels.push(data[i].Date.substring(8, 10));
            this.dailyConsumptionChartDatasetLabelHotWater = data[i].Type;
          }
          if (data[i].Code == "S") {
            consumptionColdWater.push({
              x: data[i].Date,
              y: data[i].Consumption,
            });
            this.dailyConsumptionChartDatasetLabelColdWater = data[i].Type;
          }
        }
      this.dailyConsumptionChartLabels = labels;
      this.dailyConsumptionChartDataHotWater = consumptionHotWater;
      this.dailyConsumptionChartDataColdWater = consumptionColdWater;
    },
    dailyConsumptionHeatTransform(data) {
      var result = [];
      if (data != null) {
        result = data.reduce((acc, val) => {
          if (!acc[val.Date]) {
            acc[val.Date] = {
              Date: val.Date,
              Consumption: 0,
              Day: val.Day,
              Code: "U",
              Type: "Teplo",
            };
            result.push(acc[val.Date]);
          }
          acc[val.Date].Consumption +=
            val.Consumption === null ? 0 : +val.Consumption;

          return acc;
        }, {});
      }
      return Object.values(result);
    },
    dailyConsumptionHeatTransformData(data) {
      let consumptionHeat = [];
      let labels = [];

      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].Code == "U") {
            consumptionHeat.push({
              x: data[i].Date,
              y: data[i].Consumption,
            });
            labels.push(data[i].Date.substring(8, 10));
            this.dailyConsumptionChartDatasetLabelHeat = data[i].Type;
          }
        }
      }
      this.dailyConsumptionHeatChartLabels = labels;
      this.dailyConsumptionChartDataHeat = consumptionHeat;
      //this.dailyConsumptionHeatData = data;
    },
    getDetailParameters(menuCode) {
      let parameters = "";
      parameters += "?menuId=" + this.menuMappings[menuCode];
      return parameters;
    },
    getApiCallParameters(menuCode) {
      let parameters = "?preview=1";

      if (menuCode == "DailyConsumption") {
        parameters += "&type=S";
      }
      if (menuCode == "DailyConsumptionHeat") {
        parameters += "&type=U";
        parameters += "&menuId=" + this.menuMappings["DailyConsumption"];
      } else {
        parameters += "&menuId=" + this.menuMappings[menuCode];
      }

      return parameters;
    },
    getBudikyValue(percentage) {
      let PercV = 0;
      PercV = percentage;
      if (percentage > 1) PercV = 1;
      if (percentage < -1) PercV = -1;

      return parseFloat(PercV * 100);
    },
    showAccountDetail(accountId) {
      this.$router.push({
        name: "domus-object-account-detail",
        query: {
          menuId: this.menuMappings["ObjectAccount"],
          accountId: accountId,
        },
      });
    },
    showEventsDetail(eventID, TeventID) {
      if (eventID) {
        this.$router.push({
          name: "domus-events-detail",
          query: { menuId: this.menuMappings["Events"], eventId: eventID },
        });
      }
      if (TeventID) {
        this.$router.push({
          name: "domus-events-detail",
          query: { menuId: this.menuMappings["Events"], teventId: TeventID },
        });
      }
    },
    showRepairFundDocs(recKey) {
      let parameters =
        "?tableName=faktury&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuMappings["RepairFund"];

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.repairFundDocs = data;
        this.$bvModal.show("modal-repairFund-docs");
      });
    },
    showSingleDocRepF(recKey, tableName) {
      if (!recKey) {
        console.log("Nie je zadaný recKey", recKey, tableName);
        return;
      }

      let parameters = this.getDetailParameters("RepairFund");
      parameters +=
        "&tableName=" + tableName.toString() + "&recKey=" + recKey.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.repairFundDocs = data;

        parameters =
          "?docId=" + this.repairFundDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.repairFundDocs.Documents[0].FileName;

          FileService.downloadFile(
            fileName,
            binaryContent,
            null,
            "document-download-link",
          );
        });
      });
    },
    showCostDetailDocs(EventId, TEventId, tableName) {
      /*let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuMappings["Events"];*/
      let parameters = "?menuId=" + this.menuMappings["Events"];

      /*ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.eventsDetailDocs = data;
        this.$bvModal.show("modal-eventsDetail-docs");
      });*/
      if (EventId) {
        parameters +=
          "&recKey=" +
          EventId.toString() +
          "&tableName=" +
          tableName.toString();
        ApiService.setHeader();
        ApiService.get("Document", "List" + parameters).then(({ data }) => {
          this.eventsDetailDocs = data;
          this.$bvModal.show("modal-eventsDetail-docs");
        });
      }
      if (TEventId) {
        parameters += "&recKey=" + TEventId.toString() + "&tableName=t_udalost";
        ApiService.setHeader();
        ApiService.get("Document", "DWPList" + parameters).then(({ data }) => {
          this.eventsDetailDocsDwp = data;
          this.$bvModal.show("modal-eventsDetail-docs-dwp");
        });
      }
    },
    showSingleDocEv(EventId, TEventId, tableName) {
      /*let parameters = this.getDetailParameters("Events");
      parameters +=
        "&tableName=" + tableName.toString() + "&recKey=" + recKey.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.eventsDetailDocs = data;

        parameters =
          "?docId=" + this.eventsDetailDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.eventsDetailDocs.Documents[0].FileName;

          FileService.downloadFile(
            fileName,
            binaryContent,
            null,
            "document-download-link",
          );
        });
      });*/
      let parameters = this.getDetailParameters("Events");
      let aApi = "";
      let aType = "";
      if (EventId) {
        parameters += "&recKey=" + EventId.toString();
        aApi = "";
        aType = "";
        parameters += "&tableName=" + tableName.toString();
      }
      if (TEventId) {
        parameters += "&recKey=" + TEventId.toString();
        aApi = "DWP";
        aType = "&type=t_udalost";
        parameters += "&tableName=t_udalost";
      }

      ApiService.setHeader();
      ApiService.get("Document", aApi + "List" + parameters).then(
        ({ data }) => {
          this.eventsDetailDocs = data;

          parameters =
            "?docId=" +
            this.eventsDetailDocs.Documents[0].DocId.toString() +
            aType;

          ApiService.setHeader();
          ApiService.get("Document", "Get" + aApi + parameters).then(
            ({ data }) => {
              let binaryContent = FileService.convertBase64ToByteArray(
                data.FileContent,
              );

              let fileName = this.eventsDetailDocs.Documents[0].FileName;

              FileService.downloadFile(
                fileName,
                binaryContent,
                null,
                "document-download-link",
              );
            },
          );
        },
      );
    },
    fetchData() {
      this.menuMappings = {};
      this.officialsItems = null;
      this.eventsItems = null;
      this.eventsItems1 = null;
      this.eventsItems2 = null;
      this.itemsSet1 = null;
      this.itemsSet2 = null;

      let parameters = "";

      if (!JwtService.getToken() || !JwtService.getUnit()) {
        this.$router.push({ name: "login" });
        this.$store.dispatch(LOGOUT);
      }

      MenuService.setMenuStart();

      ApiService.setHeader();

      parameters = "?preview=1";
      ApiService.get("Dashboard", "Menu" + parameters)
        .then(({ data }) => {
          // preview menu
          for (let i = 0; i < data.length; i++) {
            this.menuMappings[data[i].MenuCode] = data[i].MenuId;
          }
          // noticeBoard
          //ApiService.setHeader();
          ApiService.get("Dashboard/CompanyDashboard")
            .then(({ data }) => {
              this.noticeBoardtitle = data.Name;
              this.noticeBoard = data.HtmlContent;
            })
            .catch(({ response }) => {
              if (response.data.error)
                this.errors.push(response.data.error + " (noticeBoard)");
              else
                this.errors.push(
                  this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (DailyConsumption)",
                );
            });
          /*ApiService.get("Dashboard", "CompanyMenuDetail??companyMenuId=144")
            .then(({ data }) => {
                this.noticeBoard = data.noticeBoard;
              })*/

          // daily consumption
          if (this.menuMappings["DailyConsumption"] != null) {
            this.DailyConsumption_showLoader = 1;
            parameters = this.getApiCallParameters("DailyConsumption");
            ApiService.get("Flat", "DailyConsumption" + parameters)
              .then(({ data }) => {
                this.dailyConsumptionTransformData(data.Consumption);
                this.dailyConsumptionCreateChart();
                this.dailyConsumptionTitle = data.Title;
                this.dailyConsumptionDescription = data.Description;
                this.dailyConsumptionYear = data.Year;
                this.dailyConsumptionData = data.Consumption;
                if (data.Consumption.length <= 0) {
                  this.DailyConsumption_showLoader = 0;
                }
              })
              .catch(({ response }) => {
                this.DailyConsumption_showLoader = 0;
                if (response.data.error)
                  this.errors.push(response.data.error + " (DailyConsumption)");
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") +
                      " (DailyConsumption)",
                  );
              });
            this.DailyConsumptionHeat_showLoader = 1;
            parameters = this.getApiCallParameters("DailyConsumptionHeat");
            ApiService.get("Flat", "DailyConsumption" + parameters)
              .then(({ data }) => {
                let consum = this.dailyConsumptionHeatTransform(
                  data.Consumption,
                );
                //this.dailyConsumptionHeatTransformData(data.Consumption);
                this.dailyConsumptionHeatTransformData(consum);
                this.dailyConsumptionHeatCreateChart();
                this.dailyConsumptionHeatTitle = data.Title;
                this.dailyConsumptionHeatDescription = data.Description;
                this.dailyConsumptionHeatYear = data.Year;
                //this.dailyConsumptionHeatData = data.Consumption;
                this.dailyConsumptionHeatData = consum;
                if (data.Consumption.length <= 0) {
                  this.DailyConsumptionHeat_showLoader = 0;
                }
              })
              .catch(({ response }) => {
                this.DailyConsumptionHeat_showLoader = 0;
                if (response.data.error)
                  this.errors.push(
                    response.data.error + " (DailyConsumptionHeat)",
                  );
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") +
                      " (DailyConsumptionHeat)",
                  );
              });
          }
          // flat account
          if (this.menuMappings["account"] != null) {
            this.Account_showLoader = 1;
            parameters = this.getApiCallParameters("account");
            ApiService.get("Flat", "Account" + parameters)
              .then(({ data }) => {
                this.flatAccountTitle = data.Title;
                //this.flatAccountDescription = data.Description;
                this.flatAccountDueDate = data.DueDate;
                this.flatAccountDueBalance = data.DueBalance;
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(response.data.error + " (Account)");
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (Account)",
                  );
              });
          }

          // repair fund
          if (this.menuMappings["RepairFund"] != null) {
            this.RepairFund_showLoader = 1;
            parameters = this.getApiCallParameters("RepairFund");
            ApiService.get("Object", "RepairFund" + parameters)
              .then(({ data }) => {
                this.repairFundTitle = data.Title;
                this.repairFundDescription = data.Description;
                this.repairFundItems = data.RepairFund;
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(response.data.error + " (RepairFund)");
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (RepairFund)",
                  );
              });
          }

          // object officials
          if (this.menuMappings["ObjectOfficials"] != null) {
            this.ObjectOfficials_showLoader = 1;
            parameters = this.getApiCallParameters("ObjectOfficials");
            ApiService.get("Object", "ObjectOfficials" + parameters)
              .then(({ data }) => {
                this.transformObjectOfficialsData(data.ObjectOfficials);

                this.officialsTitle = data.Title;
                this.officialsDescription = data.Description;
                this.officialsItems = data.ObjectOfficials;
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(response.data.error + " (ObjectOfficials)");
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") +
                      " (ObjectOfficials)",
                  );
              });
          }

          // object account
          if (this.menuMappings["ObjectAccount"] != null) {
            this.ObjectAccount_showLoader = 1;
            parameters = this.getApiCallParameters("ObjectAccount");
            ApiService.get("Object", "ObjectAccount" + parameters)
              .then(({ data }) => {
                this.transformObjectAccountData(data.ObjectAccount);

                this.objectAccountTitle = data.Title;
                this.objectAccountDescription = data.Description;
                this.objectAccountItems = data.ObjectAccount;
                this.objectDetail = data.Detail;
                //this.objectBankAccount = data.BankAccount;
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(response.data.error + " (ObjectAccount)");
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (ObjectAccount)",
                  );
              });
          }

          // budiky (studena)
          if (this.menuMappings["ConsumptionStatus"] != null) {
            this.ConsumptionStatus_showLoader = 1;
            parameters =
              this.getDetailParameters("ConsumptionStatus") + "&type=S";
            ApiService.get("Flat", "ConsumptionStatus" + parameters)
              .then(({ data }) => {
                this.budikyTitle = data.Title;
                this.budikyDescription = data.Description;
                this.budikyItems = data;
                if (!this.budikyItems) this.ConsumptionStatus_showLoader = 0;
                this.budikyActualConsumption = parseFloat(
                  data.ActualConsumption,
                );
                this.budikyDiffConsumption = parseFloat(data.DiffConsumption);
                this.budikyPercConsumption = parseFloat(data.PercConsumption);
                this.budikyLastReadingDate = data.LastReadingDate;

                this.budikyValueText =
                  this.$i18n.t("SPEEDOMETER.VALUETEXT") +
                  this.getFormatService().formatNumberToLocal(
                    this.budikyActualConsumption,
                  ) +
                  " " +
                  data.Unit +
                  " (";
                if (this.budikyPercConsumption > 0) this.budikyValueText += "+";
                this.budikyValueText +=
                  Math.round(this.budikyPercConsumption * 100) + "%)";
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(
                    response.data.error + " (ConsumptionStatus)",
                  );
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") +
                      " (ConsumptionStatus)",
                  );
              });
          }

          // budiky (tepla)
          if (this.menuMappings["ConsumptionStatus"] != null) {
            this.ConsumptionStatus2_showLoader = 1;
            parameters =
              this.getDetailParameters("ConsumptionStatus") + "&type=T";
            ApiService.get("Flat", "ConsumptionStatus" + parameters)
              .then(({ data }) => {
                this.budikyHotTitle = data.Title;
                this.budikyHotDescription = data.Description;
                this.budikyHotItems = data;
                if (!this.budikyHotItems)
                  this.ConsumptionStatus2_showLoader = 0;
                this.budikyHotActualConsumption = parseFloat(
                  data.ActualConsumption,
                );
                this.budikyHotDiffConsumption = parseFloat(
                  data.DiffConsumption,
                );
                this.budikyHotPercConsumption = parseFloat(
                  data.PercConsumption,
                );
                this.budikyHotLastReadingDate = data.LastReadingDate;

                this.budikyHotValueText =
                  this.$i18n.t("SPEEDOMETER.VALUETEXT") +
                  this.getFormatService().formatNumberToLocal(
                    this.budikyHotActualConsumption,
                  ) +
                  " " +
                  data.Unit +
                  " (";
                if (this.budikyHotPercConsumption > 0)
                  this.budikyHotValueText += "+";
                this.budikyHotValueText +=
                  Math.round(this.budikyHotPercConsumption * 100) + "%)";
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(
                    response.data.error + " (ConsumptionStatus)",
                  );
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") +
                      " (ConsumptionStatus)",
                  );
              });
          }
          // budiky (heat)
          if (this.menuMappings["ConsumptionStatus"] != null) {
            this.ConsumptionStatus3_showLoader = 1;
            parameters =
              this.getDetailParameters("ConsumptionStatus") + "&type=U";
            ApiService.get("Flat", "ConsumptionStatus" + parameters)
              .then(({ data }) => {
                this.budikyHeatTitle = data.Title;
                this.budikyHeatDescription = data.Description;
                this.budikyHeatItems = data;
                if (!this.budikyHeatItems)
                  this.ConsumptionStatus3_showLoader = 0;
                this.budikyHeatActualConsumption = parseFloat(
                  data.ActualConsumption,
                );
                this.budikyHeatDiffConsumption = parseFloat(
                  data.DiffConsumption,
                );
                this.budikyHeatPercConsumption = parseFloat(
                  data.PercConsumption,
                );
                this.budikyHeatLastReadingDate = data.LastReadingDate;

                this.budikyHeatValueText =
                  this.$i18n.t("SPEEDOMETER.VALUETEXT") +
                  this.getFormatService().formatNumberToLocal(
                    this.budikyHeatActualConsumption,
                  ) +
                  " " +
                  data.Unit +
                  " (";
                if (this.budikyHeatPercConsumption > 0)
                  this.budikyHeatValueText += "+";
                this.budikyHeatValueText +=
                  Math.round(this.budikyHeatPercConsumption * 100) + "%)";
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(
                    response.data.error + " (ConsumptionStatus)",
                  );
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") +
                      " (ConsumptionStatus)",
                  );
              });
          }
          // events
          if (this.menuMappings["Events"] != null) {
            this.Events_showLoader = 1;
            parameters = this.getApiCallParameters("Events");
            ApiService.get("Object", "Events" + parameters)
              .then(({ data }) => {
                this.transformEventsData(data.Events);

                this.eventsTitle = data.Title;
                this.eventsDescription = data.Description;
                this.eventsItems1 = data.Events;
                this.itemsSet1 = 1;

                if (this.itemsSet2 === 1) {
                  this.eventsItems = this.eventsItems1
                    .concat(this.eventsItems2)
                    .sort(function(a, b) {
                      return new Date(b.Date) - new Date(a.Date);
                    });
                  this.eventsItems = this.eventsItems.slice(0, 10);
                }
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(response.data.error + " (Events)");
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (Events)",
                  );
              });
            ApiService.get("Object", "EventsPortal" + parameters)
              .then(({ data }) => {
                this.transformEventsData(data.Events);

                this.eventsTitle = data.Title;
                this.eventsDescription = data.Description;
                this.eventsItems2 = data.Events;
                this.itemsSet2 = 1;

                if (this.itemsSet1 === 1) {
                  this.eventsItems = this.eventsItems1
                    .concat(this.eventsItems2)
                    .sort(function(a, b) {
                      return new Date(b.Date) - new Date(a.Date);
                    });
                  this.eventsItems = this.eventsItems.slice(0, 10);
                }
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(response.data.error + " (Events)");
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (Events)",
                  );
              });
          }
          // partners
          if (this.menuMappings["Partners"] != null) {
            this.Partners_showLoader = 1;
            parameters = this.getApiCallParameters("Partners");
            ApiService.get("DashBoard", "Partners" + parameters)
              .then(({ data }) => {
                this.transformPartnersData(data.Partners);
                this.partnersTitle = data.Title;
                this.partnersDescription = data.Description;
                this.partnersItems = data.Partners;
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(response.data.error + " (RepairFund)");
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (RepairFund)",
                  );
              });
          }
          //community
          if (this.menuMappings["CommunityInfo"] != null) {
            parameters = this.getApiCallParameters("CommunityInfo");
            ApiService.get("DashBoard", "CommunityInfo" + parameters)
              .then(({ data }) => {
                //this.transformPartnersData(data.Partners);
                this.communityTitle = data.Title;
                this.communityDescription = data.Description;

                this.communityInfo = data.Info;

                this.communityInfoStreet = data.Street;
                this.communityInfoCity = data.City;
                this.communityInfoIco = data.Ico;
                this.communityInfoData = data.Data;
                this.communityInfowww = data.WWW;
                this.communityInfoemail = data.Email;
                this.communityInfomobil = data.Telefon;
                this.communityInfo2 = data.Info2;
              })
              .catch(({ response }) => {
                if (response.data.error)
                  this.errors.push(response.data.error + " (CommunityInfo)");
                else
                  this.errors.push(
                    this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (CommunityInfo)",
                  );
              });
          }
        })
        .catch(({ response }) => {
          if (response.data.error)
            this.errors.push(response.data.error + " (Menu)");
          else
            this.errors.push(this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (Menu)");
        });
    },
    transformEventsData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Subject: items[i].Subject,
          Responsible: items[i].Responsible == null ? "" : items[i].Responsible,
          Date: items[i].Date,
        };
      }
    },
    transformObjectOfficialsData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Address: items[i].Address,
          Email: items[i].Email,
          Function: items[i].Function,
          Name: items[i].Name,
          Object: items[i].Object,
          Phone: items[i].Phone,
          Photo: items[i].Photo,
        };
      }
    },
    transformObjectAccountData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          AccountDescription: items[i].AccountDescription,
          FinalBalance: items[i].FinalBalance,
          IssueDate: items[i].IssueDate,
          BankAccount: items[i].BankAccount,
        };
      }
    },
    transformPartnersData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Description: items[i].Description,
          Partner: items[i].Partner,
          Telephone: items[i].Telephone,
          Email: items[i].Email,
          WWW: items[i].WWW,
          Detail: items[i].Detail,
        };
      }
    },
  },
};
</script>
