<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Ceny za vystup-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid>
              <div>
                <b-row class="justify-content-md-center mb-12">
                  <div>
                    <span style="width: 10rem; font-weight: 500">
                      {{ description }}
                    </span>
                    <a
                      data-tab="0"
                      class="nav-link "
                      href="https://livingbypenta.sk/reklamacie/bytu"
                      style="display: inline-flex; padding: 0 0 0 0"
                    >
                      {{ link }}
                    </a>
                  </div>
                </b-row>
              </div>
            </b-container>
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Predpisy-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
//import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
//import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "domus-request-penta",
  components: {
    KTPortlet,
    //    Loader,
  },
  data() {
    return {
      title: null,
      link: null,
      description: null,
      footerText: null,
      menuId: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },

    fetchData() {
      this.items = null;

      this.description =
        "Pre zadávanie reklamácií týkajúcich sa apartmánov/bytov pokračujte prosím prihlásením do ";
      this.link = "Klientskeho portálu";
      //let parameters = "?menuId=" + this.menuId.toString();
    },
  },
};
</script>
