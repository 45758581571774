<template>
  <div>
    <ObjectAccountDetailDocs
      v-bind:objectAccountDetailDocs="objectAccountDetailDocs"
    />

    <div class="row">
      <div class="col-lg-12 col-xl-12 poschodoch-object-account-detail-content">
        <KTPortlet v-bind:title="null">
          <template v-slot:title>
            <div
              class="poshodoch-multiline-header d-flex flex-column align-items-start pt-3 pb-3 pl-2"
            >
              <h3 class="kt-portlet__head-title mb-2">
                {{ accountDescription }}
              </h3>
              <span
                v-if="issueDate && finalBalance"
                class="poschodoch-header-label"
              >
                <span>{{ issueDate + ": " }}</span>
                <strong>{{ finalBalance }}</strong>
              </span>
              <span v-if="bank">
                <span class="poschodoch-header-label">
                  {{ $i18n.t("OBJECTACCOUNT.TABLE.BANK") + ": " }}
                </span>
                {{ bank }}
              </span>
              <span v-if="bankAccount">
                <span class="poschodoch-header-label">
                  {{ $i18n.t("OBJECTACCOUNT.TABLE.BANKACCOUNT") + ": " }}
                </span>
                {{ bankAccount }}
              </span>
            </div>
          </template>

          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row class="mb-2 poschodoch-header-with-rows-count">
                <!--Begin::Filter -->
                <b-col
                  sm="12"
                  md="12"
                  lg="9"
                  class="poschodoch-header-filter d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-start align-items-center"
                >
                  <ul
                    class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold poschodoch-year-pills m-0 mr-0 mr-md-5"
                    role="tablist"
                  >
                    <li class="nav-item" v-for="index in years" :key="index">
                      <a
                        v-on:click="fetchData(index)"
                        data-tab="0"
                        class="nav-link"
                        href="javascript:void(0)"
                        v-bind:class="{ active: index == year }"
                      >
                        {{ index }}
                      </a>
                    </li>
                  </ul>

                  <div
                    class="poschodoch-month-dropdown d-flex align-items-center mt-3 mt-md-0 mb-3 mb-md-0 mb-lg-0"
                  >
                    <label for="object-account-dropdown-month" class="m-0 mr-3">
                      {{ $i18n.t("DAILY_CONSUMPTION.FILTERMONTH") }}
                    </label>
                    <button
                      id="previous-month"
                      type="button"
                      class="poschodoch-previous-btn page-link mr-3 mr-md-2"
                      v-on:click="previousMonth(month)"
                      :disabled="isPreviousBtnDisabled"
                    >
                      ‹
                    </button>
                    <b-dropdown
                      id="object-account-dropdown-month"
                      :text="getFormatService().getMonthName(month)"
                      variant="light"
                    >
                      <b-dropdown-item
                        v-for="month in months"
                        :key="month"
                        v-on:click="fetchData(year, month)"
                      >
                        {{ getFormatService().getMonthName(month) }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <button
                      id="next-month"
                      type="button"
                      class="poschodoch-next-btn page-link ml-3 ml-md-2"
                      v-on:click="nextMonth(month)"
                      :disabled="isNextBtnDisabled"
                    >
                      ›
                    </button>
                  </div>
                  <div
                    class="d-flex align-items-center mt-3 mt-md-0 mb-3 mb-md-0 mb-lg-0 ml-3"
                    v-if="false"
                  >
                    <b-form-checkbox
                      id="checkUnconfirmed"
                      v-model="Unconfirmed"
                      class="ml-2"
                      size="lg"
                      style
                      switch
                      v-on:input="fetchData(year, month)"
                    ></b-form-checkbox>
                    <span class="mr-5 mt-1">
                      {{ $i18n.t("OBJECTACCOUNT_DETAIL.PARAMETER_REPORT") }}
                    </span>
                  </div>
                </b-col>
                <!--End::Filter -->

                <!--Begin::Count of rows per page -->
                <b-col
                  sm="12"
                  md="12"
                  lg="3"
                  v-if="items && items.length > 0"
                  class="poschodoch-rows-count"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                      @change="getFormatService().formatSetPerPage(perPage)"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!--End::Count of rows per page -->
              </b-row>

              <b-table
                show-empty
                hover
                :items="items"
                :fields="fields"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(DocCount)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.value > 1"
                    v-on:click="
                      showObjectAccountDetailDocs(
                        data.item.Reckey,
                        data.item.TableName,
                      )
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    v-if="data.value == 1"
                    v-on:click="
                      showSingleDoc(data.item.Reckey, data.item.TableName)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-invoices-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span
                          v-if="data.value.Date"
                          class="poschodoch-table-title-mobile text-left"
                        >
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.Date,
                            ) + " "
                          }}
                          {{ data.value.VS }}
                        </span>
                        <span v-if="data.value.Description" class="text-left">
                          {{ data.value.Description }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span
                          v-bind:class="[
                            data.value.Amount < 0
                              ? 'poschodoch-table-title-mobile color-danger'
                              : 'poschodoch-table-title-mobile',
                          ]"
                        >
                          {{
                            getFormatService().formatNumberToLocal(
                              data.value.Amount,
                            )
                          }}
                        </span>
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount > 1"
                            v-on:click="
                              showObjectAccountDetailDocs(
                                data.item.Reckey,
                                data.item.TableName,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.Reckey,
                                data.item.TableName,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>

              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import ObjectAccountDetailDocs from "@/views/pages/domus/ObjectAccountDetailDocs.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "objectAccount-detail",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    ObjectAccountDetailDocs,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      objectAccountDetailDocs: null,
      items: null,
      accountId: null,
      Unconfirmed: false,
      fields: [
        {
          key: "Date",
          label: this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.DATE"),
          sortable: false,
          class: "text-left table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "VS",
          label: this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.VS"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "Description",
          label: this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.DESCRIPTION"),
          sortable: false,
          class: "text-left table-no-mobile",
        },
        {
          key: "Amount",
          label: this.addCurrencyToColumn(
            this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.AMOUNT"),
          ),
          sortable: false,
          class: "text-right table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Amount < 0) {
              item._cellVariants = { Amount: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
      filter: null,
      filterOn: [],
      year: null,
      years: null,
      yearFrom: null,
      yearTo: null,
      month: null,
      months: null,
      monthFrom: null,
      monthTo: null,
      accountDescription: null,
      bank: null,
      issueDate: null,
      finalBalance: null,
      bankAccount: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.accountId = this.$route.query.accountId;
    this.fetchData();
  },
  computed: {
    isPreviousBtnDisabled() {
      return !this.isMonthInRange(this.month - 1);
    },
    isNextBtnDisabled() {
      return !this.isMonthInRange(this.month + 1);
    },
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    isMonthInRange(monthToCheck) {
      if (this.months != null) {
        return monthToCheck != null && this.months.includes(monthToCheck);
      } else {
        return false;
      }
    },
    previousMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth - 1))
        this.fetchData(this.year, selectedMonth - 1);
    },
    nextMonth(selectedMonth = null) {
      if (this.months.length != null && this.isMonthInRange(selectedMonth + 1))
        this.fetchData(this.year, selectedMonth + 1);
    },
    addCurrency(data) {
      return (
        data + " " + FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    showObjectAccountDetailDocs(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=183";

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.objectAccountDetailDocs = data;
        this.$bvModal.show("modal-objectAccountDetail-docs");
      });
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.objectAccountDetailDocs = data;

        parameters =
          "?docId=" +
          this.objectAccountDetailDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.objectAccountDetailDocs.Documents[0].FileName;

          FileService.downloadFile(fileName, binaryContent);
        });
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setYearRange(yearFrom, yearTo) {
      if (this.years == null) {
        this.years = [];
        for (let i = parseInt(yearFrom); i <= parseInt(yearTo); i++) {
          this.years.push(i);
        }
      }
    },
    setMonthRange(pRok, pMesiac = "") {
      this.months = [];
      this.year = pRok;
      if (pRok == this.yearFrom) {
        for (let i = this.monthFrom; i <= 12; i++) {
          this.months.push(i);
        }
      } else if (pRok == this.yearTo) {
        for (let i = 1; i <= this.monthTo; i++) {
          this.months.push(i);
        }
      } else {
        this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }

      if (pMesiac == null) {
        this.month = "Zvoľte mesiac";
      }
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.DATE"),
        this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.VS"),
        this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.DESCRIPTION"),
        this.$i18n.t("OBJECTACCOUNT_DETAIL.TABLE.AMOUNT"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          FormatService.formatDateToLocal(item.Date),
          item.VS,
          item.Description,
          FormatService.formatNumberToLocalForCSV(item.Amount),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + `_${this.year}_${this.month}.csv`;

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.accountId != null)
        parameters += "&accountId=" + this.accountId.toString();
      if (this.year != null) parameters += "&year=" + this.year.toString();
      if (this.month != null) parameters += "&month=" + this.month.toString();

      window.open("/objectAccountDetailPrint" + parameters);
    },
    fetchData(selectedYear = null, selectedMonth = null) {
      this.items = null;
      let parameters =
        "?menuId=" +
        this.menuId.toString() +
        "&accountId=" +
        this.accountId.toString();
      if (selectedYear != null)
        parameters += "&year=" + selectedYear.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();
      if (this.Unconfirmed) parameters += "&Unconfirmed=1";

      ApiService.setHeader();
      ApiService.get("Object", "ObjectAccountDetail" + parameters).then(
        ({ data }) => {
          if (data.ObjectAccount) this.transformData(data.ObjectAccount);

          this.title = data.Title;
          this.description = data.Description;
          this.items = data.ObjectAccount;
          this.year = data.Year;
          this.yearFrom = data.YearFrom;
          this.yearTo = data.YearTo;
          this.monthFrom = data.MonthFrom;
          this.monthTo = data.MonthTo;
          this.currentPage = 1;
          this.totalRows = this.items.length;
          //this.Unconfirmed = data.Unconfirmed == 1 ? true : false;

          this.accountDescription = data.AccountDescription;
          this.bank = data.Bank;
          this.issueDate =
            this.$i18n.t("OBJECTACCOUNT_DETAIL.TITLEADD") +
            FormatService.formatDateToLocal(data.IssueDate);
          this.finalBalance = this.addCurrency(
            this.getFormatService().formatNumberToLocal(data.FinalBalance),
          );
          if (data.BankAccount)
            this.bankAccount = data.BankAccount.substring(0, 38);

          this.setYearRange(data.YearFrom, data.YearTo);
          this.setMonthRange(this.year, selectedMonth);
          this.month = data.Month;
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Date: items[i].Date,
          VS: items[i].VS,
          Description: items[i].Description,
          Amount: items[i].Amount,
          DocCount: items[i].DocCount,
        };
      }
    },
  },
};
</script>
