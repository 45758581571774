<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Building Elements-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
              >
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ data.value.Name }}
                          </span>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span>{{ data.value.StateOfElements }}</span>
                      </div>
                    </div>
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <span>
                        {{
                          getFormatService().formatNumberToLocal(
                            data.value.Count,
                          ) +
                            " " +
                            data.value.Note
                        }}
                      </span>
                    </div>
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          {{
                            $i18n.t("BUILDINGELEMENTS.TABLE.WARRANTYDATE") +
                              ": " +
                              (data.value.WarrantyDate
                                ? getFormatService().formatDateToLocal(
                                    data.value.WarrantyDate,
                                  )
                                : "")
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span>
                          {{
                            addCurrencyToColumn(
                              $i18n.t("BUILDINGELEMENTS.TABLE.COST"),
                            ) +
                              ": " +
                              (data.value.Cost
                                ? getFormatService().formatNumberToLocal(
                                    data.value.Cost,
                                  )
                                : "")
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Building Elements-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "buildingElements",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "Name",
          label: this.$i18n.t("BUILDINGELEMENTS.TABLE.NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Count",
          label: this.$i18n.t("BUILDINGELEMENTS.TABLE.COUNT"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right, table-no-mobile",
        },
        {
          key: "Cost",
          label: this.addCurrencyToColumn(
            this.$i18n.t("BUILDINGELEMENTS.TABLE.COST"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right, table-no-mobile",
          formatter: (value) => {
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "WarrantyDate",
          label: this.$i18n.t("BUILDINGELEMENTS.TABLE.WARRANTYDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right, table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "StateOfElements",
          label: this.$i18n.t("BUILDINGELEMENTS.TABLE.STATEOFELEMENTS"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Note",
          label: this.$i18n.t("BUILDINGELEMENTS.TABLE.NOTE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      window.open("/buildingElementsPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("BUILDINGELEMENTS.TABLE.NAME"),
        this.$i18n.t("BUILDINGELEMENTS.TABLE.COUNT"),
        this.addCurrencyToColumn(this.$i18n.t("BUILDINGELEMENTS.TABLE.COST")),
        this.$i18n.t("BUILDINGELEMENTS.TABLE.WARRANTYDATE"),
        this.$i18n.t("BUILDINGELEMENTS.TABLE.STATEOFELEMENTS"),
        this.$i18n.t("BUILDINGELEMENTS.TABLE.NOTE"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.Name,
          item.Count,
          FormatService.formatNumberToLocalForCSV(item.Cost),
          FormatService.formatDateToLocal(item.WarrantyDate),
          item.StateOfElements,
          item.Note,
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "BuildingElements" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.BuildingElements;

          this.transformData(this.items);
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Name: items[i].Name,
          Count: items[i].Count,
          Cost: items[i].Cost,
          WarrantyDate: items[i].WarrantyDate,
          StateOfElements: items[i].StateOfElements,
          Note: items[i].Note == null ? "" : items[i].Note,
        };
      }
    },
  },
};
</script>
