// English
export const locale = {
  MENU: {
    NEW: "new",
    UNIT_LIST: "Units list",
  },
  LAYOUT: {
    FOOTER: {
      LEGAL: "Terms and Conditions of Use",
      PRIVACY: "Personal Data Protection Policy",
      LEGAL_A: "Terms of the operator",
      LEGAL_Z: "Terms of the provider",
      DATETO: "Data current to:",
    },
    FOOTER_LOGIN: {
      LEFT_1: "ANASOFT APR, spol. s.r.o.",
      LEFT_2: "Mlynská dolina 41, 811 02 Bratislava, Slovak Republic",
      LEFT_3: "Phone: +421 2 3223 4484",
      RIGHT_1: "ISO 9001, 14001, 27001, 20000-1",
      RIGHT_2: "TAX ID: SK2020345778",
      RIGHT_3: "",
    },
    FOOTER_LOGIN_CZ: {
      LEFT_1: "ANASOFT s.r.o.",
      LEFT_2: "Hrnčířská 2985, 470 01 Česká Lípa, Czech Republic",
      LEFT_3: "Phone: +420 481 130 100",
      RIGHT_1: "TAX ID: CZ49904949", //IČO: 499 04 949; DIČ:
      RIGHT_2: "", //Společnost zapsána v obch. rejstříku vedeném Krajským soudem v Ústí nad Labem odd. C, vl. 25247
      RIGHT_3: "",
    },
    FOOTER_LOGIN_ENBRA: {
      LEFT_1: "ENBRA SLOVAKIA s.r.o.",
      LEFT_2: "Zvolenská cesta 29, 97401 Banská Bystrica, Slovak Republic",
      LEFT_3: "Phone: +421 2 434 141 46",
      RIGHT_1: "TAX ID: SK2020456119", //IČO: 31624189; IČ DPH:
      RIGHT_2: "", //Spoločnosť registrovaná Okresným súdom Banská Bystrica, Odd. Sro, vložka č. 2587/S
      RIGHT_3: "",
    },
    FOOTER_LOGIN_ENBRA_CZ: {
      LEFT_1: "ENBRA, a.s.",
      LEFT_2: "Durďákova 5, 613 00 Brno, Czech Republic",
      LEFT_3: "Phone: +420 533 03 99 03",
      RIGHT_1: "TAX ID: CZ44015844", // IČO: 44015844; DIČ:
      RIGHT_2: "", //Společnost zapsána v obch. rejstříku vedeném Krajským soudem v Brně odd. B, vl. 6085
      RIGHT_3: "",
    },
    FOOTER_LOGIN_PENTA: {
      LEFT_1: "Penta Real Estate Residential Services s.r.o.",
      LEFT_2:
        "Digital Park II, Einsteinova 25, 85101 Bratislava, Slovenská republika",
      LEFT_3: "Tel:  +421 2 577 88 111",
      RIGHT_1: "",
      RIGHT_2: "",
      RIGHT_3: "",
    },
    PRINT_HEADER: {
      PRINT_DATE: "Printing date: ",
    },
  },
  AUTHJS: {
    DB_NOT_AV:
      "The administrator database is unavailable for technical reasons",
    SOMETHINGWRONG: "Something went wrong. We are working on it...",
    WRONGREGCODE: "Wrong registration code.",
    NOTALLOWED:
      "Access not allowed! Please contact your administrator Satisfied housing",
    ACCNOTVALID: "The account is not valid.",
  },
  COMMON: {
    LOADER: {
      LOADING_DATA_FROM_SERVER: "Loading data from server ...",
    },
    LIST: {
      ROW_COUNT_PER_PAGE: "Records per page",
      NO_RECORDS: "There are no records to show",
      NO_RECORDS_FILTERED: "There are no records matching your criteria",
    },
    MONTHS: {
      MONTH_1: "January",
      MONTH_2: "February",
      MONTH_3: "March",
      MONTH_4: "April",
      MONTH_5: "May",
      MONTH_6: "June",
      MONTH_7: "July",
      MONTH_8: "August",
      MONTH_9: "September",
      MONTH_10: "October",
      MONTH_11: "November",
      MONTH_12: "December",
    },
  },
  FACEBOOK: {
    TITLE: "Signing in with facebook",
  },
  GOOGLE: {
    TITLE: "Signing in with google",
  },
  TABLE_CONTEXT_MENU: {
    PRINT: "Print",
    EXPORT_TO_CSV: "Export to CSV",
    DOWNLOAD: "Download",
  },
  UNIT_LIST: {
    TITLE: "List of units",
    CHANGE_BUTTON: "Change",
    SELECT_BUTTON: "Select",
    CANCEL_BUTTON: "Cancel",
    TABLE: {
      VAR_SYMBOL: "Reference number / Building",
      PROPERTY_COMPANY: "Property management company",
      ADDRESS: "Address",
      TYPE: "Type / Function",
      UNIT_NUMBER: "Unit Number",
    },
  },
  ACCOUNT: {
    TABLE: {
      PERIOD: "Month",
      NAME_OF_MOVEMENT: "Name of movement",
      DETAIL_OF_MOVEMENT: "Movement detail",
      DUE_DATE: "Due date / Payment date",
      AMOUNT: "Amount",
      BALANCE: "Balance",
    },
    BALANCEON: "Balance on ",
    DASHBOARD_TOOLTIP:
      "The balance on your account with your administrator (regulations, payments, billing). A positive balance means an overpayment, a negative balance means an underpayment.",
  },
  PAYMENTORDER: {
    TABLE: {
      ITEM: "Item",
      AMOUNT: "Amount",
      FOOTER: "Total",
    },
  },
  DECISIONS: {
    TABLE: {
      ACCEPTANCEWAY: "Accepted",
      VALIDITY: "Validity",
      DESCRIPTION: "Description",
      DATE: "Date",
    },
  },
  PAYMENTREMINDEROVERVIEW: {
    TABLE: {
      PAYMENTREMINDERTYPE: "Type",
      ARREAR: "Arrears",
      PENALTYPAYMENT: "Penalties",
      POSTAGECHARGE: "Postage",
      ISSUEDATE: "Issuance date",
      DUEDATE: "Date of payment",
      ARREARDATE: "Date of underpayment",
      STATUS: "Status",
      SMSDELIVERYDATE: "Date of SMS delivery",
      SMSDELIVERED: "SMS delivered",
      PAYMENTDATE: "Date of payment",
      DATETO: "to date",
      USER: "User",
      VS: "VS",
    },
    TOOLTIP: {
      STATUS: "Reminder status",
      PAYMENTREMINDERTYPE: "Reminder type",
      VS: "Variable symbol",
      USER: "Title, first name and last name of the space user",
      ISSUEDATE: "Issuance date",
      DUEDATE: "Date of payment",
      ARREARDATE: "Date of arrears",
      PAYMENTDATE: "Date of payment",
      SMSDELIVERYDATE: "Date of SMS delivery",
    },
    PARAMETER: {
      DATETO: "to date:",
      BUTTON: "Load",
    },
  },
  METERREADINGS: {
    TABLE: {
      METERNUMBER: "Meter",
      METERTYPE: "Meter type",
      CLIMBINGIRON: "Location",
      ASSEMBLYDATE: "Installation date",
      DISASSEMBLYDATE: "Removal date",
      HISTORY: "History",
      VERIFICATIONDATE: "Valid to",
      SEALS: "Seals",
      LASTREADINGDATE: "Last reading date",
      LASTREADINGDATEMOBILE: "Last reading",
    },
    CHECKBOXLABEL: "Show removed meters",
    CHECKBOXDAILY: "Also show daily deductions",
    CHECKBOXLABEL_NEGATIVE: "Not showing the removed meters",
    CHECKBOXLABEL_POSITIVE: "Showing the removed meters",
  },
  ANNUALBILLING: {
    TABLE: {
      NAME: "Billing period",
      PAYMENTORDER: "Payment order",
      COST: "Cost",
      DIFFERENCE: "Difference",
      TEXT: "Result",
      BALANCEDATE: "Account balance (as of date)",
      ACCOUNTBALANCEBEFORE: "Account balance",
      FINALBALANCE: "Final balance",
      PERIODID: "Period ID",
      ACCOUNT: "Account",
      GROUP: "Group",
      ACCOUNTTYPE: "Account type",
      DOCCOUNT: "Number of documents",
    },
  },
  OBJECTOFFICIALS: {
    TABLE: {
      ORDER: "Order",
      OBJECT: "Building",
      NAME: "Name",
      PHONE: "Phone / Mobile",
      EMAIL: "Email",
      ADDRESS: "Address",
      TYPE: "Type",
      FUNCTION: "Function",
      PHOTO: "Photo",
      WWW: "Link",
    },
  },
  INSPECTION: {
    TABLE: {
      INSPECTIONID: "Inspection ID",
      OBJECT: "Building",
      NAME: "Inspection Name",
      VALIDTO: "Valid To",
      LASTINSPECTION: "Last Inspection",
      DEFECTS: "Defects",
      DEFECTSALL: "Defects (found / not removed)",
      TODO: "To do",
      STATE: "Condition",
    },
  },
  BUILDINGELEMENTS: {
    TABLE: {
      NAME: "Structural element",
      COUNT: "Number",
      COST: "Cost",
      WARRANTYDATE: "Warranty until",
      STATEOFELEMENTS: "Condition",
      NOTE: "Note",
    },
  },
  COSTS: {
    TABLE: {
      CODE: "Code",
      ANALYSIS: "Service",
      UNITOFMEASURE: "Units of measurement",
      CONSUMPTION: "Consumption",
      AMOUNT: "Cost",
    },
  },
  BIDS: {
    TABLE: {
      DOCNAME: "Document name",
      FILENAME: "File name",
      DOCID: "Document ID",
      CATEGORY: "Selection procedure",
      SOURCENAME: "Source",
      CATEGORY2: "Name",
      STATUS: "Status",
      DATEFROM: "Date from",
      DATETO: "Date to",
      FROM: "from ",
      TO: "to ",
    },
    FILTER: {
      DOC_NAME: "Search document",
      CATEGORY: "Selection procedure",
      PLACEHOLDER: "All",
    },
  },
  REPAIRFUND: {
    TABLE: {
      AMOUNT: "Amount",
      DATE: "Date",
      EVIDENCE: "Document",
      DESCRIPTION: "Description",
    },
    FILTER: {
      GATE: "Entrance",
      VS: "Reference number",
      PLACEHOLDER: "All",
      VIEW: "Show",
    },
    TITLEADD: "Balance due ",
    FINALBALANCE: "Final balance",
    PARAMETER_YEAR: "for Year:",
    VIEWOPTIONS: {
      1: "Creation and drawing together",
      2: "Creation",
      3: "Drawing",
      4: "Creation first and then drawing",
    },
    OPENBALANCE: "Opening balance",
    FINBALANCE: "Final balance",
    PRODUCTION: "Production",
    DRAWING: "Drawing",
    PERIOD: "Period",
    MONTHS: "Months",
    BALANCE: "Balance",
  },
  INVOICES: {
    TABLE: {
      ID: "ID",
      OBJECT: "Center",
      CUSTOMER: "Supplier / Customer",
      AMOUNT: "Amount",
      AMOUNTPAID: "Amount to paid",
      DATEOFREGISTRATION: "Date of evidence",
      DUEDATE: "Due date",
      PAYMENTDATE: "Payment date",
      SERVICES: "Services",
      INVOICEROWS: "Invoice rows",
      ANALYSEROWS: "Services rows",
      CURRENCY: "Currency",
      DOCID: "Doc ID",
      DESCRIPTION: "Description",
      VS: "Reference number",
    },
  },
  OBJECTACCOUNT: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Account description",
      BANK: "Bank",
      FINALBALANCE: "Final balance",
      BANKACCOUNT: "Bank account",
      ISSUEDATE: "As of date",
      ISSUEDATEMOBILE: "As of",
    },
  },
  SHEETS: {
    OPTIONS: {
      ALL: "All",
      OWNERS: "Owners",
      TENANTS: "Tenants",
      ALLB: "All",
      NOB: "Non-Apartments",
      YESB: "Apartments",
      PERC: "Percentage",
      FRAC: "Numerator/Denominator",
      HIDE: "Don't display",
    },
  },
  VOTINGSHEET: {
    TABLE: {
      VS: "Reference number",
      NAME: "Name",
      OBJECTNUMBER: "Building number",
      OBJECTADDRESS: "Building address",
      GATENUMBER: "Entrance number",
      GATEADDRESS: "Entrance address",
      APARTMENTNR: "Apartment Number",
      SIGNATURE: "Signature",
      YES: "Agree",
      NO: "Disagree",
      YESYES: "Yes",
      NONO: "No",
    },
    PLACEHOLDER1: "Title of voting",
    PLACEHOLDER2: "Details of voting",
    NAME: "Voting sheet",
  },
  SHEETINPUT: {
    USERS: "Users",
    ROOMS: "Spaces",
    PORTIONS: "Co-ownership share",
    DATE: "As of date",
    BUTTON: "Display",
    TEXT1: "Title",
    TEXT2: "Description",
  },
  SIGNATURESHEET: {
    TABLE: {
      VS: "Reference number",
      APARTMENTNR: "Apartment number",
      NAME: "Name",
      GATENUMBER: "Entrance number",
      GATEADDRESS: "Entrance address",
      PORTION: "Co-ownership share",
      SIGNATURE: "Signature",
    },
    PLACEHOLDER1: "Title of the signature sheet",
  },
  ACCOUNTPROFILE: {
    DROPDOWNUSER: {
      ADDSPACE: "Add a unit",
      CANCELSPACE: "Disconnect a unit",
      NOTIFICATIONSETTINGS: "Notification settings",
      PROFILE: "Profile",
      INPROGRESS: "",
    },
    NOTE:
      "Changing the contact information in the account profile does not automatically modify the contact information with your administrator. Use the My Requests link to report changes to the administrator",
    TITLE: "Profile data",
    EMAIL: "Email",
    EMAIL_NEW: "New email",
    PHONE: "Phone number",
    PHONE_NEW: "New phone number",
    PWD: "Password",
    PWD_NEW: "New password",
    PWD_OLD: "Old password",
    PWD_NEWCONFIRM: "Confirm new password",
    PLACEHOLDER_PWD: "Your new password",
    REDIRECT:
      "Your account has been canceled. You will be redirected to the home page... ",
    REDIRECT2:
      "Registration successful. You will be redirected to the home page, log in...",
    CODE: "Code",
    VALIDATION: {
      PWD_CONFIRM: "New passwords must match",
      PWD:
        "Password must be min. 8 characters while containing at least 1 character from each of these 3 categories: Uppercase letter, Lowercase letter, Number",
      PHONE:
        "Every number needs to have more than 10 characters, numbers from the Slovak and Czech republics (+421/+420) must have the valid format at one of the available mobile service providers",
      EMAIL: "Email must be a valid email address",
    },
    BUTTON: {
      EMAIL: "Change email",
      EMAIL2: "Activate to sign in with email",
      PHONE: "Change phone number",
      PWD: "Change password",
      PHOTOUPLOAD: "Upload photo",
      PHOTODELETE: "Delete photo",
      DISCONNECT: "Disconnect",
      CONNECT: "Connect",
      DELETE_ACCOUNT: "Deactivate account",
      CODE: "Confirm verification code",
      OK: "OK",
    },
    ALERT: {
      EMAIL: "You have entered an incorrect email format",
      PHONE:
        "You have entered an incorrect phone number format (+421/+420 or an length of 12 and more characters)",
      PWD:
        "You have entered an incorrect password format (Min. 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number)",
    },
    SOCIAL: {
      GOOGLE_ON: "Signed in with Google",
      GOOGLE_OFF: "Log in with Google",
      FACEBOOK_ON: "Signed in with Facebook",
      FACEBOOK_OFF: "Log in with Facebook",
    },
    OPERATIONS: {
      SEC1: {
        TITLE: "Change your email",
        TEXT:
          "Specify your new email which will be used to receive a verification code and confirm it with your password. Changing the contact information in the account profile does not automatically modify the contact information with your administrator. Use the My Requests link to report changes to the administrator.",
      },
      SEC2: {
        TITLE: "Change your phone number",
        TEXT:
          "Specify your new phone number which will be used to receive a verification code and confirm it with your password. Changing the contact information in the account profile does not automatically modify the contact information with your administrator. Use the My Requests link to report changes to the administrator.",
      },
      SEC3: {
        TITLE: "Change your password",
        TEXT:
          "Specify your old password, new password and confirm the new one by entering it again",
      },
      SEC4: {
        TITLE: "Activate log in with email",
        TEXT:
          "Creating a password will allow you to log in with email and password",
      },
      SEC5: {
        TITLE: "Deactivate your account",
        TEXT:
          "By deactivating your account access to data about assigned spaces will be lost. This access can only be renewed by a new registration. Do you really want to deactivate the account?",
      },
      SECCODE: {
        TITLE: "Code verification",
        TEXT_SMS: "Specify the verification code delivered to your Phone",
        TEXT_EMAIL: "Specify the verification code delivered to your Email",
      },
      SECPWDCFM: {
        TITLE: "Password change e-mail",
        TEXT:
          "We sent a link to your e-mail where you can change your password",
      },
    },
    CONNECTEDACCOUNTS:
      "The following spaces are also accessible from other accounts than yours:",
    TITLEPHOTO: "Profile photo",
    TITLEPHOTODESC1: "You can change the photo by uploading a new one",
    TITLEPHOTODESC2: "Size for the uploaded photo is ",
    TITLEPHOTODESC3: "max. 2MB",
    CONSENTPHOTO:
      "By checking this box, I agree that my photo will be displayed next to my contact details within the apartment building in the DOMUS system.",
    CONSENTFULL:
      "By checking the box, the user gives the Personal Data Operator consent to the processing of personal data in the scope of his photograph, acknowledges and agrees that his photograph will be published with contact details in the building for identification purposes and ",
    CONSENTFULL_2:
      "will be accessible to persons in the building by employees of the administrator. during the function to which the person concerned has been appointed or until he decides to stop publishing the photograph by clicking on the checkbox. This consent may be revoked at any time in the same way as it was given. ”For more information, see ",
    CONSENTFULL_LINK: "Privacy policy",
  },
  EVENTS: {
    TABLE: {
      SUBJECT: "Subject",
      RESPONSIBLE: "Responsible",
      DATE: "Date",
      TYPE: "Type",
    },
    SEARCH: "Search in subject",
    FROM: "from: ",
    TO: "to: ",
    PRIORITY: {
      TEXT: "Priority",
      1: "Low",
      2: "Middle",
      3: "High",
    },
  },
  IDENTITY: {
    TITLE: "Acquiring identity",
    FILTER: {
      ALL: "All types",
      USERS: "Owners only",
      OFFICIALS: "Officials only",
      ADMINS: "Admins only",
      PLACEHOLDERCUSTOMER: "Choose a customer code",
    },
    LABEL: {
      CUSTOMER: "Customer",
      ACCESS: "Type of access",
      SEARCH: "Search",
    },
    ADMINS_TABLE: {
      TITLE: "Admins",
      CODE: "Customer code",
      LOGINNAME: "Login",
      NAME: "Name",
      SURNAME: "Surname",
    },
    OBJECTS_TABLE: {
      TITLE: "Officials",
      NAME: "First name",
      SURNAME: "Surname",
      VCS: "Building number",
      ACCESSFROM: "Access from",
      ACCESSTO: "Access to",
      ADDRESS: "Street",
      CITY: "City",
    },
    USERS_TABLE: {
      TITLE: "Users",
      NAME: "First name",
      SURNAME: "Surname",
      VS: "Var. symbol",
      ACCESSFROM: "Access from",
      ACCESSTO: "Access to",
      ADDRESS: "Street",
      CITY: "City",
      TYPE: "V/N",
    },
  },
  METERREADING_DETAIL: {
    TABLE: {
      METERNUMBER: "Meter number",
      METERTYPE: "Meter type",
      CLIMBINGIRON: "Location",
      STATE: "Result",
      READINGDATE: "Reading date",
      READINGCODE: "Reading code",
      DESCRIPTION: "Description",
      LINK: "Photo",
    },
  },
  ANNUALBILLING_DETAIL: {
    AMOUNT: "Total",
    CUSTOMIZEDAMOUNT: "Total after adjustment",
    ACCOUNTBALANCETO: "Account balance as of ",
    TABLE: {
      ITEM: "Item",
      PAYMENTORDER: "Payment order",
      COST: "Cost",
      DIFFERENCE: "Difference",
      ORDER: "Order",
    },
  },
  INSPECTION_DETAIL: {
    TABLE: {
      DATE: "Date of inspection",
      VALIDTO: "Valid to",
      NAMEOFENGINEER: "Company",
      EXPENSE: "Costs",
      DEFECTS: "Defects found",
      DEFECTSALL: "Defects (found / not removed)",
      TODO: "Unresolved defects",
      LASTINSPECTION: "Date of removal",
      STATE: "Condition",
    },
  },
  OBJECTACCOUNT_DETAIL: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Account description",
      CURRENCY: "Currency",
      INITIALBALANCE: "Initial balance",
      INITIALDATE: "Initial date",
      RELATIVEBALANCE: "Relative balance",
      FINALBALANCE: "Final balance",
      BANKACCOUNT: "Bank account",
      BANK: "Bank",
      ISSUEDATE: "Issue date",
      VS: "Reference number",
      TEXT: "Operation",
      DATE: "Date",
      AMOUNT: "Amount",
      DESCRIPTION: "Description",
    },
    TITLEADD: "Balance on ",
    PARAMETER_YEAR: "Year:",
    PARAMETER_MONTH: "Month:",
    PARAMETER_REPORT: "Show unconfirmed statements",
  },
  INVOICE_DETAIL: {
    VS: "Variable symbol",
    CUSTOMER: "Supplier",
    INV_DESC: "Subject",
    AMOUNT: "Amount to settle",
    AMOUNTPAID: "Amount to paid",
    DOCS: "Documents",
    CUSTOMERDF: "Supplier",
    CUSTOMEROF: "Customer",
    TYPE: "Invoice type",
    TABLE: {
      DELIVERYPOINT: "Delivery point",
      NAME: "Service/Description",
      VALUE: "Value",
      AMOUNT: "Amount",
      DATEFROM: "Date from",
      DATETO: "Date to",
      DATEFROM_MOBILE: "Date from",
      DATETO_MOBILE: "Date to",
      READINGCODE: "Reading code",
      ROWDESC: "Description",
      HOUSE: "Center",
      GATE: "Activity",
      INVOICE_ROWS: "Invoice lines",
      INVOICE_ANALYSIS_ROWS: "Technical data",
    },
  },
  COST_DETAIL: {
    TABLE: {
      SUBSCRIBERSITE: "Delivery point",
      BUDGET: "Budget",
      CONSUMPTION: "Consumption",
      AMOUNT: "Amount",
      DATEFROM: "Date from",
      DATETO: "Date to",
      ANALYSIS: "Service",
      UNITOFMEASURE: "Unit of measurement",
      CURRENCY: "Currency",
      MARK: "Mark",
      DATE: "Date",
      TYPEOFSOURCE: "Type of source",
      DATEFROM_MOBILE: "From",
      DATETO_MOBILE: "to",
    },
  },
  MOVEMENT_DETAIL: {
    TITLE: "Movement detail",
    ACCOUNT_NR: "Account no.",
    AMOUNT: "Amount",
    IBAN: "IBAN",
    VAR_SYMBOL: "Reference number",
    TABLE_ITEMS: {
      TITLE: "Items",
      DESCRIPTION: "Description",
      AMOUNT: "Amount",
    },
    TABLE_ASSIGNMENTS: {
      TITLE: "Assignments",
      DESCRIPTION: "Description",
      AMOUNT: "Amount",
    },
  },
  CUSTOMERMAIL: {
    BACK: "Back",
    SAVEBUTTON: "Save",
    SENDBUTTON: "Send",
    TABLE: {
      EMAILSUBJECT: "Subject",
      EMAILCONTENT: "Content",
      EMAILDETAIL: "Address List",
      DATEIINSTRUCTIONTOSENDELAMAIL: "Date Sent",
      DATELASTCHANGE: "Date Changed",
      EMAILCOUNTALL: "Number of Emails",
      EMAILCOUNTSENT: "Number of Emails Sent",
      EMAILSENT: "Email Sent",
      EMAILUNSENT: "Email Not Sent",
      NOTSENT: "Not Sent",
      TITLE: "Bulk Email",
      EMAIL: "Email",
      DATESENT: "Date Sent",
      SHOWUNSENTONLY: "Show Unsent Only",
      SEARCH: "Search Email...",
    },
    ERRORS: {
      SUBJECT_REQUIRED: "Email Subject is Required",
      CONTENT_REQUIRED: "Email content is a required field",
    },
    LIMITUSERS:
      "Send only to users outside the flat rate and active consumption control service",
    NEWMAIL: "New email",
    CONFIRMTITLE:
      "This action will send an email to all recipients. Please confirm before continuing.",
    CONFIRMSEND:
      "The send operation is irreversible and will send emails in bulk once confirmed. Are you sure you want to continue?",
  },
  DOCUMENTS: {
    FILTER: {
      DOC_NAME: "Search document",
      CATEGORY: "Category",
      PLACEHOLDER: "All",
    },
    TABLE: {
      DOC_NAME: "Document",
      CREATION_DATE: "Created",
      CATEGORY: "Category",
      DOWNLOAD: "Download",
      SERVICE: "Sending process",
      DATECRE: "Date of Issue",
      DOC_NAME2: "Attachment Name",
      CREATED_BY: "Created by",
    },
    UPLOAD: {
      HINT:
        "A single attachment must not exceed 15MB. If you want to edit the document name, you can do so in the table after selecting it.",
      UPLOAD_NEW_FILES: "Add document",
      UPLOAD_TEXT: "Upload",
      DOC_NAME: "Document name",
      TOOBIGFILE: "The document is too large (more than 15MB)",
      EXTENSION: "Extension",
      STATUS: "Status",
      READY: "Ready",
      OK: "Uploaded",
      UPLOADEDFILESCOUNT: "Number of inserted documents: ",
      DELETEQUESTION: "Do you really want to delete this document?",
      READYTOBEUPLOADED: "files ready for upload...",
      NOTUPLOADEDFILESALERT:
        "You have unuploaded files. Do you want to continue?",
      UPLOADING: "Uploading...",
      CATEGORY_PLACEHOLDER: "Select a category...",
    },
  },
  REQUEST_LIST: {
    TABLE: {
      SUBJECT: "Subject",
      NAME: "Reported by",
      REQUEST_TYPE: "Type",
      REQUEST_STATE: "State",
      CREATION_DATE: "Creation date",
      DATE_OF_CHANGE: "Date of change",
      REQUEST_ID: "Number",
    },
    FILTER: {
      ALL: "All requests",
      HOUSE: "Requests issued for the house",
      USER: "Requests from other users",
    },
    NEWREQUEST: "New request",
    TITLEUSER: "User request list",
    TITLEHOUSE: "Building request list",
    CATEGORY: "Request type",
  },
  REQUEST_DETAIL: {
    TITLE: "Request detail",
    TYPE: "Request type",
    DESCRIPTION: "Description",
    SUBJECT: "Subject",
    FILE: "File with attachment",
    CONTACTMAIL: "Contact e-mail",
    CONTACTPHONE: "Contact phone",
    RESPONSIBLE: "Responsible person",
    STATE: "Status",
    REQUESTID: "Request number",
    PARAMSTITLE: "Parameters",
    CHATTITLE: "Changes and comments",
    CONTACTMAILPLACEHOLDER: "Contact email",
    CONTACTPHONEPLACEHOLDER: "Contact phone",
    MESSAGEPLACEHOLDER: "Type your message here",
    CANCELBUTTON: "Cancel",
    SENDBUTTON: "Send",
    SAVEBUTTON: "Save",
    FILEATTACHMENTCOMMENT: "Attached documentation",
    FILEUNAVAILABLEDESC:
      "Uploading files is only available with a request status of 'Processed'",
    BUTTON_FILE_SAVE: "Send file",
    FILE_ATTACHED: "Attached file: ",
    SPR: "Admin",
  },
  NEW_REQUEST: {
    TITLE: "New request",
    BACKBUTTON: "Back",
    LABEL: {
      REQTYPE: "Request type",
      EMAIL: "Contact e-mail",
      EMAILDESC: "We'll never share your email with anyone else.",
      MOBILE: "Contact phone (in format +444999xxxxxx)",
      SUBJECT: "Subject",
      DESCRIPTION: "Description",
      FILE: "File with attachment",
      METERNR: "Meter number*",
      READINGDATE: "Reading date*",
      STATE: "State of the meter*",
      OLDTITLE: "Old title before",
      OLDTITLE2: "Old title after ",
      NEWTITLE: "New title before",
      NEWTITLE2: "New title after",
      OLDNAME: "Old name",
      NEWNAME: "New name",
      OLDSURNAME: "Old surname",
      NEWSURNAME: "New surname",
      OLDSTREET: "Old street",
      NEWSTREET: "New street",
      OLDCITY: "Old city",
      NEWCITY: "New city",
      OLDPSC: "Old post code",
      NEWPSC: "New post code",
      YEAR: "Year*",
      CHANGEDATE: "Change date from*",
      PERSONCOUNT: "Number of persons*",
      MAINTENANCE: "The report concerns",
      RADIO1: "My space",
      RADIO2: "Entrance",
      RADIO3: "Building",
      DELETE_FILES: "Cancel file attachment", //zmiznut tlacidlo
      DELETE_FILES_PRE: "Canceled - ",
      SUMA: "Sum *",
      BU: "Bank account *",
      DESCVYD: "Description *",
    },
    ERROR: {
      REQUIRED:
        "Before submitting, please fill in all required fields(*) in the form.",
      REQUIREDPERSON:
        "Before sending, please fill in all required fields(*) in the person-month form.",
      REQUIREDCAPTCHA:
        "You must confirm that you are not a robot before submitting (CAPTCHA).",
    },
    UPLOAD: "Attached documentation",
    UPLOAD2: "Attached documentation",
    UPLOADED: "Attached files to the request",
    UPLOADPLACEHOLDER: "Choose a file or drag & drop file here...",
    UPLOADPLACEHOLDER2: "Choose a file or drag & drop file here... *",
    DATEPLACEHOLDER: "No date selected",
    OLDNAMEPLACEHOLDER: "Old first name",
    OLDSURNAMEPLACEHOLDER: "Old surname",
    OLDSTREETPLACEHOLDER: "Old street",
    OLDCITYPLACEHOLDER: "Old city",
    OLDPSCPLACEHOLDER: "Old post code",
    PARAMETERS: "Parameters",
    CONFIRMLEAVE:
      "You have unsaved changes! Are you sure you want to leave the page?",
    SENDED: "Sended",
    ROZP: "Developed",
    METERNR: "Meter number:",
    STATE: "State of the meter:",
    OLDMETERS: "Previous deductions you have already entered: ",
    TITLEPUB: "Collection form for self-counting meters",
    TITLEPUBPERSON: "Collection form for entering person-months",
    UPLOADPUB: "Attach file",
    METERLEAVE:
      "Deduction data has been sent, do you want to upload data from another meter?",
    NOTICE: "Notice",
    YES: "Yes",
    NO: "No",
  },
  MONTHLY_CONSUMPTION: {
    CHART_WATER: {
      HOT_WATER: "Hot water",
      COLD_WATER: "Cold water",
      AX_X: "Month",
      AX_Y: "Consumption of water [m3]",
    },
    CHART_ROOMS: {
      AX_X: "Month",
      AX_Y: "Consumption of heat [Consumption values, kWh, GJ]",
      S1: "Kitchen",
      S2: "Living room n.1",
    },
    BUTTON1: "Another period",
    BUTTON2: "Years",
    BUTTONOK: "Search",
    FROM: "from: ",
    TO: "to: ",
    SHOW_CORRECTED: "Take climatic conditions into account",
    TABLE: {
      TYPE: "Type",
      NUMBER: "Meter No.",
      ROOM: "Room",
      VALUEFROM: "Value from",
      VALUETO: "Value to",
      CONSUMPTION: "Consumption",
      COEFFICIENT: "Correction coefficient",
      CONSUMPTION_COEFFICIENT: "Corrected consumption",
      INITCONSUMPTION: "Consumption since the beginning of the year",
      YOUR_CONSUMPTION: "Your cumulative consumption",
    },
  },
  MONTHLY_CONSUMPTION_MORE_YEARS: {
    CHART_COLD_WATER: {
      AX_X: "Month",
      AX_Y: "Consumption of cold water [m3]",
    },
    CHART_HOT_WATER: {
      AX_X: "Month",
      AX_Y: "Consumption of hot water [m3]",
    },
    CHART_HEAT: {
      AX_X: "Month",
      AX_Y: "Heat consumption [Consumption values, kWh, GJ]",
      TAKE_CLIMATIC_CONDITIONS: "Take climatic conditions into account",
    },
    LINE: "Curve",
    BAR: "Columns",
  },
  DAILY_CONSUMPTION_MORE_YEARS: {
    CHART_COLD_WATER: {
      AX_X: "Day",
      AX_Y: "Consumption of cold water [liters]",
    },
    CHART_HOT_WATER: {
      AX_X: "Day",
      AX_Y: "Consumption of hot water [liters]",
    },
    CHART_HEAT: {
      AX_X: "Day",
      AX_Y: "[Consumption values, kWh, GJ]",
    },
    MENUTITLE: "Choose displayed years (max. 3)",
  },
  DAILY_CONSUMPTION_AVERAGE_OBJECT: {
    CHART_COLD_WATER: {
      LEGEND_WATER: "My consumption",
      LEGEND_AVERAGE_WATER: "Average consumption",
      LEGEND_OBJECT_WATER: "Reference consumption",
      AX_X: "Day",
      AX_Y: "Water consumption [liters]",
    },
    CHART_HOT_WATER: {
      LEGEND_WATER: "My consumption",
      LEGEND_AVERAGE_WATER: "Average consumption",
      LEGEND_OBJECT_WATER: "Reference consumption",
      AX_X: "Day",
      AX_Y: "Water consumption [liters]",
    },
    CHART_HEAT: {
      LEGEND_HEAT: "My consumption",
      LEGEND_AVERAGE_HEAT: "Average consumption in the building",
      LEGEND_OBJECT_HEAT: "Reference consumption",
      AX_X: "Day",
      AX_Y: "[Consumption values, kWh, GJ]",
      SHOW_CORRECTED: "Take climatic conditions into account",
    },
    BUTTON1: "Custom dates",
    BUTTON2: "Months",
    BUTTONOK: "Search",
    FROM: "from: ",
    TO: "to: ",
  },
  MONTHLY_CONSUMPTION_AVERAGE_OBJECT: {
    CHART_COLD_WATER: {
      LEGEND_WATER: "My consumption",
      LEGEND_AVERAGE_WATER: "Average consumption",
      LEGEND_OBJECT_WATER: "Reference consumption",
      AX_X: "Month",
      AX_Y: "Water consumption [liters]",
    },
    CHART_HOT_WATER: {
      LEGEND_WATER: "My consumption",
      LEGEND_AVERAGE_WATER: "Average consumption",
      LEGEND_OBJECT_WATER: "Reference consumption",
      AX_X: "Month",
      AX_Y: "Water consumption [liters]",
    },
    CHART_HEAT: {
      LEGEND_HEAT: "My consumption",
      LEGEND_AVERAGE_HEAT: "Average consumption",
      LEGEND_OBJECT_HEAT: "Reference consumption",
      //LEGEND_COMPARABLE_HEAT: "Consumption in a comparable apartment",
      AX_X: "Month",
      AX_Y: "Heat consumption [Consumption values, kWh, GJ]",
      SHOW_CORRECTED: "Show corrected consumption values",
    },
    BUTTON1: "Another period",
    BUTTON2: "Years",
    BUTTONOK: "Search",
    FROM: "from: ",
    TO: "to: ",
  },
  CONSUMPTION_FOR_PERIOD: {
    BUTTONOK: "Search",
    FROM: "from: ",
    TO: "to: ",
  },
  CONSUMPTION: {
    CHART_COLD_WATER: {
      LEGEND_WATER: "Consumption in my apartment",
      LEGEND_AVERAGE_WATER: "Average consumption in the building",
      AX_X: "Year",
      AX_Y: "Water consumption [m3]",
    },
    CHART_HOT_WATER: {
      LEGEND_WATER: "Consumption in my apartment",
      LEGEND_AVERAGE_WATER: "Average consumption in the building",
      AX_X: "Year",
      AX_Y: "Water consumption [m3]",
    },
    CHART_HEAT: {
      LEGEND_HEAT: "Consumption in my apartment",
      LEGEND_AVERAGE_HEAT: "Average consumption in the building",
      AX_X: "Year",
      AX_Y: "Heat consumption [Consumption values, kWh, GJ]",
    },
  },
  DAILY_CONSUMPTION: {
    CHART_WATER: {
      HOT_WATER: "Hot water",
      COLD_WATER: "Cold water",
      AX_X: "Day",
      AX_Y: "Water consumption [liters]",
      AX_X_HEAT: "Day",
      AX_Y_HEAT: "[Consumption values, kWh, GJ]",
      HEAT: "Heat",
    },
    CHART_ROOMS: {
      AX_X: "Day",
      AX_Y: "[Consumption values, kWh, GJ]",
    },
    BUTTON1: "Another period",
    BUTTON2: "Months",
    BUTTONOK: "Search",
    FROM: "from: ",
    TO: "to: ",
    FILTERMONTH: "Month",
    EMPTY: "There are no data available for the selected period.",
  },
  BALANCES: {
    TABLE: {
      VS: "Reference no.",
      APARTMENT_NUMBER: "Apartment no.",
      APARTMENT_TYPE: "Apartment type",
      NAME: "Name",
      BALANCE: "Balance",
      LAST_PAYMENT_DATE: "Last payment date",
    },
    PARAMETER: {
      DATE: "due date ",
      BUTTON: "Submit",
    },
  },
  UNPAID_PREPAYMENTS: {
    TABLE: {
      USER: "Name",
      APARTMENT_NUMBER: "Apartment no.",
      PERIOD: "Period",
      DUE_DATE: "Due date",
      TYPE: "Type of payment",
      AMOUNT: "Amount",
      PAID: "Paid",
      TO_BE_PAID: "Unpaid",
    },
    TOOLTIP: {
      APARTMENT_NUMBER: "Room number",
      DUE_DATE:
        "In the case of a payment, this is the date of receipt of the given payment.",
      TYPE: "In the case of a payment, the type of payment will be displayed.",
      PAID:
        "In the case of displaying a payment, only the part of the payment that is not assigned to any prescription until the specified date is displayed.",
    },
    PARAMETER: {
      DATE: "to date:",
      BUTTON: "Load",
    },
  },
  UNPAID_DEBTS: {
    TABLE: {
      VS: "Reference no.",
      APARTMENT_NUMBER: "Apartment no.",
      APARTMENT_TYPE: "Apartment type",
      NAME: "Name",
      CLAIM_NAME: "Claim name",
      PERIOD: "Period",
      TO_BE_PAID: "To be paid",
      PAID: "Paid",
      DUE_DATE: "Due date",
      PAYMENT_STATUS: "Status",
      IS_BEING_VERIFIED: "is being verified",
      //ALREADY_PAID: " already paid",
      PAYMENT_DUE: "Payment due ",
    },
  },
  AUTH: {
    GENERAL: {
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      SIGNUP_ADDSPACE: "Add unit",
      SIGNIN_BUTTON: "Sign In",
      SIGNOUT_BUTTON: "Sign Out",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      BACK_TOLOGIN: "Back to login page",
      PLACEHOLDER_EMAIL: "Email address",
      CONTINUE: "Continue",
      DISCONNECT_AND_CANCEL: "Disconect unit and delete account",
      TEXT1:
        "Technická odstávka. Z dôvodu servisnej údržby bude portál a súvisiace služby 20.9.2021 dočasne nedostupné. Ďakujeme za pochopenie.",
      TEXT2:
        "Probíhá aktualizace portálu. Některé služby mohou být dočasně nedostupné. Děkujeme za pochopení.",
    },
    LOGIN: {
      BUTTON: "Sign In",
      TITLE_REG: "Sign me up",
      TEXT_MOBIL: "Please enter the verification code you received in the SMS",
      TEXT_EMAIL: "Please enter the verification code you received by email",
      LOGIN_EXTERNAL: "Sign in with",
      LOGIN_WITH_USERNAME_PASSWORD:
        "Or sign in with an email address and a password",
      USERNAME_REQUIRED: "Use the email you provided when registering",
      PASSWORD_REQUIRED: "Password is required",
      PLACEHOLDER_LOGIN: "Email",
      PLACEHOLDER_PWD: "Password",
      DEMO: "Or try out a demo access",
      WARNING:
        "(*the original login names used before 1.7.2021 are no longer accepted. Your login name has been changed to the email address you used for the original registration)",
      VYUCTOVANIE1: "Read: ",
      VYUCTOVANIE2: "Electronic voting on POSCHODOCH.SK",
    },
    UNITS: {
      TITLE: "Unit selection",
      SELECT_BUTTON: "Select",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
    },
    REGISTER: {
      TITLE: "Sign Up",
      TITLE_PHONE:
        "A verification code will be sent to your mobile phone to continue your registration. Please enter your mobile phone number:",
      SEND_CODE: "Send code",
      SEND_BUTTON: "Send",
      DIVIDER: "OR",
      LOGIN: "Login",
      ACCOUNT_EXISTS: "Already have an account?",
      VALIDATION: {
        EMAIL: "Email is required. Must be a valid email address.",
        PWD:
          "Password is required. Must be min. 8 characters while containing at least 1 character from each of these 3 categories: Uppercase letter, Lowercase letter, Number",
        PHONE:
          "Phone is required. Must have more than 10 characters, numbers from the Slovak and Czech republics (+421/+420) must have a valid format at one of the available mobile service providers.",
        CODE_EMAIL: "Email verification code is required.",
        CODE_SMS: "SMS verification code is required.",
      },
      PLACEHOLDER_PHONE: "Phone number",
      PLACEHOLDER_CODE_EMAIL: "Email verification code",
      PLACEHOLDER_CODE_SMS: "SMS verification code",
      STARTOVER:
        "Have you made a mistake during registration? Go back to the first step and start over at any time.",
      CODE_RESEND: "Resend verification code",
    },
    REGISTRATION_CODE: {
      TITLE: "Enter registration code",
      TEXT:
        "To access the portal pages, you need a registration code that identifies your flat. You can get the code from your property manager.",
      REQUIRED:
        "Registration code is required. Can contain only numbers, large and small letters with the exception of 'O'/'o'. Length must be 11 or 12 characters.",
      SEND_BUTTON: "Submit",
      PLACEHOLDER_REGCODE: "Registration code",
      HINT1: "Common causes of incorrect code: ",
      HINT2: "- typo in any character, The code has 11 or 12 characters.",
      HINT3:
        "- the code is not yet active. The newly generated codes are valid until the next day, by default within 24 hours.",
      HINT4:
        "- the code may be revoked by an administrator or administrator for security reasons.",
      HINT5:
        "If your code is not accepted the next day, please contact your administrator.",
    },
    REGISTRATION_AGREEMENT: {
      TITLE: "Terms and Conditions of Use and Price",
      HEAD: "Confirmation of terms & conditions and price",
      NAME: "Name",
      ADDRESS: "Address",
      VARIABLE_SYMBOL: "Reference number",
      AGREE_BUTTON: "I agree",
      PRICE_CHECKBOX: "I agree to the price for access ",
      PRICE_TEXT: "The building manager has set the price for access at ",
      PRICE_TEXT2:
        "In the case of an active consumption control service (remote deductions), the price for access to the portal is included in the fee for this service.",
      CUSTOMER_TEXT: "Provided by company: ",
      LEGAL_AGREEMENT_CHECKBOX:
        "I have read and agree to the Terms and Conditions of Use of the provider",
      LEGAL_AGREEMENT_CHECKBOX_ANA:
        "I have read and agree to the Terms and Conditions of Use of the operator",
      GDPR_AGREEMENT_CHECKBOX:
        "I have read and agree to the Personal Data Protection Rules",
      ANASOFT_TEXT: "The portal is operated by ANASOFT APR, spol. s r.o.",
      REQUIRED: "All conditions must be agreed (“ticked“) to continue.",
      TEXT_ACCESS: "Access to object information:",
    },
  },
  SPEEDOMETER: {
    VALUETEXT: "Current consumption: ",
  },
  RESETPASSWORD: {
    TITLE: "Insert new password",
    BUTTON: "Create password",
    TEXT: "You password has been changed. You can log in.",
  },
  FORGOTPASSWORD: {
    TITLE: "Insert your account e-mail",
    BUTTON: "Reset password",
  },
  VOTING: {
    BUTTON: "Send",
    TXT: "Input vote code from your building manager:",
    MAINTITLE: "Voting list",
    SECONDTITLE: "Period when the vote takes place:",
    BUILDING_MANAGER: "Building manager:",
    OWNER: "Owner:",
    EMAIL_TXT: "Email address to send confirmation:",
    PHONE_TXT: "Voting code sent to:",
    FILL_ALL: "Fill answers for all flats at once:",
    CONSENT:
      "I confirm, that I'm the person mentioned above and as a owner of the mentioned premises,(?) on the LV list within the according land registry (?), I'm elligible to vote. Should it be confirmed that this information provided is misleading or untrue I'm aware of my criminal responsibility and the resposibility for accrued damages.",
    SEND_CONFIRM:
      "You haven't answered all of the questions. Your voting list might be invalid. Do you really wish to send?",
    SENT_TXT:
      "Your voting has been processed. You should receive a confirmation e-mail shortly",
    BUTTON_BACK: "Back to login page",
  },
  PACKAGE: {
    BUTTON: "Send",
    TXT: "Enter the code:",
    WRONGPASSWD: "Wrong password",
    ENTRY: "(Access to the letter is password protected)",
    PACKAGE: "Letter",
  },
  USERINFO: {
    ADDRESS: "Address",
    VS: "VS",
    TYPE: "Type",
    NAME: "Name",
    SURNAME: "Surname",
    DEGREE: "Degree",
    PERSON: "Person",
    EMAIL: "Email",
    MOBIL: "Mobile",
    ADDRESS2: "Post address",
    STREET: "Street",
    CITY: "City",
    SIPO: "SIPO",
    BANKACCOUNT: "Bank account",
    ELECTRONICINVOICE: "Electronic invoice",
    PAYMENTORDER: "Payment order",
    PERSONALINFO: "Personal information",
    CONTACTINFO: "Contact address",
    PAYMANTINFO: "Payment information",
    OTHERSINFO: "Others",
    OTHERSINFO2: " ",
    ACTIVE: "active",
    INACTIVE: "inactive",
  },
  CUSTREPORT: {
    BUTTON: "Generate PDF output",
    MAINTITLE: "CUST REPORT TITLE",
    FIRSTLISTOPTION: "-- Please select an option --",
    EMPTYVALIDATION: "This field cannot remain empty (without a value)",
  },
  DAILYCONSUMPTIONOBJECT: {
    FLATNUMBER: "Name/Flat number",
    VARSYMB: "Reference number",
    CODE: "Service",
    METERNUMBER: "Meter number",
    LOCATION: "Location",
    STATE: "State",
    SV: "CW",
    TV: "HW",
    UK: "CH",
    DATE: "Date:",
  },
  INVOICESAPPROVAL: {
    TITLE: "Invoice - tax document",
    VS: "Variable symbol",
    DODAV: "Supplier",
    PREDMET: "Subject",
    SUMAKU: "Amount to settle",
    UDAJKFAK: "Invoice details",
    RIADKYFAKT: "Invoice rows",
    DOVOD: "Reason:",
    NIE: "No",
    ANO: "Yes",
    DOVODHLASKA: "The reason for the status change is mandatory!",
    DAKUEJME: "Thank you!",
    ANONIEHLASKA:
      "You can approve the invoice by pressing the 'Yes' button, or reject it by pressing the 'No' button.",
    FILE: "File",
    NAZOV: "Title",
    SPOLU: "Sum",
    MENA: "Currency",
    SHOWERRORINT:
      "An invoice cannot be approved to the extent that there are no approval states",
  },
  SHOWPICTURE: {
    INFO: "Deduction info",
    METERNUMBER: "Meter number",
    READINGDATE: "Deduction date",
    STATE: "Value",
  },
  BETA: {
    MSG: "Beta version",
  },
  EULAPRICES: {
    SUM: "Sum",
    DATE: "Date",
    TITLE: "Provider price for access",
  },
  HOME: {
    MAINTITLE_LEFT: "POSCHODOCH",
    MAINTITLE_LEFT_PENTA: "Services by PENTA",
    MAINTITLE_RIGHT: "Live with an overview",
    MAINTEXT_LEFT:
      "Access to information about your flat from your property management company.",
    MAINTEXT_RIGHT_1:
      "The poschodoch.sk portal is intended for tenants and owners of residential and non-residential premises. The aim of the portal is to provide all information about the apartment and the house.",
    MAINTEXT_RIGHT_2:
      "The poschodoch.sk web portal makes financial and technical data on flats and houses available to their tenants. The property management company allows access to the portal by providing a registration code on request. " +
      "The data is accessible from the DOMUS information system, and therefore the property management company must use this system.",
    BANNERS: {
      GS: {
        TITLE: "Consumption charts",
        TEXT:
          "Daily consumption of water, heat and cold in the apartment easily and quickly. Comparing consumption against building averages, against other periods ...",
      },
      K: {
        TITLE: "Contacts",
        TEXT:
          "Access to important contacts from the property management company or from home.",
      },
      FO: {
        TITLE: "Repair fund",
        TEXT:
          "A perfect overview of the creation and drawing from the Maintenance and Repair Fund. You can download monthly reports with a detailed breakdown in a PDF document.",
      },
    },
    FUNCTIONS: {
      TITLE: "On the portal you will also find:",
      DENNESPOT: "Daily readings",
      FONDOPRAV: "Repair fund",
      UDALOSTI: "Notice board",
      DOKUMENTY: "Building documents",
      ROCNEVYUCT: "Annual statement",
      GRAFY: "Cost charts",
      KONTO: "Account - balance of payments",
      ZASIELKY: "Documents sent from the administrator",
      FAKTURY: "Invoices",
      REVIZIE: "Revisions",
      KONTAKTY: "Contacts",
      ZIADOSTI: "Requests",
    },
    DEMO: "Try out a demo access",
    INSTALL: "Mobile application",
    APP: "Try also the mobile application",
  },
  DASHBOARD: {
    COMMUNITY: "Our community of owners",
  },
  CUSTOMERPAGE: {
    TABLE: {
      NAME: "Page Name",
      NAMESK: "SK page name",
      NAMECZ: "CZ page Name",
      NAMEEN: "EN page Name",
      PUBLISH: "Publish (Post Change)",
      SEND: "Send notification emails",
      SENDBUTTON: "Save Changes",
      ORDER: "Location",
      NAMEOFPAGE: "Page title",
      ISNEW: "Changed",
      DATE: "Date Modified",
      BACK: "Back",
      VISIBLE: "Visible",
    },
  },
  INSURANCEEVENTS: {
    NAME: "Name",
    STAV: "State",
    DATECREATION: "Event date",
    NUMBER: "Number of insurance event",
    DOCUMENTS: "Documents",
    OBJECT: "Place of insurance event",
    CAUSE: "Cause of damage ",
    DAMAGE: "Extent of damage",
    RECIEVER: "Recipient",
    SUMDAMAGE: "Damage amount",
    SUM: "Amount",
  },
  USERLISTOFFICIALS: {
    NAME: "Name",
    UNITNUMBER: "Unit number",
    EXTUNITNUMBER: "Ext. Unit number",
    STREET: "Street",
    PSC: "Postcode",
    CITY: "City",
    PCSCITY: "Postcode, City",
    ODD1: "Address",
    ODD2: "Additional user data",
    TEL: "Phone",
    MOBILE: "Mobile",
    EMAIL: "Email",
    VS: "Variable symbol",
    SIPO: "SIPO connection number",
    UNITDESCRIPTIONSK: "Type of space",
    USERTYPE: "Tenant / Owner",
    UNITSTOCK: "Floor",
    PERSONS: "Number of persons",
    PAYINFO: "Payment information",
    DTPRIST: "Date of arrival",
    DTODST: "Date of move-out",
    CHECKUZI: "Current users",
    PORTALACCESSTRUE: "User with registered access",
    PORTALACCESSFALSE: "User without registered access",
    PORTALACCESSTOOLTIP: "Registration on the portal",
    DATE: "To: ",
    ODD3: "Apartment data",
    ODD4: "Plochy",
    PERSONCOUNT: "Number of persons",
    ROOMS: "Rooms",
    CUT: "Co-ownership share",
  },
  ADMINACCOUNT: {
    LOGINNAME: "Login",
    CODE: "Admin Code",
    STATE: "Status",
    MOBILE: "Mobile",
    CREATEDATE: "Creation Date",
    DETACH: "Disconnect all spaces and cancel account",
    QU: "Are you sure?",
    ADDMANAGER: "Add Admin",
    CHANGE: "Change mail for:",
    NEWMANAGER: "New email for admin",
    EMAIL: "Email",
    BTNCHANGE: "Change Email",
    BTNNEW: "Create a manager",
    MODALTITLE: " :",
    TITLE: "Administrators Administration",
  },
  REGLOGLIST: {
    LOGINNAME: "Login",
    CODE: "Admin Code",
    FROM: "Date",
    TABLETITLE: "Events",
    TITLE: "Registration Log",
    REGSTEP: "Step",
    REGPARM: "Parameters",
    CREATEDATE: "Creation Date",
  },
  METERDAILYREADINGS: {
    CODE: "code",
    TYPE: "Medium",
    STATE: "state",
    CONSUMPTION: "Consumption",
    DATE: "date",
    APARTMENT: "Flat",
    METERNUMBER: "Meter number",
    DATEFROM: "Date From",
    DATETO: "Date to",
    STATETO: "Value to",
    UNIT: "UoM",
    STATEFROM: "Value from",
    MEDIUM: "Medium",
    APARTMENTNUMBER: "Apartment number",
    ERRORDATE: "The to date must be greater than the from date",
  },
  METERDAILYREADINGSOBJECT: {
    CHARTS: {
      AX_X_COLD: "Water consumption [l]",
      AX_Y_COLD: "Space",
      AX_X_HOT: "Water Consumption [l]",
      AX_Y_HOT: "Space",
      AX_X_HEAT: "Heat consumption [Dividers or kWh]",
      AX_Y_HEAT: "Space",
      HOT_WATER: "Hot water",
      COLD_WATER: "Cold Water",
      HEAT: "Heat",
      LEGEND_WATER_COLD: "Flat Consumption",
      LEGEND_AVERAGE_WATER_COLD: "Average consumption",
      LEGEND_WATER_HOT: "Flat Consumption",
      LEGEND_AVERAGE_WATER_HOT: "Average Consumption",
      LEGEND_HEAT: "Flat Consumption",
      LEGEND_AVERAGE_HEAT: "Average consumption",
    },
    BUTTONOK: "Load",
    DATETO: "Date",
  },
  ACCOUNTLIST: {
    TABLE: {
      VS: "Variable symbol",
      NAME: "Name",
      TYPE: "Type",
      DETAIL_OF_MOVEMENT: "Movement Detail",
      DUE_DATE: "Date of repayment / payment",
      AMOUNT: "Amount",
    },
    TYPE: "Type",
    FILTER: {
      ALL: "All",
      N: "Cargo",
      P: "Payment",
      Z: "Balance",
    },
  },
  CUSTOMERLIST: {
    TITLE: "Customer administration",
    TITLE2: "General conditions of the customer",
    TITLEEDIT: "Customer settings:",
    RESET: "Preset",
    ORIGINAL: "Original",
    SENDBUTTON: "Uložiť zmeny",
    BUTTONFREESK: "Zadarmo(SK)",
    BUTTONFREECZ: "Zdarma (CZ)",
    BUTTONFREEEN: "Free(EN)",
    TEXTFREESK: "0,00 Eur s DPH/mesiac (zadarmo)",
    TEXTFREECZ: "0,00 Kč s DPH/měsíc (zdarma)",
    TEXTFREEEN: "0,00 Eur VAT inc./month (free of charge)",
    NEWEULA: "New Terms",
    DATEFROM: "Date effective from",
    CONTENTSK: "SK content",
    CONTENTCZ: "CZ content",
    CONTENTEN: "EN content",
    APRREQ: "Require (re)confirmation of conditions",
    TABLE: {
      NAME: "Name",
      CODE: "Code",
      CITY: "City",
      PRIZE: "Prize",
      COLOR: "Color",
      EULASK: "Eula SK",
      EULACZ: "Eula CZ",
      EULAEN: "Eula EN",
      LOGO: "Logo",
    },
  },
  NOTIFICATIONSETTINGS: {
    TITLE: "Notification settings",
    DESCRIPTION:
      "The administrator will change the notification settings within 24 hours (* - the notification settings are determined by the administrator)",
    WATCH: "We do not notify",
    NOTWATCH: "Notify only on the portal",
    WATCHWITHMAIL: "We notify on the portal and also send an e-mail",
    WATCH1: "Off",
    NOTWATCH1: "(Only on poschodoch.sk)",
    WATCHWITHMAIL1: "(By e-mail and on poschodoch.sk)",
    SETMANAGER: "Set by administrator",
    APPLY: "Apply to all my premises",
    OK: "OK",
    APPLIED: "The settings have been changed",
  },
  EVENT_DETAIL: {
    DESCRIPTION: "Description",
    SUBJECT: "Subject",
    NEW: "New",
    EDIT: "Edit",
    DELETE: "Delete",
    SAVE: "Save",
    BACKBUTTON: "Back",
    UPLOAD: "Attach files",
    FILEATTACHMENTCOMMENT: "Attached documentation",
    DELETE_FILES: "Unattach files",
    UPLOADED: "Attached files",
    UPLOADPLACEHOLDER: "Select a file or drag it here...",
    DELETE_FILES_PRE: "Cancelled - ",
    NEWEVENT: "Add Event",
    PLACE: "Place",
    DATEFROM: "Date From",
    DATETO: "to",
    ONLYOWNED: "Mine",
    NOTIFI: "Notify other users about making changes.",
    SUBMITTED: "Submittor",
    EVENTCOUNT: "A maximum of 3 events can be entered per day",
    LENGTHERROR: "Maximum file size is 15MB",
    REQUIRED: "Unfilled mandatory fields",
    TOMANYFILES: "Maximum number of files is 5",
    REQUIREDDATE: "Je potrebné mať vyplnené oba dátumy udalosti",
    ELEVOTING: "Elektronicky hlasovalo",
    ELENOTVOTING: "Elektronicky nehlasovalo",
    WRITINGVOTING: "Písomne hlasuje",
    ACTUAL: "Aktuálny stav",
    VS: "Variabilný symbol",
    OBJECT: "Objekt",
    NAME: "Meno",
    VOTE: "Stav",
    TOOLTIP: "Hlasoval",
    TOOLTIPNOPE: "Nehlasoval",
    TOOLTIPWRITING: "Písomne",
    TOOLTIPELE: "Elektronicky",
    TOOLTIPDATE: "Dátum",
  },
  NOTIFICATIONS: {
    CREATION_DATE: "Date and time",
    DESCRIPTION: "Description",
    NOTIF: "Notifications",
    NOTE:
      "The administrator will change the notification settings within 24 hours (* - notification settings are determined by the administrator)",
  },
  CASHREGISTER: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Description of the cash register",
      BANK: "Number",
      FINALBALANCE: "Final Balance",
      BANKACCOUNT: "Account number",
      ISSUEDATE: "Status as of date",
      ISSUEDATEMOBILE: "Status to",
    },
  },
  CASHREGISTER_DETAIL: {
    TABLE: {
      ACCOUNTDESCRIPTION: "Account Description",
      CURRENCY: "Currency",
      INITIALBALANCE: "Initial balance",
      INITIALDATE: "Initial date",
      RELATIVEBALANCE: "Relative balance",
      FINALBALANCE: "Final Balance",
      BANKACCOUNT: "Account (IBAN)",
      BANK: "Number",
      ISSUEDATE: "Issue Date",
      VS: "Variable symbol",
      TEXT: "Text",
      DATE: "Date",
      AMOUNT: "Amount",
      DESCRIPTION: "Description",
    },
    TITLEADD: "Status to ",
    PARAMETER_YEAR: "Year:",
    PARAMETER_MONTH: "Month:",
    PARAMETER_REPORT: "Show unconfirmed reports",
  },
};
