<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <!--Begin::Bids-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row>
                <!-- Filter -->
                <div
                  class="d-flex flex-row mb-3 justify-content-md-start justify-content-center"
                >
                  <div
                    class="ml-md-2 mt-3 mt-md-0 d-flex flex-md-row flex-column justify-content-between w-100"
                  >
                    <span
                      class="d-flex flex-row align-items-center mr-md-3 mr-0"
                    >
                      <label for="search" class="mr-1" style="font-weight: 500">
                        {{ $i18n.t("EVENTS.SEARCH") }}:
                      </label>
                      <b-form-input v-model="inputTextFilter"></b-form-input>
                    </span>
                    <span
                      class="d-flex flex-row align-items-center mr-md-3 mr-0"
                    >
                      <label for="dF" class="mr-1" style="font-weight: 500">
                        {{ $i18n.t("EVENTS.FROM") }}
                      </label>
                      <b-form-input
                        id="dF"
                        v-model="inputDateFrom"
                        type="date"
                      ></b-form-input>
                    </span>
                    <span
                      class="d-flex flex-row align-items-center mt-md-0 mt-3"
                    >
                      <label for="dT" class="mr-1" style="font-weight: 500">
                        {{ $i18n.t("EVENTS.TO") }}
                      </label>
                      <b-form-input
                        id="dT"
                        v-model="inputDateTo"
                        type="date"
                      ></b-form-input>
                    </span>
                    <div
                      class="d-flex flex-row align-items-center mt-md-0 mt-3"
                    >
                      <b-form-checkbox
                        id="checkKorig"
                        v-on:change="vlastne = !vlastne"
                        size="md"
                        style
                        class="ml-3"
                        v-bind:checked="vlastne"
                        switch
                      >
                        {{ $i18n.t("EVENT_DETAIL.ONLYOWNED") }}
                      </b-form-checkbox>
                    </div>
                    <div class="d-flex align-items-md-center align-self-center">
                      <b-button
                        variant="primary"
                        class="ml-md-3 ml-0 mt-3 mt-md-0"
                        v-on:click="
                          fetchData(inputDateFrom, inputDateTo, vlastne)
                        "
                      >
                        {{
                          $i18n.t("DAILY_CONSUMPTION_AVERAGE_OBJECT.BUTTONOK")
                        }}
                      </b-button>
                    </div>
                    <div class="d-flex align-items-md-center align-self-center">
                      <b-button
                        variant="primary"
                        class="ml-md-3 ml-0 mt-3 mt-md-0"
                        v-on:click="createNewEvent"
                        v-if="addButton == 1"
                      >
                        <i class="fas fa-plus-circle mb-1"></i>
                        <span>{{ $i18n.t("EVENT_DETAIL.NEWEVENT") }}</span>
                      </b-button>
                    </div>
                  </div>
                </div>
                <!-- Filter -->
                <!--Begin::Count of rows per page -->
                <b-col class="poschodoch-rows-count">
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                      @change="getFormatService().formatSetPerPage(perPage)"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!--End::Count of rows per page -->
              </b-row>

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-function="filterTable"
                class="poschodoch-events-table"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Subject)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="
                      showEventsDetail(data.item.EventId, data.item.TEventId)
                    "
                  >
                    {{ data.value }}
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="align-self-start">
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="data.item.Priority == 0"
                      class="mr-3"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #0066a1"
                      />
                    </svg>
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="data.item.Priority == 1"
                      class="mr-3"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #fbbb17"
                      />
                    </svg>
                    <svg
                      width="3px"
                      height="3rem"
                      v-if="data.item.Priority == 2"
                      class="mr-3"
                    >
                      <rect
                        width="3px"
                        height="3rem"
                        class="poschodoch-rectangle"
                        style="fill: #c5415d"
                      />
                    </svg>
                  </div>
                  <div
                    class="d-flex flex-column align-items-start justify-content-center w-100 poschodoch-officials-table-content"
                  >
                    <span
                      class="poschodoch-officials-title-mobile"
                      style="color: var(--company-color) !important"
                    >
                      {{ data.value.Subject }}
                    </span>
                    <div
                      class="poschodoch-officials-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.Date,
                            ) +
                              ", " +
                              data.value.Responsible
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right"
                      >
                        <a
                          v-if="data.item.DocCount > 0"
                          href="javascript:void(0)"
                          v-on:click="
                            downloadFile(data.item.RecKey, data.item.Subject)
                          "
                          class="poschodoch-icon-link p-2"
                        >
                          <i
                            class="flaticon-download poschodoch-icon-medium"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(Rectangle)="data">
                  <svg width="3px" height="3rem" v-if="data.item.Priority == 0">
                    <rect
                      width="3px"
                      height="3rem"
                      class="poschodoch-rectangle"
                      style="fill: #0066a1"
                    />
                  </svg>
                  <svg width="3px" height="3rem" v-if="data.item.Priority == 1">
                    <rect
                      width="3px"
                      height="3rem"
                      class="poschodoch-rectangle"
                      style="fill: #fbbb17"
                    />
                  </svg>
                  <svg width="3px" height="3rem" v-if="data.item.Priority == 2">
                    <rect
                      width="3px"
                      height="3rem"
                      class="poschodoch-rectangle"
                      style="fill: #c5415d"
                    />
                  </svg>
                </template>
              </b-table>
              <!--End::Main table element -->
              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->

              <!-- Priority legend -->
              <div>
                <span class="mr-3">
                  {{ $i18n.t("EVENTS.PRIORITY.TEXT") + ": " }}
                </span>
                <svg width="3px" height="3rem" class="mr-3">
                  <rect
                    width="3px"
                    height="3rem"
                    class="poschodoch-rectangle"
                    style="fill: #c5415d"
                  />
                </svg>
                <span class="mr-3">{{ $i18n.t("EVENTS.PRIORITY.3") }}</span>
                <svg width="3px" height="3rem" class="mr-3">
                  <rect
                    width="3px"
                    height="3rem"
                    class="poschodoch-rectangle"
                    style="fill: #fbbb17"
                  />
                </svg>
                <span class="mr-3">{{ $i18n.t("EVENTS.PRIORITY.2") }}</span>
                <svg width="3px" height="3rem" class="mr-3">
                  <rect
                    width="3px"
                    height="3rem"
                    class="poschodoch-rectangle"
                    style="fill: #0066a1"
                  />
                </svg>
                <span class="mr-3">{{ $i18n.t("EVENTS.PRIORITY.1") }}</span>
              </div>
              <!-- Priority legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Bids-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import Loader from "@/views/components/Loader.vue";
import FormatService from "@/common/format.service";

export default {
  name: "Events",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      errors: [],
      menuId: null,
      title: null,
      items: null,
      description: null,
      dateFrom: null,
      dateTo: null,
      inputDateFrom: null,
      inputDateTo: null,
      inputTextFilter: null,
      items1: [],
      items2: [],
      itemsSet1: 0,
      itemsSet2: 0,
      addButton: 0,
      vlastne: 0,
      fields: [
        {
          key: "Rectangle",
          label: "",
          sortable: true,
          class: "table-no-mobile",
        },
        {
          key: "Subject",
          label: this.$i18n.t("EVENTS.TABLE.SUBJECT"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile poschodoch-events-subject",
        },
        {
          key: "Owner",
          label: this.$i18n.t("EVENT_DETAIL.ZADAL"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile poschodoch-events-notSubject",
        },
        {
          key: "EventType",
          label: this.$i18n.t("EVENTS.TABLE.TYPE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile poschodoch-events-notSubject",
        },
        {
          key: "Date",
          label: this.$i18n.t("EVENTS.TABLE.DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile poschodoch-events-notSubject",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile flex-row",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
      totalRows: 1,
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
    };
  },
  computed: {
    filter() {
      return { Text: this.inputTextFilter };
    },
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showEventsDetail(record.EventId, record.TEventId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showEventsDetail(eventID, TeventID) {
      if (eventID) {
        this.$router.push({
          name: "domus-events-detail",
          query: { menuId: this.menuId, eventId: eventID },
        });
      }
      if (TeventID) {
        this.$router.push({
          name: "domus-events-detail",
          query: { menuId: this.menuId, teventId: TeventID },
        });
      }
    },
    createNewEvent() {
      if (this.EventCount >= 3) {
        this.errors.push(this.$i18n.t("EVENT_DETAIL.EVENTCOUNT"));
        return;
      }
      this.$router.push({
        name: "domus-events-detail",
        query: { menuId: this.menuId },
      });
    },
    filterTable(row, filter) {
      let result = true;

      if (filter.Text != null) {
        if (
          !FormatService.normalizeString(row.Subject).includes(
            FormatService.normalizeString(filter.Text),
          )
        ) {
          result = false;
        }
      }

      return result;
    },
    fetchData(sDateFrom = null, sDateTo = null, svlastne = 0) {
      this.items = [];
      this.items1 = [];
      this.items2 = [];
      this.itemsSet1 = 0;
      this.itemsSet2 = 0;
      let parameters = "?menuId=" + this.menuId.toString();

      if (sDateFrom != null) parameters += "&dateFrom=" + sDateFrom;
      if (sDateTo != null) parameters += "&dateTo=" + sDateTo;
      if (svlastne != null) parameters += "&vls=" + (svlastne ? 1 : 0);

      ApiService.setHeader();
      ApiService.get("Object", "Events" + parameters).then(({ data }) => {
        this.transformData(data.Events);

        this.title = data.Title;
        this.description = data.Description;
        this.items1 = data.Events;
        this.dateFrom = data.DateFrom;
        this.dateTo = data.DateTo;
        this.addButton = data.AddButton;

        this.inputDateFrom = this.dateFrom;
        this.inputDateTo = this.dateTo;
        this.itemsSet1 = 1;
        this.currentPage = 1;

        if (this.itemsSet2 === 1) {
          this.items = this.items1.concat(this.items2).sort(function(a, b) {
            return new Date(b.Date) - new Date(a.Date);
          });
          this.totalRows = this.items1.length + this.items2.length;
        }
      });
      ApiService.get("Object", "EventsPortal" + parameters).then(({ data }) => {
        this.transformData(data.Events);

        this.title = data.Title;
        this.description = data.Description;
        //this.items = data.Events;
        this.items2 = data.Events;
        this.dateFrom = data.DateFrom;
        this.dateTo = data.DateTo;

        this.inputDateFrom = this.dateFrom;
        this.inputDateTo = this.dateTo;
        this.EventCount = data.EventCount;
        this.itemsSet2 = 1;

        this.currentPage = 1;

        if (this.itemsSet1 === 1) {
          this.items = this.items1.concat(this.items2).sort(function(a, b) {
            return new Date(b.Date) - new Date(a.Date);
          });
          this.totalRows = this.items1.length + this.items2.length;
        }
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Subject: items[i].Subject,
          Responsible: items[i].Responsible == null ? "" : items[i].Responsible,
          Date: items[i].Date,
        };
      }
    },
  },
};
</script>
