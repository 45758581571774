<template>
  <div>
    <a id="document-download-link" style="display: none"></a>
    <DocumentsUploadModal
      :filterCategory="filterCategory"
      :categories="filteredCategoryOptions"
      ref="documentsUploadModal"
    ></DocumentsUploadModal>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <b-container fluid v-if="items">
              <b-row class="mb-4 align-items-end">
                <b-col>
                  <label>{{ $t("DOCUMENTS.FILTER.DOC_NAME") }}</label>
                  <b-form-input v-model="filterDocName" />
                </b-col>
                <b-col>
                  <label>{{ $t("DOCUMENTS.FILTER.CATEGORY") }}</label>
                  <b-form-select
                    v-model="filterCategory"
                    :options="categoryOptions"
                  />
                </b-col>
                <b-col>
                  <label>{{ $t("COMMON.LIST.ROW_COUNT_PER_PAGE") }}</label>
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    :options="pageOptions"
                    @change="getFormatService().formatSetPerPage(perPage)"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col class="d-flex justify-content-end">
                  <!-- v-if="addButton == 1" -->
                  <b-button
                    variant="primary"
                    @click="uploadNewFiles"
                    v-if="addButton == 1"
                  >
                    <i class="fas fa-plus-circle mb-1"></i>
                    <span>
                      {{ $i18n.t("DOCUMENTS.UPLOAD.UPLOAD_NEW_FILES") }}
                    </span>
                  </b-button>
                </b-col>
              </b-row>
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-function="filterTable"
                class="poschodoch-documents-table notranslate"
              >
                <template v-slot:cell(DocName)="data">
                  <i
                    class="flaticon2-file-1 poschodoch-icon-large poschodoch-document-icon"
                  ></i>
                  <span class="ml-md-3 ml-sm-0">{{ data.item.DocName }}</span>
                </template>
                <template v-slot:cell(DocId)="data">
                  <a
                    href="javascript:void(0)"
                    @click="
                      downloadFile(
                        data.item.DocId,
                        data.item.FileName,
                        data.item.CreatedBy,
                      )
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    v-if="
                      data.item.CreatedBy !== $t('REQUEST_DETAIL.SPR') &&
                        addButton == 1
                    "
                    @click="deleteFile(data.item.DocId, data.item.FileName)"
                    class="poschodoch-icon-link p-2 ml-3"
                  >
                    <i class="flaticon-delete poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span class="poschodoch-table-title-mobile">
                          {{ data.value.DocName }}
                        </span>
                        <span>
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.CreationDate,
                            )
                          }}
                        </span>
                        <span class="poschodoch-table-title-mobile">
                          {{ $i18n.t("DOCUMENTS.TABLE.CATEGORY") }}:
                          {{ data.value.Category }}
                        </span>
                        <span class="poschodoch-table-title-mobile">
                          {{ $i18n.t("DOCUMENTS.TABLE.CREATED_BY") }}:
                          {{ data.value.CreatedBy }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <a
                          href="javascript:void(0)"
                          @click="
                            downloadFile(
                              data.value.DocId,
                              data.value.FileName,
                              data.value.CreatedBy,
                            )
                          "
                          class="poschodoch-icon-link p-2"
                        >
                          <i
                            class="flaticon-download poschodoch-icon-medium"
                          ></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          v-if="
                            data.value.CreatedBy !== $t('REQUEST_DETAIL.SPR') &&
                              addButton == 1
                          "
                          @click="
                            deleteFile(data.value.DocId, data.value.FileName)
                          "
                          class="poschodoch-icon-link p-2 ml-3"
                        >
                          <i class="flaticon-delete poschodoch-icon-medium"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FileService from "@/common/file.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import DocumentsUploadModal from "@/views/pages/domus/DocumentsUploadModal.vue";

export default {
  name: "documentsListObject",
  components: {
    KTPortlet,
    Loader,
    DocumentsUploadModal,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      addButton: 0,
      lastPeriod: "",
      periodClass: "row",
      fields: [
        {
          key: "DocName",
          label: this.$i18n.t("DOCUMENTS.TABLE.DOC_NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "CreationDate",
          label: this.$i18n.t("DOCUMENTS.TABLE.CREATION_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value) => FormatService.formatDateToLocal(value),
        },
        {
          key: "Category",
          label: this.$i18n.t("DOCUMENTS.TABLE.CATEGORY"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "CreatedBy",
          label: this.$i18n.t("DOCUMENTS.TABLE.CREATED_BY"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DocId",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      categoryOptions: [
        { value: null, text: this.$i18n.t("DOCUMENTS.FILTER.PLACEHOLDER") },
      ],
      filterDocName: null,
      filterCategory: null,
    };
  },
  computed: {
    filter() {
      return { DocName: this.filterDocName, Category: this.filterCategory };
    },
    filteredCategoryOptions() {
      // Filter out null values from categoryOptions
      return this.categoryOptions.filter((option) => option.value !== null);
    },
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    filterTable(row, filter) {
      return (
        (!filter.DocName ||
          FormatService.normalizeString(row.DocName).includes(
            FormatService.normalizeString(filter.DocName),
          )) &&
        (!filter.Category ||
          FormatService.normalizeString(row.Category).includes(
            FormatService.normalizeString(filter.Category),
          ))
      );
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    downloadFile(docId, fileName, createdBy) {
      const isSPR = createdBy === this.$i18n.t("REQUEST_DETAIL.SPR");
      const parameters = isSPR
        ? `?docId=${docId}`
        : `?docId=${docId}&type=vcslibdocs`;
      const endpoint = isSPR ? "Get" : "GetDWP";

      ApiService.setHeader();
      ApiService.get("Document", endpoint + parameters).then(({ data }) => {
        const binaryContent = FileService.convertBase64ToByteArray(
          data.FileContent,
        );
        FileService.downloadFile(
          fileName,
          binaryContent,
          null,
          "document-download-link",
        );
      });
    },
    deleteFile(docID, fileName) {
      const answer = window.confirm(
        this.$i18n.t("DOCUMENTS.UPLOAD.DELETEQUESTION") + " (" + fileName + ")",
      );
      if (!answer) {
        return;
      }

      let postContent = {
        Files: [{ FileId: docID, Filename: fileName }],
      };

      // spravim update v tabulke T_PRILOHY - nastavim is_deleted na 1
      ApiService.post("Document/DeleteDWP", postContent)
        .then(() => {})
        .catch(() => {});

      setTimeout(function() {
        window.location.reload();
      }, 300);
    },
    setCategoryOptions(items) {
      const options = {};
      items.forEach((item) => {
        if (!options[item.Category]) {
          options[item.Category] = true;
          this.categoryOptions.push({
            value: item.Category,
            text: item.Category,
          });
        }
      });
    },
    fetchData() {
      this.items = null;
      let parameters = "?tableName=vcs&menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        // volame dalsiu API na list dokumentov z portalu (T_PRILOHY)
        parameters = "?menuId=" + this.menuId.toString();
        parameters += "&tableName=vcslibdocs";
        ApiService.setHeader();
        ApiService.get("Document", "DWPList" + parameters).then(
          ({ data: additionalData }) => {
            data.Documents.push(...additionalData.Documents);
            this.transformData(data.Documents);
            this.addButton = data.AddButton;
            this.title = data.Title;
            this.description = data.Description;
            this.items = data.Documents;
            this.totalRows = this.items.length;
            this.currentPage = 1;

            this.setCategoryOptions(data.Documents);
            this.items.sort(function(a, b) {
              return new Date(b.CreationDate) - new Date(a.CreationDate);
            });
          },
        );
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          FileName: items[i].FileName,
          DocName: items[i].DocName,
          CreationDate: items[i].CreationDate,
          Category: items[i].Category,
          DocId: items[i].DocId,
          CreatedBy: items[i].CreatedBy,
        };
      }
    },
    uploadNewFiles() {
      this.$refs.documentsUploadModal.setCategoryOptions(
        this.filteredCategoryOptions,
      );
      this.$refs.documentsUploadModal.setSelectedCategory(this.filterCategory);
      this.$bvModal.show("modal-documents-upload");
    },
  },
};
</script>
<style>
body.modal-open {
  overflow: hidden;
}
</style>
