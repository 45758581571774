<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Bids-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Category)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="
                      showInsurenceEventsDetail(menuId, data.item.EventId)
                    "
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                  <span v-if="!(data.item != null)">{{ data.value }}</span>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.item != null"
                            v-on:click="
                              showInsurenceEventsDetail(
                                menuId,
                                data.item.EventId,
                              )
                            "
                            class="kt-link"
                          >
                            {{ data.item.Name }}
                          </a>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        {{ data.item.State }}
                      </div>
                    </div>
                    <div class="d-flex w-100 flex-row justify-content-between">
                      <div
                        class="d-flex flex-row align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span>
                          <b>{{ $t("BIDS.TABLE.FROM") }}</b>
                        </span>
                        <span class="ml-2">
                          {{
                            getFormatService().formatDateToLocal(
                              data.item.DateCreation,
                            )
                          }}
                        </span>
                        <span class="ml-3">
                          <b></b>
                        </span>
                        <span class="ml-2">
                          {{}}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Bids-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
//import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
//import FileService from "@/common/file.service";

export default {
  name: "domus-insurence-events",
  components: {
    KTPortlet,
    //TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      InsurenceEventsDetail: null,
      items: null,
      fields: [
        {
          key: "Name",
          label: this.$i18n.t("INSURANCEEVENTS.NAME"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Number",
          label: this.$i18n.t("INSURANCEEVENTS.NUMBER"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "State",
          label: this.$i18n.t("INSURANCEEVENTS.STAV"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DateCreation",
          label: this.$i18n.t("INSURANCEEVENTS.DATECREATION"),
          sortable: false,
          class: "text-center table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        /*{
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center p-0 table-no-mobile",
        },*/
        {
          key: "Mobile",
          label: "",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showInsurenceEventsDetail(this.menuId, record.EventId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    showInsurenceEventsDetail(menuId, EventId) {
      this.$router.push({
        name: "domus-insurence-events-detail",
        query: { menuId: menuId, eventId: EventId },
      });
    },

    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "InsurenceEvents" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;
          this.items = data.InsurenceEvents;

          if (data.InsurenceEvents) this.transformData(data.InsurenceEvents);
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          EventId: items[i].EventId,
          State: items[i].State,
          Number: items[i].Number,
          Name: items[i].Name,
          DateCreation: items[i].DateCreation,
        };
      }
    },
  },
};
</script>
