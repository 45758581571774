<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Voting Sheet-->
        <KTPortlet v-bind:title="title" class="poschodoch-print">
          <!--Begin::Data -->
          <template v-slot:body>
            <div class="row">
              <b-col
                sm="9"
                lg="9"
                class="mb-2"
                style="
                  font-size: 1.2rem;
                  font-weight: 500;
                  white-space: pre-line;
                "
              >
                <b>{{ text1 }}</b>
              </b-col>
              <!-- <b-col sm="3" lg="3" class="mb-2">
                {{$i18n.t("LAYOUT.PRINT_HEADER.PRINT_DATE")}}{{new Date().toLocaleString()}}
              </b-col> -->
              <!-- "Datum tlace: " je v novom print headeri -->
            </div>
            <div class="row mb-2">
              <b-col sm="9" lg="9" class="mb-2" style="white-space: pre-line">
                {{ text2 }}
              </b-col>
            </div>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :refreshed="print()"
                class="poschodoch-votingSheetPrint-table"
              ></b-table>
              <!--End::Main table element -->
              <div class="row ml-2 mb-3">
                Dátum písomného hlasovania:
                .......................................
              </div>
              <div class="row ml-2">
                Overovateľ:
                ..................................................................
              </div>

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Voting Sheet-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "votingSheet-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      menuId: null,
      title: null,
      description: null,
      userType: null,
      roomType: null,
      objectAddress: null,
      objectNumber: null,
      items: null,
      text1: null,
      text2: null,
      Name: this.$i18n.t("VOTINGSHEET.NAME"),
      fields: [
        {
          key: "VS",
          label: this.$i18n.t("VOTINGSHEET.TABLE.VS"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "ApartmentNr",
          label: this.$i18n.t("VOTINGSHEET.TABLE.APARTMENTNR"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Name",
          label: this.$i18n.t("VOTINGSHEET.TABLE.NAME"),
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "Yes",
          label: this.$i18n.t("VOTINGSHEET.TABLE.YES"),
          sortable: false,
          sortDirection: "desc",
          class: "text-center",
          formatter: (value) => {
            value = this.$i18n.t("VOTINGSHEET.TABLE.YESYES");
            return value;
          },
        },
        {
          key: "No",
          label: this.$i18n.t("VOTINGSHEET.TABLE.NO"),
          sortable: false,
          sortDirection: "desc",
          class: "text-center",
          formatter: (value) => {
            value = this.$i18n.t("VOTINGSHEET.TABLE.NONO");
            return value;
          },
        },
        {
          key: "Signature",
          label: this.$i18n.t("VOTINGSHEET.TABLE.SIGNATURE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-center podpis-column",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.userType = this.$route.query.userType;
    this.roomType = this.$route.query.roomType;

    this.text1 = localStorage.getItem("sheetTxt1");
    this.text2 = localStorage.getItem("sheetTxt2");

    this.fetchData();
  },
  beforeDestroy() {
    window.localStorage.removeItem("sheetTxt1");
    window.localStorage.removeItem("sheetTxt2");
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      let tit = "";
      let docTitle = "";
      if (this.userType != null)
        parameters += "&userType=" + this.userType.toString();
      else parameters += "&userType=A";
      if (this.roomType != null)
        parameters += "&roomType=" + this.roomType.toString();
      else parameters += "&roomType=T";

      ApiService.setHeader();
      ApiService.get("Object", "VotingSheet" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.title = this.$i18n.t("VOTINGSHEET.NAME");
        this.description = data.Description;
        this.objectAddress = data.ObjectAddress;
        this.objectNumber = data.ObjectNumber;
        this.items = data.VotingSheet;
        tit = this.title;
      });

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
