<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <!--Begin::Bids-->
        <KTPortlet v-bind:title="title">
          <template v-slot:toolbar>
            <b-button variant="primary" id="kt_submit" @click="sendNotifAll">
              {{ $i18n.t("NOTIFICATIONSETTINGS.APPLY") }}
            </b-button>
          </template>
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->

              <b-form-group v-if="items.length > 0">
                <!--<div class="col-12 mb-5" v-if="meraceSaved.length > 0"> -->
                <!--<h5 class="mb-4">{{ $i18n.t("NEW_REQUEST.OLDMETERS") }}</h5> -->

                <div
                  class="box pb-3 pt-3"
                  v-for="item in items"
                  :key="item.NotifId"
                >
                  <!--<span class="ml-1">
                      </span>-->
                  <b-form-radio-group
                    class="row ml-1"
                    :id="'unit_check_P2_' + item.NotifId"
                    v-model="item.Selected"
                    :name="'radios-btn-default' + item.NotifId"
                    :disabled="Wait || item.Edit === 0"
                  >
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      {{ item.Description }}
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12">
                      <b-form-radio
                        class="col-lg-3 col-md-3 col-sm-12 ml-3"
                        :style="item.Edit === 0 ? 'display: none;' : ''"
                        v-on:change="postSelected(item.NotifId, 0)"
                        value="0"
                      >
                        {{ $i18n.t("NOTIFICATIONSETTINGS.WATCH") }}
                      </b-form-radio>
                      <b-form-radio
                        class="col-lg-3 col-md-3 col-sm-12 ml-3"
                        :style="item.Edit === 0 ? 'display: none;' : ''"
                        v-on:change="postSelected(item.NotifId, 2)"
                        value="2"
                      >
                        {{ $i18n.t("NOTIFICATIONSETTINGS.NOTWATCH") }}
                      </b-form-radio>
                      <b-form-radio
                        class="col-lg-3 col-md-3 col-sm-12 ml-3"
                        :style="item.Edit === 0 ? 'display: none;' : ''"
                        v-on:change="postSelected(item.NotifId, 3)"
                        value="3"
                      >
                        {{ $i18n.t("NOTIFICATIONSETTINGS.WATCHWITHMAIL") }}
                      </b-form-radio>
                      <div
                        class="col-lg-12 col-md-12 col-sm-12"
                        style="text-align:right;"
                        v-if="item.Edit === 0"
                      >
                        {{ textOrigami(item.Selected) }}
                      </div>
                    </div>
                  </b-form-radio-group>
                </div>
                <!--</div> -->
              </b-form-group>
              <!--End::Main table element -->

              <b-modal
                ref="my-modal"
                size="lg"
                hide-footer
                :title="labelTitleModal"
                class="modal-lg"
              >
                <div class="d-block text-center modal-lg mt-3">
                  <h5>{{ $i18n.t("NOTIFICATIONSETTINGS.APPLIED") }}</h5>
                </div>
                <div class="row justify-content-center">
                  <b-button
                    class="col-sm-2 mr-2 mt-5 "
                    variant="primary"
                    @click="YesModal"
                  >
                    {{ $i18n.t("NOTIFICATIONSETTINGS.OK") }}
                  </b-button>
                </div>
              </b-modal>

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items || Wait">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Bids-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
//import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import UIService from "@/common/ui.service";
//import { mapState } from "vuex";
//import FileService from "@/common/file.service";

export default {
  name: "unitsNotif",
  components: {
    KTPortlet,
    //TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      InsurenceEventsDetail: null,
      items: null,
      Wait: false,
      errors: [],
      labelTitleModal: this.$i18n.t("NOTIFICATIONSETTINGS.APPLY"),
    };
  },
  computed: {
    /*...mapState({
      errors: (state) => state.auth.errors,
    }),*/
  },
  mounted() {
    //set default brand color
    UIService.changeSiteColorCustomer();

    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    YesModal() {
      this.$refs["my-modal"].hide();
    },
    sendNotifAll() {
      UIService.addButtonSpinner("kt_submit");

      let postContent = { NotifId: null };
      //ApiService.setHeader();
      ApiService.post("Account/UnitsNotifApplyAll", postContent)
        .then(() => {
          UIService.removeButtonSpinner("kt_submit");
          this.$refs["my-modal"].show();
        })
        .catch((error) => {
          UIService.removeButtonSpinner("kt_submit");
          if (error.response.data.error) {
            this.errors.push(error.response.data.error);
          } else {
            this.errors.push(this.$i18n.t("AUTHJS.SOMETHINGWRONG") + error);
          }
        });
    },
    postSelected(notifId, state) {
      this.Wait = true;
      this.errors = [];
      let postContent = {
        NotifId: notifId,
        Selected: state,
      };

      ApiService.post("Account/UnitsNotifPost", postContent)
        .then(() => {
          this.Wait = false;
        })
        .catch((response) => {
          if (response.response.data.error)
            this.errors.push(response.response.data.error);
          else this.errors.push("Nie�o sa pokazilo. Pracujeme na tom...");
          this.Wait = false;
        });
    },
    tableRowClickHandler(record) {
      this.showUserListOfficialsDetail(this.menuId, record.UserId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    textOrigami(what) {
      let ret;
      if (what == -1) {
        ret = this.$i18n.t("NOTIFICATIONSETTINGS.WATCH1");
      }
      if (what == 0) {
        ret = this.$i18n.t("NOTIFICATIONSETTINGS.WATCH1");
      }
      if (what == 2) {
        //ret = this.$i18n.t("NOTIFICATIONSETTINGS.NOTWATCH1");
        ret = this.$i18n.t("NOTIFICATIONSETTINGS.SETMANAGER");
      }
      if (what == 3) {
        //ret = this.$i18n.t("NOTIFICATIONSETTINGS.WATCHWITHMAIL1");
        ret = this.$i18n.t("NOTIFICATIONSETTINGS.SETMANAGER");
      }
      return ret;
    },
    fetchData() {
      this.items = null;
      //let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Account", "UnitsNotif").then(({ data }) => {
        this.title = this.$i18n.t("NOTIFICATIONSETTINGS.TITLE");
        this.description = this.$i18n.t("NOTIFICATIONSETTINGS.DESCRIPTION");
        this.items = data.Notif;
      });
    },
  },
};
</script>
