import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/common/config/i18n/en";
import { locale as sk } from "@/common/config/i18n/sk";
import { locale as cz } from "@/common/config/i18n/cz";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, sk, cz };

let defaultLang = "sk";
if (
  window.location.host.includes("poschodech") ||
  window.location.host.includes("mujbyt")
)
  defaultLang = "cz";

// get current selected language
const lang = localStorage.getItem("language") || defaultLang;

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
});

export default i18n;
