<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Predpisy-->
        <KTPortlet v-bind:title="title" class="poschodoch-print">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :refreshed="print()"
              ></b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Predpisy-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "annualBilling-print",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "Name",
          label: this.$i18n.t("ANNUALBILLING.TABLE.NAME"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "PaymentOrder",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.PAYMENTORDER"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.PaymentOrder < 0) {
              item._cellVariants = { PaymentOrder: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Cost",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.COST"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Cost < 0) {
              item._cellVariants = { Cost: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Difference",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.DIFFERENCE"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.Difference < 0) {
              item._cellVariants = { Difference: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "BalanceDate",
          label: this.$i18n.t("ANNUALBILLING.TABLE.BALANCEDATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "AccountBalanceBefore",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.ACCOUNTBALANCEBEFORE"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.AccountBalanceBefore < 0) {
              item._cellVariants = { AccountBalanceBefore: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "FinalBalance",
          label: this.addCurrencyToColumn(
            this.$i18n.t("ANNUALBILLING.TABLE.FINALBALANCE"),
          ),
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
          formatter: (value, key, item) => {
            if (item.FinalBalance < 0) {
              item._cellVariants = { FinalBalance: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Text",
          label: this.$i18n.t("ANNUALBILLING.TABLE.TEXT"),
          sortable: true,
          sortDirection: "desc",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    print() {
      setTimeout(function() {
        window.print();
      }, 1000);
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      let tit = "";
      let docTitle = "";

      ApiService.setHeader();
      ApiService.get("Flat", "AnnualBilling" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.AnnualBilling;
        tit = this.title;
      });

      setTimeout(function() {
        ApiService.setHeader();
        ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
          docTitle = tit + " - " + data.PrintText;
          document.title = docTitle.replace("\n", " - ").replace("\n", ", ");
        });
      }, 1000);
    },
  },
};
</script>
