var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InvoicesDocs',{attrs:{"invoicesDocs":_vm.invoicesDocs}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('KTPortlet',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('TableContextMenu',{attrs:{"functionCSV":_vm.downloadCSV,"functionPrint":_vm.print}})]},proxy:true},{key:"body",fn:function(){return [(_vm.items)?_c('b-container',{attrs:{"fluid":""}},[_c('b-table',{attrs:{"show-empty":"","hover":"","size":"md","empty-text":_vm.$t('COMMON.LIST.NO_RECORDS'),"empty-filtered-text":_vm.$t('COMMON.LIST.NO_RECORDS_FILTERED'),"items":_vm.items,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-direction":_vm.sortDirection},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"cell(DocCount)",fn:function(data){return [(data.value > 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showInvoicesDocs(data.item.RecKey, data.item.Tablename)}}},[_c('i',{staticClass:"flaticon-file-2 poschodoch-icon-medium"})]):_vm._e(),(data.value == 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showSingleDoc(data.item.RecKey, data.item.Tablename)}}},[_c('i',{staticClass:"flaticon-download poschodoch-icon-medium"})]):_vm._e()]}},{key:"cell(PaymentStatusIcon)",fn:function(data){return [(
                    data.item.PaymentStatus != null &&
                      data.item.PaymentStatus != 'N'
                  )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"poschodoch-note-list",attrs:{"title":_vm.$i18n.t('UNPAID_DEBTS.TABLE.IS_BEING_VERIFIED')}},[_c('span',{staticClass:"poschodoch-note ml-3"})]):_vm._e()]}},{key:"cell(Mobile)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column align-items-start w-100"},[_c('div',{staticClass:"d-flex w-100 flex-row justify-content-between"},[_c('div',{staticClass:"d-flex flex-column align-items-end poschodoch-mobile-right mr-1"},[_c('b',[_vm._v(" "+_vm._s(data.value.ClaimName + " " + data.value.Period)+" ")])]),_c('div',{staticClass:"d-flex flex-column align-items-end poschodoch-mobile-right mr-1"},[_c('span',{class:[
                          data.value.ToBePaid -
                            (data.value.Paid != 0 ? data.value.Paid : 0) <
                          0
                            ? 'color-danger'
                            : '' ]},[_vm._v(" "+_vm._s(_vm.getFormatService().formatNumberToLocal( data.value.ToBePaid - (data.value.Paid != 0 ? data.value.Paid : 0) ))+" ")]),_c('span',{staticClass:"poschodoch-mobile-document-link"},[(data.value.DocCount > 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showInvoicesDocs(
                              data.item.RecKey,
                              data.item.Tablename
                            )}}},[_c('i',{staticClass:"flaticon-file-2 poschodoch-icon-medium"})]):_vm._e(),(data.value.DocCount == 1)?_c('a',{staticClass:"poschodoch-icon-link p-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showSingleDoc(
                              data.item.RecKey,
                              data.item.Tablename
                            )}}},[_c('i',{staticClass:"flaticon-download poschodoch-icon-medium"})]):_vm._e()])])]),_c('div',{staticClass:"d-flex w-100 flex-row justify-content-between"},[_c('div',{staticClass:"d-flex flex-column align-items-end poschodoch-mobile-right mr-1"},[_vm._v(" "+_vm._s(_vm.$t("UNPAID_DEBTS.TABLE.PAYMENT_DUE") + _vm.getFormatService().formatDateToLocal( data.value.DueDate ))+" ")]),_c('div',{staticClass:"d-flex flex-column align-items-end poschodoch-mobile-right mr-1"},[(
                          data.item.PaymentStatus != null &&
                            data.item.PaymentStatus != 'N'
                        )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"poschodoch-note-list",attrs:{"title":_vm.$i18n.t('UNPAID_DEBTS.TABLE.IS_BEING_VERIFIED')}},[_c('span',{staticClass:"poschodoch-note ml-3"})]):_vm._e()])])])]}}],null,false,580576063)}),(_vm.description)?_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"lg":"12"}},[_c('hr',{staticClass:"poschodoch-separator-line mt-4 mb-4"}),_c('ul',{staticClass:"poschodoch-note-list"},[_c('li',{staticClass:"poschodoch-note"},[_vm._v(_vm._s(_vm.description))])])])],1):_vm._e()],1):_vm._e(),(!_vm.items)?_c('b-container',{attrs:{"fluid":""}},[_c('Loader')],1):_vm._e()]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }