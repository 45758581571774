// does the browser support service workers?
if ("serviceWorker" in navigator) {
  // declaring scope manually
  navigator.serviceWorker.register("/sw.js", { scope: "/product/" }).then(
    (registration) => {
      console.log("Service worker registration succeeded:", registration);
    },
    (error) => {
      console.error(`Service worker registration failed: ${error}`);
    },
  );
} else {
  console.error("Service workers are not supported.");
}
