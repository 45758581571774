<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Identity takeover-->
        <KTPortlet>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title mr-4">
              <span>
                <slot name="title">{{ title }}</slot>
              </span>
            </h3>
          </template>
          <template v-slot:body>
            <!-- Filtre -->
            <div
              class="row d-flex flex-row mb-3 justify-content-md-start justify-content-center"
            >
              <div
                class="ml-md-2 mt-3 mt-md-0 d-flex flex-md-row flex-column col-12-xl col-12 mb-3"
              >
                <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                  <label for="customer" class="mr-1" style="font-weight: 500">
                    {{ $i18n.t("IDENTITY.LABEL.CUSTOMER") }}:
                  </label>
                  <b-form-select
                    id="customer"
                    v-model="inputCustomer"
                    :options="customerOptions"
                    @change="
                      fetchData(inputCustomer);
                      disableAccess = false;
                    "
                  ></b-form-select>
                </b-col>
                <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                  <label for="typeAccess" class="mr-1" style="font-weight: 500">
                    {{ $i18n.t("IDENTITY.LABEL.ACCESS") }}:
                  </label>
                  <b-form-select
                    id="typeAccess"
                    v-model="inputAccess"
                    :options="accessOptions"
                    :disabled="disableAccess"
                  ></b-form-select>
                </b-col>
                <b-col class="d-flex flex-row align-items-center mr-md-3 mr-0">
                  <label for="search" class="mr-1" style="font-weight: 500">
                    {{ $i18n.t("IDENTITY.LABEL.SEARCH") }}:
                  </label>
                  <b-form-input
                    v-model="inputSearch"
                    @input="fetchData(inputCustomer, inputSearch)"
                  ></b-form-input>
                </b-col>
              </div>
            </div>
            <!-- Filtre -->

            <!--Begin::Users -->
            <b-container
              fluid
              v-if="itemsUsers && (inputAccess == null || inputAccess == 'U')"
            >
              <b-row class="mt-3">
                <b-col sm="12" md="6" lg="6" style="align-self: flex-end">
                  <span class="poschodoch-identity-titles">
                    {{ $i18n.t("IDENTITY.USERS_TABLE.TITLE") }}
                  </span>
                </b-col>
                <b-col
                  class="poschodoch-rows-count"
                  v-if="itemsUsers.length > 10"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelectUsers"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPageUsers"
                      id="perPageSelectUsers"
                      size="sm"
                      :options="pageOptionsUsers"
                      @change="
                        getFormatService().formatSetPerPage(perPageUsers)
                      "
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                hover
                size="md"
                stacked="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="itemsUsers"
                :fields="fieldsUsers"
                :current-page="currentPageUsers"
                :per-page="perPageUsers"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="notranslate"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Link)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data"
                    v-on:click="takeoverPOST(data.item.PortalId)"
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="fas fa-user poschodoch-icon-medium"></i>
                  </a>
                </template>
              </b-table>
              <b-row
                v-if="itemsUsers && itemsUsers.length > 10"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPageUsers"
                    :total-rows="totalRowsUsers"
                    :per-page="perPageUsers"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-container>
            <!--End::Users -->

            <!--Begin::Objects -->
            <b-container
              fluid
              v-if="itemsObjects && (inputAccess == null || inputAccess == 'F')"
            >
              <b-row class="mt-3">
                <b-col sm="12" md="6" lg="6" style="align-self: flex-end">
                  <span class="poschodoch-identity-titles">
                    {{ $i18n.t("IDENTITY.OBJECTS_TABLE.TITLE") }}
                  </span>
                </b-col>
                <b-col
                  class="poschodoch-rows-count"
                  v-if="itemsObjects.length > 10"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelectObjects"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPageObjects"
                      id="perPageSelectObjects"
                      size="sm"
                      :options="pageOptionsObjects"
                      @change="
                        getFormatService().formatSetPerPage(perPageObjects)
                      "
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                hover
                size="md"
                stacked="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="itemsObjects"
                :fields="fieldsObjects"
                :current-page="currentPageObjects"
                :per-page="perPageObjects"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="notranslate"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Link)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data"
                    v-on:click="takeoverPOST(data.item.PortalId)"
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="fas fa-house-user poschodoch-icon-medium"></i>
                  </a>
                </template>
              </b-table>
              <b-row
                v-if="itemsObjects && itemsObjects.length > 10"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPageObjects"
                    :total-rows="totalRowsObjects"
                    :per-page="perPageObjects"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-container>
            <!--End::Objects -->

            <!--Begin::Admins -->
            <b-container
              fluid
              v-if="itemsAdmins && (inputAccess == null || inputAccess == 'S')"
            >
              <b-row class="mt-3">
                <b-col sm="12" md="6" lg="6" style="align-self: flex-end">
                  <span class="poschodoch-identity-titles">
                    {{ $i18n.t("IDENTITY.ADMINS_TABLE.TITLE") }}
                  </span>
                </b-col>
                <b-col
                  class="poschodoch-rows-count"
                  v-if="itemsAdmins.length > 10"
                >
                  <b-form-group
                    v-bind:label="$t('COMMON.LIST.ROW_COUNT_PER_PAGE')"
                    label-cols-sm="10"
                    label-cols-md="10"
                    label-cols-lg="10"
                    label-align-sm="right"
                    label-align-lg="right"
                    label-align-md="right"
                    label-size="sm"
                    label-for="perPageSelectAdmins"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPageAdmins"
                      id="perPageSelectAdmins"
                      size="sm"
                      :options="pageOptionsAdmins"
                      @change="
                        getFormatService().formatSetPerPage(perPageAdmins)
                      "
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                hover
                size="md"
                stacked="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="itemsAdmins"
                :fields="fieldsAdmins"
                :current-page="currentPageAdmins"
                :per-page="perPageAdmins"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                class="notranslate"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(Link)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data"
                    v-on:click="takeoverPOST(data.item.PortalId)"
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="fas fa-user poschodoch-icon-medium"></i>
                  </a>
                </template>
              </b-table>
              <b-row
                v-if="itemsAdmins && itemsAdmins.length > 10"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPageAdmins"
                    :total-rows="totalRowsAdmins"
                    :per-page="perPageAdmins"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-container>
            <!--End::Admins -->

            <!--Begin::Description -->
            <!-- <b-container fluid v-if="itemsUsers || itemsObjects || itemsAdmins">
              <b-row class="justify-content-end">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
            </b-container> -->
            <!--End::Description -->
          </template>
        </KTPortlet>
        <!--End::Identity takeover-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import JwtService from "@/common/jwt.service";

export default {
  name: "identityTakeover",
  components: {
    KTPortlet,
  },
  data() {
    return {
      menuId: null,
      title: this.$i18n.t("IDENTITY.TITLE"),
      description: "future description 3333",
      disableAccess: true,
      customerId: null,
      itemsUsers: null,
      fieldsUsers: [
        {
          key: "LoginName",
          label: this.$i18n.t("IDENTITY.ADMINS_TABLE.LOGINNAME"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "VS",
          label: this.$i18n.t("IDENTITY.USERS_TABLE.VS"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Name",
          label: this.$i18n.t("IDENTITY.USERS_TABLE.NAME"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "AccessFrom",
          label: this.$i18n.t("IDENTITY.USERS_TABLE.ACCESSFROM"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "AccessTo",
          label: this.$i18n.t("IDENTITY.USERS_TABLE.ACCESSTO"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            if (value > "2100-01-01") return "☞";
            else return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Address",
          label: this.$i18n.t("IDENTITY.USERS_TABLE.ADDRESS"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Link",
          label: "",
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: () => {
            return "<Link>";
          },
        },
        {
          key: "Type",
          label: this.$i18n.t("IDENTITY.USERS_TABLE.TYPE"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
      ],
      itemsObjects: null,
      fieldsObjects: [
        {
          key: "LoginName",
          label: this.$i18n.t("IDENTITY.ADMINS_TABLE.LOGINNAME"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Name",
          label: this.$i18n.t("IDENTITY.OBJECTS_TABLE.NAME"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "VCS",
          label: this.$i18n.t("IDENTITY.OBJECTS_TABLE.VCS"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "AccessFrom",
          label: this.$i18n.t("IDENTITY.OBJECTS_TABLE.ACCESSFROM"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "AccessTo",
          label: this.$i18n.t("IDENTITY.OBJECTS_TABLE.ACCESSTO"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: (value) => {
            if (value > "2100-01-01") return "☞";
            else return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Address",
          label: this.$i18n.t("IDENTITY.OBJECTS_TABLE.ADDRESS"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Link",
          label: "",
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: () => {
            return "<Link>";
          },
        },
      ],
      itemsAdmins: null,
      fieldsAdmins: [
        {
          key: "Code",
          label: this.$i18n.t("IDENTITY.ADMINS_TABLE.CODE"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "LoginName",
          label: this.$i18n.t("IDENTITY.ADMINS_TABLE.LOGINNAME"),
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "Link",
          label: "",
          sortable: false,
          // class: "table-no-mobile",
          sortDirection: "desc",
          formatter: () => {
            return "<Link>";
          },
        },
      ],
      inputCustomer: null,
      inputAccess: null,
      inputSearch: null,
      customerOptions: [
        {
          value: null,
          text: this.$i18n.t("IDENTITY.FILTER.PLACEHOLDERCUSTOMER"),
          disabled: true,
        },
      ],
      accessOptions: [
        { value: null, text: this.$i18n.t("IDENTITY.FILTER.ALL") },
        { value: "U", text: this.$i18n.t("IDENTITY.FILTER.USERS") },
        { value: "F", text: this.$i18n.t("IDENTITY.FILTER.OFFICIALS") },
        { value: "S", text: this.$i18n.t("IDENTITY.FILTER.ADMINS") },
      ],
      totalRowsUsers: 1,
      currentPageUsers: 1,
      perPageUsers: FormatService.formatGetPerPageMin(),
      pageOptionsUsers: FormatService.formatGetPerPageOption(),
      totalRowsObjects: 1,
      currentPageObjects: 1,
      perPageObjects: FormatService.formatGetPerPageMin(),
      pageOptionsObjects: FormatService.formatGetPerPageOption(),
      totalRowsAdmins: 1,
      currentPageAdmins: 1,
      perPageAdmins: FormatService.formatGetPerPageMin(),
      pageOptionsAdmins: FormatService.formatGetPerPageOption(),
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchCustomers();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.takeoverPOST(record.PortalId);
    },
    pointerRowClass() {
      return "pointer-row";
    },
    takeoverPOST(portalID) {
      ApiService.setHeader();
      ApiService.post("Auth/TakeOverIndentity?portalId=" + portalID).then(
        ({ data }) => {
          JwtService.saveToken(
            data.auth_token,
            data.expires_in,
            data.refresh_token,
          );
        },
      );

      setTimeout(function() {
        window.location = window.location.origin;
      }, 2000);
    },
    fetchCustomers() {
      ApiService.setHeader();
      ApiService.get("Auth", "CustomerList").then(({ data }) => {
        this.setCustomerOptions(data.Customers);
      });
    },
    setCustomerOptions(items) {
      if (items.length != 1) {
        for (let i = 0; i < items.length; i++) {
          this.customerOptions.push({
            value: items[i].CustomerId,
            text: items[i].Code,
          });
        }
      } else {
        this.customerOptions = [];
        this.customerOptions.push({ value: null, text: items[0].Code });
        this.fetchData(items[0].CustomerId);
      }
    },
    fetchData(chosenCustomerId, search) {
      let parameters = "?menuId=" + this.menuId.toString();
      if (chosenCustomerId != null)
        parameters += "&customerId=" + chosenCustomerId.toString();
      if (search != null) parameters += "&search=" + search.toString();

      ApiService.setHeader();
      ApiService.get("Auth", "UserList" + parameters).then(({ data }) => {
        this.itemsUsers = data.Users;
        this.itemsObjects = data.Objects;
        this.itemsAdmins = data.Admins;

        this.totalRowsUsers = this.itemsUsers.length;
        this.totalRowsObjects = this.itemsObjects.length;
        this.totalRowsAdmins = this.itemsAdmins.length;
      });
    },
  },
};
</script>
