<template>
  <div>
    <KTPortlet v-if="showPre">
      <template v-slot:body>
        <h2 id="idMainH">{{ $i18n.t("PACKAGE.PACKAGE") }}</h2>
        <div class="d-flex mb-4 justify-content-center align-items-center">
          <span>{{ $i18n.t("PACKAGE.ENTRY") }}</span>
        </div>
        <div class="d-flex mb-4 justify-content-center align-items-center">
          <span>{{ $i18n.t("PACKAGE.TXT") }}</span>
          <div class="mr-5">
            <b-form-input
              v-model="passwd"
              type="password"
              class="ml-3 mr-3"
            ></b-form-input>
          </div>
        </div>
        <div
          class="d-flex mb-4 justify-content-center align-items-center"
          v-if="showError"
        >
          <span class="color-danger">{{ error }}</span>
        </div>
        <div class="row justify-content-center">
          <b-button
            @click="fetch()"
            variant="primary"
            style="width: 18rem; height: fit-content"
          >
            {{ $i18n.t("PACKAGE.BUTTON") }}
          </b-button>
        </div>
      </template>
    </KTPortlet>
    <a
      id="document-download-link-pub"
      v-if="showMain"
      style="display: none"
    ></a>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Documents-->
        <KTPortlet v-bind:title="title" v-if="showMain">
          <!--Begin::Data -->
          <template v-slot:body>
            <h2>{{ $i18n.t("PACKAGE.PACKAGE") + ": " + stCategory }}</h2>
            <div
              class="d-flex mb-4 justify-content-center align-items-center"
              v-if="showErrorMain"
            >
              <span class="color-danger">{{ errorMain }}</span>
            </div>
            <b-container fluid v-if="items">
              <!--Begin::Filter and paging -->
              <!--  <b-row class="mb-4 align-items-end">
                <b-col>
                  <label>{{ $t("DOCUMENTS.FILTER.DOC_NAME") }}</label>
                  <b-form-input v-model="filterDocName"></b-form-input>
                </b-col>
                <b-col>
                  <label>{{ $t("DOCUMENTS.FILTER.CATEGORY") }}</label>
                  <b-form-select v-model="filterCategory" :options="categoryOptions"></b-form-select>
                </b-col>
                <b-col>
                  <label>{{ $t("COMMON.LIST.ROW_COUNT_PER_PAGE") }}</label>
                  <b-form-select v-model="perPage" id="perPageSelect" :options="pageOptions"></b-form-select>
                </b-col>
              </b-row> -->
              <!--End::Filter and paging -->

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-function="filterTable"
                class="poschodoch-documents-table"
              >
                <template v-slot:cell(DocName)="data">
                  <i
                    class="flaticon2-file-1 poschodoch-icon-large poschodoch-document-icon"
                  ></i>
                  <span class="ml-md-3 ml-sm-0">{{ data.item.DocName }}</span>
                </template>
                <template v-slot:cell(DocId)="data">
                  <b-button
                    size="sm"
                    variant="primary"
                    :id="'unit_button_' + data.item.DocId"
                    v-on:click="
                      downloadFile(data.item.DocId, data.item.FileName)
                    "
                    v-if="!data.item.Selected"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </b-button>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ data.item.DocName }}
                          </span>
                        </span>
                        <!--<span>{{getFormatService().formatDateToLocal(data.value.CreationDate) }}</span>-->
                        <!--<span><span class="poschodoch-table-title-mobile">{{ $i18n.t("DOCUMENTS.TABLE.CATEGORY") + ": "}}</span>{{ data.value.Category }}</span> -->
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <b-button
                          size="sm"
                          variant="primary"
                          :id="'unit_button_' + data.item.UnitId"
                          v-on:click="
                            downloadFile(data.item.DocId, data.item.FileName)
                          "
                        >
                          <i
                            class="flaticon-download poschodoch-icon-medium"
                          ></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Pagination -->
              <b-row
                v-if="items && items.length > 0"
                class="justify-content-end"
              >
                <b-col sm="12" md="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="md"
                    class="poschodoch-pagination justify-content-end"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!--End::Pagination -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="showLoader">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Documents-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FileService from "@/common/file.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import UIService from "@/common/ui.service";
//import JwtService from "@/common/jwt.service";

export default {
  name: "documents-pub",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      showError: false,
      showErrorMain: false,
      showPre: false,
      showMain: false,
      showLoader: false,
      error: null,
      errorMain: null,
      stCategory: "",
      zasId: null,
      passwd: null,
      title: null,
      description: null,
      menuId: null,
      items: null,
      lastPeriod: "",
      periodClass: "row",
      fields: [
        {
          key: "DocName",
          label: this.$i18n.t("DOCUMENTS.TABLE.DOC_NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "CreationDate",
          label: this.$i18n.t("DOCUMENTS.TABLE.CREATION_DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        /*{
          key: "Category",
          label: this.$i18n.t("DOCUMENTS.TABLE.CATEGORY"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },*/
        {
          key: "DocId",
          label: this.$i18n.t("DOCUMENTS.TABLE.DOWNLOAD"),
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: FormatService.formatGetPerPage(),
      pageOptions: FormatService.formatGetPerPageOption(),
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      categoryOptions: [
        { value: null, text: this.$i18n.t("DOCUMENTS.FILTER.PLACEHOLDER") },
      ],
      filterDocName: null,
      filterCategory: null,
    };
  },
  computed: {
    filter() {
      return { DocName: this.filterDocName, Category: this.filterCategory };
    },
  },
  mounted() {
    this.zasId = this.$route.query.id;
    //this.menuId = this.$route.query.menuId;
    //let typeU = this.$route.query.typeU;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    filterTable(row, filter) {
      let result = true;

      if (filter.DocName != null) {
        if (
          !FormatService.normalizeString(row.DocName).includes(
            FormatService.normalizeString(filter.DocName),
          )
        ) {
          result = false;
        }
      }

      if (filter.Category != null) {
        if (
          !FormatService.normalizeString(row.Category).includes(
            FormatService.normalizeString(filter.Category),
          )
        ) {
          result = false;
        }
      }

      return result;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    downloadFile(docId, fileName) {
      let parameters =
        "?docId=" + docId.toString() + "&packageCode=" + this.zasId;

      if (this.passwd != null) {
        parameters = parameters + "&pwd=" + this.passwd;
      }

      UIService.addButtonSpinner("unit_button_" + docId);

      ApiService.setHeader();
      ApiService.get("Public", "PubDocGet" + parameters).then(({ data }) => {
        if (data.error) {
          this.showErrorMain = true;
          this.errorMain = data.error;
        } else {
          this.showErrorMain = false;
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          FileService.downloadFile(
            fileName,
            binaryContent,
            null,
            "document-download-link-pub",
          );

          UIService.removeButtonSpinner("unit_button_" + docId);
        }
      });
    },
    setCategoryOptions(items) {
      let options = {};

      for (let i = 0; i < items.length; i++) {
        let category = items[i].Category;
        if (options[category] == null) {
          options[category] = category;
          this.categoryOptions.push({ value: category, text: category });
        }
      }
    },
    fetchData() {
      /*
      https://localhost:8080/zas?id=789df957ccdb3941ab40406f63c8c67a7018e86a12edaad38fe00b0eaf6ab47cd8f471793309bed329072
      https://localhost:8080/zas?id=789df957ccdb3941ab40406f63c8c67a7018e86a12edaad38fe00b0eaf6ab47cd8f471793309bed348998
      789df957ccdb3941ab40406f63c8c67a7018e86a12edaad38fe00b0eaf6ab47cd8f471793309bed348998 – bez hesla
      789df957ccdb3941ab40406f63c8c67a7018e86a12edaad38fe00b0eaf6ab47cd8f471793309bed329072 – heslo 123456
      */
      this.showMain = true;
      this.showLoader = true;

      /*let postContent = {
        packageCode: this.zasId,
        browser: navigator.userAgent,
      };*/
      let parameters =
        "?packageCode=" + this.zasId + "&browser=" + navigator.userAgent;

      ApiService.setHeader();
      ApiService.get("Public", "DocPackage" + parameters)
        .then(({ data }) => {
          this.showLoader = false;
          if (data.error) {
            this.showError = true;
            this.error = data.error;
          }
          if (data.PwdRequired == 1) {
            this.showPre = true;
            this.showMain = false;
          } else {
            this.showPre = false;
            this.showMain = true;

            this.transformData(data.Documents);

            this.title = data.Title;
            this.description = data.Description;
            this.items = data.Documents;
            this.totalRows = this.items.length;
            this.currentPage = 1;

            this.setCategoryOptions(data.Documents);
            this.stCategory = data.Category;
          }
        })
        .catch(({ response }) => {
          this.showErrorMain = true;
          this.errorMain = "Niečo sa pokazilo. Pracujeme na tom...";
          this.showLoader = false;
          console.log(response.data.error);
        });
    },
    fetch() {
      this.items = null;
      this.showLoader = true;

      /*let postContent = {
        packageCode: this.zasId,
        pwd: this.passwd,
        browser: navigator.userAgent,
      };*/
      let parameters =
        "?packageCode=" +
        this.zasId +
        "&pwd=" +
        this.passwd +
        "&browser=" +
        navigator.userAgent;

      ApiService.setHeader();
      ApiService.get("Public", "DocPackage" + parameters)
        .then(({ data }) => {
          if (data.error) {
            this.showError = true;
            this.error = data.error;
          } else {
            this.transformData(data.Documents);

            this.title = data.Title;
            this.description = data.Description;
            this.items = data.Documents;
            this.totalRows = this.items.length;
            this.currentPage = 1;

            this.setCategoryOptions(data.Documents);
            this.stCategory = data.Category;
            this.showLoader = false;
            this.showPre = false;
            this.showMain = true;
            this.showError = false;
          }
        })
        .catch(({ response }) => {
          this.showPre = true;
          this.showMain = false;
          this.showLoader = false;
          this.showError = true;
          console.log(response.data.error);
        });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          DocName: items[i].DocName,
          CreationDate: items[i].CreationDate,
          Category: items[i].Category,
          DocId: items[i].DocId,
          FileName: items[i].FileName,
        };
      }
    },
  },
};
</script>
