<template>
  <div>
    <a id="document-download-link" href style="display: none"></a>
    <DecisionsDocs v-bind:decisionsDocs="decisionsDocs" />
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Prehlad upomienok-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
              >
                <template v-slot:cell(DocCount)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.value > 1"
                    v-on:click="showDecisionsDocs(data.item.DecisionId)"
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    v-if="data.value == 1"
                    v-on:click="
                      showSingleDoc(data.item.RecKey, data.item.TableName)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ data.value.Description }}
                          </span>
                        </span>
                        <span>
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.Date,
                            )
                          }}
                        </span>
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ data.value.Validity }}
                          </span>
                        </span>
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ data.value.AcceptanceWay }}
                          </span>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount > 1"
                            v-on:click="
                              showDecisionsDocs(menuId, data.item.InspectionId)
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.RecKey,
                                data.item.TableName,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Prehlad upomienok-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";
import DecisionsDocs from "@/views/pages/domus/DecisionsDocs.vue";

export default {
  name: "decisions",
  components: {
    KTPortlet,
    //TableContextMenu,
    Loader,
    DecisionsDocs,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      decisionsDocs: null,
      fields: [
        {
          key: "Description",
          label: this.$i18n.t("DECISIONS.TABLE.DESCRIPTION"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Date",
          label: this.$i18n.t("DECISIONS.TABLE.DATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "Validity",
          label: this.$i18n.t("DECISIONS.TABLE.VALIDITY"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "AcceptanceWay",
          label: this.$i18n.t("DECISIONS.TABLE.ACCEPTANCEWAY"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center p-0 table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    showDecisionsDocs(recKey) {
      let parameters =
        "?tableName=rozhodnutia&recKey=" + recKey.toString() + "&menuId=183";

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.decisionsDocs = data;
        this.$bvModal.show("modal-decisions-docs");
      });
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.decisionsDocs = data;

        parameters =
          "?docId=" + this.decisionsDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.decisionsDocs.Documents[0].FileName;

          FileService.downloadFile(
            fileName,
            binaryContent,
            null,
            "document-download-link",
          );
        });
      });
    },

    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "Decisions" + parameters).then(({ data }) => {
        this.transformData(data.Decisions);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Decisions;
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          DecisionId: items[i].DecisionId,
          Description: items[i].Description,
          Date: items[i].Date,
          Validity: items[i].Validity,
          AcceptanceWay: items[i].AcceptanceWay,
          TableName: items[i].TableName,
          DocCount: items[i].DocCount,
          RecKey: items[i].RecKey,
        };
      }
    },
  },
};
</script>
