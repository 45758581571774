<template>
  <div>
    <a id="document-download-link" style="display: none"></a>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Bids det-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Filter and paging -->
              <b-row class="mb-4 align-items-end">
                <b-col>
                  <label>{{ $t("BIDS.FILTER.DOC_NAME") }}</label>
                  <b-form-input v-model="filterDocName"></b-form-input>
                </b-col>
              </b-row>
              <!--End::Filter and paging -->

              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :empty-filtered-text="$t('COMMON.LIST.NO_RECORDS_FILTERED')"
                :items="items"
                :fields="fields"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                :filter-function="filterTable"
              >
                <template v-slot:cell(Docname)="data">
                  <i
                    class="flaticon2-file-1 poschodoch-icon-large poschodoch-document-icon"
                  ></i>
                  <span class="ml-md-3 ml-sm-0">{{ data.item.Docname }}</span>
                </template>
                <template v-slot:cell(DocID)="data">
                  <a
                    href="javascript:void(0)"
                    v-on:click="
                      downloadFile(data.item.DocID, data.item.Filename)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <!-- <span class="poschodoch-table-title-mobile">{{ $i18n.t("BIDS.TABLE.DOCNAME") + ": "}}</span> -->
                          {{ data.value.Docname }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <a
                          href="javascript:void(0)"
                          v-on:click="
                            downloadFile(data.item.DocID, data.item.Docname)
                          "
                          class="poschodoch-icon-link p-2"
                        >
                          <i
                            class="flaticon-download poschodoch-icon-medium"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Bids det-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import FileService from "@/common/file.service";

export default {
  name: "bids-detail",
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      fields: [
        {
          key: "Docname",
          label: this.$i18n.t("BIDS.TABLE.DOCNAME"),
          sortable: true,
          class: "table-no-mobile",
          sortDirection: "desc",
        },
        {
          key: "DocID",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      //totalRows: 1,
      //currentPage: 1,
      //perPage: 10,
      //pageOptions: [10, 20, 30],
      sortBy: "",
      sortDirection: "desc",
      filterOn: [],
      filterDocName: null,
    };
  },
  computed: {
    filter() {
      return { Docname: this.filterDocName };
    },
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.bidId = this.$route.query.bidId;
    this.fetchData();
  },
  methods: {
    filterTable(row, filter) {
      let result = true;

      if (filter.Docname != null) {
        if (
          !FormatService.normalizeString(row.Docname).includes(
            FormatService.normalizeString(filter.Docname),
          )
        ) {
          result = false;
        }
      }

      return result;
    },
    getFormatService() {
      return FormatService;
    },
    downloadFile(docId, fileName) {
      let parameters = "?docId=" + docId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "Get" + parameters).then(({ data }) => {
        let binaryContent = FileService.convertBase64ToByteArray(
          data.FileContent,
        );

        FileService.downloadFile(
          fileName,
          binaryContent,
          null,
          "document-download-link",
        );
      });
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.bidId != null) parameters += "&bidId=" + this.bidId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "BidsDetail" + parameters).then(({ data }) => {
        this.transformData(data.Bids);

        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Bids;

        //this.totalRows = this.items.length;
        //this.currentPage = 1;
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Docname: items[i].Docname,
          DocID: items[i].DocID,
        };
      }
    },
  },
};
</script>
