<template>
  <div>
    <InspectionDetail v-bind:inspectionDetail="inspectionDetail" />
    <InspectionDocs v-bind:inspectionDocs="inspectionDocs" />

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Inspection-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(DocCount)="data">
                  <div
                    @click.stop
                    class="h-100 w-100 p-2 d-flex align-items-center justify-content-center"
                    style="cursor: default"
                  >
                    <a
                      href="javascript:void(0)"
                      v-if="data.value > 1"
                      v-on:click="showInspectionDocs(data.item.InspectionId)"
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      v-if="data.value == 1"
                      v-on:click="
                        showSingleDoc(data.item.Reckey, data.item.Tablename)
                      "
                      class="poschodoch-icon-link p-2"
                    >
                      <i class="flaticon-download poschodoch-icon-medium"></i>
                    </a>
                  </div>
                </template>
                <template v-slot:cell(Name)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="
                      showInspectionDetail(menuId, data.item.InspectionId)
                    "
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                  <span v-if="!(data.item != null)">{{ data.value }}</span>
                </template>
                <template v-slot:cell(DefectsAll)="data">
                  <span>
                    {{ data.item.Defects + " / " }}{{ data.item.Todo }}
                  </span>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div
                    @click.stop
                    class="ml-3 d-flex flex-column align-items-start w-100"
                  >
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div class="d-flex flex-column align-items-start">
                        <a
                          href="javascript:void(0)"
                          v-if="data.item != null"
                          v-on:click="
                            showInspectionDetail(menuId, data.item.InspectionId)
                          "
                          class="kt-link poschodoch-meter-readings-title-mobile"
                        >
                          {{ data.value.Name }}
                        </a>
                        {{ data.value.Object }}
                        <span>
                          {{
                            $i18n.t("INSPECTION.TABLE.DEFECTS") +
                              ": " +
                              data.value.Defects
                          }}
                        </span>
                      </div>
                      <div class="d-flex flex-column align-items-end mr-3">
                        <span>
                          <b>{{ data.value.State }}</b>
                          {{
                            " " +
                              getFormatService().formatDateToLocal(
                                data.value.ValidTo,
                              )
                          }}
                        </span>
                        <span>
                          {{
                            $i18n.t("INSPECTION.TABLE.LASTINSPECTION") +
                              ": " +
                              getFormatService().formatDateToLocal(
                                data.value.LastInspection,
                              )
                          }}
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount > 1"
                            v-on:click="
                              showInspectionDocs(data.item.InspectionId)
                            "
                            class="kt-link poschodoch-meter-readings-title-mobile"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.Reckey,
                                data.item.Tablename,
                              )
                            "
                            class="kt-link poschodoch-meter-readings-title-mobile"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </span>
                        <span>
                          {{
                            $i18n.t("INSPECTION.TABLE.TODO") +
                              ": " +
                              data.value.Todo
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Inspection-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import InspectionDetail from "@/views/pages/domus/InspectionDetail.vue";
import InspectionDocs from "@/views/pages/domus/InspectionDocs.vue";
import FileService from "@/common/file.service";

export default {
  name: "inspection",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    InspectionDetail,
    InspectionDocs,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      inspectionDetail: null,
      inspectionDocs: null,
      items: null,
      fields: [
        /* {
          key: "InspectionId",
          label: this.$i18n.t("INSPECTION.TABLE.INSPECTIONID"),
          sortable: true,
          sortDirection: "desc"      
        }, */
        {
          key: "Object",
          label: this.$i18n.t("INSPECTION.TABLE.OBJECT"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Name",
          label: this.$i18n.t("INSPECTION.TABLE.NAME"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "ValidTo",
          label: this.$i18n.t("INSPECTION.TABLE.VALIDTO"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "LastInspection",
          label: this.$i18n.t("INSPECTION.TABLE.LASTINSPECTION"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "DefectsAll",
          label: this.$i18n.t("INSPECTION.TABLE.DEFECTSALL"),
          sortable: true,
          sortDirection: "desc",
          class: "text-center table-no-mobile",
        },
        {
          key: "State",
          label: this.$i18n.t("INSPECTION.TABLE.STATE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right table-no-mobile",
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center p-0 table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showInspectionDetail(this.menuId, record.InspectionId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    showInspectionDocs(recKey) {
      let parameters =
        "?tableName=revizie&recKey=" + recKey.toString() + "&menuId=183";

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.inspectionDocs = data;
        this.$bvModal.show("modal-inspection-docs");
      });
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.inspectionDocs = data;

        parameters =
          "?docId=" + this.inspectionDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.inspectionDocs.Documents[0].FileName;

          FileService.downloadFile(fileName, binaryContent);
        });
      });
    },
    showInspectionDetail(menuId, inspectionId) {
      let parameters = "?menuId=" + menuId.toString();
      if (inspectionId != null)
        parameters += "&inspectionId=" + inspectionId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "InspectionDetail" + parameters).then(
        ({ data }) => {
          this.inspectionDetail = data;
          this.$bvModal.show("modal-inspection-detail");
        },
      );
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      window.open("/inspectionPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("INSPECTION.TABLE.OBJECT"),
        this.$i18n.t("INSPECTION.TABLE.NAME"),
        this.$i18n.t("INSPECTION.TABLE.VALIDTO"),
        this.$i18n.t("INSPECTION.TABLE.LASTINSPECTION"),
        this.$i18n.t("INSPECTION.TABLE.DEFECTS"),
        this.$i18n.t("INSPECTION.TABLE.TODO"),
        this.$i18n.t("INSPECTION.TABLE.STATE"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.Object,
          item.Name,
          FormatService.formatDateToLocal(item.ValidTo),
          FormatService.formatDateToLocal(item.LastInspection),
          item.Defects,
          item.Todo,
          item.State,
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "Inspection" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.Inspection;

        this.transformData(this.items);
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          Object: items[i].Object,
          Name: items[i].Name,
          ValidTo: items[i].ValidTo,
          LastInspection: items[i].LastInspection,
          Defects: items[i].Defects,
          Todo: items[i].Todo,
          State: items[i].State,
          DocCount: items[i].DocCount,
        };
      }
    },
  },
};
</script>
