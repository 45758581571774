<template>
  <div>
    <KTPortlet v-bind:title="title" v-if="items">
      <template v-slot:body>
        <div
          lg="12"
          class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
        >
          <span>{{ $i18n.t("DAILYCONSUMPTIONOBJECT.DATE") }}</span>
          <div class="mr-5">
            <b-form-input
              v-model="dateV"
              type="date"
              class="ml-3 mr-3"
            ></b-form-input>
          </div>
          <div class="row">
            <div class="mr-5">
              <b-form-checkbox id="sv" v-model="checkedSV">
                {{ $i18n.t("DAILYCONSUMPTIONOBJECT.SV") }}
              </b-form-checkbox>
            </div>
            <div class=" mr-5">
              <b-form-checkbox id="tv" v-model="checkedTV">
                {{ $i18n.t("DAILYCONSUMPTIONOBJECT.TV") }}
              </b-form-checkbox>
            </div>
            <div class=" mr-5">
              <b-form-checkbox id="uk" v-model="checkedUK">
                {{ $i18n.t("DAILYCONSUMPTIONOBJECT.UK") }}
              </b-form-checkbox>
            </div>
          </div>
          <b-button
            variant="primary"
            class="d-flex ml-md-5 mt-3 mt-md-0"
            v-on:click="fetchData()"
          >
            {{ $i18n.t("MONTHLY_CONSUMPTION.BUTTONOK") }}
          </b-button>
        </div>
      </template>
    </KTPortlet>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Comsumption Elements-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->

              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
              >
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          <span class="poschodoch-table-title-mobile">
                            {{ data.value.Identification }}
                          </span>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span>{{ data.value.MeterNumber }}</span>
                      </div>
                    </div>

                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span>
                          {{
                            $i18n.t("DAILYCONSUMPTIONOBJECT.STATE") +
                              ": " +
                              data.value.State
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                      >
                        <span>{{ data.value.Code }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Consumption Elements-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";

export default {
  name: "dailyConsumptionObject",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      items: null,
      dateV: null,
      showAft: false,
      showPre: true,
      currentDate: null,
      checkedSV: true,
      checkedTV: true,
      checkedUK: true,
      fields: [
        {
          key: "Identification",
          label: this.$i18n.t("DAILYCONSUMPTIONOBJECT.FLATNUMBER"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Varsymb",
          label: this.$i18n.t("DAILYCONSUMPTIONOBJECT.VARSYMB"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right, table-no-mobile",
        },
        {
          key: "Code",
          label: this.$i18n.t("DAILYCONSUMPTIONOBJECT.CODE"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right, table-no-mobile",
        },
        {
          key: "MeterNumber",
          label: this.$i18n.t("DAILYCONSUMPTIONOBJECT.METERNUMBER"),
          sortable: true,
          sortDirection: "desc",
          class: "text-right, table-no-mobile",
        },
        {
          key: "Location",
          label: this.$i18n.t("DAILYCONSUMPTIONOBJECT.LOCATION"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "State",
          label: this.$i18n.t("DAILYCONSUMPTIONOBJECT.STATE"),
          sortable: true,
          sortDirection: "desc",
          class: "table-no-mobile text-right",
          formatter: (value) => {
            return value === "---"
              ? "---"
              : FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    //this.showAft = false;
    //this.showPre = true;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    addCurrency(data) {
      return data + FormatService.formatCurrency(JwtService.getCurrency());
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    print() {
      parameters = "?menuId=" + this.menuId.toString();
      /*if (this.dateV === null) 
      { 
        this.dateV = this.currentDate;
      } ;*/
      let sType = "";
      if (this.checkedSV) {
        sType = "S";
      }
      if (this.checkedTV) {
        sType = sType + "T";
      }
      if (this.checkedUK) {
        sType = sType + "U";
      }
      let parameters =
        "?menuId=" +
        this.menuId.toString() +
        "&type=" +
        sType +
        "&date=" +
        FormatService.formatDateToLocal(this.dateV.toString());

      window.open("/dailyConsumptionObjectPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("DAILYCONSUMPTIONOBJECT.FLATNUMBER"),
        this.$i18n.t("DAILYCONSUMPTIONOBJECT.VARSYMB"),
        this.$i18n.t("DAILYCONSUMPTIONOBJECT.CODE"),
        this.$i18n.t("DAILYCONSUMPTIONOBJECT.METERNUMBER"),
        this.$i18n.t("DAILYCONSUMPTIONOBJECT.LOCATION"),
        this.$i18n.t("DAILYCONSUMPTIONOBJECT.STATE"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.FlatNumber,
          item.Varsymb,
          item.Code,
          item.MeterNumber,
          item.Location,
          FormatService.formatNumberToLocalForCSV(item.State),
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetch() {
      this.items = null;
      //this.showAft = false;
      //this.showPre = true;
    },
    fetchData() {
      this.items = null;
      //this.showAft = true;
      //this.showPre = false;
      //this.currentDate = new Date();
      //this.currentDate.setDate(this.currentDate.getDate() - 1);

      /*if (this.dateV === null) 
      { 
        this.dateV = this.currentDate;        
      } ;*/
      let sType = "";
      if (this.checkedSV) {
        sType = "S";
      }
      if (this.checkedTV) {
        sType = sType + "T";
      }
      if (this.checkedUK) {
        sType = sType + "U";
      }
      let parameters = "?menuId=" + this.menuId.toString() + "&type=" + sType;
      if (this.dateV != null) {
        parameters =
          parameters +
          "&date=" +
          FormatService.formatDateToLocal(this.dateV.toString());
      }

      ApiService.setHeader();
      ApiService.get("Flat", "DailyConsumptionObject" + parameters).then(
        ({ data }) => {
          this.title = data.Title;
          this.description = data.Description;

          if (data.Users != null) {
            this.items = data.Users;
            this.transformData(this.items);
          } else {
            this.items = "[{}]";
          }

          this.dateV = data.Date;
        },
      );
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          FlatNumber: items[i].FlatNumber,
          Varsymb: items[i].Varsymb,
          Code: items[i].Code,
          MeterNumber: items[i].MeterNumber,
          Location: items[i].Location,
          State: items[i].State,
          Identification: items[i].Identification,
        };
      }
    },
  },
};
</script>
