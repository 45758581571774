<template>
  <div class="poschodoch-custReport">
    <a id="pdf-download-link" style="display: none"></a>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <!-- errors -->
            <div
              role="alert"
              v-if="errors && errors.length"
              v-bind:class="{ show: errors && errors.length }"
              class="alert fade alert-danger mt-2 mb-5"
              style="flex-direction: column"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>
            <!-- errors -->

            <!-- main -->
            <div>
              <!-- v-for -->
              <div v-if="custReportParamsData">
                <div
                  v-for="(param, i) in custReportParamsData.Params"
                  :key="i"
                  class="d-flex justify-content-center align-items-center"
                >
                  <div class="col-12 col-md-6" v-if="param.Visibility">
                    <div
                      class="
                        d-flex
                        flex-row
                        mb-4
                        justify-content-start
                        align-items-center
                      "
                    >
                      <span class="mr-2" style="width: 20%">
                        {{ param.Label }}
                      </span>

                      <b-form-input
                        v-if="param.Type === 'date' && !param.Required"
                        type="date"
                        style="width: 70%"
                        v-model="param.Value"
                      />
                      <div
                        style="width: 70%"
                        v-if="param.Type === 'date' && param.Required"
                      >
                        <b-form-input
                          :state="validateEmptyD(i)"
                          type="date"
                          v-model="param.Value"
                        />
                        <b-form-invalid-feedback :state="validateEmptyD(i)">
                          {{ $i18n.t("CUSTREPORT.EMPTYVALIDATION") }}
                        </b-form-invalid-feedback>
                      </div>

                      <b-form-input
                        v-if="param.Type === 'int' && !param.Required"
                        type="number"
                        @keydown="filterKey"
                        style="width: 70%"
                        v-model="param.Value"
                      />
                      <div
                        style="width: 70%"
                        v-if="param.Type === 'int' && param.Required"
                      >
                        <b-form-input
                          :state="validateEmptyI(i)"
                          type="number"
                          @keydown="filterKey"
                          v-model="param.Value"
                        />
                        <b-form-invalid-feedback :state="validateEmptyI(i)">
                          {{ $i18n.t("CUSTREPORT.EMPTYVALIDATION") }}
                        </b-form-invalid-feedback>
                      </div>

                      <b-form-checkbox
                        v-if="param.Type === 'checkbox' && !param.Required"
                        style="width: 70%"
                        class="mt-3 mb-3"
                        v-model="param.Value"
                      />
                      <div
                        style="width: 70%"
                        v-if="param.Type === 'checkbox' && param.Required"
                      >
                        <b-form-checkbox
                          :state="validateEmptyC(i)"
                          class="mt-3 mb-3"
                          v-model="param.Value"
                        />
                        <b-form-invalid-feedback :state="validateEmptyC(i)">
                          {{ $i18n.t("CUSTREPORT.EMPTYVALIDATION") }}
                        </b-form-invalid-feedback>
                      </div>

                      <b-form-input
                        v-if="param.Type === 'string' && !param.Required"
                        type="text"
                        style="width: 70%"
                        v-model="param.Value"
                      />
                      <div
                        style="width: 70%"
                        v-if="param.Type === 'string' && param.Required"
                      >
                        <b-form-input
                          :state="validateEmptyS(i)"
                          type="text"
                          v-model="param.Value"
                        />
                        <b-form-invalid-feedback :state="validateEmptyS(i)">
                          {{ $i18n.t("CUSTREPORT.EMPTYVALIDATION") }}
                        </b-form-invalid-feedback>
                      </div>

                      <b-form-select
                        v-if="param.Type === 'list' && !param.Required"
                        style="width: 70%"
                        v-model="param.Value"
                        :options="param.ListBox"
                      >
                        <template #first>
                          <b-form-select-option :value="null">
                            {{ $i18n.t("CUSTREPORT.FIRSTLISTOPTION") }}
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        style="width: 70%"
                        v-if="param.Type === 'list' && param.Required"
                      >
                        <b-form-select
                          :state="validateEmptyL(i)"
                          v-model="param.Value"
                          :options="param.ListBox"
                        >
                          <template #first>
                            <b-form-select-option :value="null">
                              {{ $i18n.t("CUSTREPORT.FIRSTLISTOPTION") }}
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-invalid-feedback :state="validateEmptyL(i)">
                          {{ $i18n.t("CUSTREPORT.EMPTYVALIDATION") }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- v-for -->

              <!-- button -->
              <div
                v-if="custReportParamsData"
                class="row justify-content-center mt-4"
              >
                <b-button
                  @click="sendPost()"
                  id="sendPost"
                  variant="primary"
                  style="width: 18rem; height: fit-content"
                  :disabled="!validateButton()"
                >
                  {{ $i18n.t("CUSTREPORT.BUTTON") }}
                </b-button>
              </div>
              <!-- button -->
            </div>
            <!-- main -->

            <!-- Description -->
            <b-row class="justify-content-end" v-if="description">
              <b-col lg="12">
                <hr class="poschodoch-separator-line mt-4 mb-4" />
                <ul class="poschodoch-note-list">
                  <li class="poschodoch-note">{{ description }}</li>
                </ul>
              </b-col>
            </b-row>
            <!-- Description -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import FileService from "@/common/file.service";
import UIService from "@/common/ui.service";

export default {
  name: "custReport",
  components: {
    KTPortlet,
  },
  data() {
    return {
      menuId: null,
      title: null,
      description: null,
      custReportParamsData: null,
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    filterKey(e) {
      const key = e.key;

      // If is '.' key, stop it
      if (key === ".") return e.preventDefault();
    },
    validateEmptyD(i) {
      if (
        this.custReportParamsData.Params[i].Value != null &&
        this.custReportParamsData.Params[i].Value != ""
      )
        return true;
      else return false;
    },
    validateEmptyI(i) {
      if (
        this.custReportParamsData.Params[i].Value != null &&
        this.custReportParamsData.Params[i].Value != ""
      )
        return true;
      else return false;
    },
    validateEmptyC(i) {
      if (this.custReportParamsData.Params[i].Value != null) return true;
      else return false;
    },
    validateEmptyS(i) {
      if (
        this.custReportParamsData.Params[i].Value != null &&
        this.custReportParamsData.Params[i].Value != ""
      )
        return true;
      else return false;
    },
    validateEmptyL(i) {
      if (this.custReportParamsData.Params[i].Value != null) return true;
      else return false;
    },
    validateButton() {
      for (let i = 0; i < this.custReportParamsData.Params.length; i++) {
        if (
          this.custReportParamsData.Params[i].Required &&
          this.custReportParamsData.Params[i].Visibility
        ) {
          switch (this.custReportParamsData.Params[i].Type) {
            case "date":
              return this.validateEmptyD(i);
            case "int":
              return this.validateEmptyI(i);
            case "checkbox":
              return this.validateEmptyC(i);
            case "string":
              return this.validateEmptyS(i);
            case "list":
              return this.validateEmptyL(i);
          }
        }
      }

      // Ak nenajde ziadny param co ma aj Required aj Visiblity
      return true;
    },
    fetchData() {
      let params = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "CustReportParams" + params).then(({ data }) => {
        if (data.Title) this.title = data.Title;
        if (data.Description) this.description = data.Description;
        this.custReportParamsData = data;
      });
    },
    sendPost() {
      let postContent = this.custReportParamsData;

      UIService.addButtonSpinner("sendPost");

      ApiService.setHeader();
      ApiService.post("/Flat/CustReport", postContent).then((data) => {
        let binaryContent = FileService.convertBase64ToByteArray(data.data);

        FileService.openFile(
          this.custReportParamsData.Title,
          binaryContent,
          "application/pdf",
        );
        /*FileService.downloadFile(
            this.custReportParamsData.Title + '.pdf',
            binaryContent,
            null,
            "pdf-download-link"
          );*/

        UIService.removeButtonSpinner("sendPost");
      });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.api.apiErrors,
    }),
  },
};
</script>
