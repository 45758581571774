<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Filter -->
        <KTPortlet
          v-bind:title="title"
          v-show="items || itemsHotWater || itemsHeat"
        >
          <template v-slot:body>
            <b-container fluid v-if="items || itemsHotWater || itemsHeat">
              <b-row>
                <b-col
                  lg="12"
                  class="d-flex flex-column flex-md-row justify-content-sm-center justify-content-md-between align-items-center"
                >
                  <div
                    class="d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center"
                  >
                    <div
                      class="poschodoch-month-dropdown d-flex align-items-center mt-3 mt-md-0"
                    >
                      <label
                        for="daily-consumption-dropdown-month"
                        class="m-0 mr-3"
                      >
                        {{ $i18n.t("DAILY_CONSUMPTION.FILTERMONTH") }}
                      </label>
                      <button
                        id="previous-month"
                        type="button"
                        class="poschodoch-previous-btn page-link mr-3 mr-md-2"
                        v-on:click="previousMonth(month)"
                      >
                        ‹
                      </button>
                      <b-dropdown
                        id="daily-consumption-dropdown-month"
                        :text="getFormatService().getMonthName(month)"
                        variant="light"
                      >
                        <b-dropdown-item
                          v-for="month in months"
                          :key="month"
                          v-on:click="fetchData(month)"
                        >
                          {{ getFormatService().getMonthName(month) }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <button
                        id="next-month"
                        type="button"
                        class="poschodoch-next-btn page-link ml-3 ml-md-2"
                        v-on:click="nextMonth(month)"
                      >
                        ›
                      </button>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>
          </template>
        </KTPortlet>
        <!--End::Filter -->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption MY Cold water-->
        <KTPortlet v-bind:title="subTitle" v-show="items || items === null">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadColdWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsColdWater"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '800px', height: '300px' }">
                  <Chart
                    ref="cold-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsColdWater"
                    height="300"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelColdWaterYear0 != ''"
                >
                  <b-form-checkbox
                    id="checkColdWater0"
                    v-on:change="showHideDatasetColdWater(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorColdWaterYear0 }"
                  ></span>
                  <span class="legText">{{ labelColdWaterYear0 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelColdWaterYear1 != ''"
                >
                  <b-form-checkbox
                    id="checkColdWater1"
                    v-on:change="showHideDatasetColdWater(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorColdWaterYear1 }"
                  ></span>
                  <span class="legText">{{ labelColdWaterYear1 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelColdWaterYear2 != ''"
                >
                  <b-form-checkbox
                    id="checkColdWater2"
                    v-on:change="showHideDatasetColdWater(2)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorColdWaterYear2 }"
                  ></span>
                  <span class="legText">{{ labelColdWaterYear2 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelColdWaterYear3 != ''"
                >
                  <b-form-checkbox
                    id="checkColdWater3"
                    v-on:change="showHideDatasetColdWater(3)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorColdWaterYear3 }"
                  ></span>
                  <span class="legText">{{ labelColdWaterYear3 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelColdWaterYear4 != ''"
                >
                  <b-form-checkbox
                    id="checkColdWater4"
                    v-on:change="showHideDatasetColdWater(4)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorColdWaterYear4 }"
                  ></span>
                  <span class="legText">{{ labelColdWaterYear4 }}</span>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsColdWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption MY Cold water-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption MY Hot water-->
        <KTPortlet
          v-bind:title="subTitleHotWater"
          v-show="itemsHotWater || itemsHotWater === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadHotWaterCSV"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="itemsHotWater">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHotWater"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '800px', height: '300px' }">
                  <Chart
                    ref="hot-water-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHotWater"
                    height="300"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHotWaterYear0 != ''"
                >
                  <b-form-checkbox
                    id="checkHotWater0"
                    v-on:change="showHideDatasetHotWater(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHotWaterYear0 }"
                  ></span>
                  <span class="legText">{{ labelHotWaterYear0 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHotWaterYear1 != ''"
                >
                  <b-form-checkbox
                    id="checkHotWater1"
                    v-on:change="showHideDatasetHotWater(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHotWaterYear1 }"
                  ></span>
                  <span class="legText">{{ labelHotWaterYear1 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHotWaterYear2 != ''"
                >
                  <b-form-checkbox
                    id="checkHotWater2"
                    v-on:change="showHideDatasetHotWater(2)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHotWaterYear2 }"
                  ></span>
                  <span class="legText">{{ labelHotWaterYear2 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHotWaterYear3 != ''"
                >
                  <b-form-checkbox
                    id="checkHotWater3"
                    v-on:change="showHideDatasetHotWater(3)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHotWaterYear3 }"
                  ></span>
                  <span class="legText">{{ labelHotWaterYear3 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHotWaterYear4 != ''"
                >
                  <b-form-checkbox
                    id="checkHotWater4"
                    v-on:change="showHideDatasetHotWater(4)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHotWaterYear4 }"
                  ></span>
                  <span class="legText">{{ labelHotWaterYear4 }}</span>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHotWater">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption MY Hot water-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Daily Consumption MY Heat-->
        <KTPortlet
          v-bind:title="subTitleHeat"
          v-show="itemsHeat || itemsHeat === null"
        >
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu :functionCSV="downloadHeatCSV"></TableContextMenu>
          </template>
          <!--End::Context menu-->
          <template v-slot:body>
            <b-container fluid v-if="itemsHeat">
              <!--Begin::Main chart element -->
              <div
                style="width: 100%; overflow-x: auto"
                v-if="chartOptionsHeat"
                class="poschodoch-consumption-graph"
              >
                <div :style="{ minWidth: '800px', height: '300px' }">
                  <Chart
                    ref="heat-chart"
                    v-for="c in charts"
                    :key="c"
                    v-bind:options="chartOptionsHeat"
                    height="300"
                    width="0"
                  ></Chart>
                </div>
              </div>
              <!--End::Main chart element -->

              <!--Begin::Legend -->
              <b-row class="justify-content-customLegend">
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHeatYear0 != ''"
                >
                  <b-form-checkbox
                    id="checkHeat0"
                    v-on:change="showHideDatasetHeat(0)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHeatYear0 }"
                  ></span>
                  <span class="legText">{{ labelHeatYear0 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHeatYear1 != ''"
                >
                  <b-form-checkbox
                    id="heckHeat1"
                    v-on:change="showHideDatasetHeat(1)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHeatYear1 }"
                  ></span>
                  <span class="legText">{{ labelHeatYear1 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHeatYear2 != ''"
                >
                  <b-form-checkbox
                    id="heckHeat2"
                    v-on:change="showHideDatasetHeat(2)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHeatYear2 }"
                  ></span>
                  <span class="legText">{{ labelHeatYear2 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHeatYear3 != ''"
                >
                  <b-form-checkbox
                    id="heckHeat3"
                    v-on:change="showHideDatasetHeat(3)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHeatYear3 }"
                  ></span>
                  <span class="legText">{{ labelHeatYear3 }}</span>
                </div>
                <div
                  style="display: flex"
                  class="mr-5"
                  v-if="labelHeatYear4 != ''"
                >
                  <b-form-checkbox
                    id="heckHeat4"
                    v-on:change="showHideDatasetHeat(4)"
                    size="lg"
                    style
                    checked="true"
                    switch
                  ></b-form-checkbox>
                  <span
                    class="legSquare"
                    v-bind:style="{ backgroundColor: colorHeatYear4 }"
                  ></span>
                  <span class="legText">{{ labelHeatYear4 }}</span>
                </div>
              </b-row>
              <!--End::Legend -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!chartOptionsHeat">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
        </KTPortlet>
        <!--End::Daily Consumption MY Heat-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import Chart from "@/views/components/Chart.vue";
import ColorService from "@/common/color.service";
import FileService from "@/common/file.service";
import { mapGetters } from "vuex";

export default {
  name: "daily-consumption-more-years",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    Chart,
  },
  data() {
    return {
      title: null,
      description: null,
      subTitle: null,
      subTitleHotWater: null,
      subTitleHeat: null,
      menuId: null,
      yearFrom: null,
      yearTo: null,
      month: null,
      months: null,
      monthFrom: null,
      monthTo: null,
      items: null,
      itemsHotWater: null,
      itemsHeat: null,
      labelColdWaterYear0: "",
      labelColdWaterYear1: "",
      labelColdWaterYear2: "",
      labelColdWaterYear3: "",
      labelColdWaterYear4: "",
      colorColdWaterYear0: "",
      colorColdWaterYear1: "",
      colorColdWaterYear2: "",
      colorColdWaterYear3: "",
      colorColdWaterYear4: "",
      labelHotWaterYear0: "",
      labelHotWaterYear1: "",
      labelHotWaterYear2: "",
      labelHotWaterYear3: "",
      labelHotWaterYear4: "",
      colorHotWaterYear0: "",
      colorHotWaterYear1: "",
      colorHotWaterYear2: "",
      colorHotWaterYear3: "",
      colorHotWaterYear4: "",
      labelHeatYear0: "",
      labelHeatYear1: "",
      labelHeatYear2: "",
      labelHeatYear3: "",
      labelHeatYear4: "",
      colorHeatYear0: "",
      colorHeatYear1: "",
      colorHeatYear2: "",
      colorHeatYear3: "",
      colorHeatYear4: "",
      chartOptionsColdWater: null,
      chartOptionsHotWater: null,
      chartOptionsHeat: null,
      chartLabels: [],
      chartLabelsHotWater: [],
      chartLabelsHeat: [],
      charts: [1],
      chartDataColdWater: [],
      chartDataHotWater: [],
      datasetsHeat: [],
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    previousMonth(selectedMonth = null) {
      if (selectedMonth == 1) this.fetchData(12);
      else this.fetchData(selectedMonth - 1);
    },
    nextMonth(selectedMonth = null) {
      if (selectedMonth == 12) this.fetchData(1);
      else this.fetchData(selectedMonth + 1);
    },
    setMonthRange() {
      this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    },
    showHideDatasetColdWater(index) {
      this.chartOptionsColdWater.data.datasets[index].hidden = !this
        .chartOptionsColdWater.data.datasets[index].hidden;
      this.chartOptionsColdWater.chart.update();
    },
    showHideDatasetHotWater(index) {
      this.chartOptionsHotWater.data.datasets[index].hidden = !this
        .chartOptionsHotWater.data.datasets[index].hidden;
      this.chartOptionsHotWater.chart.update();
    },
    showHideDatasetHeat(index) {
      this.chartOptionsHeat.data.datasets[index].hidden = !this.chartOptionsHeat
        .data.datasets[index].hidden;
      this.chartOptionsHeat.chart.update();
    },
    createChart() {
      this.charts = [this.charts[0] + 1];
      this.chartOptionsColdWater = {
        type: "bar",
        data: {
          datasets: this.chartDataColdWater,
          labels: this.chartLabels,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem, data) => {
                let title =
                  tooltipItem[0].label +
                  "." +
                  this.month +
                  "." +
                  data.datasets[tooltipItem[0].datasetIndex].label;

                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_MORE_YEARS.CHART_COLD_WATER.AX_X",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_MORE_YEARS.CHART_COLD_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHotWater() {
      //this.charts = [this.charts[0] + 1];
      this.chartOptionsHotWater = {
        type: "bar",
        data: {
          datasets: this.chartDataHotWater,
          labels: this.chartLabelsHotWater,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem, data) => {
                let title =
                  tooltipItem[0].label +
                  "." +
                  this.month +
                  "." +
                  data.datasets[tooltipItem[0].datasetIndex].label;

                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_MORE_YEARS.CHART_HOT_WATER.AX_X",
                  ),
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_MORE_YEARS.CHART_HOT_WATER.AX_Y",
                  ),
                },
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    createChartHeat() {
      //this.charts = [this.charts[0] + 1];
      this.chartOptionsHeat = {
        type: "bar",
        data: {
          datasets: this.datasetsHeat,
          labels: this.chartLabelsHeat,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: false,
          legendCallback: UIService.createChartLegend,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: this.layoutConfig("colors.state.brand"),
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              title: (tooltipItem, data) => {
                let title =
                  tooltipItem[0].label +
                  "." +
                  this.month +
                  "." +
                  data.datasets[tooltipItem[0].datasetIndex].label;

                return title;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_MORE_YEARS.CHART_HEAT.AX_X",
                  ),
                },
                stacked: false,
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t(
                    "DAILY_CONSUMPTION_MORE_YEARS.CHART_HEAT.AX_Y",
                  ),
                },
                stacked: false,
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      };
    },
    transformData(data) {
      let yearLabels = [];
      let yearData = {};
      let consumptionColdWater = [];
      let labels = [];
      let baseColor = ColorService.hexToRgb(
        this.layoutConfig("colors.state.info"),
      );

      this.labelColdWaterYear0 =
        data.Series.length > 0 ? data.Series[0].Year : "";
      this.labelColdWaterYear1 =
        data.Series.length > 1 ? data.Series[1].Year : "";
      this.labelColdWaterYear2 =
        data.Series.length > 2 ? data.Series[2].Year : "";
      this.labelColdWaterYear3 =
        data.Series.length > 3 ? data.Series[3].Year : "";
      this.labelColdWaterYear4 =
        data.Series.length > 4 ? data.Series[4].Year : "";

      for (let i = 0; i < data.Series.length; i++) {
        let labelYear = "";
        labelYear += data.Series[i].Year;
        yearLabels.push(labelYear);
        yearData[data.Series[i].Year] = [];

        for (let j = 0; j < data.Series[i].Consumption.length; j++) {
          yearData[data.Series[i].Year].push({
            x: data.Series[i].Consumption[j].Day,
            y: data.Series[i].Consumption[j].Consumption,
          });
        }
      }

      for (let i = data.ConsumptionFrom; i <= data.ConsumptionTo; i++) {
        labels.push(i);
      }

      for (let i = 0; i < yearLabels.length; i++) {
        let room = yearLabels[i];
        let ratio = 1 - 0.5 * (i / yearLabels.length);
        let color =
          "rgb(" +
          parseInt(baseColor.r * ratio) +
          "," +
          parseInt(baseColor.g * ratio) +
          "," +
          parseInt(baseColor.b * ratio) +
          ")";
        switch (i) {
          case 0:
            this.colorColdWaterYear0 = color;
            break;
          case 1:
            this.colorColdWaterYear1 = color;
            break;
          case 2:
            this.colorColdWaterYear2 = color;
            break;
          case 3:
            this.colorColdWaterYear3 = color;
            break;
          case 4:
            this.colorColdWaterYear4 = color;
            break;
        }

        consumptionColdWater.push({
          data: yearData[room],
          label: room,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        });
      }

      this.chartLabels = labels;
      this.chartDataColdWater = consumptionColdWater;
    },
    transformDataHotWater(data) {
      let consumptionHotWater = [];
      let yearLabels = [];
      let yearData = {};
      let labels = [];
      let baseColor = ColorService.hexToRgb(
        this.layoutConfig("colors.state.danger"),
      );

      this.labelHotWaterYear0 =
        data.Series.length > 0 ? data.Series[0].Year : "";
      this.labelHotWaterYear1 =
        data.Series.length > 1 ? data.Series[1].Year : "";
      this.labelHotWaterYear2 =
        data.Series.length > 2 ? data.Series[2].Year : "";
      this.labelHotWaterYear3 =
        data.Series.length > 3 ? data.Series[3].Year : "";
      this.labelHotWaterYear4 =
        data.Series.length > 4 ? data.Series[4].Year : "";
      for (let i = 0; i < data.Series.length; i++) {
        yearLabels.push(data.Series[i].Year);
        yearData[data.Series[i].Year] = [];

        for (let j = 0; j < data.Series[i].Consumption.length; j++) {
          yearData[data.Series[i].Year].push({
            x: data.Series[i].Consumption[j].Day,
            y: data.Series[i].Consumption[j].Consumption,
          });
        }
      }

      for (let i = data.ConsumptionFrom; i <= data.ConsumptionTo; i++) {
        labels.push(i);
      }

      for (let i = 0; i < yearLabels.length; i++) {
        let room = yearLabels[i];
        let ratio = 1 - 0.5 * (i / yearLabels.length);
        let color =
          "rgb(" +
          parseInt(baseColor.r * ratio) +
          "," +
          parseInt(baseColor.g * ratio) +
          "," +
          parseInt(baseColor.b * ratio) +
          ")";
        switch (i) {
          case 0:
            this.colorHotWaterYear0 = color;
            break;
          case 1:
            this.colorHotWaterYear1 = color;
            break;
          case 2:
            this.colorHotWaterYear2 = color;
            break;
          case 3:
            this.colorHotWaterYear3 = color;
            break;
          case 4:
            this.colorHotWaterYear4 = color;
            break;
        }

        consumptionHotWater.push({
          data: yearData[room],
          label: room,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        });
      }

      this.chartLabelsHotWater = labels;
      this.chartDataHotWater = consumptionHotWater;
    },
    transformDataHeat(data) {
      let roomLabels = [];
      let roomData = {};
      let labels = [];
      let roomDatasets = [];
      let baseColor = ColorService.hexToRgb(
        this.layoutConfig("colors.state.brand"),
      );

      this.labelHeatYear0 = data.Series.length > 0 ? data.Series[0].Year : "";
      this.labelHeatYear1 = data.Series.length > 1 ? data.Series[1].Year : "";
      this.labelHeatYear2 = data.Series.length > 2 ? data.Series[2].Year : "";
      this.labelHeatYear3 = data.Series.length > 3 ? data.Series[3].Year : "";
      this.labelHeatYear4 = data.Series.length > 4 ? data.Series[4].Year : "";
      for (let i = 0; i < data.Series.length; i++) {
        roomLabels.push(data.Series[i].Year);
        roomData[data.Series[i].Year] = [];

        for (let j = 0; j < data.Series[i].Consumption.length; j++) {
          roomData[data.Series[i].Year].push({
            x: data.Series[i].Consumption[j].Day,
            y: data.Series[i].Consumption[j].Consumption,
          });
        }
      }

      for (let i = data.ConsumptionFrom; i <= data.ConsumptionTo; i++) {
        labels.push(i);
      }

      for (let i = 0; i < data.Series.length; i++) {
        let room = roomLabels[i];
        let ratio = 1 - 0.5 * (i / data.Series.length);
        let color =
          "rgb(" +
          parseInt(baseColor.r * ratio) +
          "," +
          parseInt(baseColor.g * ratio) +
          "," +
          parseInt(baseColor.b * ratio) +
          ")";
        switch (i) {
          case 0:
            this.colorHeatYear0 = color;
            break;
          case 1:
            this.colorHeatYear1 = color;
            break;
          case 2:
            this.colorHeatYear2 = color;
            break;
          case 3:
            this.colorHeatYear3 = color;
            break;
          case 4:
            this.colorHeatYear4 = color;
            break;
        }

        roomDatasets.push({
          data: roomData[room],
          label: room,
          fill: false,
          borderColor: color,
          backgroundColor: color,
        });
      }

      this.datasetsHeat = roomDatasets;
      this.chartLabelsHeat = labels;
    },
    downloadColdWaterCSV() {
      this.downloadCSV(this.subTitle, this.chartLabels, this.items);
    },
    downloadHotWaterCSV() {
      this.downloadCSV(
        this.subTitleHotWater,
        this.chartLabelsHotWater,
        this.itemsHotWater,
      );
    },
    downloadHeatCSV() {
      this.downloadCSV(this.subTitleHeat, this.chartLabelsHeat, this.itemsHeat);
    },
    downloadCSV(title, labels, items) {
      let header = [];
      let values = [];

      if (items) {
        header.push("");
        items.forEach(function(item) {
          header.push(item.Year);
          item.Consumption.forEach(function(value) {
            values.push([
              item.Year,
              value.Day,
              FormatService.formatNumberToLocalForCSV(value.Consumption),
            ]);
          });
        });
      }

      let result = FileService.generateChartCSV(header, labels, values);
      let fileName = title + ".csv";

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData(selectedMonth = null) {
      // cold water
      this.items = null;
      this.chartOptionsColdWater = null;
      let parameters = "?type=S&menuId=" + this.menuId.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();

      ApiService.setHeader();
      ApiService.get("Flat", "DailyConsumptionYears" + parameters)
        .then(({ data }) => {
          this.transformData(data);
          this.createChart();

          this.title = data.Title;
          this.description = data.Description;
          this.subTitle = data.Subtitle;
          this.items = data.Series;
          this.yearFrom = data.YearFrom;
          this.yearTo = data.YearTo;
          this.monthFrom = data.MonthFrom;
          this.monthTo = data.MonthTo;
          this.dateFrom = data.DateFrom;
          this.dateTo = data.DateTo;
          this.setMonthRange();
          this.month = data.Month;
        })
        .catch(() => {
          this.items = undefined;
        });

      // hot water
      this.itemsHotWater = null;
      this.chartOptionsHotWater = null;
      parameters = "?type=T&menuId=" + this.menuId.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();

      ApiService.get("Flat", "DailyConsumptionYears" + parameters)
        .then(({ data }) => {
          this.transformDataHotWater(data);
          this.createChartHotWater();

          this.subTitleHotWater = data.Subtitle;
          this.itemsHotWater = data.Series;

          if (!this.items) {
            this.title = data.Title;
            this.description = data.Description;

            this.yearFrom = data.YearFrom;
            this.yearTo = data.YearTo;
            this.monthFrom = data.MonthFrom;
            this.monthTo = data.MonthTo;
            this.dateFrom = data.DateFrom;
            this.dateTo = data.DateTo;
            this.setMonthRange();
            this.month = data.Month;
          }
        })
        .catch(() => {
          this.itemsHotWater = undefined;
        });

      // heat
      this.itemsHeat = null;
      this.chartOptionsHeat = null;
      parameters = "?type=U&menuId=" + this.menuId.toString();
      if (selectedMonth != null)
        parameters += "&month=" + selectedMonth.toString();

      ApiService.get("Flat", "DailyConsumptionYears" + parameters)
        .then(({ data }) => {
          this.transformDataHeat(data);
          this.createChartHeat();

          this.subTitleHeat = data.Subtitle;
          this.itemsHeat = data.Series;

          if (!this.items && !this.itemsHotWater) {
            this.title = data.Title;
            this.description = data.Description;

            this.yearFrom = data.YearFrom;
            this.yearTo = data.YearTo;
            this.monthFrom = data.MonthFrom;
            this.monthTo = data.MonthTo;
            this.dateFrom = data.DateFrom;
            this.dateTo = data.DateTo;
            this.setMonthRange();
            this.month = data.Month;
          }
        })
        .catch(() => {
          this.itemsHeat = undefined;
        });
    },
  },
};
</script>
