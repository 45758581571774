<template>
  <div>
    <CashRegisterDocs v-bind:cashRegisterDocs="cashRegisterDocs" />
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <!--Begin::Object Account-->
        <KTPortlet v-bind:title="title">
          <!--Begin::Context menu-->
          <template v-slot:toolbar>
            <TableContextMenu
              :functionCSV="downloadCSV"
              :functionPrint="print"
            ></TableContextMenu>
          </template>
          <!--End::Context menu-->

          <!--Begin::Data -->
          <template v-slot:body>
            <b-container fluid v-if="items">
              <!--Begin::Main table element -->
              <b-table
                show-empty
                hover
                size="md"
                :empty-text="$t('COMMON.LIST.NO_RECORDS')"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-direction="sortDirection"
                @row-clicked="tableRowClickHandler"
                :tbody-tr-class="pointerRowClass"
              >
                <template v-slot:cell(FinalBalance)="data">
                  {{ data.value }} {{ data.item.Currency }}
                </template>
                <template v-slot:cell(AccountDescription)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.item != null"
                    v-on:click="
                      showCashRegisterDetail(menuId, data.item.AccountId)
                    "
                    class="kt-link"
                  >
                    {{ data.value }}
                  </a>
                  <span v-if="!(data.item != null)">{{ data.value }}</span>
                </template>
                <template v-slot:cell(DocCount)="data">
                  <a
                    href="javascript:void(0)"
                    v-if="data.value > 1"
                    v-on:click="
                      showCashRegisterDocs(
                        data.item.Reckey,
                        data.item.TableName,
                      )
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-file-2 poschodoch-icon-medium"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    v-if="data.value == 1"
                    v-on:click="
                      showSingleDoc(data.item.Reckey, data.item.TableName)
                    "
                    class="poschodoch-icon-link p-2"
                  >
                    <i class="flaticon-download poschodoch-icon-medium"></i>
                  </a>
                </template>
                <template v-slot:cell(Mobile)="data">
                  <div class="d-flex flex-column align-items-start w-100">
                    <div
                      class="poschodoch-meter-readings-table-items d-flex w-100 flex-row justify-content-between"
                    >
                      <div
                        class="d-flex flex-column align-items-start poschodoch-mobile-left"
                      >
                        <span class="text-left">
                          <a
                            href="javascript:void(0)"
                            v-if="data.item.AccountDescription != null"
                            v-on:click="
                              showCashRegisterDetail(
                                menuId,
                                data.item.AccountId,
                              )
                            "
                            class="kt-link"
                          >
                            {{ data.value.AccountDescription }}
                          </a>
                        </span>
                        <span class="text-left">
                          {{
                            $i18n.t("CASHREGISTER.TABLE.ISSUEDATEMOBILE") + " "
                          }}
                          {{
                            getFormatService().formatDateToLocal(
                              data.value.IssueDate,
                            ) + ": "
                          }}
                          <span
                            v-bind:class="[
                              data.value.FinalBalance < 0
                                ? 'poschodoch-table-title-mobile color-danger'
                                : 'poschodoch-table-title-mobile',
                            ]"
                          >
                            {{
                              addCurrency(
                                getFormatService().formatNumberToLocal(
                                  data.value.FinalBalance,
                                ),
                              )
                            }}
                          </span>
                        </span>
                        <span class="text-left">
                          <span>
                            {{ $i18n.t("CASHREGISTER.TABLE.BANK") + ": " }}
                          </span>
                          {{ data.value.Bank }}
                        </span>
                        <span class="poschodoch-mobile-document-link">
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount > 1"
                            v-on:click="
                              showCashRegisterDocs(
                                data.item.Reckey,
                                data.item.TableName,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-file-2 poschodoch-icon-medium"
                            ></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            v-if="data.value.DocCount == 1"
                            v-on:click="
                              showSingleDoc(
                                data.item.Reckey,
                                data.item.TableName,
                              )
                            "
                            class="poschodoch-icon-link p-2"
                          >
                            <i
                              class="flaticon-download poschodoch-icon-medium"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <!--End::Main table element -->

              <!--Begin::Description -->
              <b-row class="justify-content-end" v-if="description">
                <b-col lg="12">
                  <hr class="poschodoch-separator-line mt-4 mb-4" />
                  <ul class="poschodoch-note-list">
                    <li class="poschodoch-note">{{ description }}</li>
                  </ul>
                </b-col>
              </b-row>
              <!--End::Description -->
            </b-container>

            <!--Begin::Loader -->
            <b-container fluid v-if="!items">
              <Loader />
            </b-container>
            <!--End::Loader -->
          </template>
          <!--End::Data -->
        </KTPortlet>
        <!--End::Object Account-->
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TableContextMenu from "@/views/components/TableContextMenu.vue";
import ApiService from "@/common/api.service";
import FormatService from "@/common/format.service";
import Loader from "@/views/components/Loader.vue";
import JwtService from "@/common/jwt.service";
import FileService from "@/common/file.service";
import CashRegisterDocs from "@/views/pages/domus/CashRegisterDocs.vue";

export default {
  name: "cashRegister",
  components: {
    KTPortlet,
    TableContextMenu,
    Loader,
    CashRegisterDocs,
  },
  data() {
    return {
      title: null,
      description: null,
      menuId: null,
      cashRegisterDetail: null,
      cashRegisterDocs: null,
      totalRows: null,
      items: null,
      fields: [
        {
          key: "AccountDescription",
          label: this.$i18n.t("CASHREGISTER.TABLE.ACCOUNTDESCRIPTION"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "Bank",
          label: this.$i18n.t("CASHREGISTER.TABLE.BANK"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
        },
        {
          key: "IssueDate",
          label: this.$i18n.t("CASHREGISTER.TABLE.ISSUEDATE"),
          sortable: false,
          sortDirection: "desc",
          class: "text-center, table-no-mobile",
          formatter: (value) => {
            return FormatService.formatDateToLocal(value);
          },
        },
        {
          key: "FinalBalance",
          label: this.$i18n.t("CASHREGISTER.TABLE.FINALBALANCE"),
          sortable: false,
          sortDirection: "desc",
          class: "table-no-mobile",
          formatter: (value, key, item) => {
            if (item.FinalBalance < 0) {
              item._cellVariants = { FinalBalance: "danger" };
            }
            return FormatService.formatNumberToLocal(value);
          },
        },
        {
          key: "DocCount",
          label: "",
          sortable: false,
          class: "text-center table-no-mobile",
        },
        {
          key: "Mobile",
          label: "Mobile",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
      sortBy: "",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.menuId = this.$route.query.menuId;
    this.fetchData();
  },
  methods: {
    getFormatService() {
      return FormatService;
    },
    tableRowClickHandler(record) {
      this.showCashRegisterDetail(this.menuId, record.AccountId);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    addCurrency(data) {
      return (
        data + " " + FormatService.formatCurrency(JwtService.getCurrency())
      );
    },
    addCurrencyToColumn(column) {
      return (
        column +
        " [" +
        FormatService.formatCurrency(JwtService.getCurrency()) +
        "]"
      );
    },
    showCashRegisterDocs(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=183";

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.cashRegisterDocs = data;
        this.totalRows = this.cashRegisterDocs.length;
        this.$bvModal.show("modal-cashRegister-docs");
      });
    },
    showSingleDoc(recKey, tableName) {
      let parameters =
        "?tableName=" +
        tableName.toString() +
        "&recKey=" +
        recKey.toString() +
        "&menuId=" +
        this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Document", "List" + parameters).then(({ data }) => {
        this.cashRegisterDetailDocs = data;

        parameters =
          "?docId=" + this.cashRegisterDetailDocs.Documents[0].DocId.toString();

        ApiService.setHeader();
        ApiService.get("Document", "Get" + parameters).then(({ data }) => {
          let binaryContent = FileService.convertBase64ToByteArray(
            data.FileContent,
          );

          let fileName = this.cashRegisterDetailDocs.Documents[0].FileName;

          FileService.downloadFile(fileName, binaryContent);
        });
      });
    },
    showCashRegisterDetail(menuId, accountId) {
      this.$router.push({
        name: "domus-cashRegister-detail",
        query: { menuId: this.menuId, accountId: accountId },
      });
    },
    print() {
      let parameters = "?menuId=" + this.menuId.toString();
      if (this.date != null) parameters += "&date=" + this.date.toString();

      window.open("/cashRegisterPrint" + parameters);
    },
    downloadCSV() {
      let header = [
        this.$i18n.t("CASHREGISTER.TABLE.ACCOUNTDESCRIPTION"),
        this.$i18n.t("CASHREGISTER.TABLE.BANK"),
        this.$i18n.t("CASHREGISTER.TABLE.ISSUEDATE"),
        this.addCurrencyToColumn(
          this.$i18n.t("CASHREGISTER.TABLE.FINALBALANCE"),
        ),
        //this.$i18n.t("CASHREGISTER.TABLE.BANKACCOUNT"),
      ];

      let rows = [];
      this.items.forEach(function(item) {
        let row = [
          item.AccountDescription,
          item.Bank,
          FormatService.formatDateToLocal(item.IssueDate),
          FormatService.formatNumberToLocalForCSV(item.FinalBalance),
          //item.BankAccount,
        ];
        rows.push(row);
      });

      let result = FileService.generateCSV(header, rows);
      let fileName = this.title + `_${this.date}.csv`;

      FileService.downloadFile(fileName, result.content, result.contentType);
    },
    fetchData() {
      this.items = null;
      let parameters = "?menuId=" + this.menuId.toString();

      ApiService.setHeader();
      ApiService.get("Object", "CashRegister" + parameters).then(({ data }) => {
        this.title = data.Title;
        this.description = data.Description;
        this.items = data.CashRegister;

        this.transformData(this.items);
      });
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].Mobile = {
          AccountId: items[i].AccountId,
          AccountDescription: items[i].AccountDescription,
          Bank: items[i].Bank,
          IssueDate: items[i].IssueDate,
          FinalBalance: items[i].FinalBalance,
          BankAccount: items[i].BankAccount,
          DocCount: items[i].DocCount,
        };
      }
    },
  },
};
</script>
